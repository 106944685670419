import { useCancellationContext } from "./CancellationContext";
import { CancelStepHeader } from "./CancelStepHeader";

export const CancelFeatures: React.FC = () => {
  const { selectedLearnerNames, totalSeats } = useCancellationContext();
  const features = [
    "Unlimited access to all math courses",
    "Expertly crafted learning content",
    "Parent dashboard insights and progress tracking",
    "Personalized practice tailored to your learner",
    "Engaging and interactive learning activities",
    "Opportunity for improved grades and confidence",
    "Flexible learning support anytime, anywhere",
    "Top-notch resources for peace of mind",
  ];

  const singleLearnerName =
    selectedLearnerNames.length === 1 ? selectedLearnerNames[0] : null;

  const isAllLearners = totalSeats === selectedLearnerNames.length;

  return (
    <div className="flex flex-col gap-8">
      <CancelStepHeader
        title="Are you sure you want to cancel?"
        subtitle={
          singleLearnerName && !isAllLearners ? (
            <>
              Here&apos;s what <strong>{singleLearnerName}</strong> will miss
              out on:
            </>
          ) : (
            "Here's what you'll miss out on:"
          )
        }
      />
      <ul className="flex flex-col gap-4">
        {features.map((feature) => (
          <li key={feature} className="flex items-center gap-3">
            <i className="far fa-check !text-dm-brand-blue-500" />
            {feature}
          </li>
        ))}
      </ul>
    </div>
  );
};
