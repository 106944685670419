import clsx from "clsx";
import { Switch } from "@headlessui/react";
import React from "react";

export const DmModal = ({
  showModal,
  setShowModal,
  cancelMessage,
  confirmMessage,
  modalTitle,
  modalBodyText,
  modalAction,
}: {
  showModal: boolean;
  setShowModal: React.Dispatch<boolean>;
  cancelMessage?: string;
  confirmMessage?: string;
  modalTitle?: string;
  modalBodyText?: any;
  modalAction: any;
}) => {
  return (
    <div
      className="modal fade border-left fixed right-0 top-0 z-50 hidden h-full overflow-y-auto overflow-x-hidden border-gray-200 outline-none"
      id="dm-modal"
      tabIndex={-1}
      aria-labelledby="dm-modal"
      aria-hidden="true"
      style={showModal ? { display: "block" } : { display: "none" }}
    >
      <div className="modal-dialog pointer-events-none relative w-auto">
        <div className="modal-content pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none">
          <div className="modal-header flex flex-shrink-0 items-center justify-between rounded-t-md border-b border-gray-200 p-4">
            <h5
              className="text-xl font-medium leading-normal text-gray-800"
              id="dm-modal"
            >
              {modalTitle}
            </h5>
            <button
              type="button"
              className="btn-close box-content h-4 w-4 rounded-none border-none p-1 text-black opacity-50 hover:text-black hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body relative p-4">{modalBodyText}</div>
          <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end rounded-b-md border-t border-gray-200 p-4">
            <button
              type="button"
              className="rounded
              bg-purple-600
                px-6
                py-2.5
                text-xs
                font-medium
                uppercase
                leading-tight
                text-white
                shadow-md
                transition duration-150
                ease-in-out hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700
                focus:shadow-lg focus:outline-none
                focus:ring-0
                active:bg-purple-800
                active:shadow-lg"
              data-bs-dismiss="modal"
              onClick={() => setShowModal(false)}
            >
              {cancelMessage || "Close"}
            </button>
            <button
              type="button"
              className="ml-1
                rounded
                bg-blue-600
                px-6
                py-2.5
                text-xs
                font-medium
                uppercase
                leading-tight
                text-white
                shadow-md transition
                duration-150 ease-in-out hover:bg-blue-700 hover:shadow-lg
                focus:bg-blue-700 focus:shadow-lg
                focus:outline-none
                focus:ring-0
                active:bg-blue-800
                active:shadow-lg"
              onClick={() => {
                modalAction();
                setShowModal(false);
              }}
            >
              {confirmMessage || "Save changes"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const DmLoadingSpinner = ({
  message,
  className,
  spinnerColor,
}: {
  message: string;
  className?: string;
  spinnerColor?: string;
}) => {
  return (
    <div
      className={
        className ||
        "inline-flex items-center px-4 py-2 text-base font-medium text-dm-blue transition duration-150 ease-in-out sm:px-6 lg:px-8"
      }
    >
      <svg
        className={clsx(
          "mr-3 h-5 w-5 animate-spin",
          spinnerColor || "text-dm-blue"
        )}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        ></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
      {message}
    </div>
  );
};

/**
 * Toggle with a single label.
 * Feel free to edit to make this a more generic component!
 */
export const DMToggleSingleLabel = ({
  checked,
  onChange,
  label,
}: {
  checked: boolean;
  onChange: (enabled: boolean) => void;
  label: string;
}) => (
  <Switch.Group>
    <div className="flex items-center">
      <Switch
        checked={checked}
        onChange={onChange}
        className={`${
          checked ? "bg-dm-brand-blue-500" : "bg-gray-600"
        } relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-dm-brand-blue-500 focus:ring-offset-2`}
      >
        <span
          className={`${
            checked ? "translate-x-6" : "translate-x-1"
          } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
        />
      </Switch>
      <Switch.Label className="ml-4">{label}</Switch.Label>
    </div>
  </Switch.Group>
);
