import SwipeListener from "swipe-listener";
import renderMathInElement from "../../student/utils/auto-render";
import {
  colors,
  addLinearGradient,
  addLoseGameText,
  addWinGameText,
} from "./gameFunctions";
import { generateProblem } from "./snakeGameProblemGenerator";

window.renderMathInElement = renderMathInElement; // for typeset function
const { DeltaGraph, $, rand, typeset } = window;

const GRID_SIZE = 20;
const SNAKE_LENGTH = 12;
const INITIAL_MOVE_INTERVAL = 100;
const LENGTH_TO_ADD = 5;
const NUM_CORRECT_TO_WIN = 5;

/**
 * Controls countdown and starts game
 * @param graph instance of DeltaGraph
 * @param level integer > 0 representing the user's level
 * @param startFunc callback to execute that runs the game after the countdown, accepts parameter of an object representing initial question that is rendered on the screen
 */
function startGame(graph, level, startFunc) {
  let count = 3; // 3 second countdown

  const xPos = (graph.bounds.xmin + graph.bounds.xmax) * 0.5;
  const yPos = (graph.bounds.ymin + graph.bounds.ymax) * 0.5;
  const textSettings = {
    fontSize: "6rem",
    align: "mm",
    fontWeight: "700",
    fill: colors.white,
    class: "font-serif",
  };

  // show question, answers, overlay and countdown
  const initialQuestionObject = initialQuestionSetUp(graph, level);
  const overlay = addOverlay(graph);
  let text = graph.text(xPos, yPos, count, textSettings);

  const countdown = setInterval(() => {
    count -= 1;
    if (text) text.remove();
    if (count < 1) {
      clearInterval(countdown);
      overlay.remove();
      startFunc(initialQuestionObject);
      return;
    }
    text = graph.text(xPos, yPos, count, textSettings);
  }, 1000);
}

/** Sets up the initial graph (this should only run once, even if the player plays again)
 * @param winFunc function to execute upon a win
 * @param level integer > 0 representing the user's level
 */
function initialGameSetUp(winFunc, loseFunc, level) {
  const graph = new DeltaGraph("snake-game-canvas", {
    axes: false,
    xmin: -1,
    xmax: GRID_SIZE + 1,
    ymin: -1,
    ymax: GRID_SIZE + 1,
    xscl: 1,
    yscl: 1,
    width: 500,
    height: 500,
    border: 0,
    wideContainer: true,
    align: "center",
  });

  // add gradients
  addLinearGradient(
    graph,
    "dm-linear-gradient",
    colors["dm-brand-blue-500"],
    colors["dm-brand-blue-600"]
  );

  // create edge (with outer, middle and inner border) and grid
  const { xmax, xmin, ymax, ymin } = graph.bounds;

  // outer border rectangle
  graph.rect(xmin, ymax, xmax - xmin, ymax - ymin, {
    strokeWidth: 0,
    rx: "24",
    fill: colors["dm-brand-blue-500"],
  });

  // middle border rectangle
  const borderDiffMiddle = 0.15;
  graph.rect(
    xmin + borderDiffMiddle,
    ymax - borderDiffMiddle,
    xmax - xmin - borderDiffMiddle * 2,
    ymax - ymin - borderDiffMiddle * 2,
    {
      strokeWidth: 0,
      rx: "24",
      fill: "url('#dm-linear-gradient')",
    }
  );

  // background rectangle
  graph.rect(xmin + 1, ymax - 1, xmax - xmin - 2, ymax - ymin - 2, {
    stroke: colors["dm-brand-blue-100"],
    fill: colors["dm-brand-blue-100"],
  });

  // grid lines
  const gridSettings = { strokeWidth: 1, stroke: colors["dm-brand-blue-200"] };
  for (let i = 1; i < GRID_SIZE; i++) {
    graph.line(i, GRID_SIZE, i, 0, gridSettings);
    graph.line(0, i, GRID_SIZE, i, gridSettings);
  }

  // inner border (no fill rectangle)
  const borderDiffInner = 0.9;
  graph.rect(
    xmin + borderDiffInner,
    ymax - borderDiffInner,
    xmax - xmin - borderDiffInner * 2,
    ymax - ymin - borderDiffInner * 2,
    {
      stroke: colors["dm-brand-blue-500"],
      strokeWidth: graph.getx(0.15) - graph.getx(0),
      rx: "8",
      fill: "none",
    }
  );

  const overlay = addOverlay(graph);

  const titleHtml = `<h2 class="font-serif text-white text-xl text-center font-bold">
    Snake Game Rules
  </h2>`;

  const rules =
    level === 10
      ? `
    <li>
      You will be given a "starting number". If that number is EVEN, cut it in half. If that number is ODD
      then multiply by 3 and add 1. Take the resulting number and apply the same rule. Use the arrow keys
      to move the snake around the board. Always eat the next number by applying the rule. If the snake
      eats the wrong number, crashes into the wall or eats itself, the game is over. You win when you eat
      the number "1", which by the Collatz Conjecture, will always occur.
    </li>
  `
      : `
    <li>
      <strong>Goal:</strong> Guide the snake around the board to eat the correct answer to each math problem. 
    </li><li>
      <strong>Controls:</strong> Use your arrow keys to navigate the snake around the game board.
    </li><li>
      <strong>How to Win:</strong> Each correct answer eaten earns you a point and makes the snake grow longer.  Eat 5 correct answers in a row to win! 
    </li><li>
      <strong>Beware:</strong> Crashing into a wall, your own tail, or an incorrect answer ends the game!
    </li>
  `;

  const rulesHtml = `<ul class="font-sans text-white text-base text-wrap flex flex-col gap-3">${rules}</ul>`;

  const buttonHtml = `<button id="startGame" class="font-sans text-sm font-bold bg-white max-w-full border rounded border-dm-charcoal-800 h-10">
    Start Game
  </button>`;

  const outerDivHtml = `<div class="flex flex-col gap-4 content-center max-w-xs">
    ${titleHtml}
    ${rulesHtml}
    ${buttonHtml}
  </div>`;

  const overlayDiv = graph.html(
    graph.invx((500 - 320) / 2), // width of graph minus width of div
    (ymin + ymax) * 0.9, // may need to be adjusted depending on copy
    outerDivHtml
  );

  // button handler to start the game (with a countdown)
  $("#startGame").click(() => {
    overlayDiv.remove();
    overlay.remove();
    startGame(graph, level, (initialQuestionInfo) =>
      runSnakeGame(
        graph,
        winFunc,
        loseFunc,
        level,
        INITIAL_MOVE_INTERVAL,
        initialQuestionInfo
      )
    );
  });
}

function addOverlay(graph) {
  const { xmin, xmax, ymin, ymax } = graph.bounds;
  const overlay = graph.rect(
    xmin + 0.9,
    ymax - 0.9,
    xmax - xmin - 1.8,
    ymax - ymin - 1.8,
    {
      strokeWidth: 0,
      fill: colors["dm-brand-blue-800"],
      rx: 8,
      fillOpacity: 0.6,
    }
  );
  return overlay;
}

/**
 * Renders the initial prompt and the first set of answers on the screen
 * @param graph instance of DeltaGraph
 * @param level integer > 0 representing the user's level
 * @return an object with 3 properties: snakeArray, problem and answerPositions
 */
function initialQuestionSetUp(graph, level) {
  const snakeArray = snakeSetUp();
  const problem = generateProblem(level);
  processPrompt(problem.questionPrompt, problem.question);
  const answerPositions = processQuestion(snakeArray, problem.choices, graph);
  return {
    snakeArray,
    problem,
    answerPositions,
  };
}

/**
 * Generates the initial positions of the snake on the screen
 * @return an array of arrays representing the snake's initial positions on the grid
 */
function snakeSetUp() {
  // start snake facing up in the middle, tail 1 away from bottom
  const snakeArray = [[Math.round(GRID_SIZE / 2), 4]];
  for (let i = 1; i < SNAKE_LENGTH; i++) {
    // start bended to give more room
    if (i <= 3) {
      snakeArray.push([
        snakeArray[0][0],
        snakeArray[snakeArray.length - 1][1] - 1,
      ]);
    } else {
      snakeArray.push([
        snakeArray[snakeArray.length - 1][0] - 1,
        snakeArray[snakeArray.length - 1][1],
      ]);
    }
  }
  return snakeArray;
}

/**
 * Displays prompt
 * @param questionPrompt string (with possible latex)
 * @param question string that should be rendered as math (possibly undefined)
 */
function processPrompt(questionPrompt, question = "") {
  const prompt = questionPrompt + `\\(${question}\\)`;
  $("#live-question-prompt").html(prompt);
  typeset("live-question-prompt");
}

/**
 * Generates answers on the grid for the snake to eat
 * @param snakeArray an array of arrays representing the snake's initial positions on the grid
 * @param choices an array of 4 strings representing the 4 answer choices
 * @param graph instance of DeltaGraph
 * @return an array of 4 objects, each object has the reference to the text node, the rectangle node, the choice string for that answer and the positions of the answer
 */
function processQuestion(snakeArray, choices, graph) {
  const answerPositions = [];
  const unallowedPositions = snakeArray.slice();
  choices.forEach((choice) => {
    const choiceStr = choice + "";
    let failure, positions;
    do {
      failure = false;
      positions = []; // of this one choice (taking up several boxes)
      for (let i = 0; i < choiceStr.length; i++) {
        let x, y;
        if (positions.length === 0) {
          x = rand(0, GRID_SIZE - choiceStr.length);
          y = rand(0, GRID_SIZE - choiceStr.length);
        } else {
          x = positions[positions.length - 1][0] + 1;
          y = positions[positions.length - 1][1]; // horizontal across
        }
        // don't allow any unallowedPositions OR any position of any choice to equal the x or y value of the head (so it won't crash imminently into it)
        const distance = (pt1, pt2) => {
          return Math.sqrt(
            Math.pow(pt1[0] - pt2[0], 2) + Math.pow(pt1[1] - pt2[1], 2)
          );
        };
        if (
          unallowedPositions.find((pos) => pos[0] === x && pos[1] === y) ||
          distance([x, y], snakeArray[0]) <= 5
        ) {
          // don't put anything within 5 units of the head
          failure = true;
          continue;
        }
        positions.push([x, y]);
      }
    } while (failure);
    positions.forEach((pos) => unallowedPositions.push(pos)); // cannot have choices appear over each other either
    const rectRef = graph.rect(
      positions[0][0],
      positions[0][1] + 1,
      positions.length,
      1,
      {
        fill: "url('#dm-linear-gradient')",
        rx: "12",
        strokeWidth: 1,
        stroke: colors["dm-brand-blue-500"],
      }
    );
    const textRef = graph.text(
      positions[0][0] + positions.length / 2,
      positions[0][1] + 0.5,
      choiceStr,
      {
        align: "mm",
        class: "font-sans font-bold tracking-widest",
        fill: colors.white,
      }
    );

    answerPositions.push({
      choice,
      positions,
      rectRef,
      textRef,
    });
  });
  return answerPositions;
}

/** Starts the game
 * @param graph DeltaGraph instance
 * @param winFunc callback to execute upon winning the game
 * @param loseFunc callback to execute upon losing the game
 * @param level integer representing the user's level (problems assigned will change)
 * @param moveInterval integer representing the speed in ms
 * @param initialQuestionInfo an object with 3 properties: snakeArray, problem and answerPositions - representing the initial question on the screen before the snake is drawn
 */
function runSnakeGame(
  graph,
  winFunc,
  loseFunc,
  level,
  moveInterval,
  initialQuestionInfo
) {
  // destructure the initial question currently on the screen
  let snakeArray, problem, answerPositions;
  ({ snakeArray, problem, answerPositions } = initialQuestionInfo);

  let question,
    choices,
    correctChoice,
    questionPrompt,
    badNavigationLoss,
    solution;
  let toWinRemaining = NUM_CORRECT_TO_WIN;

  choices = problem.choices;
  correctChoice = problem.choices[0];
  questionPrompt = problem.questionPrompt;
  question = problem.question ? problem.question : "";
  solution = problem.solution;

  const drawSquare = (coord) => {
    const padding = 0.02;
    return graph.polygon(
      [
        [coord[0] + padding, coord[1] + padding],
        [coord[0] + padding, coord[1] + 1 - padding],
        [coord[0] + 1 - padding, coord[1] + 1 - padding],
        [coord[0] + 1 - padding, coord[1] + padding],
      ],
      {
        fill: "url('#dm-linear-gradient')",
        strokeWidth: 0.5,
        stroke: colors["dm-brand-blue-100"],
      }
    );
  };

  let snakeRefs = []; // an array same size as snakeArray of the square references to remove them each frame
  for (let i = 0; i < snakeArray.length; i++) {
    snakeRefs.push(drawSquare(snakeArray[i]));
  }

  function generateQuestion() {
    toWinRemaining--;
    if (level !== 10 && toWinRemaining === 0) return "win";
    // determine a win for collatz (level 10)
    if (level === 10 && choices && Number(choices[0]) === 1) return "win";

    const problem = generateProblem(level, choices ? choices[0] : undefined);
    choices = problem.choices;
    correctChoice = problem.choices[0];
    questionPrompt = problem.questionPrompt;
    question = problem.question ? problem.question : "";
    solution = problem.solution;
  }

  function processQuestionRefresh() {
    if (generateQuestion() === "win") {
      gameWin();
      return;
    }
    processPrompt(questionPrompt, question);
    clearAnswers();
    answerPositions = processQuestion(snakeArray, choices, graph);
  }

  let xDir = 0; // one must be 0 at all times
  let yDir = 1; // up to start

  const randValue = Math.floor(Math.random() * Math.pow(10, 15));
  const randCirc = graph.circle(-1000000, 0, 1);
  randCirc.attr("rand", randValue); // attach a rand to the dom to confirm if this global keydown should be off-ed
  let pendingMoves = []; // a quick up, left should register even if both pressed before the UP can happen
  // note to engineer: might have been easier to just register keystrokes, rather than potential direction changes, which is based off of the previous direction

  const moveFunc = (direction) => {
    if (checkDomDestroyed()) return;
    if (gameOver) return;
    const [xDirPrev, yDirPrev] = pendingMoves.length
      ? pendingMoves[pendingMoves.length - 1]
      : [xDir, yDir];
    if (direction === "left" && yDirPrev) {
      pendingMoves.push([-1, 0]); // xDir shall be set to -1 and yDir shall be set to 0
    } else if (direction === "right" && yDirPrev) {
      pendingMoves.push([1, 0]);
    } else if (direction === "down" && xDirPrev) {
      pendingMoves.push([0, -1]);
    } else if (direction === "up" && xDirPrev) {
      pendingMoves.push([0, 1]);
    }
  };

  const keydownFunc = (e) => {
    switch (e.key) {
      case "ArrowLeft":
        moveFunc("left");
        break;
      case "ArrowRight":
        moveFunc("right");
        break;
      case "ArrowDown":
        moveFunc("down");
        break;
      case "ArrowUp":
        moveFunc("up");
        break;
      default:
        break;
    }

    e.preventDefault(); // prevent scrolling
  };

  const swipeFunction = (e) => {
    if (e.detail.directions.left) moveFunc("left");
    else if (e.detail.directions.right) moveFunc("right");
    else if (e.detail.directions.bottom) moveFunc("down");
    else if (e.detail.directions.top) moveFunc("up");
  };

  // key functions for game
  $(window).on("keydown.snakeMove" + randValue, keydownFunc);

  // swipe functions for mobile (add only if the user is detected as using a mobile device)
  const isTouchDevice = window.is_touch_device();
  const snakeCanvas = document.getElementById("snake-game-canvas");
  let listener;
  if (isTouchDevice) {
    listener = SwipeListener(snakeCanvas);
    snakeCanvas.addEventListener("swipe", swipeFunction);
    snakeCanvas.classList.add("touch-none");
  }

  const removeTouchSettings = () => {
    if (isTouchDevice) {
      snakeCanvas?.removeEventListener("swipe", swipeFunction);
      snakeCanvas?.classList?.remove("touch-none");
      listener?.off();
    }
  };

  let correctCount = 0;

  let lengthRemainingToAdd = 0; // when snake eats something, this gets set to 2-3, which stops "popping" off the back as many times
  const snakeIntervalRef = setInterval(() => {
    if (checkDomDestroyed()) return;
    if (gameOver) return;
    if (pendingMoves.length) {
      [xDir, yDir] = pendingMoves.shift();
    }
    const newHead = [snakeArray[0][0] + xDir, snakeArray[0][1] + yDir];
    checkForSymbol(newHead);
    checkForGameOver(newHead);
    if (!gameOver) {
      snakeArray.unshift(newHead);
      snakeRefs.unshift(drawSquare(newHead));
      if (lengthRemainingToAdd) {
        // don't pop for a bit to extend snake length
        lengthRemainingToAdd--;
      } else {
        snakeArray.pop(); // no need for that point anymore (theoretically it "moved" to 2nd to last)
        snakeRefs.pop().remove(); // remove tail end from DOM
      }
    }
  }, moveInterval);

  function checkForSymbol(newHead) {
    const [x, y] = newHead;
    for (const choicePositions of answerPositions) {
      if (
        choicePositions.positions.find((pos) => pos[0] === x && pos[1] === y)
      ) {
        // found an intersection
        if (choicePositions.choice !== correctChoice) {
          choicePositions.rectRef.attr({
            fill: colors["error-500"],
            stroke: colors["error-500"],
          }); // change incorrect answer background to red
          gameLose("incorrect");
        } else {
          // correct, get the next question
          lengthRemainingToAdd += LENGTH_TO_ADD; // TODO: vary based on question symbol length
          correctCount += 1;
          processQuestionRefresh();
        }
      }
    }
  }

  function checkForGameOver(newHead) {
    if (
      snakeArray
        .slice(1)
        .find((pos) => pos[0] === newHead[0] && pos[1] === newHead[1])
    ) {
      // ate self
      badNavigationLoss = true;
      gameLose("hitSelf");
    } else if (
      newHead[0] < 0 ||
      newHead[1] < 0 ||
      newHead[0] >= GRID_SIZE ||
      newHead[1] >= GRID_SIZE
    ) {
      // touched wall
      badNavigationLoss = true;
      gameLose("hitWall");
    }
  }

  let gameOver = false;
  function gameWin() {
    gameOver = true;
    $("#live-question-prompt").html("");
    removeTouchSettings();

    const { xmin, xmax, ymin, ymax } = graph.bounds;
    // overlay (rather than clear screen)
    graph.rect(xmin + 0.95, ymax - 0.95, xmax - xmin - 1.9, ymax - ymin - 1.9, {
      strokeWidth: 0,
      fill: colors["dm-brand-blue-100"],
      rx: 8,
    });

    addWinGameText(graph);
    winFunc(moveInterval);
  }

  function gameLose(loseReason) {
    gameOver = true;
    answerPositions[0].rectRef.attr({
      fill: colors["success-500"],
      stroke: colors["success-500"],
    }); // assumes that the correct choice is the first element in the array, changes background color to green
    if (solution) {
      solution("#live-solution");
      typeset("live-solution");
    }

    loseFunc({ snakeNumCorrect: correctCount, snakeLoseReason: loseReason });

    removeTouchSettings();

    setTimeout(() => {
      const overlay = addOverlay(graph);

      const { htmlRef, button } = addLoseGameText(graph);

      // event handler to restart the game
      button.click(() => {
        // revert back to active game styles
        htmlRef.remove();
        clearScreen();
        overlay.remove();
        // start the game
        startGame(graph, level, (initialQuestionInfo) =>
          runSnakeGame(
            graph,
            winFunc,
            loseFunc,
            level,
            badNavigationLoss && moveInterval < 250
              ? moveInterval + 5
              : moveInterval,
            initialQuestionInfo
          )
        );
      });
    }, 3000);
  }

  function clearScreen() {
    clearAnswers();
    clearSnake();
    $("#live-solution").html("");
    $("#live-question-prompt").html("");
  }

  function clearAnswers() {
    answerPositions?.forEach((answer) => {
      answer.rectRef?.remove();
      answer.textRef?.remove();
    });
  }

  function clearSnake() {
    snakeRefs?.forEach((ref) => ref.remove());
  }

  function checkDomDestroyed() {
    // "new problem hit" or otherwise game is gone. stop intervals and keydown events on the window
    if ($('circle[rand="' + randValue + '"]').length === 0) {
      $(window).off("keydown.snakeMove" + randValue);
      removeTouchSettings();
      if (snakeIntervalRef) clearTimeout(snakeIntervalRef);
      return true;
    }
    return false;
  }
}

/** Script that executes the snake game
 * @param winFunc callback to execute upon winning the game
 * @param loseFunc callback to execute upon losing the game
 * @param numWins integer >= 0 representing the number of times the user has won the game
 */
export function snakeScript(winFunc, loseFunc, numWins) {
  const level = numWins === 0 ? 1 : Math.floor(numWins / 5) + 1;
  initialGameSetUp(winFunc, loseFunc, level);
}
