import clsx from "clsx";
import { Link, useParams } from "react-router-dom";
import slugify from "slugify";
import { REACT_APP_ADMIN_LINK } from "../../../utils";

export default function SchoolTabs({
  pendingTeacherInvites,
  teachers,
  admins,
}: {
  pendingTeacherInvites: any;
  teachers: any;
  admins: any;
}) {
  const params = useParams();

  const tabs = [
    {
      name: "Current Teachers",
      roles: ["district", "super_district", "school", "super_school"],
    },
    {
      name: "Invite Teachers",
      roles: ["district", "super_district", "school", "super_school"],
    },
    {
      name: "Current Admins",
      roles: ["district", "super_district", "school", "super_school"],
    },
    {
      name: "Invite Admins",
      roles: ["district", "super_district", "school", "super_school"],
    },
  ];

  const getCount = (tabName: string) => {
    let theCount = undefined;
    if (tabName === "Current Teachers") {
      theCount = teachers.length > 0 ? teachers.length : undefined;
    } else if (tabName === "Invite Teachers") {
      theCount =
        pendingTeacherInvites.length > 0
          ? pendingTeacherInvites.length
          : undefined;
    } else if (tabName === "Current Admins") {
      theCount = admins.length > 0 ? admins.length : undefined;
    }

    return theCount ? (
      <span
        className={
          "ml-3 hidden rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-900 md:inline-block"
        }
      >
        {theCount}
      </span>
    ) : undefined;
  };

  return (
    <>
      <div className="px-6">
        <nav
          className="-mb-px flex justify-between sm:justify-normal sm:space-x-20"
          aria-label="Tabs"
        >
          {tabs.map((tab) => (
            <Link
              key={tab.name}
              to={`${REACT_APP_ADMIN_LINK}/schools/${
                params.school_id
              }/${slugify(tab.name, {
                lower: true,
              })}`}
              replace
              className={clsx(
                slugify(tab.name, { lower: true }) === params.selected_tab
                  ? "border-dm-brand-blue-500"
                  : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                "border-b-4 px-1 py-4 text-center text-sm font-medium"
              )}
              aria-current={
                slugify(tab.name, { lower: true }) === params.selected_tab
                  ? "page"
                  : undefined
              }
            >
              {tab.name}
              {getCount(tab.name)}
            </Link>
          ))}
        </nav>
      </div>
    </>
  );
}
