import { oxfordCommaJoin } from "../../../utils/oxfordCommaJoin";
import { useCancellationContext } from "./CancellationContext";
import { CancelStepHeader } from "./CancelStepHeader";

export const DiscountOffer: React.FC = () => {
  const { discount, selectedLearnerNames, totalSeats } =
    useCancellationContext();

  const features = [
    ["Expertly Crafted", "Courses designed by experienced educators."],
    [
      "Personalized Learning",
      "Practice tailored to your learner's unique needs.",
    ],
    [
      "Boosted Confidence",
      "Watch their self-belief soar as they master new skills.",
    ],
    ["Stress-Free Learning", "Effective methods, no more homework struggles."],
    ["Engaging & Fun", "Spark a love for math with exciting activities."],
    ["Stay Informed", "Track their progress in real-time."],
    ["Learn Anytime, Anywhere", "Flexibility for busy families."],
    ["Peace of Mind", "Know your learner is getting the support they deserve."],
  ];

  const selectedLearnerNamesStr = oxfordCommaJoin(
    selectedLearnerNames.map((fullName) => `${fullName}'s`)
  );

  return (
    <div className="flex flex-col gap-8">
      <CancelStepHeader
        title={
          discount?.numMonths === 1
            ? `${discount?.percentOff}% Off Your Next ${discount?.numMonths} Month!`
            : `${discount?.percentOff}% Off Your Next ${discount?.numMonths} Months!`
        }
        subtitle={
          totalSeats > 1 && totalSeats === selectedLearnerNames.length
            ? `Keep ${selectedLearnerNamesStr} math progresses going strong.`
            : "Keep your learner's math progress going strong."
        }
        banner="Limited Time Offer"
      />
      <div className="flex flex-col gap-4">
        <p>
          We know things change. But before you go, give your learner the gift
          of continued math success at an unbeatable price.
        </p>
        <ul className="flex flex-col gap-2 sm:gap-4">
          {features.map(([title, description]) => (
            <li key={title} className="flex items-start gap-[10px]">
              <i className="far fa-check mt-1 !text-dm-brand-blue-500" />
              <span>
                <strong>{title}:</strong> {description}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
