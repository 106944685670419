import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDMQuery } from "../../utils";
import { formatNumber, dateFormatOptions } from "../utils";
import DeltaMathAccordion from "../../shared/DeltaMathAccordion";
import { useDeltaToastContext } from "../../shared/contexts/ToasterContext";

export interface EstimateDataInterface {
  quoteNumber: string;
  billingAddress: string;
  billingCity: string;
  billingState: string;
  billingZip: string;
  email: string;
  lineItems: Record<string, unknown>;
  dateCreated: string;
  lastUpdateTime: string;
  totalQuotePrice: string;
}

export const EstimateInfo = ({
  number = 0,
  setQuickbooksData,
  setQuickbooksInvoiceData,
}: {
  number?: number;
  setQuickbooksData?: any;
  setQuickbooksInvoiceData?: any;
}) => {
  const params = useParams();
  const quoteNumber = number || params.quoteNumber;
  const toastContext = useDeltaToastContext();

  const [updated, setUpdated] = useState(false);
  const [createdByEmployee, setCreatedByEmployee] = useState(false);
  const [invoiceId, setInvoiceId] = useState();
  const [estimateData, setEstimateData] = useState({
    quoteNumber: "",
    txnId: "",
    billingAddress: "",
    billingCity: "",
    billingState: "",
    billingZip: "",
    email: "",
    customerMemo: "",
    linkedTxn: { txnId: "", txnType: "" },
    lineItems: [
      {
        product: "",
        description: "",
        quantity: "",
        unitPrice: "",
        totalProductPrice: "",
        status: "",
      },
    ],

    dateCreated: "",
    lastUpdateTime: "",
    totalQuotePrice: "",
  });

  const { data } = useDMQuery({
    path: `/manager_new/quickbooks/estimate/${quoteNumber}`,
    customerServiceHeader: true,
    cacheKey: ["estimate", `${params.quoteNumber}`],
    queryOptions: {
      enabled: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      onError: (error: {
        code: number;
        statusText: string;
        message: string;
      }) => {
        toastContext.addToast({
          status: "Error",
          message: error.message,
        });
      },
    },
  });

  useEffect(() => {
    if (data && setQuickbooksData) {
      setQuickbooksData(data);
    }
  }, [data]);

  const {
    data: invoiceData,
    refetch: refetchInvoice,
    remove: removeInvoice,
  } = useDMQuery({
    path: `/manager_new/quickbooks/invoice/${invoiceId}`,
    customerServiceHeader: true,
    queryOptions: {
      enabled: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: 1000 * 60 * 5,
      onError: (error: {
        code: number;
        statusText: string;
        message: string;
      }) => {
        toastContext.addToast({
          status: "Error",
          message: error.message,
        });
      },
    },
  });

  useEffect(() => {
    setEstimateData({
      quoteNumber: "",
      txnId: "",
      billingAddress: "",
      billingCity: "",
      billingState: "",
      billingZip: "",
      email: "",
      customerMemo: "",
      linkedTxn: { txnId: "", txnType: "" },
      lineItems: [
        {
          product: "",
          description: "",
          quantity: "",
          unitPrice: "",
          totalProductPrice: "",
          status: "",
        },
      ],

      dateCreated: "",
      lastUpdateTime: "",
      totalQuotePrice: "",
    });
  }, []);

  useEffect(() => {
    if (invoiceData && setQuickbooksInvoiceData) {
      setQuickbooksInvoiceData(invoiceData);
    }
  }, [invoiceData]);

  useEffect(() => {
    if (invoiceId) {
      refetchInvoice();
    }
  }, [invoiceId]);

  useEffect(() => {
    removeInvoice();
    setQuickbooksInvoiceData({});

    if (data && data?.MetaData?.CreateTime !== data.MetaData?.LastUpdatedTime) {
      setUpdated(true);
    }
    if (data && data.LinkedTxn && data.LinkedTxn[0].TxnType === "Invoice") {
      setInvoiceId(data.LinkedTxn[0].TxnId);
    }
    if (data && data.BillEmail?.Address?.includes("@deltamath.com")) {
      setCreatedByEmployee(true);
    }
    const products =
      data &&
      Array.isArray(data.Line) &&
      data.Line.map((line: any) => {
        if (line?.LineNum)
          return {
            product: line?.SalesItemLineDetail?.ItemRef?.name,
            description: line?.Description,
            quantity: line?.SalesItemLineDetail?.Qty,
            unitPrice: line?.SalesItemLineDetail?.UnitPrice,
            totalProductPrice: line?.Amount,
            status: line?.TxnStatus,
          };
        else return;
      });
    data &&
      setEstimateData({
        quoteNumber: data.DocNumber,
        txnId: data.Id || "",
        linkedTxn: data.LinkedTxn || [],
        billingAddress: data.BillAddr?.Line1,
        billingCity: data.BillAddr?.City,
        billingState: data.BillAddr?.CountrySubDivisionCode,
        billingZip: data.BillAddr?.PostalCode,
        email: data.BillEmail?.Address,
        customerMemo: data.CustomerMemo?.value,
        lineItems: products,
        dateCreated:
          (data.MetaData?.CreateTime &&
            new Date(data.MetaData.CreateTime).toLocaleDateString(
              "en-us",
              dateFormatOptions
            )) ||
          "",
        lastUpdateTime:
          (data.MetaData?.LastUpdatedTime &&
            new Date(data.MetaData.LastUpdatedTime).toLocaleDateString(
              "en-us",
              dateFormatOptions
            )) ||
          "",
        totalQuotePrice: data?.TotalAmt,
      });
  }, [data]);

  return (
    <div>
      {!estimateData && (
        <div className="w-full">
          <p>No Quickbooks estimate found.</p>
        </div>
      )}
      {estimateData && (
        <>
          <>
            <div className="w-full">
              {updated && (
                <div className="flex text-red-700">
                  <svg
                    className="mr-2 mt-1 h-4 w-4 fill-current text-red-700"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                  </svg>
                  This Estimate has been updated since it was first created.
                </div>
              )}
              {createdByEmployee && (
                <div className="mt-2 flex text-red-700">
                  <svg
                    className="mr-2 mt-1 h-4 w-4 fill-current text-red-700"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                  </svg>
                  This Estimate was created by a DeltaMath employee.
                </div>
              )}

              {estimateData.billingAddress && (
                <div className="mb-4 mt-4">
                  <h3 className="font-bold">Billing Address:</h3>
                  <div>{estimateData.billingAddress}</div>
                  <div>
                    {estimateData.billingCity} {estimateData.billingState}{" "}
                    {estimateData.billingZip}
                  </div>
                </div>
              )}

              <table className="mt-4 w-full">
                <thead>
                  <tr>
                    {invoiceData && <th>Invoice #</th>}
                    <th className="">Date Created</th>
                    <th className="">Customer Email</th>
                    <th className="">Total</th>
                    <th className="">Last Updated</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {invoiceData && <td>{invoiceData.DocNumber}</td>}
                    <td>{estimateData.dateCreated}</td>
                    <td>{estimateData.email}</td>
                    <td>
                      ${" "}
                      {estimateData.totalQuotePrice &&
                        formatNumber(parseInt(estimateData.totalQuotePrice))}
                    </td>
                    <td>{estimateData.lastUpdateTime}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>

          <DeltaMathAccordion
            title={<strong>View Estimate Details</strong>}
            renderExpanded={true}
            showExpand={true}
            content={
              <>
                <table className="w-full table-fixed border-gray-300">
                  <thead className="mt-2">
                    <tr>
                      <th className="text-left">Product</th>
                      <th className="pl-4 text-left">Description</th>
                      <th className="pr-4 text-right">Quantity</th>
                      <th className="pr-4 text-right">Price</th>
                      <th className="pr-4 text-right">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {estimateData &&
                      Array.isArray(estimateData.lineItems) &&
                      estimateData.lineItems.map((item) => {
                        if (item)
                          return (
                            <tr className="" key={item.product}>
                              <td className="border-r text-center">
                                {item.product}
                              </td>
                              <td className="border-r pl-4">
                                {item.description}
                              </td>
                              <td className="mt-0 border-r pr-4 text-right">
                                {item.quantity}
                              </td>
                              <td className="mt-0 border-r pr-4 text-right">
                                {formatNumber(parseInt(item.totalProductPrice))}
                              </td>
                              <td className="">{item.status}</td>
                            </tr>
                          );
                        else return;
                      })}
                  </tbody>
                </table>
                <div className="mt-8 inline-flex">
                  <div className="mr-24">
                    <h3 className="font-bold">
                      Message displayed on estimate:
                    </h3>
                    <div>{estimateData.customerMemo}</div>
                  </div>
                </div>
              </>
            }
          />
        </>
      )}
    </div>
  );
};

export default EstimateInfo;
