import { HTMLAttributes, ReactNode, Ref, forwardRef } from "react";
import clsx from "clsx";

interface IconButtonProps extends HTMLAttributes<HTMLButtonElement> {
  icon: ReactNode;
  "aria-label": string;
}

export const IconButton = forwardRef(
  (
    { icon, className, ...props }: IconButtonProps,
    ref: Ref<HTMLButtonElement>
  ) => (
    <button
      ref={ref}
      className={clsx("disabled:cursor-not-allowed", className)}
      {...props}
    >
      <span aria-hidden={true}>{icon}</span>
    </button>
  )
);

IconButton.displayName = "IconButton";
