import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import CalculatorRow from "./CalculatorRow";
import AlphabeticalKeyboard from "./AlphabeticalKeyboard";
import NumericalKeyboard from "./NumericalKeyboard";
import KeyboardControls from "./KeyboardControls";
import FunctionsKeyboard from "./FunctionsKeyboard";
import { XIcon } from "@heroicons/react/outline";
import FracModal from "./FracModal";
import clsx from "clsx";
const dmKAS = (window as any).dmKAS;

export type AnswerData = {
  latex: string;
  prevLatex: string;
  currentAnswer: string;
  currentAnswerFull: number;
  prevAnswer: string;
  prevAnswerFull: number;
};

export const evaluate = (latex: string) => {
  return dmKAS.valCalculator(latex);
};

const DMKeyboard = ({
  close,
  input,
  focusedInput,
  showCalculator,
  answerData,
  setAnswerData,
  showKeyboard,
  handleGlobalFocus,
  withSidebar = true,
  disableScroll = false,
  centerKeyboard = false,
  learner = false,
  globalInputsMap,
}: {
  close: () => void;
  input?: any;
  focusedInput: any;
  showCalculator: boolean;
  answerData: AnswerData;
  setAnswerData: Dispatch<SetStateAction<AnswerData>>;
  showKeyboard: boolean;
  handleGlobalFocus: (mqID: any) => void;
  withSidebar?: boolean;
  disableScroll?: boolean;
  centerKeyboard?: boolean;
  learner?: boolean;
  globalInputsMap?: Map<any, any>;
}) => {
  const [showNumeric, setShowNumeric] = useState<boolean>(true);
  const [showFuncs, setShowFuncs] = useState<boolean>(false);
  const [showRad, setShowRad] = useState<boolean>(false);
  const [shift, setShift] = useState<boolean>(false);
  const [showFrac, setShowFrac] = useState(false);

  const inputsRef = useRef<Map<string, any> | null>(input || null);
  const keyboardRef = useRef<HTMLDivElement | null>(null);

  /* Math Input Field Event Handler */
  const handleChange = (mq: any): void => {
    const inputStr = mq.latex();
    parseAnswer(inputStr);
  };

  const parseAnswer = (latex: string) => {
    const original = latex;
    let fullAnswer = dmKAS.valCalculator(latex);
    if (!latex) {
      return;
    }
    try {
      const prevValue = isNaN(answerData.prevAnswerFull)
        ? "0"
        : answerData.prevAnswerFull;

      latex = latex.replace(/\\operatorname\{ans\}/gi, "(" + prevValue + ")");
    } catch (e) {
      setAnswerData({
        ...answerData,
        currentAnswer: "...",
        currentAnswerFull: 0,
      });
      fullAnswer = 0;
    }
    try {
      dmKAS.setMode(showRad ? "radian" : "degree");
      fullAnswer = dmKAS.valCalculator(latex);
    } catch (e) {
      setAnswerData({
        ...answerData,
        currentAnswer: "...",
        currentAnswerFull: 0,
      });
      return;
    }
    if (isNaN(fullAnswer)) {
      setAnswerData({
        ...answerData,
        currentAnswer: "...",
        currentAnswerFull: 0,
      });
      return;
    }
    setAnswerData({
      ...answerData,
      latex: original,
      currentAnswer: displayAnswer(fullAnswer),
      currentAnswerFull: fullAnswer,
    });
  };

  useEffect(() => {
    /** update dmKAS mode and recalculate answer on Radian/Degree selection */
    if (showRad) {
      dmKAS.setMode("radian");
    } else dmKAS.setMode("degree");
    if (
      input &&
      focusedInput &&
      focusedInput === "math-input-0" &&
      input.get(focusedInput)
    ) {
      handleChange(input.get(focusedInput));
    }
  }, [showRad]);

  useEffect(() => {
    if (!focusedInput && input) {
      handleGlobalFocus(input.keys().next().value);
    }
  }, []);

  /* Scroll focused element into view when keyboard is toggled on */
  useEffect(() => {
    if (!disableScroll && showKeyboard && focusedInput && !showCalculator) {
      const el = document.getElementById(focusedInput);
      if (!el) return;
      const doc = document.documentElement;
      const docTop =
        (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
      const topToKeyboard =
        doc.clientHeight -
        (keyboardRef?.current?.getBoundingClientRect().height || 180);
      const scrollDis = el.getBoundingClientRect().bottom + 72 - topToKeyboard;
      window.scrollTo({
        top: scrollDis > 0 ? docTop + scrollDis : docTop,
        behavior: "smooth",
      });
    }
  }, [showKeyboard, focusedInput]);

  useEffect(() => {
    if (!showCalculator) {
      setAnswerData({
        ...answerData,
        currentAnswer: "",
        currentAnswerFull: 0,
      });
    }
  }, [showCalculator]);

  const isVisible = (target: HTMLElement) => {
    return !!(
      target.offsetWidth ||
      target.offsetHeight ||
      target.getClientRects().length
    );
  };

  const handleEnter = () => {
    if (answerData.currentAnswer !== "...") {
      const target = document.getElementById("submit-answer-form");
      if (target && isVisible(target) && focusedInput !== "math-input-0") {
        target.click();
      } else {
        setAnswerData({
          ...answerData,
          currentAnswer: "",
          currentAnswerFull: 0,
          latex: "",
          prevLatex: answerData.latex,
          prevAnswer: answerData.currentAnswer,
          prevAnswerFull: answerData.currentAnswerFull,
        });
        input.get(focusedInput).latex("");
      }
    }
    /** also want to add a row above the input row showing the previous expression */
  };

  const handleClose = () => {
    setAnswerData({
      ...answerData,
      currentAnswer: "",
      currentAnswerFull: 0,
    });
    close();
  };

  return (
    <div
      id="dm-keyboard"
      ref={keyboardRef}
      className={clsx(
        "fixed bottom-0 right-0 z-10 w-full rounded-lg bg-gray-200 shadow-md",
        withSidebar && !learner && "lg:w-3/4 3xl:w-[calc(100%-448px)]",
        learner && "w-full lg:w-[calc(100%-23rem)]"
      )}
    >
      {answerData.currentAnswerFull ? (
        <FracModal
          ansToShow={answerData.currentAnswer}
          decimal={answerData.currentAnswerFull}
          open={showFrac}
          setOpen={() => setShowFrac(!showFrac)}
        />
      ) : (
        <></>
      )}
      {showCalculator && (
        <div className="left-0 top-0">
          <CalculatorRow
            ref={(node: any) => {
              if (!inputsRef.current) inputsRef.current = new Map();
              const map = inputsRef.current;
              if (node) {
                map.set("math-input-0", node);
              } else {
                map.delete("math-input-0");
              }
            }}
            handleEnter={handleEnter}
            handleChange={handleChange}
            answerData={answerData}
            setAnswerData={setAnswerData}
            handleGlobalFocus={handleGlobalFocus}
            globalInputsMap={globalInputsMap}
          />
        </div>
      )}
      <div
        className={clsx(
          "relative py-2",
          centerKeyboard && "flex justify-center"
        )}
      >
        <div
          className={clsx(
            showCalculator
              ? answerData.prevLatex !== ""
                ? "-top-32"
                : "-top-20"
              : "-top-8",
            "absolute right-0 mr-2 sm:top-2"
          )}
        >
          <button onClick={() => handleClose()} aria-label="Close keyboard.">
            <XIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        {showNumeric ? (
          <div
            className={clsx(
              "relative flex max-w-[800px] grid-cols-3 justify-center space-x-6 sm:mx-12 sm:my-0 sm:grid-cols-4 sm:space-x-6",
              centerKeyboard && "grow"
            )}
          >
            {showFuncs ? (
              <>
                <FunctionsKeyboard
                  shift={shift}
                  showRad={showRad}
                  input={input}
                  focusedInput={focusedInput}
                  showFuncs={showFuncs}
                  showFrac={showFrac}
                  setShowFuncs={setShowFuncs}
                  setShift={setShift}
                  setShowNumeric={setShowNumeric}
                  setShowRad={setShowRad}
                  setShowFrac={setShowFrac}
                />
              </>
            ) : (
              <NumericalKeyboard
                shift={shift}
                input={input}
                focusedInput={focusedInput}
                setShift={setShift}
                setShowNumeric={setShowNumeric}
                previousAnswer={parseFloat(answerData.prevAnswer)}
                showFuncs={showFuncs}
                setShowFuncs={setShowFuncs}
                handleEnter={handleEnter}
              />
            )}
            <div className="col col-span-1 w-[19%]">
              <KeyboardControls
                input={input}
                focusedInput={focusedInput}
                handleEnter={handleEnter}
              />
            </div>
            {/* <div className="col col-span-1"></div> */}
          </div>
        ) : (
          <div className="sm:mx-32">
            <AlphabeticalKeyboard
              shift={shift}
              input={input}
              focusedInput={focusedInput}
              setShift={setShift}
              setShowNumeric={setShowNumeric}
              handleEnter={handleEnter}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default DMKeyboard;

function displayAnswer(num: number): string {
  if (num === undefined) return "";
  let result: number | string = parseFloat(num.toPrecision(10));
  const absNum = Math.abs(num);
  if (absNum < Math.pow(10, 10) && absNum >= Math.pow(10, -1)) {
    // number between 0.1 and 1000
    return result.toString();
  } else if (absNum >= Math.pow(10, 21)) {
    // number very big
    return "overflow";
  } else if (absNum <= Math.pow(10, -13)) {
    // number very close to 0
    return "0";
  } else if (absNum < Math.pow(10, -1)) {
    // num between 0 and 0.1 -- use scientific notation at 6 decimal places
    if (absNum > Math.pow(10, -6)) {
      return (result + "").substring(0, 11 + (num < 0 ? 1 : 0));
    } else {
      result = num.toExponential() + "";
      const allowedLength = 12 + (num < 0 ? 1 : 0);
      if (result.length > allowedLength) {
        // must truncate
        const pos = result.search("e");
        const end = result.substring(pos);
        return result.substring(0, allowedLength - end.length) + end;
      } else return result;
    }
  }
  const log = Math.floor(Math.log(Math.abs(result)) / Math.log(10));
  const str = result + "";
  let start;
  let substr;
  if (num < 0) {
    start = str.substring(0, 2);
    substr = str.substring(2, 6);
  } else {
    start = str.substring(0, 1);
    substr = str.substring(1, 6);
  }
  let i: number;
  for (i = substr.length - 1; i > 0; i--) {
    if (substr[i] !== "0") break;
  }
  return start + "." + substr.substring(0, i + 1) + "e" + log;
}
