import { useMutation } from "react-query";
import { AppTimersInput } from "../types";
import { deltamathAPI } from "../../manager/utils";
import axios from "axios";
import { useCallback, useEffect } from "react";
import {
  TIMER_LOCALSTORAGE_APP_KEY,
  TIMER_LOCALSTORAGE_BASE_COURSE_KEY,
  TIMER_LOCALSTORAGE_ROOT_KEY,
} from "../constants";

const TIMER_SAVE_INTERVAL = 60 * 1000; // 1 minute

function getAppTimersFromLocalStorage(): {
  payload: AppTimersInput;
  keysToRemove: string[];
} {
  const payload: AppTimersInput = {
    app: 0,
    courses: {},
  };
  const keysToRemove: string[] = [];

  Object.entries(localStorage).forEach(([key, value]) => {
    if (key.startsWith(TIMER_LOCALSTORAGE_ROOT_KEY)) {
      const { elapsed } = JSON.parse(value);
      if (key.endsWith(TIMER_LOCALSTORAGE_APP_KEY)) {
        payload.app = elapsed / 1000;
        keysToRemove.push(key);
      } else if (key.includes(TIMER_LOCALSTORAGE_BASE_COURSE_KEY)) {
        const courseIdStr = key.split(":").pop();
        if (courseIdStr && courseIdStr !== "null") {
          payload.courses[courseIdStr] = elapsed / 1000;
          try {
            keysToRemove.push(key);
          } catch (e) {
            // ignore
          }
        }
      }
    }
  });

  return { payload, keysToRemove };
}

/**
 * Mutation that periodically updates app and course timers based on
 * current timer localStorage values
 */
export function useAppTimersMutation(onSaveTimers?: () => void) {
  const { mutateAsync } = useMutation((body: AppTimersInput) => {
    return axios.post(
      `${deltamathAPI()}/learner/data/time`,
      JSON.stringify(body),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  });

  const saveTimers = useCallback(async () => {
    const { payload, keysToRemove } = getAppTimersFromLocalStorage();

    // No elapsed values were found
    if (keysToRemove.length === 0) {
      return;
    }

    try {
      await mutateAsync(payload);
      onSaveTimers?.();
      keysToRemove.forEach((key) => {
        localStorage.removeItem(key);
      });
    } catch (e) {
      // Let the timer continue running and try again later
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutateAsync]);

  useEffect(() => {
    const interval = setInterval(() => {
      saveTimers();
    }, TIMER_SAVE_INTERVAL);

    return () => clearInterval(interval);
  }, [saveTimers]);
}
