import { useState } from "react";
import { ResponsiveImage } from "../ResponsiveImage";
import { getFilePath } from "../../../utils";
import { PageFooter } from "../Layouts/PageFooter";
import LoginFormSwitcher from "./LoginFormSwitcher";

type Props = {
  setLoggedIn: (loggedIn: boolean) => void;
};

const Login = (props: Props) => {
  const params = new URLSearchParams(window.location.search);
  const reset = params.get("reset");

  const [pageState, setPageState] = useState<
    "login" | "forgot" | "reset" | "resetSent"
  >(reset ? "reset" : "login");

  const imageFiles =
    pageState === "login" || pageState === "reset"
      ? [
          "/images/learner/login/deltie-thumbs-up.png",
          "/images/learner/login/deltie-thumbs-up@2x.png",
        ]
      : pageState === "forgot"
      ? ["/images/learner/login/deltie-upset.png"]
      : ["/images/learner/login/deltie-jump-wave.png"];

  return (
    <div className="relative flex min-h-screen w-full flex-col pb-6">
      <section className="relative flex w-full flex-col items-center bg-[length:200px_200px] md:mb-16">
        <header className="w-full px-4 pb-3 pt-4 md:px-14 md:pb-6 md:pt-6">
          <ResponsiveImage
            srcs={[getFilePath("/images/DeltaMath-Logo_Home.svg")]}
            alt="DeltaMath Home"
          />
        </header>
      </section>

      <LoginFormSwitcher
        setLoggedIn={props.setLoggedIn}
        setPageState={setPageState}
      />

      <div className="absolute bottom-0 right-0 mb-14 h-52 md:mb-0 md:h-80 [@media(max-height:720px)]:hidden">
        <ResponsiveImage
          srcs={imageFiles.map((f) => getFilePath(f))}
          alt="Deltie smiling with thumbs up"
          className="h-full"
        />
      </div>
      <PageFooter />
    </div>
  );
};

export default Login;
