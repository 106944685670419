import ReactPlayer from "react-player";
import { useUserContext } from "../../shared/contexts/UserContext";

const HelpVideos = () => {
  const userContext = useUserContext();

  const helpVideos = [
    {
      title: "Introduction & Onboarding",
      description: "Manage teacher and admins on your license.",
      videoPath:
        "Admin-Portal-Onboarding/Default/HLS/Admin-Portal-Onboarding.m3u8",
      subtitlePath: "captions/Admin-Portal-Onboarding.mp4.vtt",
    },
    {
      title: "Student Usage Reports",
      description:
        "View student usage over time, compared between schools/teachers/sections.",
      videoPath:
        "admin_portal_student_usage/Default/HLS/admin_portal_student_usage.m3u8",
      subtitlePath: "captions/admin_portal_student_usage.mp4.vtt",
    },
    {
      title: "Student Performance Reports",
      description:
        "View detailed student performance data by school/teacher/section.",
      videoPath:
        "admin_portal_student_performance_reports/Default/HLS/admin_portal_student_performance_reports.m3u8",
      subtitlePath: "captions/admin_portal_student_performance_reports.mp4.vtt",
    },
    {
      title: "Diagnostics and Shared Assessments",
      description:
        "Create diagnostics and/or shared assessments by pushing assignments to specific schools/teachers/sections.",
      videoPath:
        "admin_portal_pushed_assignments/Default/HLS/admin_portal_pushed_assignments.m3u8",
      subtitlePath: "captions/admin_portal_pushed_assignments.mp4.vtt",
    },
  ];
  return (
    <div className="p-6">
      <div className="m-6 mb-16 text-center">
        <p className="text-4xl font-semibold text-dm-light-blue">
          Admin Portal Help Videos
        </p>
      </div>
      <div className="grid grid-cols-1 gap-12 md:grid-cols-2">
        {userContext.getJWT() &&
          helpVideos.map((video) => (
            <>
              <div className="flex flex-col justify-between gap-6">
                <div className="flex flex-col gap-6">
                  <h5 className="text-xl font-medium text-dm-light-blue">
                    {video.title}
                  </h5>
                  <p>{video.description}</p>
                </div>
                <ReactPlayer
                  url={`https://videos.deltamath.com/${video.videoPath}`}
                  controls={true}
                  style={{
                    maxWidth: "100%",
                  }}
                  height="100%"
                  config={{
                    file: {
                      hlsOptions: {
                        forceHLS: true,
                      },
                      attributes: { crossOrigin: "anonymous" },
                      tracks: [
                        {
                          label: "English",
                          kind: "captions",
                          src: `https://videos.deltamath.com/${video.subtitlePath}`,
                          srcLang: "en",
                          default: false,
                        },
                      ],
                    },
                  }}
                />
              </div>
            </>
          ))}
      </div>
    </div>
  );
};

export default HelpVideos;
