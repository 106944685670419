import clsx from "clsx";
import { Learner } from "../../../types";
import { shortOrdinal } from "../../../utils/shortOrdinal";
import { DeltieIcon } from "./DeltieIcon";

export const MultiLearnerHeader: React.FC<{
  numLearners: number;
  learners: Learner[];
}> = ({ numLearners, learners }) => (
  <div className="mb-8 w-full overflow-auto sm:mb-14">
    <div className="relative mt-[6px] inline-flex min-w-full basis-auto justify-between gap-6 p-4">
      <div className="absolute -top-[6px] left-0 flex h-[6px] w-full basis-auto justify-start bg-dm-brand-blue-100 sm:rounded-full">
        <div
          className="h-full rounded-r-full bg-dm-brand-blue-500 transition-all sm:rounded-l-full"
          style={{
            width: `${Math.round(
              ((learners.length + 1) / numLearners) * 100
            )}%`,
          }}
        />
      </div>

      {Array(numLearners)
        .fill(null)
        .map((_, i) =>
          learners[i] ? (
            <div key={learners[i]._id} className="flex h-[50px] gap-3">
              <div className="flex h-6 w-6 flex-none items-center justify-center rounded-full bg-dm-purple-500">
                <i className="far fa-check text-sm !text-white" />
              </div>
              <div className="flex flex-col whitespace-nowrap">
                <span className="font-serif text-lg font-bold text-dm-brand-blue-600">
                  {shortOrdinal(i + 1)} learner
                </span>
                <span className="text-sm text-dm-gray-500">
                  {learners[i].first} {learners[i].last}
                </span>
              </div>
            </div>
          ) : (
            <div
              key={i}
              className={clsx(
                "flex h-[50px] items-center justify-center gap-2 whitespace-nowrap rounded-lg px-4 py-2 font-serif font-bold",
                i === learners.length
                  ? "bg-dm-brand-blue-100/50 text-dm-brand-blue-600"
                  : "text-dm-gray-200"
              )}
            >
              <DeltieIcon opacity={i === learners.length ? 1 : 0.5} />
              {shortOrdinal(i + 1)} learner
            </div>
          )
        )}
    </div>
  </div>
);
