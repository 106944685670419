import { PropsWithChildren } from "react";

export const WarningBanner: React.FC<
  PropsWithChildren<{
    bannerTitle?: string;
    bannerText?: string;
  }>
> = ({ bannerTitle, bannerText, children }) => {
  return (
    <div className="w-[1288px] max-w-full rounded-lg border border-dm-warning-800 bg-dm-warning-100 p-4">
      <i
        aria-hidden
        className="far fa-exclamation-triangle mr-3 text-dm-warning-800"
      />
      {bannerTitle && <strong>{bannerTitle} </strong>}
      {bannerText && <span>{bannerText} </span>}
      {children}
    </div>
  );
};
