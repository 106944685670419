import clsx from "clsx";
import { getFilePath } from "../../../utils";
import Accordion from "../Accordion";
import { ResponsiveImage } from "../ResponsiveImage";
import { SectionTitle } from "./SectionTitle";
import { useState } from "react";
import { MAX_CONTAINER_WIDTH } from "./constants";
import { useHomepageSignupContext } from "./HomepageSignupContext";

export const Faq: React.FC = () => {
  const { plans } = useHomepageSignupContext();

  const [activeQs, setActiveQs] = useState<number[]>([]);
  const faqItems: { title: string; body: string | React.ReactNode }[] = [
    {
      title: "Who can benefit from DeltaMath for Home?",
      body: "DeltaMath for Home is designed for learners of all levels. Whether you're looking to catch up, get ahead, or simply strengthen your math skills, our personalized practice and engaging activities provide an effective learning solution.",
    },
    {
      title: "What grade levels does DeltaMath for Home cover? ",
      body: "DeltaMath for Home offers content for middle school, high school, and college-level math.",
    },
    {
      title: "What's the difference between DeltaMath for Home and DeltaMath?",
      body: "DeltaMath for Home is a subscription service for individual learners and families to access anytime, anywhere. DeltaMath is our classroom version used by teachers and students in school.",
    },
    {
      title: "How does DeltaMath for Home personalize the learning experience?",
      body: "Our platform uses pre-quizzes to assess your current understanding and create targeted practice. This ensures you focus on areas where you need the most help, making your learning more efficient.",
    },
    {
      title: "What if my child needs help with a problem?",
      body: "DeltaMath for Home provides videos, examples and step-by-step solutions to help your child understand and solve problems independently.",
    },
    {
      title: "Can I track my child's progress on DeltaMath for Home?",
      body: "Yes! Parents have a dedicated dashboard to see detailed reports on their child's progress, including quiz scores, practice time, and areas for improvement.",
    },
    {
      title: "Is a parent account included in the subscription? ",
      body: "Yes, each subscription includes a parent account at no extra cost. This account is automatically created and linked to your learner’s account, giving you access to track their progress and view the Parent Dashboard.",
    },
    {
      title: "Can I use DeltaMath for Home without a parent account?",
      body: "Yes. Learners can create their own accounts and use DeltaMath for Home independently.",
    },
    {
      title: "Is DeltaMath for Home aligned with school curriculum standards?",
      body: "Our courses are broadly aligned with widely adopted curriculum standards, complementing and reinforcing what your child is learning in school.",
    },
    {
      title: "Is there a free trial available?",
      body: !plans
        ? 'loading...'
        :  plans[0].trialLength > 0
          ? `Yes, we offer a ${plans[0].trialLength}-day free trial so
          you can experience the benefits of DeltaMath for Home before being
          charged for your subscription.`
          : 'Sorry, we do not offer a free trial at this time',
    },
    {
      title: "How can I get in touch with DeltaMath for Home customer support?",
      body: (
        <>
          Our dedicated customer support team is here to help! You can reach us
          via email at{" "}
          <a
            href="mailto:home@deltamath.com"
            className="text-dm-brand-blue-500 underline"
          >
            home@deltamath.com
          </a>
          .
        </>
      ),
    },
  ];

  return (
    <section
      className={clsx(
        "relative w-full px-4 md:px-6 xl:px-0",
        MAX_CONTAINER_WIDTH
      )}
    >
      <SectionTitle
        title="Your Questions, Answered"
        subtitle=" Find answers to common questions about DeltaMath for Home"
      />

      <div className="overflow-hidden rounded-lg border-2 border-dm-brand-blue-500">
        {faqItems.map((faq, index) => (
          <Accordion
            key={faq.title}
            isOpen={activeQs.includes(index)}
            onToggle={() => {
              if (activeQs.includes(index)) {
                setActiveQs(activeQs.filter((i) => i !== index));
              } else {
                setActiveQs([...activeQs, index]);
              }
            }}
            buttonClasses={clsx(
              "px-8 py-5 rounded-none text-left",
              index > 0 && "border-t border-dm-brand-blue-500",
              activeQs.includes(index) && "bg-dm-brand-blue-100"
            )}
            titleButton={<h5 className="font-bold">{faq.title}</h5>}
            panel={<p className="px-8 py-5">{faq.body}</p>}
          />
        ))}
      </div>

      <ResponsiveImage
        className="absolute -left-40 -top-28 hidden md:block"
        srcs={[
          getFilePath("/images/learner/home/notebook.png"),
          getFilePath("/images/learner/home/notebook@2x.png"),
        ]}
        alt="Notebook with 'A+' on the front"
      />
      <ResponsiveImage
        className="absolute -left-4 -top-10 hidden lg:block xl:-left-8"
        srcs={[
          getFilePath("/images/learner/home/target.png"),
          getFilePath("/images/learner/home/target@2x.png"),
        ]}
        alt="Target with arrows hitting the bullseye"
      />
    </section>
  );
};
