import { displayInlineMath } from "../../utils";

export type CommonButtonProps = {
  btnText: string | any;
  btnCmd?: boolean;
  btnOutput?: string;
  inputsRef?: React.MutableRefObject<any>;
  focusedInput: string | null;
  ansType?: string | number;
  id?: string;
  className?: string;
  btnAction?: boolean;
};

export default function CommonButton({
  btnText,
  btnCmd,
  btnOutput,
  inputsRef,
  focusedInput,
}: CommonButtonProps): JSX.Element {
  /* Event handler to insert latex from common buttons to correct math input field */
  const handleCommonBtnClick = (btnLatex: string) => {
    if (!inputsRef) return;

    if (!inputsRef.current) inputsRef.current = new Map();
    const map = inputsRef.current;
    const node = focusedInput ? map.get(focusedInput) : map.get("1");

    if (!node) {
      return;
    }
    // replace all parentheses and square brackets with correct latex
    btnLatex = btnLatex
      .replaceAll("(", "\\left(")
      .replaceAll(")", "\\right)")
      .replaceAll("[", "\\left[")
      .replaceAll("]", "\\right]");

    if (btnCmd) {
      // use the command mq method
      node.cmd(btnLatex);
    } else {
      // account for special buttons
      if (btnLatex.includes(",")) {
        // such as [,] for interval notation
        node.write(btnLatex).keystroke("Left Left");
      } else if (btnLatex.includes("cuberoot")) {
        node
          .cmd("nthroot")
          .typedText("3")
          .keystroke("Tab")
          .keystroke("Left Right");
      } else if (btnLatex.includes("nthroot")) {
        node.cmd("nthroot").keystroke("Left Right");
      } else if (btnLatex.includes("logbase")) {
        node.write("log_{}").keystroke("Tab").keystroke("Left");
      } else if (btnLatex.includes("sqrt")) {
        node.cmd(btnLatex);
      } else {
        // all other latex
        node.write(btnLatex);
      }
    }
    node.focus();
  };

  return (
    <button
      type="button"
      name={btnText}
      className="inline-flex items-center rounded-md border border-transparent bg-blue-100 px-3 py-2 text-sm font-medium leading-4 text-dm-dark-blue hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
      onClick={() => {
        const btnLaTeX = btnOutput !== undefined ? btnOutput : btnText;
        handleCommonBtnClick(btnLaTeX);
      }}
    >
      {displayInlineMath(btnText)}
    </button>
  );
}
