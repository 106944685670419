import { useParams } from "react-router-dom";
import {
  SearchResult,
  SkillResult,
  useCourseSearchResults,
  useAllCoursesSearchResults,
} from "./useCourseSearchResults";
import { useEffect, useRef, useState } from "react";
import { REACT_APP_LEARNER_LINK, unreachableCase } from "../../../utils";
import clsx from "clsx";
import { SearchConfirmationModal } from "./SearchConfirmationModal";
import { useCourseContext } from "../../contexts/CourseContext";
import { SearchResultItem } from "./SearchResultItem";
import { SearchResultTitle } from "./SearchResultTitle";
import { HighlightText } from "./HighlightText";
import { useLearnerAnalytics } from "../../analytics/useLearnerAnalytics";
import { searchEvent, searchResultClickedEvent } from "../../analytics/events";

interface SearchResultsContainerProps {
  id: string;
  query: string;
}

interface SearchResultsProps extends SearchResultsContainerProps {
  results: SearchResult[];
}

export const AllCoursesSearchResults: React.FC<SearchResultsContainerProps> = (
  props
) => {
  const results = useAllCoursesSearchResults(props.query);

  return <SearchResults results={results} {...props} />;
};

export const CourseSearchResults: React.FC<SearchResultsContainerProps> = (
  props
) => {
  const results = useCourseSearchResults(props.query);

  return <SearchResults results={results} {...props} />;
};

const SearchResults: React.FC<SearchResultsProps> = ({
  id,
  query,
  results,
}) => {
  const resultsContainer = useRef<HTMLUListElement>(null);
  const { coursePath } = useParams();
  const courseContext = useCourseContext();
  const courseData = courseContext.getCourseData(coursePath);

  const [activeSkillResult, setActiveSkillResult] =
    useState<SkillResult | null>(null);

  const { track } = useLearnerAnalytics();

  useEffect(() => {
    if (resultsContainer.current) {
      resultsContainer.current.scrollTop = 0;
    }
  }, [query]);

  useEffect(() => {
    if (query.trim().length) {
      track(
        searchEvent({
          searchTerm: query,
          numResults: results.length,
        })
      );
    }
    // Only want this to run when the results change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results]);

  const trackSearchResultClick = () => {
    track(searchResultClickedEvent({ searchTerm: query }));
  };

  const handleSkillClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    result: SkillResult
  ) => {
    trackSearchResultClick();
    if (!result.practiceInitialized) {
      e.preventDefault();
      setActiveSkillResult(result);
    }
  };

  if (query.length === 0) {
    return null;
  }

  return (
    <div
      id={id}
      className={clsx(
        results.length === 0 && "pb-2",
        "absolute top-full z-20 mt-2 flex w-full flex-col rounded-lg border bg-white pt-4 shadow-lg"
      )}
    >
      <h4
        className="mb-2 px-4"
        aria-label={`${results.length} results found for ${query}`}
        aria-live="polite"
      >
        {results.length} {results.length === 1 ? "result for " : "results for "}
        <strong>
          <em>&quot;{query}&quot;</em>
        </strong>
      </h4>

      {results.length > 0 && (
        <ul
          className="flex max-h-96 flex-col gap-2 overflow-auto px-4 pb-4 pt-2 text-sm"
          ref={resultsContainer}
        >
          {results.map((result) => {
            switch (result.kind) {
              case "unit":
                return (
                  <SearchResultItem
                    key={result.unitId}
                    to={`${REACT_APP_LEARNER_LINK}/${coursePath}/${result.unitPath}`}
                    onClick={trackSearchResultClick}
                  >
                    <SearchResultTitle>
                      Unit {result.unitIndex}:{" "}
                      <HighlightText text={result.unitName} query={query} />
                    </SearchResultTitle>
                  </SearchResultItem>
                );
              case "subunit":
                return (
                  <SearchResultItem
                    key={result.subunitId}
                    to={`${REACT_APP_LEARNER_LINK}/${coursePath}/${result.unitPath}/${result.subunitPath}`}
                    onClick={trackSearchResultClick}
                  >
                    <SearchResultTitle>
                      Unit {result.unitIndex}: {result.unitName}
                    </SearchResultTitle>
                    <p>
                      Section {result.subunitIndex}:{" "}
                      <HighlightText text={result.subunitName} query={query} />
                    </p>
                  </SearchResultItem>
                );
              case "skill":
                return (
                  <SearchResultItem
                    key={`${result.subunitId}-skill-${result.skillIndex}`}
                    to={`${REACT_APP_LEARNER_LINK}/${coursePath}/${result.unitPath}/${result.subunitPath}/practice/${result.skillIndex}`}
                    onClick={(e) => handleSkillClick(e, result)}
                  >
                    <SearchResultTitle>
                      Unit {result.unitIndex}: {result.unitName}
                    </SearchResultTitle>
                    <p>
                      Section {result.subunitIndex}: {result.subunitName}
                    </p>
                    <p>
                      Skill:{" "}
                      <HighlightText text={result.skillName} query={query} />
                    </p>
                  </SearchResultItem>
                );
              case "course":
                return (
                  <SearchResultItem
                    key={`${result.courseName}`}
                    to={`${REACT_APP_LEARNER_LINK}/course/${result.coursePath}`}
                    onClick={trackSearchResultClick}
                  >
                    {result.location === "course" ? (
                      <>
                        <SearchResultTitle>
                          <HighlightText
                            text={result.queryMatch}
                            query={query}
                          />
                        </SearchResultTitle>
                      </>
                    ) : (
                      <>
                        <SearchResultTitle>
                          {result.courseName}
                        </SearchResultTitle>
                        <p>
                          Features{" "}
                          <HighlightText
                            text={result.queryMatch}
                            query={query}
                          />
                        </p>
                      </>
                    )}
                  </SearchResultItem>
                );
              default:
                return unreachableCase(result);
            }
          })}
        </ul>
      )}

      <SearchConfirmationModal
        result={activeSkillResult}
        courseId={courseData?.id}
        onClose={() => setActiveSkillResult(null)}
      />
    </div>
  );
};
