import { cloneDeep } from "lodash";
import { useCourseContext } from "../contexts/CourseContext";
import { useLearnerContext } from "../contexts/LearnerContext";
import { myCourseDataType } from "../types";

/**
 * Calculates the course test progress and adds it to a new array of course progress.
 * Used primarily to calculate total course progress and correctly display the course radial.
 * Returns an object with three properties: isFirstAttemptComplete, isInProgress and currentCourseData. The first two are referring to the course test, the third is a copy of the course progress, including the course test.
 */
export function useCurrentCourseData(courseID?: string) {
  const courseContext = useCourseContext();
  const learnerContext = useLearnerContext();
  const courseProgress = learnerContext.getProgress(courseID || "");

  const currentCourses = courseContext.getCurrentLearnerCourses(
    courseID,
    learnerContext
  );

  const isCurrentTestSubmitted =
    courseProgress?.courseTest !== undefined &&
    courseProgress?.courseTest?.assignment?.submitted !== undefined;
  const isFirstAttemptComplete =
    isCurrentTestSubmitted ||
    !!courseProgress?.courseTest?.assignment?.resultHist;
  const isInProgress =
    courseProgress?.courseTest !== undefined &&
    !isCurrentTestSubmitted &&
    !courseProgress?.courseTest?.assignment?.solutionSeen;

  // If progress is 0 but course test has been started, set progress
  // to a small amount so progress pie chart shows it in progress
  const courseTestProgress =
    courseProgress?.courseTest && courseProgress?.courseTest?.progress === 0
      ? 0.01
      : courseProgress?.courseTest?.progress;

  const currentCourseData: myCourseDataType | undefined = cloneDeep(
    currentCourses.find((course: myCourseDataType) => course.id === courseID)
  );

  currentCourseData?.units?.push({
    id: `course-test-${courseID}`,
    path: "coursetest",
    name: "course-test",
    progress: isFirstAttemptComplete ? 1 : courseTestProgress || 0,
  });

  return {
    isFirstAttemptComplete,
    isInProgress,
    currentCourseData,
  };
}
