import { useLearnerContext } from "../../contexts/LearnerContext";
import { Skeleton } from "../Skeleton";
import { LearnerProfileBeta } from "./Beta/LearnerProfileBeta";
import { IndependentLearnerProfile } from "./IndependentLearnerProfile";
import { LearnerProfile } from "./LearnerProfile";
import { ProfilePage } from "./ProfilePage";

export const LearnerProfileSwitcher: React.FC = () => {
  const { learner } = useLearnerContext();
  const isIndependentLearner = learner?.entitlements.includes("billing");

  if (!learner)
    return (
      <ProfilePage>
        <div className="flex h-[528px] w-[1288px] max-w-full flex-col gap-8 rounded-2xl border border-dm-charcoal-100 bg-white p-6">
          <Skeleton />
        </div>
      </ProfilePage>
    );

  return learner.enableSoftLaunch ? (
    isIndependentLearner ? (
      <IndependentLearnerProfile user={learner} />
    ) : (
      <LearnerProfile user={learner} />
    )
  ) : (
    <LearnerProfileBeta />
  );
};
