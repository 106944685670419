import { useState } from "react";
import Button from "../../../student/components/generic/button";
import { useDMQuery } from "../../../utils";
import { DmLoadingSpinner } from "../../utils/functions";
import { CreatePlansModal } from "./CreatePlansModal";
import { SubscriptionPlansTable } from "./SubscriptionPlansTable";
import { LearnerSubscriptionPlan } from "../../../learner/types";
import { useMutation } from "react-query";
import axios from "axios";
import { deltamathAPI } from "../../utils";
import { withJsonHeader } from "../../../shared/axiosUtils";
import { BasicContentContainer } from "../BasicContentContainer";
import { useDocumentTitle } from "usehooks-ts";

export const SubscriptionPlansPage = () => {
  useDocumentTitle("Subscription Plans");
  const [showCreatePlansModal, setShowCreatePlansModal] = useState(false);
  const {
    data: plansData,
    refetch: refetchPlans,
    isLoading,
  } = useDMQuery<{ plans: LearnerSubscriptionPlan[] }>({
    path: "/payments/subscriptions/plans/list/all",
    queryOptions: {
      staleTime: 1000 * 60 * 5, // 5 minutes
      refetchOnWindowFocus: false,
    },
  });

  const hasDraftPlans = plansData?.plans.some((plan) => plan.state === "draft");

  const { mutateAsync: publishPlans } = useMutation(() => {
    return axios.post(
      `${deltamathAPI()}/payments/subscriptions/plans/publish`,
      {},
      withJsonHeader()
    );
  });

  const handlePublish = async () => {
    const message =
      "Are you sure you want to publish the current draft plans? This will make them visible to learners.";
    if (window.confirm(message)) {
      await publishPlans();
      refetchPlans();
    }
  };

  const findPlan = (
    interval: LearnerSubscriptionPlan["interval"],
    state: LearnerSubscriptionPlan["state"]
  ) => {
    return plansData?.plans.find(
      (p) => p.interval === interval && p.state === state
    );
  };

  return (
    <div className="flex flex-col gap-6 p-6">
      <header>
        <h1 className="mb-2 text-2xl font-bold">Subscription Plans</h1>
        <ul className="text-sm text-dm-charcoal-500">
          <li>
            <b>Draft:</b> These plans have been created but are not yet visible
            to learners
            <ul className="ml-3 list-inside list-disc">
              <li>
                There will only ever be two draft plans at one
                time&mdash;monthly and annual
              </li>
              <li>Creating new drafts will archive existing drafts</li>
            </ul>
          </li>
          <li>
            <b>Published:</b> These plans are currently visible to learners
            <ul className="ml-3 list-inside list-disc">
              <li>
                There will only ever be two published plans at one
                time&mdash;monthly and annual
              </li>
              <li>
                Publishing will archive the current published plans and make the
                drafts visible
              </li>
            </ul>
          </li>
          <li>
            <b>Archived:</b> These plans are no longer visible to learners
          </li>
        </ul>
      </header>
      <div className="flex gap-4">
        <Button
          className="flex w-auto items-center gap-3 !px-4"
          size="small"
          disabled={isLoading}
          onClick={() => setShowCreatePlansModal(true)}
        >
          <i className="fas fa-plus" />
          Create new draft plans
        </Button>
        <Button
          className="flex w-auto items-center gap-3 bg-dm-charcoal-500 !px-4 hover:bg-dm-charcoal-600 disabled:hover:bg-dm-charcoal-500"
          size="small"
          disabled={!hasDraftPlans}
          onClick={handlePublish}
        >
          <i className="fas fa-arrow-alt-circle-up" />
          Publish current draft plans
        </Button>
      </div>
      {isLoading && (
        <BasicContentContainer>
          <DmLoadingSpinner message="" />
        </BasicContentContainer>
      )}
      {plansData && <SubscriptionPlansTable plans={plansData.plans} />}
      {plansData && (
        <CreatePlansModal
          key={plansData.plans.length}
          visible={showCreatePlansModal}
          currentMonthlyPlan={
            findPlan("monthly", "published") || findPlan("monthly", "draft")
          }
          currentAnnualPlan={
            findPlan("annual", "published") || findPlan("annual", "draft")
          }
          onClose={() => setShowCreatePlansModal(false)}
          onPlansCreated={() => {
            refetchPlans();
            setShowCreatePlansModal(false);
          }}
        />
      )}
    </div>
  );
};
