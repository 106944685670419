import { Transition } from "@headlessui/react";
import clsx from "clsx";
import { Fragment, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { REACT_APP_LEARNER_LINK, getFilePath } from "../../../utils";
import { ResponsiveImage } from "../ResponsiveImage";
import ProfileButton from "../Sidebar/ProfileButton";
import { useOnClickOutside, useScrollLock } from "usehooks-ts";
import { useParentContext } from "../../contexts/ParentContext";
import { Learner } from "../../types";
import { InsightsButton } from "./InsightsButton";
import { useDeltaMathHomeLink } from "../../utils/useDeltaMathHomeLink";

export const ParentSidebar: React.FC = () => {
  const { learners, currentLearner, setCurrentLearnerId } = useParentContext();
  const deltaMathHomeLink = useDeltaMathHomeLink();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const mobileSidebarRef = useRef(null);
  const closeButtonHover = useRef(false);

  useOnClickOutside(mobileSidebarRef, (e) => {
    if (!closeButtonHover.current) setIsMenuOpen(false);
  });

  const { lock, unlock } = useScrollLock({
    autoLock: false,
    widthReflow: false,
  });

  const updateLearner = (learner: Learner) => setCurrentLearnerId(learner?._id);

  useEffect(() => {
    isMenuOpen ? lock() : unlock();
    return () => unlock();
  }, [isMenuOpen, lock, unlock]);

  useEffect(() => {
    setIsMenuOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href]);

  return (
    <>
      <div className="sticky inset-x-0 top-0 z-[9] flex items-center justify-between gap-x-5 border-b border-dm-charcoal-100 bg-white px-4 py-3">
        <Link
          to={deltaMathHomeLink}
          className="h-9 bg-white focus:outline-none"
        >
          <ResponsiveImage
            className="z-2 transition-width h-9 duration-300 ease-out"
            srcs={[getFilePath("/images/DeltaMath-Logo_Home.svg")]}
            alt="DeltaMath Home"
          />
        </Link>
        <button
          className="!text-dm-gray-200"
          onPointerEnter={() => (closeButtonHover.current = true)}
          onPointerLeave={() => (closeButtonHover.current = false)}
          onClick={(e) => setIsMenuOpen((prev: boolean) => !prev)}
          aria-label={!isMenuOpen ? "open sidebar menu" : "close sidebar menu"}
        >
          {!isMenuOpen ? (
            <i className="fas fa-fw fa-bars text-lg" aria-hidden="true"></i>
          ) : (
            <i
              className="fas fa-fw fa-times text-lg !text-dm-gray-200"
              aria-hidden="true"
            ></i>
          )}
        </button>
      </div>
      <Transition.Root
        show={isMenuOpen}
        as="div"
        className={clsx(
          "fixed z-[8] flex flex-col overflow-clip bg-white text-dm-charcoal-800 lg:hidden",
          "inset-y-0 left-0 border border-r border-dm-charcoal-100"
        )}
      >
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-900/80" />
        </Transition.Child>
        <div
          className="fixed bottom-0 left-0 top-14 flex w-full bg-white p-2 md:max-w-80"
          ref={mobileSidebarRef}
        >
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-150 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <nav
              className="relative flex w-full flex-1 flex-col pt-5"
              aria-label="Sidebar"
            >
              <div className="-mx-2 flex flex-grow flex-col justify-start gap-2 overflow-auto px-2">
                {currentLearner &&
                  learners.length > 0 &&
                  learners.map((learner: Learner) => (
                    <InsightsButton
                      key={learner._id}
                      learner={learner}
                      currentLearner={currentLearner}
                      onClick={() => {
                        updateLearner(learner);
                        setIsMenuOpen(false);
                      }}
                    />
                  ))}
              </div>
              <div className="flex shrink-0 flex-col border-t border-dm-charcoal-100 bg-white pb-2 pt-4">
                <ProfileButton
                  link={`${REACT_APP_LEARNER_LINK}/parent/profile`}
                />
              </div>
            </nav>
          </Transition.Child>
        </div>
      </Transition.Root>
    </>
  );
};
