import Button from "../../../../student/components/generic/button";
import { useCreateAssignmentMutation } from "../../../utils";
import { getFilePath } from "../../../../utils";
import { ResponsiveImage } from "../../ResponsiveImage";

type Props = {
  courseId: string;
  unitId: string;
  subunitId: string;
  skillsCount: number | undefined;
  estimatedTime: number;
  prequiz100: boolean;
};

const PostQuizLanding = (props: Props) => {
  const createAssignmentMutation = useCreateAssignmentMutation();

  const initializeProgressForCourse = () => {
    createAssignmentMutation(
      "postQuiz",
      props.courseId,
      props.unitId,
      props.subunitId
    );
  };

  return (
    <div className="flex flex-grow flex-col items-center justify-center gap-2 rounded-lg border border-dm-charcoal-100 bg-white px-4 py-6 max-sm:m-4">
      <ResponsiveImage
        className="mx-auto h-auto w-56"
        srcs={[
          getFilePath("/images/learner/postquiz/start-postquiz.png"),
          getFilePath("/images/learner/postquiz/start-postquiz@2x.png"),
        ]}
        alt=""
        aria-hidden="true"
      />
      <h4 className="mt-6 font-serif text-2xl">
        {props.prequiz100
          ? "You aced it! Ready for a bonus challenge?"
          : "Ready for the post-quiz?"}
      </h4>
      <p className="max-w-2xl">
        {props.prequiz100
          ? "This post-quiz isn't required, but it's a great way to level up your skills. Want to dive in and see what else you can conquer?"
          : "Time to show what you know and put your skills to the test! This quiz will check your understanding of this section. Score a 70% or higher to complete the section. If you don't pass on the first try, you can review your answers and try again."}
      </p>
      {props.skillsCount && (
        <p className="font-bold">
          {props.skillsCount} Questions | {props.estimatedTime} Minutes
          (estimated)
        </p>
      )}
      <div className="flex flex-col items-center gap-y-4 pt-4">
        <Button onClick={initializeProgressForCourse}>Start Post Quiz</Button>
      </div>
    </div>
  );
};

export default PostQuizLanding;
