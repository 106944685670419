import { useState } from "react";
import { SearchInput } from "./SearchInput";
import { CourseSearchResults, AllCoursesSearchResults } from "./SearchResults";
import { SearchType } from "../../types";

export const CourseSearch: React.FC<{ type: SearchType }> = ({ type }) => {
  const [query, setQuery] = useState("");
  const id = "search-results";
  return (
    <div className="relative w-full">
      <SearchInput resultsId={id} onQuery={(q) => setQuery(q)} type={type} />
      {type === "course" ? (
        <CourseSearchResults id={id} query={query} />
      ) : (
        <AllCoursesSearchResults id={id} query={query} />
      )}
    </div>
  );
};
