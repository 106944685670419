import clsx from "clsx";
import { ReactNode } from "react";

type Props = {
  message?: string | ReactNode;
  children: ReactNode;
  options?: {
    aligned?: "default" | "center";
    inline?: boolean;
    raise?: string;
    tooltipRight?: boolean;
  };
  className?: string;
};

/**
 * @deprecated for accessibility purposes - use Infotip for showing more information in an overlay, use TooltipButton for showing more information in an overlay AND when the button needs to trigger an action
 */
export const Tooltip = (props: Props) => {
  return (
    <div className={`inline-block ${props.className}`}>
      <div
        className={`group relative ${
          props.options && props.options.inline ? "inline" : "flex flex-col"
        } ${
          !props.options || props.options.aligned === "center"
            ? "items-center"
            : ""
        } overflow-visible`}
      >
        {props.children}
        {props.message && (
          <div
            className={clsx(
              props?.options?.raise ? props.options.raise : "mb-6",
              `absolute bottom-0 z-50 hidden flex-col items-center whitespace-nowrap group-hover:flex`,
              props.options?.tooltipRight ? "left-4 top-0" : ""
            )}
          >
            <span className="relative z-50 whitespace-nowrap rounded-md bg-dm-charcoal-600 p-2 text-xs leading-5 text-white shadow-lg">
              {props.message}
            </span>
            <div className="z-50 -mt-2 h-3 w-3 rotate-45 bg-dm-charcoal-600"></div>
          </div>
        )}
      </div>
    </div>
  );
};
