import { useEffect, useState, useRef } from "react";
import clsx from "clsx";

type Props = {
  children: React.ReactNode;
  additionalClasses?: string;
  fadeClass?: string;
  threshold?: number;
};

export default function FadeInSection(props: Props): JSX.Element {
  const [isVisible, setVisible] = useState(true);
  const domRef: any = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const current = domRef.current;
    if (!current) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          setVisible(entry.isIntersecting);
        });
      },
      { threshold: props.threshold ?? 0.35 }
    );

    observer.observe(current);

    return () => observer.unobserve(current);
  }, [domRef]);
  return (
    <div
      className={clsx(
        props?.fadeClass ?? "fade-in-section",
        isVisible ? "is-visible" : "",
        props?.additionalClasses
      )}
      ref={domRef}
    >
      {props.children}
    </div>
  );
}
