import { deterministicallyChooseLevel } from "./deterministicallyChooseLevel";
import { divisibilityRules } from "./snakeDivisibilityRules";
import { doubleTripleHalf } from "./snakeDoubleTripleHalf";
import { orderOfOperations } from "./snakeOrderOfOps";
import { simplifyFrac } from "./snakeSimplifyFrac";
import { doubleTripleHalfLevel2 } from "./snakeDoubleTripleHalfL2";
import { gcfLcm } from "./snakeGcfLcm";
import { percents } from "./snakePercents";
import { divisibilityRulesLevel2 } from "./snakeDivisibilityRulesL2";
import { benchmarkFractions } from "./snakeBenchmarkFractions";
import { collatz } from "./snakeCollatz";

const { shuffle, gcd } = window;
const NUM_LEVELS = 9;

/** Given the level, returns a problem object
 * @param level integer representing the user's level
 * @param currentValue optional, the current number - specifically for collatz
 * @return problem object with the following properties: questionPrompt (string with html), question? (string), choices (array of 4 strings representing answer choices with the correct answer always at index 0), solution function to run
 */
export function generateProblem(level, currentValue) {
  switch (level) {
    case 1:
      return doubleTripleHalf();
    case 2:
      return orderOfOperations();
    case 3:
      return simplifyFrac();
    case 4:
      return divisibilityRules();
    case 5:
      return doubleTripleHalfLevel2();
    case 6:
      return gcfLcm();
    case 7:
      return percents();
    case 8:
      return divisibilityRulesLevel2();
    case 9:
      return benchmarkFractions();
    case 10:
      return collatz(currentValue);
    default: {
      // if the user's level is not one of the cases, choose a level for the user based on the date (currently does NOT include collatz)
      const todaysLevel = deterministicallyChooseLevel(NUM_LEVELS);
      return generateProblem(todaysLevel);
    }
  }
}

export function processChoices(choices, correctAnswer) {
  shuffle(choices);
  choices = choices
    .unique()
    .filter((val) => val !== correctAnswer)
    .slice(0, 3)
    .map((val) => String(val));
  choices.unshift(String(correctAnswer));
  return choices;
}

/**
 * @param a positive integer
 * @param b positive integer
 * @return an array of all common factors of a and b, excluding 1 and the gcd
 */
export function getCommonFactors(a, b) {
  const gcdValue = gcd(a, b);
  const commonFact = [];
  for (let i = 2; i < gcdValue; i++) {
    if (gcdValue % i === 0) commonFact.push(i);
  }
  return commonFact;
}
