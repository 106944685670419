import { useEffect, useLayoutEffect, useState } from "react";
import { useLearnerContext } from "../../../contexts/LearnerContext";
import Accordion from "../../Accordion";
import { Prize } from "../../../types";
import clsx from "clsx";
import { ConfigPrizeModal } from "../../Modals/Prizes/ConfigPrizeModal";
import { ContentPrizeModal } from "../../Modals/Prizes/ContentPrizeModal";
import { ContentPrizeButton } from "./ContentPrizeButton";
import { LOCKED_PRIZE_TOOLTIP } from "../../../constants";
import { useLearnerAnalytics } from "../../../analytics/useLearnerAnalytics";
import {
  clickedPrizeCTAEvent,
  openedPrizeAccordionEvent,
} from "../../../analytics/events";

type Props = {
  prize: Prize;
  row: number;
};

export const PrizeCard: React.FC<Props> = (props) => {
  const { learner } = useLearnerContext();
  const { track } = useLearnerAnalytics();
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [showConfigPrizeModal, setShowConfigPrizeModal] =
    useState<boolean>(false);
  const [showContentPrizeModal, setShowContentPrizeModal] =
    useState<boolean>(false);

  // TODO: defaultOpen may not be needed
  const defaultOpen: boolean = 0 || false;
  useLayoutEffect(() => {
    setIsOpen(defaultOpen);
  }, [defaultOpen]);

  const isUnlocked = learner?.prizes?.includes(props.prize.key);

  const configClick = () => {
    if (!isUnlocked) {
      console.log("config click disabled");
      return;
    } else {
      setShowConfigPrizeModal(true);
    }
  };

  useEffect(() => {
    if (showConfigPrizeModal || showContentPrizeModal) {
      track(clickedPrizeCTAEvent({ prize: props.prize.key }));
    }
  }, [props.prize.key, showConfigPrizeModal, showContentPrizeModal, track]);

  return (
    <>
      <Accordion
        isOpen={isOpen}
        setIsOpen={() => {
          if (!isOpen) {
            track(openedPrizeAccordionEvent({ prize: props.prize.key }));
          }
          setIsOpen(!isOpen);
        }}
        titleButton={
          <div className="flex gap-x-6">
            <div
              className={clsx(
                "relative flex items-center justify-center rounded-lg",
                !isUnlocked && "bg-dm-gray-200"
              )}
            >
              <span
                className={clsx(
                  !isUnlocked && "brightness-[.6]",
                  "flex h-14 w-14 flex-col justify-center overflow-hidden rounded"
                )}
              >
                {props.prize.image}
              </span>
              {!isUnlocked && (
                <i className="far fa-lock absolute left-[20px] top-[18px] text-white" />
              )}
            </div>
            <div className="text-left">
              <h4 className="flex gap-x-4 font-sans text-xs font-normal text-dm-charcoal-500">
                <span>{isUnlocked ? "Unlocked" : "Locked"}</span>
                <span className="text-dm-charcoal-100">|</span>
                <span>Row {props.row}</span>
              </h4>
              <h2 className="font-serif text-lg font-bold">
                {props.prize.name}
              </h2>
            </div>
          </div>
        }
        panel={
          <div className="flex flex-col items-center gap-2 px-6 pb-6 text-left sm:flex-row">
            <p className="grow text-sm">{props.prize.description}</p>
            {props.prize.type === "content" && (
              <ContentPrizeButton
                setShowGame={setShowContentPrizeModal}
                isPrizeUnlocked={!!isUnlocked}
                prize={props.prize}
              />
            )}
            {props.prize.type === "config" && (
              <button
                onClick={configClick}
                className={clsx(
                  "shrink-0 rounded bg-dm-brand-blue-500 px-8 py-1 text-sm leading-6 text-white disabled:cursor-not-allowed disabled:opacity-50 max-sm:w-full",
                  isUnlocked
                    ? "hover:bg-dm-brand-blue-600 active:bg-dm-brand-blue-800"
                    : "cursor-not-allowed opacity-50"
                )}
                {...(!isUnlocked
                  ? {
                      "data-tip": LOCKED_PRIZE_TOOLTIP,
                      "data-for": "pointsPageTooltip",
                      "aria-disabled": true,
                    }
                  : {})}
              >
                {props.prize.configCta}
              </button>
            )}
          </div>
        }
        isSummaryPage
        wrapperClasses="!mb-0"
        buttonClasses="!px-4 md:!px-6 !py-4"
      />
      {props.prize.type === "config" && (
        <ConfigPrizeModal
          visible={showConfigPrizeModal}
          onClose={() => setShowConfigPrizeModal(false)}
          prize={props.prize}
          showConfig={true}
        />
      )}
      {props.prize.type === "content" && (
        <ContentPrizeModal
          visible={showContentPrizeModal}
          onClose={() => setShowContentPrizeModal(false)}
          prize={props.prize}
          showContent={true}
        />
      )}
    </>
  );
};
