import { getLearnerAccountStatus } from "../components/Profile/getLearnerAccountStatus";
import { Learner } from "../types";

export function getOpenSeats(totalSeats: number, learners: Learner[]) {
  const nonCancelledLearners = learners.reduce((accumulator, learner) => {
    const status = getLearnerAccountStatus(learner);
    return status === "canceled" ? accumulator : accumulator + 1;
  }, 0);

  return totalSeats - nonCancelledLearners;
}
