import { UnitLandingWrapper } from "./UnitLandingWrapper";
import { Skeleton, SkeletonCircle, SkeletonText } from "./Skeleton";

const FAKE_UNITS = [
  "Culpa laboris voluptate",
  "Ut eu non quis",
  "Dolor duis ipsum consectetur",
];

const ASSIGNMENT_TYPES = ["Pre-Quiz", "Practice", "Post-Quiz"];

export const UnitLandingLoading: React.FC = () => {
  return (
    <UnitLandingWrapper>
      <div className="mb-4 flex w-full gap-x-6 rounded-lg border border-dm-charcoal-100 bg-white px-6 py-4">
        <div className="relative flex h-12 w-12 shrink-0 items-center">
          <SkeletonCircle />
        </div>
        <div className="flex-grow">
          <SkeletonText>
            <h1 className="font-serif text-2xl font-bold">
              Waiting for the unit to load
            </h1>
          </SkeletonText>
          <div className="mt-1 text-sm">
            <SkeletonText>
              <h3>N sections</h3>
            </SkeletonText>
          </div>
          <div className="mt-1 text-base text-dm-charcoal-500">
            <SkeletonText>
              <p>
                Lorem ipsum dolor sit amet consectetur. Tellus lacus et lorem
                vulputate. Amet donec vivamus enim pulvinar blandit sapien
                blandit sit nisl. Lorem ipsum dolor.
              </p>
            </SkeletonText>
          </div>
        </div>
      </div>

      {FAKE_UNITS.map((s) => (
        <div
          key={`unit-${s}`}
          className="flex w-full flex-wrap justify-between gap-y-3 rounded-lg border border-dm-charcoal-100 bg-white px-6 py-4 md:items-center md:gap-x-2"
        >
          <div className="flex basis-full flex-col md:flex-grow md:basis-3/5">
            <div className="mb-2 text-lg font-bold text-dm-charcoal-800">
              <SkeletonText>
                <h4>{s}</h4>
              </SkeletonText>
            </div>
            <div className="h-6 py-1 md:w-80">
              <Skeleton />
            </div>
            <div className="flex">
              <SkeletonText>
                <h4 className="text-xs">NN% complete</h4>
              </SkeletonText>
            </div>
          </div>

          <div className="h-10 w-44 basis-full !leading-7 max-md:order-1 md:flex-shrink-0 md:basis-auto">
            <Skeleton />
          </div>

          <div className="flex basis-full flex-col gap-2 text-sm">
            {ASSIGNMENT_TYPES.map((a) => (
              <div
                key={`assignment-${a}`}
                className="flex items-center gap-1.5"
              >
                <div className="h-6 w-6">
                  <SkeletonCircle />
                </div>
                <SkeletonText>{a}</SkeletonText>
              </div>
            ))}
          </div>
        </div>
      ))}

      <div className="mb-2 mt-6 font-serif text-lg font-bold">
        <SkeletonText>
          <h3>Unit Test</h3>
        </SkeletonText>
      </div>

      <div className="w-full rounded-lg border border-dm-charcoal-100 bg-white px-6 py-4">
        <div className="flex items-center justify-between gap-x-2">
          <div className="flex basis-full items-center gap-2">
            <div className="h-8 w-8">
              <SkeletonCircle />
            </div>
            <SkeletonText>
              <h3 className="text-lg font-bold">Course unit text</h3>
            </SkeletonText>
          </div>
          <div className="flex basis-full gap-4 max-md:hidden md:flex-shrink-0 md:basis-auto">
            <div className="h-10 w-44 max-md:basis-full">
              <Skeleton />
            </div>
          </div>
        </div>
        <div className="my-4 flex gap-2 text-sm">
          <SkeletonText>
            <p>Loading unit test progress</p>
          </SkeletonText>
        </div>
      </div>
    </UnitLandingWrapper>
  );
};
