import { useEffect, useState } from "react";
import { useDMQuery } from "../../../utils";
import {
  GetProblem,
  LearnerAssignmentTypes,
  LogData,
  Problem,
} from "../../types";
import { obfuscate } from "../../../student/utils";
import CustomFileWrapper from "../Problem/CustomFileWrapper";
import StatusTags from "../Problem/StatusTags";
import PortalModal from "../../../shared/PortalModal";

type Props = {
  visible: boolean;
  onClose: () => void;
  questionIndex: number;
  assignmentId?: string;
  assignmentType: LearnerAssignmentTypes;
  version?: number;
  skillCode?: string;
  skillId?: string;
};

const SolutionModal = (props: Props) => {
  const [problem, setProblem] = useState<Problem>();
  const [renderKey, setRerenderKey] = useState<number>(0);
  const [logData, setLogData] = useState<LogData | undefined>();

  const { data: problemData, refetch: problemRefetch } = useDMQuery<GetProblem>(
    {
      path: `/learner/assignment/problem/${props.skillId}?assignmentId=${
        props.assignmentId
      }${props.version !== undefined ? `&version=${props.version}` : ""}`,
      queryOptions: {
        enabled: false,
      },
    }
  );

  // This may cause multiple refetches of the problem but it potentially could stop weird edge cases that have to do with problem rendering
  // and scripts running at different times
  const closeModal = () => {
    props.onClose();
    setProblem(undefined);
  };

  useEffect(() => {
    if (props.skillId && props.assignmentId && props.visible) {
      problemRefetch();
    }
  }, [props.visible, props.assignmentId, props.skillId]);

  useEffect(() => {
    if (problemData) {
      setProblem({
        ...problemData.problem,
        lines: problemData.problem.lines || [],
        data:
          typeof problemData.problem.data === "string"
            ? obfuscate(`${problemData.problem._id}`).reveal(
                `${problemData.problem.data}`
              )
            : problemData.problem.data,
      });
      setLogData(problemData.problem.log_data);
      setRerenderKey(renderKey + 1);
    }
  }, [problemData]);

  return (
    <PortalModal
      visible={props.visible}
      onClose={closeModal}
      largeModal
      title={`Solution for Question ${props.questionIndex + 1}`}
      body={
        <>
          {problem ? (
            <>
              <CustomFileWrapper
                skillId=""
                key={`renderKey-${problem._id}-${renderKey}`}
                problem={problem}
                logData={logData}
                checkAnswer={() => {
                  console.log("CHECK ANSWER");
                }}
                isCheckAnswerLoading={false}
                unsubmitAllowed={false}
                showSolutions={true}
                skippedProblem={!logData?.ans}
                renderKey={renderKey}
                inModal
                noBorder
                header={
                  <StatusTags
                    skillCode={problem.skillcode}
                    logData={logData}
                    skippedProblem={!logData?.ans}
                    questionTitle={""}
                    solutionShowing={true}
                    assignmentType={props.assignmentType}
                  />
                }
              />
            </>
          ) : (
            "Example Modal"
          )}
        </>
      }
    />
  );
};

export default SolutionModal;
