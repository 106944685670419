import Modal from "../../../student/components/generic/Modal";
import { ContactSupportForm } from "./ContactSupportForm";

export const ContactSupportModal: React.FC<{
  showModal: boolean;
  onClose: () => void;
}> = ({ showModal, onClose }) => {
  return (
    <Modal
      visible={showModal}
      onClose={onClose}
      closeX
      bodyClassName="!px-4 pb-6 !pt-12 sm:!px-10 sm:pb-8"
      title={
        <div className="text-center font-serif text-lg font-bold text-dm-brand-blue-600 sm:text-2xl">
          Contact Support
        </div>
      }
      body={
        <div className="px-2">
          <p className="mb-6 font-sans text-sm font-bold !leading-[26px]">
            We&apos;re here to help! Get in touch with our support team.
          </p>
          <ContactSupportForm onClose={onClose} />
        </div>
      }
    />
  );
};
