import { useLearnerContext } from "../../contexts/LearnerContext";
import { Course, Unit } from "../../types";
import { DMPieChart } from "../../../student/components/generic/DMPieChart";
import { getUnitProgressAriaLabel } from "../../utils/index";
import SubunitProgressSection from "./SubunitProgressSection";
import UnitTestProgressSection from "./UnitTestProgressSection";

type Props = {
  course: Course;
  unit: Unit;
  unitNumber: number;
  cardId: string;
  lastClickedId?: string;
};

const CourseProgressUnitSummaryCard = (props: Props) => {
  const learnerContext = useLearnerContext();

  const courseProgress = learnerContext.getProgress(props.unit.courseId);
  const unitProgress = courseProgress?.units.find(
    (u) => u.unitId === props.unit.id
  );

  const isCurrentTestSubmitted =
    unitProgress?.unitTest !== undefined &&
    unitProgress?.unitTest?.assignment?.submitted !== undefined;
  const isFirstAttemptComplete =
    isCurrentTestSubmitted || !!unitProgress?.unitTest?.assignment?.resultHist;

  const progressForUnitTest = isFirstAttemptComplete
    ? 1
    : !!unitProgress?.unitTest && unitProgress?.unitTest?.progress === 0
    ? 0.01
    : unitProgress?.unitTest?.progress || 0;

  const subunitsProgress = props.unit.subunitOrder?.map((suo) => {
    const subunitProg = unitProgress?.subunits.find(
      (sub) => suo === sub.subunitId
    );
    return {
      id: suo,
      progress:
        subunitProg && subunitProg?.progress === 0 && !!subunitProg.preQuiz
          ? 0.01
          : subunitProg?.progress || 0,
    };
  });

  const segments = [
    ...(subunitsProgress?.map((s) => {
      return {
        value: 1,
        color:
          s?.progress === 0
            ? "#E3E8EF"
            : s?.progress === 1
            ? "#2F52E9"
            : "#A9CCFA",
      };
    }) || []),
    {
      value: 1,
      color:
        progressForUnitTest === 0
          ? "#E3E8EF"
          : progressForUnitTest === 1
          ? "#2F52E9"
          : "#A9CCFA",
    },
  ];

  const unitProgressValue = Math.round((unitProgress?.progress || 0) * 100);

  return (
    <>
      <div id={props.cardId} className="flex gap-2">
        <div className="relative flex h-8 w-8 shrink-0 transition-all">
          {unitProgressValue !== 100 ? (
            <DMPieChart
              data={segments}
              lineWidth={21}
              paddingAngle={10}
              totalValue={segments.length}
              startAngle={273}
              className="pointer-events-none absolute"
              ariaLabel={getUnitProgressAriaLabel(subunitsProgress)}
            />
          ) : (
            <i
              className="far fa-check-circle text-[32px] leading-none !text-dm-brand-blue-500"
              aria-hidden="true"
            ></i>
          )}
        </div>
        <div>
          <div className="flex items-center gap-2">
            <h3 className="font-sans text-base font-bold leading-tight text-dm-charcoal-800 md:text-lg">
              Unit {props.unitNumber}: {props.unit.name}
            </h3>
            {unitProgressValue > 0 && unitProgressValue < 100 && (
              <span className="rounded-full bg-dm-brand-blue-500 px-2 py-1 font-sans text-xs font-normal leading-none text-white">
                {`${unitProgressValue}% complete`}
              </span>
            )}
          </div>
          <p className="font-sans text-sm font-normal">
            {segments.length - 1} Sections
          </p>
        </div>
      </div>
      <div className="mb-6 mt-6 grid grid-cols-1 gap-6">
        {props.unit.subunitOrder.map((suo, i) => {
          const subunit = props.unit.subunits?.find((s) => s.id === suo);
          const subunitProgress = unitProgress?.subunits?.find(
            (s) => s.subunitId === suo
          );

          const difficulty =
            subunitProgress?.difficulty ||
            learnerContext.learner?.level ||
            "easy";

          if (!subunit) {
            return null;
          }

          return (
            <SubunitProgressSection
              subunit={subunit}
              subunitProgress={subunitProgress}
              index={i}
              difficulty={difficulty}
              key={`subunit-${subunit.id}-${i}`}
            />
          );
        })}
        <UnitTestProgressSection
          unitNumber={props.unitNumber}
          unitProgress={unitProgress}
        />
      </div>
    </>
  );
};

export default CourseProgressUnitSummaryCard;
