import { clone, last, memoize } from "lodash";
import { Progress, SubunitProgress, UnitProgress } from "../types";

interface BaseProgressResult {
  kind: string;
}

interface CourseResult extends BaseProgressResult {
  kind: "course";
  courseProgress: Progress;
  isEndOfCourse: boolean;
}

interface UnitResult extends BaseProgressResult {
  kind: "unit";
  unitProgress: UnitProgress;
}

interface SubunitResult extends BaseProgressResult {
  kind: "subunit";
  unitProgress: UnitProgress;
  subunitProgress: SubunitProgress;
}

type IncompleteProgressResult = CourseResult | UnitResult | SubunitResult;

const getDate = memoize((dateStr: string) => new Date(dateStr));

/** Given a learner's current progress, try to find the next item they should do. */
export function getIncompleteProgress(
  progress: Progress
): IncompleteProgressResult {
  // If all units are completed, return the course test
  if (
    progress.units.every(
      (unit) => unit.progress === 1 && unit.unitTest?.progress === 1
    )
  ) {
    const lastUnit = last(progress.units);
    const lastSubunit = last(lastUnit?.subunits);
    if (lastUnit && lastSubunit) {
      return {
        kind: "course",
        courseProgress: progress,
        isEndOfCourse: progress.courseTest?.progress === 1,
      };
    }
  }

  // If just started, find the started subunit
  if (
    progress.units.every((unit) => unit.progress === 0) &&
    progress.progress === 0
  ) {
    for (const unitProgress of progress.units) {
      for (const subunit of unitProgress.subunits) {
        if (subunit.preQuiz || subunit.practice || subunit.postQuiz) {
          return {
            kind: "subunit",
            unitProgress,
            subunitProgress: subunit,
          };
        }
      }
    }
  }

  const unitsBeforeSorting = clone(progress.units);
  // Find the last updated subunit
  const [newestUnitProgress] = progress.units.sort(
    (progressA, progressB) =>
      getDate(progressB.updatedAt).getTime() -
      getDate(progressA.updatedAt).getTime()
  );
  const subunitsBeforeSorting = clone(newestUnitProgress.subunits);
  const [newestSubunitProgress] = newestUnitProgress.subunits.sort(
    (subunitA, subunitB) =>
      getDate(subunitB.updatedAt).getTime() -
      getDate(subunitA.updatedAt).getTime()
  );

  // if the unit updatedAt is more than subunit updatedAt then a unit test has been started or worked on
  if (
    newestUnitProgress.unitTest &&
    newestUnitProgress.unitTest.progress !== 1 &&
    newestUnitProgress.updatedAt > newestSubunitProgress.updatedAt
  ) {
    return {
      kind: "unit",
      unitProgress: newestUnitProgress,
    };
  }

  // If the latest subunit progress is not completed, return it
  if (newestSubunitProgress.progress < 1) {
    return {
      kind: "subunit",
      unitProgress: newestUnitProgress,
      subunitProgress: newestSubunitProgress,
    };
  }

  // The latest subunit progress is completed. Find the next subunit in the unit.
  const subunitIndex = subunitsBeforeSorting.findIndex(
    ({ subunitId }) => subunitId === newestSubunitProgress.subunitId
  );

  const subunitsAfter = subunitsBeforeSorting
    .slice(subunitIndex)
    .filter((x) => x.progress < 1);
  const subunitsBefore = subunitsBeforeSorting
    .slice(0, subunitIndex)
    .filter((x) => x.progress < 1);

  // To continue on in the sequence if there is a subunit later on in the unit that isn't complete
  // choose that before circling back around to previous subunits
  const nextSubunit =
    subunitsAfter.length > 0
      ? subunitsAfter[0]
      : subunitsBefore.length > 0
      ? subunitsBefore[0]
      : undefined;
  if (nextSubunit) {
    return {
      kind: "subunit",
      unitProgress: newestUnitProgress,
      subunitProgress: nextSubunit,
    };
  }

  // The latest subunit progress is completed, but there wasn't another subunit. Try the unit test
  if (
    newestSubunitProgress.progress === 1 &&
    (!newestUnitProgress.unitTest?.progress ||
      newestUnitProgress.unitTest.progress < 1)
  ) {
    return {
      kind: "unit",
      unitProgress: newestUnitProgress,
    };
  }

  // The latest subunit progress is completed. Find an incomplete subunit in the next unit.
  const unitIndex = unitsBeforeSorting.findIndex(
    ({ unitId }) => unitId === newestUnitProgress.unitId
  );
  const unitsAfter = unitsBeforeSorting
    .slice(unitIndex)
    .filter((x) => x.progress < 1);
  const unitsBefore = unitsBeforeSorting
    .slice(0, unitIndex)
    .filter((x) => x.progress < 1);

  const nextUnit =
    unitsAfter.length > 0
      ? unitsAfter[0]
      : unitsBefore.length > 0
      ? unitsBefore[0]
      : undefined;
  if (nextUnit) {
    for (const subunit of nextUnit.subunits) {
      if (subunit.progress < 1) {
        return {
          kind: "subunit",
          unitProgress: nextUnit,
          subunitProgress: subunit,
        };
      }
    }
    // if we get here it means that all subunits in the unit are complete so the only thing left is to go to a unit test
    return {
      kind: "unit",
      unitProgress: nextUnit,
    };
  }

  // This should be technically impossible, but to make typescript happy, let's just
  // return the course
  return {
    kind: "course",
    courseProgress: progress,
    isEndOfCourse: progress.courseTest?.progress === 1,
  };
}
