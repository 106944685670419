import { useEffect } from "react";
import { unreachableCase } from "../../../../utils";
import { useLearnerAnalytics } from "../../../analytics/useLearnerAnalytics";
import { useNewPrize } from "../../../utils/useNewPrize";
import { ConfigPrizeModal } from "./ConfigPrizeModal";
import { ContentPrizeModal } from "./ContentPrizeModal";
import { StandardPrizeModal } from "./StandardPrizeModal";
import { unlockPrizeEvent } from "../../../analytics/events";

type Props = {
  visible: boolean;
  onClose: () => void;
};

export const UnlockPrizeModal = (props: Props) => {
  const prize = useNewPrize();
  const { track } = useLearnerAnalytics();

  useEffect(() => {
    if (props.visible && prize) {
      track(unlockPrizeEvent({ prize: prize.key }));
    }
  }, [prize, props.visible, track]);

  /* should not occur */
  if (!prize) return null;

  switch (prize.type) {
    case "standard":
      return (
        <StandardPrizeModal
          visible={props.visible}
          onClose={props.onClose}
          prize={prize}
        />
      );
    case "config":
      return (
        <ConfigPrizeModal
          visible={props.visible}
          onClose={props.onClose}
          prize={prize}
        />
      );
    case "content":
      return (
        <ContentPrizeModal
          visible={props.visible}
          onClose={props.onClose}
          prize={prize}
        />
      );
    default:
      unreachableCase(prize);
  }
};
