export const PointsHexagon: React.FC<{ points: number }> = ({ points }) => {
  const height = points >= 1000 ? 71 : points >= 100 ? 56 : 47;
  const width = points >= 1000 ? 60 : points >= 100 ? 48 : 40;

  return (
    <div
      className={`relative flex items-center justify-center font-serif text-2xl font-bold text-dm-purple-600`}
      // tailwind really doesn't play nice with dynamic styling
      style={{
        minHeight: height,
        minWidth: width,
      }}
    >
      <div className="absolute left-0 top-0 z-0">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox="0 0 40 47"
          fill="none"
          aria-hidden="true"
        >
          <path
            d="M18.4635 1.44125C19.4194 0.861745 20.6181 0.861745 21.574 1.44124L37.5088 11.1018C38.4058 11.6455 38.9536 12.6183 38.9536 13.6671V33.3329C38.9536 34.3817 38.4058 35.3545 37.5088 35.8982L21.574 45.5588C20.6181 46.1383 19.4194 46.1383 18.4635 45.5588L2.52867 35.8982C1.63173 35.3545 1.08393 34.3817 1.08393 33.3329V13.6671C1.08393 12.6182 1.63173 11.6455 2.52866 11.1018L18.4635 1.44125Z"
            className="fill-dm-purple-100 stroke-dm-purple-800"
            strokeWidth="2"
          />
        </svg>
      </div>
      <span className="z-[1]">{points}</span>
    </div>
  );
};
