import { useEffect } from "react";
import { useParams } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { useCourseContext } from "../contexts/CourseContext";
import SubunitSummaryCard from "./Subunit/SubunitSummaryCard";
import { useLearnerContext } from "../contexts/LearnerContext";
import UnitTestCard from "./Unit/UnitTestCard";
import { Course, Unit } from "../types";
import UnitSummaryHeader from "./Unit/UnitSummaryHeader";
import { UnitLandingLoading } from "./UnitLandingLoading";
import { LandingPageError } from "./LandingPageError";
import { UnitLandingWrapper } from "./UnitLandingWrapper";
import { LearnerPage } from "./Layouts/LearnerPage";

const UnitLanding = () => {
  const { coursePath, unitPath } = useParams();
  const courseContext = useCourseContext();
  const learnerContext = useLearnerContext();
  const courseData = courseContext.getCourseData(coursePath);
  const unitData = courseContext.getUnitData(unitPath, coursePath);
  // This needs to be here for hook rules to utilize useDmQuery and set state in the context to fetch
  // this component needs to be fully mounted before updating that state
  useEffect(() => {
    courseContext.setActiveCourse(courseData?.id);
  }, [courseContext, courseData]);

  return (
    <LearnerPage title={unitData?.name}>
      {courseContext.state.isLoadingCourse ||
      courseContext.state.isLoadingSubunits ||
      learnerContext.state.isLoadingProgress ? (
        <UnitLandingLoading />
      ) : !courseData ? (
        <LandingPageError>Unable to load this course</LandingPageError>
      ) : courseData?.unitOrder.length === 0 || !courseData?.units ? (
        <LandingPageError>
          There are no units for this course or the order is not set
        </LandingPageError>
      ) : !unitData ||
        unitData.subunitOrder.length === 0 ||
        !unitData.subunits ? (
        <LandingPageError>
          There are no subunits for this unit or the order is not set
        </LandingPageError>
      ) : (
        <UnitLandingWithData courseData={courseData} unitData={unitData} />
      )}
    </LearnerPage>
  );
};

const UnitLandingWithData: React.FC<{ courseData: Course; unitData: Unit }> = ({
  courseData,
  unitData,
}) => {
  const { coursePath, unitPath } = useParams();
  const learnerContext = useLearnerContext();
  const courseProgress = learnerContext.getProgress(courseData?.id || "");

  const unitProgress = courseProgress?.units?.find(
    (p) => p.unitId === unitData.id
  );

  const isCurrentTestSubmitted =
    unitProgress?.unitTest !== undefined &&
    unitProgress?.unitTest?.assignment?.submitted !== undefined;
  const isFirstAttemptComplete =
    isCurrentTestSubmitted || !!unitProgress?.unitTest?.assignment?.resultHist;
  const isInProgress =
    unitProgress?.unitTest !== undefined &&
    !isCurrentTestSubmitted &&
    !unitProgress?.unitTest?.assignment?.solutionSeen;

  const unitIndex =
    courseData.unitOrder.findIndex((uId) => uId === unitData.id) + 1;

  const subunitsProgress = unitData.subunitOrder.map((suId) => {
    const subunit = unitProgress?.subunits.find(
      (sub) => sub.subunitId === suId
    );
    // If progress is 0 but pre-quiz has been started, I set progress to a small amount so the
    // segment on the unit icon in unit summary will show as light blue (in progress).
    // These values are not used anywhere else, only for determining the color of the section segment
    const progress =
      subunit && subunit?.progress === 0 && !!subunit?.preQuiz
        ? 0.01
        : subunit?.progress;
    return {
      id: suId,
      progress: progress || 0,
    };
  });

  const unitTestProgress =
    unitProgress?.unitTest && unitProgress?.unitTest?.progress === 0
      ? 0.01
      : unitProgress?.unitTest?.progress;

  subunitsProgress.push({
    id: "unit-test",
    progress: isFirstAttemptComplete ? 1 : unitTestProgress || 0,
  });

  ReactTooltip.rebuild();

  const estimatedTime = Math.max(
    5,
    Math.ceil((unitData?.avgQuizTime || 0) / 60 / 5) * 5
  );

  return (
    <>
      <UnitLandingWrapper>
        <UnitSummaryHeader
          title={`Unit ${unitIndex}: ${unitData.name}`}
          sectionProgress={subunitsProgress}
          description={unitData.description}
          unitProgress={unitProgress?.progress}
        />
        {unitData.subunitOrder.map((suo, i) => {
          const subunit = unitData.subunits?.find((su) => su.id === suo);
          if (!subunit) {
            return <></>;
          }

          return (
            <SubunitSummaryCard
              index={i + 1}
              subunit={subunit}
              courseId={courseData.id}
              coursePath={coursePath}
              unitId={unitData.id}
              unitPath={unitPath}
              key={`subunit-${suo}-${i}`}
            />
          );
        })}
        <h3 className="mb-2 mt-6 font-serif text-lg font-bold">Unit Test</h3>
        <UnitTestCard
          unit={unitData}
          coursePath={coursePath}
          unitPath={unitPath}
          progress={courseProgress}
          estimatedTime={estimatedTime}
          isFirstAttemptComplete={isFirstAttemptComplete}
          isInProgress={isInProgress}
        />
      </UnitLandingWrapper>
      <ReactTooltip
        id="sidebar-tooltip"
        className="!rounded font-sans text-xs"
        effect="solid"
        delayShow={800}
        delayHide={50}
        place="right"
      />
    </>
  );
};

export default UnitLanding;
