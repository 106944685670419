import clsx from "clsx";

type Props = {
  assignmentComplete?: boolean;
  skillComplete?: boolean;
  skillCorrect?: number;
  isActive: boolean;
};

export default function IconStatus({
  assignmentComplete,
  skillComplete,
  skillCorrect,
  isActive,
}: Props): JSX.Element {
  if (assignmentComplete) {
    if (skillCorrect === 1) {
      return (
        <>
          <i
            className="far fa-check fa-fw text-xl leading-none !text-dm-success-500"
            aria-hidden="true"
          ></i>
          <span className="sr-only">Skill is complete and correct.</span>
        </>
      );
    } else if (skillCorrect !== 1) {
      return (
        <>
          <i
            className="far fa-times fa-fw text-xl leading-none !text-dm-error-500"
            aria-hidden="true"
          ></i>
          <span className="sr-only">Skill is complete and incorrect.</span>
        </>
      );
    }
  } else {
    if (skillComplete) {
      return (
        <>
          <i
            className="far fa-check-circle fa-fw text-xl leading-none !text-dm-brand-blue-500"
            aria-hidden="true"
          ></i>
          <span className="sr-only">Skill is complete.</span>
        </>
      );
    } else {
      return (
        <>
          <i
            className={clsx(
              "far fa-circle fa-fw text-xl leading-none !text-dm-charcoal-100",
              isActive ? "!text-white" : "!text-dm-charcoal-100"
            )}
            aria-hidden="true"
          ></i>
          <span className="sr-only">Skill is not complete.</span>
        </>
      );
    }
  }

  return <></>;
}
