import { PieChart, PieChartProps } from "react-minimal-pie-chart";

interface DMPieChartProps extends PieChartProps {
  ariaLabel?: string;
}

export const DMPieChart = ({
  ariaLabel,
  className,
  ...props
}: DMPieChartProps) => (
  <div
    className={className}
    aria-label={ariaLabel}
    aria-hidden={ariaLabel === undefined}
    role="img"
  >
    <PieChart {...props} />
  </div>
);
