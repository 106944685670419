import React, { useEffect, useRef, useState } from "react";
import { useLearnerContext } from "../../contexts/LearnerContext";
import { DashboardCard } from "../Dashboard/DashboardCard";
import { TriangleStarsIcon } from "../../../shared/icons/TriangleStarsIcon";
import { PascalsTriangleLocked } from "./PascalsTriangleLocked";
import { PascalsTriangleUnlocked } from "./PascalsTriangleUnlocked";
import { Transition } from "@headlessui/react";
import { Skeleton, SkeletonText } from "../Skeleton";
import { UnlockPrizeModal } from "../Modals/Prizes/UnlockPrizeModal";

type TransitionState = "waiting" | "locked" | "transitioning" | "unlocked";

export const PascalsTriangleCard: React.FC = () => {
  const { learner } = useLearnerContext();
  const [state, setState] = useState<TransitionState>("waiting");
  const isInitialDataLoad = useRef<boolean>(true);
  const [showUnlockPrizeModal, setShowUnlockPrizeModal] =
    useState<boolean>(false);
  const [needsOnboarding, setNeedsOnboarding] = useState<boolean>(() =>
    // Force onboarding for testing purposes
    window.location.search.includes("onboarding")
  );

  useEffect(() => {
    switch (state) {
      case "waiting":
        setState(
          learner === undefined
            ? "waiting"
            : learner.pascalsRow === undefined
            ? "locked"
            : "unlocked"
        );
        break;
      case "locked":
        setState("transitioning");
        break;
      case "unlocked":
        // Don't change once unlocked
        break;
      case "transitioning":
        throw new Error("Cannot change transitioning state from effect");
      default:
        throw new Error("Invalid state");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [learner]);

  /* Shows unlock prize modal when the length of the prizes array changes, after initial data load */
  useEffect(() => {
    if (learner?.prizes?.length) {
      if (!isInitialDataLoad.current) {
        setShowUnlockPrizeModal(true);
      } else {
        isInitialDataLoad.current = false;
      }
    }
  }, [learner?.prizes?.length]);

  return (
    <DashboardCard icon={<TriangleStarsIcon />} title="Pascal's Triangle">
      {state === "waiting" ? (
        <div className="mt-4 flex h-60 flex-col items-center justify-between gap-6 md:mt-0 md:flex-row">
          <div className="flex flex-col gap-2 self-start md:self-center">
            <SkeletonText>
              <h3 className="text-lg font-bold">
                Nisi eiusmod qui eiusmod culpa occaecat do id occaecat
              </h3>
            </SkeletonText>
            <SkeletonText>
              <p>Nisi consectetur dolor consequat do tempor voluptate qui</p>
            </SkeletonText>
          </div>
          <div className="h-full w-60">
            <Skeleton />
          </div>
        </div>
      ) : (
        <>
          <UnlockPrizeModal
            visible={showUnlockPrizeModal}
            onClose={() => setShowUnlockPrizeModal(false)}
          />
          <Transition
            show={state === "locked"}
            leave="transition-opacity ease-linear duration-250"
            leaveTo="opacity-0"
            leaveFrom="opacity-100"
            afterLeave={() => setState("unlocked")}
          >
            <PascalsTriangleLocked
              onUnlock={() => {
                setNeedsOnboarding(true);
                setState("unlocked");
              }}
            />
          </Transition>

          <Transition
            show={state === "unlocked"}
            enter="transition-opacity ease-linear duration-250"
            enterFrom="opacity-0"
            enterTo="opacity-100"
          >
            <PascalsTriangleUnlocked
              needsOnboarding={needsOnboarding}
              setShowUnlockPrizeModal={setShowUnlockPrizeModal}
            />
          </Transition>
        </>
      )}
    </DashboardCard>
  );
};
