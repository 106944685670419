import { DashboardCard } from "./DashboardCard";
import { SkeletonText } from "../Skeleton";
import { QuestionMarkCircleIcon2 } from "../../../shared/icons/QuestionMarkCircleIcon2";
import { useLearnerContext } from "../../contexts/LearnerContext";

export const QuestionsAnsweredCorrectlyCard: React.FC = () => {
  const { learner } = useLearnerContext();
  return (
    <DashboardCard
      icon={<QuestionMarkCircleIcon2 />}
      title="Questions Answered Correctly"
    >
      <h4 className="mb-6 mt-4 font-serif text-3xl font-bold">
        <SkeletonText loaded={!!learner}>
          {learner?.solved_correct ?? "??"}{" "}
          {learner?.solved_correct !== 1 ? "Questions" : "Question"}
        </SkeletonText>
      </h4>

      {/* This is lorem ipsum in the designs. Uncomment this when there's real text to display. */}
      {/* <p className="text-xs text-gray-500">
        Ipsum eiusmod eu est excepteur commodo cillum
      </p> */}
    </DashboardCard>
  );
};
