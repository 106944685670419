import clsx from "clsx";
import { RoundedLockIcon } from "./RoundedLockIcon";
import { PascalsCell } from "./PascalsCell";
import { noop } from "lodash";

export const LockedRow: React.FC<{ length: number }> = ({ length }) => (
  <div
    className="relative z-0 flex justify-center"
    key={`locked-row-${length}`}
  >
    <div className="absolute -bottom-[13px] -left-6 -right-6 top-0 z-20 bg-gradient-to-t from-white to-transparent"></div>
    <div
      className={clsx(
        "absolute left-0 top-0 z-20 flex h-full w-full items-center justify-center",
        // Margin adjustment for when the icon is between two cells or in the middle a cell
        length % 2 === 0 ? "mt-1" : "mt-[1px]"
      )}
    >
      <RoundedLockIcon variant="static" size="sm" />
    </div>
    {Array(length)
      .fill(null)
      .map((_, index) => (
        <PascalsCell
          key={`bonus-cell-${index}`}
          currentValue={null}
          expectedValue={0}
          isEditable={false}
          isHighlighted={false}
          showPlus={false}
          setActiveCell={noop}
          redeemPoints={noop}
        />
      ))}
  </div>
);
