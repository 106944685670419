import { useEffect, useState } from "react";
import Button from "../../../student/components/generic/button";
import { useMutation } from "react-query";
import axios from "axios";
import { deltamathAPI } from "../../utils";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import { useBeforeUnload } from "react-router-dom";

type Props = {
  name: string;
  friendlyUrl: string;
  description?: string;
  featuredCourseOrder?: number;
  type: "course" | "unit" | "subunit";
  id: string;
  callback: () => void;
  versionState: "draft" | "past" | "published" | "deleted";
};

const EditName = (props: Props) => {
  const toastContext = useDeltaToastContext();

  const [edit, setEdit] = useState<boolean>(false);
  const [name, setName] = useState<string>(props.name);
  const [friendlyUrl, setFriendlyUrl] = useState<string>(props.friendlyUrl);
  const [description, setDescription] = useState<string>(
    props.description ?? ""
  );
  const [featuredCourseOrder, setFeaturedCourseOrder] = useState<
    number | undefined
  >(undefined);

  const saveEdit = useMutation(
    (body: string) => {
      return axios.post(
        `${deltamathAPI()}/manager_new/parent-portal/${props.type}/${props.id}`,
        body,
        {
          headers: {
            "Content-type": "application/json",
          },
        }
      );
    },
    {
      onSuccess() {
        if (props.callback) {
          props.callback();
        }
      },
      onError(e) {
        console.error(e);
        toastContext.addToast({
          status: "Error",
          message: "Problem creating corse",
        });
      },
    }
  );

  useEffect(() => {
    if (props.name) {
      setName(props.name);
    }
    if (props.friendlyUrl) {
      setFriendlyUrl(props.friendlyUrl);
    }
    if (props.description) {
      setDescription(props.description);
    }
    if (props.featuredCourseOrder) {
      setFeaturedCourseOrder(props.featuredCourseOrder);
    }
  }, [
    props.name,
    props.friendlyUrl,
    props.description,
    props.featuredCourseOrder,
  ]);

  const save = () => {
    const body = {
      name,
      friendlyUrl,
      description,
      featuredCourseOrder,
    };

    saveEdit.mutate(JSON.stringify(body));
    setEdit(false);
  };

  const beforeunload = (e: BeforeUnloadEvent) => {
    if (
      edit &&
      (name !== props.name ||
        friendlyUrl !== props.friendlyUrl ||
        description !== props.description)
    ) {
      e.preventDefault();

      toastContext.addToast({
        status: "Warning",
        message: "Please save your changes before leaving",
        dismiss: 15,
      });
      return "onbeforeunload";
    }
  };

  useBeforeUnload(beforeunload);

  return (
    <>
      {edit ? (
        <form className="flex flex-col gap-2" onSubmit={save}>
          <div className="mb-2 flex items-center gap-2">
            <h4
              className={`${
                props.type !== "subunit" ? "text-2xl" : ""
              } font-bold`}
            >
              Name:{" "}
            </h4>
            <input
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="h-full"
            />
          </div>
          <div className="mb-2 flex gap-2">
            <p
              className={`${
                props.type !== "subunit" ? "text-xl" : ""
              } font-bold`}
            >
              Friendly Url:{" "}
            </p>
            {props.versionState === "draft" ? (
              <input
                value={friendlyUrl}
                onChange={(e) => setFriendlyUrl(e.target.value)}
              />
            ) : (
              <p>{friendlyUrl}</p>
            )}
          </div>

          <div className="flex flex-col gap-2">
            {props.type !== "subunit" && (
              <div>
                <h4 className={`text-2xl font-bold`}>Description: </h4>
                <textarea
                  name="message"
                  rows={4}
                  cols={60}
                  onChange={(e) => setDescription(e.target.value)}
                >
                  {description}
                </textarea>
              </div>
            )}
          </div>

          <div className="flex items-center gap-2">
            <label className="font-bold" htmlFor="featuredCourseOrder">
              Featured course order
            </label>
            <input
              type="number"
              name="featuredCourseOrder"
              id="featuredCourseOrder"
              value={featuredCourseOrder}
              onChange={(e) => setFeaturedCourseOrder(Number(e.target.value))}
            />
          </div>

          <div className="flex gap-4">
            <Button>Save</Button>
            <button type="button" onClick={() => setEdit(false)}>
              Cancel
            </button>
          </div>
        </form>
      ) : (
        <div className="flex flex-col items-start gap-2">
          <h4
            className={`${props.type !== "subunit" ? "text-xl" : ""} font-bold`}
          >
            {props.name}
          </h4>
          <p
            className={`${props.type !== "subunit" ? "text-xl" : ""} font-bold`}
          >
            Friendly Url: {props.friendlyUrl}
          </p>
          {props.type !== "subunit" && <div>{description}</div>}
          <p>
            <b>Featured course order:</b>{" "}
            {props.featuredCourseOrder ? (
              <code>{props.featuredCourseOrder}</code>
            ) : (
              "not set"
            )}
          </p>
          <Button onClick={() => setEdit(true)} className="mt-2">
            Edit
          </Button>
        </div>
      )}
    </>
  );
};

export default EditName;
