import { PropsWithChildren } from "react";
import { ProfileDividedContainer } from "./ProfileDividedContainer";
import clsx from "clsx";

export const ProfileFieldGroup: React.FC<
  PropsWithChildren<{
    title?: string;
    className?: string;
  }>
> = ({ title, className, children }) => (
  <ProfileDividedContainer className={clsx("flex flex-col gap-4", className)}>
    {title && <h2 className="font-serif">{title}</h2>}
    <div className="flex flex-col gap-4 sm:gap-6">{children}</div>
  </ProfileDividedContainer>
);
