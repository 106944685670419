import React from "react";
import { Column, useTable } from "react-table";

export const DeltaMathSearchableTable = ({
  columns,
  data,
  currentSearch,
  updateSearch,
  isLoading,
  restoreDefaults,
  refetch,
  selectAccount,
  type,
  handleSubmit,
  notSearchable,
}: {
  columns: Array<Column>;
  data: Array<any>;
  currentSearch: any;
  updateSearch: any;
  handleSubmit: any;
  isLoading: boolean;
  restoreDefaults: any;
  refetch: any;
  selectAccount: any;
  type?: string;
  notSearchable?: Array<string>;
}) => {
  const tableInstance = useTable({ columns, data });
  const { getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;
  return (
    <>
      <div className="mx-4 bg-white sm:rounded-md">
        <div className="flex flex-col">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <form onSubmit={handleSubmit}>
                  <span className="relative z-0 inline-flex rounded-md shadow-sm">
                    <button
                      type="submit"
                      name="search-button"
                      className="flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:m-2"
                      value="Submit"
                      color="secondary"
                      disabled={isLoading}
                    >
                      {isLoading ? "Loading" : "Submit"}
                    </button>
                    <button
                      type="reset"
                      name="search-button"
                      className="rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:m-2"
                      value="Submit"
                      color="secondary"
                      disabled={isLoading}
                      onClick={() => restoreDefaults()}
                    >
                      Reset
                    </button>
                  </span>
                  <table className="min-w-full table-fixed divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      {headerGroups.map((headerGroup) => (
                        <tr
                          {...headerGroup.getHeaderGroupProps()}
                          key={headerGroup.id}
                        >
                          {headerGroup.headers.map((column) => (
                            <React.Fragment key={column.id}>
                              {column.Header === "schoolPlus" ? (
                                <th className="w-0"></th>
                              ) : (
                                <th
                                  {...column.getHeaderProps()}
                                  scope="col"
                                  className="py-3.5 pl-2 pr-2 text-sm font-semibold text-gray-900"
                                >
                                  {column.Header === "#" && (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="m-4 h-6 w-6"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke="currentColor"
                                      strokeWidth={2}
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M7 20l4-16m2 16l4-16M6 9h14M4 15h14"
                                      />
                                    </svg>
                                  )}

                                  {column.Header !== "#" &&
                                  column.Header !== "Expiration" &&
                                  column.Header !== " " &&
                                  column.Header !== "License Type" &&
                                  column.Header !== "License Tier" ? (
                                    <input
                                      type="text"
                                      name={`${column.id}`}
                                      placeholder={`${column.render("Header")}`}
                                      value={currentSearch[column.id] || ""}
                                      onChange={(e) =>
                                        updateSearch({
                                          ...currentSearch,
                                          [column.id]: e.target.value,
                                        })
                                      }
                                      disabled={notSearchable?.includes(
                                        `${column.Header}`
                                      )}
                                      className={
                                        notSearchable?.includes(
                                          `${column.Header}`
                                        )
                                          ? "block min-w-full rounded-md border-none bg-gray-50 sm:text-sm"
                                          : "block min-w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                      }
                                    />
                                  ) : (
                                    !(column.Header === "#") && (
                                      <div className="block min-w-80 min-w-full p-4 text-left sm:text-sm">
                                        {column.Header}
                                      </div>
                                    )
                                  )}
                                </th>
                              )}
                            </React.Fragment>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody
                      {...getTableBodyProps()}
                      className="divide-y divide-gray-200 bg-white"
                    >
                      {rows.map((row) => {
                        prepareRow(row);
                        return (
                          <tr
                            {...row.getRowProps()}
                            key={row.id}
                            onClick={() => selectAccount(row.original)}
                            className="cursor-pointer"
                          >
                            {row.cells.map((cell, index) => {
                              return (
                                <td
                                  {...cell.getCellProps()}
                                  key={index}
                                  className="whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-900"
                                >
                                  {cell.render("Cell")}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
