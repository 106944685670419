import { useContext } from "react";
import { Disclosure } from "@headlessui/react";

import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";
import clsx from "clsx";
import StudentSectionsContext from "../_context/StudentSectionsContext";
import { Link } from "react-router-dom";
import CustomGraphingIcon from "./icons/CustomGraphIcon";
import { REACT_APP_STUDENT_LINK } from "../../utils";

const items = [
  {
    name: "Graphing Calculator",
    // href: "/calculator/graphingcalc.html",
    href: `${REACT_APP_STUDENT_LINK}/graphingCalculator`,
    icon: <CustomGraphingIcon />,
  },
];

export default function CalculatorButton({
  setSidebarOpen,
}: {
  setSidebarOpen: any;
}): JSX.Element {
  const { showKeyboard, setShowKeyboard, showCalculator, setShowCalculator } =
    useContext(StudentSectionsContext);

  return (
    <Disclosure
      as="div"
      // key="archiveCourses"
      className="space-y-1"
      defaultOpen={false}
    >
      {({ open }) => (
        <>
          <div className="inline-flex w-full items-center justify-between p-2 text-sm text-dm-gray-200 hover:text-dm-gray-600">
            <button
              aria-label="Calculator"
              type="button"
              onClick={() => {
                // in a setTimeout to beat the <Transition> of the closing
                // sidebar, which caused the calculator to show and
                // quickly disappear b/c the sidebar refocuses on the last
                // focused element using <FocusTrap>
                setTimeout(() => {
                  if (showKeyboard && !showCalculator) {
                    setShowCalculator(true);
                  } else if (!showKeyboard) {
                    setShowKeyboard(true);
                    setShowCalculator(true);
                  } else {
                    setShowCalculator(false);
                    setShowKeyboard(false);
                  }
                }, 300);
                setSidebarOpen(false);
              }}
            >
              <i className="far fa-calculator mr-2 h-6 w-6 text-xl leading-none"></i>
              {/* <CalculatorIcon
                className="mr-2 h-6 w-6 text-dm-gray-200"
                aria-hidden="true"
              /> */}
              <span className="flex-1 text-left text-sm text-dm-gray-600">
                Calculator
              </span>
            </button>
            <Disclosure.Button
              className="group ml-2 h-5 w-5 items-end text-sm"
              aria-label="Show advanced calculator options."
            >
              {open ? (
                <ChevronUpIcon
                  className="text-dm-gray-200"
                  aria-hidden="true"
                />
              ) : (
                <ChevronDownIcon
                  className="text-dm-gray-200"
                  aria-hidden="true"
                />
              )}
            </Disclosure.Button>
          </div>

          <Disclosure.Panel className="space-y-1">
            {items.map((item, index) => (
              <Link
                to={item.href}
                key={`calckey-${index}`}
                className={clsx(
                  "group flex items-center rounded-md px-3 py-2 pl-8 text-sm text-dm-gray-600"
                )}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="mr-2 h-6 w-6">{item.icon}</span>
                <span className="text-dm-gray-600">{item.name}</span>
              </Link>
            ))}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
