import Modal from "../../../../student/components/generic/Modal";
import Button from "../../../../student/components/generic/button";
import { PrizeModalProps, StandardPrize } from "../../../types";
import { PrizeModalHeader } from "./PrizeModalHeader";

interface Props extends PrizeModalProps {
  prize: StandardPrize;
}

export const StandardPrizeModal = (props: Props) => {
  return (
    <Modal
      visible={props.visible}
      onClose={props.onClose}
      title={
        <PrizeModalHeader image={props.prize.image}>
          {props.prize.unlockTitle}
        </PrizeModalHeader>
      }
      body={props.prize.unlockText}
      closeX={true}
      footer={
        <div className="mt-4 flex justify-center border-t pt-8">
          <Button
            className={"grow text-sm sm:w-[300px] sm:grow-0"}
            onClick={props.onClose}
          >
            Got It
          </Button>
        </div>
      }
    />
  );
};
