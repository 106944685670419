import { useEffect } from "react";
import Modal from "../../../student/components/generic/Modal";
import { useLearnerContext } from "../../contexts/LearnerContext";
import { useLearnerAnalytics } from "../../analytics/useLearnerAnalytics";
import {
  learnHowPointsWorkModalEvent,
  parentLearnHowPointsWorkModalEvent,
} from "../../analytics/events";

type Props = {
  visible: boolean;
  onClose: () => void;
};

const HowPointsWorkModal = (props: Props) => {
  const { learner } = useLearnerContext();
  const { track } = useLearnerAnalytics();

  useEffect(() => {
    if (props.visible) {
      track(
        learner?.entitlements.includes("learner")
          ? learnHowPointsWorkModalEvent()
          : parentLearnHowPointsWorkModalEvent()
      );
    }
  }, [learner?.entitlements, props.visible, track]);

  return (
    <Modal
      visible={props.visible}
      onClose={() => props.onClose()}
      title={
        <span className="font-serif leading-8">
          Understanding the Points System
        </span>
      }
      body={
        <div className="flex flex-col gap-4">
          <p>
            Points are the key to unlocking rewards, tracking progress, and
            celebrating learning milestones! Here&rsquo;s how they&rsquo;re
            earned:
          </p>
          <ul className="flex list-disc flex-col gap-2 pl-6">
            <li>
              <strong>Pre-Quizzes:</strong> 1 point for each question answered
              (skipped quizzes earn no points).
            </li>
            <li>
              <strong>Practice:</strong>
              <ul className="list-outside list-disc pl-6">
                <li>
                  <strong>Required Skills:</strong> 2 points per correct answer
                  (up to 6 points per skill).
                </li>
                <li>
                  <strong>Optional Skills:</strong> 1 point per correct answer
                  (up to 3 points per skill).
                </li>
                <li>
                  <strong>Watching Videos:</strong> 3 points for watching the
                  full video, partial points for partial viewing.
                </li>
              </ul>
            </li>
            <li>
              <strong>Post-Quizzes:</strong>
              <ul className="list-disc pl-6">
                <li>
                  <strong>Scores &lt;70%:</strong> 2 points for the first
                  attempt, then 2 points for each retake with a higher score.
                </li>
                <li>
                  <strong>Scores &ge;70%:</strong> 1 point per correct answer,
                  plus bonus points for high scores or significant improvement
                  from the pre-quiz (once per quiz/test).
                  <ul>
                    <li>
                      <strong>Note:</strong> If you improve your score from
                      &lt;70% to &ge;70% on a retake, you&rsquo;ll earn points
                      based on the &ge;70% criteria, maximizing your point
                      potential!
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              <strong>Unit & Course Tests:</strong>
              <ul className="list-disc pl-6">
                <li>
                  <strong>First Attempt:</strong> 1 point per correct answer,
                  plus a 5-point bonus for a perfect score.
                </li>
                <li>
                  <strong>Retakes:</strong> 2 points for improving your score,
                  plus 1 point per correct answer compared to your previous best
                  (no points for retakes after reaching 100%).
                </li>
              </ul>
            </li>
            <li>
              <strong>Ultimate Bonus:</strong> Earn a whopping 50 bonus points
              for achieving 100% on all unit completions and passing the course
              test!
            </li>
          </ul>
        </div>
      }
      closeX
      noLine
    />
  );
};

export default HowPointsWorkModal;
