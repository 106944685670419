import { Link } from "react-router-dom";
import { REACT_APP_LEARNER_LINK, getFilePath } from "../../../../utils";
import { ResponsiveImage } from "../../ResponsiveImage";

type Props = {
  coursePath?: string;
  unitPath?: string;
  subunitPath?: string;
  firstUnsolved: number;
  skippedPreQuiz: boolean;
};

const PracticeLanding = (props: Props) => {
  return (
    <div className="flex flex-grow flex-col items-center justify-center rounded-lg border border-dm-charcoal-100 bg-white px-4 py-6 max-sm:m-4">
      <ResponsiveImage
        className="mx-auto h-auto w-56"
        srcs={[
          getFilePath("/images/learner/practice/start-practice.png"),
          getFilePath("/images/learner/practice/start-practice@2x.png"),
        ]}
        alt=""
        aria-hidden="true"
      />
      {props.skippedPreQuiz ? (
        <>
          <h4 className="mx-3 mt-8 font-serif text-2xl font-bold">
            Let&rsquo;s Practice!
          </h4>
          <p className="mx-3 mt-2">
            Since you skipped the pre-quiz, all skills are required in this
            practice section. Want a more tailored experience next time? Take
            the pre-quiz to skip skills you already know.
          </p>
        </>
      ) : (
        <>
          <h4 className="mx-3 mt-8 font-serif text-2xl font-bold">
            Time to practice!
          </h4>
          <p className="mx-3 mt-2">
            We picked practice questions just for you based on your pre-quiz
            results. Questions with a thumbs up are optional &ndash; you already
            showed you know these! All other skills are required to complete
            this section. Let&rsquo;s get to it!
          </p>
        </>
      )}
      <div className="flex flex-col items-center gap-y-4 pt-6">
        <Link
          to={`${REACT_APP_LEARNER_LINK}/${props.coursePath}/${props.unitPath}/${props.subunitPath}/practice/${props.firstUnsolved}`}
          className="rounded bg-dm-brand-blue-500 px-8 py-2 leading-6 text-white hover:bg-dm-brand-blue-600 active:bg-dm-brand-blue-800 disabled:cursor-not-allowed disabled:opacity-50"
        >
          {props.skippedPreQuiz ? "Get Started!" : <>Let&apos;s Go!</>}
        </Link>
      </div>
    </div>
  );
};

export default PracticeLanding;
