import { useContext, useRef } from "react";
import UploadWork from "./UploadWork";
import BackToHome from "./BackToHome";
import Button from "./generic/button";
import StudentSectionsContext from "../_context/StudentSectionsContext";

type TestCallToActionProps = {
  isSkillComplete: boolean;
  isAssignmentComplete: boolean;
  hasNextSection: boolean;
  hasPreviousSection: boolean;
  hasWorkToUpload: boolean;
  hasEndEarly: boolean;
  hasEndEarlyUnlimited: boolean;
  readyToShowResults: boolean;
  inTestMode: boolean;
  isUnlimited?: boolean;
  handleNextSection: () => void;
  handlePreviousSection: () => void;
  handleShowResults: () => void;
  handleEndEarly: (isTest?: boolean, isUnlimited?: boolean) => void;
};

type ButtonInfo = {
  text?: string;
  action?: () => void;
  disabled: boolean;
  type?: "primary" | "secondary" | "outline";
};

export default function TestCallToAction({
  isSkillComplete,
  isAssignmentComplete,
  hasNextSection,
  hasPreviousSection,
  hasWorkToUpload,
  hasEndEarly,
  hasEndEarlyUnlimited,
  readyToShowResults,
  inTestMode,
  isUnlimited = false,
  handleShowResults,
  handleNextSection,
  handlePreviousSection,
  handleEndEarly,
}: TestCallToActionProps): JSX.Element {
  const buttonInfo: ButtonInfo = { disabled: false };
  const buttonInfo2: ButtonInfo = { disabled: false };
  const { globalFocusedInput } = useContext(StudentSectionsContext);

  const buttonRef = useRef<HTMLElement>(null);

  const endTimeText = isUnlimited ? "End Test Now" : "End Test Early";

  let showUploadWork = false;
  let showBackToHome = false;

  const changeFocus = () =>
    setTimeout(() => {
      if (buttonRef?.current && globalFocusedInput !== "math-input-0") {
        // scrollToView(buttonRef, "auto");
        buttonRef?.current?.focus();
      }
    }, 50);

  if (inTestMode) {
    // student is still in "test mode"
    if (hasEndEarlyUnlimited) {
      buttonInfo2.text = endTimeText;
      buttonInfo2.action = () => handleEndEarly(true, isUnlimited);
      buttonInfo2.type = "primary";
    }
    if (!isSkillComplete) {
      buttonInfo.text = "Next Question";
      buttonInfo.disabled = true;

      if (readyToShowResults) {
        buttonInfo2.text = "Show Results";
        buttonInfo2.action = handleShowResults;
        buttonInfo2.type = "outline";
      }
    } else {
      if (hasNextSection) {
        if (hasEndEarly) {
          buttonInfo.text = endTimeText;
          buttonInfo.action = () => handleEndEarly(true, isUnlimited);
        } else {
          buttonInfo.text = "Next Question";
          buttonInfo.action = handleNextSection;
        }
        changeFocus();
        if (readyToShowResults) {
          buttonInfo2.text = "Show Results";
          buttonInfo2.action = handleShowResults;
          buttonInfo2.type = "outline";
        }
      } else if (isAssignmentComplete) {
        if (hasEndEarly) {
          buttonInfo.text = endTimeText;
          buttonInfo.action = () => handleEndEarly(true, isUnlimited);
        } else if (readyToShowResults) {
          buttonInfo.text = "Show Results";
          buttonInfo.action = handleShowResults;
        } else if (hasWorkToUpload) {
          showUploadWork = true;
        } else {
          showBackToHome = true;
        }
      }
    }
  } else {
    // student is not in "test mode"
    buttonInfo.text = "Next Question";
    buttonInfo.action = handleNextSection;
    buttonInfo.disabled = hasNextSection ? false : true;
    if (hasNextSection) changeFocus();
    buttonInfo2.text = "Previous Question";
    buttonInfo2.action = handlePreviousSection;
    buttonInfo2.disabled = hasPreviousSection ? false : true;
    buttonInfo2.type = "primary";
  }

  return (
    <>
      {buttonInfo2.text !== undefined && buttonInfo2.action !== undefined && (
        <Button
          onClick={buttonInfo2.action}
          disabled={buttonInfo2.disabled}
          type={buttonInfo2.type}
          className="max-sm:w-full"
        >
          {buttonInfo2.text}
        </Button>
      )}
      {showUploadWork && <UploadWork buttonType="primary" />}
      {showBackToHome && <BackToHome type="primary" />}
      {buttonInfo.text !== undefined && (
        <Button
          onClick={buttonInfo.action}
          disabled={buttonInfo.disabled}
          type="primary"
          className="max-sm:w-full"
          ref={buttonRef}
        >
          {buttonInfo.text}
        </Button>
      )}
    </>
  );
}
