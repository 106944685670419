import Modal from "../../../student/components/generic/Modal";
import { useDMQuery } from "../../../utils";
import { FormattedPrice, LearnerSubscriptionDetails } from "../../types";
import { formatPrice } from "../../utils/formatPrice";
import { format } from "date-fns";
import { DmLoadingSpinner } from "../../../manager/utils/functions";
import { useState } from "react";

export const QuantityChangeModal: React.FC<{
  visible: boolean;
  onClose: () => void;
  subscription: LearnerSubscriptionDetails;
  newQuantity: number;
  title: string;
  confirmButtonText: string;
  onConfirm: () => Promise<void>;
}> = ({
  visible,
  onClose,
  subscription,
  newQuantity,
  title,
  confirmButtonText,
  onConfirm,
}) => {
  const [isConfirming, setIsConfirming] = useState(false);

  const {
    data: previewData,
    isRefetching: isRefetchingPreviewData,
    isError,
  } = useDMQuery<{
    proratedAmount: FormattedPrice;
    fullAmount: FormattedPrice;
  }>({
    path: "/payments/subscriptions/update/quantity/preview",
    params: { quantity: newQuantity },
    queryOptions: { enabled: visible, refetchOnWindowFocus: false },
  });

  const formattedProratedAmount = formatPrice(
    previewData ? previewData.proratedAmount : { value: 0, currency: "USD" }
  );
  const formattedFullAmount = formatPrice(
    previewData ? previewData.fullAmount : { value: 0, currency: "USD" }
  );
  const formattedBillingDate = format(
    new Date(subscription.periodEnd),
    "MMMM d, yyyy"
  );
  const quantityDiff = newQuantity - subscription.quantity;

  const handleConfirm = async () => {
    setIsConfirming(true);
    await onConfirm();
    setIsConfirming(false);
  };

  return (
    <Modal
      visible={visible}
      onClose={onClose}
      closeX
      body={
        <div className="flex flex-col gap-10">
          <h2 className="text-center font-serif text-2xl font-bold text-dm-brand-blue-600">
            {title}
          </h2>
          {isError ? (
            <p className="py-8 text-center text-dm-error-500">
              There was an error fetching your subscription details.
            </p>
          ) : !previewData || isRefetchingPreviewData ? (
            <div className="flex h-20 w-full items-center justify-center">
              <DmLoadingSpinner message="" />
            </div>
          ) : (
            <p>
              You&apos;re adding {quantityDiff} learner(s). A prorated charge of{" "}
              {formattedProratedAmount} will be applied today. From{" "}
              {formattedBillingDate}, your subscription will renew at{" "}
              {formattedFullAmount} per {subscription.interval}. Are you sure
              you want to continue?
            </p>
          )}
        </div>
      }
      secondaryButtonText="Cancel"
      secondaryOnClick={onClose}
      confirmButtonText={confirmButtonText}
      confirmDisabled={!previewData || isRefetchingPreviewData || isConfirming}
      onConfirm={handleConfirm}
    />
  );
};
