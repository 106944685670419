const PictureInPictureIcon = ({
  classes = "w-5 h-5",
}: {
  classes?: string;
}) => {
  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      fill="none"
    >
      <g clipPath="url(#clip0_1085_11811)">
        <rect
          x="3"
          y="6"
          width="26"
          height="20"
          rx="3"
          stroke="white"
          strokeWidth="2"
        />
        <mask id="path-2-inside-1_1085_11811" fill="white">
          <rect x="15" y="16" width="11" height="7" rx="1" />
        </mask>
        <rect
          x="15"
          y="16"
          width="11"
          height="7"
          rx="1"
          fill="white"
          stroke="white"
          strokeWidth="4"
          mask="url(#path-2-inside-1_1085_11811)"
        />
      </g>
    </svg>
  );
};

export default PictureInPictureIcon;
