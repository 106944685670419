import { useMutation } from "react-query";
import { useDMQuery } from "../../../utils";
import axios from "axios";
import { deltamathAPI } from "../../utils";
import { DmLoadingSpinner } from "../../utils/functions";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import { FeatureFlagsTable } from "./FeatureFlagsTable";
import { FeatureFlag, PartialFeatureFlag } from "./types";
import { BasicContentContainer } from "../BasicContentContainer";
import { CreateFeatureFlagForm } from "./CreateFeatureFlagForm";
import { useDocumentTitle } from "usehooks-ts";

export const FeatureFlagsPage: React.FC = () => {
  useDocumentTitle("Feature Flags");
  const toastContext = useDeltaToastContext();
  const {
    data: featureFlagData,
    isLoading,
    refetch: refetchFlags,
  } = useDMQuery<{
    flags: FeatureFlag[];
  }>({
    path: "/feature-flags/list",
    queryOptions: {
      staleTime: 1000 * 60 * 5, // 5 minutes
      refetchOnWindowFocus: false,
    },
  });

  const { mutateAsync: updateFlagMutation } = useMutation(
    (flag: PartialFeatureFlag) => {
      return axios.post(
        `${deltamathAPI()}/feature-flags/update`,
        JSON.stringify({ flag }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    }
  );

  const updateFlag = async (flag: PartialFeatureFlag) => {
    try {
      await updateFlagMutation(flag);
      await refetchFlags();
    } catch (e: any) {
      toastContext.addToast({
        message:
          e.response.data.message || "Unable to create or update feature flag",
        status: "Error",
      });
    }
  };

  return (
    <div className="flex flex-col gap-10 p-6">
      <div>
        <h1 className="mb-1 text-2xl font-bold">Feature flags</h1>
        <p className="mb-3 flex flex-col text-sm text-dm-charcoal-500">
          <span>
            <b>Disabled:</b> the feature is hidden for everyone
          </span>
          <span>
            <b>Active:</b> the feature is only visible to the specified users
          </span>
          <span>
            <b>Global:</b> the feature is visible to everyone
          </span>
        </p>
        {isLoading && (
          <BasicContentContainer>
            <DmLoadingSpinner message="" />
          </BasicContentContainer>
        )}
        {featureFlagData?.flags && (
          <FeatureFlagsTable
            flags={featureFlagData.flags}
            refetchFlags={refetchFlags}
            updateFlag={updateFlag}
          />
        )}
      </div>
      <div>
        <h2 className="mb-2 text-lg font-bold">Create a new feature flag</h2>
        <CreateFeatureFlagForm
          refetchFlags={refetchFlags}
          updateFlag={updateFlag}
        />
      </div>
    </div>
  );
};
