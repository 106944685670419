import { AnalyticsPlugin } from "analytics";
import { AnalyticsEvent } from "../types";

declare global {
  interface Window {
    sib: { equeue: any[]; client_key: string; [key: string]: any };
    sendinblue: any;
  }
}

/**
 * This is an Analytics plugin that implements an interface for sending events to Brevo
 * @see https://getanalytics.io/
 * @see https://getanalytics.io/plugins/writing-plugins/
 */
export const BREVO_PLUGIN: AnalyticsPlugin = {
  name: "brevo",

  initialize: () => {
    window.sib = {
      equeue: [],
      client_key: process.env.REACT_APP_BREVO_CLIENT_KEY || "",
    };
    window.sendinblue = {};
    for (
      let j = ["track", "identify", "trackLink", "page"], i = 0;
      i < j.length;
      i++
    ) {
      (function (k) {
        window.sendinblue[k] = function (...args: any[]) {
          const arg = Array.prototype.slice.call(args);
          (
            window.sib[k] ||
            function () {
              const t: Record<string, any> = {};
              t[k] = arg;
              window.sib.equeue.push(t);
            }
          )(arg[0], arg[1], arg[2], arg[3]);
        };
      })(j[i]);
    }
    const n = document.createElement("script");
    const i = document.getElementsByTagName("script")[0];
    n.type = "text/javascript";
    n.id = "sendinblue-js";
    n.async = !0;
    n.src = `https://sibautomation.com/sa.js?key=${window.sib.client_key}`;
    i.parentNode?.insertBefore(n, i);
    window.sendinblue.page();
  },

  page: () => {
    window.sendinblue.page();
  },

  track: ({ payload }: { payload: AnalyticsEvent<any> }) => {
    window.sendinblue.track(payload.event, null, {
      data: payload.properties,
    });
  },

  identify: ({
    payload,
  }: {
    payload: { userId: string; traits: Record<string, unknown> };
  }) => {
    if (!payload.traits.email) {
      throw new Error("Email required for Brevo");
    }
    if (
      typeof payload.traits.email !== "string" ||
      payload.traits.email.trim().length === 0
    ) {
      throw new Error("Email is wrong type or blank");
    }
    window.sendinblue.identify(payload.traits.email, {
      id: payload.userId,
      ...payload.traits,
    });
  },

  loaded: () => {
    return !!window.sendinblue;
  },
};
