export const GiftIcon: React.FC<{ showAria?: boolean }> = ({
  showAria = false,
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-label="gift icon"
      aria-hidden={!showAria}
    >
      <path
        d="M12 8.44444V20M12 8.44444C12 8.44444 12 7.11753 12 6.66667C12 5.68483 12.7959 4.88889 13.7778 4.88889C14.7596 4.88889 15.5556 5.68483 15.5556 6.66667C15.5556 7.64851 14.7596 8.44444 13.7778 8.44444C13.2468 8.44444 12 8.44444 12 8.44444ZM12 8.44444C12 8.44444 12 6.72259 12 6.22222C12 4.99492 11.0051 4 9.77778 4C8.55048 4 7.55556 4.99492 7.55556 6.22222C7.55556 7.44952 8.55048 8.44444 9.77778 8.44444H12ZM5.77778 12H18.2222M5.77778 12C4.79594 12 4 11.2041 4 10.2222C4 9.24038 4.79594 8.44444 5.77778 8.44444H18.2222C19.2041 8.44444 20 9.24038 20 10.2222C20 11.2041 19.2041 12 18.2222 12M5.77778 12V18.2222C5.77778 19.2041 6.57372 20 7.55556 20H16.4444C17.4263 20 18.2222 19.2041 18.2222 18.2222V12"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
