/* eslint-disable */
// Originally taken from https://github.com/isaacphysics/isaac-react-app/commit/ce3ba2c19969a293101daaa85c5381d4114e53a4
// And based on https://github.com/KaTeX/KaTeX/blob/v0.11.1/contrib/render-a11y-string/render-a11y-string.js

// Latest Khan version: https://github.com/KaTeX/KaTeX/blob/main/contrib/render-a11y-string/render-a11y-string.js
// Latest isaac version: https://github.com/isaacphysics/isaac-react-app/blob/master/src/app/services/katex-a11y.js
/**
 * renderA11yString returns a readable string.
 *
 * In some cases the string will have the proper semantic math
 * meaning,:
 *   renderA11yString("\\frac{1}{2}"")
 *   -> "start fraction, 1, divided by, 2, end fraction"
 *
 * However, other cases do not:
 *   renderA11yString("f(x) = x^2")
 *   -> "f, left parenthesis, x, right parenthesis, equals, x, squared"
 *
 * The commas in the string aim to increase ease of understanding
 * when read by a screenreader.
 */

import { filter } from "lodash";
import {
  stringMap,
  powerMap,
  openMap,
  closeMap,
  binMap,
  relMap,
  accentUnderMap,
  descriptiveAccentOverMap,
  denominatorMap,
  arrowMap,
  accentOverMap,
} from "./string-mappings";
import {
  genMatrixA11yJSX,
  genArrayA11yJSX,
  genAlignedA11yJSX,
} from "./matrixA11y";
var fedText: any;
declare global {
  interface Window {
    MQ: any;
    $: any;
    katex: any;
    renderA11yString: any;
  }
}

/* array for holding any JSX that should be returned along w/ an accessibility string. For instance, to make matrices accessible on an element by element basis, this can hold a 'table' of JSX divs.
 the array should consist of objects with two properties: stringToReplace, which is the accessibility string that would normally be made by passing the part of the latex which should be jsx through renderA11yString, and jsx, which has the actual jsx which should be returned instead. Before being returned, the jsx will be injected between anything which is also being passed through renderA11yString in the same place. As an example, in creating a matrix the latex may read A = \left[ <matrix> \right]. All that latex is being processed, recursively, in the same go of the function - stringToReplace would be just the <matrix> string which will be stripped and replaced w/ the jsx before returning */
let a11yJSX: {
  stringToReplace: string;
  jsx: JSX.Element;
}[] = [];

const dropZoneRegex =
  /\[drop-zone(?<params>\|(?<index>i-\d+?)?(?<width>w-\d+?)?(?<height>h-\d+?)?)?]/g;

/*variable to track whether buildString should treat a digit as part of a decimal. This needs to be scoped outside of buildString because each numerical digit is fed individually into buildString without the overall tree or array. Alternatively, we could write a buildRegion function into buildStrings which would have access to the whole tree */

let inDecimal = false;
let inFunction = 0;

/* [MB @ 12.8.2023] The interface below is used to track any settings that have been built in to give module coders some control over the accessibiltiy behavior. Such settings are stored in the format property of problem/question lines. Typing here is probably overkill since for the most part calls to renderA11yString happen at runtime, but
 */
export interface CustomSettingInterface {
  fOfX: boolean; //determines whether f(x), g(x), h(x) are read as "__ of x"
  longNumber: boolean; //when in text mode, using commas in long numbers can cause strange behavior where pieces of the number are read separatetly. The issue w/ correcting is that we rely on commas to parse coordinate pairs. Module programmers can use this setting to indicate that commas should be ignored for parsing the number
  customString: string;
}
/* default settings for accessibility customization. If needed, these are changed by an argument passed to a call to renderA11yString() */
let customSettings: CustomSettingInterface = {
  fOfX: true,
  longNumber: true,
  customString: "",
};

const buildString = (str: string, type: string, a11yStrings: string[]) => {
  if (!str) {
    return;
  }
  let ret;

  if (type === "open") {
    ret = str in openMap ? openMap[str] : stringMap[str] || str;
  } else if (type === "close") {
    ret = str in closeMap ? closeMap[str] : stringMap[str] || str;
  } else if (type === "bin") {
    ret = binMap[str] || str;
  } else if (type === "rel") {
    ret = relMap[str] || stringMap[str] || str;
  } else if (type === "arrow") {
    ret = arrowMap[str] || "arrow";
  } else {
    ret = stringMap[str] || str;
  }

  //If the text to add is a period, set it back to being treated as a regular period.
  if (str === ".") {
    ret = str;
  }

  //check to see if prior character was a period. If so, change to "point" provided that this character is not a whitespace character. It is actually fairly common for a "." to be used as a period, not a decimal, inside our math latex because otherwise the "." might wrap to a newline on its own

  /*While it may be better to test text as soon as the '.' occurs, the text is only fed into the a11yString[] array one character at a time*/

  if (a11yStrings[a11yStrings.length - 1] === ".") {
    let notWhiteSpace = /\S/;
    const charAfterPeriod = str[0];
    if (notWhiteSpace.test(charAfterPeriod)) {
      a11yStrings[a11yStrings.length - 1] = stringMap["."];
    }
  }

  // If the text to add is a number and the last string is a number, then
  // combine them into a single number - unless numerical text is part of a decimal string. Do similar if this text is inside a
  // 'start text' region.

  let numRegex = /^\d+$/;
  let startTextRegex = /^start ((bold|italic) )?text$/;
  inDecimal = str === "." || (inDecimal && numRegex.test(ret));
  if (
    (a11yStrings.length > 0 &&
      numRegex.test(ret) &&
      !inDecimal &&
      numRegex.test(a11yStrings[a11yStrings.length - 1])) ||
    (a11yStrings.length > 1 &&
      type === "normal" &&
      startTextRegex.test(a11yStrings[a11yStrings.length - 2]))
  ) {
    a11yStrings[a11yStrings.length - 1] += ret;
  } else if (ret) {
    a11yStrings.push(ret);
  }
};

const buildRegion = (a11yStrings: string[][], callback: any) => {
  const regionStrings: string[] = [];
  a11yStrings.push(regionStrings);
  callback(regionStrings);
};

const handleObject = (tree: any, a11yStrings: any, atomType: any) => {
  // Everything else is assumed to be an object...
  switch (tree.type) {
    case "accent": {
      buildRegion(a11yStrings, (a11yStrings: any) => {
        buildA11yStrings(tree.base, a11yStrings, atomType);
        a11yStrings.push("with");
        buildString(
          accentOverMap[tree.label] || tree.label,
          "normal",
          a11yStrings
        );
        a11yStrings.push("on top");
      });
      break;
    }

    case "accentUnder": {
      buildRegion(a11yStrings, (a11yStrings: any) => {
        buildA11yStrings(tree.base, a11yStrings, atomType);
        a11yStrings.push("with");
        buildString(accentUnderMap[tree.label], "normal", a11yStrings);
        a11yStrings.push("underneath");
      });
      break;
    }

    case "accent-token": {
      // Used internally by accent symbols.
      break;
    }

    case "atom": {
      const { text } = tree;
      switch (tree.family) {
        case "bin": {
          buildString(text, "bin", a11yStrings);
          break;
        }
        case "close": {
          if (inFunction > 0) {
            inFunction--;
          } else {
            buildString(text, "close", a11yStrings);
          }
          break;
        }
        // TODO(kevinb): figure out what should be done for inner
        case "inner": {
          buildString(tree.text, "inner", a11yStrings);
          break;
        }
        case "open": {
          if (
            (customSettings.fOfX &&
              ["f", "F", "g", "G", "h", "H"].includes(
                a11yStrings[a11yStrings.length - 1]
              )) ||
            (Array.isArray(a11yStrings[a11yStrings.length - 1]) &&
              ((a11yStrings[a11yStrings.length - 1][
                a11yStrings[a11yStrings.length - 1].length - 1
              ] === "end superscript" &&
                ["f", "F", "g", "G", "h", "H"].includes(
                  a11yStrings[a11yStrings.length - 3]
                )) ||
                (a11yStrings[a11yStrings.length - 1][
                  a11yStrings[a11yStrings.length - 1].length - 1
                ] === "prime" &&
                  ["f", "F", "g", "G", "h", "H"].includes(
                    a11yStrings[a11yStrings.length - 2]
                  ))))
          ) {
            inFunction++;
            a11yStrings.push(" of");
          } else {
            buildString(text, "open", a11yStrings);
          }
          break;
        }
        case "punct": {
          buildString(text, "punct", a11yStrings);
          break;
        }
        case "rel": {
          buildString(text, "rel", a11yStrings);
          break;
        }
        default: {
          console.log(`"${tree.family}" is not a valid atom type`);
          break;
        }
      }
      break;
    }

    case "color": {
      const color = tree.color.replace(/katex-/, "");

      buildRegion(a11yStrings, (regionStrings: any) => {
        // regionStrings.push("start color " + color);
        buildA11yStrings(tree.body, regionStrings, atomType);
        // regionStrings.push("end color " + color);
      });
      break;
    }

    case "color-token": {
      // Used by \color, \colorbox, and \fcolorbox but not directly rendered.
      // It's a leaf node and has no children so just break.
      break;
    }

    case "delimsizing": {
      if (tree.delim && tree.delim !== ".") {
        buildString(tree.delim, "normal", a11yStrings);
      }
      break;
    }

    case "genfrac": {
      buildRegion(a11yStrings, (regionStrings: any) => {
        // genfrac can have unbalanced delimiters
        const { leftDelim, rightDelim } = tree;

        // NOTE: Not sure if this is a safe assumption
        // hasBarLine true -> fraction, false -> binomial
        if (tree.hasBarLine) {
          const numeratorString = flatten(
            buildA11yStrings(tree.numer, [], atomType)
          ).join(",");
          const denominatorString = flatten(
            buildA11yStrings(tree.denom, [], atomType)
          ).join(",");
          if ("1" === numeratorString && denominatorString in denominatorMap) {
            regionStrings.push(`one ${denominatorMap[denominatorString]}`);
          } else {
            regionStrings.push("start fraction");
            leftDelim && buildString(leftDelim, "open", regionStrings);
            buildA11yStrings(tree.numer, regionStrings, atomType);
            regionStrings.push("divided by");
            buildA11yStrings(tree.denom, regionStrings, atomType);
            rightDelim && buildString(rightDelim, "close", regionStrings);
            regionStrings.push("end fraction");
          }
        } else {
          regionStrings.push("start binomial");
          leftDelim && buildString(leftDelim, "open", regionStrings);
          buildA11yStrings(tree.numer, regionStrings, atomType);
          regionStrings.push("over");
          buildA11yStrings(tree.denom, regionStrings, atomType);
          rightDelim && buildString(rightDelim, "close", regionStrings);
          regionStrings.push("end binomial");
        }
      });
      break;
    }
    case "hbox": {
      buildA11yStrings(tree.body, a11yStrings, atomType);
      break;
    }

    case "kern": {
      // No op: we don't attempt to present kerning information
      // to the screen reader.
      break;
    }

    case "leftright": {
      if (
        customSettings.fOfX &&
        ["f", "F", "g", "G", "h", "H"].includes(
          a11yStrings[a11yStrings.length - 1]
        ) &&
        tree.left === "("
      ) {
        a11yStrings.push(" of");
        buildA11yStrings(tree.body, a11yStrings, atomType);
      } else {
        buildRegion(a11yStrings, (regionStrings: any) => {
          buildString(tree.left, "open", regionStrings);
          buildA11yStrings(tree.body, regionStrings, atomType);
          buildString(tree.right, "close", regionStrings);
        });
      }
      break;
    }

    case "leftright-right": {
      // TODO: double check that this is a no-op
      break;
    }

    case "lap": {
      buildA11yStrings(tree.body, a11yStrings, atomType);
      break;
    }

    case "mathord": {
      buildString(tree.text, "normal", a11yStrings);
      break;
    }

    case "op": {
      const { body, name } = tree;
      if (body) {
        buildA11yStrings(body, a11yStrings, atomType);
      } else if (name) {
        buildString(name, "normal", a11yStrings);
      }
      break;
    }

    case "op-token": {
      // Used internally by operator symbols.
      buildString(tree.text, atomType, a11yStrings);
      break;
    }

    case "ordgroup": {
      buildA11yStrings(tree.body, a11yStrings, atomType);
      break;
    }

    case "overline": {
      buildRegion(a11yStrings, function (a11yStrings: any) {
        a11yStrings.push("start overline");
        buildA11yStrings(tree.body, a11yStrings, atomType);
        a11yStrings.push("end overline");
      });
      break;
    }

    case "pmb": {
      a11yStrings.push("bold");
      break;
    }

    case "phantom": {
      // a11yStrings.push("empty space");
      break;
    }

    case "raisebox": {
      buildA11yStrings(tree.body, a11yStrings, atomType);
      break;
    }

    case "rule": {
      a11yStrings.push("rectangle");
      break;
    }

    case "sizing": {
      buildA11yStrings(tree.body, a11yStrings, atomType);
      break;
    }

    case "spacing": {
      a11yStrings.push("space");
      break;
    }

    case "styling": {
      // We ignore the styling and just pass through the contents
      buildA11yStrings(tree.body, a11yStrings, atomType);
      break;
    }

    case "sqrt": {
      buildRegion(a11yStrings, (regionStrings: any) => {
        const { body, index } = tree;
        if (index) {
          const indexString = flatten(
            buildA11yStrings(index, [], atomType)
          ).join(",");
          if (indexString === "3") {
            regionStrings.push("cube root of");
            buildA11yStrings(body, regionStrings, atomType);
            regionStrings.push("end cube root");
            return;
          }

          regionStrings.push("start root");
          regionStrings.push("start index");
          buildA11yStrings(index, regionStrings, atomType);
          regionStrings.push("end index");
          buildA11yStrings(body, regionStrings, atomType);
          regionStrings.push("end root");
          return;
        }

        regionStrings.push("square root of");
        buildA11yStrings(body, regionStrings, atomType);
        regionStrings.push("end square root");
      });
      break;
    }

    case "supsub": {
      const { base, sub, sup } = tree;
      const opType = base && base.type === "op" ? base.name : null;
      let isSimpleSubscriptVariable = false;
      let supString = "";
      if (sup) {
        supString = flatten(buildA11yStrings(sup, [], atomType)).join(" ");
      }
      const descriptiveSuperscript =
        sup && supString in descriptiveAccentOverMap;
      // case e.g. "q_{1}q_{2}" to be read as "q one q two":
      if (base && base.type === "mathord" && sub) {
        let baseString = flatten(buildA11yStrings(base, [], atomType)).join(
          ","
        );
        //join w/ ',' won't leave a trailing comma, which should be there for parsing correctly
        baseString += ",";
        const subscriptString = flatten(
          buildA11yStrings(sub, [], atomType)
        ).join(",");
        if (
          /^[a-zA-Z]$/.test(baseString) &&
          /^([0-9]+|\\textrm{(min|max)})$/.test(subscriptString)
        ) {
          buildRegion(a11yStrings, function (regionStrings: any) {
            regionStrings.push(`${baseString} ${subscriptString}`);
          });
          isSimpleSubscriptVariable = true;
        }
      }

      //if there is a 'descriptive superscript' (ex: \frown for arc) push string prior to building base
      if (descriptiveSuperscript) {
        buildRegion(a11yStrings, function (regionStrings: any) {
          regionStrings.push(`${descriptiveAccentOverMap[supString]}`);
        });
      }

      // otherwise ordinary base, subscript and superscript:

      if (base && !isSimpleSubscriptVariable) {
        buildA11yStrings(base, a11yStrings, atomType);
      }

      if (sub && !isSimpleSubscriptVariable) {
        switch (opType) {
          case "\\log":
            buildRegion(a11yStrings, function (regionStrings: any) {
              regionStrings.push(`base`);
              buildA11yStrings(sub, regionStrings, atomType);
            });
            break;
          case "\\int":
          case "\\sum":
            buildRegion(a11yStrings, function (regionStrings: any) {
              regionStrings.push(`from`);
              buildA11yStrings(sub, regionStrings, atomType);
            });
            break;
          default:
            buildRegion(a11yStrings, function (regionStrings: any) {
              regionStrings.push(`start subscript`);
              buildA11yStrings(sub, regionStrings, atomType);
              regionStrings.push(`end subscript`);
            });
        }
      }

      if (sup) {
        buildRegion(a11yStrings, function (regionStrings: any) {
          //moving this variable definition earlier b/c in some cases of sup we will need to build the sup string prior to the base
          // const supString = flatten(buildA11yStrings(sup, [], atomType)).join(
          //   " "
          // );

          switch (opType) {
            case "\\int":
            case "\\sum":
              buildRegion(a11yStrings, function (regionStrings: any) {
                regionStrings.push(`to`);
                buildA11yStrings(sup, regionStrings, atomType);
                regionStrings.push(`of`);
              });
              break;
            default:
              if (supString in powerMap) {
                regionStrings.push(powerMap[supString]);
                return;
              }
              if (/^((minus )?[0-9]+|[A-Za-z])$/.test(supString)) {
                regionStrings.push(`to the power ${supString} `);
                return;
              }
              //don't default to start/end if the 'superscript' is really just notation for something like arc, segment. The reason these are not handled in this switch is that strings like 'arc' or 'segment' should be pushed prior to building the base string, which happens earlier
              if (!descriptiveSuperscript) {
                buildRegion(a11yStrings, function (regionStrings: any) {
                  regionStrings.push(`start superscript`);
                  buildA11yStrings(sup, regionStrings, atomType);
                  regionStrings.push(`end superscript`);
                });
              }
          }
        });
      } else if (sub && (opType === "\\int" || opType === "\\sum")) {
        buildRegion(a11yStrings, function (regionStrings: any) {
          regionStrings.push(`of`);
        });
      }
      break;
    }

    case "text": {
      // TODO: handle other fonts
      let modifier: any;
      switch (tree.font) {
        case "\\textbf":
          modifier = "bold";
          break;
        case "\\textit":
          modifier = "italic";
          break;
        default:
          modifier = "";
      }
      buildRegion(a11yStrings, function (regionStrings: any) {
        if (
          tree.body
            .map((a: any) => (a.hasOwnProperty("text") ? a.text : ""))
            .join("")
            .search(dropZoneRegex) !== -1
        ) {
          regionStrings.push("clickable drop zone");
        } else {
          /* Original treatment of \text elements: */
          //regionStrings.push(`start ${modifier} text`.replace(/\s+/, " "));
          //buildA11yStrings(tree.body, regionStrings, atomType);
          //regionStrings.push(`end ${modifier} text`.replace(/\s+/, " "));

          /* Updated treatment of \text elements (builds string based on all textord nodes and spacing nodes within the \text block) */
          // const textordArr = tree.body.map((node: any) => node.text);
          // const textStr = textordArr.join("");
          // regionStrings.push(textStr);

          /* Updated above approach to ensure that 'body' arrays/objects inside of a tree starting w/ text type also get searched. There were issues when latex was nested inside \text{} (ex: \text{This is regular text \textit{this is ital}}) where the inner latex was not getting read because the actual text nodes were embedded inside a body (there was no .text at the top level object in the tree.body array) */

          //reducer function called recursively to search through full depth of tree and can be set up with various conditionals to call out text stylings w/in the 'text' tree body

          const textTreeReducer = function (prev: any, current: any): any {
            //if the current node has a .text, add it to the string - check for string map if not a space
            if (current.text !== undefined) {
              //While in text mode, there are some characters, like brackets, which will needs spaces added before or after for the SR b/c you wouldn't type the spaces in. For 'math' this is already handled by separating everything with ", ". We can add more to these as needed
              const addSpaceAfterList = [
                "(",
                "[",
                "\\{",
                "\\lbrace",
                "\\lvert",
                "\\$",
              ];
              const addSpaceBeforeList = [
                ")",
                "]",
                "\\}",
                "\\rbrace",
                "\\rvert",
              ];
              const afterSpace = addSpaceAfterList.includes(current.text)
                ? " "
                : "";
              const beforeSpace = addSpaceBeforeList.includes(current.text)
                ? " "
                : "";
              const exclude = [
                ",",
                ".",
                ":",
                "?",
                "%",
                " ",
                "\\ ",
                "'",
                "/",
                "-",
              ];
              return (
                prev +
                beforeSpace +
                ((!exclude.includes(current.text) && stringMap[current.text]) ||
                  current.text) +
                afterSpace
              );
              //if the type is text, we can read the font and put in whatever modification we want
            } else if (current.body && current.type === "text") {
              // let modifier = "";

              //If we want we can call out text styling

              // switch (current.font) {
              //     case `\\text`:
              //         return prev + current.body.reduce(textTreeReducer, '')
              //     case `\\textit`:
              //         modifier = 'italics'
              //         break
              //     case '\\textbf':
              //         modifier = 'bold'
              // }
              // return prev + `start ${modifier} ` + current.body.reduce(textTreeReducer,'') + ` end ${modifier}`

              return prev + current.body.reduce(textTreeReducer, "");
            } else if (current.body instanceof Array) {
              return prev + current.body.reduce(textTreeReducer, "");
            } else if (current.body) {
              return textTreeReducer(prev, current.body);
            } else return prev;
          };

          //reduce the tree body into string using above reducer and add to regionStrings
          const textStr = tree.body.reduce(textTreeReducer, "");
          regionStrings.push(textStr);
        }
      });
      break;
    }

    case "textord": {
      buildString(tree.text, atomType, a11yStrings);
      break;
    }

    case "smash": {
      buildA11yStrings(tree.body, a11yStrings, atomType);
      break;
    }

    case "enclose": {
      // TODO: create a map for these.
      // TODO: differentiate between a body with a single atom, e.g.
      // "cancel a" instead of "start cancel, a, end cancel"
      if (/cancel/.test(tree.label)) {
        buildRegion(a11yStrings, function (regionStrings: any) {
          regionStrings.push("start cancel");
          buildA11yStrings(tree.body, regionStrings, atomType);
          regionStrings.push("end cancel");
        });
        break;
      } else if (/box/.test(tree.label)) {
        buildRegion(a11yStrings, function (regionStrings: any) {
          regionStrings.push("start box");
          buildA11yStrings(tree.body, regionStrings, atomType);
          regionStrings.push("end box");
        });
        break;
      } else if (/sout/.test(tree.label)) {
        buildRegion(a11yStrings, function (regionStrings: any) {
          regionStrings.push("start strikeout");
          buildA11yStrings(tree.body, regionStrings, atomType);
          regionStrings.push("end strikeout");
        });
        break;
      }
      console.log(
        `KaTeX-a11y: enclose node with ${tree.label} not supported yet`
      );
      break;
    }

    case "vcenter": {
      buildA11yStrings(tree.body, a11yStrings, atomType);
      break;
    }

    case "vphantom": {
      console.log("KaTeX-a11y: vphantom not implemented yet");
      break;
    }

    case "hphantom": {
      console.log("KaTeX-a11y: hphantom not implemented yet");
      break;
    }

    case "operatorname": {
      buildA11yStrings(tree.body, a11yStrings, atomType);
      break;
    }

    case "array": {
      /* Instead of a general approach to "array" type, it could make sense to have at least some specifics.
      currently we have implementations for cases and matrices
      Determining if the tree contains cases does not seem 100% straightforward (welcome to suggestions beyond what I did).
      */

      /* SOME HELPER FUNCTIONS */

      //Function to find a tree node that has the raw latex input and return it. Not necessarily an ideal way to differentiate among the latex structures which are categorized as 'array' type but generally works. An alternative would be editing the katex source code to specify whether a treenode is cases, matrix, etc.
      const findLatexInput = function (treeNode: any): any {
        //base case 1: there is input
        if (!treeNode) {
          return "none";
        }
        if (treeNode?.loc?.lexer?.input) {
          return treeNode.loc.lexer.input;
        }
        if (treeNode.body) {
          return findLatexInput(treeNode.body);
        }
        if (treeNode instanceof Array) {
          if (treeNode.length > 0) {
            return findLatexInput(treeNode[0]);
          }
          return "none";
        }
        if (treeNode instanceof Object) {
          for (const prop in treeNode) {
            if (treeNode[prop].body) {
              return findLatexInput(treeNode[prop].body);
            }
            if (treeNode[prop] instanceof Object) {
              return findLatexInput(treeNode[prop]);
            }
          }
        }
        return "none";
      };
      //function which uses the parsing from this file to create accessible strings and populates a nested array which is then fed to matrixA11y.tsx to map to JSX. JSX generation is separated out for no other reason than to keep this file .ts and not .tsx to avoid any merging issues
      const getMatrixAria = function (): any {
        /* The general approach here is to create a table-like structure (for now using <divs>) in the DOM which is not visible but is exposed to the screen reader. Using <divs> will prevent the screen-reader from trying to read the entire table at once and gives some control over reader behavior (or rather, doesn't introduce behavior which may be superfluous). The <divs> are created as JSX and are actually generated in matrixA11y.jsx
         */

        //variable to hold strings for matrix descriptions
        const matrixRows: any = [];
        //fill matrixRows w/ arrays which contain the strings for each element
        tree.body.forEach(function (row: any, rowNum: number) {
          const matrixRow: any = [];
          row.forEach(function (e: any, colNum: number) {
            buildRegion([], function (regionStrings: any) {
              regionStrings.push(`row ${rowNum + 1}, column ${colNum + 1}`);
              buildA11yStrings(
                tree.body[rowNum][colNum],
                regionStrings,
                atomType
              );
              matrixRow.push(flatten(regionStrings).join(", "));
            });
          });
          matrixRows.push(matrixRow);
        });
        //call to function inside matrixA11y.jsx which actually creates the jsx
        const matrixJSX = genMatrixA11yJSX(matrixRows);

        return matrixJSX;
      };

      const getArrayAria = function (): any {
        /* The general approach here is to create a table-like structure (for now using <divs>) in the DOM which is not visible but is exposed to the screen reader. Using <divs> will prevent the screen-reader from trying to read the entire table at once and gives some control over reader behavior (or rather, doesn't introduce behavior which may be superfluous). The <divs> are created as JSX and are actually generated in matrixA11y.jsx
         */

        //variable to hold strings for matrix descriptions
        const arrayRows: any = [];
        //fill matrixRows w/ arrays which contain the strings for each element
        tree.body.forEach(function (row: any, rowNum: number) {
          const arrayRow: any = [];
          row.forEach(function (e: any, colNum: number) {
            buildRegion([], function (regionStrings: any) {
              regionStrings.push(`row ${rowNum + 1}, column ${colNum + 1}`);
              buildA11yStrings(
                tree.body[rowNum][colNum],
                regionStrings,
                atomType
              );
              arrayRow.push(flatten(regionStrings).join(", "));
            });
          });
          arrayRows.push(arrayRow);
        });
        //call to function inside matrixA11y.jsx which actually creates the jsx
        const ArrayJSX = genArrayA11yJSX(arrayRows);

        return ArrayJSX;
      };

      const getAlignedAria = function (): any {
        const alignedRows: any = [];
        tree.body.forEach(function (row: any, rowNum: number) {
          const alignedRow: any = [];
          row.forEach(function (e: any, colNum: number) {
            buildRegion([], function (regionStrings: any) {
              buildA11yStrings(
                tree.body[rowNum][colNum],
                regionStrings,
                atomType
              );
              alignedRow.push(flatten(regionStrings).join(", "));
            });
          });
          alignedRows.push(alignedRow);
        });
        //call to function inside matrixA11y.jsx which actually creates the jsx
        const alignedJSX = genAlignedA11yJSX(alignedRows);

        return alignedJSX;
      };

      const arrayLatex = findLatexInput(tree);

      const isCases = arrayLatex.includes("begin{cases}");

      const isAlignedAt = arrayLatex.includes("begin{alignedat}");

      const matrixTest = [
        "matrix",
        "pmatrix",
        "bmatrix",
        "Bmatrix",
        "vmatrix",
        "Vmatrix",
      ];
      let isMatrix = false;
      matrixTest.forEach(function (matrix) {
        if (arrayLatex.includes(`begin{${matrix}}`)) {
          isMatrix = true;
        }
      });

      /* STRING FOR CASES */

      /* Implementation for \begin{cases}, which falls under "array" type */
      if (isCases) {
        buildRegion(a11yStrings, function (a11yStrings: any) {
          a11yStrings.push("begin cases");
          tree.body.forEach((e: any, i: number) => {
            a11yStrings.push(`begin case ${i + 1}`);
            buildA11yStrings(e, a11yStrings, atomType);
            a11yStrings.push(`end case ${i + 1}`);
          });
          a11yStrings.push("end cases");
        });
        break;
      }

      // console.log("KaTeX-a11y: array not implemented yet");

      /* MATRICES */

      if (isMatrix) {
        /* push strings which describe matrix to the a11yString array and to the matrixStrArr - a single string will still be returned as a sort of default option 
        Note: the matrixStrArr is used here to get the entirety of the accessibility string which will be created. Trying to copy a11yStrings turns out to be tricky b/c of the recursive nature of the functions here. Having the string here allows for the matrix JSX to be appropriately placed later. See renderA11yString below, but the idea is to make sure that the matrix JSX comes after whatever latex was before \begin matrix and then before whatever other latex is in the string being parsed.
        */
        let matrixStrArr: any = [];
        buildRegion(a11yStrings, function (a11yStrings: any) {
          a11yStrings.push("begin matrix");
          matrixStrArr.push("begin matrix");
          tree.body.forEach((e: any, i: number) => {
            a11yStrings.push(`begin row ${i + 1}`);
            matrixStrArr.push(`begin row ${i + 1}`);
            e.forEach((val: any, colNum: number) => {
              a11yStrings.push(`column ${colNum + 1}`);
              buildA11yStrings(val, a11yStrings, atomType);
              matrixStrArr.push(`column ${colNum + 1}`);
              buildA11yStrings(val, matrixStrArr, atomType);
            });
          });
          a11yStrings.push("end matrix");
          matrixStrArr.push("end matrix");
        });
        const matrixString = flatten(matrixStrArr).join(", ");
        const matrixAria = getMatrixAria();
        a11yJSX.push({ stringToReplace: matrixString, jsx: matrixAria });
        break;
      }

      if (isAlignedAt) {
        a11yJSX = [];
        let alignedStrArr: any = [];
        buildRegion(a11yStrings, function (a11yStrings: any) {
          tree.body.forEach((e: any, i: number) => {
            e.forEach((val: any, colNum: number) => {
              buildA11yStrings(val, a11yStrings, atomType);
              buildA11yStrings(val, alignedStrArr, atomType);
            });
          });
        });
        const alignedString = flatten(alignedStrArr).join(", ");
        const alignedAria = getAlignedAria();
        a11yJSX.push({ stringToReplace: alignedString, jsx: alignedAria });
        break;
      }

      /* GENERAL ARRAY APPROACH*/

      let arrayStrArr: any = [];
      buildRegion(a11yStrings, function (a11yStrings: any) {
        a11yStrings.push("begin array");
        arrayStrArr.push("begin array");
        tree.body.forEach((e: any, i: number) => {
          a11yStrings.push(`begin row ${i + 1}`);
          arrayStrArr.push(`begin row ${i + 1}`);
          e.forEach((val: any, colNum: number) => {
            a11yStrings.push(`column ${colNum + 1}`);
            buildA11yStrings(val, a11yStrings, atomType);
            arrayStrArr.push(`column ${colNum + 1}`);
            buildA11yStrings(val, arrayStrArr, atomType);
          });
        });
        a11yStrings.push("end array");
        arrayStrArr.push("end array");
      });
      const arrayString = flatten(arrayStrArr).join(", ");
      const arrayAria = getArrayAria();
      a11yJSX.push({ stringToReplace: arrayString, jsx: arrayAria });

      break;
    }

    case "raw": {
      console.log("KaTeX-a11y: raw not implemented yet");
      break;
    }

    case "size": {
      // Although there are nodes of type "size" in the parse tree, they have
      // no semantic meaning and should be ignored.
      break;
    }

    case "url": {
      console.log("KaTeX-a11y: url not implemented yet");
      break;
    }

    case "tag": {
      console.log("KaTeX-a11y: tag not implemented yet");
      break;
    }

    case "verb": {
      buildString(`start verbatim`, "normal", a11yStrings);
      buildString(tree.body, "normal", a11yStrings);
      buildString(`end verbatim`, "normal", a11yStrings);
      break;
    }

    case "environment": {
      console.log("KaTeX-a11y: environment not implemented yet");
      break;
    }

    case "horizBrace": {
      buildString(`start ${tree.label.slice(1)}`, "normal", a11yStrings);
      buildA11yStrings(tree.base, a11yStrings, atomType);
      buildString(`end ${tree.label.slice(1)}`, "normal", a11yStrings);
      break;
    }

    case "infix": {
      // All infix nodes are replace with other nodes.
      break;
    }

    case "includegraphics": {
      console.log("KaTeX-a11y: includegraphics not implemented yet");
      break;
    }

    case "font": {
      // TODO: callout the start/end of specific fonts
      // TODO: map \BBb{N} to "the naturals" or something like that
      buildA11yStrings(tree.body, a11yStrings, atomType);
      break;
    }

    case "href": {
      console.log("KaTeX-a11y: href not implemented yet");
      break;
    }

    case "cr": {
      // This is used by environments and newlines.
      buildRegion(a11yStrings, function (a11yStrings: any) {
        if (tree.newLine || tree.newRow) {
          a11yStrings.push(". ");
        }
      });
      break;
    }

    case "underline": {
      if (tree.body.body[0]?.type === "phantom") {
        a11yStrings.push("blank space");
        break;
      }
      buildRegion(a11yStrings, function (a11yStrings: any) {
        a11yStrings.push("start underline");
        buildA11yStrings(tree.body, a11yStrings, atomType);
        a11yStrings.push("end underline");
      });
      break;
    }

    case "xArrow": {
      buildString(tree.label, "arrow", a11yStrings);
      break;
    }

    case "cdlabel": {
      console.log("KaTeX-a11y: cdlabel not implemented yet");
      break;
    }

    case "cdlabelparent": {
      console.log("KaTeX-a11y: cdlabelparent not implemented yet");
      break;
    }

    case "mclass": {
      // \neq and \ne are macros so we let "htmlmathml" render the mathmal
      // side of things and extract the text from that.
      const atomType = tree.mclass.slice(1);
      // $FlowFixMe: drop the leading "m" from the values in mclass
      buildA11yStrings(tree.body, a11yStrings, atomType);
      break;
    }

    case "mathchoice": {
      // TODO: track which which style we're using, e.g. dispaly, text, etc.
      // default to text style if even that may not be the correct style
      buildA11yStrings(tree.text, a11yStrings, atomType);
      break;
    }

    case "htmlmathml": {
      buildA11yStrings(tree.mathml, a11yStrings, atomType);
      break;
    }

    case "middle": {
      buildString(tree.delim, atomType, a11yStrings);
      break;
    }

    case "internal": {
      // internal nodes are never included in the parse tree
      break;
    }

    case "html": {
      buildA11yStrings(tree.body, a11yStrings, atomType);
      break;
    }

    default:
      console.log("KaTeX a11y un-recognized type: " + tree.type);
      break;
  }
};

const buildA11yStrings = (tree: any, a11yStrings: any, atomType: any) => {
  if (tree instanceof Array) {
    for (let i = 0; i < tree.length; i++) {
      buildA11yStrings(tree[i], a11yStrings, atomType);
    }
  } else {
    handleObject(tree, a11yStrings, atomType);
  }

  return a11yStrings;
};

const flatten = function (array: any) {
  let result: any = [];

  array.forEach(function (item: any) {
    if (item instanceof Array) {
      result = result.concat(flatten(item));
    } else {
      result.push(item);
    }
  });

  return result;
};

export const renderA11yString = function (
  text: any,
  settings?: any,
  //when implementing matrices, function changed to allow for JSX to return. The below parameter was added w/ a default of false so that existing calls to renderA11yString would be unaffected unless specifically altered to allow for JSX if there is any
  allowReturnJSX: boolean = false,
  programmerSettings: CustomSettingInterface | false = false
): string | { a11yString: string; a11yJSX: JSX.Element[] } {
  //need to reset decimal status so that a number added, say, in an addLine() call after a previous call ended with a decimal is not considered part of that decimal
  inDecimal = false;
  if (programmerSettings) {
    Object.assign(customSettings, programmerSettings);
  }
  try {
    if (customSettings.customString !== "") {
      text = customSettings.customString;
      customSettings.customString = "";
    }
    text = preProcess(text);
    const tree = window.katex.__parse(text, settings);
    fedText = text;
    let a11yStrings = buildA11yStrings(tree, [], "normal");
    a11yStrings = filter(a11yStrings, (str) => {
      return str !== "start underbrace" && str !== "end underbrace";
    });

    //[MB] - This is a highly specific check and fix but for a very specific situation. Normally in math mode letters are separated by commas. If a latex construct has a single variable in math mode and then immediately goes to text, there wouldn't be a need for a comma. This is normally fine except in the case of the letter 'a' which the screen reader will read as the english word 'a' unless there is a comma. This is a fix for that specific situation. (The screen reader in other words does not really have a "math mode" when it comes to letters.) I chose to do this here for visibility and to avoid changing the main katex logic for producing a11y strings.

    if (
      a11yStrings.length === 1 &&
      (a11yStrings[0] === "a" ||
        (Array.isArray(a11yStrings[0]) &&
          a11yStrings[0].length === 1 &&
          a11yStrings[0][0] === "a" &&
          tree.length === 1)) &&
      tree[0].mode === "math"
    ) {
      return "a,";
    }
    const a11yString = flatten(a11yStrings).join(", ");
    //stick to default behavior unless allowJSX has been set to true in the function call AND the a11yJSX array was actually filled
    if (a11yJSX.length === 0 || !allowReturnJSX) {
      return a11yString;
    } else {
      try {
        let surroundingStr = a11yString;
        let jsxToReturn: JSX.Element[] = [];
        /*for each object in a11yJSX array, create an array where the 0 element is any text which comes before the structure being rendered in jsx (or the jsx itself if none), 
        the 1 element is the jsx if there was such text, and the [2] element is anything that comes after the structure being rendered in jsx */
        a11yJSX.forEach((e: any, i: number) => {
          const { stringToReplace, jsx } = e;
          const surroundingStrArr = surroundingStr.split(stringToReplace);
          jsxToReturn.push(surroundingStrArr[0], jsx);
          surroundingStr = surroundingStrArr[1] || "";
          if (i === a11yJSX.length - 1) {
            jsxToReturn.push(surroundingStr);
          }
        });
        a11yJSX = [];
        return { a11yString, a11yJSX: jsxToReturn };
      } catch (e) {
        console.error("Error creating a11yJSX: " + e);
        return a11yString;
      }
    }
  } catch (e) {
    console.error("KaTeX renderA11yString error: " + e);
    return "";
  }
};

//function to take a raw text string and make some useful replacements we can use to control what gets parsed intro the the katex tree. This can be particularly useful reducing multiple characters into a single latex command or an something that we can apply a stringMap to

function preProcess(text: string): string {
  if (!text) return "";
  return text.replace(/\.\.\./g, "\\ldots");
}

// export default renderA11yString;
