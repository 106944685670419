import { useLearnerContext } from "../contexts/LearnerContext";
import { GameKey } from "../types";
import { getStartOfDay } from "./getStartOfDay";

export function useIsGameUnlocked(gameKey: GameKey): boolean {
  const { learner } = useLearnerContext();

  if (!learner) return false;

  const existingGameState = learner.prizesState && learner.prizesState[gameKey];
  // user already played today
  if (
    existingGameState &&
    new Date(existingGameState.lastWinDate) >= getStartOfDay(learner.timezone)
  ) {
    return false;
  }

  return true;
}
