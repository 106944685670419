export const SparkleIcon: React.FC<{
  className?: string;
  strokeWidth?: number;
}> = ({ className = "", strokeWidth = 1 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      className={className}
      aria-hidden={true}
    >
      <path
        d="M5.4065 7.952L5 9.375L4.5935 7.952C4.48844 7.58443 4.29145 7.24969 4.02113 6.97937C3.75081 6.70905 3.41607 6.51206 3.0485 6.407L1.625 6L3.048 5.5935C3.41557 5.48844 3.75031 5.29145 4.02063 5.02113C4.29095 4.75081 4.48794 4.41607 4.593 4.0485L5 2.625L5.4065 4.048C5.51156 4.41557 5.70855 4.75031 5.97887 5.02063C6.24919 5.29095 6.58393 5.48794 6.9515 5.593L8.375 6L6.952 6.4065C6.58443 6.51156 6.24969 6.70855 5.97937 6.97887C5.70905 7.24919 5.51206 7.58393 5.407 7.9515L5.4065 7.952ZM9.6295 4.3575L9.5 4.875L9.3705 4.3575C9.29637 4.06079 9.14301 3.78981 8.9268 3.57352C8.7106 3.35722 8.43968 3.20375 8.143 3.1295L7.625 3L8.143 2.8705C8.43968 2.79625 8.7106 2.64278 8.9268 2.42648C9.14301 2.21019 9.29637 1.93921 9.3705 1.6425L9.5 1.125L9.6295 1.6425C9.70367 1.93927 9.8571 2.21029 10.0734 2.42659C10.2897 2.64289 10.5607 2.79633 10.8575 2.8705L11.375 3L10.8575 3.1295C10.5607 3.20367 10.2897 3.35711 10.0734 3.57341C9.8571 3.78971 9.70367 4.06073 9.6295 4.3575ZM8.947 10.2835L8.75 10.875L8.553 10.2835C8.49777 10.1178 8.40472 9.96726 8.28123 9.84377C8.15774 9.72028 8.00718 9.62723 7.8415 9.572L7.25 9.375L7.8415 9.178C8.00718 9.12277 8.15774 9.02972 8.28123 8.90623C8.40472 8.78274 8.49777 8.63218 8.553 8.4665L8.75 7.875L8.947 8.4665C9.00223 8.63218 9.09528 8.78274 9.21877 8.90623C9.34226 9.02972 9.49282 9.12277 9.6585 9.178L10.25 9.375L9.6585 9.572C9.49282 9.62723 9.34226 9.72028 9.21877 9.84377C9.09528 9.96726 9.00223 10.1178 8.947 10.2835Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth={strokeWidth}
        strokeLinejoin="round"
      />
    </svg>
  );
};
