import { getFilePath } from "../../../utils";
import { useMediaQuery } from "usehooks-ts";
import { ResponsiveImage } from "../ResponsiveImage";

export const Stat: React.FC = () => {
  const isHighDpi = useMediaQuery("screen and (min-resolution: 73dpi)");
  const bgImage = getFilePath(
    isHighDpi
      ? "/images/learner/home/bg-green-squiggles@2x.png"
      : "/images/learner/home/bg-green-squiggles.png"
  );

  return (
    <section className="w-full overflow-hidden py-10 md:py-28">
      <div
        className="relative flex h-[311px] w-full items-center justify-center px-6"
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "1248px 311px",
        }}
      >
        <p className="w-[650px] max-w-full text-center font-serif text-2xl md:text-3xl xl:w-full">
          <strong className="text-3xl md:text-[40px]">100%</strong> of learners
          report feeling more confident after using DeltaMath for Home.
        </p>

        <ResponsiveImage
          className="absolute -top-20 right-1/2 -mr-[380px] hidden md:block xl:-mr-[580px]"
          srcs={[
            getFilePath("/images/learner/home/cap.png"),
            getFilePath("/images/learner/home/cap@2x.png"),
          ]}
          alt="Graduation cap"
          aria-hidden
        />
        <ResponsiveImage
          className="absolute -top-4 right-1/2 -mr-[550px] hidden md:block xl:-mr-[770px]"
          srcs={[
            getFilePath("/images/learner/home/graph.png"),
            getFilePath("/images/learner/home/graph@2x.png"),
          ]}
          alt="Graduation cap"
          aria-hidden
        />
        <ResponsiveImage
          className="absolute -bottom-28 right-1/2 -mr-[490px] hidden md:block xl:-mr-[710px]"
          srcs={[
            getFilePath("/images/learner/home/backpack.png"),
            getFilePath("/images/learner/home/backpack@2x.png"),
          ]}
          alt="Graduation cap"
          aria-hidden
        />
      </div>
    </section>
  );
};
