export const GraduationCapIcon: React.FC<{ showAria?: boolean }> = ({
  showAria = false,
}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="graduation cap"
    aria-hidden={!showAria}
  >
    <path
      d="M5.40356 10.0148C5.15493 11.9181 5.00678 13.8331 4.95973 15.752C7.56197 16.839 10.0536 18.174 12.4 19.7384C14.7467 18.1739 17.2386 16.839 19.8412 15.752C19.7941 13.8331 19.6459 11.9181 19.3973 10.0148M19.3973 10.0148C20.1883 9.74901 20.9901 9.50314 21.8 9.27897C18.8501 7.211 15.7011 5.44258 12.4 4C9.09883 5.44288 5.94989 7.2116 3 9.27987C3.80746 9.50281 4.60864 9.74787 5.40265 10.0148C7.81491 10.8258 10.1554 11.8363 12.4 13.0357C14.6442 11.8363 16.9854 10.8258 19.3973 10.0148ZM7.65435 14.4016C7.83415 14.4016 8.00659 14.3301 8.13373 14.203C8.26087 14.0759 8.3323 13.9034 8.3323 13.7236C8.3323 13.5438 8.26087 13.3714 8.13373 13.2442C8.00659 13.1171 7.83415 13.0457 7.65435 13.0457C7.47455 13.0457 7.30211 13.1171 7.17497 13.2442C7.04783 13.3714 6.9764 13.5438 6.9764 13.7236C6.9764 13.9034 7.04783 14.0759 7.17497 14.203C7.30211 14.3301 7.47455 14.4016 7.65435 14.4016ZM7.65435 14.4016V11.0796C9.18809 10.1263 10.7723 9.25661 12.4 8.47447M6.06614 18.9149C6.57051 18.4118 6.97047 17.8139 7.24303 17.1557C7.51559 16.4975 7.65537 15.7919 7.65435 15.0795V13.7236"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
