import { PropsWithChildren } from "react";
import { TriangleStarsIcon } from "../../../shared/icons/TriangleStarsIcon";
import { DashboardCard } from "./DashboardCard";
import Button from "../../../student/components/generic/button";
import { NavLink } from "react-router-dom";
import { LockedTriangleBackground } from "./LockedTriangleBackground";
import { RoundedLockIcon } from "../Points/RoundedLockIcon";
import { useLearnerContext } from "../../contexts/LearnerContext";
import { MIN_POINTS_TO_UNLOCK } from "../../constants";
import { Skeleton, SkeletonText } from "../Skeleton";
import { PointsHexagon } from "../Points/PointsHexagon";
import { UnlockedTriangleBackground } from "./UnlockedTriangleBackground";
import { REACT_APP_LEARNER_LINK, getFilePath } from "../../../utils";
import { isPascalsRowCompletable } from "../../utils/isPascalsRowCompletable";

const Title: React.FC<PropsWithChildren<{ as?: string }>> = ({
  as = "h3",
  children,
}) => {
  const Tag = as as keyof JSX.IntrinsicElements;
  return <Tag className="font-serif text-lg font-bold">{children}</Tag>;
};

const PrizesLink: React.FC = () => (
  <NavLink
    to={`${REACT_APP_LEARNER_LINK}/points/prizes`}
    className="flex shrink-0 items-center gap-2 text-dm-brand-blue-500 hover:text-dm-brand-blue-600"
  >
    <i className="far fa-gift text-lg" aria-hidden="true"></i>
    View All Prizes
  </NavLink>
);

export const PascalsTriangleCard: React.FC = () => {
  const { pointsAvailable, learner } = useLearnerContext();

  const LeftContent = () => {
    if (learner === undefined || pointsAvailable === null) {
      return (
        <>
          <Title>
            <SkeletonText>
              Mollit sit elit eiusmod officia do duis anim aute in non.
            </SkeletonText>
          </Title>
          <div className="flex items-center gap-6 text-sm">
            <div className="h-10 w-32">
              <Skeleton></Skeleton>
            </div>
            <SkeletonText>
              <PrizesLink />
            </SkeletonText>
          </div>
        </>
      );
    }
    if (
      learner.pascalsRow === undefined &&
      pointsAvailable < MIN_POINTS_TO_UNLOCK
    ) {
      return (
        <>
          <Title>
            Earn {MIN_POINTS_TO_UNLOCK} points to play Pascal&apos;s Triangle
            <br className="md:hidden xl:block" />
            and win prizes for each row you unlock!
          </Title>
          <div className="mt-2 flex flex-wrap items-center gap-x-6 gap-y-2 text-sm">
            <Button
              type="outline"
              className="shrink-0 border-2 !py-1 font-bold"
              href={`${REACT_APP_LEARNER_LINK}/points`}
            >
              Explore Points
            </Button>
            <PrizesLink />
          </div>
        </>
      );
    }
    if (learner.pascalsRow === undefined) {
      return (
        <>
          <h3 className="flex items-end gap-2 font-serif">
            <PointsHexagon points={pointsAvailable} />
            pts available
          </h3>
          <Title as="h4">
            You&rsquo;re ready to unlock Pascal&rsquo;s triangle!
            <br />
            Use your points to play and start earning cool prizes.
          </Title>
          <div className="mt-3 flex flex-wrap items-center gap-x-6 gap-y-2 text-sm md:mt-6">
            <Button
              type="points"
              className="!py-1"
              href={`${REACT_APP_LEARNER_LINK}/points`}
            >
              Play Pascal&apos;s Triangle
            </Button>
            <PrizesLink />
          </div>
        </>
      );
    }
    if (isPascalsRowCompletable(learner.pascalsRow, pointsAvailable)) {
      return (
        <>
          <h3 className="flex items-end gap-2 font-serif">
            <PointsHexagon points={pointsAvailable} />
            pts available
          </h3>
          <Title as="h4">
            Row {learner.pascalsRow.length} ready to unlock!
          </Title>
          <p>
            Use your points to unlock the next row of Pascal&rsquo;s Triangle
            and win a new prize.
          </p>
          <div className="mt-2 flex flex-wrap items-center gap-x-6 gap-y-2 text-sm md:mt-6">
            <Button
              type="points"
              className="!py-1"
              href={`${REACT_APP_LEARNER_LINK}/points`}
            >
              Unlock Next Row
            </Button>
            <PrizesLink />
          </div>
        </>
      );
    }
    return (
      <>
        <h3 className="flex items-end gap-2 font-serif">
          <PointsHexagon points={pointsAvailable} />
          pts available
        </h3>
        <div className="flex flex-col gap-1">
          <Title as="h4">{learner.pascalsRow.length} rows unlocked!</Title>
          <p>
            Earn more points to unlock the next row of Pascal&rsquo;s Triangle
            and win a new prize!
          </p>
        </div>
        <div className="mt-2 flex flex-wrap items-center gap-x-6 gap-y-2 text-sm md:mt-4">
          <Button
            type="outline"
            className="border-2 !py-1 font-bold"
            href={`${REACT_APP_LEARNER_LINK}/points`}
          >
            Explore Points
          </Button>
          <PrizesLink />
        </div>
      </>
    );
  };

  const RightContent = () => {
    if (learner === undefined || pointsAvailable === null) {
      return (
        <div className="h-[182px] w-[187px] shrink-0">
          <Skeleton />
        </div>
      );
    }
    if (learner.pascalsRow === undefined) {
      return (
        <div className="relative flex h-[182px] w-[187px] shrink-0 items-center justify-center md:scale-75 xl:scale-100">
          <div className="z-[7] -mt-2">
            <RoundedLockIcon
              variant={
                pointsAvailable < MIN_POINTS_TO_UNLOCK ? "static" : "ping"
              }
              size="md"
            />
          </div>
          <div className="absolute left-0 top-0">
            <LockedTriangleBackground />
          </div>
        </div>
      );
    }
    return (
      <div className="relative mb-4 shrink-0">
        <UnlockedTriangleBackground />
        <img
          className="absolute bottom-10 left-0"
          width="48"
          height="49"
          src={getFilePath("/images/sparkles.png")}
          srcSet={`${getFilePath("/images/sparkles.png")} 1x, ${getFilePath(
            "/images/sparkles@2x.png"
          )} 2x`}
          alt="Sparkles emoji"
          aria-hidden="true"
        />
        <img
          className="absolute right-0 top-4"
          width="48"
          height="49"
          src={getFilePath("/images/sparkles.png")}
          srcSet={`${getFilePath("/images/sparkles.png")} 1x, ${getFilePath(
            "/images/sparkles@2x.png"
          )} 2x`}
          alt="Sparkles emoji"
          aria-hidden="true"
        />
      </div>
    );
  };

  return (
    <DashboardCard
      size="large"
      icon={<TriangleStarsIcon />}
      title="Pascal's Triangle"
    >
      <div className="mt-6 flex flex-col items-center justify-between gap-6 md:flex-row md:items-end">
        <div className="mb-4 flex flex-col gap-2">
          <LeftContent />
        </div>
        <RightContent />
      </div>
    </DashboardCard>
  );
};
