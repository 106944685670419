interface HighlightTextProps {
  text: string;
  query: string;
}

export const HighlightText: React.FC<HighlightTextProps> = ({
  text,
  query,
}) => {
  const sanitizedQuery = query.replace(/[#-.]|[[-^]|[?|{}]/g, "\\$&");
  const match = text.match(new RegExp(sanitizedQuery, "i"));
  if (match) {
    return (
      <span
        dangerouslySetInnerHTML={{
          __html: text.replace(
            match[0],
            `<strong><em>${match[0]}</em></strong>`
          ),
        }}
      />
    );
  } else {
    return <>{text}</>;
  }
};
