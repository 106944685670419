import { useContext, useEffect } from "react";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/solid";
import { PlayIcon } from "@heroicons/react/outline";
import StudentSectionsContext from "../../_context/StudentSectionsContext";
import Document from "../icons/Document";

type StandardSkillHeaderProps = {
  showSolution: boolean;
  currentProblem: any; // TS TODO
  currentProblemData: any; // TS TODO
  skillData: any; // TS TODO
  solveSkill: any; // TS TODO
  handleShowExample: () => void;
  handleShowVideo: () => void;
};

export default function StandardSkillHeader({
  showSolution,
  currentProblem,
  currentProblemData,
  skillData,
  solveSkill,
  handleShowExample,
  handleShowVideo,
}: StandardSkillHeaderProps): JSX.Element {
  const { userValues } = useContext(StudentSectionsContext);

  /* Logic to show icon */
  const showTeacherGraded = skillData.isTeacherType; // teacher graded icon (before it's scored by the teacher)

  // logic to determine whether to show correct or incorrect icon (depending on if the problem was teacher graded or auto graded)
  const isTeacherGraded = currentProblem.correct === -1;
  const isTeacherGradedCorrect =
    isTeacherGraded &&
    skillData.maxProblemsOneDone &&
    skillData.maxProblemsOneDoneCorrect;
  const isTeacherGradedIncorrect =
    !showTeacherGraded &&
    isTeacherGraded &&
    skillData.maxProblemsOneDone &&
    skillData.maxProblemsOneDoneCorrect === false;
  const isAutoGradedCorrect = currentProblem.correct === 1;
  const isAutoGradedIncorrect = currentProblem.correct === 0;

  const showCorrect = isAutoGradedCorrect || isTeacherGradedCorrect;
  const showIncorrect = isAutoGradedIncorrect || isTeacherGradedIncorrect;

  // logic to show example button
  const showExampleButton =
    skillData.isStandardSkill &&
    skillData.type !== "teacher_created" &&
    solveSkill?.ta?.skills[solveSkill?.ta?.skillName]?.show_example &&
    currentProblemData?.examples &&
    currentProblemData?.skillcode;

  // logic to show help video button
  const showHelpVideoButton =
    userValues?.hasPlus && currentProblemData?.show_video && !skillData.isVideo;

  const hasQuestionButtons = !!showExampleButton || !!showHelpVideoButton;

  // Hide header in the case of a test where the question page is showing (otherwise show it)
  const showHeader = !(
    skillData.isTest &&
    currentProblem.correct === undefined &&
    !hasQuestionButtons
  );

  // Hack to have screen reader announce if the student got the problem correct/incorrect after component loads
  useEffect(() => {
    const alertElementId = showIncorrect
      ? "incorrect"
      : showCorrect
      ? "correct"
      : showTeacherGraded
      ? "teacher-graded"
      : null;

    if (alertElementId) {
      setTimeout(() => {
        document.getElementById(alertElementId)?.setAttribute("role", "alert");
      }, 100);
    }
  }, [showIncorrect, showCorrect, showTeacherGraded]);

  return (
    <>
      {showHeader && (
        <div className="mb-6 flex flex-wrap items-center justify-between gap-2">
          <h2 className="font-serif text-lg font-bold">
            {skillData.isTest && currentProblem.correct === undefined
              ? ""
              : !showSolution
              ? "Question"
              : "Solution"}
          </h2>
          {currentProblem?.correct === undefined ? (
            hasQuestionButtons && (
              <div className="flex gap-2 sm:gap-4">
                {!!showHelpVideoButton && (
                  <button
                    className="inline-flex items-center gap-x-1.5 rounded border border-dm-charcoal-800 px-6 py-1 font-sans text-dm-charcoal-800 sm:px-8 sm:py-2"
                    onClick={handleShowVideo}
                  >
                    <PlayIcon className="-ml-0.5 w-5" />
                    <span className="hidden sm:block">Watch Video</span>
                    <span className="block sm:hidden">Video</span>
                  </button>
                )}
                {!!showExampleButton && (
                  <button
                    className="rounded border border-dm-charcoal-800 px-6 py-1 font-sans text-dm-charcoal-800 sm:px-8 sm:py-2"
                    onClick={handleShowExample}
                  >
                    <span className="hidden sm:block">Show Examples</span>
                    <span className="block sm:hidden">Examples</span>
                  </button>
                )}
              </div>
            )
          ) : (
            <div className="icon">
              {showCorrect && (
                <div className="inline-flex items-center rounded-full border border-dm-success-500 bg-dm-success-100 py-0.5 pl-0.5 pr-4 font-sans sm:py-1 sm:pl-2 sm:pr-6">
                  <CheckCircleIcon className="w-10 pr-1 text-dm-success-500 sm:w-14" />
                  <span id="correct">Correct</span>
                </div>
              )}
              {showIncorrect && (
                <div className="inline-flex items-center rounded-full border border-dm-error-500 bg-dm-error-100 py-0.5 pl-0.5 pr-4 font-sans sm:py-1 sm:pl-2 sm:pr-6">
                  <XCircleIcon className="w-10 pr-1 text-dm-error-500 sm:w-14" />
                  <span id="incorrect">Incorrect</span>
                </div>
              )}
              {showTeacherGraded && (
                <div className="inline-flex items-center rounded-full border border-dm-charcoal-500 bg-dm-charcoal-100 py-0.5 pl-0.5 pr-4 font-sans sm:py-1 sm:pl-2 sm:pr-6">
                  <div className="my-1 ml-1 mr-2 h-8 w-8 rounded-full border border-white bg-dm-charcoal-500 p-2 sm:h-10 sm:w-10">
                    <Document
                      classes="block m-auto w-4 sm:w-6"
                      strokeColor="#FFFFFF"
                    />
                  </div>
                  <span id="teacher-graded">Teacher Graded</span>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
}
