import { FormattedLearnerSubscriptionPlan } from "../../../types";

export function calculateAnnualPlanDiscount(
  monthlyPlan: Pick<FormattedLearnerSubscriptionPlan, "learnerPrice">,
  annualPlan: Pick<FormattedLearnerSubscriptionPlan, "learnerPrice">
): number {
  return Math.round(
    100 -
      (annualPlan.learnerPrice.value / (monthlyPlan.learnerPrice.value * 12)) *
        100
  );
}
