import { processChoices } from "./snakeGameProblemGenerator";

const { rand, $, frac } = window;

/** Find double, triple or half of a number
 * @return problem object with the following properties: questionPrompt (string with html), question?  (string), choices (array of 4 strings representing answer choices with the correct answer always at index 0), solution function to run
 */
export function percents() {
  const types = [10, 20, 25, 75];
  const type = types[rand(0, types.length - 1)];

  switch (type) {
    case 10:
      return tenPercent();
    case 20:
      return twentyPercent();
    case 25:
      return twentyFivePercent();
    case 75:
      return seventyFivePercent();
    default:
      break;
  }
}

/** Find 10% of a number
 * @return problem object with the following properties: questionPrompt (string with html), question? (string), choices (array of 4 strings representing answer choices with the correct answer always at index 0), solution function to run
 */
function tenPercent() {
  const largeRange = rand(0, 1) === 0;
  const a = largeRange ? 10 * rand(11, 50) : 5 * rand(3, 19);

  const correctAnswer = a * 0.1;
  let choices = [a * 0.2, a * 0.15, a * 0.05];

  choices = processChoices(choices, correctAnswer);

  const solution = (id) => {
    $(id).html(
      `<span class="text-2xl">\\(${frac(a, 10)}=${correctAnswer}\\)</span>`
    );
  };

  return {
    questionPrompt: `What is \\(10\\%\\) of \\(${a}\\)?`,
    choices,
    solution,
  };
}

/** Find 20% of a number
 * @return problem object with the following properties: questionPrompt (string with html), question? (string), choices (array of 4 strings representing answer choices with the correct answer always at index 0), solution function to run
 */
function twentyPercent() {
  const a = 20 * rand(1, 25);

  const correctAnswer = a * 0.2;
  let choices = [a * 0.1, a * 0.15, a * 0.25, a * 0.4];

  choices = processChoices(choices, correctAnswer);

  const solution = (id) => {
    $(id).html(
      `<span class="text-2xl">\\(${frac(a, 5)}=${correctAnswer}\\)</span>`
    );
  };

  return {
    questionPrompt: `What is \\(20\\%\\) of \\(${a}\\)?`,
    choices,
    solution,
  };
}

/** Find 25% of a number
 * @return problem object with the following properties: questionPrompt (string with html), question? (string), choices (array of 4 strings representing answer choices with the correct answer always at index 0), solution function to run
 */
function twentyFivePercent() {
  const a = 20 * rand(1, 8);

  const correctAnswer = a * 0.25;
  let choices = [a * 0.5, a * 0.75, a * 0.2, a * 0.4];

  choices = processChoices(choices, correctAnswer);

  const solution = (id) => {
    $(id).html(
      `<span class="text-2xl">\\(${frac(a, 4)}=${correctAnswer}\\)</span>`
    );
  };

  return {
    questionPrompt: `What is \\(25\\%\\) of \\(${a}\\)?`,
    choices,
    solution,
  };
}

/** Find 75% of a number
 * @return problem object with the following properties: questionPrompt (string with html), question? (string), choices (array of 4 strings representing answer choices with the correct answer always at index 0), solution function to run
 */
function seventyFivePercent() {
  const largeRange = rand(0, 3) === 0;
  const a = largeRange ? 40 * rand(3, 5) : 8 * rand(3, 12);

  const correctAnswer = a * 0.75;
  let choices = [
    correctAnswer + 2,
    correctAnswer - 2,
    a * 0.25,
    a * 0.625,
    a * 0.875,
  ];

  choices = processChoices(choices, correctAnswer);

  const solution = (id) => {
    $(id).html(
      `<span class="text-2xl">\\(3 \\times ${frac(
        a,
        4
      )}=${correctAnswer}\\)</span>`
    );
  };

  return {
    questionPrompt: `What is \\(75\\%\\) of \\(${a}\\)?`,
    choices,
    solution,
  };
}
