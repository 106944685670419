import { Tooltip } from "../../shared/Tooltip";
import { AssignmentDueDateType, SectionDataStatus } from "../_constants";

const DATE_FORMAT: Intl.DateTimeFormatOptions = {
  month: "long",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
};

interface DueDateTextProps {
  dueDateType: AssignmentDueDateType;
  formattedActiveDueDate: string;
  lateCredit: number | undefined;
}

const DueDateText: React.FC<DueDateTextProps> = ({
  dueDateType,
  formattedActiveDueDate,
  lateCredit,
}) => {
  switch (dueDateType) {
    case SectionDataStatus.completed:
    case SectionDataStatus.upcoming:
      return (
        <>
          <strong>Due:</strong> {formattedActiveDueDate}
        </>
      );
    case SectionDataStatus.late_credit_available:
      return (
        <>
          <strong>{lateCredit}% credit until:</strong> {formattedActiveDueDate}
        </>
      );
    case SectionDataStatus.no_late_available:
      return <>No late credit</>;
    default:
      return null;
  }
};

interface DueDateProps {
  studentAssignment: Record<string, any> | null;
}

export const SidebarDueDate: React.FC<DueDateProps> = ({
  studentAssignment,
}) => {
  const dueDateType: AssignmentDueDateType | undefined =
    studentAssignment?.status ?? undefined;

  const isLate = dueDateType === SectionDataStatus.late_credit_available;

  const dateObject = isLate
    ? studentAssignment?.lateDate?.date
    : studentAssignment?.dueDate;

  const activeDueDate =
    dateObject?.date && dateObject?.tz
      ? new Date(dateObject?.date).toLocaleString("en-US", {
          ...DATE_FORMAT,
          timeZone: dateObject?.tz,
        })
      : null;

  const originalDueDate =
    studentAssignment?.dueDate?.date && studentAssignment?.dueDate?.tz
      ? new Date(studentAssignment?.dueDate?.date).toLocaleString("en-US", {
          ...DATE_FORMAT,
          timeZone: dateObject?.tz,
        })
      : "";

  const tooltipMessage = isLate
    ? `Originally Due: ${originalDueDate}`
    : undefined;

  return (
    <div className="mb-4">
      <Tooltip message={tooltipMessage} options={{ aligned: "default" }}>
        <p className="text-sm">
          {dueDateType && activeDueDate && (
            <DueDateText
              dueDateType={dueDateType}
              formattedActiveDueDate={activeDueDate}
              lateCredit={studentAssignment?.lateDate?.credit}
            />
          )}
        </p>
      </Tooltip>
    </div>
  );
};
