import clsx from "clsx";
import { ButtonHTMLAttributes } from "react";

export const FooterButton: React.FC<
  ButtonHTMLAttributes<HTMLButtonElement>
> = ({ children, className, ...props }) => {
  return (
    <button
      {...props}
      className={clsx(
        "text-dm-charcoal-800 hover:text-dm-charcoal-500",
        className
      )}
    >
      {children}
    </button>
  );
};
