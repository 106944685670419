import axios from "axios";
import { useMutation } from "react-query";
import { deltamathAPI } from "../../manager/utils";
import { useDeltaToastContext } from "../../shared/contexts/ToasterContext";
import { StripeCheckoutBody, StripeCheckoutResponse } from "../types";

/** Mutation for starting a Stripe Checkout session */

export default function useStripeCheckoutMutation() {
  const toastContext = useDeltaToastContext();

  const { mutateAsync } = useMutation(
    (body: StripeCheckoutBody) => {
      return axios.post<StripeCheckoutResponse>(
        `${deltamathAPI()}/payments/checkout/session`,
        JSON.stringify(body),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    },
    {
      onError() {
        toastContext.addToast({
          title: "Error",
          message: "There was an error initializing Stripe",
          status: "Error",
        });
      },
    }
  );

  return { mutate: mutateAsync };
}
