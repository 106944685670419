import { SkeletonText } from "../Skeleton";

export const DashboardHeader: React.FC<{
  headerText: string;
  loaded?: boolean;
}> = ({ headerText, loaded = true }) => {
  return (
    <header className="relative mb-6 w-full">
      <h1 className="font-serif text-lg font-bold">
        <SkeletonText loaded={loaded}>{headerText}</SkeletonText>
      </h1>
    </header>
  );
};
