import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import DeltaMathTable from "../../shared/DeltaMathTable";
import {
  REACT_APP_ADMIN_LINK,
  REACT_APP_TEACHER_LINK,
  useDMQuery,
} from "../../utils";
import { demoString } from "../../utils/demo";
import { Tooltip } from "../../shared/Tooltip";
import DmTabs, { TabInterface } from "../../shared/Tabs";
import { DmLoadingSpinner } from "../../manager/utils/functions";
import SearchIcon from "../../shared/icons/SearchIcon";

/**
 * Constructs an array of dates from sequestial years on Jul 31 {year} 23:59:59 GMT-0400
 * @returns array of dates representing 4 years before and after the current year
 */
const createJulyArr = () => {
  const year = new Date().getFullYear() - 1;
  const allJulys = [];
  for (let i = 0; i < 5; i++) {
    const prevYear = new Date(`July 31 ${year - i} 23:59:59 GMT-4:00`);
    const nextYear = new Date(`July 31 ${year + i} 23:59:59 GMT-4:00`);

    allJulys.unshift(prevYear);
    if (i !== 0) allJulys.push(nextYear);
  }
  return allJulys;
};
const JULYS = createJulyArr();

export default function View() {
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const [tabData, setTabData] = React.useState<any>([]);
  const [filteredData, setFilteredData] = React.useState([]);
  const [searchVal, setSearchVal] = React.useState("");
  const { data: pushedAssignmentData, isLoading: pushedAssignmentsLoading } =
    useDMQuery({
      path: "/admin_new/data/pushed-assignments",
    });

  const { data: sectionData, isLoading: sectionDataLoading } = useDMQuery({
    path: "/admin_new/data/sections",
    queryOptions: {
      staleTime: 1000 * 60 * 15,
    },
  });

  useEffect(() => {
    if (sectionData && pushedAssignmentData) {
      const finalData: any[] = [];
      const finalTeachers: { name: any; teachercode: number }[] = [];
      /** parse through assignment data and teacher data to match teacher to assignments they have access to */
      const teachersMatchedToAssignments: any[] = [];
      pushedAssignmentData.forEach((assignment: any) => {
        const flatTeachers = assignment.sectionList
          .map((section: any) => {
            const id = section;
            const match = sectionData.find(
              (section: any) => section.sectionId === id
            );
            if (match?.teachers) {
              return match.teachers;
            }
          })
          .filter((e: any) => !!e)
          .flat();

        /** filter out duplicate teachers */
        if (flatTeachers) {
          const uniqueTeachers = flatTeachers
            .filter(
              (teacher: any, index: number, self: any) =>
                index ===
                self.findIndex(
                  (t: any) => t._id === teacher._id && t.name === teacher.name
                )
            )
            .map((t: any) => ({ name: t.name, teachercode: t.teachercode })); // we just need the name and teachercode
          finalData.push({
            ...assignment,
            teachers: uniqueTeachers,
          });
          teachersMatchedToAssignments.push({
            ...assignment,
            teachers: uniqueTeachers,
          });
          finalTeachers.push(...uniqueTeachers);
        }
      });

      const rawTabData = constructTabData(teachersMatchedToAssignments);

      /** map raw data into tabs that will display a <DMTable/> */
      const formattedTabData = rawTabData.map((tab: TabInterface) => ({
        title: tab.title,
        panel: (
          <DeltaMathTable data={tab.panel} columns={teachersTableColumns} />
        ),
      }));
      setTabData(formattedTabData);
    }
  }, [pushedAssignmentData, sectionData]);

  const constructTabData = (data: any) => {
    return data.reduce(
      (acc: TabInterface[], current: { last_edit: number }) => {
        /** Find correct school year for current assignment */
        let schoolYear = "Unknown";
        if (current.last_edit) {
          const date = new Date(current.last_edit * 1000);
          const position = JULYS.findIndex(
            (pos, i) =>
              date.getTime() > pos.getTime() &&
              date.getTime() < JULYS[i + 1].getTime()
          );
          schoolYear = `${JULYS[position].getFullYear()}-${JULYS[
            position + 1
          ].getFullYear()}`;
        }
        /** Check if school year is already present in tabs accumulator */
        if (acc.find((entry: TabInterface) => entry.title === schoolYear)) {
          /** If a tab exists, push data into this tab */
          const index = acc.findIndex(
            (entry: TabInterface) => entry.title === schoolYear
          );
          acc[index].panel.push(current);
          return [...acc];
        } else {
          /** Create a new tab to accomodate a new school year */
          return [...acc, { title: schoolYear, panel: [current] }];
        }
      },
      /** Initialize a tab with all of the data */
      [{ title: "All", panel: data }]
    );
  };

  const teachersTableColumns = [
    {
      Header: "Name",
      accessor: "name",
      align: "left",
      width: "20%",
      wrap: true,
      Cell: (props: any) => {
        return (
          user.teachercode &&
          props.row.original._id && (
            <Link
              className="underline hover:no-underline"
              to={`${REACT_APP_ADMIN_LINK}/teacher/${user.teachercode}/assignment/${props.row.original._id}`}
            >
              {props.row.original.name}
            </Link>
          )
        );
      },
    },
    {
      Header: "Teachers",
      accessor: "teachers",
      wrap: true,
      width: "50%",
      Cell: (props: any) =>
        props.value.length > 0 ? (
          <div className="w-full">
            {props.value
              .map((teacher: { name: string; teachercode: number }) =>
                demoString({ value: teacher.name, type: "person_full" })
              )
              .join(", ")}
          </div>
        ) : (
          "-"
        ),
      align: "left",
    },
    {
      Header: "Sections/Dates",
      accessor: "",
      Cell: (props: any) => {
        return (
          user.teachercode &&
          props.row.original._id && (
            <Link
              className="inline-flex items-center rounded px-2.5 py-1.5 text-xs font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              to={`${REACT_APP_ADMIN_LINK}/push-assignment/${props.row.original._id}`}
            >
              {/* Edit Sections/Dates */}
              <Tooltip message="Edit">
                <i className="fa far fa-pen fa-2x text-dm-gray-200"></i>
              </Tooltip>
            </Link>
          )
        );
      },
      align: "center",
    },
    {
      Header: "Content",
      accessor: "_id",
      Cell: (props: {
        row: {
          original: {
            teachercode: number;
            _id: number;
            teachers: { name: string; teachercode: number }[];
          };
        };
      }) => {
        const teachWhoPushed = props.row.original.teachers.find(
          (teacher) => teacher.teachercode === props.row.original.teachercode
        );
        return (
          <>
            {user.teachercode === props.row.original.teachercode ? (
              <a
                className="inline-flex items-center rounded px-2.5 py-1.5 text-xs font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                target="_blank"
                rel="noreferrer"
                href={`${REACT_APP_TEACHER_LINK}?edit=${props.row.original._id}`}
              >
                {/* Edit Content */}
                <Tooltip message="Edit">
                  <i className="fa far fa-pen fa-2x text-dm-gray-200"></i>
                </Tooltip>
              </a>
            ) : (
              <>
                {teachWhoPushed && teachWhoPushed.name ? (
                  <Tooltip message={`Pushed by ${teachWhoPushed.name}`}>
                    <span>{teachWhoPushed.name}</span>
                  </Tooltip>
                ) : (
                  <Tooltip message={"Teachercode of assignment owner."}>
                    <span>{props.row.original.teachercode}</span>
                  </Tooltip>
                )}
              </>
            )}
          </>
        );
      },
      align: "center",
    },
    {
      Header: "Last Edit",
      accessor: "last_edit",
      Cell: (props: any) => {
        const { last_edit } = props.row.original;
        return last_edit
          ? new Date(last_edit * 1000).toLocaleDateString()
          : "-";
      },
      align: "left",
    },
  ];

  const handleChange = (filter: string) => {
    if (sectionData && pushedAssignmentData) {
      const finalData: any[] = [];
      const finalTeachers: { name: any; teachercode: number }[] = [];
      /** parse through assignment data and teacher data to match teacher to assignments they have access to */
      const teachersMatchedToAssignments: any[] = [];
      pushedAssignmentData.forEach((assignment: any) => {
        const flatTeachers = assignment.sectionList
          .map((section: any) => {
            const id = section;
            const match = sectionData.find(
              (section: any) => section.sectionId === id
            );
            if (match?.teachers) {
              return match.teachers;
            }
          })
          .filter((e: any) => !!e)
          .flat();

        /** filter out duplicate teachers */
        if (flatTeachers) {
          const uniqueTeachers = flatTeachers
            .filter(
              (teacher: any, index: number, self: any) =>
                index ===
                self.findIndex(
                  (t: any) => t._id === teacher._id && t.name === teacher.name
                )
            )
            .map((t: any) => ({ name: t.name, teachercode: t.teachercode })); // we just need the name and teachercode
          finalData.push({
            ...assignment,
            teachers: uniqueTeachers,
          });
          teachersMatchedToAssignments.push({
            ...assignment,
            teachers: uniqueTeachers,
          });
          finalTeachers.push(...uniqueTeachers);
        }
      });

      const rawTabData = constructTabData(teachersMatchedToAssignments).map(
        (tab: { title: string; panel: any[] }) => {
          return {
            title: tab.title,
            panel: tab.panel.filter(
              (row: any) =>
                row.teachers.some(
                  (teacher: { name: string; teachercode: number }) =>
                    teacher.name.toUpperCase().indexOf(filter.toUpperCase()) !==
                    -1
                ) || row.name.toUpperCase().indexOf(filter.toUpperCase()) !== -1
            ),
          };
        }
      );
      /** map raw data into tabs that will display a <DMTable/> */
      const formattedTabData = rawTabData.map((tab: TabInterface) => ({
        title: tab.title,
        panel: (
          <DeltaMathTable data={tab.panel} columns={teachersTableColumns} />
        ),
      }));
      setFilteredData(formattedTabData);
    }
  };

  return (
    <>
      <div className="border-b bg-white p-6">
        <h3 className="text-brand-charcoal-800 font-serif text-lg font-bold">
          View Pushed Assignments
        </h3>
        <>
          <span>
            <SearchIcon classes={"absolute ml-2 mt-7"} />
          </span>
          <input
            value={searchVal || ""}
            onChange={(e) => {
              setSearchVal(e.target.value);
              handleChange(e.target.value);
            }}
            className="my-4 rounded-md border border-b p-2 pl-8 sm:w-1/4"
            placeholder="Search"
          />
        </>
      </div>
      <div className="bg-dm-background-blue p-6">
        <div className="rounded-md border">
          {(pushedAssignmentsLoading || sectionDataLoading) && (
            <DmLoadingSpinner message={"Loading..."} />
          )}
          {!pushedAssignmentsLoading && !sectionDataLoading && (
            <>
              {/* Account for "All" being present */}
              {tabData && tabData.length > 1 ? (
                <div className="flow-root">
                  <DmTabs tabs={filteredData.length ? filteredData : tabData} />
                </div>
              ) : (
                <p className="bg-white p-6">
                  You haven&apos;t pushed any assignments yet. You can{" "}
                  <Link
                    className="underline hover:no-underline"
                    to={`${REACT_APP_ADMIN_LINK}/push-assignment`}
                  >
                    push an assignment here.
                  </Link>
                </p>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}
