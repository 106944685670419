import { CheckAnswerResponse, LogData, Problem } from "../../types";
import DefaultAnswer from "./DefaultAnswer";
import MultipleChoice from "./MultipleChoice";
import FormattedAnswer, {
  formatAns,
} from "../../../student/components/answerForm/FormattedAnswer";
import { useEffect, useState } from "react";
import CustomAnswer from "./CustomAnswer";
import { obfuscate } from "../../../student/utils";
import Solution from "../Solution/Solution";
import ReactTooltip from "react-tooltip";
import { IconKeyboard } from "@tabler/icons-react";
import DmConfirmModal from "../../../student/components/answerForm/DmConfirmModal";
import { useFocusContext } from "../../contexts/FocusContext";
import { getSelectedChoice } from "../../../utils";
import { useProblemSolvingContext } from "../../contexts/ProblemSolvingContext";
import { TooltipButton } from "../../../shared/TooltipButton";
import { useLearnerAnalytics } from "../../analytics/useLearnerAnalytics";
import { unsubmitProblemEvent } from "../../analytics/events";
import { useParams } from "react-router-dom";
import Button from "../../../student/components/generic/button";

type Props = {
  problem: Problem;
  problemData: any;
  isMathQuillLoaded: boolean;
  checkAnswer: (body: string) => void;
  isCheckAnswerLoading: boolean;
  elapsedTime?: number;
  elapsedTestTime?: number;
  checkAnswerResponse?: CheckAnswerResponse;
  unsubmitAllowed: boolean;
  externalFileData?: any[];
  skippedProblem?: boolean;
  unsubmit?: () => void;
  attemptText?: string;
  logData?: LogData;
};

const Answer = (props: Props) => {
  const { indexOfSkill } = useParams();
  const { logData, skillId, assignment } = useProblemSolvingContext();
  const focusContext = useFocusContext();
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const noSolutionText: string = props.problemData?.multipleSolutionsPossible
    ? props.problemData?.multipleSolutionsBtns
      ? props.problemData?.multipleSolutionsBtns[0]
      : "No solution"
    : "Answer left blank";

  const [isGuided, setIsGuided] = useState<boolean>(
    props.problemData.guided || false
  );
  const [userSolution, setUserSolution] = useState<
    object | string[] | undefined
  >(logData ? logData.ans : props.logData ? props.logData.ans : undefined);
  const [customMessage, setCustomMessage] = useState<string | undefined>(
    logData ? logData.sol : props.logData ? props.logData.sol : undefined
  );
  const [showUserSolution, setShowUserSolution] = useState<boolean>(true);
  const [checkAnswerResults, setCheckAnswerResults] = useState<
    | {
        correct?: number | undefined;
        messages?: string[] | undefined;
      }
    | undefined
  >(undefined);

  const { track, getAssignmentData } = useLearnerAnalytics();

  const endGuided = (msg?: string) => {
    setIsGuided(false);
    // Line below not tested (cannot find a module that includes a msg...)
    if (msg !== undefined && window.alertMath) window.alertMath(msg);
  };
  /* Set global function */
  window.endGuided = endGuided;

  const unsubmit = () => {
    if (props.unsubmit) {
      props.unsubmit();
    }

    setUserSolution(undefined);
    setCustomMessage(undefined);
    setCheckAnswerResults(undefined);

    track(
      unsubmitProblemEvent({
        ...getAssignmentData(assignment.type),
        skillCode: props.problem.skillcode,
        skillNumber: indexOfSkill ? parseInt(indexOfSkill) : -1,
      })
    );
  };

  useEffect(() => {
    if (props.checkAnswerResponse?.responseType === "retry") {
      setUserSolution(undefined);
      setCustomMessage(undefined);
    }
    setShowUserSolution(true);
  }, [props.checkAnswerResponse]);

  const submit = (
    answer: string[] | object,
    checkAnswerResults?: {
      correct?: number | undefined;
      messages?: string[] | undefined;
    },
    customMsg?: string
  ) => {
    setShowUserSolution(false);
    setUserSolution(answer);
    setCustomMessage(customMsg);
    setCheckAnswerResults(checkAnswerResults);
    if (assignment.type === "practice") {
      setShowConfirmModal(true);
    } else {
      confirmSubmit(answer, checkAnswerResults, customMsg);
    }
  };

  const confirmSubmit = (
    userSolution?: string[] | object,
    checkAnswerResults?: {
      correct?: number | undefined;
      messages?: string[] | undefined;
    },
    customMessage?: string
  ) => {
    const solution = formatAns({
      ansType: props.problem.ansType,
      answer: userSolution,
      noSolutionText: noSolutionText,
      studentIsStuck: false,
      noAnswerSubmitted: false,
      leftLatex: props.problem.data.leftLatex,
      rightLatex: props.problem.data.rightLatex,
      customMsg: customMessage,
      setNotation: props.problemData.setNotation,
    }).formattedAnsStrArray.join("");

    const bodyObj = {
      skillId,
      sk: props.problem.skillcode,
      encryptedProblemId: props.problem._id,
      testDuration: props.elapsedTestTime
        ? Math.round(props.elapsedTestTime / 1000)
        : undefined,
      submitted: {
        solution: solution,
        answers: userSolution,
        duration: props.elapsedTime
          ? Math.round(props.elapsedTime / 1000)
          : undefined,
        ...(props.problem.ansType === "custom"
          ? {
              answerData: props.problem.data?.data?.answerData,
              ...(checkAnswerResults && checkAnswerResults.correct !== undefined
                ? {
                    metaData: obfuscate(`${props.problem._id}`).hide(
                      checkAnswerResults
                    ),
                  }
                : {}),
            }
          : {}),
      },
    };

    props.checkAnswer(JSON.stringify(bodyObj));
  };

  if (props.skippedProblem) {
    return <div className="answerArea">Answer left blank</div>;
  }

  return (
    <div>
      <DmConfirmModal
        showModal={showConfirmModal}
        setShowModal={setShowConfirmModal}
        isSubmitDisabled={props.isCheckAnswerLoading}
        headline={"Verify Solution"}
        subHeadline="Are you sure you want to submit this answer?"
        message={
          <>
            {userSolution && (
              <FormattedAnswer
                ansType={props.problem.ansType}
                answer={userSolution}
                leftLatex={props.problem.data.leftLatex}
                rightLatex={props.problem.data.rightLatex}
                noSolutionText={noSolutionText}
                choices={props.problem.choices}
                setNotation={props.problemData.setNotation}
                customMsg={customMessage}
                studentSolution={getSelectedChoice(
                  userSolution,
                  props.problem.choices ?? []
                )}
              />
            )}
          </>
        }
        modalAction={() =>
          confirmSubmit(userSolution, checkAnswerResults, customMessage)
        }
      />
      <div
        className={`${
          userSolution !== undefined && showUserSolution ? "hidden" : ""
        }`}
      >
        <div className="mb-8 flex flex-col justify-start gap-x-4 gap-y-2 sm:flex-row sm:items-center">
          <h2 className="font-serif text-xl font-bold">Answer</h2>
          {props.attemptText ? (
            <>
              <div
                className="attempts text-sm text-gray-500"
                data-effect="solid"
                data-for="attempts-tooltip"
                data-tip="The number of attempts on this specific problem before you are marked incorrect"
              >
                {props.attemptText}
              </div>
              <ReactTooltip
                id="attempts-tooltip"
                className="max-w-[14rem] font-sans text-xs"
              />
            </>
          ) : null}
          {assignment.type !== "practice" && isGuided ? (
            <Button
              type="link"
              className="w-fit font-sans"
              onClick={() => {
                submit(
                  { giveUp: true },
                  { correct: 0 },
                  "\\text{I don't know this answer}"
                );
              }}
              disabled={props.isCheckAnswerLoading}
            >
              I don&apos;t know this answer
            </Button>
          ) : null}
        </div>
        {isGuided && (
          <div className="answerArea">
            {props.problemData.special === true
              ? "Submitting is not possible on this problem. Just follow the instructions above."
              : "You must answer all questions above in order to submit."}
          </div>
        )}
        {(props.problem.ansType === 1 ||
          (props.problem.ansType === "custom" &&
            document.querySelectorAll(".mathquill-editable").length > 0)) && (
          <div id="keyboard" className="absolute right-4 top-4">
            <TooltipButton
              message="Virtual Keyboard"
              onClick={() => {
                if (!focusContext.keyboardOpen && focusContext.calculatorOpen) {
                  focusContext.openKeyboard(true);
                  focusContext.openCalculator(false);
                } else {
                  focusContext.openKeyboard(!focusContext.keyboardOpen);
                }
              }}
              className="inline-flex items-center rounded-md border border-transparent bg-gray-200 px-2 py-1 text-dm-blue hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-dm-lightest-blue focus:ring-offset-2"
            >
              <IconKeyboard className="h-7" aria-hidden="true" />
            </TooltipButton>
          </div>
        )}
        {props.problem.ansType === 2 && !isGuided ? (
          <MultipleChoice {...props} handleSubmit={submit} />
        ) : props.problem.ansType === 1 && !isGuided ? (
          <DefaultAnswer {...props} handleSubmit={submit} />
        ) : (
          <CustomAnswer
            {...props}
            handleSubmit={submit}
            isMathQuillLoaded={props.isMathQuillLoaded}
            className={isGuided ? "hidden" : ""}
          />
        )}
      </div>
      {userSolution && showUserSolution && (
        <Solution
          problem={props.problem}
          checkAnswerResponse={props.checkAnswerResponse}
          unsubmitAllowed={props.unsubmitAllowed}
          userSolution={userSolution}
          noSolutionText={noSolutionText}
          noAnswerSubmitted={userSolution === undefined}
          unsubmit={unsubmit}
          customMessage={customMessage}
          externalFileData={props.externalFileData}
        />
      )}
    </div>
  );
};

export default Answer;
