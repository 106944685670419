import { PropsWithChildren } from "react";
import { ProfileDividedContainer } from "./ProfileDividedContainer";

export const ProfileColumn: React.FC<PropsWithChildren<unknown>> = ({
  children,
}) => (
  <ProfileDividedContainer className="flex w-full flex-col sm:gap-14">
    {children}
  </ProfileDividedContainer>
);
