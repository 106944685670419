import { LearnerAssignmentRef } from "../../types";
import IconAssignmentProgress from "../IconAssignmentProgress";
import Button from "../../../student/components/generic/button";
import AssignmentPreviewModal from "../Modals/AssignmentPreviewModal";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { TooltipButton } from "../../../shared/TooltipButton";
import { Infotip } from "../../../shared/Infotip";

type Props = {
  type: "preQuiz" | "practice" | "postQuiz";
  assignmentRef?: LearnerAssignmentRef;
  preQuiz100?: boolean;
  locked?: boolean;
  lockedText?: string;
  href?: string;
  courseId: string;
  unitId: string;
  subunitId: string;
};

const SubunitCardAssignmentProgress = (props: Props) => {
  const navigate = useNavigate();
  const [showAssignmentModal, setShowAssignmentModal] =
    useState<boolean>(false);
  const submitted =
    (!props.assignmentRef && props.preQuiz100 && props.type === "postQuiz") ||
    props.assignmentRef?.submitted !== undefined ||
    (props.type === "practice" && props.assignmentRef?.progress === 1);

  const progress =
    !props.assignmentRef && props.preQuiz100 && props.type === "postQuiz"
      ? 100
      : (props.assignmentRef?.progress || 0) * 100;

  const maxGrade =
    !props.assignmentRef && props.preQuiz100 && props.type === "postQuiz"
      ? 100
      : (props.assignmentRef?.maxGrade || 0) * 100;

  const displayTitle =
    props.type === "preQuiz"
      ? "Pre-Quiz"
      : props.type === "practice"
      ? "Practice"
      : "Post-Quiz";

  return (
    <div className="flex items-center gap-2">
      <Infotip
        options={{ inline: true }}
        {...(props.type === "postQuiz" &&
        props.assignmentRef &&
        submitted &&
        maxGrade < 70
          ? {
              message:
                "You must retake the post-quiz and get a score of 70% or higher to complete this section",
              ariaLabel: "More information",
            }
          : {})}
      >
        <IconAssignmentProgress
          type={props.type}
          progress={progress}
          submitted={submitted}
          maxGrade={maxGrade}
          isStarted={
            props.assignmentRef !== undefined ||
            (props.type === "preQuiz" && !props.locked)
          }
          resizingClasses="scale-75"
        />
      </Infotip>
      {!props.locked &&
      props.href &&
      (props.type !== "practice" || progress > 0) ? (
        <Button
          type="link"
          href={props.href}
          className="!py-0 text-dm-charcoal-600"
        >
          {displayTitle}
        </Button>
      ) : props.type === "practice" &&
        progress === 0 &&
        props.href &&
        !props.locked ? (
        <Button
          type="link"
          className="cursor-pointer !py-0 text-dm-charcoal-600"
          onClick={() =>
            navigate(props.href ?? "", {
              state: { landing: true },
            })
          }
        >
          {displayTitle}
        </Button>
      ) : (
        <span className="text-dm-gray-800 opacity-60">{displayTitle}</span>
      )}

      {!submitted &&
        props.assignmentRef &&
        props.assignmentRef.progress > 0 && (
          <span className="ml-2 inline-flex items-center rounded-full bg-dm-brand-blue-500 px-1.5 py-0.5 text-xs font-medium text-white">
            {`${Math.round(
              (props.assignmentRef.progress || 0) * 100
            )}% Complete`}
          </span>
        )}
      {props.type === "postQuiz" &&
        props.preQuiz100 &&
        !props.assignmentRef &&
        `: Not Required`}
      {submitted &&
        props.assignmentRef?.maxGrade !== undefined &&
        ` Score: ${Math.round(props.assignmentRef.maxGrade * 100)}%`}
      {/* There's no lock for a Pre-Quiz, so using `props.locked` to instead determine skipped */}
      {props.type === "preQuiz" && props.locked && (
        <span className="text-dm-gray-800 opacity-60">: Skipped</span>
      )}
      {props.type !== "preQuiz" && props.locked && (
        <Infotip message={props.lockedText} ariaLabel="Locked">
          <i className="far fa-lock text-dm-gray-200" aria-hidden="true" />
        </Infotip>
      )}
      {!props.locked &&
        props.assignmentRef === undefined &&
        props.type === "preQuiz" && (
          <TooltipButton
            message="Preview Quiz Questions"
            onClick={() => {
              setShowAssignmentModal(true);
            }}
          >
            <i className="far fa-eye text-dm-gray-200" aria-hidden="true" />
          </TooltipButton>
        )}
      <AssignmentPreviewModal
        title="Pre-Quiz Preview"
        courseId={props.courseId}
        unitId={props.unitId}
        subunitId={props.subunitId}
        type="preQuiz"
        visible={showAssignmentModal}
        toggleVisible={setShowAssignmentModal}
        navigateButtonText="Go To Pre-Quiz"
        navigateHref={props.href || ""}
      />
    </div>
  );
};

export default SubunitCardAssignmentProgress;
