import { Dispatch, SetStateAction } from "react";
import axios from "axios";
import { QueryClient, useMutation, useQueryClient } from "react-query";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { deltamathAPI } from "../../manager/utils";
import { REACT_APP_STUDENT_LINK, useDMQuery } from "../../utils";
import {
  setLSPasscodeToken,
  processIndividualAssignment,
  scrollToView,
  updateFullAssignmentData,
} from "../utils";

/* **************** */
/* unlockAssignment */
/* **************** */

export const unlockAssignment = (
  teacherId: any,
  passcode: string,
  setLoadingData: Dispatch<SetStateAction<any>>
) => {
  const token = localStorage.getItem("id_token");
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const pcKeyName = "pc_" + teacherId + "_" + user?._id;

  return useMutation(
    () => {
      setLoadingData((prevState: any) => ({
        ...prevState,
        isShowing: true,
        error: false,
      }));
      return axios.post(
        deltamathAPI() +
          `/student/unlockAssignment/${teacherId}?passcode=${passcode}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    {
      onSuccess: (data: any, variables: any, context: any) => {
        // console.group("unlockAssignment success");
        // console.log("data", data);
        // console.log("data.data", data.data);
        // console.log("variables", variables);
        // console.log("context", context);
        // console.groupEnd();

        const pcKeyValue = data?.data?.passcode_token;
        localStorage.setItem(pcKeyName, pcKeyValue);
        // should continue in unlockAssignment useEffect() where initially called
      },
      onError: (error: any, variables: any, context: any) => {
        console.group("unlockAssignment onError");
        console.log("error", error);
        console.log("JSON.stringify(error)", JSON.stringify(error));
        console.log(
          "error msg",
          error?.response?.data?.message || error?.error
        );
        console.log("variables", variables);
        console.log("context", context);
        console.groupEnd();
        setLoadingData((prevState: any) => ({
          ...prevState,
          isShowing: true,
          error: true,
          title: error?.response?.status === 409 ? "Notice" : "Error",
          message: `${
            error?.response?.data?.message ||
            error?.message ||
            error?.error ||
            ""
          }`,
        }));
      },
    }
  );
};

/* ******************** */
/* startTimedAssignment */
/* ******************** */

export const getTimedAssignment = (
  teacherId: any,
  activeSection: string,
  setLoadingData: Dispatch<SetStateAction<any>>,
  dmAssignmentData: any,
  setDmAssignmentData: Dispatch<SetStateAction<any>>,
  navigate: NavigateFunction,
  queryClient: QueryClient,
  refetchAssignments: () => void
) => {
  return useDMQuery({
    path: `/student/startTimedAssignment/${teacherId}`,
    method: "post",
    cacheKey: ["startTimedAssignment", teacherId],
    params: {
      ...setLSPasscodeToken(teacherId),
    },
    queryOptions: {
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: false,
      onSuccess: async (data: any) => {
        // Backend is returning with updated data
        updateFullAssignmentData(
          data,
          data.ta._id,
          activeSection,
          dmAssignmentData,
          setDmAssignmentData
        );

        queryClient.invalidateQueries("/student/data/assignments");
        queryClient.invalidateQueries("/student/data/sections");

        const sid = data?.sa?._id;
        if (sid) {
          dmAssignmentData[activeSection].filter(
            (assignment: any, index: number) => {
              if (assignment.sa._id === sid) {
                const dataObj = { ...dmAssignmentData };
                dataObj[activeSection][index] =
                  processIndividualAssignment(data);
                setDmAssignmentData({ ...dataObj });
                return;
              }
            }
          );

          const firstSkill = data?.ta?.order[0];
          const uid = data?.ta?.skills?.[firstSkill]?.uid;
          const tid = data?.ta?._id;

          if (!!uid && !!tid) {
            navigate(
              `${REACT_APP_STUDENT_LINK}/${activeSection}/${tid}/${uid}`
            );
            scrollToView();
          } else {
            setLoadingData((prevState: any) => ({
              ...prevState,
              isShowing: false,
            }));
            console.log("Error. tid:", tid, " | uid:", uid);
          }
        }
      },
      onError: (error: any) => {
        refetchAssignments();
        setLoadingData((prevState: any) => ({
          ...prevState,
          isShowing: true,
          error: true,
          title: "Error",
          message: `${
            error?.response?.data?.message ||
            error?.message ||
            error?.error ||
            ""
          }`,
        }));
      },
    },
  });
};

// "Show Solutions|Results" button appears when a student is taking an obscured test,
// they finish answering all questions, and they are allowed to view results
// at that moment

export const useShowResults = (taId: number) =>
  useMutation((body: string) => {
    return axios.post(deltamathAPI() + `/student/showResults/${taId}`, body, {
      headers: { "Content-Type": "application/json" },
    });
  });

// End a timed restricted test early
export const useEndEarly = (taId: number) =>
  useMutation((body: string) => {
    return axios.post(deltamathAPI() + `/student/endEarly/${taId}`, body, {
      headers: { "Content-Type": "application/json" },
    });
  });
