import { NavLink } from "react-router-dom";
import { getFilePath, REACT_APP_LEARNER_LINK } from "../../../../utils";

export const AddLearnersHeader: React.FC<{
  title?: string;
  showSkipLink: boolean;
}> = ({ title, showSkipLink }) => {
  const imgWidth = "w-[173px]";
  return (
    <header className="mb-7 flex w-full items-center justify-between px-4 pt-4 sm:mb-10 sm:pt-6 md:px-14">
      <img
        className={imgWidth}
        src={getFilePath("/images/DeltaMath-Logo_Home.svg")}
        alt="DeltaMath for Home"
      />
      {title && (
        <h1 className="hidden font-serif text-2xl font-bold text-dm-brand-blue-600 md:block">
          {title}
        </h1>
      )}
      {showSkipLink ? (
        <NavLink
          className="text-sm font-bold text-dm-brand-blue-500 hover:text-dm-brand-blue-600"
          to={`${REACT_APP_LEARNER_LINK}/parent/profile`}
        >
          Skip for now
        </NavLink>
      ) : (
        <span>&nbsp;</span>
      )}
    </header>
  );
};
