import TrashIcon from "../../../shared/icons/TrashIcon";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { deltamathAPI } from "../../../manager/utils";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import DeltaMathConfirmation from "../../../shared/DeltaMathConfirmation";
import DeltaMathTable from "../../../shared/DeltaMathTable";
import { DemoMode } from "../../../utils/demo";
import { REACT_APP_ADMIN_LINK, executeQuery } from "../../../utils";
import { IconButton } from "../../../student/components/generic/IconButton";
import Modal from "../../../student/components/generic/Modal";

interface IChangeAdminStatusPayload {
  newAdminId?: string;
  currentAdminId?: string;
  schoolId?: string;
  districtID?: string;
  type?: "school" | "district";
  downgradeOnly?: boolean;
}

export default function CurrentAdmins({
  admins,
  school_id,
  partOfDistrict,
}: {
  admins: any;
  school_id: any;
  partOfDistrict: boolean;
}) {
  const toastContext = useDeltaToastContext();
  const queryClient = useQueryClient();
  const [showUpgradeConfirmation, setShowUpgradeConfirmation] = useState(false);
  const [showDowngradeWarning, setShowDowngradeWarning] = useState(false);
  const [cannotDowngrade, setCannotDowngrade] = useState("");
  const [changeAdminStatusPayload, setChangeAdminStatusPayload] =
    useState<IChangeAdminStatusPayload>({});

  const [showDeleteAdminConfirmation, setShowDeleteAdminConfirmation] =
    useState(false);
  const [userToDelete, setUserToDelete] = useState({
    id: "",
    first: "",
    last: "",
  });

  const removeAdmin = useMutation(
    () => {
      return axios.delete(
        deltamathAPI() +
          `/admin_new/manage/remove/${userToDelete.id}/${school_id}`
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("admin-data");
        toastContext.addToast({
          status: "Success",
          message: "Admin Removed Succesfully",
        });
      },
      onError: () => {
        toastContext.addToast({
          status: "Error",
          message: "There was an error with the request.",
        });
      },
    }
  );

  const { mutate: updateAdmin } = useMutation(
    async () =>
      await executeQuery({
        path: "/admin_new/manage/update_school_admin",
        method: "post",
        requestBody: changeAdminStatusPayload as Record<string, unknown>,
      }),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(["admin-data"]);
        toastContext.addToast({
          status: res.success ? "Success" : "Error",
          message: res.message,
        });
      },
      onError: () => {
        toastContext.addToast({
          status: "Error",
          message: "There was an error with the request",
        });
      },
      onSettled: () => {
        if (showDowngradeWarning) {
          setShowDowngradeWarning(false);
        }
        if (showUpgradeConfirmation) {
          setShowUpgradeConfirmation(false);
        }
      },
    }
  );

  const handleAdminChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
    admin: any
  ) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setShowUpgradeConfirmation(true);
    } else {
      if (
        admins.filter((a: any) => a.account_type === "super_school").length ===
        1
      ) {
        setCannotDowngrade(
          "You cannot downgrade the last primary admin at this school."
        );
      } else if (admin.account_type.includes("district")) {
        setCannotDowngrade("You cannot downgrade a district level admin.");
      } else {
        setShowDowngradeWarning(true);
      }
    }
  };

  useEffect(() => {
    setShowDeleteAdminConfirmation(false);
  }, [removeAdmin.isSuccess]);

  const columns = React.useMemo(() => {
    const columnsDefinition: {
      Header: string;
      accessor: string;
      align: string;
      Cell?: (props: any) => JSX.Element;
      disableSortBy?: boolean;
    }[] = [
      {
        Header: "First Name",
        accessor: "first",
        align: "left",
        Cell: (props: any) => {
          return <DemoMode value={props.value} type="person_first" />;
        },
      },
      {
        Header: "Last Name",
        accessor: "last",
        align: "left",
        Cell: (props: any) => {
          return <DemoMode value={props.value} type="person_last" />;
        },
      },
      {
        Header: "Email",
        accessor: "email",
        align: "left",
        Cell: (props: any) => {
          return <DemoMode value={props.value} type="person_email" />;
        },
      },
    ];

    if (localStorage.getItem("customer_service_token")) {
      columnsDefinition.push(
        ...(!partOfDistrict || 5 === 5
          ? [
              {
                Header: "Primary Admin?",
                accessor: "account_type",
                align: "center",
                Cell: ({ row, value }: { row: any; value: any }) => (
                  <input
                    key={"input" + row.original._id}
                    type="checkbox"
                    checked={value.includes("super")}
                    disabled={row.original.account_type.includes("district")}
                    onChange={(e) => {
                      e.preventDefault();
                      const isChecked = e.target.checked;
                      if (isChecked) {
                        setChangeAdminStatusPayload({
                          newAdminId: row.original._id,
                          schoolId: school_id,
                        });
                      } else {
                        setChangeAdminStatusPayload({
                          currentAdminId: row.original._id,
                          schoolId: school_id,
                          downgradeOnly: true,
                        });
                      }
                      handleAdminChange(e, row.original);
                    }}
                  />
                ),
              },
            ]
          : [])
      );
    }

    columnsDefinition.push({
      Header: "",
      accessor: "showDeleteButton",
      align: "",
      disableSortBy: true,
      Cell: (props: { row: { original: any } }) => (
        <div className="flex justify-end">
          {props.row.original.showDeleteButton && (
            <IconButton
              onClick={() => {
                setShowDeleteAdminConfirmation(true);
                setUserToDelete({
                  id: props.row.original._id,
                  first: props.row.original.first,
                  last: props.row.original.last,
                });
              }}
              icon={<TrashIcon />}
              aria-label={"Delete"}
            />
          )}
        </div>
      ),
    });
    return columnsDefinition;
  }, [admins, partOfDistrict, school_id]);

  return (
    <>
      {admins && admins.length > 0 && (
        <div className="rounded-md border">
          <DeltaMathTable
            columns={columns}
            data={admins}
            options={{
              initialState: {
                sortBy: [
                  {
                    id: "last",
                    desc: false,
                  },
                ],
              },
            }}
          />
        </div>
      )}
      {admins && admins.length === 0 && (
        <>
          <p className="text-md my-1 mb-6 text-gray-500">No admins found.</p>

          <p>
            <Link
              className="underline hover:no-underline"
              to={`${REACT_APP_ADMIN_LINK}/schools/${school_id}/invite-admins`}
            >
              Invite an Admin
            </Link>{" "}
            to get started.
          </p>
        </>
      )}

      {showDeleteAdminConfirmation && userToDelete && (
        <DeltaMathConfirmation
          title={`Remove Admin`}
          message={
            <>
              Are you sure you want to remove{" "}
              <DemoMode value={userToDelete.first} type="person_first" />{" "}
              <DemoMode value={userToDelete.last} type="person_last" />
              &rsquo;s account? This admin will be removed from the school and
              will no longer have access to the school&apos;s data.
            </>
          }
          confirm="Delete"
          confirmAction={removeAdmin.mutate}
          cancel="Cancel"
          cancelAction={() => {
            setShowDeleteAdminConfirmation(false);
            setUserToDelete({
              id: "",
              first: "",
              last: "",
            });
          }}
        />
      )}
      {showUpgradeConfirmation && (
        <DeltaMathConfirmation
          title={"Upgrade Admin"}
          message={`Upgrading this admin to a primary admin will automatically downgrade any account which is a primary admin at only this school to a regular admin. District level admins and super admins associated with multiple schools will not be downgraded. Do you want to proceed?`}
          confirm="Upgrade"
          confirmAction={updateAdmin}
          cancel="Cancel"
          cancelAction={() => {
            setShowUpgradeConfirmation(false);
            setChangeAdminStatusPayload({});
          }}
        />
      )}
      {showDowngradeWarning && (
        <DeltaMathConfirmation
          title={"Downgrade Admin"}
          message={`Do you want to downgrade this admin to a regular admin? Note that doing so will downgrade them at ALL schools they are associated with.`}
          confirm="Downgrade"
          confirmAction={updateAdmin}
          cancel="Cancel"
          cancelAction={() => {
            setShowDowngradeWarning(false);
            setChangeAdminStatusPayload({});
          }}
        />
      )}
      {cannotDowngrade && (
        <Modal
          visible={!!cannotDowngrade}
          onClose={() => setCannotDowngrade("")}
          header={"Cannot Downgrade Admin"}
          body={cannotDowngrade}
          confirmButtonText="OK"
        />
      )}
    </>
  );
}
