import { Tooltip } from "../Tooltip";

export const CondensedCell = ({
  value,
  maxLength = 25,
}: {
  value: string;
  maxLength?: number;
}) => {
  if (value.length > maxLength) {
    return (
      <Tooltip message={value}>
        <span>{value.slice(0, maxLength)}...</span>
      </Tooltip>
    );
  } else return <span>{value}</span>;
};
