import {
  FormEventHandler,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from "react";
import Button from "../../../../student/components/generic/button";
import { SignupFormField } from "./SignupFormField";
import {
  ConditionalPasswordErrorMessages,
  evaluatePassword,
} from "../../../../utils";
import { useLogin } from "../../../../utils/auth/login";
import { useHomepageSignupContext } from "../HomepageSignupContext";
import { useMutation } from "react-query";
import { withJsonHeader } from "../../../../shared/axiosUtils";
import { deltamathAPI } from "../../../../manager/utils";
import axios from "axios";
import { useCheckEmail } from "../../../utils/useCheckEmail";
import { processPasswordForRequest } from "../../../../utils/processPasswordForRequest";

export interface SignupPayload {
  first: string;
  last: string;
  email: string;
  password: string;
}

export const SignupForm: React.FC<{
  initialData: Partial<SignupPayload>;
}> = ({ initialData }) => {
  const login = useLogin();
  const { accountType } = useHomepageSignupContext();
  const [data, setData] = useState<SignupPayload>({
    first: "",
    last: "",
    email: "",
    password: "",
    ...initialData,
  });
  const [nonFieldError, setNonFieldError] = useState<string | undefined>();
  const [formErrors, setFormErrors] = useState<{
    [K in keyof SignupPayload]?: string | ReactElement;
  }>({});
  const [isLoading, setIsLoading] = useState(false);
  const firstNameFieldRef = useRef<HTMLInputElement>(null);

  const { checkEmail } = useCheckEmail(data.email);

  const isFormDisabled =
    Object.values(formErrors).some((e) => e !== undefined) ||
    Object.values(data).some((v) => v.trim().length === 0) ||
    !evaluatePassword(data.password.trim());

  useEffect(() => {
    if (initialData !== null) {
      setData({ ...data, ...initialData });
    }
    // Only want this to run when user sets initialData
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialData]);

  const { mutateAsync: createAccountMutation } = useMutation(
    ({
      payload,
      accountType,
      parent,
    }: {
      payload: SignupPayload;
      accountType: "learner" | "parent" | undefined;
      parent?: string;
    }) => {
      return axios.post<{ status: "success" | "error" }>(
        `${deltamathAPI()}/learner/default/create-account`,
        JSON.stringify({
          ...payload,
          accountType,
          parent,
          password: processPasswordForRequest(payload.password),
        }),
        withJsonHeader()
      );
    }
  );

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    setNonFieldError(undefined);
    setIsLoading(true);
    if (!(await handleCheckEmail())) {
      setIsLoading(false);
      return;
    }
    try {
      await createAccountMutation({ payload: data, accountType });
      const { success } = await login(data.email, data.password, "learner");
      if (!success) {
        setNonFieldError("There was an error while logging in");
      } else {
        // Nothing to do here. Will render payment plans when user is logged in
      }
    } catch (e: any) {
      setNonFieldError(
        e.response?.data?.message ||
          "There was an error while creating your account"
      );
      setIsLoading(false);
    }
  };

  const handleCheckEmail = async () => {
    const { success, message } = await checkEmail();
    setFormErrors({ ...formErrors, email: message });
    return success;
  };

  useEffect(() => {
    firstNameFieldRef.current?.focus();
  }, []);

  return (
    <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
      <SignupFormField
        ref={firstNameFieldRef}
        label="First Name"
        value={data.first}
        error={formErrors.first}
        onChange={(e) => setData({ ...data, first: e.currentTarget.value })}
      />
      <SignupFormField
        label="Last Name"
        value={data.last}
        error={formErrors.last}
        onChange={(e) => setData({ ...data, last: e.currentTarget.value })}
      />
      <SignupFormField
        label="Email Address"
        type="email"
        value={data.email}
        error={formErrors.email}
        onChange={(e) => {
          setData({ ...data, email: e.currentTarget.value });
          setFormErrors({ ...formErrors, email: undefined });
        }}
        onBlur={handleCheckEmail}
      />
      <SignupFormField
        label="Password"
        type="password"
        value={data.password}
        error={<ConditionalPasswordErrorMessages password={data.password} />}
        onChange={(e) => {
          setData({ ...data, password: e.currentTarget.value });
        }}
      />
      {nonFieldError && (
        <div className="rounded bg-dm-error-100 p-4 text-center text-sm text-dm-error-500">
          {nonFieldError}
        </div>
      )}
      <Button disabled={isFormDisabled} isLoading={isLoading}>
        Sign Up
      </Button>
    </form>
  );
};
