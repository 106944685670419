import React, { useEffect, useState } from "react";
import { isDistrictNCES_ID, isSchoolNCES_ID } from "../../utils/quoteUtils";
import { executeQuery } from "../../../utils";
import { useMutation } from "react-query";
import { DmLoadingSpinner } from "../../utils/functions";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import { Link } from "react-router-dom";
import { REACT_APP_MANAGER_LINK } from "../../../utils";

const IndividualQuoteForm: React.FC<{
  nces: string;
  individualLicenses?: Array<{
    licenseTier: "Plus" | "Integral";
    licenseCount: number;
  }>;
  contactEmail: string;
  contactFirst: string;
  contactLast: string;
}> = ({
  nces,
  individualLicenses,
  contactEmail,
  contactFirst,
  contactLast,
}) => {
  const [individualSiteType, setIndividualSiteType] = useState<string>(
    isSchoolNCES_ID(nces) ? "School" : isDistrictNCES_ID(nces) ? "District" : ""
  );

  const [messages, setMessages] = useState<
    Array<{ quoteNumber: string; text: string }>
  >([]);

  const [disabled, setDisabled] = useState<boolean>(false);
  const [sendWarning, setSendWarning] = useState<string>("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIndividualSiteType(e.target.value);
  };

  const toastContext = useDeltaToastContext();

  const generateIndividualRenewalQuote = async ({
    licenseTier,
    licenseCount,
    contactEmail,
    contactFirst,
    contactLast,
  }: {
    licenseTier: "Plus" | "Integral";
    licenseCount: number;
    contactEmail: string;
    contactFirst: string;
    contactLast: string;
  }) => {
    const user = JSON.parse(localStorage.getItem("user") ?? "{}");
    const requestBody = {
      type: "Individual License",
      customer_service_token: localStorage.getItem("customer_service_token"),
      contactEmail: contactEmail === "" ? user.email : contactEmail,
      licenseTier,
      individualSiteType,
      contactName:
        contactFirst === "" || contactLast === ""
          ? `${user.first} ${user.last}`
          : `${contactFirst} ${contactLast}`,
      licenseCount,
      id: nces,
      fromRenewalsPage: true,
    };
    const data = await executeQuery({
      path: "/quote_request",
      method: "post",
      requestBody,
    });
    return data;
  };

  const { isLoading, mutate: getIndividualRenewalQuote } = useMutation(
    generateIndividualRenewalQuote,
    {
      onSuccess: (data, variables) => {
        if (!data?.info) {
          setMessages((prev) => [
            ...prev,
            {
              quoteNumber: "",
              text: `Quote for ${variables.licenseTier} had an error and was not sent as expected.`,
            },
          ]);
          console.error("Returned quote has an error: ", data);
        } else if (
          !data?.info.quoteNumber ||
          !data?.info.licenseTier ||
          !data?.info.licenseCount
        ) {
          setMessages((prev) => [
            ...prev,
            {
              quoteNumber: "",
              text: `Quote for ${variables.licenseTier} had an error. Check browser console`,
            },
          ]);
          console.error("Returned quote has an error: ", data.info);
        } else {
          const { licenseTier, quoteNumber } = data.info;
          const quantity = data.info.licenseCount;
          setMessages((prev) => [
            ...prev,
            {
              quoteNumber,
              text: `Quote Number ${quoteNumber} for ${quantity} ${licenseTier} license${
                quantity > 1 ? "s" : ""
              } was successful`,
            },
          ]);
        }
      },
      onError: (error) => {
        toastContext.addToast({
          status: "Error",
          message: JSON.stringify(error),
        });
        console.error(error);
      },
    }
  );

  const handleIndividualQuoteRequest = () => {
    setMessages([]);
    individualLicenses?.forEach((license) => {
      const { licenseTier, licenseCount } = license;
      getIndividualRenewalQuote({
        licenseTier,
        licenseCount,
        contactEmail,
        contactFirst,
        contactLast,
      });
    });
  };

  useEffect(() => {
    if (
      ![contactEmail, contactFirst, contactLast].every((v) => v === "") &&
      [contactEmail, contactFirst, contactLast].some((v) => v === "")
    ) {
      setDisabled(true);
      setSendWarning(
        "If you are manually setting contact info, please fill out all contact information. Otherwise leave all fields blank."
      );
    } else {
      setDisabled(false);
      setSendWarning("");
    }
  }, [contactEmail, contactFirst, contactLast]);

  return (
    <div className="mt-8 flex flex-col">
      <h2 className="text-xl font-bold">
        Send Renewal Quote{(individualLicenses?.length ?? 0) > 1 ? "s" : ""} to
        Orders (individual licenses):
      </h2>
      <div className="mt-2 flex items-center space-x-4">
        {!isSchoolNCES_ID(nces) && !isDistrictNCES_ID(nces) && (
          <div className="flex flex-row">
            <div className="mr-4">
              Confirm Site Type (could not be auto-set):
            </div>
            <div className="flex">
              <div className="mr-4 flex items-center space-x-2">
                <input
                  type="radio"
                  id="confirm-school"
                  name="site-type"
                  value="School"
                  defaultChecked={individualSiteType === "School"}
                  onChange={handleChange}
                />
                <label htmlFor="confirm-school">School</label>
              </div>
              <div className="mr-4 flex items-center space-x-2">
                <input
                  type="radio"
                  id="confirm-district"
                  name="site-type"
                  value="District"
                  defaultChecked={individualSiteType === "District"}
                  onChange={handleChange}
                />
                <label htmlFor="confirm-school">District</label>
              </div>
            </div>
          </div>
        )}
        <button
          className="justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-slate-400"
          type={"button"}
          onClick={handleIndividualQuoteRequest}
          disabled={isLoading || disabled}
        >
          {isLoading ? (
            <DmLoadingSpinner
              className="justify-center rounded-md border border-transparent bg-indigo-600 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              spinnerColor="text-white"
              message="Loading..."
            />
          ) : (
            <div className="flex flex-col space-y-1">
              <div>
                Send Quote{(individualLicenses?.length ?? 0) > 1 ? "s" : ""} to
                Orders
              </div>
              <div>(Individual Licenses)</div>
            </div>
          )}
        </button>
      </div>
      <div className="mt-2 flex flex-col space-y-2">
        {(individualLicenses?.length ?? 0) > 1 && (
          <div className="mt-2 text-red-500">
            NOTE: this site has both PLUS and INTEGRAL individual licenses. If
            you use the send to orders feature, two separate quotes will be made
            and sent.
          </div>
        )}
        {messages?.length > 0 && (
          <ul>
            {messages.map(
              (message: { quoteNumber: string; text: string }, index) => {
                const { quoteNumber, text } = message;
                const key = `message-${index}`;
                if (quoteNumber === "") {
                  return (
                    <li key={key} className="text-red-500">
                      {text}
                    </li>
                  );
                } else {
                  return (
                    <li key={key} className="text-lg">
                      <Link
                        className="text-indigo-500 hover:underline"
                        to={`${REACT_APP_MANAGER_LINK}/quote-lookup/${quoteNumber}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {text}
                      </Link>
                    </li>
                  );
                }
              }
            )}
          </ul>
        )}
        {sendWarning !== "" && (
          <div className="text-dm-earth-yellow-600">{sendWarning}</div>
        )}
      </div>
    </div>
  );
};

export default IndividualQuoteForm;
