import Button from "../../../student/components/generic/button";
import { useMutation } from "react-query";
import axios from "axios";
import { deltamathAPI } from "../../../manager/utils";
import { useNavigate } from "react-router-dom";
import { ExternalLinkIcon } from "@heroicons/react/outline";
import { REACT_APP_LEARNER_LINK } from "../../../utils";
import { useLearnerAnalytics } from "../../analytics/useLearnerAnalytics";
import { parentImpersonationEvent } from "../../analytics/events";

type Props = {
  childName: string;
  childIdToImpersonate: string;
};

const ImpersonationButton = (props: Props) => {
  const navigate = useNavigate();
  const { track } = useLearnerAnalytics();

  const impersonate = useMutation(
    () => {
      return axios.post(
        `${deltamathAPI()}/impersonate/learner/${props.childIdToImpersonate}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    },
    {
      onSuccess: (data) => {
        track(parentImpersonationEvent());
        localStorage.setItem("user", JSON.stringify(data.data.user));
        navigate(`${REACT_APP_LEARNER_LINK}/dashboard`);
      },
    }
  );

  const impersonateClick = () => {
    impersonate.mutate();
  };

  return (
    <Button
      type="outline"
      size="small"
      className="inline-flex h-8 w-full items-center justify-center gap-x-2 self-start font-bold leading-tight max-sm:mx-auto max-sm:mt-2 md:w-auto"
      onClick={impersonateClick}
    >
      Switch to Learner View as {props.childName}
      <ExternalLinkIcon className="h-4 shrink-0" />
    </Button>
  );
};

export default ImpersonationButton;
