import { useEffect } from "react";
import Button from "../../../../student/components/generic/button";
import { useCancellationContext } from "./CancellationContext";
import { CancelStepHeader } from "./CancelStepHeader";
import { useParentContext } from "../../../contexts/ParentContext";
import clsx from "clsx";
import { getLearnerAccountStatus } from "../getLearnerAccountStatus";

type LearnerInfo = {
  fullName: string;
  _id?: string;
};

export const CancelMultipleLearners: React.FC = () => {
  const {
    selectedLearners,
    setSelectedLearners,
    setConfirmDisabled,
    learnerSelectRadioState,
    setLearnerSelectRadioState,
    selectedLearnerNames,
    setSelectedLearnerNames,
    totalSeats,
  } = useCancellationContext();
  const { learners } = useParentContext();

  const toggleSelectedLearner = (learnerInfo: LearnerInfo) => {
    const { _id, fullName } = learnerInfo;
    if (_id) {
      if (selectedLearners.includes(_id)) {
        setSelectedLearners(selectedLearners.filter((id) => id !== _id));
      } else {
        setSelectedLearners([...selectedLearners, _id]);
      }
    }
    if (selectedLearnerNames.includes(fullName)) {
      setSelectedLearnerNames(
        selectedLearnerNames.filter((name) => name !== fullName)
      );
    } else {
      setSelectedLearnerNames([...selectedLearnerNames, fullName]);
    }
  };

  useEffect(() => {
    setConfirmDisabled(
      learnerSelectRadioState === null ||
        (learnerSelectRadioState === "some" &&
          selectedLearnerNames.length === 0)
    );
  }, [
    learnerSelectRadioState,
    selectedLearnerNames.length,
    setConfirmDisabled,
  ]);

  const nonCancelledLearners = learners.filter((learner) => {
    const accountStatus = getLearnerAccountStatus(learner);
    return accountStatus !== "canceled";
  });

  const numUninvitedLearners = totalSeats - nonCancelledLearners.length;

  const allLearners: LearnerInfo[] = [
    ...nonCancelledLearners.map((learner) => {
      return {
        fullName: `${learner.first} ${learner.last}`,
        _id: learner._id,
      };
    }),
    ...Array.from(
      { length: numUninvitedLearners },
      (_, index) => index + nonCancelledLearners.length + 1
    ).map((learnerNum) => {
      return {
        fullName: `Learner ${learnerNum}`,
      };
    }),
  ];

  return (
    <div className="flex flex-col gap-10">
      <CancelStepHeader title="Are you canceling all learner subscriptions?" />
      <div className="flex flex-col items-center gap-4">
        <Button
          className={clsx(
            "w-48 rounded-full !px-0 text-sm",
            learnerSelectRadioState === "all" &&
              "pointer-events-none border-dm-brand-blue-800 bg-dm-brand-blue-800 text-white focus:text-white"
          )}
          type="outline"
          onClick={() => setLearnerSelectRadioState("all")}
        >
          Yes, all learners.
        </Button>
        <Button
          className={clsx(
            "w-48 rounded-full !px-0 text-sm",
            learnerSelectRadioState === "some" &&
              "pointer-events-none border-dm-brand-blue-800 bg-dm-brand-blue-800 text-white focus:text-white"
          )}
          type="outline"
          onClick={() => setLearnerSelectRadioState("some")}
        >
          No, only some learners.
        </Button>
      </div>
      {learnerSelectRadioState === "some" && (
        <form
          className="flex flex-col gap-4"
          onSubmit={(e) => e.preventDefault()}
        >
          <h3 className="text-center font-serif font-bold text-dm-brand-blue-600 sm:text-lg">
            Please select the learner accounts you&apos;d like to cancel:
          </h3>
          <div className="flex flex-col gap-[10px] px-6">
            {allLearners.map((learnerInfo, index) => (
              <label
                key={learnerInfo.fullName.replace(/ /g, "_") + index}
                className="flex items-center gap-2"
              >
                <input
                  className="focus:ring-transparent"
                  type="checkbox"
                  onChange={() => toggleSelectedLearner(learnerInfo)}
                  checked={selectedLearnerNames.includes(learnerInfo.fullName)}
                />
                {learnerInfo.fullName}
              </label>
            ))}
          </div>
        </form>
      )}
    </div>
  );
};
