import { GiftIcon } from "../../../shared/icons/GiftIcon";
import Button from "../../../student/components/generic/button";
import { DashboardCard } from "./DashboardCard";
import { useNextPrize } from "../../utils/useNextPrize";
import { REACT_APP_LEARNER_LINK, getFilePath } from "../../../utils";
import { ResponsiveImage } from "../ResponsiveImage";

export const NextPrizeCard: React.FC = () => {
  const nextPrize = useNextPrize();

  return (
    <DashboardCard title="Next Prize" icon={<GiftIcon />}>
      <div className="flex items-center justify-between gap-4">
        <div className="mt-4">
          <h4 className="font-serif text-lg font-bold">
            {nextPrize === null ? "More Prizes Coming Soon" : nextPrize?.name}
          </h4>
          <Button
            type="link"
            className="cursor-pointer text-xs"
            href={`${REACT_APP_LEARNER_LINK}/points/prizes`}
          >
            View All Prizes
          </Button>
        </div>
        <span className="max-w-[80px] overflow-hidden rounded-lg">
          {nextPrize === null ? (
            <ResponsiveImage
              srcs={[
                getFilePath("/images/learner/prizes/deltie-prizes-soon.png"),
                getFilePath("/images/learner/prizes/deltie-prizes-soon@2x.png"),
              ]}
              alt="More prizes soon"
            />
          ) : (
            nextPrize?.image
          )}
        </span>
      </div>
    </DashboardCard>
  );
};
