import { processChoices } from "./snakeGameProblemGenerator";

const { rand, $ } = window;

/** Which number is divisible by 6? 9?
 * @return problem object with the following properties: questionPrompt (string with html), question?  (string), choices (array of 4 strings representing answer choices with the correct answer always at index 0), solution function to run
 */
export function divisibilityRulesLevel2() {
  const types = [6, 9];
  const type = types[rand(0, types.length - 1)];

  switch (type) {
    case 6:
      return divisibleBy6();
    case 9:
      return divisibleBy9();
    default:
      break;
  }
}

/** Find a number that is divisible by 6
 * @return problem object with the following properties: questionPrompt (string with html), question? (string), choices (array of 4 strings representing answer choices with the correct answer always at index 0), solution function to run
 */
function divisibleBy6() {
  const correctAnswer = 6 * rand(13, 99);

  // even but not divisible by 3
  const n = rand(24, 198);
  const distractor1 = 2 * (n + Math.floor(n / 2) + 1);

  // divisible by 3 but not even
  const k = 3 * rand(25, 197);
  const distractor2 = k % 2 === 0 ? k + 3 : k;

  // digits add to 6, but it is odd
  const hundredsDig = rand(0, 3) === 0 ? rand(4, 5) : rand(1, 3); // favors lower digs with more possibilities (hundreds digit of 4 and 5 only have one possibility each)
  const tensDig =
    hundredsDig % 2 === 0
      ? 2 * rand(0, (5 - hundredsDig - 1) / 2) + 1
      : 2 * rand(0, (5 - hundredsDig) / 2);
  const onesDig = 6 - (hundredsDig + tensDig);
  const distractor3 = hundredsDig * 100 + tensDig * 10 + onesDig;

  const choices = processChoices(
    [distractor1, distractor2, distractor3],
    correctAnswer
  );
  if (choices.length !== 4) return divisibleBy6();

  const correctHundredsDig = Math.floor(correctAnswer / 100);
  const correctTensDig = Math.floor(correctAnswer / 10) % 10;
  const correctOnesDig = correctAnswer % 10;
  const digSum = correctHundredsDig + correctTensDig + correctOnesDig;

  const solution = (id) => {
    $(id).html(
      `\\(${correctAnswer}\\) is even and \\(${
        correctHundredsDig > 0 ? correctHundredsDig + "+" : ""
      }${correctTensDig} + ${correctOnesDig} = ${digSum}\\), divisible by \\(3\\)`
    );
  };

  return {
    questionPrompt: `Which number is divisible by \\(6\\)?`,
    choices,
    solution,
  };
}

/** Find a number that is divisible by 9
 * @return problem object with the following properties: questionPrompt (string with html), question? (string), choices (array of 4 strings representing answer choices with the correct answer always at index 0), solution function to run
 */
function divisibleBy9() {
  const correctAnswer = 9 * rand(12, 66);

  // last digit is a 9 (not divisible by 9)
  const tens = Math.floor((rand(8, 52) * 9) / 8) + 1;
  const distractor1 = tens * 10 + 9;

  // last two digits are divisible by 9
  const distractor2 = generateDistractors();
  const distractor3 = generateDistractors();

  const choices = processChoices(
    [distractor1, distractor2, distractor3],
    correctAnswer
  );
  if (choices.length !== 4) return divisibleBy9();

  const correctHundredsDig = Math.floor(correctAnswer / 100);
  const correctTensDig = Math.floor(correctAnswer / 10) % 10;
  const correctOnesDig = correctAnswer % 10;
  const digSum = correctHundredsDig + correctTensDig + correctOnesDig;

  const solution = (id) => {
    $(id).html(
      `\\(${
        correctHundredsDig > 0 ? correctHundredsDig + "+" : ""
      }${correctTensDig} + ${correctOnesDig} = ${digSum}\\), divisible by \\(9\\)`
    );
  };

  return {
    questionPrompt: `Which number is divisible by \\(9\\)?`,
    choices,
    solution,
  };
}

function generateDistractors() {
  const ones = 9 * rand(2, 11);
  const hundreds = rand(1, 5);
  return hundreds * 100 + ones;
}
