export const Profile: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => (
  <div className="flex flex-col gap-8 rounded-xl border border-dm-charcoal-100 bg-white p-6">
    <h3 className="flex items-center gap-2">
      <span className="flex h-6 w-6 justify-center leading-none">
        <i
          className="far fa-user text-xl text-dm-gray-800"
          aria-hidden="true"
        ></i>
      </span>
      <span className="text-sm leading-6 text-dm-gray-600">Profile</span>
    </h3>
    <div className="flex flex-col gap-4">{children}</div>
  </div>
);
