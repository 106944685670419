import React, { useEffect, useRef } from "react";
import { InputField } from "../../../student/components/answerForm/InputField";
import { useFocusContext } from "../../contexts/FocusContext";

type Props = {
  inputNum: number;
  inputFields: Array<{
    num: number;
    ref: React.Ref<HTMLSpanElement>;
  }>;
  setInputFields: (
    fields: Array<{
      num: number;
      ref: React.Ref<HTMLSpanElement>;
    }>
  ) => void;
  totalFields: number;
  problemData: any;
  handleChange: (mq: any) => void;
  handleSubmit: () => void;
  defaultFocus: boolean;
};

const InputFieldWrapper = (props: Props) => {
  const focusContext = useFocusContext();
  const ref = useRef<HTMLSpanElement | null>(null);

  // bind functions to focusContext - they are passed to an event listener
  const setShowKeyboard = focusContext.openKeyboard.bind(focusContext);
  const setShowCalculator = focusContext.openCalculator.bind(focusContext);

  // If the number doesn't match what we already have we set it in the state
  useEffect(() => {
    if (
      !props.inputFields.some(
        (inputField) => inputField.num === props.inputNum
      ) &&
      ref.current
    ) {
      props.setInputFields([
        ...props.inputFields,
        {
          num: props.inputNum,
          ref: ref,
        },
      ]);
    }
  }, [props.inputFields, props.inputNum, ref.current]);

  // This will default focus the last input
  useEffect(() => {
    if (ref.current && props.defaultFocus) {
      ref.current.focus();
    }
  }, [props.defaultFocus, ref.current]);

  return (
    <InputField
      setShowKeyboard={setShowKeyboard}
      setShowCalculator={setShowCalculator}
      currentInputField={props.inputNum}
      numInputFields={props.totalFields}
      leftLatex={props.problemData.leftLatex}
      rightLatex={props.problemData.rightLatex}
      answerWidth={props.problemData.answerWidth}
      binomialExponent={props.problemData.binomialExponent}
      handleChange={props.handleChange}
      handleFocus={(mq: any) => {
        if (mq) {
          const element = document.getElementById(mq);
          if (element) {
            focusContext.setMQ(element);
          }
        }
      }}
      ref={(node: any) => {
        if (!ref.current) {
          ref.current = node;
        }
      }}
      key={"inputs" + props.inputNum}
      handleSubmit={props.handleSubmit}
    />
  );
};

export default InputFieldWrapper;
