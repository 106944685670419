import { format } from "date-fns";
import { ProfilePage } from "./ProfilePage";
import { ProfileSection } from "./ProfileSection";
import { ProfileField } from "./ProfileField";
import { ProfileFieldGroup } from "./ProfileFieldGroup";
import { Learner, LearnerSubscriptionDetails } from "../../types";
import { useDMQuery } from "../../../utils";
import { ProfileColumn } from "./ProfileColumn";
import { SubscriptionDetailsField } from "./Fields/SubscriptionDetailsField";
import { BillingFrequencyField } from "./Fields/BillingFrequencyField";
import { BillingHistoryField } from "./Fields/BillingHistoryField";
import { PaymentInformationField } from "./Fields/PaymentInformationField";
import { UpcomingChargesField } from "./Fields/UpcomingChargesField";
import { LearnerEditAccountButton } from "./LearnerEditAccountButton";
import { CancellationContextProvider } from "./Cancellation/CancellationContext";
import { CancelSubscriptionModal } from "./Cancellation/CancelSubscriptionModal";
import { useEffect, useState } from "react";
import { useLearnerContext } from "../../contexts/LearnerContext";
import { isSubscriptionActive } from "../../utils/isSubscriptionActive";
import Modal from "../../../student/components/generic/Modal";
import { HomepageSignupContextProvider } from "../Home/HomepageSignupContext";
import { Payment } from "../Home/Payment/Payment";

export const IndependentLearnerProfile: React.FC<{ user: Learner }> = ({
  user,
}) => {
  const { learner } = useLearnerContext();
  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const { data: subscriptionData, refetch: refetchSubscriptionData } =
    useDMQuery<{
      subscription: LearnerSubscriptionDetails;
    }>({
      path: "/payments/subscriptions/active",
      queryOptions: { refetchOnWindowFocus: false },
    });

  useEffect(() => {
    if (learner && !isSubscriptionActive(learner)) {
      setShowPaymentModal(true);
    }
  }, [learner]);

  return (
    <ProfilePage>
      <CancellationContextProvider
        refetchSubscriptionData={refetchSubscriptionData}
        subscription={subscriptionData?.subscription}
      >
        <ProfileSection title="Account & Subscription Information">
          <ProfileColumn>
            <ProfileFieldGroup title="Your Account" className="relative">
              <LearnerEditAccountButton
                className="absolute right-0 top-0 sm:right-10"
                user={user}
              />
              <ProfileField label="Account Holder Name">
                {user.first} {user.last}
              </ProfileField>
              <ProfileField label="Account Holder Email">
                {user.email}
              </ProfileField>
              <ProfileField label="Password">{"*".repeat(16)}</ProfileField>
              <ProfileField label="Learner Start Date">
                {format(new Date(user.createdAt), "MMMM' 'd', 'yyyy")}
              </ProfileField>
            </ProfileFieldGroup>

            <ProfileFieldGroup title="Your Subscription">
              <SubscriptionDetailsField
                subscription={subscriptionData?.subscription}
                setShowPaymentModal={() => setShowPaymentModal(true)}
              />
            </ProfileFieldGroup>
          </ProfileColumn>

          <ProfileColumn>
            <ProfileFieldGroup title="Billing">
              <UpcomingChargesField
                subscription={subscriptionData?.subscription}
              />

              <BillingFrequencyField
                subscription={subscriptionData?.subscription}
              />

              {subscriptionData?.subscription.status !== "trialing" && (
                <BillingHistoryField
                  periodStart={subscriptionData?.subscription.periodStart}
                />
              )}

              <PaymentInformationField
                subscription={subscriptionData?.subscription}
              />
            </ProfileFieldGroup>
          </ProfileColumn>
        </ProfileSection>

        <CancelSubscriptionModal />

        <Modal
          visible={showPaymentModal}
          onClose={() => setShowPaymentModal(false)}
          closeX
          bodyClassName="!p-0"
          widthClassName="max-w-full w-[1041px] transition-none"
          body={
            <HomepageSignupContextProvider
              plansRef={null}
              initialAccountType="learner"
            >
              <Payment
                title="Select a Plan to Renew Access"
                buttonText="Reactivate My Subscription"
                isRenewal
              />
            </HomepageSignupContextProvider>
          }
        />
      </CancellationContextProvider>
    </ProfilePage>
  );
};
