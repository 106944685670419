import { Dispatch, SetStateAction } from "react";
import clsx from "clsx";
import Button from "../../student/components/generic/button";
import useStripeCheckoutMutation from "../utils/useStripeCheckoutMutation";
import AllFeaturesAccordion from "./AllFeaturesAccordion";

type Props = {
  type: "plus" | "integral";
  fullPrice: number;
  discountedPrice?: number;
  expiration?: number;
  twoColumnDisplay?: boolean;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  productKey: string;
};

const FeatureBlock = (props: Props): JSX.Element => {
  const { mutate } = useStripeCheckoutMutation();
  //TODO: checking if page is embedded in Angular iframe
  const browserWindow = window.self === window.top ? window : window.parent;

  const subscriptionExpiration = props.expiration
    ? new Date(props.expiration * 1000).toLocaleDateString("en-US", {
        dateStyle: "medium",
      })
    : undefined;

  const handleStripeCheckout = async () => {
    try {
      const data = await mutate({
        productKey: props.productKey,
      });
      const url = data.data.url;
      browserWindow.location.href = url;
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div
      className={clsx(
        "featureblock flex flex-col rounded-lg border border-dm-charcoal-100 bg-white px-6 pb-6 pt-8 text-dm-gray-800",
        props.twoColumnDisplay
          ? "gap-4 md:grid md:grid-cols-2"
          : "flex flex-col"
      )}
    >
      <div className="flex grow flex-col items-start justify-start gap-4">
        <h3
          className={clsx(
            "rounded-sm px-2 py-0.5 text-lg tracking-wide",
            props.type === "plus"
              ? "bg-dm-brand-blue-200"
              : "bg-dm-earth-yellow-500"
          )}
        >
          <strong>Delta</strong>Math{" "}
          {props.type === "plus" ? "PLUS" : "INTEGRAL"}
        </h3>
        {props.type === "plus" ? (
          <p className="leading-relaxed text-dm-gray-600">
            DeltaMath PLUS allows for further customizations for assignments
            using problem types, instructional videos for each module, creation
            of tests, coteachers for classes, and so much more.
          </p>
        ) : (
          <p className="leading-relaxed text-dm-gray-600">
            Get all features of DeltaMath PLUS as well as print assignment to
            PDF, Student upload of work, and Canvas/Schoology integration for an
            individual teacher.
          </p>
        )}
        <AllFeaturesAccordion
          type={props.type}
          isOpen={props.isOpen}
          setIsOpen={props.setIsOpen}
        />
      </div>
      <div
        className={clsx(
          "flex flex-col gap-4",
          props.twoColumnDisplay && "md:self-end"
        )}
      >
        <div
          className={clsx(
            "flex flex-row items-center gap-2 pt-4 font-serif text-2xl font-bold",
            props.twoColumnDisplay && "flex-wrap"
          )}
        >
          <span
            className={clsx(
              props.discountedPrice
                ? "text-dm-gray-200 line-through"
                : "rounded bg-dm-success-200 px-2"
            )}
          >
            ${props.fullPrice}
          </span>
          {props.discountedPrice ? (
            <span className="rounded bg-dm-success-200 px-2">
              ${props.discountedPrice}
            </span>
          ) : null}
          {subscriptionExpiration ? (
            <p
              className={clsx(
                "font-sans text-sm font-normal",
                props.twoColumnDisplay && "w-full"
              )}
            >
              Subscription through <strong>{subscriptionExpiration}</strong>
            </p>
          ) : null}
        </div>

        <Button
          type="primary"
          size="standard"
          className="mt-2 w-full"
          onClick={handleStripeCheckout}
        >
          Upgrade Today
        </Button>
      </div>
    </div>
  );
};

export default FeatureBlock;
