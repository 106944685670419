import { useContext, useState, useCallback } from "react";
import CelebrateIcon from "../icons/CelebrateIcon";
import { SectionDataStatus } from "../../_constants";
import StudentSectionsContext from "../../_context/StudentSectionsContext";
import Assignments from "../../components/assignments";
import SectionEmptyState from "./SectionEmptyState";
import SectionWelcomeState from "./SectionWelcomeState";
import { getSectionData } from "../../utils";
import FadeInSection from "../FadeInSection";
import clsx from "clsx";
import { REACT_APP_STUDENT_LINK } from "../../../utils";

export default function Upcoming(): JSX.Element {
  const { activeSection, dmAssignmentData, showPastDue } = useContext(
    StudentSectionsContext
  );

  const [assignmentType, setAssignmentType] = useState<string>("all");

  const assignmentList = getSectionData(
    SectionDataStatus.upcoming,
    activeSection,
    dmAssignmentData
  );
  const assignmentCount = assignmentList?.length;

  const assignmentByType = useCallback(
    (type = "") =>
      assignmentList.filter((assignment: any) => {
        const aType = assignment.ta.type;
        const isTest = assignment.ta.is_test || false;

        switch (type) {
          case "standard": {
            if (aType === "standard" && isTest === false) {
              return assignment;
            }
            break;
          }
          case "test": {
            if (aType === "standard" && isTest === true) {
              return assignment;
            }
            break;
          }
          case "correction": {
            if (aType === "correction") {
              return assignment;
            }
            break;
          }
          case "all":
          default:
            return assignment;
        }
      }),
    [assignmentList]
  );

  // show assignment sorting buttons if there are more than 5 total
  // and they are not all the same type
  const showAssignmentTypeButtons = (): boolean =>
    assignmentCount > 4 &&
    assignmentByType("standard").length !== assignmentCount &&
    assignmentByType("test").length !== assignmentCount &&
    assignmentByType("correction").length !== assignmentCount;

  return (
    <>
      {typeof dmAssignmentData[activeSection] === "undefined" ? (
        <SectionWelcomeState />
      ) : assignmentCount ? (
        <>
          {showAssignmentTypeButtons() ? (
            <div className="overflow-hidden">
              <div className="flex flex-nowrap gap-3 overflow-auto border-b border-dm-charcoal-100 bg-white px-4 py-4 text-dm-charcoal-800 sm:px-6">
                <FadeInSection fadeClass="fade-in-badge" threshold={0.65}>
                  <button
                    className={clsx(
                      "group whitespace-nowrap rounded-full border-2 px-4 py-1.5 text-sm",
                      assignmentType === "all"
                        ? "border-dm-brand-blue-800 bg-dm-brand-blue-800 text-white"
                        : "border-dm-brand-blue-200 bg-transparent hover:bg-dm-crystal-blue-100"
                    )}
                    onClick={() => setAssignmentType("all")}
                  >
                    All
                    <span
                      className={clsx(
                        "ml-2 rounded-full px-2.5 py-0.5 text-xs",
                        assignmentType === "all"
                          ? "bg-white text-dm-charcoal-800"
                          : "bg-dm-charcoal-100 text-dm-charcoal-800"
                      )}
                      aria-label={`${assignmentByType("all").length} task${
                        assignmentByType("all").length !== 1 ? "s" : ""
                      }.`}
                    >
                      {assignmentByType("all").length}
                    </span>
                  </button>
                </FadeInSection>
                {assignmentByType("standard").length > 0 ? (
                  <FadeInSection fadeClass="fade-in-badge" threshold={0.65}>
                    <button
                      className={clsx(
                        "group whitespace-nowrap rounded-full border-2 px-4 py-1.5 text-sm",
                        assignmentType === "standard"
                          ? "border-dm-brand-blue-800 bg-dm-brand-blue-800 text-white"
                          : "border-dm-brand-blue-200 bg-transparent hover:bg-dm-crystal-blue-100"
                      )}
                      onClick={() => setAssignmentType("standard")}
                    >
                      Assignments
                      <span
                        className={clsx(
                          "ml-2 rounded-full px-2.5 py-0.5 text-xs",
                          assignmentType === "standard"
                            ? "bg-white text-dm-charcoal-800"
                            : "bg-dm-charcoal-100 text-dm-charcoal-800"
                        )}
                        aria-label={`${
                          assignmentByType("standard").length
                        } task${
                          assignmentByType("standard").length !== 1 ? "s" : ""
                        }.`}
                      >
                        {assignmentByType("standard").length}
                      </span>
                    </button>
                  </FadeInSection>
                ) : null}
                {assignmentByType("test").length > 0 ? (
                  <FadeInSection fadeClass="fade-in-badge" threshold={0.65}>
                    <button
                      className={clsx(
                        "group whitespace-nowrap rounded-full border-2 px-4 py-1.5 text-sm",
                        assignmentType === "test"
                          ? "border-dm-brand-blue-800 bg-dm-brand-blue-800 text-white"
                          : "border-dm-brand-blue-200 bg-transparent hover:bg-dm-crystal-blue-100"
                      )}
                      onClick={() => setAssignmentType("test")}
                    >
                      Tests
                      <span
                        className={clsx(
                          "ml-2 rounded-full px-2.5 py-0.5 text-xs",
                          assignmentType === "test"
                            ? "bg-white text-dm-charcoal-800"
                            : "bg-dm-charcoal-100 text-dm-charcoal-800"
                        )}
                        aria-label={`${assignmentByType("test").length} task${
                          assignmentByType("test").length !== 1 ? "s" : ""
                        }.`}
                      >
                        {assignmentByType("test").length}
                      </span>
                    </button>
                  </FadeInSection>
                ) : null}

                {assignmentByType("correction").length > 0 ? (
                  <FadeInSection fadeClass="fade-in-badge" threshold={0.65}>
                    <button
                      className={clsx(
                        "group whitespace-nowrap rounded-full border-2 px-4 py-1.5 text-sm",
                        assignmentType === "correction"
                          ? "border-dm-brand-blue-800 bg-dm-brand-blue-800 text-white"
                          : "border-dm-brand-blue-200 bg-transparent hover:bg-dm-crystal-blue-100"
                      )}
                      onClick={() => setAssignmentType("correction")}
                    >
                      Corrections
                      <span
                        className={clsx(
                          "ml-2 rounded-full px-2.5 py-0.5 text-xs",
                          assignmentType === "correction"
                            ? "bg-white text-dm-charcoal-800"
                            : "bg-dm-charcoal-100 text-dm-charcoal-800"
                        )}
                        aria-label={`${
                          assignmentByType("correction").length
                        } task${
                          assignmentByType("correction").length !== 1 ? "s" : ""
                        }.`}
                      >
                        {assignmentByType("correction").length}
                      </span>
                    </button>
                  </FadeInSection>
                ) : null}
              </div>
            </div>
          ) : null}
          <div className="mt-4 px-4 sm:mt-6 sm:px-6">
            {assignmentByType(assignmentType)?.map((section: any) => (
              <Assignments
                section={section}
                key={`up-section:${encodeURI(section?.ta?._id)}`}
              />
            ))}
          </div>
        </>
      ) : (
        <SectionEmptyState
          icon={<CelebrateIcon />}
          header="You're all caught up!"
          message={`Go to the ${
            showPastDue ? "Past Due or " : ""
          }Completed tab to keep practicing skills.`}
          btnText={
            showPastDue
              ? "Work On Past Due Assignments"
              : "Practice Skills From Completed Assignments"
          }
          btnLink={
            showPastDue
              ? `${REACT_APP_STUDENT_LINK}/${activeSection}/${SectionDataStatus["past-due"]}`
              : `${REACT_APP_STUDENT_LINK}/${activeSection}/${SectionDataStatus.completed}`
          }
        />
      )}
    </>
  );
}
