import { useEffect } from "react";
import ReactTooltip from "react-tooltip";
import { useIntersectionObserver } from "usehooks-ts";
import clsx from "clsx";
import Modal from "../../../student/components/generic/Modal";
import { ParentActivity } from "../../types";
import RecentActivityDetailCard from "../Parent/RecentActivityDetailCard";
import { useLearnerAnalytics } from "../../analytics/useLearnerAnalytics";
import { parentOpenRecentActivityModalEvent } from "../../analytics/events";

type Props = {
  visible: boolean;
  onClose: () => void;
  activity: ParentActivity[] | undefined;
  hasNextPage: boolean | undefined;
  fetchNextPage: () => void;
  isFetchingNextPage: boolean;
};

const RecentActivityModal: React.FC<Props> = (props: Props) => {
  const { track } = useLearnerAnalytics();
  const { isIntersecting, ref: moreActivityRef } = useIntersectionObserver({
    threshold: 0.1,
  });

  useEffect(() => {
    if (isIntersecting && props.hasNextPage && !props.isFetchingNextPage) {
      props.fetchNextPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIntersecting, props.hasNextPage]);

  useEffect(() => {
    if (props.visible) {
      track(parentOpenRecentActivityModalEvent());
    }
  }, [props.visible, track]);

  return (
    <>
      <Modal
        visible={props.visible}
        onClose={() => props.onClose()}
        title={
          <span className="text-center font-serif text-2xl font-bold">
            Recent Activity
          </span>
        }
        body={
          <div className="flex flex-col gap-y-4">
            {props.activity?.map((activity, index) => {
              return (
                <RecentActivityDetailCard
                  activity={activity}
                  key={`${activity.title}-${index}-detail`}
                />
              );
            })}
            <div
              ref={moreActivityRef}
              className={clsx(
                "text-dm w-full text-sm font-bold italic text-dm-charcoal-200",
                props.isFetchingNextPage || props.hasNextPage
                  ? "animate-ellipsis"
                  : null
              )}
            >
              {props.isFetchingNextPage || props.hasNextPage
                ? "Getting More Activity"
                : "That's Everything!"}
            </div>
          </div>
        }
        closeX
        noLine
        largeModal
      />
      <ReactTooltip
        id="activityTooltip"
        className="max-w-96 !rounded !px-3 font-sans text-xs leading-normal before:!-z-10"
        effect="float"
        delayShow={250}
        delayHide={50}
        multiline={true}
        place="top"
        html={true}
        getContent={(dataTip) => dataTip}
      />
    </>
  );
};

export default RecentActivityModal;
