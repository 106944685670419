import clsx from "clsx";
import { LearnerSubscriptionDetails } from "../../../types";
import { getLearnerAccountStatus } from "../getLearnerAccountStatus";
import { ProfileField } from "../ProfileField";
import { SubscriptionStatusPill } from "../SubscriptionStatusPill";
import { formatPrice } from "../../../utils/formatPrice";
import { format } from "date-fns";
import { SkeletonText } from "../../Skeleton";
import { useLearnerContext } from "../../../contexts/LearnerContext";
import Button from "../../../../student/components/generic/button";
import { isSubscriptionActive } from "../../../utils/isSubscriptionActive";

export const SubscriptionDetailsField: React.FC<{
  subscription: LearnerSubscriptionDetails | undefined;
  setShowPaymentModal: () => void;
}> = ({ subscription, setShowPaymentModal }) => {
  const { learner } = useLearnerContext();

  if (!subscription || !learner) {
    return (
      <ProfileField label="Subscription Details">
        <SkeletonText>Loading subscription data</SkeletonText>
      </ProfileField>
    );
  }

  const { discount } = subscription;
  const status = getLearnerAccountStatus(learner);
  const isCanceled = status === "canceled";

  return (
    <ProfileField label="Subscription Details">
      <div className="flex flex-col items-start gap-2 md:flex-row">
        <span>
          DeltaMath for Home for{" "}
          <strong>
            {subscription.quantity === 1
              ? "1 learner"
              : `${subscription.quantity} learners`}{" "}
            ({subscription.interval === "month" ? "Monthly" : "Annual"})
          </strong>
        </span>
        <SubscriptionStatusPill status={status} />
      </div>
      <div className="flex items-center gap-2 font-serif text-lg font-bold">
        <span className={clsx(discount && !isCanceled && "line-through")}>
          {formatPrice(subscription.amount)}
        </span>
        {discount && !isCanceled && (
          <>
            <span>
              {formatPrice({
                value: subscription.amount.value * (discount.percentOff / 100),
                currency: subscription.amount.currency,
              })}
            </span>
            {!isCanceled && (
              <span className="font-sans text-sm font-normal italic">
                Limited time offer until{" "}
                {format(new Date(discount.periodEnd), "MM/dd/yy")}
              </span>
            )}
          </>
        )}
      </div>
      {!isSubscriptionActive(learner) && (
        <Button className="mt-3" onClick={setShowPaymentModal}>
          Reactivate My Subscription
        </Button>
      )}
    </ProfileField>
  );
};
