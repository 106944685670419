import { useState } from "react";
import Button from "../../../student/components/generic/button";
import { FeatureFlag, PartialFeatureFlag } from "./types";

export const UsersCell: React.FC<{
  flag: FeatureFlag;
  updateFlag: (flag: PartialFeatureFlag) => void;
}> = ({ flag, updateFlag }) => {
  const [showForm, setShowForm] = useState(false);
  const [value, setValue] = useState(flag.users.join(", "));
  return showForm ? (
    <form
      className="flex flex-col items-start gap-2"
      onSubmit={(e) => {
        e.preventDefault();
        const message = `This will update the users for '${flag.name}'. Are you sure you want to continue?`;
        if (flag.state === "disabled" || window.confirm(message)) {
          updateFlag({ ...flag, users: value.split(",").map((u) => u.trim()) });
        }
      }}
    >
      <label className="-mb-1 text-sm" htmlFor="ids">
        User ids, comma-separated
      </label>
      <textarea
        id="ids"
        className="h-20 w-64 resize-y rounded border-dm-charcoal-200 py-1 hover:border-dm-charcoal-500"
        onChange={(e) => setValue(e.target.value)}
        value={value}
        data-lpignore
        data-allow-1password="no"
        autoComplete="off"
      />
      <div className="flex gap-4">
        <Button size="small">Save</Button>
        <button className="text-sm" onClick={() => setShowForm(false)}>
          Cancel
        </button>
      </div>
    </form>
  ) : (
    <button
      className="overflow-hidden text-ellipsis whitespace-nowrap underline hover:no-underline"
      onClick={() => setShowForm(true)}
    >
      {flag.users.length === 1 ? "1 user" : `${flag.users.length} users`}
    </button>
  );
};
