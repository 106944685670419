import { useEffect, useState } from "react";
import { Disclosure, Switch } from "@headlessui/react";
import clsx from "clsx";
import { Link, NavLink, useParams } from "react-router-dom";
import { getAdminParams } from "./utils";
import { useQueryClient } from "react-query";
import { deltamathAPI } from "../manager/utils";
import axios from "axios";
import { useDeltaToastContext } from "../shared/contexts/ToasterContext";
import { demoString } from "../utils/demo";
import { useUserContext } from "../shared/contexts/UserContext";
import Cookies from "js-cookie";
import {
  REACT_APP_ADMIN_LINK,
  REACT_APP_HOMEPAGE_LINK,
  REACT_APP_TEACHER_LINK,
} from "../utils";
import PeopleIcon from "../shared/icons/PeopleIcon";
import SchoolBellIcon from "../shared/icons/SchoolBellIcon";
import ClipboardIcon from "../shared/icons/ClipboardIcon";
import DocumentIcon from "../shared/icons/DocumentIcon";
import ChevronDown from "../shared/icons/ChevronDown";
import QuestionMarkCircleIcon from "../shared/icons/QuestionMarkCircleIcon";
import MortarBoardIcon from "../shared/icons/MortarBoardIcon";
import { DocumentAddIcon } from "@heroicons/react/solid";
import { PlusIcon } from "@heroicons/react/outline";

export default function SideNavigation({
  schools,
  hasIntegralAtAll,
  demoModeEnabled,
  setDemoModeEnabled,
}: {
  schools: any;
  hasIntegralAtAll: boolean;
  demoModeEnabled: boolean;
  setDemoModeEnabled: any;
}) {
  const toastContext = useDeltaToastContext();
  const userContext = useUserContext();
  const adminParams = getAdminParams();
  const queryClient = useQueryClient();
  const params = useParams();
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const admin = JSON.parse(localStorage.getItem("admin") || "{}");
  const token = userContext.getJWT();
  const [isCustomerService, setIsCustomerService] = useState(false);
  const [hideDemoMode, setHideDemoMode] = useState(false);

  useEffect(() => {
    if (demoModeEnabled) {
      localStorage.setItem("demo_mode_enabled", "true");
    } else {
      localStorage.removeItem("demo_mode_enabled");
    }
  }, [demoModeEnabled]);

  useEffect(() => {
    setIsCustomerService(!!localStorage.getItem("customer_service_token"));
    const handleKeyDown = (event: any) => {
      // Define your key combination here
      if (event.ctrlKey && event.altKey && event.code === "KeyH") {
        setHideDemoMode((prev) => !prev);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const schoolsForNav: { name: string; href: string; roles: string[] }[] =
    schools.map((school: any) => ({
      name: school.schoolName,
      href: `${REACT_APP_ADMIN_LINK}/schools/${school.schoolid}`,
      roles: ["district", "super_district"],
    }));

  const topNavigation = [
    {
      name: "District Admins",
      icon: PeopleIcon,
      href: `${REACT_APP_ADMIN_LINK}/district/current-admins`,
      roles: ["district", "super_district"],
      integralOnly: false,
    },
    {
      name: "School Summary",
      icon: SchoolBellIcon,
      href: `${REACT_APP_ADMIN_LINK}/schools`,
      roles: ["district", "super_district", "school", "super_school"],
      integralOnly: false,
      children: schoolsForNav,
    },
    {
      name: "Pushed Assignments",
      roles: ["district", "super_district", "school", "super_school"],
      integralOnly: true,
      icon: DocumentIcon,
      children: [
        {
          name: "Create Assignment",
          href: `${REACT_APP_ADMIN_LINK}/push-assignment`,
          roles: ["district", "super_district", "school", "super_school"],
        },
        {
          name: "View Assignments",
          href: `${REACT_APP_ADMIN_LINK}/view-pushed-assignments`,
          roles: ["district", "super_district", "school", "super_school"],
        },
      ],
    },
    {
      name: "Reports",
      icon: ClipboardIcon,
      roles: ["district", "super_district", "school", "super_school"],
      integralOnly: true,
      children: [
        {
          name: "Student Usage",
          href: `${REACT_APP_ADMIN_LINK}/reports/student-usage/select-scope/schools`,
        },
        {
          name: "Student Performance",
          href: `${REACT_APP_ADMIN_LINK}/reports/student-performance/select-scope`,
        },
      ],
    },
  ];

  const bottomNavigation = [
    {
      name: "Help Videos",
      icon: QuestionMarkCircleIcon,
      href: `${REACT_APP_ADMIN_LINK}/help-videos`,
      roles: ["school", "super_school", "district", "super_district"],
      integralOnly: false,
    },
    {
      name: `${user.first} ${user.last}`,
      icon: MortarBoardIcon,
      href: `${REACT_APP_ADMIN_LINK}/profile`,
      roles: ["school", "super_school", "district", "super_district"],
      integralOnly: false,
      secondAction: {
        name: "Log Out",
        action: () => {
          queryClient.invalidateQueries();
          queryClient.removeQueries();
          userContext.clearJWT();
          localStorage.removeItem("admin");
          localStorage.removeItem("user");
          Cookies.remove("refresh_token_javascript");
          window.location.href = `${REACT_APP_HOMEPAGE_LINK}`;
        },
      },
    },
  ];

  const slugFromHref = (href: string) => {
    const arr = href.split("/");
    return arr.at(-1) || "";
  };

  return (
    <>
      <nav className="relative flex h-full w-[275px] flex-col justify-between py-[40px]">
        <div id="top-items" className="space-y-1">
          {topNavigation.map((item) => (
            <div key={item.name}>
              {item.children ? (
                item.href ? (
                  <>
                    {item.roles &&
                      item?.roles.length > 0 &&
                      item?.roles.includes(adminParams.account_type) &&
                      (!item?.integralOnly ||
                        (item?.integralOnly && hasIntegralAtAll)) && (
                        <Disclosure
                          as="div"
                          key={`${item.name}-drop`}
                          className="space-y-1"
                        >
                          {({ open }) => (
                            <>
                              <Disclosure.Button
                                className={
                                  "text-md flex w-full items-center justify-between rounded-md py-2 text-left font-medium text-dm-gray-600 focus:outline-none"
                                }
                              >
                                <NavLink
                                  id="link"
                                  className={({ isActive }) =>
                                    clsx(
                                      isActive
                                        ? "text-md flex w-full items-center rounded-l-md bg-dm-brand-blue-100 px-2 py-2 font-medium text-dm-gray-600"
                                        : "text-md flex w-full items-center rounded-md px-2 py-2 font-medium text-dm-gray-600 hover:underline"
                                    )
                                  }
                                  to={item.href}
                                >
                                  {item.icon && <item.icon />}
                                  <span className="ml-2 flex-1">
                                    {item.name}
                                  </span>{" "}
                                </NavLink>
                                <div
                                  className={clsx(
                                    params["*"] &&
                                      !params["*"].includes("select-scope") &&
                                      params["*"].includes(
                                        slugFromHref(item.href)
                                      )
                                      ? "rounded-r-md bg-dm-brand-blue-100"
                                      : "",
                                    "py-2 pr-1"
                                  )}
                                >
                                  <ChevronDown
                                    classes={
                                      open
                                        ? "rotate-180 transform mr-2"
                                        : "mr-2"
                                    }
                                  />
                                </div>
                              </Disclosure.Button>
                              {open && (
                                <Disclosure.Panel
                                  className="space-y-1"
                                  static
                                  key={item.name + demoModeEnabled}
                                >
                                  {item.children?.map((subItem) => (
                                    <NavLink
                                      to={subItem.href}
                                      className={({ isActive }) =>
                                        clsx(
                                          isActive
                                            ? "group flex w-full items-center rounded-md bg-dm-brand-blue-100 py-2 pl-11 text-sm font-medium text-dm-gray-600"
                                            : "group flex w-full items-center rounded-md py-2 pl-11 text-sm font-medium text-dm-gray-600 hover:underline"
                                        )
                                      }
                                      key={subItem.name + demoModeEnabled}
                                    >
                                      {demoModeEnabled &&
                                        item.name === "School Summary" &&
                                        demoString({
                                          value: subItem.name,
                                          type: "school_name",
                                          district_size: schools.length,
                                        })}
                                      {(!demoModeEnabled ||
                                        !(item.name === "School Summary")) &&
                                        subItem.name}
                                    </NavLink>
                                  ))}
                                </Disclosure.Panel>
                              )}
                            </>
                          )}
                        </Disclosure>
                      )}
                  </>
                ) : (
                  <>
                    {item.roles &&
                      item?.roles.length > 0 &&
                      item?.roles.includes(adminParams.account_type) &&
                      (!item?.integralOnly ||
                        (item?.integralOnly && hasIntegralAtAll)) && (
                        <Disclosure
                          as="div"
                          key={`${item.name}-drop`}
                          className="space-y-1"
                        >
                          {({ open }) => (
                            <>
                              <Disclosure.Button
                                className={
                                  "text-md group flex w-full items-center justify-between rounded-md py-2 pl-2 pr-1 text-left font-medium text-dm-gray-600 focus:outline-none"
                                }
                              >
                                {item.icon && <item.icon />}
                                <span className="ml-2 flex-1">
                                  {item.name}
                                </span>{" "}
                                <ChevronDown
                                  classes={
                                    open ? "rotate-180 transform mr-2" : "mr-2"
                                  }
                                />
                              </Disclosure.Button>
                              {open && (
                                <Disclosure.Panel
                                  className="space-y-1"
                                  static
                                  key={item.name}
                                >
                                  {item.children?.map((subItem) => (
                                    <NavLink
                                      to={subItem.href}
                                      className={({ isActive }) =>
                                        clsx(
                                          isActive
                                            ? "group flex w-full items-center rounded-md bg-dm-brand-blue-100 py-2 pl-11 text-sm font-medium text-dm-gray-600"
                                            : "group flex w-full items-center rounded-md py-2 pl-11 text-sm font-medium text-dm-gray-600 hover:underline"
                                        )
                                      }
                                      key={subItem.name}
                                    >
                                      {subItem.name}
                                    </NavLink>
                                  ))}
                                </Disclosure.Panel>
                              )}
                            </>
                          )}
                        </Disclosure>
                      )}
                  </>
                )
              ) : (
                <>
                  {item.roles &&
                    item.roles.length > 0 &&
                    item?.roles.includes(adminParams.account_type) &&
                    (!item?.integralOnly ||
                      (item?.integralOnly && hasIntegralAtAll)) && (
                      <div key={item.name}>
                        <NavLink
                          to={item.href}
                          className={({ isActive }) =>
                            clsx(
                              isActive
                                ? "text-md group flex w-full items-center rounded-md bg-dm-brand-blue-100 py-2 pl-2 font-medium text-dm-gray-600"
                                : "text-md group flex w-full items-center rounded-md py-2 pl-2 font-medium text-dm-gray-600 hover:underline"
                            )
                          }
                        >
                          {item.icon && <item.icon />}
                          <span className="ml-2 flex-1">{item.name}</span>
                        </NavLink>
                      </div>
                    )}
                </>
              )}
            </div>
          ))}
          {isCustomerService &&
            ["super_district", "district"].includes(
              adminParams.account_type
            ) && (
              <div>
                <Link
                  to={`${REACT_APP_ADMIN_LINK}/district/add-school`}
                  className="text-md group flex w-full cursor-pointer items-center rounded-md py-2 pl-2 font-medium text-dm-gray-600 hover:underline"
                >
                  <PlusIcon
                    className={"mr-3 h-6 w-6 flex-shrink-0 text-dm-gray-600"}
                  />
                  Add School to District
                </Link>
              </div>
            )}
          {isCustomerService && !hideDemoMode && (
            <Switch.Group as="div" className="flex items-center">
              <Switch
                checked={demoModeEnabled}
                onChange={() => {
                  setDemoModeEnabled((prev: boolean) => !prev);
                }}
                className={clsx(
                  demoModeEnabled ? "bg-dm-brand-blue-500" : "bg-gray-200",
                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                )}
              >
                <span
                  aria-hidden="true"
                  className={clsx(
                    demoModeEnabled ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
              <Switch.Label as="span" className="my-2 ml-3">
                <span className="text-md font-medium text-dm-charcoal-800">
                  Demo Mode
                </span>
              </Switch.Label>
            </Switch.Group>
          )}
        </div>

        <div
          id="bottom-items"
          className="mt-1 flex flex-col border-t bg-white pt-6"
        >
          <span
            onClick={async () => {
              const adminTeacherMissing =
                !user.isTeacherAdmin && admin && !admin.isTeacherAdmin;
              if (adminTeacherMissing) {
                const request = await axios.post(
                  deltamathAPI() + "/admin_new/manage/create_teacher_admin",
                  {},
                  {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${token}`,
                    },
                  }
                );
                if (!request?.data?.success) {
                  toastContext.addToast({
                    status: "Error",
                    message: request.data.message,
                  });
                } else {
                  window.location.href = `${REACT_APP_TEACHER_LINK}`;
                }
              } else {
                window.location.href = `${REACT_APP_TEACHER_LINK}`;
              }
            }}
            className="text-md group flex w-full items-center rounded-md py-2 pl-2 font-medium text-dm-gray-600 hover:cursor-pointer hover:underline"
          >
            <PeopleIcon classes="mr-2" />
            Teacher Application
          </span>
          {bottomNavigation.map(
            (item) =>
              item.roles &&
              item.roles.length > 0 &&
              item?.roles.includes(adminParams.account_type) &&
              (!item?.integralOnly ||
                (item?.integralOnly && hasIntegralAtAll)) && (
                <div
                  key={item.name}
                  className={clsx(
                    window.location.pathname === item.href
                      ? "text-md group flex w-full items-center rounded-md bg-dm-brand-blue-100 py-2 pl-2 font-medium text-dm-gray-600"
                      : "text-md group flex w-full items-center rounded-md py-2 pl-2 font-medium text-dm-gray-600",
                    item.secondAction ? "flex justify-between" : ""
                  )}
                >
                  <Link to={item.href} className="hover:underline">
                    {item.icon && <item.icon />}
                    <span className="ml-2 flex-1">{item.name}</span>
                  </Link>
                  {item.secondAction && (
                    <span
                      onClick={item.secondAction.action}
                      className="flex text-dm-brand-blue-500 hover:cursor-pointer hover:underline"
                    >
                      {item.secondAction.name}
                    </span>
                  )}
                </div>
              )
          )}
        </div>
      </nav>
    </>
  );
}
