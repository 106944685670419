import React, { useState } from "react";
import { LearnerPage } from "../Layouts/LearnerPage";
import { useCourseContext } from "../../contexts/CourseContext";
import Sidebar from "../Sidebar/unittest";
import ScoreBar from "../ScoreBar";
import { ResponsiveImage } from "../ResponsiveImage";
import { getFilePath } from "../../../utils";
import Button from "../../../student/components/generic/button";
import { TooltipButton } from "../../../shared/TooltipButton";
import AssignmentPreviewModal from "../Modals/AssignmentPreviewModal";
import { useLearnerContext } from "../../contexts/LearnerContext";
import {
  useCreateAssignmentMutation,
  useRetakeAssignmentMutation,
} from "../../utils";
import { useNavigate } from "react-router-dom";

type Props = {
  courseId: string;
  unitId?: string;
  subunitId?: string;
  assignmentId?: string;
};

const TestLanding = (props: Props) => {
  const courseContext = useCourseContext();
  const learnerContext = useLearnerContext();
  const createAssignmentMutation = useCreateAssignmentMutation();
  const retakeAssignmentMutation = useRetakeAssignmentMutation();
  const [showAssignmentModal, setShowAssignmentModal] =
    useState<boolean>(false);
  const navigate = useNavigate();

  const courseProgress = learnerContext.getProgress(props.courseId);
  const learnerDifficulty = learnerContext.getLearnerLevel();

  const courseData = courseContext.getCourseData(props.courseId);
  const unitData = props.unitId
    ? courseContext.getUnitData(props.unitId, props.courseId)
    : undefined;

  const estimatedTime = Math.max(
    5,
    Math.ceil(
      (unitData ? unitData.avgQuizTime || 0 : courseData?.avgQuizTime || 0) /
        60 /
        5
    ) * 5
  );

  const flatSubunitProgress =
    courseProgress?.units.flatMap((u) => u.subunits) || [];
  const skillsInUnit = (unitData?.subunits || []).flatMap((su) =>
    su.skills.filter((sk) => {
      const subunitProgress = flatSubunitProgress.find(
        (su) => su.subunitId === sk.subunitId
      );

      return (
        sk.difficulty === (subunitProgress?.difficulty || learnerDifficulty) ||
        sk.difficulty === "both"
      );
    })
  ).length;
  const skillsInCourse = (courseData?.units || []).flatMap((unit) =>
    unit?.subunits
      ?.flatMap((su) => su.skills)
      .filter((sk) => {
        const subunitProgress = flatSubunitProgress.find(
          (su) => su.subunitId === sk.subunitId
        );

        return (
          sk.difficulty ===
            (subunitProgress?.difficulty || learnerDifficulty) ||
          sk.difficulty === "both"
        );
      })
  ).length;

  const skillNumbers = props.unitId
    ? (
        courseProgress?.units?.find((u) => u.unitId === props.unitId)?.unitTest
          ?.assignment?.skills || []
      ).length || (skillsInUnit <= 20 ? skillsInUnit : 20)
    : (courseProgress?.courseTest?.assignment?.skills || []).length ||
      (skillsInCourse <= 30 ? skillsInCourse : 30);

  const unitOrderPosition =
    courseData && unitData && courseData?.unitOrder?.indexOf(unitData.id) + 1;

  const scoreBarTitle = props.unitId
    ? `Unit ${unitOrderPosition}: ${unitData?.name}`
    : `${courseData?.name}: Course Test`;

  const notSubmittingAnything = () => {
    console.log("Not submitting anything");
  };

  return (
    <LearnerPage
      title={`${props.unitId ? "Unit" : "Course"} Test`}
      noPadding
      sidebar={<Sidebar type={props.unitId ? "unitTest" : "courseTest"} />}
    >
      <ScoreBar
        pageState={props.unitId ? "unitTest" : "courseTest"}
        assignment={undefined}
        title={scoreBarTitle}
        preQuizAssignment={undefined}
        postQuizAssignment={undefined}
        skippedPreQuiz={undefined}
        preQuiz100={false}
        callback={undefined}
        finishedSubunit={false}
        testTimer={undefined}
        onSubmitAssignment={notSubmittingAnything}
      />
      <div className="flex h-full flex-grow flex-col bg-dm-background-blue-100 pb-8 text-center text-dm-charcoal-500 sm:p-6 lg:p-8">
        <div className="flex h-full flex-grow flex-col items-center justify-center rounded-lg border border-dm-charcoal-100 bg-white px-4 py-6 max-sm:m-4">
          <ResponsiveImage
            className="inline h-auto w-56"
            srcs={[
              getFilePath(
                "/images/learner/coursetest/course-test-you-sure.png"
              ),
              getFilePath(
                "/images/learner/coursetest/course-test-you-sure@2x.png"
              ),
            ]}
            alt={`Take the ${props.unitId ? "Unit" : "Course"} Test`}
            aria-hidden="true"
          />
          <div className="mx-3 mt-8 flex items-center gap-2">
            <h4 className="font-serif text-2xl font-bold">
              Ready for your {props.unitId ? "unit" : "course"} test
              {props.assignmentId ? " retake" : ""}?
            </h4>
            <TooltipButton
              message="Preview Test Questions"
              onClick={() => {
                setShowAssignmentModal(true);
              }}
            >
              <i
                className="far fa-eye text-xl text-dm-gray-200"
                aria-hidden="true"
              />
            </TooltipButton>
          </div>
          <p className="mx-3 mt-2">
            This test covers what you&apos;ve learned in each{" "}
            {props.unitId ? "section" : "unit"}. Time to show off your new
            skills!
          </p>
          <p className="mt-2 font-bold">
            {skillNumbers} Questions | {estimatedTime} Minutes (estimated)
          </p>
          <div className="flex flex-col items-center justify-center gap-y-4 pt-6">
            <Button
              onClick={async () => {
                if (props.assignmentId) {
                  await retakeAssignmentMutation(
                    props.assignmentId,
                    props.courseId,
                    props.unitId ? "unitTest" : "courseTest"
                  );
                  navigate("0");
                } else {
                  createAssignmentMutation(
                    props.unitId ? "unitTest" : "courseTest",
                    props.courseId,
                    props.unitId
                  );
                }
              }}
            >
              {props.assignmentId ? "Retake" : "Start"}{" "}
              {props.unitId ? "Unit" : "Course"} Test
            </Button>
          </div>
          <AssignmentPreviewModal
            title={`${props.unitId ? "Unit" : "Course"} Test Preview`}
            courseId={props.courseId}
            unitId={props.unitId}
            type={props.unitId ? "unitTest" : "courseTest"}
            visible={showAssignmentModal}
            toggleVisible={setShowAssignmentModal}
            navigateButtonText={`Go To ${
              props.unitId ? "Unit" : "Course"
            } Test`}
          />
        </div>
      </div>
    </LearnerPage>
  );
};

export default TestLanding;
