import { Fragment, Dispatch, SetStateAction } from "react";
import { Listbox, Transition } from "@headlessui/react";
import Chevron from "../Chevron";
import clsx from "clsx";
import { LearnerStatsKind } from "../../types";

type Props = {
  selectedType: { kind: LearnerStatsKind; name: string };
  setSelectedType: Dispatch<
    SetStateAction<{ kind: LearnerStatsKind; name: string }>
  >;
  statType: Record<string, string>[];
};

export const LearnerStatsDropdown: React.FC<Props> = ({
  selectedType,
  setSelectedType,
  statType,
}) => (
  <Listbox value={selectedType} onChange={setSelectedType} by="kind">
    {({ open }) => (
      <div className="relative w-44">
        <Listbox.Button className="relative w-full cursor-default rounded border border-dm-gray-200 bg-white py-2 pl-3 pr-7 text-left text-sm focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300">
          <span className="block truncate">{selectedType.name}</span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <Chevron open={open} />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white p-1 text-sm shadow-lg ring-1 ring-black/5 focus:outline-none">
            {statType.map((stat, statIndex) => (
              <Listbox.Option
                key={statIndex}
                className={({ active, selected }) =>
                  clsx(
                    "relative cursor-default select-none rounded-md p-2 text-dm-gray-800",
                    active ? "bg-dm-brand-blue-100" : "",
                    selected ? "font-bold" : ""
                  )
                }
                value={stat}
              >
                {stat.name}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    )}
  </Listbox>
);
