export const SectionsIcon: React.FC<{ showAria?: boolean }> = ({
  showAria = false,
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-label="sections icon"
      aria-hidden={!showAria}
    >
      <rect
        x="4.5"
        y="4.5"
        width="15"
        height="1"
        rx="0.5"
        fill="currentColor"
        stroke="currentColor"
      />
      <rect
        x="8.5"
        y="11.5"
        width="7"
        height="1"
        rx="0.5"
        fill="currentColor"
        stroke="currentColor"
      />
      <rect
        x="4.5"
        y="18.5"
        width="15"
        height="1"
        rx="0.5"
        fill="currentColor"
        stroke="currentColor"
      />
    </svg>
  );
};
