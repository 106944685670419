import { set, startOfDay } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

/** Gets the start of the day (6:00am) in the learner's timezone */
export function getStartOfDay(timezone: string): Date {
  const zonedDate = utcToZonedTime(new Date(), timezone);
  const dateAtStartOfDay = startOfDay(zonedDate);

  return set(dateAtStartOfDay, {
    hours: 6,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });
}
