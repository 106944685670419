import React, { useRef } from "react";
import ReactPlayer from "react-player";
import { useParams, useSearchParams } from "react-router-dom";
import { Disclosure } from "@headlessui/react";
import { getFilePath, useDMQuery } from "../utils";

const VideosApp = () => {
  const params = useParams();

  const { data: helpVideoData } = useDMQuery({
    path: `/public/help-video/${params.video_slug}`,
  });

  const playerRef = useRef<ReactPlayer>(null);
  const [searchParams] = useSearchParams();

  const handleDuration = (duration: number) => {
    if (
      duration &&
      searchParams &&
      searchParams.get("t") &&
      playerRef.current
    ) {
      const time = Number(searchParams.get("t"));
      if (time > 0 && time < duration) {
        playerRef.current?.seekTo(time, "seconds");
      }
    }
  };

  return (
    <div>
      <div className="min-h-screen">
        <Disclosure as="nav">
          <div className="border-dm-brand-charcoal-100 mx-auto border-b px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex flex-shrink-0 space-x-4 sm:items-center md:items-start">
                  <h2 className="px-3 py-2 text-xl font-medium text-gray-300">
                    <img
                      width="183"
                      height="27"
                      src={getFilePath("/images/DeltaMath-Logo_Blue.png")}
                      alt="DeltaMath Logo"
                    />
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </Disclosure>

        <div className="mx-auto min-h-[calc(100vh-64px)] bg-dm-background-blue-100">
          <div className="m-auto block w-9/12">
            <div className="inline-flex w-full flex-col items-start justify-start gap-8 rounded-2xl border border-neutral-50 pt-6">
              <div className="flex flex-col items-start justify-start gap-4 self-stretch">
                {!helpVideoData && (
                  <>Sorry, we had a problem loading your video.</>
                )}
                {helpVideoData && (
                  <>
                    <div className="inline-flex items-center justify-start gap-3 self-stretch">
                      <h5 className="font-['Lora'] text-3xl font-bold leading-10 text-neutral-900">
                        {helpVideoData.title}
                      </h5>
                    </div>
                    <div className="flex flex-col items-start justify-start gap-4 self-stretch">
                      <div className="font-['Lato'] text-base font-normal leading-relaxed text-neutral-900">
                        {helpVideoData.description}
                      </div>
                      <ReactPlayer
                        ref={playerRef}
                        onDuration={handleDuration}
                        url={`https://videos.deltamath.com/${helpVideoData.videoSlug}/Default/HLS/${helpVideoData.videoSlug}.m3u8`}
                        controls={true}
                        style={{
                          maxWidth: "100%",
                          width: "100%",
                        }}
                        height="100%"
                        width="1192px"
                        config={{
                          file: {
                            hlsOptions: {
                              forceHLS: true,
                            },
                            attributes: { crossOrigin: "anonymous" },
                            tracks: [
                              {
                                label: "English",
                                kind: "captions",
                                src: `https://videos.deltamath.com/captions/${helpVideoData.videoSlug}.mp4.vtt`,
                                srcLang: "en",
                                default: false,
                              },
                            ],
                          },
                        }}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideosApp;
