import { QuestionMarkCircleIcon } from "@heroicons/react/outline";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Transition } from "@headlessui/react";
import { DmLoadingSpinner } from "../../../manager/utils/functions";
import { REACT_APP_ADMIN_LINK, useDMQuery } from "../../../utils";
import DeltaMathToggle from "./DeltaMathToggle";
import {
  DisplayPerformanceGraphs,
  GraphDataInterface,
  NoDataMessage,
} from "./DisplayPerformanceGraphs";
import { ProgressError } from "./DisplayPerformanceGraphs";
import LegendDialog from "./LegendDialog";
import ScatterPlot from "./ScatterPlot";
import DmAlertModal from "../../../student/components/answerForm/DmAlertModal";
import { useUserContext } from "../../../shared/contexts/UserContext";
import { DeltaMathSelect } from "../../../shared/DeltaMathSelect";
import BackArrowIcon from "../../../shared/icons/BackArrowIcon";
import { filterOptions } from "./Index";
import { parseFilterToPreference } from "../StudentPerformanceUtils";

interface IndividualProgressParams {
  skillcodes: string[];
  series: string[];
  schoolIds: string[];
  teacherIds: string[];
  sectionIds: string[];
  start: number;
  end: number;
}

const DisplayGlobalPerformanceGraph = ({
  dataScope,
  requestParams,
  selectedStandard,
  setRequestParams,
  filterValue,
  setFilterValue,
  startDate,
  endDate,
  metaString,
  selectedYear,
}: {
  dataScope: string;
  requestParams: any;
  selectedStandard: any;
  setRequestParams: (value: any) => void;
  filterValue: string;
  setFilterValue: (value: string) => void;
  startDate: number;
  endDate: number;
  metaString?: string;
  selectedYear?: string;
}) => {
  const userContext = useUserContext();
  const token = userContext.getJWT();

  const tempPreferences = JSON.parse(
    localStorage.getItem("tempPreferences") || "{}"
  );

  //   const [haveResults, setHaveResults] = useState(false);
  const [showFluency, setShowFluency] = useState(true);
  const [progressError, setProgressError] = useState<ProgressError>();
  const [graphData, setGraphData] = useState<GraphDataInterface[]>();

  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState<JSX.Element>();
  const [specificSk, setSpecificSk] = useState<string>();
  const [individualProgressParams, setIndividualProgressParams] =
    useState<IndividualProgressParams>();

  const { data, refetch, isLoading, error } = useDMQuery({
    cacheKey: [`/admin_new/data/progressByStandards`, requestParams],
    path: `/admin_new/data/progressByStandards`,
    requestBody: requestParams,
    method: "post",
    additionalHeaders: {
      Authorization: `Bearer ${token}`,
    },
    queryOptions: {
      enabled: false,
      onError: (err: ProgressError) => setProgressError(err),
      retry: 0,
    },
  });

  // Switching to view-data and we have request params, actually make the API request
  useEffect(() => {
    if (requestParams) {
      if (
        (typeof requestParams.teacherIds !== "undefined" &&
          requestParams.teacherIds.length > 0) ||
        (typeof requestParams.sectionIds !== "undefined" &&
          requestParams.sectionIds.length > 0) ||
        (typeof requestParams.schoolIds !== "undefined" &&
          requestParams.schoolIds.length > 0)
      ) {
        refetch();
      }
    } else {
      console.log({ requestParams });
      // navigate(
      //   `${REACT_APP_ADMIN_LINK}/reports/student-performance/data-scope`,
      //   {
      //     replace: true,
      //   }
      // );
    }
  }, [requestParams]);

  useEffect(() => {
    if (data) {
      /** parse only total data */
      for (const entry in data) {
        if (entry === "data") {
          const uniqueStudents = data[entry]["uniqueStudents"];
          const newGraphData: GraphDataInterface[] = [];
          const standardMap = data[entry]["standardToData"];
          for (const standard in standardMap) {
            const thisNumStudentAssignments =
              standardMap[standard]["numStudentAssignments"];
            if (thisNumStudentAssignments > 0) {
              newGraphData.push({
                label: standard,
                data: {
                  flu: standardMap[standard]["flu"] || 0,
                  perc: standardMap[standard]["perc"] || 0,
                  problemsPerStudent:
                    thisNumStudentAssignments / uniqueStudents || 0,
                },
              });
            }
          }
          setGraphData(newGraphData);
        }
      }
    }
  }, [data]);

  const [legendOpen, setLegendOpen] = useState(false);
  const handleInfoClick = () => {
    setLegendOpen(!legendOpen);
  };

  useEffect(() => {
    if (requestParams?.skillCodesToStandards && specificSk) {
      const skillsArr = Object.keys(requestParams.skillCodesToStandards);
      const specificSkills: string[] = [];
      skillsArr.forEach((sk: string) => {
        if (
          requestParams.skillCodesToStandards[sk].indexOf(specificSk) !== -1
        ) {
          specificSkills.push(sk);
        }
      });

      const newIndiParams = {
        series: requestParams.series,
        schoolIds: requestParams.schoolIds,
        teacherIds: requestParams.teacherIds,
        sectionIds: requestParams.sectionIds,
        skillcodes: specificSkills,
        start: startDate,
        end: endDate,
      };
      setIndividualProgressParams(newIndiParams);
    }
  }, [specificSk, startDate, endDate, requestParams?.skillCodesToStandards]);

  const handleDataPointSelect = (value: any) => {
    if (Array.isArray(value) && value.length > 1) {
      setModalMessage(
        <>
          {value.map((standard: string, index: number) => (
            <button
              key={index}
              className="mb-4 inline-flex w-full justify-center rounded-md border border-transparent bg-dm-blue px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-dm-dark-blue focus:outline-none focus:ring-2 focus:ring-dm-lightest-blue focus:ring-offset-2 sm:text-sm"
              onClick={() => {
                setSpecificSk(standard);
                setShowModal(false);
              }}
            >
              {standard}
            </button>
          ))}
        </>
      );
      setShowModal(true);
    } else setSpecificSk(value[0]);
  };

  const handleChooseSk = (sk: any) => {
    setSpecificSk(sk);
  };

  /**
   * @param selectedKey key value of selected filterOption
   * updates component state based on filter selection
   */
  function handleFilterSelection(selectedKey: string) {
    /** update localStorage.tempPreferences.performanceViewPref when a new selection is made */
    localStorage.setItem(
      "tempPreferences",
      JSON.stringify({
        ...tempPreferences,
        performanceViewPref: parseFilterToPreference(selectedKey),
      })
    );
    setFilterValue(selectedKey);

    /** update requestParams value and trigger refetch for new data */
    const newFilterParams = parseFilterToPreference(selectedKey);
    setRequestParams({
      ...requestParams,
      ...newFilterParams,
    });
  }

  return (
    <>
      <div id="report-header" className="border border-b-2 bg-white px-6">
        <Link
          to={`${REACT_APP_ADMIN_LINK}/reports/student-performance/select-standard`}
          className="align-center inline-flex py-4 font-bold"
        >
          <BackArrowIcon classes="mt-1.5" />
          <span className="pl-2 text-dm-brand-blue-500">Back to Standards</span>
        </Link>
        <h2 className="font-serif text-2xl font-bold">
          Student Performance Data
        </h2>
        <div className="flex flex-row justify-between">
          <div className="flex flex-row space-x-2 py-2">
            {metaString && (
              <span className="border-r-2 pr-2">
                {metaString}
                {}{" "}
              </span>
            )}
            <span>{selectedYear}</span>
          </div>
        </div>

        <div className="flex flex-row justify-between pb-8">
          <span className="flex">
            <DeltaMathSelect
              label={""}
              defaultVal={"all"}
              options={filterOptions}
              onChangeFn={handleFilterSelection}
              value={filterValue}
            />
            <button
              className="relative z-10 ml-2 mt-2 flex flex-row text-sm"
              onClick={handleInfoClick}
            >
              <div>
                <QuestionMarkCircleIcon
                  className={
                    "group mt-2.5 h-4 w-4 flex-shrink-0 text-gray-400 hover:cursor-pointer"
                  }
                />
              </div>
              <span className="ml-1 mt-2 whitespace-nowrap">Help </span>
            </button>
          </span>
        </div>
      </div>
      <div id="report-body" className="bg-dm-background-blue p-6">
        <div className="rounded-md border bg-white p-6">
          {error && !isLoading && (
            <div className="text-red-500">{progressError?.message}</div>
          )}
          {isLoading && <DmLoadingSpinner message="Loading..." />}
          {!error &&
            !isLoading &&
            (typeof graphData === "undefined" || graphData.length === 0) && (
              <NoDataMessage />
            )}
          {graphData && graphData.length > 0 && !isLoading && (
            <>
              <Transition
                as="div"
                show={true}
                appear={true}
                enter="transition-opacity duration-1000"
                enterFrom="transform opacity-0"
                enterTo="transform opacity-100"
                leave="transition-opacity duration-1000"
                leaveFrom="transform opacity-100"
                leaveTo="transform opacity-0"
              >
                <LegendDialog
                  isOpen={legendOpen}
                  setIsOpen={setLegendOpen}
                  axisOption={showFluency ? "Fluency" : "Completion"}
                />
              </Transition>
              <div className="sm:flex sm:flex-row sm:justify-between">
                <h1 className="font-serif text-lg font-bold">
                  {" "}
                  {selectedStandard.name
                    ? selectedStandard.name
                    : selectedStandard.label}
                </h1>
                <div className="mb-2">
                  <DeltaMathToggle
                    optionA="Fluency"
                    optionB="Completion"
                    aSelected={showFluency}
                    onChangeFn={() => setShowFluency(!showFluency)}
                  />
                </div>
              </div>
              <ScatterPlot
                title={`${
                  showFluency ? "Fluency" : "Completion"
                } by Standard for Selected ${dataScope}`}
                data={graphData}
                yAxisVariable={showFluency ? "flu" : "perc"}
                onClick={handleDataPointSelect}
              />
              <DmAlertModal
                showModal={showModal}
                setShowModal={setShowModal}
                showButton={false}
                headline={"Select a specific standard."}
                message={modalMessage}
                modalAction={handleChooseSk}
              />
              {individualProgressParams && specificSk && (
                <>
                  <h1 className="mt-2 w-full border-t-2 p-2 text-center">
                    Selected Standard: <strong>{specificSk}</strong>
                  </h1>
                  <p className="pb-4 text-center text-sm text-gray-600">
                    {getDescriptionByLabel(selectedStandard, specificSk)}
                  </p>
                  <DisplayPerformanceGraphs
                    dataScope={dataScope}
                    requestParams={individualProgressParams}
                    setRequestParams={setIndividualProgressParams}
                    filterValue={filterValue}
                    setFilterValue={setFilterValue}
                    FormattedSelection={() => <div>not shown</div>}
                    isGlobal={true}
                  />
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

const getDescriptionByLabel: any = (standard: any, label: string) => {
  if (standard.module_data) {
    if (standard.label === label) {
      return standard.description;
    }
  } else if (standard.data) {
    return Object.keys(standard.data).map((id: any) =>
      getDescriptionByLabel(standard.data[id], label)
    );
  }
};

export default DisplayGlobalPerformanceGraph;
