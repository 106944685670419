import { SVGProps } from "react";

const WIDTH = 43;
const HEIGHT = 48;
const PADDING = 0.5;
const BOX_HEIGHT = 24;
const POINT_HEIGHT = (HEIGHT - BOX_HEIGHT) / 2;
const MIDPOINT = WIDTH / 2;
const VERTICES = [
  [MIDPOINT, PADDING],
  [WIDTH - PADDING - PADDING, POINT_HEIGHT],
  [WIDTH - PADDING - PADDING, BOX_HEIGHT + POINT_HEIGHT],
  [MIDPOINT, HEIGHT - PADDING],
  [PADDING, BOX_HEIGHT + POINT_HEIGHT],
  [PADDING, POINT_HEIGHT],
];

type HexagonState =
  | "initially_locked"
  | "not_available"
  | "available"
  | "filled"
  | "hint"
  | "error"
  | "selected";

function propsForState(state: HexagonState): SVGProps<SVGPathElement> {
  switch (state) {
    case "initially_locked":
      return {
        className:
          "transition-colors fill-dm-brand-blue-100 stroke-dm-charcoal-800",
        strokeDasharray: "2 2",
      };
    case "not_available":
      return {
        className: "transition-colors fill-white stroke-dm-charcoal-200",
        strokeDasharray: "2 2",
      };
    case "available":
      return {
        className: "transition-colors fill-white stroke-dm-purple-800",
        strokeDasharray: "2 2",
      };
    case "filled":
      return {
        className: "transition-colors fill-dm-purple-100 stroke-dm-purple-800",
      };
    case "hint":
      return {
        className: "transition-colors fill-dm-purple-200 stroke-dm-purple-800",
      };
    case "error":
      return {
        className: "transition-colors fill-dm-error-200 stroke-dm-error-800",
      };
    case "selected":
      return {
        className: "transition-colors fill-dm-purple-600 stroke-dm-purple-800",
      };
  }
}

export const Hexagon: React.FC<{ state: HexagonState }> = ({ state }) => (
  <svg
    width={WIDTH}
    height={HEIGHT}
    viewBox={`0 0 ${WIDTH} ${HEIGHT}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d={`M ${VERTICES.map((v) => v.join(" ")).join(" L ")} Z`}
      {...propsForState(state)}
    />
  </svg>
);
