import ProgressBar from "../../shared/ProgressBar";

export default function SideProgressBar({
  solveSkill,
}: {
  solveSkill: any;
}): JSX.Element {
  if (!solveSkill) {
    return <></>;
  }

  const isTest = !!solveSkill?.ta?.is_test;
  const grade = solveSkill?.sa?.grade;
  const complete = solveSkill?.sa?.actuallyComplete;
  const obscureResults = !!solveSkill?.ta?.obscureResults;
  const immediateTestFeedback =
    isTest && solveSkill?.ta?.student_feedback === "immediately";

  // Case 1: all modules are special, don't show progress bar
  const isAllSpecial = solveSkill?.sa?.actuallyComplete === null;

  let showGrade = false;
  let showComplete = false;
  let percent1: number | undefined;
  let percent2: number | undefined;

  // Case 2: grade is null, only show completion
  if (grade === null) {
    // show complete only
    showComplete = true;
    percent1 = complete;
  }
  // Case 3: if the module is NOT a test or a test with results that show immediately
  else if (!isTest || immediateTestFeedback) {
    // display the grade
    showGrade = true;
    percent1 = grade;
    if (grade < complete) {
      // show both the grade AND actually complete with blue and lightblue
      showComplete = true;
      percent2 = complete;
    }
  }
  // Case 4: test with obscured results
  else if (isTest && obscureResults) {
    // show complete only
    showComplete = true;
    percent1 = complete;
  }
  // Case 5: test with results shown (but NOT test with results shown immediately)
  else if (isTest && !obscureResults) {
    // show grade only
    showGrade = true;
    percent1 = grade;
  }

  return (
    <>
      {!isAllSpecial && (
        <>
          <div className="flex justify-between text-sm leading-5">
            {showGrade && (
              <h4>
                Grade: <span className="font-semibold">{grade}</span>%
              </h4>
            )}
            {showComplete && (
              <h4>
                Complete: <span className="font-semibold">{complete}</span>%
              </h4>
            )}
          </div>
          <ProgressBar
            percentCompleted={percent1}
            percentCompletedSecondary={percent2}
            solvePage={true}
            tooltipEnabled={false}
          />
        </>
      )}
    </>
  );
}
