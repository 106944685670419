import React, { AnchorHTMLAttributes } from "react";
import { REACT_APP_HOMEPAGE_LINK } from "../../../utils";
import { ContactSupport } from "../Footer/ContactSupport";
import { GiveUsFeedback } from "../Footer/GiveUsFeedback";
import { useUserContext } from "../../../shared/contexts/UserContext";
import { Learner } from "../../types";

const FooterLink: React.FC<AnchorHTMLAttributes<HTMLAnchorElement>> = ({
  children,
  ...props
}) => (
  <a
    {...props}
    target="_blank"
    className="text-dm-charcoal-800 hover:text-dm-charcoal-500"
  >
    {children}
  </a>
);

export const PageFooter: React.FC = () => {
  const userContext = useUserContext();
  const user: Learner | null = JSON.parse(
    localStorage.getItem("user") || "null"
  );
  const isLoggedIn = user?._id || userContext.getJWT().length > 0;

  return (
    <footer className="flex w-full justify-center px-6 text-xs text-dm-gray-200">
      <div className="flex w-full flex-wrap items-center justify-around gap-x-6 gap-y-2 md:w-3/4">
        <nav className="md:shrink-0">
          <ul className="flex flex-wrap items-center justify-center gap-x-6 gap-y-2">
            <li>
              <FooterLink href={`${REACT_APP_HOMEPAGE_LINK}/terms-of-service`}>
                Terms and Conditions
              </FooterLink>
            </li>
            <li>
              <FooterLink href={`${REACT_APP_HOMEPAGE_LINK}/privacy-policy`}>
                Privacy Policy
              </FooterLink>
            </li>
            {isLoggedIn && (
              <>
                <li>
                  <GiveUsFeedback />
                </li>
                <li>
                  <ContactSupport />
                </li>
              </>
            )}
          </ul>
        </nav>
        <span className="shrink-0">
          &copy; {new Date().getFullYear()} DeltaMath. All Rights Reserved.
        </span>
      </div>
    </footer>
  );
};
