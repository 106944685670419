import { SetStateAction, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "../../shared/DatePicker/index.css";
import { REACT_APP_ADMIN_LINK, useDMQuery } from "../../utils";
import DeltaMathTable from "../../shared/DeltaMathTable";
import React from "react";
import { isBefore } from "date-fns";
import axios from "axios";
import { useMutation } from "react-query";
import { deltamathAPI } from "../../manager/utils";
import { useDeltaToastContext } from "../../shared/contexts/ToasterContext";
import { Column } from "react-table";
import { getAdminParams } from "../utils";
import { QuestionMarkCircleIcon } from "@heroicons/react/outline";
import { DmLoadingSpinner } from "../../manager/utils/functions";
import { DeltaMathSearchableComboBox } from "../../shared/DeltaMathSearchableComboBox";
import clsx from "clsx";
import { DemoMode, demoString } from "../../utils/demo";
import { useUserContext } from "../../shared/contexts/UserContext";
import { Tooltip } from "../../shared/Tooltip";

export default function Index({
  schools,
  schoolsWithIntegral,
}: {
  schools: any;
  schoolsWithIntegral: any;
}) {
  const toastContext = useDeltaToastContext();
  const userContext = useUserContext();
  const adminParams = getAdminParams();
  const navigate = useNavigate();
  const token = userContext.getJWT();
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const params = useParams();
  const assignmentId = params.assignment_id || "";
  const [tableColumns, setTableColumns] = React.useState<Column[]>();
  const [schoolsCount, setSchoolsCount] = useState<number>(0);
  const [sectionOptions, setSectionOptions] = useState([]);

  const [editing] = useState(params.assignment_id ? true : false);

  const [assignmentShownDate, setAssignmentShownDate] = useState<Date>();
  const [assignmentDueDate, setAssignmentDueDate] = useState<Date>();
  const [takedownDateChecked, setTakedownDateChecked] =
    useState<boolean>(false);
  const [assignmentTakedownDate, setAssignmentTakedownDate] = useState<Date>();
  const [lateCreditChecked, setLateCreditChecked] = useState<boolean>(false);
  const [lateSubmissionDate, setLateSubmissionDate] = useState<Date>();
  const [lateCreditPercentage, setLateCreditPercentage] = useState("0");
  const [teachersCanEditDates, setTeachersCanEditDates] =
    useState<boolean>(false);
  const [dateError, setDateError] = useState(false);
  const [selectedSections, setSelectedSections] = useState<any[]>([]);
  const [pushAssignmentStatus, setPushAssignmentStatus] = useState("idle");
  const [pushedAssignmentId, setPushedAssignmentId] = useState(undefined);
  const [pushedSections, setPushedSections] = useState<Record<string, any>>({});
  const [displayTable, setDisplayTable] = useState(false);

  const [teacher, setTeacher] = useState<{
    _id?: number;
    teachercode?: string;
  }>({
    teachercode: "",
  });
  const [assignment, setAssignment] = useState<{
    is_test?: boolean;
    type?: string;
    schoolid: string;
    name: string;
    id: string;
  }>({
    schoolid: "",
    id: "",
    name: "",
  });
  const [assignmentType, setAssignmentType] = useState("");
  const [assignmentName, setAssignmentName] = useState("");
  const [teacherOptions, setTeacherOptions] = useState([]);
  const [assignmentOptions, setAssignmentOptions] = useState([]);
  const [assignmentLateSubmissionDate] = useState<Date>();

  const [pushDisabled, setPushDisabled] = useState(true);
  const [disabledMessages, setDisabledMessages] = useState([] as string[]);

  const setTheSelectedRows = (elements: any) => {
    const selectedDataIds = Object.keys(elements);
    const arrayOfSelectedSections: React.SetStateAction<any[]> = [];
    for (let i = 0; i < selectedDataIds.length; i++) {
      const arrayId = parseInt(selectedDataIds[i]);
      arrayOfSelectedSections.push(sectionOptions[arrayId]["sectionId"]);
    }
    setSelectedSections(arrayOfSelectedSections);
  };

  const { data: teacherData } = useDMQuery({
    path: "/admin_new/data/teacher",
    queryOptions: {
      staleTime: 1000 * 60 * 15,
      refetchOnWindowFocus: false,
    },
  });

  const { data: adminsTeachersData } = useDMQuery({
    path: "/admin_new/data/admins-teachers",
    params: adminParams,
    queryOptions: {
      staleTime: 1000 * 60 * 15,
      refetchOnWindowFocus: false,
    },
  });

  const { data: allSections, isLoading: sectionsLoading } = useDMQuery({
    path: "/admin_new/data/sections",
    queryOptions: {
      staleTime: 1000 * 60 * 15,
      refetchOnWindowFocus: false,
    },
  });

  const { data: pushedAssignmentData } = useDMQuery({
    path: `/admin_new/data/pushed-assignments/${assignmentId}`,
    cacheKey: ["admin_new/data/pushed-assignments", assignmentId],
    queryOptions: {
      enabled: assignmentId !== "",
    },
  });

  const {
    data: assignmentsData,
    isSuccess: assignmentsSuccess,
    isLoading: assignmentsIsLoading,
  } = useDMQuery({
    path: `/admin_new/data/assignments?teachercode=${teacher.teachercode}&for_pushed=true`,
    queryOptions: {
      staleTime: 1000 * 60 * 15,
      refetchOnWindowFocus: false,
      enabled: !!teacher._id,
    },
  });

  useEffect(() => {
    // enabled by default
    let disablePushAssignmentButton = false;
    const disableMessages = [];

    if (!assignment) {
      disableMessages.push("You must select an assignment.");
      disablePushAssignmentButton = true;
    }

    if (selectedSections.length === 0) {
      disableMessages.push("You must select one or more sections.");
      disablePushAssignmentButton = true;
    }

    if (!assignmentShownDate) {
      disableMessages.push("You must select a date to show the assignment.");
      disablePushAssignmentButton = true;
    }

    if (!assignmentDueDate) {
      disableMessages.push("You must select a due date for the assignment.");
      disablePushAssignmentButton = true;
    }
    setDisabledMessages(disableMessages);
    setPushDisabled(disablePushAssignmentButton);
  }, [assignment, selectedSections, assignmentShownDate, assignmentDueDate]);

  useEffect(() => {
    // Assignment Shown Date:
    if (pushedAssignmentData) {
      const showDate =
        pushedAssignmentData.pushAssignmentReq?.shown_date &&
        new Date(pushedAssignmentData.pushAssignmentReq?.shown_date * 1000);
      showDate && setAssignmentShownDate(showDate);
      const dueDate =
        pushedAssignmentData.pushAssignmentReq?.due_date &&
        new Date(pushedAssignmentData.pushAssignmentReq?.due_date * 1000);
      dueDate && setAssignmentDueDate(dueDate);

      // Give Late Credit
      setLateCreditChecked(
        pushedAssignmentData.pushAssignmentReq?.late_credit_checked
      );
      const lateDate =
        pushedAssignmentData.pushAssignmentReq?.late_credit_date &&
        new Date(
          pushedAssignmentData.pushAssignmentReq?.late_credit_date * 1000
        );
      lateDate && setLateSubmissionDate(lateDate);
      setLateCreditPercentage(
        pushedAssignmentData.pushAssignmentReq?.late_credit
      );

      // Assignment Takedown Date
      setTakedownDateChecked(
        pushedAssignmentData.pushAssignmentReq?.takedown_date_checked
      );
      const takedownDate =
        pushedAssignmentData.pushAssignmentReq?.takedown_date &&
        new Date(pushedAssignmentData.pushAssignmentReq?.takedown_date * 1000);
      takedownDate && setAssignmentTakedownDate(takedownDate);

      // Teachers Can Edit:
      if (pushedAssignmentData.teachers_can_edit_dates) {
        setTeachersCanEditDates(true);
      }

      // Pre-select sections in table if assignment already pushed to them
      const preSelectedSections: SetStateAction<Record<string, any>> = {};
      Array.isArray(sectionOptions) &&
        sectionOptions.length > 0 &&
        Array.isArray(pushedAssignmentData.sectionList) &&
        sectionOptions.forEach((section: any, index: number) => {
          if (pushedAssignmentData.sectionList.includes(section.sectionId)) {
            preSelectedSections[index] = true;
          }
        });
      setPushedSections(preSelectedSections);
      setDisplayTable(true);
    }
  }, [pushedAssignmentData, sectionOptions]);

  const pushAssignment = useMutation(
    (body: string) => {
      return axios.post(deltamathAPI() + "/admin_new/push-assignment", body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    },
    {
      onSuccess(data) {
        if (editing) {
          setPushedAssignmentId(data.data.assignment_id);
          toastContext.addToast({
            status: "Success",
            message: "Assignment successfully updated.",
          });
        } else {
          toastContext.addToast({
            status: "Success",
            message: "Assignment successfully pushed.",
          });
        }
        navigate(`${REACT_APP_ADMIN_LINK}/view-pushed-assignments`);
      },
      onError() {
        toastContext.addToast({
          status: "Error",
          message: "There was an error pushing your assignment.",
        });
      },
    }
  );

  useEffect(() => {
    if (teacherData) {
      let teachersResult = teacherData;

      if (adminsTeachersData) {
        const adminTeachersResult = adminsTeachersData;

        // Loop through all admin teacher accounts in reverse, so its simplet to remove a match.
        for (let i = adminTeachersResult.length - 1; i >= 0; i--) {
          const matchedTeacher = teachersResult.find(
            (t: { teachercode: string }) =>
              t.teachercode === adminTeachersResult[i].teachercode
          );
          if (matchedTeacher) {
            adminTeachersResult.splice(i, 1);
          }
        }

        if (adminTeachersResult.length > 0) {
          teachersResult = teachersResult.concat(adminTeachersResult);
        }
      }

      // Get object into the share we want for the selectbox
      for (let i = 0; i < teachersResult.length; i++) {
        teachersResult[i].name =
          teachersResult[i]["first"] + " " + teachersResult[i]["last"];
        teachersResult[i].id = teachersResult[i]["_id"];
      }

      // Find myself and get me to the top.
      const myself = teachersResult.filter(
        (t: any) => t.teachercode === user.teachercode
      );
      // Take myself out of the result
      teachersResult = teachersResult.filter(
        (t: any) => t.teachercode !== user.teachercode
      );

      // Get all integral school ids, so we can remove teachers that aren't in integral schools
      const integralSchoolIds = schoolsWithIntegral.map((s: any) => s.schoolid);

      // This seemed more readable, loop through al
      for (let i = teachersResult.length - 1; i >= 0; i--) {
        // Inpect the teacher to see if their schoolPlus array contains any of the integral school ids
        if (
          !teachersResult[i].schoolPlus.some((r: any) =>
            integralSchoolIds.includes(r)
          )
        ) {
          teachersResult.splice(i, 1); // remove them if no match
        }
      }

      // Sort by last name alphabetically
      teachersResult.sort((a: { last: string }, b: { last: string }) =>
        a.last.toLowerCase() > b.last.toLowerCase()
          ? 1
          : b.last.toLowerCase() > a.last.toLowerCase()
          ? -1
          : 0
      );

      for (let i = 0; i < teachersResult.length; i++) {
        teachersResult[i].first = demoString({
          value: teachersResult[i].first,
          type: "person_first",
        });
        teachersResult[i].last = demoString({
          value: teachersResult[i].last,
          type: "person_last",
        });
        teachersResult[i].name = demoString({
          value: teachersResult[i].name,
          type: "person_full",
        });
      }

      // Put myself first.
      const me = myself[0];
      me.name =
        demoString({
          value: `${me.first} ${me.last}`,
          type: "person_full",
        }) + " (Me)";
      teachersResult.unshift(me);

      setTeacherOptions(teachersResult);
    }
  }, [teacherData, adminsTeachersData, schoolsWithIntegral, user.teachercode]);

  useEffect(() => {
    if (allSections && schools && allSections.length > 0) {
      let allSchoolsViewable: any[] = []; // keep track of how many schools are visible, in order to hide the column if there's only one

      const mappedSections = allSections.map((section: any) => {
        const allTeachers: string[] = [];
        section.teachers.forEach((teach: { _id: number; name: string }) => {
          allTeachers.push(teach.name);
        });
        section["teach"] = allTeachers.join(", ");
        if (section.schools) {
          const showSchool = section.schools.map((schoolid: any) => {
            const results = schools.filter(function (entry: {
              schoolid: string;
            }) {
              return entry.schoolid === schoolid;
            });
            if (results.length > 0 && results[0]?.licenseTier === "integral") {
              section["schoolHasIntegral"] = true;
            }
          });
          if (!showSchool) {
            return false;
          }
          allSchoolsViewable = allSchoolsViewable.concat(section.schools); // add school to visible array
          section["school"] = section.schools
            .map((sch: any) => schools.find((x: any) => x.schoolid === sch))
            .map((sch: any) => sch.schoolName)
            .join(", ");
        }
        return section;
      });
      const onlyIntegral = mappedSections.filter(
        (section: { schoolHasIntegral: boolean }) => section.schoolHasIntegral
      );

      // Gets unique values and set the length in state
      const unique = allSchoolsViewable.filter((v, i, a) => a.indexOf(v) === i);
      setSchoolsCount(unique?.length);
      setSectionOptions(onlyIntegral);
    }
  }, [allSections, schools]);

  useEffect(() => {
    if (assignmentsData) {
      const assignments = assignmentsData;
      for (let i = 0; i < assignments.length; i++) {
        assignments[i].id = assignments[i]["_id"];
      }
      // Hide Corrections, we can't push them
      const filteredAssignments = assignments.filter(
        (assignment: { type: string }) => assignment.type !== "correction"
      );
      setAssignmentOptions(filteredAssignments);
    }
  }, [assignmentsData]);

  useEffect(() => {
    setAssignmentName(assignment.name);

    let assignmentType = "";
    if (assignment.type === "standard") {
      if (assignment.is_test) {
        assignmentType = "Test";
      } else {
        assignmentType = "Standard";
      }
    }

    setAssignmentType(assignmentType);
  }, [assignment]);

  useEffect(() => {
    let dateInputError = false;
    if (assignmentTakedownDate) {
      if (assignmentDueDate) {
        dateInputError = isBefore(assignmentTakedownDate, assignmentDueDate);
      }
      if (assignmentShownDate) {
        dateInputError = isBefore(assignmentTakedownDate, assignmentShownDate);
      }
      if (assignmentLateSubmissionDate) {
        dateInputError = isBefore(
          assignmentTakedownDate,
          assignmentLateSubmissionDate
        );
      }
    } else if (assignmentDueDate && assignmentShownDate) {
      dateInputError = isBefore(assignmentDueDate, assignmentShownDate);
    }
    setDateError(dateInputError);
  }, [
    assignmentDueDate,
    assignmentShownDate,
    assignmentTakedownDate,
    lateSubmissionDate,
    assignmentLateSubmissionDate,
  ]);

  useEffect(() => {
    const columnsDefinitionArray: Array<any> = [
      {
        Header: "Section",
        accessor: "sectionName",
        align: "left",
      },
      {
        Header: "Teacher",
        accessor: "teach",
        align: "left",
        Cell: (props: { value: string }) => {
          if (props.value.length > 25) {
            return (
              <>
                <Tooltip message={props.value}>
                  <DemoMode
                    value={`${props.value.slice(0, 25)}...`}
                    type="person_full"
                  />
                </Tooltip>
              </>
            );
          } else return <span>{props.value}</span>;
        },
      },
    ];

    if (schoolsCount > 1) {
      // only show the column if there's more than 1
      columnsDefinitionArray.push({
        Header: "School",
        accessor: "school",
        align: "left",
        Cell: (props: any) => {
          return (
            <DemoMode
              value={props.value}
              type="school_name"
              district_size={schoolsCount}
            />
          );
        },
      });
    }
    setTableColumns(columnsDefinitionArray);
  }, [schoolsCount]);

  const delay = 8; //# of seconds to display error message
  useEffect(() => {
    const timer1 = setTimeout(
      () => {
        setPushAssignmentStatus("idle");
      },
      delay * 1000 // multiply by miliseconds
    );
    return () => {
      clearTimeout(timer1);
    };
  }, [pushAssignmentStatus]);

  return (
    <>
      <div className="sticky top-0 z-50 border border-b-2 bg-white">
        <div className="flex justify-between px-6 py-8">
          <h2 className="font-serif text-2xl font-bold">
            {params.assignment_id ? (
              `Editing Assignment: ${pushedAssignmentData?.name}`
            ) : (
              <>
                Create Assignment
                <span className="align-middle text-xs font-normal">
                  <Link
                    to={`${REACT_APP_ADMIN_LINK}/help-videos`}
                    className={
                      "cursor-pointer rounded-md py-2 pb-6 pl-2 hover:underline"
                    }
                  >
                    <QuestionMarkCircleIcon
                      className={"mb-1 mr-1 h-4 w-4 text-dm-gray-200"}
                    />
                    Help
                  </Link>
                </span>
              </>
            )}
          </h2>
          <button
            type="submit"
            onClick={() => {
              setPushAssignmentStatus("loading");
              const body = {
                update: editing,
                assignment_name: assignmentName,
                ...(assignmentId && {
                  teacher_assignment_id: parseInt(assignmentId),
                }),
                ...(assignment?.id && {
                  teacher_assignment_id: parseInt(assignment.id),
                }),
                section_ids: selectedSections,
                late_credit_checked: lateCreditChecked,
                late_credit_date:
                  lateSubmissionDate && lateSubmissionDate.getTime() / 1000,
                late_credit: lateCreditPercentage,
                takedown_date_checked: takedownDateChecked,
                shown_date:
                  assignmentShownDate && assignmentShownDate?.getTime() / 1000,
                due_date:
                  assignmentDueDate && assignmentDueDate?.getTime() / 1000,
                takedown_date:
                  assignmentTakedownDate &&
                  assignmentTakedownDate?.getTime() / 1000,
                teachers_can_edit_dates: teachersCanEditDates,
              };
              pushAssignment.mutate(JSON.stringify(body));
            }}
            disabled={pushDisabled || pushAssignmentStatus === "loading"}
            className="inline-flex justify-center rounded-md border border-transparent bg-dm-brand-blue-500 px-8 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-50"
          >
            {editing ? "Update Assignment" : "Push Assignment"}
          </button>
        </div>
      </div>
      <div className="space-y-4 px-6 py-6">
        {!editing && (
          <div className="rounded-md border bg-white">
            <h3 className="flex flex-col border-b px-6 py-2 font-sans text-lg">
              <span className=""> Select an Assignment</span>{" "}
              {assignment?.id === "" && (
                <span className="text-sm text-dm-error-500">
                  You must select an assignment.
                </span>
              )}
            </h3>
            <div>
              <div className="mt-5 md:col-span-5 md:mt-0">
                <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                  {teacherOptions && teacherOptions.length > 0 && (
                    <DeltaMathSearchableComboBox
                      options={teacherOptions}
                      label={"Teacher / Admin"}
                      selectedValue={teacher}
                      setValue={setTeacher}
                    />
                  )}
                  {teacherOptions && teacherOptions.length === 0 && (
                    <p>No teachers found.</p>
                  )}

                  {assignmentsIsLoading && (
                    <DmLoadingSpinner message="Loading Assignments..." />
                  )}

                  {assignmentsSuccess &&
                    assignmentOptions &&
                    assignmentOptions.length > 0 && (
                      <DeltaMathSearchableComboBox
                        options={assignmentOptions}
                        label={"Assignment"}
                        selectedValue={assignment}
                        setValue={setAssignment}
                      />
                    )}

                  {!assignmentsIsLoading &&
                    teacher.teachercode &&
                    assignmentOptions &&
                    assignmentOptions.length === 0 && (
                      <p>No assignments found, select another teacher.</p>
                    )}

                  {assignment.id && (
                    <>
                      <div>
                        <label
                          htmlFor="assignment_name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Pushed Assignment Name
                        </label>
                        <div className="relative mt-1 flex rounded-md">
                          <input
                            type="text"
                            className="inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:ring-offset-gray-100"
                            id="assignment_name"
                            name="assignment_name"
                            value={assignmentName}
                            onChange={(e) => setAssignmentName(e.target.value)}
                          />

                          <span
                            className={clsx(
                              "ml-8 inline-flex items-center rounded-md px-2.5 py-0.5 text-sm font-medium",
                              assignmentType === "Standard" &&
                                "bg-blue-100 text-blue-800",
                              assignmentType === "Correction" &&
                                "bg-red-100 text-red-800",
                              assignmentType === "Test" &&
                                "bg-gray-100 text-gray-800"
                            )}
                          >
                            {assignmentType}
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="rounded-md border bg-white">
          <h3 className="flex flex-col border-b px-6 py-2 font-sans text-lg">
            <span className="">Set Deadline</span>{" "}
            {!assignmentShownDate && (
              <span className="text-sm text-dm-error-500">
                {"You must select a date to show the assignment."}
              </span>
            )}
            {!assignmentDueDate && (
              <span className="text-sm text-dm-error-500">
                {"You must select a due date for the assignment."}
              </span>
            )}
          </h3>
          <div className="shadow sm:rounded-md">
            <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
              <div className="grid grid-cols-3 gap-6">
                <div className="col-span-3 sm:col-span-2">
                  <label className="block text-sm font-medium text-gray-700">
                    Assignment Shown:
                  </label>
                  <div className="relative mt-1 flex rounded-md">
                    <DatePicker
                      selected={assignmentShownDate}
                      onChange={(date) => date && setAssignmentShownDate(date)}
                      selectsStart
                      nextMonthButtonLabel=">"
                      previousMonthButtonLabel="<"
                      popperClassName="react-datepicker-left"
                      timeInputLabel="Time:"
                      dateFormat="MM/dd/yyyy h:mm aa"
                      showTimeInput
                      popperPlacement="bottom"
                      popperModifiers={[
                        {
                          name: "flip",
                          options: {
                            flipVariations: false,
                            allowedAutoPlacements: ["bottom", "bottom-start"],
                            fallbackPlacements: ["bottom", "bottom-start"],
                          },
                        },
                        {
                          name: "preventOverflow",
                          enabled: false,
                        },
                        {
                          name: "hide",
                          enabled: false,
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-3 gap-6">
                <div className="col-span-3 sm:col-span-2">
                  <label className="block text-sm font-medium text-gray-700">
                    Assignment Due:
                  </label>
                  <div className="relative mt-1 flex rounded-md">
                    {/* https://reactdatepicker.com/#example-configure-popper-properties */}
                    <DatePicker
                      selected={assignmentDueDate}
                      onChange={(date) => date && setAssignmentDueDate(date)}
                      selectsStart
                      nextMonthButtonLabel=">"
                      previousMonthButtonLabel="<"
                      popperClassName="react-datepicker-left"
                      popperPlacement="bottom"
                      popperModifiers={[
                        {
                          name: "flip",
                          options: {
                            flipVariations: false,
                            allowedAutoPlacements: ["bottom", "bottom-start"],
                            fallbackPlacements: ["bottom", "bottom-start"],
                          },
                        },
                        {
                          name: "preventOverflow",
                          enabled: false,
                        },
                        {
                          name: "hide",
                          enabled: false,
                        },
                      ]}
                      timeInputLabel="Time:"
                      dateFormat="MM/dd/yyyy h:mm aa"
                      showTimeInput
                    />
                  </div>
                </div>
              </div>

              <div className="flex items-start">
                <div className="flex h-5 items-center">
                  <input
                    id="lateCreditChecked"
                    name="lateCreditChecked"
                    onChange={() => setLateCreditChecked(!lateCreditChecked)}
                    checked={lateCreditChecked}
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-dm-blue focus:ring-indigo-500"
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label
                    htmlFor="lateCreditChecked"
                    className="font-medium text-gray-700"
                  >
                    Give Late Credit
                  </label>
                </div>
              </div>

              {lateCreditChecked && (
                <div>
                  <div className="grid grid-cols-2 gap-6">
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Late Submission Date:
                      </label>
                      <div className="relative mt-1 flex rounded-md">
                        <DatePicker
                          selected={lateSubmissionDate}
                          onChange={(date) =>
                            date && setLateSubmissionDate(date)
                          }
                          selectsStart
                          nextMonthButtonLabel=">"
                          previousMonthButtonLabel="<"
                          popperClassName="react-datepicker-left"
                          timeInputLabel="Time:"
                          dateFormat="MM/dd/yyyy h:mm aa"
                          showTimeInput
                          popperPlacement="bottom"
                          popperModifiers={[
                            {
                              name: "flip",
                              options: {
                                flipVariations: false,
                                allowedAutoPlacements: [
                                  "bottom",
                                  "bottom-start",
                                ],
                                fallbackPlacements: ["bottom", "bottom-start"],
                              },
                            },
                            {
                              name: "preventOverflow",
                              enabled: false,
                            },
                            {
                              name: "hide",
                              enabled: false,
                            },
                          ]}
                        />
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor="lateCreditPercentage"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Late Credit Percentage:
                      </label>
                      <div className="relative mt-1 flex rounded-md">
                        <input
                          type="number"
                          className="inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:ring-offset-gray-100"
                          id="lateCreditPercentage"
                          name="lateCreditPercentage"
                          value={lateCreditPercentage}
                          onChange={(e) =>
                            setLateCreditPercentage(e.target.value)
                          }
                        />
                        <span className="-ml-14 mt-2">%</span>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="flex items-start">
                <div className="flex h-5 items-center">
                  <input
                    id="takedownDateChecked"
                    name="takedownDateChecked"
                    onChange={() =>
                      setTakedownDateChecked(!takedownDateChecked)
                    }
                    checked={takedownDateChecked}
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-dm-blue focus:ring-indigo-500"
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label
                    htmlFor="assignmentTakedownDate"
                    className="font-medium text-gray-700"
                  >
                    Assignment Takedown Date
                  </label>
                </div>
              </div>

              {takedownDateChecked && (
                <div className="grid grid-cols-3 gap-6">
                  <div className="col-span-3 sm:col-span-2">
                    <label className="block text-sm font-medium text-gray-700">
                      Assignment Takedown Date:
                    </label>
                    <div className="relative mt-1 flex rounded-md">
                      <DatePicker
                        selected={assignmentTakedownDate}
                        onChange={(date) =>
                          date && setAssignmentTakedownDate(date)
                        }
                        selectsStart
                        nextMonthButtonLabel=">"
                        previousMonthButtonLabel="<"
                        popperClassName="react-datepicker-left"
                        timeInputLabel="Time:"
                        dateFormat="MM/dd/yyyy h:mm aa"
                        showTimeInput
                        popperPlacement="bottom"
                        popperModifiers={[
                          {
                            name: "flip",
                            options: {
                              flipVariations: false,
                              allowedAutoPlacements: ["bottom", "bottom-start"],
                              fallbackPlacements: ["bottom", "bottom-start"],
                            },
                          },
                          {
                            name: "preventOverflow",
                            enabled: false,
                          },
                          {
                            name: "hide",
                            enabled: false,
                          },
                        ]}
                      />
                    </div>
                  </div>
                </div>
              )}

              <div className="flex items-start">
                <div className="flex h-5 items-center">
                  <input
                    id="teachersCanEditDates"
                    name="teachersCanEditDates"
                    onChange={() =>
                      setTeachersCanEditDates(!teachersCanEditDates)
                    }
                    checked={teachersCanEditDates}
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-dm-blue focus:ring-indigo-500"
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label
                    htmlFor="teachersCanEditDates"
                    className="font-medium text-gray-700"
                  >
                    Teachers Can Edit Dates
                  </label>
                  <p className="text-gray-500">
                    If checked, teachers will be able to edit the above dates
                    for their classes.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="rounded-md border bg-white">
          <h3 className="z-10 flex flex-col border-b px-6 py-2 font-sans text-lg">
            <span className="">Set Section</span>{" "}
            <span className="text-xs">Please select one or more sections.</span>
            {!selectedSections?.length && (
              <span className="text-sm text-dm-error-500">
                You must select one or more sections.
              </span>
            )}
          </h3>
          <div className="h-200 -z-10 -mt-0.5 flex flex-col space-y-6 bg-white">
            {/* <label className="block px-6 text-sm font-medium text-gray-700">
                  Selected Sections: {selectedSections.length}
                </label> */}

            {sectionsLoading && <>Loading...</>}

            {editing
              ? sectionOptions &&
                pushedAssignmentData &&
                displayTable &&
                tableColumns && (
                  <DeltaMathTable
                    columns={tableColumns}
                    data={sectionOptions}
                    options={{
                      selectable: true,
                      preSelectedRows: pushedSections,
                      globalFilter: true,
                    }}
                    setSelectedRows={setTheSelectedRows}
                  />
                )
              : sectionOptions &&
                tableColumns && (
                  <DeltaMathTable
                    columns={tableColumns}
                    data={sectionOptions}
                    options={{ selectable: true, globalFilter: true }}
                    setSelectedRows={setTheSelectedRows}
                  />
                )}
          </div>
          {/* <div className="bg-gray-50 px-4 py-3 sm:px-6">
                {disabledMessages && disabledMessages.length > 0 && (
                  <>
                    <ul className="ml-8 list-disc">
                      {disabledMessages.map((m) => (
                        <li
                          key={m}
                          className="text-md mt-2 font-bold text-red-500"
                        >
                          {m}
                        </li>
                      ))}
                    </ul>
                  </>
                )}
              </div> */}

          {pushedAssignmentId && (
            <div>
              Only you can now{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href={`/teacher/data/assignment/${pushedAssignmentId}`}
              >
                edit or delete the assignment
              </a>{" "}
              in your teacher account.
            </div>
          )}
        </div>
      </div>
    </>
  );
}
