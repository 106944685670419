import { DashboardCard } from "../Dashboard/DashboardCard";
import { CourseProgressCard } from "./CourseProgressCard";
import { LearnerStats } from "./LearnerStats";
import { RecentActivity } from "./RecentActivity";
import { LaunchLearnerCard } from "./LaunchLearnerCard";
import { QuestionMarkCircleIcon, ClockIcon } from "@heroicons/react/outline";
import { TotalPointsCard } from "./TotalPointsCard";
import { LearningStreakCard } from "../Dashboard/LearningStreakCard";
import { format } from "date-fns/esm";
import { secondsToDuration } from "../../utils/secondsToDuration";
import { Learner } from "../../types";
import TourCardWrapper from "../Tour/TourCardWrapper";

export const ParentDashboard: React.FC<{
  currentLearner: Learner;
  header: React.ReactElement;
}> = ({ currentLearner, header }) => {
  const updatedAtDate =
    currentLearner.createdAt &&
    format(new Date(currentLearner.createdAt), "MMM d, yyyy");
  const lastLoginDate =
    currentLearner.last_login &&
    format(new Date(currentLearner.last_login), "MMM d, h:mm aaa");

  return (
    <>
      {header}
      <div className="grid grid-cols-1 gap-4 lg:grid-cols-3">
        <TourCardWrapper step={1} tourType="parent">
          <CourseProgressCard />
        </TourCardWrapper>
        <TourCardWrapper
          step={2}
          tourType="parent"
          classNames="col-span-1 grid grid-cols-1 gap-4 lg:col-span-2 lg:grid-cols-2"
        >
          <TotalPointsCard />
          <LearningStreakCard
            streaks={currentLearner.streaks}
            longestStreak={currentLearner.longest_streak}
            isParent
          />
        </TourCardWrapper>
        <TourCardWrapper
          step={3}
          tourType="parent"
          classNames="col-span-1 grid grid-cols-1 gap-4 lg:col-span-3 lg:grid-cols-3"
        >
          <DashboardCard
            size="flex"
            icon={<QuestionMarkCircleIcon />}
            title="Questions Answered"
          >
            <h3 className="mt-4 font-serif text-2xl font-bold">
              {currentLearner.solved === 1
                ? "1 question"
                : (currentLearner.solved || 0) + " questions"}
            </h3>
            <p className="text-sm text-dm-gray-500">Since {updatedAtDate}</p>
          </DashboardCard>
          <DashboardCard
            size="flex"
            icon={<QuestionMarkCircleIcon />}
            title="Questions Answered Correctly"
          >
            <h3 className="mt-4 font-serif text-2xl font-bold">
              {currentLearner.solved_correct === 1
                ? "1 question"
                : (currentLearner.solved_correct || 0) + " questions"}
            </h3>
            <p className="text-sm text-dm-gray-500">Since {updatedAtDate}</p>
          </DashboardCard>
          <DashboardCard
            size="flex"
            icon={<ClockIcon />}
            title="Total Time Spent"
          >
            <h3 className="mt-4 font-serif text-2xl font-bold">
              {currentLearner.total_app_time
                ? secondsToDuration(currentLearner.total_app_time)
                : "0 mins"}
            </h3>
            <p className="text-sm text-dm-gray-500">
              Last logged in: {lastLoginDate}
            </p>
          </DashboardCard>
        </TourCardWrapper>
      </div>
      <div className="mt-4 grid grid-cols-1 gap-4 lg:grid-cols-2">
        <TourCardWrapper step={4} tourType="parent">
          <LearnerStats showEmptyState={false} />
        </TourCardWrapper>
        <TourCardWrapper step={5} tourType="parent">
          <RecentActivity currentLearnerId={currentLearner._id} />
        </TourCardWrapper>
      </div>
      <TourCardWrapper step={6} tourType="parent" classNames="lg:col-span-2">
        <LaunchLearnerCard className="mt-4" showEmptyState={false} />
      </TourCardWrapper>
    </>
  );
};
