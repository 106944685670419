import { useEffect, useRef, forwardRef } from "react";
import { dmMqConfig } from "../utils";
const MQ = (window as any).MQ;

type MQProps = {
  setShowKeyboard?: any;
  setShowCalculator?: any;
  latex?: string;
  onChange?: (mq: any) => void;
  handleSubmit?: () => void;
  handleFocus?: (mq: any) => void;
  config?: any; // TS TODO
  mathquillDidMount?: (mq: any) => void;
  id?: string;
  ariaLabel?: string;
  cssText?: string;
  isCalc?: boolean;
};

export const MQReact = forwardRef((props: MQProps, forwardMathRef: any) => {
  const wrapperElement = useRef<HTMLSpanElement>(null);
  const mqLatex = useRef<string>("");
  useMQConfig(props, wrapperElement, mqLatex, forwardMathRef);

  return (
    <>
      <span className="bg-white" ref={wrapperElement} id={props.id}></span>
    </>
  );
});

MQReact.displayName = "MQReact";

/* ************ */
/* Custom Hooks */
/* ************ */

/* Custom Hook to configure MQ */
function useMQConfig(
  {
    setShowKeyboard,
    setShowCalculator,
    latex,
    onChange,
    handleSubmit,
    handleFocus,
    config,
    mathquillDidMount,
    id,
    cssText,
    ariaLabel,
    isCalc = false,
  }: MQProps,
  wrapperElement: React.RefObject<HTMLSpanElement>,
  mqLatex: React.MutableRefObject<string>,
  forwardMathRef?: any
) {
  useEffect(() => {
    if (!wrapperElement.current) return;

    const wrapperRef = wrapperElement.current;

    /* Determine if user is on a touch device */
    const isTouchDevice: boolean = (window as any).is_touch_device();

    /* Set MQ global configuration, based on user device */
    MQ.config(dmMqConfig(config));

    /* Create mathquill field */
    const mq = MQ.MathField(wrapperRef);

    /* Configure handlers for individual mathquill fields */
    mq.config(
      generateHandlers({
        config,
        onChange,
        handleSubmit,
        mqLatex,
        isCalc,
      })
    );

    /* Set latex */
    if (latex) {
      mq.latex(latex);
      mqLatex.current = latex;
    }

    /* Set aria label */
    if (ariaLabel) mq.setAriaLabel(ariaLabel);

    /* Define 'focusin' event listener */
    let focusInFunc: () => void;
    let textareaEl: Element;
    if (handleFocus || isTouchDevice) {
      focusInFunc = () => {
        if (isTouchDevice && !isCalc) {
          if (setShowCalculator) setShowCalculator(false);
          if (setShowKeyboard) setShowKeyboard(true);
        }
        if (handleFocus) handleFocus(id);
      };
      textareaEl = wrapperRef.querySelectorAll("textarea")[0];
      textareaEl?.addEventListener("focusin", focusInFunc);
    }

    /* Add click event to more easily focus MathQuill inputs on touchscreen */
    const clickAndFocus = () => {
      mq.focus();
    };
    if (isTouchDevice) {
      wrapperRef.addEventListener("click", clickAndFocus);
    }

    /* Invoke mathquillDidMount func */
    if (mathquillDidMount) mathquillDidMount(mq);

    /* Invoke ref callback passing in MQ object as the node */
    if (forwardMathRef) forwardMathRef(mq);

    /* Set styles, if necessary */
    if (cssText) wrapperRef.style.cssText = cssText;

    return () => {
      if (forwardMathRef) forwardMathRef(null); // invoke ref callback to nullify unmounted MQ
      if (handleFocus || isTouchDevice) {
        textareaEl?.removeEventListener("focusin", focusInFunc);
      }
      if (isTouchDevice) {
        wrapperRef?.removeEventListener("click", clickAndFocus);
      }
    };
  }, [onChange]);
}

/* **************** */
/* Helper Functions */
/* **************** */

/* Function to generate handlers */
const generateHandlers = ({
  config,
  onChange,
  handleSubmit,
  mqLatex,
  isCalc = false,
}: {
  config: any;
  onChange?: (mq: any) => void;
  handleSubmit?: () => void;
  mqLatex: React.MutableRefObject<string>;
  isCalc: boolean;
}): Record<string, any> => {
  const combinedConfig: Record<string, any> = {};

  combinedConfig.handlers = {
    edit: (mq: any) => {
      // invoke any edit handlers provided in props
      if (config?.handlers?.edit) config.handlers.edit(mq);
      if (onChange) onChange(mq);
      // restrict to 7 decimal spaces
      if (isCalc === false) {
        preventLongDecimals(mq, mqLatex);
      }
    },
    enter: (mq: any) => {
      // invoke any enter handlers provided in props
      if (config?.handlers?.enter) config.handlers.enter(mq);
      if (handleSubmit) handleSubmit();
    },
    ...(config?.handlers?.reflow ? { reflow: config.handlers.reflow } : {}),
  };

  return combinedConfig;
};

/* Function to prevent long decimals */
const preventLongDecimals = (
  mq: any,
  mqLatex: React.MutableRefObject<string>
): void => {
  const latex = mq.latex();
  if (/\.\d{8,}/.test(latex)) mq.latex(mqLatex.current);
  else if (/0{8,}/.test(latex)) mq.latex(mqLatex.current);
  else mqLatex.current = latex;
};
