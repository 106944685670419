import { useLayoutEffect, useRef, useState } from "react";
import { Problem } from "../../types";
import clsx from "clsx";
import { displayInlineMath, resizeKatexLine } from "../../../student/utils";
import renderMathInElement from "../../../student/utils/auto-render";
import { SubmitAnswerButton } from "./SubmitAnswerButton";

type Props = {
  problem: Problem;
  problemData: any;
  handleSubmit: (answer: string[] | object) => void;
  isCheckAnswerLoading: boolean;
};

const MultipleChoice = (props: Props) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [computedChoicesPerLine, setComputedChoicesPerLine] = useState<number>(
    props.problemData.choicesPerLine ? props.problemData.choicesPerLine : 2
  );
  const [answer, setAnswer] = useState<string[]>([""]);
  const initialLoad = useRef<boolean>(true);
  const katexRefs = useRef<Map<string, any> | null>(null);

  const refCallback = (node: any, i: number) => {
    if (!katexRefs.current) katexRefs.current = new Map();
    const map = katexRefs.current;
    if (node) {
      map.set(String(i), node);
    } else {
      map.delete(String(i));
    }
  };

  const handleInitialResize = () => {
    const elementMap = katexRefs.current ? katexRefs.current : new Map();

    // determine if each choice should be on one line
    const initialResizeNotNeeded =
      computedChoicesPerLine !== 1 &&
      Array.from(elementMap).some((value: any) => {
        const el = value[1];
        const katexEl = el.querySelector(".katex");
        if (!el || !katexEl) return false;

        el.classList.add("overflow-hidden");

        const origWidth = katexEl.getBoundingClientRect().width;
        const elWidth = el.clientWidth - 28; // room for padding

        el.classList.remove("overflow-hidden");

        return origWidth > elWidth;
      });

    if (initialResizeNotNeeded) setComputedChoicesPerLine(1);
    else handleResize();
  };

  const handleResize = () => {
    const elementMap = katexRefs.current ? katexRefs.current : new Map();
    elementMap.forEach((value: any) => {
      resizeKatexLine(value);
    });
  };

  /**
   * If the computedChoicesPerLine was updated to be 1, due to space restrictions, handleResize should recompute with the new dimensions
   * Note: handleResize should only execute if it is not the initial load AND the computedChoicesPerLine was updated to be 1
   * */
  useLayoutEffect(() => {
    if (computedChoicesPerLine === 1 && !initialLoad.current) {
      handleResize();
    }
  }, [computedChoicesPerLine]);

  /* to render math in element if choices are in html format and resize, if necessary */
  useLayoutEffect(() => {
    if (
      containerRef.current &&
      props.problem.choices &&
      props.problem.choices.length > 0 &&
      props.problem.choices[0][0] === "<" &&
      renderMathInElement
    ) {
      renderMathInElement(containerRef.current);
    }
    const resizeNeeded = containerRef.current?.querySelector(".katex") !== null;
    if (resizeNeeded) {
      handleInitialResize();
      window.addEventListener("resize", handleResize);
    }
    initialLoad.current = false;
    return () => {
      if (resizeNeeded) window.removeEventListener("resize", handleResize);
    };
  }, []);

  const containerStyle = clsx(
    "basis-3/4 grow grid gap-4 grid-cols-1 pr-4",
    "sm:grid-cols-" + computedChoicesPerLine
  );

  const submit = () => {
    props.handleSubmit(answer);
  };

  return (
    <form
      onSubmit={(e) => {
        // allow for enter to submit the multiple choice answer
        e.preventDefault();
        submit();
      }}
    >
      <div ref={containerRef} className={"flex flex-row flex-wrap gap-y-2"}>
        <fieldset className={containerStyle}>
          <legend className="sr-only">Multiple Choice Answers</legend>
          {props.problem.choices?.map((choice, index) => {
            const setInnerHTML = choice[0] === "<" ? true : false;
            return (
              <div
                key={choice + index}
                className="flex items-center text-lg"
                ref={(node: any) => refCallback(node, index)}
              >
                <input
                  id={choice + index}
                  name="answer-choices"
                  type="radio"
                  value={String(index)}
                  className="h-4 w-4 cursor-pointer border-gray-400"
                  onChange={(e) => setAnswer([e.currentTarget.value])}
                  onKeyUp={(e) => {
                    //   if (e.key === "Enter") setShowAnswerPreview(true);
                  }}
                />
                {setInnerHTML ? (
                  <label
                    htmlFor={choice + index}
                    dangerouslySetInnerHTML={{ __html: choice }}
                    className="ml-3 block cursor-pointer font-medium"
                  ></label>
                ) : (
                  <label
                    htmlFor={choice + index}
                    className="ml-3 block cursor-pointer font-medium"
                  >
                    {props.problemData.inlineChoices === false
                      ? displayInlineMath(
                          choice,
                          !props.problemData.inlineChoices
                        )
                      : displayInlineMath(choice)}
                  </label>
                )}
              </div>
            );
          })}
        </fieldset>
      </div>
      <SubmitAnswerButton
        submit={submit}
        isCheckAnswerLoading={props.isCheckAnswerLoading}
        className="mt-6"
      />
    </form>
  );
};

export default MultipleChoice;
