import { Progress, Course } from "../../types";
import IconAssignmentProgress from "../IconAssignmentProgress";

type Props = {
  courseData: Course;
  progress: Progress | undefined;
  estimatedTime: number;
  isFirstAttemptComplete: boolean;
};

const CourseProgressTestCard = (props: Props) => {
  const progress =
    (!!props.progress?.courseTest && props.progress?.courseTest?.progress === 0
      ? 0.01
      : props.progress?.courseTest?.progress || 0) * 100;
  const maxGrade = (props.progress?.courseTest?.maxGrade || 0) * 100;

  const skillsInCourse = (props.courseData?.units || []).flatMap((unit) =>
    unit?.subunits?.flatMap((su) => su.skills)
  ).length;

  const skillNumbers =
    (props.progress?.courseTest?.assignment?.skills || []).length ||
    (skillsInCourse <= 30 ? skillsInCourse : 30);
  const skillsCompleted = (
    props.progress?.courseTest?.assignment?.skills || []
  ).filter((x) => x.skillComplete).length;

  const testAttemptNumber =
    (props.progress?.courseTest?.assignment?.resultHist?.length || 0) + 1;

  const currDuration = props.progress?.courseTest?.assignment?.duration;
  const bestScoreTime = !props.isFirstAttemptComplete
    ? currDuration
    : props.progress?.courseTest?.assignment?.resultHist
        ?.filter(
          (attempt) =>
            attempt.duration !== undefined &&
            attempt.grade === props.progress?.courseTest?.maxGrade
        )
        .reduce(
          (acc, curr) =>
            acc < (curr.duration ?? 0) ? acc : curr.duration ?? 0,
          0
        ) || currDuration;

  const duration = bestScoreTime
    ? Math.max(1, Math.round(bestScoreTime / 60))
    : undefined;

  return (
    <>
      <hr className="mb-6 flex-grow border-t border-dm-charcoal-100" />
      <div
        id={`panel-course-test-${props.courseData.id}`}
        className="w-full bg-white"
      >
        <div className="flex basis-full gap-2">
          <IconAssignmentProgress
            type={"courseTest"}
            progress={progress}
            submitted={props.progress?.courseTest?.submitted !== undefined}
            maxGrade={maxGrade}
          />
          <div>
            <h3 className="text-lg font-bold">{props.courseData.name} Test</h3>
            <div className="mt-4 flex flex-col gap-y-1 text-sm leading-normal">
              {props.isFirstAttemptComplete ? (
                <div className="flex gap-2">
                  <p>
                    Score:{" "}
                    <strong>
                      {maxGrade}%{testAttemptNumber > 1 ? ` (Best)` : ""}
                    </strong>
                  </p>
                  {" | "}
                  <p>
                    {`${duration} ${
                      duration !== 1 ? "Minutes" : "Minute"
                    } Spent`}
                  </p>
                </div>
              ) : null}
              {!props.progress?.courseTest ? (
                <div className="flex gap-2">
                  <p>{skillNumbers} Questions</p>
                  {" | "}
                  <p>{props.estimatedTime} Minutes (estimated)</p>
                </div>
              ) : !props.progress?.courseTest?.submitted ? (
                <p>
                  {skillsCompleted}/{skillNumbers} Questions Answered{" "}
                  {testAttemptNumber > 1 ? (
                    <strong>(Attempt {testAttemptNumber})</strong>
                  ) : null}
                </p>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CourseProgressTestCard;
