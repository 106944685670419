import { NavLink, useMatch } from "react-router-dom";
import { useBoolean } from "usehooks-ts";
import { StarsIcon } from "../../../shared/icons/StarsIcon";
import { useLearnerContext } from "../../contexts/LearnerContext";
import { DashboardCard } from "./DashboardCard";
import { SkeletonText } from "../Skeleton";
import Button from "../../../student/components/generic/button";
import HowPointsWorkModal from "../Modals/HowPointsWorkModal";
import { REACT_APP_LEARNER_LINK } from "../../../utils";

export const TotalPointsCard: React.FC = () => {
  const { learner } = useLearnerContext();
  const isOnPointsPage = useMatch(`${REACT_APP_LEARNER_LINK}/points`);
  const { value: isShowingModal, toggle: toggleIsShowingModal } =
    useBoolean(false);

  const dateFormatted = new Date().toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });

  return (
    <DashboardCard title="Total Points" icon={<StarsIcon />}>
      <h4 className="mb-2 mt-4 font-serif text-3xl font-bold">
        <SkeletonText loaded={!!learner}>
          {learner?.pointsEarned ?? "??"} pts
        </SkeletonText>
      </h4>
      <p className="mb-1 text-xs text-gray-500">
        Total points earned as of {dateFormatted}
      </p>
      {!isOnPointsPage ? (
        <NavLink
          to={`${REACT_APP_LEARNER_LINK}/points`}
          className="text-sm text-dm-brand-blue-500 hover:text-dm-brand-blue-600"
        >
          View points
        </NavLink>
      ) : (
        <>
          <Button
            type="link"
            className="text-dm-blue-500 hover:text-dm-blue-700 cursor-pointer text-sm hover:underline"
            onClick={() => toggleIsShowingModal()}
          >
            Learn How Points Work
          </Button>
          <HowPointsWorkModal
            visible={isShowingModal}
            onClose={toggleIsShowingModal}
          />
        </>
      )}
    </DashboardCard>
  );
};
