import clsx from "clsx";
import { HTMLAttributes } from "react";
import { LearnerAccountStatus } from "../../types";

export const SubscriptionStatusPill: React.FC<
  HTMLAttributes<HTMLSpanElement> & {
    status: LearnerAccountStatus;
  }
> = ({ status, className, ...props }) => {
  return (
    <span
      className={clsx(
        "inline-flex h-[22px] items-center rounded-full px-2 text-xs",
        status === "active"
          ? "bg-dm-success-500 text-white"
          : status === "pending"
          ? "bg-dm-warning-500 text-dm-charcoal-600"
          : "bg-dm-gray-500 text-white",
        className
      )}
      {...props}
    >
      {status === "active"
        ? "Active"
        : status === "pending"
        ? "Pending"
        : status === "canceled"
        ? "Canceled"
        : "Inactive"}
    </span>
  );
};
