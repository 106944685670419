import React, { Fragment, useMemo } from "react";
import { useDMQuery } from "../../../utils";
import { Dialog, Transition } from "@headlessui/react";
import DeltaMathTable from "../../../shared/DeltaMathTable";

interface StudentClassesProps {
  id: string;
  name: string;
}

interface ISection {
  name: string;
  teacherInfo: Array<{
    first: string;
    last: string;
    email: string;
    teachercode: string;
  }>;
}

const StudentClasses: React.FC<StudentClassesProps> = ({ id, name }) => {
  const [open, setOpen] = React.useState(false);

  const { data: sectionData, isLoading } = useDMQuery({
    path: `/manager_new/manage/student/${id}`,
    method: "get",
    cacheKey: ["student-sections", id],
    queryOptions: {
      enabled: open,
    },
  });

  const columns = [
    {
      Header: "Section Name",
      accessor: "name",
      align: "left",
    },
    {
      Header: "Teacher's Name",
      accessor: (row: any) => row.first + " " + row.last,
      align: "left",
    },
    {
      Header: "Teacher's Email",
      accessor: "email",
      align: "left",
    },
    {
      Header: "Teacher Code",
      accessor: "teachercode",
      align: "left",
    },
    {
      Header: "Term",
      accessor: "term",
      align: "left",
      Cell: ({ value }: { value: string }) => {
        return value === "current" ? "Current" : "Archived";
      },
    },
  ];

  const handleOpen = (e: any) => {
    e.preventDefault();
    setOpen(true);
  };

  return (
    <Fragment>
      <button
        onClick={handleOpen}
        className="text-indigo-600 hover:text-indigo-900"
      >
        See Student Classes
      </button>
      <Transition appear show={open} as={Fragment}>
        <Dialog
          id={`classes-${id}`}
          open={open}
          onClose={() => setOpen(false)}
          as="div"
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 z-[100] bg-black/30" />
          </Transition.Child>
          <div className="fixed inset-0 z-[100] overflow-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-[1000px] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title className="my-2 text-xl font-bold">
                    {`Sections for ${name}`}
                  </Dialog.Title>
                  {isLoading && <div>Loading...</div>}
                  {!isLoading && (!sectionData || sectionData.length === 0) && (
                    <div className="my-2">No sections found</div>
                  )}
                  {!isLoading && sectionData?.length > 0 && (
                    <DeltaMathTable columns={columns} data={sectionData} />
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </Fragment>
  );
};

export default StudentClasses;
