export const GridSquareIcon: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.71875 9.11111V6H8.82986V9.11111H5.71875ZM3.71875 5C3.71875 4.44771 4.16647 4 4.71875 4H9.82986C10.3821 4 10.8299 4.44772 10.8299 5V10.1111C10.8299 10.6634 10.3821 11.1111 9.82986 11.1111H4.71875C4.16646 11.1111 3.71875 10.6634 3.71875 10.1111V5ZM14.6075 9.11111V6H17.7186V9.11111H14.6075ZM12.6075 5C12.6075 4.44771 13.0553 4 13.6075 4H18.7186C19.2709 4 19.7186 4.44772 19.7186 5V10.1111C19.7186 10.6634 19.2709 11.1111 18.7186 11.1111H13.6075C13.0553 11.1111 12.6075 10.6634 12.6075 10.1111V5ZM14.6075 14.8888V17.9999H17.7186V14.8888H14.6075ZM13.6075 12.8888C13.0553 12.8888 12.6075 13.3365 12.6075 13.8888V18.9999C12.6075 19.5522 13.0553 19.9999 13.6075 19.9999H18.7186C19.2709 19.9999 19.7186 19.5522 19.7186 18.9999V13.8888C19.7186 13.3365 19.2709 12.8888 18.7186 12.8888H13.6075ZM5.71875 17.9999V14.8888H8.82986V17.9999H5.71875ZM3.71875 13.8888C3.71875 13.3365 4.16647 12.8888 4.71875 12.8888H9.82986C10.3821 12.8888 10.8299 13.3365 10.8299 13.8888V18.9999C10.8299 19.5522 10.3821 19.9999 9.82986 19.9999H4.71875C4.16646 19.9999 3.71875 19.5522 3.71875 18.9999V13.8888Z"
      fill="currentColor"
    />
  </svg>
);
