import React from "react";
import { ResponsiveImage } from "../ResponsiveImage";
import {
  REACT_APP_HOMEPAGE_LINK,
  REACT_APP_LEARNER_LINK,
  getFilePath,
} from "../../../utils";
import Button from "../../../student/components/generic/button";

type Props = {
  title: string;
  body: string | React.ReactNode;
};

const ErrorState = (props: Props) => {
  //TODO: ONCE IMAGES ARE SETTLED REPLACE WITH SAD DELTIE IMAGE
  return (
    <div className="flex max-w-xl flex-col items-center text-center">
      <ResponsiveImage
        className="mb-4 max-w-40 object-contain"
        srcs={[
          getFilePath("/images/learner/signup/deltie-sad.png"),
          getFilePath("/images/learner/signup/deltie-sad@2x.png"),
        ]}
        alt="Deltie smiling and giving a thumbs up"
      />
      <h1 className="text-2xl font-bold leading-[48px] text-dm-brand-blue-600">
        {props.title}
      </h1>
      <p className="text-md mt-2 text-dm-brand-blue-600">{props.body}</p>
      <p className="text-md mt-2 text-dm-brand-blue-600">
        Meant to log in?{" "}
        <Button
          type="link"
          href={`${REACT_APP_HOMEPAGE_LINK}${REACT_APP_LEARNER_LINK}/sign-in`}
          className="text-dm-brand-blue-600 underline hover:text-dm-brand-blue-800"
        >
          Log in here
        </Button>
      </p>
    </div>
  );
};

export default ErrorState;
