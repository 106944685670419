import { PascalsTriangleRow } from "../types";

type CellValueTuple = [number | null, number];

/**
 * Function to generate Pascal's Triangle up to the provided row
 * @returns A 2D array of tuples where the first element is the learner's value
 *    and the second element is the expected value, e.g. a completed cell might
 *    contain [10, 10] while an incomplete cell would be [null, 10]
 */
export const generatePascalsTriangle = (
  nthRow: PascalsTriangleRow
): CellValueTuple[][] => {
  const rows: CellValueTuple[][] = [];
  for (let lineIndex = 0; lineIndex < nthRow.length; lineIndex += 1) {
    const row = [];
    for (let cellIndex = 0; cellIndex < lineIndex + 1; cellIndex += 1) {
      let cellValue = 1;
      const k =
        cellIndex > lineIndex - cellIndex ? lineIndex - cellIndex : cellIndex;
      for (let i = 0; i < k; i += 1) {
        cellValue *= lineIndex - i;
        cellValue /= i + 1;
      }

      const actualValue =
        lineIndex !== nthRow.length - 1 || nthRow[cellIndex] === true
          ? cellValue
          : null;
      const expectedValue = cellValue;
      const result: CellValueTuple = [actualValue, expectedValue];
      row.push(result);
    }
    rows.push(row);
  }

  return rows;
};
