const ResetSent = () => {
  return (
    <>
      <h2 className="mt-6 text-center font-[Lora] text-2xl font-bold text-dm-brand-blue-600">
        Forgot Password
      </h2>
      <div className="flex-grow sm:mx-auto sm:w-full sm:max-w-md">
        <div className="px-4 sm:rounded-lg sm:px-10">
          <p className="mt-10 text-center text-dm-brand-blue-800">
            {
              "Thank you! We've sent an email to this email or your parent's account if we found a match. Follow the instructions in the email to reset your password."
            }
          </p>
        </div>
      </div>
    </>
  );
};

export default ResetSent;
