import { LearnerSubscriptionDetails } from "../types";

/** Returns true a subscription is in a state in which it can be modified */
export function canModifySubscription(
  subscription: Pick<LearnerSubscriptionDetails, "status" | "willBeCanceled">
): boolean {
  return (
    ["trialing", "active"].includes(subscription.status) &&
    !subscription.willBeCanceled
  );
}
