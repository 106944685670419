import { ParentProfile } from "./ParentProfile";
import { ParentProfileBeta } from "./Beta/ParentProfileBeta";
import { useLearnerContext } from "../../contexts/LearnerContext";

export const ParentProfileSwitcher: React.FC = () => {
  const { learner } = useLearnerContext();
  if (!learner) {
    return null;
  }
  return learner.enableSoftLaunch ? <ParentProfile /> : <ParentProfileBeta />;
};
