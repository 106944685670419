import clsx from "clsx";
import Document from "../../student/components/icons/Document";
import { LearnerAssignmentTypes } from "../types";
import { DMPieChart } from "../../student/components/generic/DMPieChart";

type Props = {
  extraClasses?: string;
  isActive?: boolean;
  type: LearnerAssignmentTypes | "course";
  progress: number;
  submitted: boolean;
  maxGrade?: number;
  isStarted?: boolean;
  resizingClasses?: string;
  lineWidth?: number;
  iconClasses?: string;
  iconUrl?: string;
  name?: string;
};

export default function IconAssignmentProgress(props: Props): JSX.Element {
  const progressColor = () => {
    if (!props.isStarted) {
      return "dm-gray-200";
    } else {
      return "dm-brand-blue-500";
    }
  };

  const asstTypeIcon = () => {
    if (props.type === "practice" || props.type === "course") {
      if (props.iconUrl !== undefined) {
        return (
          <img
            src={`${props.iconUrl}`}
            className={clsx(
              props.iconClasses || "w-4",
              props.isStarted ? "stroke-[#2f52e9]" : "stroke-[#9CA3AF]"
            )}
            aria-hidden="true"
            alt={props.name ? `${props.name} icon` : "Course icon"}
          />
        );
      } else {
        return (
          <Document
            classes={clsx(props.iconClasses || "w-4")}
            strokeColor={props.isStarted ? "#2f52e9" : "#9CA3AF"}
          />
        );
      }
    }

    return (
      <i
        className={`far fa-list fa-lg leading-none text-${progressColor()}`}
        aria-hidden="true"
      ></i>
    );
  };

  const HIDE_ELEMENT = false;
  if (props.type === "postQuiz") {
    if (
      props.maxGrade !== undefined &&
      props.maxGrade < 70 &&
      props.submitted
    ) {
      return (
        <div
          className="relative block shrink-0 text-center leading-none"
          role="img"
          aria-label="Retake quiz"
        >
          <i
            className={clsx(
              "far fa-check-circle text-[2rem] leading-none !text-dm-gray-200",
              props.resizingClasses
            )}
            aria-hidden="true"
          ></i>
          <i
            className="fas fa-redo absolute -right-1 -top-1 rotate-[135deg] -scale-x-100 rounded-full bg-white/70 p-0.5 text-[11px] leading-none !text-dm-brand-blue-500"
            aria-hidden="true"
          ></i>
        </div>
      );
    } else if (props.maxGrade !== undefined && props.maxGrade >= 70) {
      return (
        <i
          className={clsx(
            "far fa-check-circle text-[2rem] leading-none !text-dm-brand-blue-500",
            props.resizingClasses
          )}
          aria-label="Complete"
          role="img"
        ></i>
      );
    }
  }

  return (
    <>
      <div
        className={clsx(
          "relative block shrink-0 text-center leading-none",
          props.extraClasses
        )}
      >
        {!props.submitted ? (
          <div
            className={clsx(
              "relative flex h-8 w-8 items-center justify-center text-xs leading-none",
              props.resizingClasses
            )}
          >
            <DMPieChart
              data={[
                {
                  value: props.progress,
                  color: "#2f52e9",
                },
                {
                  value: 100 - props.progress,
                  color: props.isActive ? "#FFFFFF" : "#E3E8EF",
                },
              ]}
              lineWidth={props.lineWidth || 15}
              paddingAngle={0}
              totalValue={100}
              startAngle={270}
              className="pointer-events-none absolute"
              ariaLabel={`Progress is ${props.progress} out of 100.`}
            />
            {asstTypeIcon()}
          </div>
        ) : (
          <i
            className={clsx(
              "far fa-check-circle text-[2rem] leading-none !text-dm-brand-blue-500",
              props.resizingClasses
            )}
            aria-label="Complete"
            role="img"
          ></i>
        )}
      </div>
      {/*** IMPORTANT *****
        dynamically generated classes in Tailwind do not work, such as `stroke-${color}`
        because the classes are preprocessed.
        So we need to prerender all the possibilities below so the classes already exist
      */}
      {HIDE_ELEMENT && (
        <div
          className="hidden 
            fill-dm-brand-blue-500
            fill-dm-gray-200
            fill-dm-success-500
            stroke-dm-brand-blue-500
            stroke-dm-error-500
            stroke-dm-gray-200
            stroke-dm-success-500
            text-dm-brand-blue-500
            text-dm-gray-200
            text-dm-success-500"
        ></div>
      )}
    </>
  );
}
