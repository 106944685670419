import React, { useEffect, useState } from "react";

export const EditableTextarea = ({
  value: initialValue,
  row,
  updateMutation,
}: {
  value: string;
  row: any;
  updateMutation: any;
}) => {
  const [message, setMessage] = useState(initialValue);
  const [editing, setEditing] = useState(false);

  return (
    <>
      <textarea
        className="h-24 w-full rounded-lg border-2 border-gray-300 p-2"
        id={message}
        name="message"
        value={message}
        onClick={() => setEditing(true)}
        onChange={(event) => setMessage(event.target.value)}
      />
      <div className="min-h-[2em]">
        {editing && (
          <>
            <button
              type="button"
              onClick={() => {
                let quoteNumber = "";
                if (row.original.dm_quoteNumber) {
                  quoteNumber = row.original.dm_quoteNumber;
                } else if (row.original.quoteNumber) {
                  quoteNumber = row.original.quoteNumber;
                }
                if (quoteNumber || row.original.DocNumber) {
                  updateMutation.mutate({
                    quoteNumber: quoteNumber,
                    invoiceDocNumber: row.original.DocNumber,
                    field: "notes",
                    value: message,
                  });
                  setEditing(false);
                } else {
                  alert("There was an issue saving your value.");
                }
              }}
              className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Save
            </button>
            <button
              type="button"
              onClick={() => {
                setEditing(false);
              }}
              className="ml-5 inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Cancel
            </button>
          </>
        )}
      </div>
    </>
  );
};

export const NCESFlagEditable = ({
  value: initialValue,
  row,
  updateMutation,
}: {
  value: string;
  row: any;
  column: { id: string };
  updateMutation: any;
}) => {
  const [message, setMessage] = useState(initialValue);
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    setMessage(initialValue);
  }, [initialValue]);

  return (
    <>
      <textarea
        className="h-24 w-full rounded-lg border-2 border-gray-300 p-2"
        value={message}
        onChange={(event) => setMessage(event.target.value)}
        onClick={() => setEditing(true)}
      />
      <div className="min-h-[2em]">
        {editing && (
          <>
            <button
              type="button"
              onClick={() => {
                const ncesId = row.original.nces_id;
                const type = row.original.target;
                if (ncesId && type) {
                  updateMutation.mutate({
                    nces_notes: message,
                    nces_id: ncesId,
                    target: type,
                    nces_flag: true,
                  });
                  setEditing(false);
                } else {
                  alert("There was an issue saving your value.");
                }
              }}
              className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Save
            </button>
            <button
              type="button"
              onClick={() => {
                setEditing(false);
                setMessage(initialValue);
              }}
              className="ml-5 inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Cancel
            </button>
          </>
        )}
      </div>
    </>
  );
};
