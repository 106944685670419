import { useEffect, useState } from "react";
import { useDMQuery } from "../../../utils";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import { isNaN } from "lodash";
import { Skill } from "../../types";
import { Tooltip } from "../../../shared/Tooltip";
import ReactTooltip from "react-tooltip";

export type ModuleInfo = {
  inception: number;
  problem_count: number;
  idcodes: number[][];
  typeDescription: {
    [key: string]: string;
  };
};

type Props = {
  skill: Skill;
  removeSkill: (skillCode: string) => void;
  difficultyUpdate: (id: string, difficulty: "easy" | "hard" | "both") => void;
  typeUpdate: (id: string, typeKey: string, add: boolean) => void;
  testWeightUpdate: (id: string, weight: number) => void;
  addAllTypes: (id: string, typeKeys: string[]) => void;
  updateName: (id: string, newName: string) => void;
  allSkillsInCourse?: {
    sk: string;
    typeKeys: string[];
    unitId: string;
    unitVersion: string;
    unitVersionState: string;
    unitName: string;
    subunitId: string;
    subunitName: string;
  }[];
  deleteDisabled: boolean;
  showMessage: boolean;
  updatingName: React.MutableRefObject<boolean>;
};

const SkillBox = (props: Props) => {
  const toastContext = useDeltaToastContext();
  const [easyChecked, setEasyChecked] = useState<boolean>(
    props.skill.difficulty !== "hard"
  );
  const [hardChecked, setHardChecked] = useState<boolean>(
    props.skill.difficulty !== "easy"
  );
  const [editName, setEditName] = useState<string | undefined>(undefined);

  const {
    data: skillData,
    refetch: skillRefetch,
    status: skillDataStatus,
  } = useDMQuery<ModuleInfo>({
    path: `/manager_new/skill-codes/module_info/${props.skill.skillCode}`,
    queryOptions: {
      enabled: false,
    },
  });

  const checked = (difficulty: "easy" | "hard", checked: boolean) => {
    if (props.skill.difficulty === difficulty && !checked) {
      if (difficulty === "easy") {
        setEasyChecked(false);
        setHardChecked(true);
      } else {
        setEasyChecked(true);
        setHardChecked(false);
      }
    } else {
      if (difficulty === "easy") {
        setEasyChecked(checked);
      } else {
        setHardChecked(checked);
      }
    }
  };

  useEffect(() => {
    const difficulty =
      easyChecked && hardChecked ? "both" : hardChecked ? "hard" : "easy";
    if (props.skill.difficulty !== difficulty) {
      props.difficultyUpdate(props.skill._id, difficulty);
    }
  }, [easyChecked, hardChecked]);

  useEffect(() => {
    if (props.skill.skillCode && skillDataStatus !== "success") {
      skillRefetch();
    }
  }, [props.skill.skillCode, skillDataStatus]);

  const typeKeys =
    skillData && skillData.typeDescription
      ? Object.keys(skillData.typeDescription)
      : [];

  useEffect(() => {
    if (typeKeys.length !== 0 && props.skill.typeKeys.length === 0) {
      props.addAllTypes(props.skill._id, typeKeys);
    }
  }, [props.skill.typeKeys, typeKeys]);

  useEffect(() => {
    if (
      (props.skill.difficulty === "easy" && (!easyChecked || hardChecked)) ||
      (props.skill.difficulty === "hard" && (!hardChecked || easyChecked)) ||
      (props.skill.difficulty === "both" && (!easyChecked || !hardChecked))
    ) {
      if (props.skill.difficulty === "both") {
        setEasyChecked(true);
        setHardChecked(true);
      } else if (props.skill.difficulty === "easy") {
        setEasyChecked(true);
        setHardChecked(false);
      } else if (props.skill.difficulty === "hard") {
        setEasyChecked(false);
        setHardChecked(true);
      }
    }
  }, [props.skill]);

  const saveName = () => {
    if (editName) {
      props.updatingName.current = false;
      props.updateName(props.skill._id, editName);
      setEditName(undefined);
    }
  };

  const matchedSkills = props.allSkillsInCourse?.filter(
    (as) =>
      as.sk === props.skill.skillCode &&
      (props.showMessage || as.unitVersionState !== "deleted")
  );

  ReactTooltip.rebuild();

  return (
    <div className="border border-dashed border-dm-charcoal-200 p-4">
      <div className="mb-5 flex items-center justify-between gap-2">
        {editName === undefined ? (
          <div className="flex items-center gap-1">
            <p>{props.skill.skillName}</p>
            <i
              className="far fa-edit cursor-pointer"
              onClick={() => {
                props.updatingName.current = true;
                setEditName(props.skill.skillName);
              }}
            />
          </div>
        ) : (
          <div className="flex items-center gap-1">
            <input
              defaultValue={props.skill.skillName}
              onChange={(e) => setEditName(e.target.value)}
            />
            <i className="far fa-save cursor-pointer" onClick={saveName} />
          </div>
        )}
        <ReactTooltip id="delete-tooltip">
          {props.deleteDisabled
            ? `You may not delete this skill because the unit has been published`
            : `Delete this skill`}
        </ReactTooltip>
        <i
          data-tip
          data-for="delete-tooltip"
          className="far fa-minus-circle cursor-pointer text-2xl"
          onClick={() => {
            if (props.deleteDisabled) {
              return;
            }
            props.removeSkill(props.skill._id);
          }}
        />
      </div>
      <div className="flex">
        <div className="text-wrap text-left">{props.skill.skillCode}</div>
      </div>
      <div className="flex items-center justify-between">
        <div className="flex gap-6">
          <div>
            <input
              id={`easyChecked-${props.skill._id}`}
              name="eastCheck"
              onChange={(e) => {
                if (props.deleteDisabled) {
                  toastContext.addToast({
                    status: "Error",
                    message:
                      "You may not change the difficulty of this skill when published",
                  });
                  return;
                }
                checked("easy", e.target.checked);
              }}
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-dm-blue focus:ring-indigo-500"
              checked={easyChecked}
            />
            <label
              htmlFor={`easyChecked-${props.skill._id}`}
              className="ml-1 font-medium text-gray-700"
            >
              Easy
            </label>
          </div>
          <div>
            <input
              id={`hardChecked-${props.skill._id}`}
              name="hardCheck"
              onChange={(e) => {
                if (props.deleteDisabled) {
                  toastContext.addToast({
                    status: "Error",
                    message:
                      "You may not change the difficulty of this skill when published",
                  });
                  return;
                }
                checked("hard", e.target.checked);
              }}
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-dm-blue focus:ring-indigo-500"
              checked={hardChecked}
            />
            <label
              htmlFor={`hardChecked-${props.skill._id}`}
              className="ml-1 font-medium text-gray-700"
            >
              Hard
            </label>
          </div>
        </div>
        <div className="flex items-center gap-2">
          <label>Test Weight</label>
          <input
            className="h-10 w-10 text-center"
            value={props.skill.testWeight}
            onChange={(e) => {
              if (!isNaN(Number(e.target.value))) {
                props.testWeightUpdate(props.skill._id, Number(e.target.value));
              } else if (e.target.value) {
                toastContext.addToast({
                  status: "Error",
                  message: "You must enter an integer",
                });
              }
            }}
          ></input>
        </div>
      </div>
      {skillDataStatus === "success" && (
        <div className="mt-4 flex flex-col items-start">
          {typeKeys.map((key, i) => {
            const type = skillData.typeDescription[key];

            return (
              <div key={`skillbox-${key}-${i}-${props.skill._id}`}>
                <input
                  id={`type${key}-${props.skill._id}`}
                  name={`type${key}-${props.skill._id}`}
                  onChange={(e) => {
                    props.typeUpdate(props.skill._id, key, e.target.checked);
                  }}
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-dm-blue focus:ring-indigo-500"
                  checked={props.skill.typeKeys.includes(key)}
                />
                <label
                  htmlFor={`type${key}-${props.skill._id}`}
                  className="ml-1 font-medium text-gray-700"
                >
                  {type}
                </label>
              </div>
            );
          })}
        </div>
      )}
      {matchedSkills && matchedSkills.length !== 0 && (
        <div className="mt-2 rounded-lg border border-dm-error-500 bg-dm-error-200 px-4 py-2 text-dm-error-600">
          This module was also assigned in{" "}
          {matchedSkills
            .map((ms) => `${ms.unitName}, ${ms.subunitName}`)
            .join(" and ")}
        </div>
      )}
    </div>
  );
};

export default SkillBox;
