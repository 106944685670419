// import { VirtualKeyboardLayout } from "mathlive";

export enum SectionDataStatus {
  upcoming = "upcoming",
  no_late_available = "no_late_available",
  late_credit_available = "late_credit_available",
  completed = "completed",
  "past-due" = "past-due",
  "preview" = "preview",
  sampleStudentOverride = "sampleStudentOverride",
}

// export enum SectionStatus {
//   "upcoming" = "Upcoming",
//   "past-due" = "Past Due",
//   "completed" = "Completed",
// }

// export enum SectionParams {
//   "upcoming" = "upcoming",
//   "past-due" = "past-due",
//   "completed" = "completed",
// }

export enum AssignmentType {
  standard = "standard",
  correction = "correction",
}

export type AssignmentDueDateType =
  | "upcoming"
  | "past-due"
  | "completed"
  | "no_late_available"
  | "late_credit_available"
  | "preview";

export type SkillType =
  | "timed"
  | "standard"
  | "pure_standard"
  | "timed_mixed"
  | "dm_video"
  | "youtube_video"
  | "teacher_created"
  | "particular_problem"
  | "subtype_selected"
  | "proper_mixed"
  | "special";

export type SkillDataType = {
  uid?: string;
  url?: string;
  completion?: string;
  scoreOnSolve?: string;
  percentCompleted?: number;
  isCompleted?: boolean;
  type: SkillType;
  name?: string;
  isVideo?: boolean;
  isTest?: boolean;
  isStandardSkill?: boolean;
  isTimedModule?: boolean;
  isTeacherType?: boolean;
  video?: string;
  icon?: JSX.Element;
  progress?: {
    showSegments?: boolean;
    total?: number;
    score?: number;
    record?: number;
    weight?: number;
  };
  maxProblemsOneDone?: boolean;
  maxProblemsOneDoneCorrect?: boolean;
  maxProblems?: number;
  solvedProblems?: number;
  isMaxProblemType?: boolean;
  isMaxProblemComplete?: boolean;
  obscureResults?: boolean;
  solutionsAvailable?: boolean;
  showSolutionsSetting?: "always" | "never" | "incorrect_only" | "correct_only";
};

// export const DM_KEYBOARD_LAYER = {
//   "layer-2": {
//     styles: "",
//     rows: [
//       [
//         { latex: ">" },
//         { latex: "<" },
//         { latex: "\\ge" },
//         { latex: "\\le" },
//         { class: "separator w5" },
//         { label: "log", insert: "$$\\log\\left(#0\\right)$$" },
//         { label: "ln", insert: "$$\\ln\\left(#0\\right)$$" },
//         { class: "separator w5" },
//         { label: "sin", insert: "$$\\sin\\left(#0\\right)$$" },
//         { label: "cos", insert: "$$\\cos\\left(#0\\right)$$" },
//         { label: "tan", insert: "$$\\tan\\left(#0\\right)$$" },
//       ],
//       [
//         { latex: "!" },
//         { class: "small", label: "ⁿ√", insert: "\\sqrt[#0]{#1}" },
//         { class: "small", label: "³√", insert: "\\sqrt[3]{#1}" },
//         { class: "small tex", label: "|a|", insert: "\\left|#0\\right|" },
//         { class: "separator w5" },
//         { label: "nCr", insert: "\\text{nCr}\\left(#0,#1\\right)" },
//         { label: "nPr", insert: "$$\\text{nPr}\\left(#0,#1\\right)$$" },
//         { class: "separator w5" },

//         {
//           class: " small",
//           label: "<span>sin<sup>-1</sup></span>",
//           insert: "$$\\sin^{-1}\\left(#0\\right)$$",
//         },
//         {
//           class: " small",
//           label: "<span>cos<sup>-1</sup></span>",
//           insert: "$$\\cos^{-1}\\left(#0\\right)$$",
//         },
//         {
//           class: " small",
//           label: "<span>tan<sup>-1</sup></span>",
//           insert: "$$\\tan^{-1}\\left(#0\\right)$$",
//         },
//       ],
//       [
//         { latex: "\\theta" },
//         { latex: "e" },
//         { latex: "i" },
//         { latex: "\\pm" },
//         { class: "separator w50" },
//         { class: "separator" },
//       ],
//       [{ class: "separator" }],
//     ],
//   },
//   "layer-1": {
//     styles: "",
//     rows: [
//       [
//         { latex: "x" },
//         { class: "tex", latex: "y" },
//         { latex: "(" },
//         { latex: ")" },
//         { class: "separator w5" },
//         { label: "7", key: "7" },
//         { label: "8", key: "8" },
//         { label: "9", key: "9" },
//         { latex: "\\div" },
//         { class: "separator w5" },
//         {
//           class: "action font-glyph", // bottom right
//           label: "&#x232b;",
//           command: ["performWithFeedback", "deleteBackward"],
//         },
//       ],
//       [
//         { class: "tex", latex: "=" },
//         {
//           class: "small",
//           label: "√",
//           insert: "$$\\sqrt{#0}$$",
//         },
//         {
//           class: "tex small",
//           label: "<span><i>x</i>&thinsp;²</span>",
//           insert: "$$#@^{2}$$",
//         },
//         {
//           class: "tex small",
//           label: "<span><i>x</i><sup>&thinsp;<i>n</i></sup></span>",
//           insert: "$$#@^{#0}$$",
//         },
//         { class: "separator w5" },
//         { label: "4", latex: "4" },
//         { label: "5", key: "5" },
//         { label: "6", key: "6" },
//         { latex: "\\times" },
//         { class: "separator w5" },
//         {
//           class: "action w5",
//           label: "<svg><use xlink:href='#svg-arrow-left' /></svg>",
//           command: ["performWithFeedback", "moveToPreviousChar"],
//         },
//         {
//           class: "action w5",
//           label: "<svg><use xlink:href='#svg-arrow-right' /></svg>",
//           command: ["performWithFeedback", "moveToNextChar"],
//         },
//         // { class: "separator" },
//       ],
//       [
//         { class: "separator" },
//         { class: "separator" },
//         { class: "separator" },
//         { latex: "," },
//         { class: "separator w5" },
//         { label: "1", key: "1" },
//         { label: "2", key: "2" },
//         { label: "3", key: "3" },
//         { latex: "-" },
//         { class: "separator w5" },
//         { class: "separator" },
//       ],
//       [
//         { class: "separator w20" },
//         { class: "separator w20" },
//         { class: "separator w5" },
//         { label: "0", key: "0" },
//         { latex: "." },
//         { latex: "\\pi" },
//         { latex: "+" },
//         { class: "separator w5" },
//         { class: "separator" },
//       ],
//     ],
//   },
// };

// export const DM_KEYBOARD = {
//   "dm-keyboard": {
//     label: "123", // Label displayed in the Virtual Keyboard Switcher
//     tooltip: "First Level", // Tooltip when hovering over the label
//     layer: "layer-1",
//   },
//   "layer2-keyboard": {
//     label: "Funcs",
//     tooltip: "Second Level",
//     layer: "layer-2",
//   },
// };

// export const DM_KEYBOARD: VirtualKeyboardLayout = {
//   layers: [
//     {
//       id: "123",
//       rows: [
//         [
//           { latex: "x" },
//           { class: "tex", latex: "y" },
//           { latex: "(" },
//           { latex: ")" },
//           { class: "separator w5" },
//           { label: "7", key: "7" },
//           { label: "8", key: "8" },
//           { label: "9", key: "9" },
//           { latex: "\\div" },
//           { class: "separator w5" },
//           {
//             class: "action font-glyph", // bottom right
//             label: "&#x232b;",
//             command: ["performWithFeedback", "deleteBackward"],
//           },
//         ],
//         [
//           { class: "tex", latex: "=" },
//           {
//             class: "small",
//             label: "√",
//             insert: "$$\\sqrt{#0}$$",
//           },
//           {
//             class: "tex small",
//             label: "<span><i>x</i>&thinsp;²</span>",
//             insert: "$$#@^{2}$$",
//           },
//           {
//             class: "tex small",
//             label: "<span><i>x</i><sup>&thinsp;<i>n</i></sup></span>",
//             insert: "$$#@^{#0}$$",
//           },
//           { class: "separator w5" },
//           { label: "4", latex: "4" },
//           { label: "5", key: "5" },
//           { label: "6", key: "6" },
//           { latex: "\\times" },
//           { class: "separator w5" },
//           {
//             class: "action w5",
//             label: "<svg><use xlink:href='#svg-arrow-left' /></svg>",
//             command: ["performWithFeedback", "moveToPreviousChar"],
//           },
//           {
//             class: "action w5",
//             label: "<svg><use xlink:href='#svg-arrow-right' /></svg>",
//             command: ["performWithFeedback", "moveToNextChar"],
//           },
//           // { class: "separator" },
//         ],
//         [
//           { class: "separator" },
//           { class: "separator" },
//           { class: "separator" },
//           { latex: "," },
//           { class: "separator w5" },
//           { label: "1", key: "1" },
//           { label: "2", key: "2" },
//           { label: "3", key: "3" },
//           { latex: "-" },
//           { class: "separator w5" },
//           { class: "separator" },
//         ],
//         [
//           { class: "separator w20" },
//           { class: "separator w20" },
//           { class: "separator w5" },
//           { label: "0", key: "0" },
//           { latex: "." },
//           { latex: "\\pi" },
//           { latex: "+" },
//           { class: "separator w5" },
//           { class: "separator" },
//         ],
//       ],
//     },
//     {
//       id: "Funcs",
//       rows: [
//         [
//           { latex: ">" },
//           { latex: "<" },
//           { latex: "\\ge" },
//           { latex: "\\le" },
//           { class: "separator w5" },
//           { label: "log", insert: "$$\\log\\left(#0\\right)$$" },
//           { label: "ln", insert: "$$\\ln\\left(#0\\right)$$" },
//           { class: "separator w5" },
//           { label: "sin", insert: "$$\\sin\\left(#0\\right)$$" },
//           { label: "cos", insert: "$$\\cos\\left(#0\\right)$$" },
//           { label: "tan", insert: "$$\\tan\\left(#0\\right)$$" },
//         ],
//         [
//           { latex: "!" },
//           { class: "small", label: "ⁿ√", insert: "\\sqrt[#0]{#1}" },
//           { class: "small", label: "³√", insert: "\\sqrt[3]{#1}" },
//           { class: "small tex", label: "|a|", insert: "\\left|#0\\right|" },
//           { class: "separator w5" },
//           { label: "nCr", insert: "\\text{nCr}\\left(#0,#1\\right)" },
//           { label: "nPr", insert: "$$\\text{nPr}\\left(#0,#1\\right)$$" },
//           { class: "separator w5" },

//           {
//             class: " small",
//             label: "<span>sin<sup>-1</sup></span>",
//             insert: "$$\\sin^{-1}\\left(#0\\right)$$",
//           },
//           {
//             class: " small",
//             label: "<span>cos<sup>-1</sup></span>",
//             insert: "$$\\cos^{-1}\\left(#0\\right)$$",
//           },
//           {
//             class: " small",
//             label: "<span>tan<sup>-1</sup></span>",
//             insert: "$$\\tan^{-1}\\left(#0\\right)$$",
//           },
//         ],
//         [
//           { latex: "\\theta" },
//           { latex: "e" },
//           { latex: "i" },
//           { latex: "\\pm" },
//           { class: "separator w50" },
//           { class: "separator" },
//         ],
//         [{ class: "separator" }],
//       ],
//     },
//     "roman",
//   ],
// };
