import { LearnerAssignment, SimplifiedLearnerAssignment } from "../types";
import { concat, max } from "lodash";
import { TestSummaryItem } from "./TestSummaryItem";

type Props = {
  assignment: LearnerAssignment;
  courseId: string;
  unitId?: string;
};
const TestSummary = (props: Props) => {
  const type = props.assignment.type;

  const assignmentSkills: Array<SimplifiedLearnerAssignment> = concat(
    props.assignment.resultHist
      ? props.assignment.resultHist.map((x, i) => {
          const previousResults =
            i > 0 ? (props.assignment.resultHist || []).slice(0, i) : [];
          const bestGrade = max(previousResults.map((rh) => rh.grade));

          const scoreDiff =
            i > 0 && bestGrade !== undefined ? x.grade - bestGrade : undefined;

          return {
            ...x,
            scoreDifference: scoreDiff,
            current: 0,
          };
        })
      : [],
    props.assignment?.submitted
      ? {
          grade: props.assignment.grade,
          skills: props.assignment.skills,
          submitted: props.assignment.submitted,
          created: props.assignment.createdAt,
          _id: props.assignment._id,
          scoreDifference: props.assignment.resultHist
            ? props.assignment.grade -
              (max(props.assignment.resultHist?.map((rh) => rh.grade)) || 0)
            : undefined,
          current: 1,
          duration: props.assignment.duration,
          pointsEarned: props.assignment.pointsEarned,
        }
      : []
  );

  return (
    <>
      <h1 className="mb-6 text-left font-serif text-lg font-bold max-md:mx-4 max-sm:mt-8 md:text-2xl">
        {type === "unitTest" ? "Unit Test Summary" : "Course Test Summary"}
      </h1>
      {assignmentSkills.map((assignment: SimplifiedLearnerAssignment) => (
        <TestSummaryItem
          key={assignment._id}
          courseId={props.courseId}
          unitId={props.unitId}
          assignment={assignment}
          type={type}
        />
      ))}
    </>
  );
};

export default TestSummary;
