import { UnitsIcon } from "../../../shared/icons/UnitsIcon";
import { useLearnerContext } from "../../contexts/LearnerContext";
import { Progress, UnitProgress } from "../../types";
import { DashboardCard } from "./DashboardCard";

function calculateCompletedUnits(progresses: Progress[]): number {
  let total = 0;
  progresses.forEach((progress: Progress) => {
    progress.units.forEach((unit: UnitProgress) => {
      if (unit.progress === 1) total += 1;
    });
  });
  return total;
}

export const UnitsCompletedCard: React.FC = () => {
  const learnerContext = useLearnerContext();
  const units = calculateCompletedUnits(learnerContext.state.progresses);

  return (
    <DashboardCard title="Units Completed" icon={<UnitsIcon />}>
      <h4 className="mb-2 mt-4 font-serif text-3xl font-bold">{units} units</h4>
      <p className="mb-1 text-xs text-gray-500">
        Total units completed so far.
      </p>
    </DashboardCard>
  );
};
