import clsx from "clsx";
import { PropsWithChildren } from "react";

/**
 * A gray pulsing rect to use as a placeholder while data is loading. This will be the
 * full width and height of its container.
 */
export const Skeleton: React.FC = () => (
  <div className="h-full w-full animate-pulse rounded-sm bg-dm-charcoal-100" />
);

/**
 * A gray pulsing bar to use as a placeholder for text while data is loading. This
 * will take the shape of its children but will not display them
 */
export const SkeletonText: React.FC<
  PropsWithChildren<{
    loaded?: boolean;
  }>
> = ({ loaded = false, children }) => (
  <div className="flex">
    <div
      className={clsx(
        !loaded && "inline-block animate-pulse rounded-sm bg-dm-charcoal-100"
      )}
    >
      <div className={clsx(!loaded && "invisible")}>{children}</div>
    </div>
  </div>
);

/**
 * A gray pulsing circle to use as a placeholder while data is loading. This will be the
 * full width and height of its container.
 */
export const SkeletonCircle: React.FC = () => (
  <div className="h-full w-full animate-pulse rounded-full bg-dm-charcoal-100" />
);
