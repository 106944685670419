import { Link, LinkProps } from "react-router-dom";

export const SearchResultItem: React.FC<LinkProps> = ({
  children,
  ...props
}) => (
  <li>
    <Link
      className="flex flex-col gap-1 rounded-md border border-dm-charcoal-100 px-4 py-2 transition-colors hover:bg-dm-brand-blue-100"
      target="_new"
      {...props}
    >
      {children}
    </Link>
  </li>
);
