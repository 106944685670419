import { NavLink } from "react-router-dom";
import { Learner, LearnerSubscriptionDetails } from "../../types";
import { getOpenSeats } from "../../utils/getOpenSeats";
import { WarningBanner } from "../Parent/WarningBanner";
import { REACT_APP_LEARNER_LINK } from "../../../utils";

export const OpenSeatsWarningBanner: React.FC<{
  subscription: LearnerSubscriptionDetails;
  learners: Learner[];
}> = ({ subscription, learners }) => {
  const numSeats = subscription.quantity;
  const openSeats = getOpenSeats(subscription.quantity, learners);

  if (openSeats <= 0) return <></>;

  let bannerTitle: string, bannerText: string, bannerCta: string;
  if (numSeats === 1) {
    // one learner
    bannerTitle = "Action Needed:";
    bannerText = "Add your learner and link their profile to get started!";
    bannerCta = "Invite learner now.";
  } else if (numSeats > 1 && openSeats === numSeats) {
    // multiple learners, 0 invites sent
    bannerTitle = "Action Needed:";
    bannerText = "Add your learners and link their profiles to get started.";
    bannerCta = `Invite ${numSeats} learners now.`;
  } else {
    // multiple learners, some invites sent
    bannerTitle = "Almost There!";
    if (openSeats === 1) {
      bannerText = "You have 1 learner that still needs an invite.";
      bannerCta = "Send remaining invite now.";
    } else {
      bannerText = `You have ${openSeats} learners that still need an invite.`;
      bannerCta = "Send remaining invites now.";
    }
  }
  return (
    <WarningBanner bannerTitle={bannerTitle} bannerText={bannerText}>
      <NavLink
        to={`${REACT_APP_LEARNER_LINK}/parent/add-learners`}
        className="font-bold text-dm-brand-blue-500 hover:underline"
      >
        {bannerCta}
      </NavLink>
    </WarningBanner>
  );
};
