import { ResponsiveImage } from "../ResponsiveImage";
import { ComponentProps, PropsWithChildren } from "react";

type ImageCardProps = {
  imageSrcs: ComponentProps<typeof ResponsiveImage>["srcs"];
  imageAlt: string;
  title: string;
  text: string;
};

export const DashboardImageCard: React.FC<
  PropsWithChildren<ImageCardProps>
> = ({ imageSrcs, imageAlt, title, text, children }) => (
  <div className="relative flex w-full flex-col gap-x-8 gap-y-5 rounded-xl border border-dm-charcoal-100 bg-white p-6 md:flex-row">
    <ResponsiveImage
      className="w-[247px] self-center md:self-start"
      srcs={imageSrcs}
      alt={imageAlt}
    />
    <div className="flex flex-col justify-center">
      <h2 className="mb-1 font-serif text-lg font-bold leading-snug text-dm-gray-800">
        {title}
      </h2>
      <p className="mb-4 text-sm text-dm-gray-800 md:leading-7">{text}</p>
      {children}
    </div>
  </div>
);
