import { usePrizes } from "../../../utils/usePrizes";
import { LearnerPage } from "../../Layouts/LearnerPage";
import { PrizeCard } from "./PrizeCard";
import ReactTooltip from "react-tooltip";
import { useEffect } from "react";

export const PrizesPage: React.FC = () => {
  const prizes = usePrizes();

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <>
      <LearnerPage title="Prizes">
        <h1 className="mb-4 mt-6 px-6 font-serif text-2xl font-bold leading-10">
          Prizes
        </h1>
        <ol className="flex flex-col gap-y-2">
          {prizes.map((prize, index) => (
            <PrizeCard
              key={`${prize.key}-${index}`}
              prize={prize}
              row={index + 5}
            />
          ))}
        </ol>
      </LearnerPage>
      <ReactTooltip
        id="pointsPageTooltip"
        className="!rounded !px-3 font-sans text-xs leading-normal before:!-z-10"
        effect="solid"
        delayShow={150}
        delayHide={50}
        multiline={true}
        place="top"
        html={true}
        getContent={(dataTip) => dataTip}
      />
    </>
  );
};
