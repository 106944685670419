import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CourseType, Unit } from "../../types";
import { REACT_APP_MANAGER_LINK, useDMQuery } from "../../../utils";
import UnitDetails from "./UnitDetails";
import Select, { SingleValue } from "react-select";
import Button from "../../../student/components/generic/button";
import { ArrowLeftIcon } from "@heroicons/react/solid";
import { Checkbox } from "../../../learner/components/Splash/Checkbox";

const CourseDetail = () => {
  const [courseVersion, setCourseVersion] = useState<string | undefined>(
    undefined
  );
  const [hideTypes, setHideTypes] = useState<boolean>(false);

  const params = useParams();
  const courseId = params.courseId ?? "";

  const {
    data: courseData,
    refetch: courseRefetch,
    status: courseStatus,
  } = useDMQuery<CourseType>({
    path: `/manager_new/parent-portal/course/${courseId}`,
    queryOptions: {
      enabled: false,
    },
  });

  const { data: unitData, refetch: unitRefetch } = useDMQuery<Unit[]>({
    path: `/manager_new/parent-portal/course/${courseId}/units?versionId=${courseVersion}`,
    queryOptions: {
      enabled: false,
    },
  });

  useEffect(() => {
    if (courseData) {
      setCourseVersion(
        courseData.versionHistory[courseData.versionHistory.length - 1]
          .versionKey
      );
    }
  }, [courseData]);

  useEffect(() => {
    if (courseId !== "") {
      courseRefetch();
    }
  }, [courseId]);

  useEffect(() => {
    if (courseVersion) {
      unitRefetch();
    }
  }, [courseVersion]);

  if (!courseData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="px-4 pt-10">
      <div className="pb-6">
        <Button
          href={`${REACT_APP_MANAGER_LINK}/course/${courseId}?version=${courseVersion}`}
          type="link"
        >
          <ArrowLeftIcon
            className="h-5 w-5 text-dm-gray-200"
            aria-hidden="true"
          />
          Back To Course
        </Button>
        <div className="my-4">
          <Button
            onClick={() => setHideTypes(!hideTypes)}
            type={hideTypes ? "outline" : "primary"}
          >
            {hideTypes ? "Show" : "Hide"} Types
          </Button>
        </div>
        <h2 className="text-2xl font-bold">Course Detail</h2>
        <p>{courseData.name}</p>
        <Select
          placeholder="Select Specific Version"
          name="Unit Version"
          options={courseData.versionHistory.map((v) => {
            return {
              value: v.versionKey,
              label: `V${v.versionName}`,
            };
          })}
          isClearable={true}
          isSearchable={false}
          closeMenuOnSelect={false}
          className="w-56"
          onChange={(
            newValue: SingleValue<{ label: string; value: string }>
          ) => {
            if (newValue?.value) {
              setCourseVersion(newValue.value);
            }
          }}
        />
      </div>
      {unitData &&
        unitData.map((u) => {
          return (
            <UnitDetails
              key={`unit-detail-${u._id}`}
              unit={u}
              unitId={u._id}
              hideTypes={hideTypes}
            />
          );
        })}
    </div>
  );
};

export default CourseDetail;
