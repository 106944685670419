import { useEffect, useRef, useState } from "react";
import { CheckAnswerResponse, Problem } from "../../types";
import RenderLines from "../Problem/RenderLines";
import {
  generateProblemScripts,
  processlines,
  resizeKatex,
} from "../../../student/utils";
import { attempt, debounce } from "lodash";
import { useProblemSolvingContext } from "../../contexts/ProblemSolvingContext";
import clsx from "clsx";
import renderMathInElement from "../../../student/utils/auto-render";

type Props = {
  problem: Problem;
  checkAnswerResponse?: CheckAnswerResponse;
  unsubmitAllowed: boolean;
  userSolution?: object | string[];
  noSolutionText: string;
  noAnswerSubmitted: boolean;
  unsubmit: () => void;
  customMessage?: string;
  externalFileData?: any[];
  showMultipleAnswers: boolean;
};

const YourSolutionGraph = (props: Props) => {
  const { logData } = useProblemSolvingContext();
  const showAttempts =
    logData?.attempts_data &&
    logData?.attempts_data.length > 0 &&
    props.showMultipleAnswers;
  const numberOfAttempts = (logData?.attempts_data?.length || 0) + 1;

  const [attemptNumber, setAttemptNumber] = useState<number>(
    numberOfAttempts - 1
  );
  const [katexResizingData, setKatexResizingData] = useState<any>({});
  const listRef = useRef<HTMLUListElement>(null);
  const correct =
    showAttempts && attemptNumber === 0 ? false : logData?.correct;

  const bindAnswerData = (answerData: any) => {
    const scripts = generateProblemScripts(
      props.externalFileData,
      props.problem,
      answerData
    );
    eval(`window.deltaGraphs = [];${props.problem?.inlineSolutionCode};`);
    scripts.problemScripts?.solutionScripts(
      document.querySelector(".answerData")
    );
  };

  useEffect(() => {
    if (logData) {
      bindAnswerData(logData.answerData || logData.data);
    }
  }, []);

  /* On browser resize, call resizeKatex() to readjust the KaTeX width */
  useEffect(() => {
    const handleResize = () => {
      setKatexResizingData((prevState: any) => ({
        ...prevState,
        ["solution"]: resizeKatex("solution", prevState),
      }));
    };

    const debounce_resize = debounce(handleResize, 150);

    handleResize();
    window.addEventListener("resize", debounce_resize);
    return () => {
      window.removeEventListener("resize", debounce_resize);
    };
  }, []);

  useEffect(() => {
    if (listRef.current) renderMathInElement(listRef.current);
  }, [attemptNumber]);

  const handleClick = (moveForward: boolean) => {
    const currAttempt = moveForward ? attemptNumber + 1 : attemptNumber - 1;
    if (currAttempt >= 0 && currAttempt < numberOfAttempts) {
      // first attempt should be total number - 1 which is found on logData not in attempts_data
      const answerData =
        currAttempt === numberOfAttempts - 1 || !logData?.attempts_data
          ? logData?.data
          : logData?.attempts_data[currAttempt].data;
      if (answerData) {
        bindAnswerData(answerData);
      }
      setAttemptNumber(currAttempt);
    }
  };

  const messages =
    // first attempt should be total number - 1 which is found on logData not in attempts_data
    attemptNumber === numberOfAttempts - 1 || !showAttempts
      ? logData?.messages
      : logData?.attempts_data && logData?.attempts_data.length > 0
      ? logData?.attempts_data[attemptNumber].messages
      : undefined;

  return (
    <>
      {showAttempts && (
        <div className="row mt-6">
          <div className="col-sm-9 z-10 flex items-center justify-between text-[#4B5563]">
            <button
              disabled={attemptNumber === 0}
              className="disabled:opacity-50"
              onClick={() => handleClick(false)}
              aria-labelledby="arrow-left-circle"
            >
              <svg
                id="arrow-left-circle"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="h-6 w-6"
                role="img"
              >
                <title>See previous answer</title>
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M11.25 9l-3 3m0 0l3 3m-3-3h7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </button>
            <h3>
              <div className="row flex items-center font-sans">
                Attempt {attemptNumber + 1} out of{" "}
                {((logData?.attempts_data && logData?.attempts_data.length) ||
                  0) + 1}
                {correct ? (
                  <>
                    {" "}
                    (correct)
                    <i className="far fa-check ml-2 !text-dm-success-500" />
                  </>
                ) : (
                  <>
                    {" "}
                    (incorrect)
                    <i className="far fa-times ml-2 !text-dm-error-500" />
                  </>
                )}
              </div>
            </h3>
            <button
              disabled={attemptNumber + 1 === numberOfAttempts}
              className="disabled:opacity-50"
              onClick={() => handleClick(true)}
              aria-labelledby="arrow-right-circle"
            >
              <svg
                id="arrow-right-circle"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="h-6 w-6"
                role="img"
              >
                <title>See next answer</title>
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12.75 15l3-3m0 0l-3-3m3 3h-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </button>
          </div>
        </div>
      )}

      <div className="display-problem problem-page mb-8">
        <RenderLines
          displayData={processlines(props.problem.answerLines)}
          problemData={props.problem}
          resizingData={
            katexResizingData ? katexResizingData["solution"] : undefined
          }
          locString="solution"
        />
      </div>

      {messages && messages.length > 0 ? (
        <div className="row">
          <ul ref={listRef} className="col-sm-9 text-center">
            {messages.map((msg, index) => (
              <li
                key={"message" + index}
                className={clsx(
                  "px-4 py-2 sm:px-6",
                  correct ? "text-[#078445]" : "text-[#D21527]"
                )}
              >
                {msg}
              </li>
            ))}
          </ul>
        </div>
      ) : null}
    </>
  );
};

export default YourSolutionGraph;
