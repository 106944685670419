import { useParentContext } from "../../contexts/ParentContext";
import { Learner } from "../../types";
import { InsightsButton } from "./InsightsButton";
import { InsightsDropdown } from "./InsightsDropdown";

export const InsightsContainer: React.FC = () => {
  const { learners, currentLearner, setCurrentLearnerId } = useParentContext();

  if (learners.length === 0 || !currentLearner) return <></>;

  const updateLearner = (learner: Learner) => setCurrentLearnerId(learner?._id);

  if (learners.length <= 2) {
    return (
      <div className="flex items-center gap-6 lg:gap-8">
        {learners.map((learner: Learner) => (
          <InsightsButton
            key={learner._id}
            learner={learner}
            currentLearner={currentLearner}
            onClick={() => updateLearner(learner)}
          />
        ))}
      </div>
    );
  } else {
    return (
      <InsightsDropdown
        learners={learners}
        currentLearner={currentLearner}
        updateLearner={updateLearner}
      />
    );
  }
};
