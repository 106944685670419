import { useState } from "react";
import { useMutation } from "react-query";
import axios from "axios";
import { cloneDeep } from "lodash";
import { deltamathAPI } from "../../../../manager/utils";
import Modal from "../../../../student/components/generic/Modal";
import Button from "../../../../student/components/generic/button";
import { obfuscate } from "../../../../student/utils";
import { SwitchHorizontalIcon } from "@heroicons/react/outline";
import { LearnerAssignmentTypes } from "../../../types";
import { getFilePath } from "../../../../utils";
import { useDeltaToastContext } from "../../../../shared/contexts/ToasterContext";
import { useProblemSolvingContext } from "../../../contexts/ProblemSolvingContext";
import { useLearnerContext } from "../../../contexts/LearnerContext";
import { useLearnerAnalytics } from "../../../analytics/useLearnerAnalytics";
import { usedQuestionSwapEvent } from "../../../analytics/events";
import { useParams } from "react-router-dom";

type Props = {
  assignmentId: string;
  type?: LearnerAssignmentTypes;
  isSolutionShowing?: boolean;
};

const QuestionSwap: React.FC<Props> = (props) => {
  const { indexOfSkill } = useParams();
  const [showModal, setShowModal] = useState(false);

  const toastContext = useDeltaToastContext();
  const learnerContext = useLearnerContext();

  const {
    setCurrentProblem,
    skillId,
    setLogData,
    setAttempts,
    questionSwap,
    assignment,
    problemData,
  } = useProblemSolvingContext();

  const { track, getAssignmentData } = useLearnerAnalytics();

  const { mutate: questionSwapMutate, isLoading } = useMutation(
    (body: { assignmentId: string; skillId: string }) => {
      return axios.post(
        `${deltamathAPI()}/learner/assignment/question_swap`,
        JSON.stringify(body),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    },
    {
      onSuccess: (data, variables) => {
        track(
          usedQuestionSwapEvent({
            ...getAssignmentData(assignment.type),
            skillCode: problemData?.problem.skillcode ?? "unknown",
            skillNumber: indexOfSkill ? parseInt(indexOfSkill, 10) : -1,
          })
        );

        setLogData(data.data.nextProblem.log_data);
        setAttempts(
          data.data.nextProblem.attempts
            ? data.data.nextProblem.attempts?.used
            : 0
        );
        setCurrentProblem({
          ...data.data.nextProblem,
          lines: data.data.nextProblem.lines || [],
          data:
            typeof data.data.nextProblem.data === "string"
              ? obfuscate(`${data.data.nextProblem._id}`).reveal(
                  `${data.data.nextProblem.data}`
                )
              : data.data.nextProblem.data,
        });
        const clonedAssignment = cloneDeep(assignment);
        clonedAssignment.questionSwap = variables.skillId;
        learnerContext.updateAssignmentAndProgress({
          assignment: clonedAssignment,
        });

        setShowModal(false);
      },
      onError: (error: any) => {
        toastContext.addToast({
          title: "Error",
          message:
            error?.response?.data?.message ||
            error?.message ||
            "An error occurred",
          status: "Error",
        });
        setShowModal(false);
      },
    }
  );

  const questionSwapClick = () => {
    const body = {
      assignmentId: props.assignmentId,
      skillId: skillId,
    };
    questionSwapMutate(body);
  };

  return (
    <>
      {questionSwap === undefined && !props.isSolutionShowing ? (
        <>
          <Button
            className="flex items-center gap-x-1 !px-3 !py-0 font-sans text-sm text-dm-brand-blue-500"
            type="link"
            onClick={() => {
              setShowModal(true);
            }}
          >
            <SwitchHorizontalIcon className="w-6 text-dm-gray-200" />
            Question Swap
          </Button>
          <Modal
            visible={showModal}
            onClose={() => {
              setShowModal(false);
            }}
            title={
              <>
                <img
                  className="mx-auto mb-4 w-36"
                  src={getFilePath("/images/learner/clipboard.png")}
                  alt="Question Swap"
                />
                <span className="font-serif font-bold">Use Question Swap?</span>
              </>
            }
            body="Want to try a different question? This will give you a new problem on the same skill. Remember, you only have one swap per test attempt. Choose wisely!"
            confirmButtonText="Swap My Question"
            secondaryButtonText={
              props.type === "postQuiz" ? "Back to Quiz" : "Back to Test"
            }
            secondaryOnClick={() => setShowModal(false)}
            onConfirm={() => {
              questionSwapClick();
            }}
            secondaryDisabled={isLoading}
            confirmDisabled={isLoading}
          />
        </>
      ) : questionSwap !== undefined && questionSwap === skillId ? (
        <span className="flex items-center gap-x-1 rounded-full bg-dm-purple-200 px-2 py-0.5 font-sans text-sm leading-none text-dm-purple-800">
          <SwitchHorizontalIcon className="w-4" />
          Swapped
        </span>
      ) : null}
    </>
  );
};

export default QuestionSwap;
