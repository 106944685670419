import { useContext, useEffect } from "react";
import { NavLink, useParams, useSearchParams } from "react-router-dom";
import StudentSectionsContext from "../_context/StudentSectionsContext";
import clsx from "clsx";
import { SectionDataStatus, AssignmentDueDateType } from "../_constants";
import { timedAssg, getAssignmentDueDateType } from "../utils";
import TestCorrection from "../components/link/TestCorrection";
import TimedAssignment from "../components/link/TimedAssignment";
import { REACT_APP_STUDENT_LINK } from "../../utils";

type Props = {
  assignments: any;
  refetchAssignments: () => void;
};

enum AssignmentType {
  standard = "standard",
  correction = "correction",
}

export default function Link({
  assignments,
  refetchAssignments,
}: Props): JSX.Element {
  const { teacherId } = useParams();
  const { loadingData, setLoadingData, setOpenAssignment } = useContext(
    StudentSectionsContext
  );
  const [searchParams] = useSearchParams();

  // Find the correct section and assignment
  const keys = Object.keys(assignments);
  const sectionId = keys.find((k: any) =>
    assignments[k]
      ? assignments[k].find((a: any) => a?.ta?._id == teacherId)
      : undefined
  );
  const assignment =
    assignments[sectionId ?? ""] &&
    assignments[sectionId ?? ""].find((a: any) => a?.ta?._id == teacherId);

  const backButtonUrl = `${REACT_APP_STUDENT_LINK}/${sectionId}/${getAssignmentDueDateType(
    assignment?.sa?.status
  )}`;

  // If this exists in local storage it was an LTI launch
  const lti = JSON.parse(
    localStorage.getItem("lti_assignment_payload") || "{}"
  ).isLtiResourceLaunch;

  useEffect(() => {
    // if the `loading...` modal is showing when Link loads, close it
    if (loadingData?.isShowing === true && loadingData?.error === false) {
      setLoadingData((prevState: any) => ({
        ...prevState,
        isShowing: false,
      }));
    }

    setOpenAssignment(assignment?.ta?._id);
  }, []);

  const timedAssignment = assignment ? timedAssg(assignment, true) : undefined;

  if (assignment === undefined || !timedAssignment) {
    return (
      <div className="max-h-screen w-full max-w-2xl overflow-y-auto rounded-xl bg-white p-4 text-left sm:px-8 sm:py-12">
        <p className="text-sm tracking-tight">
          The link is not valid or links to an assignment which is not currently
          assigned to your class.
        </p>
        {!lti && (
          <div className="mt-8 flex flex-wrap items-center justify-end gap-x-6 gap-y-3 border-t border-t-[#CCCED0] pt-8 sm:flex-nowrap">
            <NavLink
              to={backButtonUrl}
              className={clsx(
                "basis-full rounded border px-5 py-2.5 text-center text-sm text-white focus-visible:outline focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:basis-auto sm:px-8",
                "border-dm-brand-blue-500 bg-dm-brand-blue-500 hover:bg-dm-brand-blue-600"
              )}
            >
              Back To Home
            </NavLink>
          </div>
        )}
      </div>
    );
  }

  const hideTitle = searchParams.get("hideTitle");

  const sectionType: AssignmentType | string = assignment?.ta?.type;
  const isCorrectionType = sectionType == AssignmentType.correction;

  const assignmentDueDateType: AssignmentDueDateType | undefined =
    assignment?.sa?.status || "";

  const dateObject =
    assignmentDueDateType == SectionDataStatus.late_credit_available
      ? assignment?.sa?.lateDate?.date
      : assignment?.sa?.dueDate;

  const dueDate =
    dateObject?.date && dateObject?.tz
      ? new Date(dateObject?.date).toLocaleString("en-US", {
          timeZone: dateObject?.tz,
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        })
      : "";

  return (
    <div className="max-h-screen w-full max-w-2xl overflow-y-auto rounded-xl bg-white p-4 text-left sm:px-8 sm:py-12">
      {hideTitle !== "true" && (
        <>
          <h1 className="font-serif text-2xl font-semibold tracking-tight">
            {assignment?.ta?.name}
          </h1>
          {dueDate && <h2 className="mt-4 font-bold">Due: {dueDate}</h2>}
        </>
      )}

      <div className="mt-6">
        {isCorrectionType && sectionId && teacherId && (
          <TestCorrection
            timedAssignment={timedAssignment}
            sa={assignment.sa}
            ta={assignment.ta}
            teacherId={teacherId}
            sectionId={sectionId}
            refetchAssignments={refetchAssignments}
          />
        )}
        {!isCorrectionType && sectionId && teacherId && (
          <TimedAssignment
            timedAssignment={timedAssignment}
            sa={assignment.sa}
            ta={assignment.ta}
            teacherId={teacherId}
            sectionId={sectionId}
            refetchAssignments={refetchAssignments}
          />
        )}
      </div>
    </div>
  );
}
