import { FormEventHandler, useRef, useState } from "react";
import { TextField } from "../../learner/components/Splash/TextField";
import Select, { SingleValue } from "react-select";
import Button from "../../student/components/generic/button";
import { useMutation } from "react-query";
import { deltamathAPI } from "../utils";
import axios from "axios";
import { useDeltaToastContext } from "../../shared/contexts/ToasterContext";
import Modal from "../../student/components/generic/Modal";
import { REACT_APP_HOMEPAGE_LINK, REACT_APP_LEARNER_LINK } from "../../utils";

type FormState = {
  first: string;
  last: string;
  email: string;
  courseName: string;
};

type Props = {
  visible: boolean;
  onClose: () => void;
  callback: () => void;
};

const BetaAppInviteModal = (props: Props) => {
  const [inviteKey, setInviteKey] = useState<string>();
  const toast = useDeltaToastContext();

  const createInvite = useMutation(
    (body: string) => {
      return axios.post(
        `${deltamathAPI()}/manager_new/parent-portal/beta_invite`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    },
    {
      onSuccess: (data) => {
        setInviteKey(data.data.key);
        props.callback();
      },
      onError: (error) => {
        toast.addToast({
          status: "Error",
          message: "Issue creating invite.",
        });
        console.error(error);
      },
    }
  );

  const formRef = useRef<HTMLFormElement>(null);
  const [formState, setFormState] = useState<FormState>({
    first: "",
    last: "",
    email: "",
    courseName: "",
  });
  const [formErrors, setFormErrors] = useState<{
    [K in keyof FormState]?: string;
  }>({});

  const handleSubmit: FormEventHandler = (e) => {
    e.preventDefault();
    if (!formState.first) {
      setFormErrors({ ...formErrors, first: "First name is required" });
    }
    if (!formState.last) {
      setFormErrors({ ...formErrors, last: "Last name is required" });
    }
    if (!formState.email) {
      setFormErrors({ ...formErrors, email: "Email is required" });
    }
    if (!formState.courseName) {
      setFormErrors({
        ...formErrors,
        courseName: "Course selection is required",
      });
    }
    createInvite.mutate(JSON.stringify(formState));
  };

  return (
    <Modal
      visible={props.visible}
      onClose={props.onClose}
      noLine
      body={
        <>
          {inviteKey && (
            <h5>
              INVITE KEY: {REACT_APP_HOMEPAGE_LINK}
              {REACT_APP_LEARNER_LINK}/beta_invite?key={inviteKey}
            </h5>
          )}
          <form onSubmit={handleSubmit} className="p-10" ref={formRef}>
            <TextField
              label="First Name"
              value={formState.first}
              error={formErrors.first}
              onChange={(e) =>
                setFormState({ ...formState, first: e.target.value })
              }
            />
            <TextField
              label="Last Name"
              value={formState.last}
              error={formErrors.last}
              onChange={(e) =>
                setFormState({ ...formState, last: e.target.value })
              }
            />
            <TextField
              label="Email"
              value={formState.email}
              error={formErrors.email}
              onChange={(e) =>
                setFormState({ ...formState, email: e.target.value })
              }
              type="email"
            />

            <Select
              className="pt-6"
              placeholder="Select Course"
              name="colorstypeOptions"
              options={[
                {
                  value: "7th Grade",
                  label: "7th Grade",
                },
                {
                  value: "Algebra 2",
                  label: "Algebra 2",
                },
              ]}
              isSearchable={false}
              isMulti={false}
              onChange={(
                newValue: SingleValue<{ label: string; value: string }>
              ) => {
                if (newValue) {
                  setFormState({ ...formState, courseName: newValue.value });
                }
              }}
            />
            <Button submit className="mt-10">
              Create Invite
            </Button>
          </form>
        </>
      }
    ></Modal>
  );
};

export default BetaAppInviteModal;
