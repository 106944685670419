import { SparklesIcon } from "@heroicons/react/outline";
import Button from "../../../student/components/generic/button";
import { DashboardCard } from "../Dashboard/DashboardCard";
import { useParentContext } from "../../contexts/ParentContext";
import { SkeletonText } from "../Skeleton";
import HowPointsWorkModal from "../Modals/HowPointsWorkModal";

import { useBoolean } from "usehooks-ts";

export const TotalPointsCard: React.FC = () => {
  const { currentLearner } = useParentContext();
  const { value: isShowingModal, toggle: toggleIsShowingModal } =
    useBoolean(false);
  return (
    <>
      <DashboardCard size="flex" icon={<SparklesIcon />} title="Total Points">
        <h3 className="mt-4 font-serif text-2xl font-bold">
          <SkeletonText loaded={!!currentLearner}>
            {currentLearner?.pointsEarned ?? "??"} pts
          </SkeletonText>
        </h3>
        <Button
          type="link"
          className="text-dm-blue-500 hover:text-dm-blue-700 cursor-pointer text-sm hover:underline"
          onClick={() => toggleIsShowingModal()}
        >
          Learn How Points Work
        </Button>
        <HowPointsWorkModal
          visible={isShowingModal}
          onClose={toggleIsShowingModal}
        />
      </DashboardCard>
    </>
  );
};
