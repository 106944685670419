import Button from "../../../student/components/generic/button";
import { SkeletonText } from "../Skeleton";
import { useLearnerContext } from "../../contexts/LearnerContext";
import { LockedTriangleBackground } from "./LockedTriangleBackground";
import { useState } from "react";
import { RoundedLockIcon } from "./RoundedLockIcon";
import { MIN_POINTS_TO_UNLOCK } from "../../constants";
import { ResponsiveImage } from "../ResponsiveImage";
import { getFilePath } from "../../../utils";

export const PascalsTriangleLocked: React.FC<{ onUnlock: () => void }> = ({
  onUnlock,
}) => {
  const { learner, pointsAvailable } = useLearnerContext();

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const canUnlock =
    pointsAvailable !== null && pointsAvailable >= MIN_POINTS_TO_UNLOCK;

  const handleUnlock = async () => {
    setIsButtonDisabled(true);
    // this will send them into onboarding
    onUnlock();
    setIsButtonDisabled(false);
  };

  const LeftContent = () => {
    if (!learner) {
      return null;
    }
    if (!canUnlock) {
      return (
        <>
          <h3 className="font-serif text-lg font-bold">
            Earn {MIN_POINTS_TO_UNLOCK} points to unlock Pascal&apos;s Triangle
          </h3>
          <p>Win prizes for each row you unlock</p>
        </>
      );
    }
    return (
      <>
        <h3 className="font-serif text-lg font-bold">
          You&rsquo;ve earned {learner.pointsEarned} points!
        </h3>
        <p>
          You&rsquo;re ready to unlock Pascal&rsquo;s Triangle. Use your points
          to play and start earning cool prizes.
        </p>
        <Button
          type="points"
          className="mt-4"
          onClick={handleUnlock}
          disabled={isButtonDisabled}
        >
          Unlock Pascal&apos;s Triangle
        </Button>
      </>
    );
  };

  const imageFile = canUnlock
    ? "pascals-triangle-earned-points"
    : "pascals-triangle";

  return (
    <div className="mt-4 flex flex-col justify-between gap-4 md:mt-0 md:flex-row">
      <div className="self-start md:max-w-96 md:self-center">
        <SkeletonText loaded={!!learner}>
          <LeftContent />
        </SkeletonText>
      </div>
      <div className="flex w-full shrink-0 items-center gap-y-[11px] md:w-auto md:justify-end">
        <div className="relative flex h-[188px] w-[205px] items-center justify-center">
          <LockedTriangleBackground />

          <div className="absolute bottom-0 left-0 z-10 h-[80%] w-full bg-gradient-to-t from-white to-transparent"></div>

          {!canUnlock ? (
            <div className="absolute z-20">
              <RoundedLockIcon variant="static" size="md" />
            </div>
          ) : (
            <div className="absolute z-20 mt-4">
              <RoundedLockIcon variant="ping" size="md" />
            </div>
          )}
        </div>
        <ResponsiveImage
          className="inline h-auto w-36 object-contain md:pt-16"
          srcs={[
            getFilePath(`/images/learner/points/${imageFile}.png`),
            getFilePath(`/images/learner/points/${imageFile}@2x.png`),
          ]}
          alt=""
          aria-hidden="true"
        />
      </div>
    </div>
  );
};
