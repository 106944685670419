import { useState, useEffect, Dispatch, SetStateAction } from "react";
import clsx from "clsx";
import { PieChart } from "react-minimal-pie-chart";
import { SkillDataType } from "../_constants";
import Document from "./icons/Document";
import DonutChart from "./DonutChart";

type Props = {
  skillData: SkillDataType;
  setTooltipText: Dispatch<SetStateAction<string | null>>;
  tooltipId?: string;
  isActive?: boolean;
  extraClasses?: string;
};

export default function IconProgress({
  skillData,
  setTooltipText,
  tooltipId = "piechart",
  isActive = false,
  extraClasses = "",
}: Props): JSX.Element {
  const isTestType = skillData.isTest;
  const isVideoType = skillData.isVideo;
  const isTimedType = skillData.isTimedModule;
  const isSpecial = skillData.type === "special";

  const [textforTooltip, setTextForTooltip] = useState<string>("");

  useEffect(() => {
    if (skillData.isTeacherType) {
      setTextForTooltip(
        skillData.isCompleted ? "Teacher Graded - Pending" : "Teacher Graded"
      );
    } else if (
      skillData?.progress?.record !== undefined &&
      skillData?.progress?.score !== undefined &&
      skillData?.progress?.total !== undefined
    ) {
      // partial credit:
      if (
        skillData.isMaxProblemType &&
        skillData.isCompleted &&
        skillData.progress.record > 0 &&
        skillData.maxProblemsOneDoneCorrect === false
      ) {
        if (isTestType) {
          const weight =
            skillData?.progress?.weight === undefined
              ? 1
              : skillData?.progress?.weight;
          setTextForTooltip(
            `Score: ${skillData?.progress?.score * weight}/${
              skillData?.progress?.total * weight
            }`
          );
        } else {
          setTextForTooltip(
            `${
              skillData?.progress?.record > skillData?.progress?.score
                ? `High Score: ${skillData?.progress?.record}`
                : `Score: ${skillData?.progress?.score}`
            }/${skillData?.progress?.total}`
          );
        }
      } else if (!isTestType) {
        if (
          skillData?.progress?.record > Math.ceil(skillData?.progress?.score)
        ) {
          setTextForTooltip(
            `Score: ${skillData?.progress?.score}/${skillData?.progress?.total}<br>High Score: ${skillData?.progress?.record}`
          );
        } else if (skillData?.progress?.record !== skillData?.progress?.score) {
          setTextForTooltip(
            `Score: ${skillData?.progress?.score}/${skillData?.progress?.total}`
          );
        }
      }
    }
  }, [skillData]);

  const icon = () => {
    if (isSpecial) {
      return (
        <div
          className="pointer-events-auto absolute h-5 w-5"
          aria-hidden={true}
        >
          <PieChart
            data={[{ value: 100, color: isActive ? "#FFFFFF" : "#9CA3AF" }]}
            lineWidth={40}
            className="pointer-events-none"
          />
        </div>
      );
    } else if (skillData.isTeacherType) {
      return (
        <>
          <Document
            strokeColor={
              skillData.isCompleted
                ? isActive
                  ? "#FFFFFF"
                  : "#585858"
                : "#9CA3AF"
            }
          />
          <span className="sr-only">
            {skillData.isCompleted
              ? "Skill is complete."
              : "Skill is incomplete."}
          </span>
        </>
      );
    } else if (isTestType) {
      if (skillData.isCompleted) {
        if (skillData.obscureResults) {
          return (
            <>
              <i
                className="far fa-check-circle text-xl leading-none !text-dm-brand-blue-500"
                aria-hidden="true"
              ></i>
              <span className="sr-only">Skill is complete.</span>
            </>
          );
        } else if (
          skillData.maxProblemsOneDoneCorrect &&
          !skillData.obscureResults
        ) {
          return (
            <>
              <i
                className="far fa-check text-xl leading-none !text-dm-success-500"
                aria-hidden="true"
              ></i>
              <span className="sr-only">Skill is complete and correct.</span>
            </>
          );
        } else if (
          !skillData.maxProblemsOneDoneCorrect &&
          !skillData.obscureResults
        ) {
          return (
            <>
              <i
                className="far fa-times text-xl leading-none !text-dm-error-500"
                aria-hidden="true"
              ></i>
              <span className="sr-only">Skill is complete and incorrect.</span>
            </>
          );
        }
      } else {
        return (
          <>
            <i
              className={clsx(
                "far fa-circle text-xl leading-none",
                isActive ? "text-white" : "text-gray-400"
              )}
              aria-hidden="true"
            ></i>
            <span className="sr-only">Skill is incomplete.</span>
          </>
        );
      }
    } else if (isVideoType) {
      const completed = skillData.percentCompleted || 0;
      if (completed === 100)
        return (
          <>
            <i
              className="far fa-check text-xl leading-none !text-dm-success-500"
              aria-hidden="true"
            ></i>
            <span className="sr-only">Skill is complete.</span>
          </>
        );
      else
        return (
          <div className="relative flex h-5 w-5 flex-shrink-0 items-center justify-center text-xs leading-none">
            <PieChart
              data={[
                { value: completed, color: "#2F52E9" },
                {
                  value: 100 - completed,
                  color: isActive ? "#FFFFFF" : "#E3E8EF",
                },
              ]}
              lineWidth={25}
              paddingAngle={0}
              totalValue={100}
              startAngle={270}
              className="pointer-events-none absolute"
            />
            <i
              className={clsx(
                "fas fa-play fa-xs pl-0.5",
                completed < 100
                  ? isActive
                    ? "text-white"
                    : "!text-dm-charcoal-100"
                  : "!text-dm-brand-blue-500"
              )}
              aria-hidden="true"
            ></i>
            <span className="sr-only">
              Video skill is {completed} percent complete.
            </span>
          </div>
        );
    } else if (isTimedType) {
      const completed = skillData.percentCompleted || 0;
      if (completed === 100)
        return (
          <>
            <i
              className="far fa-check text-xl leading-none !text-dm-success-500"
              aria-hidden="true"
            ></i>
            <span className="sr-only">Skill is complete and correct.</span>
          </>
        );
      else
        return (
          <div className="relative flex h-5 w-5 items-center justify-center text-xs leading-none">
            <PieChart
              data={[
                { value: completed, color: "#2F52E9" },
                {
                  value: 100 - completed,
                  color: isActive ? "#FFFFFF" : "#E3E8EF",
                },
              ]}
              lineWidth={25}
              paddingAngle={0}
              totalValue={100}
              startAngle={270}
              className="pointer-events-none absolute"
            />
            <svg
              viewBox="0 0 500 500"
              xmlns="http://www.w3.org/2000/svg"
              fill="#E3E8EF"
              className="pl-0.5"
              aria-labelledby={skillData?.name + "-icon-title"}
            >
              <title id={skillData?.name + "-icon-title"} lang="en">
                Timed skill is {completed} percent complete.
              </title>
              <path d="M 275.938 372.652 L 191.038 310.952 C 187.938 308.652 186.138 305.052 186.138 301.252 L 186.138 137.052 C 186.138 130.452 191.538 125.052 198.138 125.052 L 230.138 125.052 C 236.738 125.052 242.138 130.452 242.138 137.052 L 242.138 278.752 L 308.938 327.352 C 314.338 331.252 315.438 338.752 311.538 344.152 L 292.738 370.052 C 288.838 375.352 281.338 376.552 275.938 372.652 Z"></path>
            </svg>
          </div>
        );
    } else if (!isTestType) {
      if (skillData.isMaxProblemType && skillData.isCompleted) {
        if (
          skillData.maxProblemsOneDoneCorrect === false &&
          skillData.progress?.record === 0
        ) {
          return (
            <>
              <i
                className="far fa-times text-xl leading-none !text-dm-error-500"
                aria-hidden="true"
              ></i>
              <span className="sr-only">Skill is complete and incorrect.</span>
            </>
          );
        } else if (skillData.maxProblemsOneDoneCorrect) {
          return (
            <>
              <i
                className="far fa-check text-xl leading-none !text-dm-success-500"
                aria-hidden="true"
              ></i>
              <span className="sr-only">Skill is complete and correct.</span>
            </>
          );
        } else {
          return (
            <>
              <DonutChart
                isMaxOne={!!skillData.maxProblems}
                progress={skillData.progress}
                isActive={isActive}
              />
              <span className="sr-only">
                {textforTooltip === "" &&
                skillData?.progress?.score !== undefined &&
                skillData.progress.total !== undefined
                  ? `${skillData.progress.score} out of ${skillData.progress.total} complete.`
                  : ""}
              </span>
            </>
          );
        }
      } else {
        if (skillData.isCompleted) {
          return (
            <>
              <i
                className="far fa-check text-xl leading-none !text-dm-success-500"
                aria-hidden="true"
              ></i>
              <span className="sr-only">Skill is complete.</span>
            </>
          );
        }
        return (
          <>
            <DonutChart
              isMaxOne={!!skillData.maxProblems}
              progress={skillData.progress}
              isActive={isActive}
            />
            <span className="sr-only">
              {textforTooltip === "" &&
              skillData?.progress?.score !== undefined &&
              skillData.progress.total !== undefined
                ? `${skillData.progress.score} out of ${skillData.progress.total} complete.`
                : ""}
            </span>
          </>
        );
      }
    }
    return <></>;
  };

  return (
    <>
      <div
        className={clsx(
          "relative block h-5 w-5 shrink-0 text-center leading-none",
          extraClasses
        )}
        data-tip
        data-for={tooltipId}
        onPointerOver={() => {
          setTooltipText(textforTooltip);
        }}
        onPointerOut={() => {
          setTooltipText("");
        }}
      >
        {icon()}
      </div>
      {textforTooltip !== null ? (
        <p
          className="sr-only order-last"
          dangerouslySetInnerHTML={{ __html: textforTooltip }}
        ></p>
      ) : null}
    </>
  );
}
