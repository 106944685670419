import { getFilePath } from "../../../utils";
import { Learner } from "../../types";
import { ResendInviteLink } from "../Profile/ResendInviteLink";
import { ResendInviteButton } from "./AddLearners/ResendInviteButton";
import { DashboardImageCard } from "./DashboardImageCard";
import { LearnerStats } from "./LearnerStats";
import { RecentActivity } from "./RecentActivity";
import { WarningBanner } from "./WarningBanner";

export const DashboardForInvitedLearner: React.FC<{
  currentLearner: Learner;
  header: React.ReactElement;
}> = ({ currentLearner, header }) => {
  return (
    <>
      <div className="mb-6">
        <WarningBanner bannerTitle="Your learner's invite has been sent to their email! Check their inbox and let them know to accept it!">
          <ResendInviteLink
            learnerId={currentLearner._id}
            className="!py-0 !text-base font-bold hover:underline"
            buttonText="Resend invite"
          />
        </WarningBanner>
      </div>
      {header}
      <div className="flex flex-col gap-6">
        <DashboardImageCard
          imageSrcs={[
            getFilePath(
              "/images/learner/parent/dashboard/deltie-stars-math.png"
            ),
            getFilePath(
              "/images/learner/parent/dashboard/deltie-stars-math@2x.png"
            ),
          ]}
          imageAlt="Smiling Deltie with star eyes"
          title="Learner Invite was sent!"
          text="They'll need to accept the invite to access/activate their DeltaMath for Home account."
        >
          <ResendInviteButton
            learnerId={currentLearner._id}
            type="primary"
            className="md:max-w-[310px]"
          />
        </DashboardImageCard>
        <div className="z-[7] order-1 grid grid-cols-1 gap-4 lg:grid-cols-2">
          <LearnerStats showEmptyState={true} />
          <RecentActivity currentLearnerId={currentLearner._id} />
        </div>
      </div>
    </>
  );
};
