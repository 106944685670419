import { REACT_APP_LEARNER_LINK } from "../../utils";
import { useParentContext } from "../contexts/ParentContext";

/** If the parent has not invited any learners yet, then clicking on the DeltaMath For Home icon should take them to their profile page. Otherwise, it should go to the dashboard. */
export function useDeltaMathHomeLink() {
  const { learners } = useParentContext();

  return learners.length === 0
    ? `${REACT_APP_LEARNER_LINK}/parent/profile`
    : `${REACT_APP_LEARNER_LINK}/parent`;
}
