import { PropsWithChildren } from "react";

/** Just a simple white box with centered content */
export const BasicContentContainer: React.FC<PropsWithChildren<unknown>> = ({
  children,
}) => (
  <div className="flex min-h-52 w-full items-center justify-center rounded border border-dm-charcoal-200 bg-white p-4 text-dm-charcoal-500">
    {children}
  </div>
);
