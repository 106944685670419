import Modal from "../../../student/components/generic/Modal";
import Button from "../../../student/components/generic/button";
import { ResponsiveImage } from "../ResponsiveImage";
import { getFilePath } from "../../../utils";
import { TourTypes } from "../../contexts/TourContext";

const content = [
  {
    type: "parent",
    title: "Welcome to DeltaMath for Home!",
    description:
      "Track progress, see insights, and support your child's math learning. Take a quick tour to see how DeltaMath for Home works for parents.",
  },
  {
    type: "dashboard",
    title: "Introducing Your New Dashboard",
    description:
      "Take a quick tour to learn how your dashboard helps you track progress, get personalized insights, and reach your math goals",
  },
  {
    type: "learner",
    title: "Welcome to DeltaMath for Home!",
    description:
      "This quick tour will take you through the basics of how to use the platform. Get ready to learn math in a whole new way!",
  },
];

type Props = {
  type: TourTypes;
  visible: boolean;
  onClose: () => void;
  startTour: () => void;
  remindMeLater: () => void;
  skipTour: () => void;
};

const StartTourModal = (props: Props) => {
  const copy = content.find((c) => c.type === props.type);

  return (
    <Modal
      visible={props.visible}
      onClose={props.onClose}
      removeOverflow
      body={
        <div className="flex flex-col items-center gap-4">
          <ResponsiveImage
            className=""
            srcs={[
              getFilePath("/images/learner/home/deltie-a-plus-small.png"),
              getFilePath("/images/learner/home/deltie-a-plus-small@2x.png"),
            ]}
            alt="Deltie holding an A+ sign"
          />
          <h3 className="mt-6 w-full text-2xl font-bold leading-[26px]">
            {copy?.title}
          </h3>
          <p className="leading-[26px]">{copy?.description}</p>
        </div>
      }
      widthClassName={"max-w-full w-[768px] transition-none"}
      footer={
        <div className="mt-8 flex w-full flex-col items-center justify-between gap-4 border-t pt-8 md:w-auto md:flex-row">
          <Button
            onClick={props.skipTour}
            type="link"
            className="font-bold leading-[26px]"
          >
            Skip Tour
          </Button>
          <div className="flex w-full flex-col gap-4 md:w-auto md:flex-row">
            <Button onClick={props.remindMeLater} type="outline" size="large">
              Remind Me Later
            </Button>
            <Button onClick={props.startTour} type="primary" size="large">
              Take the Tour
            </Button>
          </div>
        </div>
      }
    />
  );
};

export default StartTourModal;
