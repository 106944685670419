import { uniqueId } from "lodash";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Rating, RatingScale } from "../../types";
import { FormFooter } from "./FormFooter";
import { IconRadioInput } from "./IconRadioInput";
import axios from "axios";
import { useMutation } from "react-query";
import { deltamathAPI } from "../../../manager/utils";
import { useState } from "react";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import { withJsonHeader } from "../../../shared/axiosUtils";
import { ResponsiveImage } from "../ResponsiveImage";
import { getFilePath } from "../../../utils";

type FormData = {
  rating: Rating;
  explanation: string;
  consent: boolean;
};

export const FeedbackForm: React.FC<{ onClose: () => void }> = ({
  onClose,
}) => {
  const {
    handleSubmit,
    control,
    watch,
    register,
    formState: { isValid },
  } = useForm<FormData>({
    mode: "onChange",
  });
  const currentRating = watch("rating");
  const textareaId = uniqueId("explanation");
  const toastContext = useDeltaToastContext();
  const [nonFieldError, setNonFieldError] = useState<string | null>(null);

  const submitFeedback = useMutation(
    (body: FormData) => {
      return axios.post<{ status: "success" | "error" }>(
        `${deltamathAPI()}/learner/shared/feedback`,
        JSON.stringify(body),
        withJsonHeader()
      );
    },
    {
      onSuccess: (data) => {
        const message = "Thank you for submitting feedback!";
        toastContext.addToast({
          message,
          status: "Success",
        });
        onClose();
      },
      onError: (e) => {
        const message =
          "An error occurred while submitting feedback. Please try again.";
        setNonFieldError(message);
      },
    }
  );

  const onSubmit: SubmitHandler<FormData> = (data) => {
    submitFeedback.mutate(data);
  };

  const ratings: RatingScale[] = [
    {
      rating: "Awful",
      icon: (
        <ResponsiveImage
          srcs={[
            getFilePath("/images/learner/deltiemojis/deltie-awful.png"),
            getFilePath("/images/learner/deltiemojis/deltie-awful@2x.png"),
          ]}
          alt={"Angry Deltie"}
        />
      ),
    },
    {
      rating: "Poor",
      icon: (
        <ResponsiveImage
          srcs={[
            getFilePath("/images/learner/deltiemojis/deltie-poor.png"),
            getFilePath("/images/learner/deltiemojis/deltie-poor@2x.png"),
          ]}
          alt={"Sad Deltie"}
        />
      ),
    },
    {
      rating: "Neutral",
      icon: (
        <ResponsiveImage
          srcs={[
            getFilePath("/images/learner/deltiemojis/deltie-neutral.png"),
            getFilePath("/images/learner/deltiemojis/deltie-neutral@2x.png"),
          ]}
          alt={"Neutral Deltie"}
        />
      ),
    },
    {
      rating: "Good",
      icon: (
        <ResponsiveImage
          srcs={[
            getFilePath("/images/learner/deltiemojis/deltie-good.png"),
            getFilePath("/images/learner/deltiemojis/deltie-good@2x.png"),
          ]}
          alt={"Happy Deltie"}
        />
      ),
    },
    {
      rating: "Excellent",
      icon: (
        <ResponsiveImage
          srcs={[
            getFilePath("/images/learner/deltiemojis/deltie-excellent.png"),
            getFilePath("/images/learner/deltiemojis/deltie-excellent@2x.png"),
          ]}
          alt={"Deltie with starry eyes"}
        />
      ),
    },
  ];

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <fieldset className="mb-10 flex flex-row flex-wrap items-center justify-center gap-4">
        {ratings.map((ratingScaleItem) => {
          const { rating, icon } = ratingScaleItem;
          return (
            <Controller
              key={rating}
              name="rating"
              control={control}
              render={({ field }) => (
                <IconRadioInput
                  {...field}
                  label={rating}
                  icon={icon}
                  isSelected={currentRating === rating}
                  value={rating}
                />
              )}
              rules={{
                required: true,
              }}
            />
          );
        })}
      </fieldset>
      <div className="mb-4 flex flex-col gap-1">
        <label
          htmlFor={textareaId}
          className="flex flex-col gap-2 text-sm font-bold"
        >
          Tell us more about your experience:
        </label>
        <textarea
          id={textareaId}
          className="h-40 w-full rounded-md border border-gray-300 px-2 py-1 text-sm focus:ring-transparent"
          {...register("explanation", {
            required: true,
          })}
        />
      </div>
      <label className="mb-10 flex items-center gap-2 pl-2 text-sm">
        <input
          className="border border-dm-charcoal-200"
          type="checkbox"
          {...register("consent")}
        />
        <span>
          I consent to DeltaMath for Home contacting me based on my feedback
        </span>
      </label>
      {nonFieldError && (
        <div className="my-4 text-dm-error-500">{nonFieldError}</div>
      )}
      <FormFooter
        submitButtonText="Send Report"
        submitDisabled={!isValid}
        secondaryButtonText="Cancel"
        secondaryOnClick={onClose}
      />
    </form>
  );
};
