import { NavLink } from "react-router-dom";
import clsx from "clsx";

type Props = {
  isMinimized: boolean;
  userName: string;
  link: string;
};

export const ProfileLink: React.FC<Props> = (props) => (
  <NavLink className="group" to={props.link}>
    <div className="flex items-center gap-2">
      <span
        className={clsx(
          "flex h-6 justify-center",
          props.isMinimized ? "w-7" : "w-6"
        )}
      >
        <i
          className="far fa-user text-xl leading-none text-dm-gray-200 group-hover:text-dm-gray-600"
          aria-label="Your profile"
        ></i>
      </span>
      {!props.isMinimized && (
        <span className="truncate text-dm-gray-600">{props.userName}</span>
      )}
    </div>
  </NavLink>
);
