import { SpeechBubble } from "./SpeechBubble";

export const OnboardingPascalsStep: React.FC<{
  step: number;
  goToNextStep: () => void;
  isPaused: boolean;
}> = ({ step, goToNextStep, isPaused }) => {
  if (step === 1) {
    return (
      <SpeechBubble
        text="This is Pascal's Triangle. It's a triangle of numbers that is really important in probability, combinatorics, and algebra."
        isVisible={true}
        onClick={goToNextStep}
        stepNumber={1}
      />
    );
  }

  if (step === 2) {
    return (
      <SpeechBubble
        isVisible={isPaused}
        text="When filling out the numbers in the triangle, we always put 1's along the left and right sides."
        onClick={goToNextStep}
        stepNumber={2}
      />
    );
  }

  return (
    <SpeechBubble
      isVisible={isPaused && step !== 4}
      text="To figure out any number on the inside of the triangle, add the two numbers above it."
      onClick={goToNextStep}
      stepNumber={3}
      isLastStep
    />
  );
};
