import clsx from "clsx";
import { HTMLAttributes } from "react";

export const TiltedBanner: React.FC<
  HTMLAttributes<HTMLHeadingElement> & { rotateClockwise: boolean }
> = ({ rotateClockwise, className, ...props }) => {
  const rotateClass = rotateClockwise ? "rotate-[18deg]" : "-rotate-[12deg]";

  return (
    <h5
      className={clsx(
        "absolute rounded-full bg-[#7BD78C] px-8 py-2",
        rotateClass,
        className
      )}
      {...props}
    >
      For Parents and Learners
    </h5>
  );
};
