import { useContext } from "react";
import BookClosedIcon from "../icons/BookClosedIcon"; // TODO: Add correct icon
import { SectionDataStatus } from "../../_constants";
import StudentSectionsContext from "../../_context/StudentSectionsContext";
import Assignments from "../assignments";
import SectionWelcomeState from "./SectionWelcomeState";
import SectionEmptyState from "./SectionEmptyState";
import { getSectionData } from "../../utils";
import { useUserContext } from "../../../shared/contexts/UserContext";
import { Navigate } from "react-router-dom";
import { REACT_APP_STUDENT_LINK } from "../../../utils";

export default function Preview(): JSX.Element {
  const userContext = useUserContext();
  const { activeSection, dmAssignmentData, showPastDue } = useContext(
    StudentSectionsContext
  );

  if (typeof dmAssignmentData[activeSection] === "undefined") {
    return <SectionWelcomeState />;
  }

  const assignmentList = getSectionData(
    SectionDataStatus["preview"],
    activeSection,
    dmAssignmentData
  );
  const assignmentCount = assignmentList?.length;

  const showUpcoming =
    !assignmentCount &&
    getSectionData(SectionDataStatus.upcoming, activeSection, dmAssignmentData)
      ?.length
      ? true
      : false;

  if (!userContext.isSampleStudent()) {
    return (
      <Navigate
        to={`${REACT_APP_STUDENT_LINK}/${activeSection}/${SectionDataStatus.upcoming}`}
      />
    );
  }

  return (
    <>
      <div className="mx-6 mt-4 flex gap-3 rounded-lg border border-dm-warning-800 bg-dm-warning-100 px-4 py-2 align-top">
        <i className="far fa-eye mt-1" />
        <div className="h-full">
          <p className="text-sm font-bold">Teacher Preview</p>
          <p className="text-sm">
            These assignments or tests are not yet visible to students.
          </p>
        </div>
      </div>
      {assignmentCount ? (
        <div className="mt-4 px-4 sm:mt-6 sm:px-6">
          {assignmentList?.map((section: any) => (
            <Assignments
              future
              section={section}
              key={`com-section:${encodeURI(section?.ta?._id)}`}
            />
          ))}
        </div>
      ) : (
        <SectionEmptyState
          icon={<BookClosedIcon />}
          header="You have no completed assignments or tests"
          message={`You can find assignments to work on in the Upcoming ${
            showPastDue ? "or Past Due tabs" : "tab"
          } above.`}
          btnText={
            !showUpcoming && showPastDue
              ? "Work On Past Due Assignments"
              : "Check Out Upcoming"
          }
          btnLink={
            !showUpcoming && showPastDue
              ? `${REACT_APP_STUDENT_LINK}/${activeSection}/${SectionDataStatus["past-due"]}`
              : `${REACT_APP_STUDENT_LINK}/${activeSection}/${SectionDataStatus.upcoming}`
          }
        />
      )}
    </>
  );
}
