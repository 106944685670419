import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Learner } from "../../types";
import {
  ConditionalPasswordErrorMessages,
  evaluatePassword,
  REACT_APP_LEARNER_LINK,
} from "../../../utils";
import clsx from "clsx";
import Button from "../../../student/components/generic/button";
import { useMutation } from "react-query";
import axios from "axios";
import { deltamathAPI } from "../../../manager/utils";
import { withJsonHeader } from "../../../shared/axiosUtils";
import { processPasswordForRequest } from "../../../utils/processPasswordForRequest";
import { useNavigate } from "react-router-dom";
import { useLogin } from "../../../utils/auth/login";

interface FormInput {
  password: string;
}

export const AcceptInviteForm: React.FC<{ learner: Learner }> = ({
  learner,
}) => {
  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    getValues,
    formState: { errors },
  } = useForm<FormInput>({ mode: "onChange" });
  const login = useLogin();
  const [showPassword, setShowPassword] = useState(false);
  const [nonFieldError, setNonFieldError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const { mutateAsync: acceptInviteMutation } = useMutation(
    (data: FormInput) => {
      return axios.post(
        `${deltamathAPI()}/learner/default/invite/accept`,
        JSON.stringify({
          ...data,
          password: processPasswordForRequest(data.password),
          learnerId: learner._id,
        }),
        withJsonHeader()
      );
    }
  );

  const onSubmit: SubmitHandler<FormInput> = async (data) => {
    setNonFieldError(null);
    setIsLoading(true);
    try {
      await acceptInviteMutation(data);
      const { success } = await login(learner.email, data.password, "learner");
      if (success) {
        navigate(`${REACT_APP_LEARNER_LINK}/dashboard`);
      } else {
        setIsLoading(false);
        setNonFieldError("There was an error while logging in");
      }
    } catch (e: any) {
      setIsLoading(false);
      setNonFieldError(
        e.response?.data?.message ||
          e.message ||
          "An error occurred while accepting the invite"
      );
    }
  };

  return (
    <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col gap-1">
        <label className="text-sm font-bold" htmlFor="email">
          Email
        </label>
        <input
          className="rounded border border-dm-gray-200 bg-white px-3 py-2 text-dm-gray-200"
          id="email"
          value={learner.email}
          disabled
        />
      </div>

      <div className="flex flex-col gap-1">
        <label className="text-sm font-bold" htmlFor="password">
          Password
        </label>
        <div className="relative w-full">
          <input
            className="w-full rounded border border-dm-gray-200 px-3 py-2 invalid:border-dm-error-600"
            id="password"
            aria-invalid={!!errors.password}
            type={showPassword ? "text" : "password"}
            {...register("password", {
              required: "Password is required",
              validate: (v) =>
                // If invalid, return a random value so the error component re-renders
                !evaluatePassword(v) ? Math.random().toString() : true,
            })}
          />
          <button
            type="button"
            className="absolute right-2 top-1/2 -mt-3 flex h-6 w-6 items-center justify-center text-dm-gray-200"
            onClick={() => setShowPassword(!showPassword)}
          >
            <i
              className={clsx(showPassword ? "fas fa-eye-slash" : "far fa-eye")}
              aria-hidden
            />
            <span className="sr-only">Show password</span>
          </button>
        </div>
        {errors.password?.type === "validate" && (
          <ConditionalPasswordErrorMessages
            password={getValues("password") || ""}
          />
        )}
        {errors.password && errors.password.type !== "validate" && (
          <p className="text-sm text-dm-error-500">
            {errors.password?.message}
          </p>
        )}
      </div>
      <Button className="h-12" isLoading={isLoading}>
        Complete Sign Up
      </Button>
      {nonFieldError && (
        <p className="text-sm text-dm-error-500">{nonFieldError}</p>
      )}
    </form>
  );
};
