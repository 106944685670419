import { processChoices } from "./snakeGameProblemGenerator";
import { collatzStartingNums } from "./gameFunctions";

const { rand, randNotZero, $ } = window;

/** Which fraction is closest to __ or between ___ and ___
 * @return problem object with the following properties: questionPrompt (string with html), question?  (string), choices (array of 4 strings representing answer choices with the correct answer always at index 0), solution function to run
 */
export function collatz(currentValue) {
  const initialLaunch = currentValue === undefined;

  const previousValue = initialLaunch
    ? collatzStartingNums[rand(0, collatzStartingNums.length - 1)]
    : currentValue;

  const isEven = previousValue % 2 === 0;
  let choices = [];
  let correctAnswer, solutionStr;

  if (isEven) {
    correctAnswer = previousValue / 2;
    choices.push(previousValue * 3 + 1);
    solutionStr = `${previousValue} \\div 2 = ${correctAnswer}`;
  } else {
    correctAnswer = previousValue * 3 + 1;
    choices.push(previousValue * 3 - 1);
    solutionStr = `${previousValue} \\times 3 + 1 = ${correctAnswer}`;
  }

  if (correctAnswer < 11) {
    choices.push(
      correctAnswer + 1,
      correctAnswer + rand(2, 3),
      correctAnswer + 5,
      correctAnswer + 10
    );
    if (correctAnswer >= 2) choices.push(correctAnswer - 1);
    if (correctAnswer >= 4) choices.push(correctAnswer - rand(2, 3));
  } else {
    choices.push(
      correctAnswer + randNotZero(1, 1),
      correctAnswer + randNotZero(2, 3),
      correctAnswer + randNotZero(5, 5),
      correctAnswer + randNotZero(10, 10)
    );
  }
  if (correctAnswer > 100) choices.push(correctAnswer + randNotZero(20, 20));
  if (correctAnswer > 500) choices.push(correctAnswer + randNotZero(50, 50));
  if (correctAnswer > 1000) choices.push(correctAnswer + randNotZero(100, 100));

  choices.filter((choice) => choice > 0);
  choices = processChoices(choices, correctAnswer);
  if (choices.length !== 4) return collatz(currentValue);

  const solution = (id) => {
    $(id).html(`\\(${solutionStr}\\)`);
  };

  return {
    questionPrompt: initialLaunch ? "Starting number: " : "Current number: ",
    question: previousValue,
    choices,
    solution,
  };
}
