import { useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import clsx from "clsx";
import IconAssignmentProgress from "../../IconAssignmentProgress";
import { useLearnerContext } from "../../../contexts/LearnerContext";
import { useCourseContext } from "../../../contexts/CourseContext";
import {
  LearnerAssignmentSkillData,
  SubunitAssignmentTypes,
} from "../../../types";
import { Skeleton } from "../../Skeleton";
import { REACT_APP_LEARNER_LINK } from "../../../../utils";

type Props = {
  title: string;
  type: SubunitAssignmentTypes;
};

export default function CollapsedIcons({ type }: Props): JSX.Element {
  const [isHover, setIsHover] = useState<boolean>(false);

  const { coursePath, unitPath, subunitPath, assignmentType } = useParams();

  const learnerContext = useLearnerContext();
  const courseContext = useCourseContext();
  const courseData = courseContext.getCourseData(coursePath);
  const unitData = courseContext.getUnitData(unitPath, coursePath);
  const subunitData = courseContext.getSubunitData(
    subunitPath,
    unitPath,
    coursePath
  );

  if (
    !coursePath ||
    !unitPath ||
    !subunitPath ||
    !courseData ||
    !unitData ||
    !subunitData
  ) {
    return (
      <div className="h-12 w-12">
        <Skeleton />
      </div>
    );
  }

  const progresses = learnerContext.getProgress(courseData.id);

  const units = progresses?.units?.find((p) => p.unitId === unitData.id);
  const subunits = units?.subunits?.find((p) => p.subunitId === subunitData.id);

  const practiceComplete =
    subunits?.practice?.assignment?.skills &&
    subunits.practice.assignment.skills.every((s) => s.skillComplete === true);
  const isPreQuiz = type === "preQuiz";
  const isPractice = type === "practice";
  const isPostQuiz = type === "postQuiz";

  const isSubmitted =
    !!subunits?.[type]?.assignment?.submitted ||
    !!(isPractice && practiceComplete);

  const preQuiz100 = subunits?.preQuiz?.assignment?.grade === 1;

  if (subunits?.[type] === undefined) {
    if (isPreQuiz || (isPostQuiz && practiceComplete)) {
      return (
        <NavLink
          key={`assignment-${subunitData.id}-${type}`}
          to={`${REACT_APP_LEARNER_LINK}/${coursePath}/${unitPath}/${subunitPath}/${type.toLowerCase()}`}
          className={({ isActive }) =>
            clsx(
              isActive ? "bg-dm-brand-blue-100" : "",
              "group relative mb-1 inline-block rounded-md p-2 text-sm leading-none last:mb-0 hover:bg-dm-brand-blue-100"
            )
          }
          onPointerEnter={() => {
            if (!isHover) setIsHover(true);
          }}
          onPointerLeave={() => {
            if (isHover) setIsHover(false);
          }}
        >
          {({ isActive }) => (
            <IconAssignmentProgress
              type={type}
              progress={isPostQuiz && preQuiz100 ? 100 : 0}
              submitted={isPostQuiz && preQuiz100 ? true : false}
              isActive={isActive || isHover}
              isStarted={true}
            />
          )}
        </NavLink>
      );
    } else {
      return (
        <div
          className={clsx(
            "group relative mb-1 inline-block rounded-md p-2 text-sm leading-none last:mb-0",
            assignmentType === type.toLowerCase() && "bg-dm-brand-blue-100"
          )}
        >
          <IconAssignmentProgress
            type={type}
            progress={0}
            submitted={false}
            isStarted={false}
          />
        </div>
      );
    }
  }

  const skills: LearnerAssignmentSkillData[] | undefined =
    subunits?.[type]?.assignment?.skills;
  //TODO: fix for `Error: Rendered more hooks than during the previous render.`
  if (skills === undefined) {
    return <></>;
  }

  const progress = (subunits?.[type]?.assignment?.progress || 0) * 100;
  const maxGrade = (subunits?.[type]?.maxGrade || 0) * 100;

  return (
    <NavLink
      key={`assignment-${subunitData.id}-${type}`}
      to={`${REACT_APP_LEARNER_LINK}/${courseData.id}/${unitData.id}/${
        subunitData.id
      }/${type.toLowerCase()}`}
      className={({ isActive }) =>
        clsx(
          isActive ? "bg-dm-brand-blue-100" : "",
          "group relative mb-1 inline-block rounded-md p-2 text-sm leading-none last:mb-0 hover:bg-dm-brand-blue-100"
        )
      }
      onPointerEnter={() => {
        if (!isHover) setIsHover(true);
      }}
      onPointerLeave={() => {
        if (isHover) setIsHover(false);
      }}
    >
      {({ isActive }) => (
        <IconAssignmentProgress
          type={type}
          progress={progress}
          submitted={isSubmitted}
          isActive={isActive || isHover}
          maxGrade={maxGrade}
          isStarted={true}
        />
      )}
    </NavLink>
  );
}
