import { useCallback, useMemo, useContext, useEffect } from "react";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { Tab } from "@headlessui/react";
import { getActiveSectionData, getSectionData } from "../utils";
import StudentSectionsContext from "../_context/StudentSectionsContext";
import clsx from "clsx";
import { SectionDataStatus } from "../_constants";
import { useUserContext } from "../../shared/contexts/UserContext";

export default function Main(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    activeSection,
    dmSectionsData,
    dmAssignmentData,
    setCurrentProblemData,
    setShowPastDue,
    sectionsStatus,
    assignmentsIsFetched,
  } = useContext(StudentSectionsContext);
  const userContext = useUserContext();

  // Conditionally include the Past Due tab
  const pastDueAssignments = getSectionData(
    SectionDataStatus["past-due"],
    activeSection,
    dmAssignmentData
  );

  const previewAssignments = getSectionData(
    SectionDataStatus["preview"],
    activeSection,
    dmAssignmentData
  );

  /* Define tab data */
  const tabs = [
    ...(userContext.isSampleStudent() && previewAssignments?.length
      ? [
          {
            name: "Preview",
            param: SectionDataStatus["preview"],
            tabSectionData: getSectionData(
              SectionDataStatus["preview"],
              activeSection,
              dmAssignmentData
            ),
          },
        ]
      : []),
    {
      name: "Upcoming",
      param: SectionDataStatus.upcoming,
      tabSectionData: getSectionData(
        SectionDataStatus.upcoming,
        activeSection,
        dmAssignmentData
      ),
    },
    ...(pastDueAssignments?.length
      ? [
          {
            name: "Past Due",
            param: SectionDataStatus["past-due"],
            tabSectionData: pastDueAssignments,
          },
        ]
      : []),
    {
      name: "Complete",
      param: SectionDataStatus.completed,
      tabSectionData: getSectionData(
        SectionDataStatus.completed,
        activeSection,
        dmAssignmentData
      ),
    },
  ];

  /* Resets currentProblemData */
  useEffect(() => {
    setCurrentProblemData(undefined);
  }, []);

  /* Sets state for showing the Past Due tab (showPastDue is used for empty state copy) */
  useEffect(() => {
    if (pastDueAssignments?.length) setShowPastDue(true);
    else setShowPastDue(false);
  }, [activeSection, dmAssignmentData]);

  const tabIndex = useCallback(() => {
    let currentTab = tabs.findIndex((object) => {
      return location.pathname.includes(object.param);
    });
    if (currentTab < 0) currentTab = 0;
    return currentTab;
  }, [location, dmAssignmentData]);

  const onTabChange = (index: number): void => {
    if (tabs[index]) {
      navigate(`${activeSection}/${tabs[index].param}`);
    }
  };

  const sectionData: any = useMemo(
    () => getActiveSectionData(activeSection, dmSectionsData),
    [activeSection, dmSectionsData]
  );

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  return (
    <>
      <main
        id="main-content"
        role="main"
        className="flex-grow overflow-auto pb-8"
      >
        {/* Check if section endpoint has not yet been called (idle) or is in the process (loading) */}
        {["success", "error"].indexOf(sectionsStatus) === -1 ? (
          <div className="mx-4 pt-5 text-sm sm:mx-6">Loading sections...</div>
        ) : typeof activeSection === "undefined" &&
          dmSectionsData?.length === 0 ? (
          <div className="mx-4 mt-6 rounded-lg border border-dm-charcoal-100 bg-white px-5 py-16 text-center text-sm sm:mx-6">
            <i
              className="far fa-chalkboard mb-5 inline-block flex-shrink-0 text-center text-2xl text-dm-brand-blue-500"
              aria-hidden="true"
            ></i>
            <br />
            <span className="font-serif">
              You are not currently enrolled in any sections. You must be given
              a class code by your teacher and you can add that on the sidebar.
            </span>
          </div>
        ) : typeof activeSection !== "undefined" &&
          typeof sectionData === "undefined" &&
          /* Check if section endpoint has been called and returned a status (success or error) */
          ["success", "error"].indexOf(sectionsStatus) > -1 ? (
          <div className="mx-4 mt-6 rounded-lg border border-dm-charcoal-100 bg-white px-5 py-16 text-center text-sm sm:mx-6">
            <i
              className="far fa-chalkboard mb-5 inline-block flex-shrink-0 text-center text-2xl text-dm-brand-blue-500"
              aria-hidden="true"
            ></i>
            <br />
            <span className="font-serif">
              <strong>Error: </strong> Section id <em>{activeSection}</em>{" "}
              doesn&apos;t exist or you do not have access to it.
            </span>
          </div>
        ) : (
          <>
            <div className="flex min-w-0 flex-col gap-y-2 bg-white px-4 py-4 sm:px-6 sm:py-8">
              <h2 className="text-sm text-dm-charcoal-500">
                {sectionData?.teacherNames}
              </h2>
              <h1 className="font-serif text-lg font-bold leading-7 text-gray-900 sm:truncate sm:text-2xl">
                {sectionData?.name}
              </h1>
            </div>
            {/* Tabs */}
            <div className="block border-b border-dm-charcoal-100 bg-white">
              <Tab.Group
                selectedIndex={tabIndex()}
                onChange={onTabChange}
                defaultIndex={0}
              >
                <Tab.List
                  className={clsx(
                    "-mb-px flex gap-x-1.5 border-b border-dm-charcoal-100 px-2.5 sm:gap-x-16 sm:px-6",
                    !assignmentsIsFetched && "invisible"
                  )}
                >
                  {typeof activeSection !== "undefined" &&
                    typeof sectionData !== "undefined" &&
                    tabs.map((tab: any) => {
                      return (
                        <Tab
                          key={`tab-${tab.name}`}
                          className={({ selected }) =>
                            clsx(
                              "whitespace-nowrap border-b-4 px-2 py-2 text-sm focus:outline-none sm:px-4",
                              selected
                                ? "border-dm-brand-blue-500 font-bold text-dm-charcoal-800"
                                : "border-transparent text-dm-gray-500 hover:border-dm-charcoal-200 hover:text-dm-charcoal-800"
                            )
                          }
                        >
                          {({ selected }) => (
                            <>
                              {tab.name}
                              {tab.tabSectionData?.length ? (
                                <span
                                  className={clsx(
                                    "ml-2 rounded-lg px-1.5 py-0.5 text-xs font-normal sm:ml-3 sm:px-2.5",
                                    selected
                                      ? "bg-dm-brand-blue-500 text-white"
                                      : "bg-dm-charcoal-100 text-dm-charcoal-800"
                                  )}
                                  aria-label={`${
                                    tab.tabSectionData.length
                                  } task${
                                    tab.tabSectionData.length !== 1 ? "s" : ""
                                  }.`}
                                >
                                  {tab.tabSectionData.length}
                                </span>
                              ) : null}
                            </>
                          )}
                        </Tab>
                      );
                    })}
                </Tab.List>
              </Tab.Group>
            </div>
            <Outlet />
          </>
        )}
      </main>
      <ReactTooltip
        id="asstProgress"
        className="max-w-[80%] text-center"
        effect="solid"
        multiline={true}
        html={true}
        place="top"
        getContent={(dataTip) => dataTip}
      />
    </>
  );
}
