import React, { useContext, useState, ReactElement, useEffect } from "react";
import DMKeyboard, {
  AnswerData,
} from "../../student/components/calculator/DMKeyboard";
import { noop } from "lodash";
import { useParams } from "react-router-dom";
const MQ = (window as any).MQ;

export type MathField = any;

interface Focus {
  getMQ(): MathField | null;
  setMQ(element: Element): void;
  openKeyboard(visible: boolean): void;
  openCalculator(visible: boolean): void;
}

export class FocusHolder implements Focus {
  readonly state: MathField | null;
  readonly keyboardOpen: boolean;
  readonly calculatorOpen: boolean;
  private setState: (state: MathField) => void;
  private showKeyboard: (visible: boolean) => void;
  private showCalculator: (visible: boolean) => void;

  constructor(
    state: MathField | null,
    setState: (state: MathField) => void,
    showKeyboard: (visible: boolean) => void,
    keyboardOpen: boolean,
    calculatorOpen: boolean,
    showCalculator: (visible: boolean) => void
  ) {
    this.state = state;
    this.setState = setState;
    this.showKeyboard = showKeyboard;
    this.keyboardOpen = keyboardOpen;
    this.calculatorOpen = calculatorOpen;
    this.showCalculator = showCalculator;
  }

  openCalculator(visible: boolean): void {
    this.showCalculator(visible);
  }

  getMQ(): MathField | null {
    return this.state;
  }

  setMQ(element: Element): void {
    const mq = MQ(element);
    if (mq instanceof MQ.MathField && this.state?.id !== mq.id) {
      this.setState(mq);
    }
  }

  openKeyboard(visible: boolean): void {
    this.showKeyboard(visible);
  }

  // Not sure if this is needed in the future but it could be nice to have
  getCurrentFocusedInput(): HTMLElement | undefined {
    if (this.state && this.state.__controller?.container?.id) {
      return (
        document.getElementById(this.state.__controller?.container?.id) ||
        undefined
      );
    }
    return undefined;
  }
}

const FocusContext = React.createContext<FocusHolder>(
  new FocusHolder(null, noop, noop, false, false, noop)
);

export default FocusContext;

export function FocusContextProvider({ children }: { children: ReactElement }) {
  const { indexOfSkill } = useParams();

  const [state, setState] = useState<MathField>(null);
  const [showKeyboard, setShowKeyboard] = useState<boolean>(false);
  const [showCalculator, setShowCalculator] = useState<boolean>(false);
  const [answerData, setAnswerData] = useState<AnswerData>({
    latex: "",
    prevLatex: "",
    currentAnswer: "",
    currentAnswerFull: 0,
    prevAnswer: "",
    prevAnswerFull: 0,
  });

  const actualInput = state
    ? document.getElementById(state.__controller?.container?.id)
    : undefined;

  const handleGlobalFocus = (mqId: any) => {
    const span = document.getElementById(mqId);
    const mq = MQ(span);
    if (mq instanceof MQ.MathField && state?.id !== mq.id) {
      setState(mq);
    }
  };

  const mapForKeyboard = new Map();
  mapForKeyboard.set(String(actualInput?.id), state);

  useEffect(() => {
    if (showKeyboard && indexOfSkill === undefined) {
      setShowKeyboard(false);
    }
  }, [indexOfSkill]);

  return (
    <FocusContext.Provider
      value={
        new FocusHolder(
          state,
          setState,
          setShowKeyboard,
          showKeyboard,
          showCalculator,
          setShowCalculator
        )
      }
    >
      {showKeyboard && (
        <DMKeyboard
          close={() => setShowKeyboard(false)}
          focusedInput={actualInput?.id}
          input={mapForKeyboard}
          showCalculator={showCalculator}
          // previousAnswer={previousAnswer}
          // setPreviousAnswer={setPreviousAnswer}
          answerData={answerData}
          setAnswerData={setAnswerData}
          showKeyboard={showKeyboard}
          handleGlobalFocus={handleGlobalFocus}
          globalInputsMap={mapForKeyboard}
          learner
        />
      )}
      {children}
    </FocusContext.Provider>
  );
}

export function useFocusContext(): FocusHolder {
  return useContext(FocusContext);
}
