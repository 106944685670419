import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useParams, useNavigate, NavLink, Link } from "react-router-dom";
import { getStartEndDate } from "../StudentUsageUtils";
import slugify from "slugify";
import clsx from "clsx";
import { Transition } from "@headlessui/react";
import React from "react";
import { Column } from "react-table";
import { DmLoadingSpinner } from "../../../manager/utils/functions";
import DeltaMathTable, {
  customGradeSort,
  MultiCheckFilter,
} from "../../../shared/DeltaMathTable";
import { TextFilter, NoFilter } from "../../../shared/table/filters";
import { REACT_APP_ADMIN_LINK, formatNumber, useDMQuery } from "../../../utils";
import { demoString, DemoMode } from "../../../utils/demo";
import {
  GlobalStandardRequestInterface,
  PerformanceRequestType,
  parsePreferenceToFilter,
  skillCodeToStandardMap,
} from "../StudentPerformanceUtils";
import { SubStandard } from "../../../manager/components/standards/common/types";
import { DeltaMathSelect } from "../../../shared/DeltaMathSelect";
import SelectStandard from "./SelectStandard";
import { setIdsArray } from "../StudentUsageUtils";
import { toLower } from "lodash";
import BackArrowIcon from "../../../shared/icons/BackArrowIcon";
import { DisplayPerformanceGraphs } from "./DisplayPerformanceGraphs";
import DisplayGlobalPerformanceGraph from "./DisplayGlobalPerformanceGraph";
import { CondensedCell } from "../../../shared/table/cells";

export const filterOptions = [
  { key: "all", val: "All assignments and tests" },
  { key: "tests", val: "Tests only" },
  { key: "pushed-assignments", val: "Pushed assignments only" },
  // { key: "standard", val: "Standard" },
];

const StudentPerformanceContainer = ({
  schoolsWithIntegral,
}: {
  schoolsWithIntegral: any;
}) => {
  const params = useParams();
  const navigate = useNavigate();
  const {
    yesterday,
    today,
    yearToDate,
    lastYearToDate,
    lastYearEnd,
    currentkey,
    lastKey,
  } = getStartEndDate();

  /** initialize user preference to "All"*/
  const temp = {
    tempPreferences: {
      performanceViewPref: {
        fetchTests: false,
        fetchPushed: false,
        fetchStandard: false,
      },
    },
  };
  let tempPreferences = JSON.parse(
    localStorage.getItem("tempPreferences") || "{}"
  );
  Object.hasOwn(tempPreferences, "fetchPushed") === false
    ? (tempPreferences = temp)
    : null;

  const yearOptions = [
    {
      val: `Year Beginning 8/${yearToDate.getFullYear().toString(10).slice(2)}`,
      key: currentkey,
      startDate: yearToDate,
      endDate: today,
    },
    {
      val: `Year Beginning 8/${lastYearToDate
        .getFullYear()
        .toString(10)
        .slice(2)}`,
      key: lastKey,
      startDate: lastYearToDate,
      endDate: lastYearEnd,
    },
  ];

  /** Student Perforamance state variable, required across
   * "/select-scope", "/select-standard", and "/view-data"
   * */
  const [startDate, setStartDate] = useState<Date | null>(yearToDate);
  const [endDate, setEndDate] = useState<Date | null>(today);
  const [dateRange, setDateRange] = useState<string>("year-to-date");
  const [userStateCode, setUserStateCode] = useState<string>();
  const [selectedYear, setSelectedYear] = useState<string>(yearOptions[0].val);
  const [getCourse, setCourse] = useState<string>("");
  const [getActivated, setActivated] = useState("");
  const [selectedDataScope, setSelectedDataScope] = useState<string>("Schools");
  const [selectedSchools, setSelectedSchools] = useState<any>({});
  const [selectedTeachers, setSelectedTeachers] = useState<any>({});
  const [selectedSections, setSelectedSections] = useState<any>({});
  const [selectedStandard, setSelectedStandard] = useState<SubStandard>();
  const [selectCourseOptions, setSelectCourseOptions] = useState<any>();
  const [performanceParams, setPerformanceParams] =
    useState<PerformanceRequestType>({ series: [], skillcodes: [] });
  const [globalRequestParams, setGlobalRequestParams] =
    useState<GlobalStandardRequestInterface>();
  // on load, filterValue should be initialized to user default preference if one exists in localStorage
  const [filterValue, setFilterValue] = useState<string>(
    tempPreferences?.performanceViewPref &&
      Object.keys(tempPreferences?.performanceViewPref).length > 1
      ? parsePreferenceToFilter(tempPreferences.performanceViewPref).val
      : "All"
  );
  const [skillcodes, setSkillcodes] = useState<string[]>([]);

  /** fetch data */
  const { data: teacherData } = useDMQuery({
    path: "/admin_new/data/teacher",
    queryOptions: {
      staleTime: 1000 * 60 * 15,
    },
  });

  const { data: sectionData } = useDMQuery({
    path: "/admin_new/data/sections",
    queryOptions: {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 15,
    },
  });

  const { refetch: refetchSchoolData } = useDMQuery({
    path: "/admin_new/data/school",
    queryOptions: {
      enabled: true,
      staleTime: 1000 * 60 * 15,
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      onSuccess: (data: { address: { state: string } }[]) => {
        if (!userStateCode) {
          if (data[0].address?.state) {
            setUserStateCode(data[0].address.state);
          } else {
            const user = JSON.parse(localStorage.getItem("user") || "{}");
            const userStateCode: string = user?.schoolinfo?.state;
            setUserStateCode(userStateCode);
          }
        }
      },
    },
  });

  const { refetch: refetchDistrict } = useDMQuery({
    path: "/admin_new/data/district",
    queryOptions: {
      enabled: true,
      staleTime: 1000 * 60 * 15,
      onSuccess: (data: { address: { state: string } }) => {
        if (data?.address?.state) {
          setUserStateCode(data.address.state);
        } else {
          refetchSchoolData();
        }
      },
    },
  });

  const { data: standardData, refetch: refetchStandardData } = useDMQuery({
    /** NEW ENDPOINT (does not return anything on dev) */
    path: `/standards/${getCourse}`,
    queryOptions: {
      enabled: false,
      staleTime: 1000 * 60 * 15,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  });

  const { data: allStandards, refetch: refetchAllStandards } = useDMQuery({
    /** new standards endpoint  */
    path: `/standards/code/${userStateCode}`,
    queryOptions: {
      enabled: false,
      staleTime: 1000 * 60 * 15,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  });

  const { data: apStandardData, refetch: refetchApStandardData } = useDMQuery({
    /** new standards endpoint  */
    path: `/standards/code/AP`,
    queryOptions: {
      enabled: false,
      staleTime: 1000 * 60 * 15,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  });

  useEffect(() => {
    /** this shouldn't actually do anything */
    refetchDistrict();
  }, []);

  useEffect(() => {
    if (!params.page) {
      navigate(
        `${REACT_APP_ADMIN_LINK}/reports/student-performance/select-scope`
      );
    }
    if (params.page === "select-scope" && !params.tab) {
      navigate(
        `${REACT_APP_ADMIN_LINK}/reports/student-performance/select-scope/schools`
      );
    }
  }, [params]);

  useEffect(() => {
    /** call standards endpoint when user state code has loaded */
    refetchApStandardData();
    if (userStateCode) {
      refetchAllStandards();
    }
  }, [userStateCode]);

  useEffect(() => {
    /** set standards options for user based on /standards/:code response,
     * fetch individual standard data for the default standard selection
     */
    const options = [];
    if (allStandards && allStandards.length) {
      const basicOptions = allStandards.map(
        (option: { name: string; _id: string }) => ({
          key: option._id,
          val: option.name,
        })
      );
      options.push(...basicOptions);
    }
    if (apStandardData && apStandardData.length) {
      const apOptions = apStandardData.map(
        (option: { name: string; _id: string }) => ({
          key: option._id,
          val: option.name,
        })
      );
      options.push(...apOptions);
    }
    if (options.length > 0) {
      setSelectCourseOptions(options);
      options.length > 0 && setCourse(options[0].key);
      refetchStandardData();
    }
  }, [allStandards, apStandardData]);

  useEffect(() => {
    /** because we are fetching data for standards (courses) individually via their _id,
     * refetch standard info every time a new "Course" option is selected
     * */
    if (getCourse) {
      refetchStandardData();
    }
  }, [getCourse]);

  /** handler functions */
  /**
   *  parses selected data scope and sets request parameters for a standard or skillcodes
   * @param param0 {
   *  dataScope: current selectedDataScope (might not be necessary)
   *  currentParams: any existing params (i.e. fetchTest, fetchPushed) that should persist
   *  requestObject: unique request fields (not shared between requests)
   *  setterFn: setState function to update request params,
   * }
   */
  const updateRequestParams = ({
    dataScope,
    currentParams,
    requestObject,
    setterFn,
  }: {
    dataScope: string;
    requestObject?: any;
    currentParams: any;
    setterFn: (value: any) => void;
  }) => {
    if (dataScope === "Schools" && selectedSchools && schoolsWithIntegral) {
      const selectedSchoolIds = setIdsArray(
        selectedSchools,
        schoolsWithIntegral,
        ["schoolid"]
      ).map((school) => school.schoolid);
      setterFn({
        ...currentParams,
        ...requestObject,
        teacherIds: undefined,
        sectionIds: undefined,
        schoolIds: selectedSchoolIds,
        series: selectedSchoolIds.length ? selectedSchoolIds : null,
      });
    } else if (selectedDataScope === "Teachers" && selectedTeachers) {
      const selectedTeacherCodes = setIdsArray(selectedTeachers, teacherData, [
        "_id",
      ]).map((teacher) => teacher._id);
      setterFn({
        ...currentParams,
        ...requestObject,
        schoolIds: undefined,
        sectionIds: undefined,
        teacherIds: selectedTeacherCodes,
        series: selectedTeacherCodes.length ? selectedTeacherCodes : null,
      });
    } else if (selectedDataScope === "Sections" && selectedSections) {
      const selectedSectionIds = setIdsArray(selectedSections, sectionData, [
        "sectionId",
      ]).map((section) => section.sectionId);
      setterFn({
        ...currentParams,
        ...requestObject,
        schoolIds: undefined,
        teacherIds: undefined,
        sectionIds: selectedSectionIds,
        series: selectedSectionIds.length ? selectedSectionIds : null,
      });
    }
  };
  const selectCourse = (value: string) => {
    setCourse(value);
  };

  const selectStandardFn = (skillcodes: string[]) => {
    if (skillcodes.length > 0) {
      setSkillcodes(skillcodes);
    }
  };

  const selectStandard = (
    e: React.MouseEvent<HTMLDivElement>,
    standard: any
  ) => {
    const standardEl: HTMLDivElement | null =
      e.currentTarget.querySelector(".standard-descrip");
    if (standardEl === null) {
      return;
    }
    if (getActivated === standardEl.id && selectedStandard === standard) {
      setActivated("");
      setSelectedStandard(undefined);
    } else {
      setActivated(standardEl.id);
      setSelectedStandard(standard);
    }
  };

  const handleViewAllStandards = () => {
    const skillsToStandard =
      getCourse && standardData && skillCodeToStandardMap(standardData);
    setSelectedStandard(standardData);
    const req = {
      code: userStateCode,
      skillCodesToStandards: skillsToStandard,
      start: startDate && `${startDate?.getTime() / 1000}`,
      end: endDate && `${endDate?.getTime() / 1000}`,
      // DEV time span
      // start: "1577836800",
      // end: "1672531200",
    };
    updateRequestParams({
      dataScope: selectedDataScope,
      requestObject: req,
      currentParams: globalRequestParams,
      setterFn: setGlobalRequestParams,
    });
    if (skillsToStandard && selectedDataScope) {
      navigate(
        `${REACT_APP_ADMIN_LINK}/reports/student-performance/view-global-report`,
        {
          replace: true,
        }
      );
    }
  };

  const getSelectedInfo = (tab: string) => {
    let count = undefined;
    if (tab === "Sections") {
      count = Object.keys(selectedSections).length ?? undefined;
      if (count === sectionData.length) {
        count = "All";
      }
    } else if (tab === "Teachers") {
      count = Object.keys(selectedTeachers).length ?? undefined;
      if (count === teacherData.length) {
        count = "All";
      }
    } else if (tab === "Schools") {
      count = Object.keys(selectedSchools).length ?? undefined;
      if (count === schoolsWithIntegral.length) {
        count = "All";
      }
    }
    return count;
  };

  // console.log({ allStandards, selectCourseOptions, standardData });

  const FormattedCurrentSelection = () => {
    return (
      <div className="flex flex-row space-x-2 py-2">
        <span className="border-r-2 pr-2">{`${getSelectedInfo(
          selectedDataScope
        )} ${selectedDataScope} Selected`}</span>
        <span>{selectedYear}</span>
      </div>
    );
  };

  const handleGenerate = () => {
    updateRequestParams({
      dataScope: selectedDataScope,
      requestObject: {
        start: startDate && startDate?.getTime() / 1000,
        end: endDate && endDate?.getTime() / 1000,
        skillcodes: [...skillcodes],
      },
      currentParams: performanceParams,
      setterFn: setPerformanceParams,
    });
    navigate(`${REACT_APP_ADMIN_LINK}/reports/student-performance/view-report`);
  };

  return (
    <>
      {params.page === "select-scope" && (
        <DataScope
          selectedDataScope={selectedDataScope}
          setSelectedDataScope={setSelectedDataScope}
          schoolsWithIntegral={schoolsWithIntegral}
          selectedSchools={selectedSchools}
          setSelectedSchools={setSelectedSchools}
          selectedSections={selectedSections}
          setSelectedSections={setSelectedSections}
          selectedTeachers={selectedTeachers}
          setSelectedTeachers={setSelectedTeachers}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          dateRange={dateRange}
          setDateRange={setDateRange}
          yearOptions={yearOptions}
          selectedYear={selectedYear}
          setSelectedYear={setSelectedYear}
        />
      )}
      {params.page === "select-standard" && (
        <>
          <div id="report-header" className="border border-b-2 bg-white px-6">
            <Link
              to={`${REACT_APP_ADMIN_LINK}/reports/student-performance/select-scope/${toLower(
                selectedDataScope
              )}`}
              className="align-center inline-flex py-4 font-bold"
            >
              <BackArrowIcon classes="mt-1.5" />
              <span className="pl-2 text-dm-brand-blue-500">
                Back to Data Scope
              </span>
            </Link>
            <h2 className="mb-4 font-serif text-2xl font-bold leading-10 text-neutral-900">
              Standards
            </h2>
            <h4 className="text-md font-sans text-base font-bold leading-relaxed text-neutral-900">
              Select the Standard
            </h4>
            <div className="flex flex-row justify-between pb-4">
              <FormattedCurrentSelection />
              <div>
                <button
                  onClick={handleGenerate}
                  disabled={selectedStandard ? false : true}
                  className="justify-center rounded-md border border-transparent bg-dm-brand-blue-500 px-8 py-2 text-sm font-bold text-white shadow-sm hover:bg-dm-brand-blue-500 focus:outline-none focus:ring-2 focus:ring-dm-brand-blue-500 focus:ring-offset-2 disabled:opacity-50"
                >
                  Generate Report
                </button>
              </div>
            </div>
          </div>
          {allStandards && selectCourseOptions && (
            <div className="flex flex-row p-6">
              <div className="sm:w-full lg:w-72">
                <DeltaMathSelect
                  label="Select Course"
                  options={selectCourseOptions}
                  onChangeFn={selectCourse}
                  value={getCourse}
                />
              </div>
              {standardData && (
                <button
                  onClick={handleViewAllStandards}
                  disabled={!standardData}
                  className="ml-2 mt-6 rounded-md border border-transparent bg-dm-brand-blue-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  View All Standards for this Course
                </button>
              )}
            </div>
          )}
          <Transition
            as="div"
            show={true}
            appear={true}
            enter="transition-opacity duration-300"
            enterFrom="transform opacity-0"
            enterTo="transform opacity-100"
            leave="transition-opacity duration-300"
            leaveFrom="transform opacity-100"
            leaveTo="transform opacity-0"
          >
            {getCourse &&
              userStateCode &&
              standardData &&
              Array.isArray(standardData.order) &&
              standardData?.order?.map(
                (item: string) =>
                  standardData?.data &&
                  standardData?.data[item] && (
                    <SelectStandard
                      key={item}
                      standard={standardData.data[item]}
                      depth={1}
                      path="data."
                      activatedPath={getActivated}
                      rules={[]}
                      stateCode={userStateCode}
                      onClickFn={selectStandard}
                      selectFn={selectStandardFn}
                      closeFn={() => null}
                    />
                  )
              )}
          </Transition>
        </>
      )}
      {params.page === "view-report" && selectCourseOptions && (
        <DisplayPerformanceGraphs
          dataScope={selectedDataScope}
          requestParams={performanceParams}
          setRequestParams={setPerformanceParams}
          filterValue={filterValue}
          setFilterValue={setFilterValue}
          standard={`${
            selectCourseOptions.find((option: any) => option.key === getCourse)
              .val
          } - ${selectedStandard?.label}`}
          FormattedSelection={() => <FormattedCurrentSelection />}
          isGlobal={false}
          standardDescription={selectedStandard?.description}
        />
      )}
      {params.page === "view-global-report" && startDate && endDate && (
        <DisplayGlobalPerformanceGraph
          dataScope={selectedDataScope}
          requestParams={globalRequestParams}
          selectedStandard={selectedStandard}
          setRequestParams={setGlobalRequestParams}
          filterValue={filterValue}
          setFilterValue={setFilterValue}
          startDate={startDate?.getTime() / 1000 || 0}
          endDate={endDate?.getTime() / 1000 || 0}
          metaString={`${getSelectedInfo(
            selectedDataScope
          )} ${selectedDataScope} Selected`}
          selectedYear={selectedYear}
        />
      )}
    </>
  );
};

// const StandardsAccordion = () => {};

export default StudentPerformanceContainer;

const DataScope = ({
  selectedDataScope,
  setSelectedDataScope,
  schoolsWithIntegral,
  selectedSchools,
  setSelectedSchools,
  selectedSections,
  setSelectedSections,
  selectedTeachers,
  setSelectedTeachers,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  dateRange,
  setDateRange,
  yearOptions,
  selectedYear,
  setSelectedYear,
}: {
  selectedDataScope: string;
  setSelectedDataScope: Dispatch<SetStateAction<string>>;
  schoolsWithIntegral: any;
  selectedSchools?: any;
  setSelectedSchools?: Dispatch<SetStateAction<any>>;
  selectedSections?: any;
  setSelectedSections?: Dispatch<SetStateAction<any>>;
  selectedTeachers?: any;
  setSelectedTeachers?: Dispatch<SetStateAction<any>>;
  startDate: Date | null;
  setStartDate: Dispatch<SetStateAction<Date | null>>;
  endDate: Date | null;
  setEndDate: Dispatch<SetStateAction<Date | null>>;
  dateRange: string;
  setDateRange: Dispatch<SetStateAction<string>>;
  yearOptions: any;
  selectedYear: any;
  setSelectedYear: any;
}) => {
  const params = useParams();
  const scopeTabs = ["Schools", "Teachers", "Sections"];

  return (
    <>
      <div className="border border-b-2 bg-white">
        <div className="p-6">
          <h2 className="mb-4 font-serif text-2xl font-bold leading-10 text-dm-charcoal-800">
            Student Performance Reports
          </h2>
          <p className="font-serif text-lg font-bold leading-relaxed text-dm-charcoal-500">
            Select Data Scope
          </p>
        </div>
        <nav
          className={
            "-mb-px flex justify-between px-6 sm:justify-normal sm:space-x-14"
          }
          aria-label="Tabs"
        >
          {scopeTabs.map((tab) => (
            <NavLink
              key={tab}
              to={`${REACT_APP_ADMIN_LINK}/reports/student-performance/select-scope/${slugify(
                tab,
                {
                  lower: true,
                }
              )}`}
              onClick={() => setSelectedDataScope(tab)}
              replace
              className={clsx(
                slugify(tab, { lower: true }) === params.tab
                  ? "border-dm-brand-blue-500 font-bold"
                  : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",

                "border-b-4 px-1 pb-4 text-center text-sm"
              )}
              aria-current={
                slugify(tab, { lower: true }) === params.tab
                  ? "page"
                  : undefined
              }
            >
              <span className="px-2">{tab}</span>
              {/* {getSelectedCount(tab)} */}
            </NavLink>
          ))}
        </nav>
      </div>
      <div id="scope-body" className="p-6">
        <div className="flex justify-between">
          <DeltaMathSelect
            options={yearOptions}
            label=""
            defaultVal={selectedYear}
            onChangeFn={(value) => {
              setStartDate(
                yearOptions.find(
                  (option: {
                    val: string;
                    key: string;
                    start: Date;
                    end: Date;
                  }) => option.key === value
                )?.startDate
              );
              setEndDate(
                yearOptions.find(
                  (option: {
                    val: string;
                    key: string;
                    start: Date;
                    end: Date;
                  }) => option.key === value
                )?.endDate
              );
              setSelectedYear(value);
            }}
            value={selectedYear}
          />

          <Link
            to={`${REACT_APP_ADMIN_LINK}/reports/student-performance/select-standard`}
            replace
            className="justify-center rounded-md border border-transparent bg-dm-brand-blue-500 px-8 py-2 text-sm font-bold text-white shadow-sm hover:bg-dm-brand-blue-500 focus:outline-none focus:ring-2 focus:ring-dm-brand-blue-500 focus:ring-offset-2 disabled:opacity-50"
          >
            Next: Select Standard
          </Link>
        </div>

        <div id="select-data-scope" className="mt-8">
          <SelectDataScope
            schoolsWithIntegral={schoolsWithIntegral}
            selectedTeachers={selectedTeachers}
            setSelectedTeachers={setSelectedTeachers}
            selectedDataScope={selectedDataScope}
            setSelectedDataScope={setSelectedDataScope}
            selectedSchools={selectedSchools}
            setSelectedSchools={setSelectedSchools}
            selectedSections={selectedSections}
            setSelectedSections={setSelectedSections}
          />
        </div>
      </div>
    </>
  );
};

const SelectDataScope = ({
  schoolsWithIntegral,
  selectedDataScope,
  selectedSchools,
  setSelectedSchools,
  selectedSections,
  setSelectedSections,
  selectedTeachers,
  setSelectedTeachers,
}: {
  schoolsWithIntegral: any;
  selectedDataScope: string;
  setSelectedDataScope: any;
  selectedSchools?: any;
  setSelectedSchools?: any;
  selectedSections?: any;
  setSelectedSections?: any;
  selectedTeachers?: any;
  setSelectedTeachers?: any;
}) => {
  const params = useParams();
  const [preSelectedSchools, setPreselectedSchools] =
    React.useState<Record<string, any>>(selectedSchools);
  const [preSelectedTeachers, setPreselectedTeachers] =
    React.useState<Record<string, any>>(selectedTeachers);
  const [preSelectedSections, setPreselectedSections] =
    React.useState<Record<string, any>>(selectedSections);
  const [teacherTableData, setTeacherTableData] = React.useState();
  const [sectionsTableData, setSectionsTableData] = React.useState();
  const [schoolTableData, setSchoolTableData] = React.useState<any>();
  const [schoolsCount, setSchoolsCount] = React.useState<number>(0);
  const [teacherColumns, setTeacherColumns] = React.useState<Column[]>();
  const [sectionColumns, setSectionColumns] = React.useState<Column[]>();

  function getAssignmentsCount(teacher_id: number) {
    return teacherCountsData &&
      typeof teacherCountsData[teacher_id]?.assignments != "undefined"
      ? formatNumber(parseInt(teacherCountsData[teacher_id]?.assignments))
      : 0;
  }

  useEffect(() => {
    if (Object.keys(selectedSchools).length > 0) {
      setPreselectedSchools(selectedSchools);
    } else if (schoolTableData) {
      const allSchools = Object.create({});
      for (let i = 0; i < schoolTableData.length; i++) {
        allSchools[i] = true;
      }
      setPreselectedSchools(allSchools);
    }
  }, [schoolTableData]);

  const { data: teacherData, isLoading: teacherDataLoading } = useDMQuery({
    path: "/admin_new/data/teacher",
    queryOptions: {
      staleTime: 1000 * 60 * 15,
    },
  });
  // if there is only one teacher -> pre-select teacher in table
  useEffect(() => {
    if (Object.keys(selectedTeachers).length > 0) {
      setPreselectedTeachers(selectedTeachers);
    }
    if (teacherData && teacherData?.length === 1) {
      setPreselectedTeachers({ 0: true });
    }
  }, [teacherData, selectedTeachers]);

  const { data: teacherCountsData, isLoading: teacherCountsDataLoading } =
    useDMQuery({
      path: "/admin_new/data/teacherCounts",
      queryOptions: {
        staleTime: 1000 * 60 * 15,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
      },
    });

  useEffect(() => {
    const newTeachData: any = [];
    if (Array.isArray(teacherData) && Array.isArray(schoolsWithIntegral)) {
      teacherData.forEach((teacher: any) => {
        const school = schoolsWithIntegral.find(
          (school) =>
            // not all schoolids are in the 0th index of schoolPlus[], so get the first id that is not a district id
            teacher.schoolPlus.find((id: string) => id.length > 7) ===
            school.schoolid
        );
        // only show integral
        if (school) {
          newTeachData.push({
            first: demoString({ value: teacher.first, type: "person_first" }),
            last: demoString({ value: teacher.last, type: "person_first" }),
            school:
              school &&
              demoString({ value: school.schoolName, type: "school_name" }),
            assignments: getAssignmentsCount(teacher._id),
          });
        }
      });
    }
    setTeacherTableData(newTeachData);
  }, [teacherData, schoolsWithIntegral, teacherCountsData]);

  useEffect(() => {
    if (
      schoolsWithIntegral &&
      Array.isArray(schoolsWithIntegral) &&
      teacherCountsData
    ) {
      const theSchoolCounts:
        | ((prevState: never[]) => never[])
        | {
            schoolid: string;
            schoolName: string;
            lowGrade: string;
            highGrade: string;
            theSchoolStudentsCount: number;
          }[] = [];
      schoolsWithIntegral.map(
        (school: {
          schoolid: string;
          schoolName: string;
          lowGrade: string;
          highGrade: string;
          dmLicense: { has_integral: any };
        }) => {
          let theSchoolStudentsCount = 0;
          if (Array.isArray(teacherData) && teacherData.length > 0) {
            teacherData.map(
              (teacher: {
                schoolid: string;
                schoolPlus: string[];
                _id: string;
                admin: string;
                annual_logs: number;
              }) => {
                if (teacher.schoolPlus.includes(school.schoolid)) {
                  const studentCount = getStudentCount(parseInt(teacher._id));
                  theSchoolStudentsCount += studentCount;
                }
              }
            );
          }
          theSchoolCounts.push({
            schoolid: school.schoolid,
            schoolName: demoString({
              value: school.schoolName,
              type: "school_name",
            }),
            lowGrade: school.lowGrade,
            highGrade: school.highGrade,
            theSchoolStudentsCount,
          });
        }
      );
      setSchoolsCount(theSchoolCounts.length);
      setSchoolTableData(theSchoolCounts);
    }
  }, [schoolsWithIntegral, teacherData, teacherCountsData]);

  function getStudentCount(teacher_id: number) {
    return teacherCountsData &&
      teacherCountsData[teacher_id] &&
      typeof teacherCountsData[teacher_id].students != "undefined"
      ? parseInt(teacherCountsData[teacher_id].students)
      : 0;
  }

  const { data: sectionsData, isLoading: sectionsDataLoading } = useDMQuery({
    path: "/admin_new/data/sections",
    queryOptions: {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 15,
    },
  });

  // if there is only one section -> pre-select section in table
  useEffect(() => {
    if (Object.keys(selectedSections).length > 0) {
      setPreselectedSections(selectedSections);
    } else if (sectionsData) {
      if (sectionsData.length === 1) {
        setPreselectedSections({ 0: true });
      } else setPreselectedSections({});
    }
  }, [sectionsData, selectedSections]);

  useEffect(() => {
    const newSectionsData: any = [];
    if (Array.isArray(sectionsData) && Array.isArray(schoolsWithIntegral)) {
      sectionsData.map((section: any) => {
        const school = schoolsWithIntegral.find(
          (school) =>
            // not all schoolids are in the 0th index of schoolPlus[], so get the first id that is not a district id
            section.schools.find((id: string) => id.length > 7) ===
            school.schoolid
        );

        const teacherArray: string[] = [];
        section.teachers.length &&
          section.teachers.forEach((teacher: { _id: number; name: string }) => {
            if (!teacherArray.includes(teacher.name)) {
              teacherArray.push(teacher.name);
            }
          });

        // mostly a check in case there is no "logsPerYear" value, which would throw an error when we access it
        const yearsWeHaveLogs =
          section["logsPerYear"] && Object.keys(section["logsPerYear"]);
        const totalLogs = 0;
        if (school) {
          newSectionsData.push({
            sectionName: demoString({
              value: section.sectionName,
              type: "section_name",
            }),
            teachers:
              teacherArray.length > 0
                ? teacherArray
                    .map((teacher: string) =>
                      demoString({ value: teacher, type: "person_full" })
                    )
                    .join(", ")
                : "-",
            school:
              school &&
              demoString({ value: school.schoolName, type: "school_name" }),
            // aggregate all logs from all years
            logs: Array.isArray(yearsWeHaveLogs)
              ? yearsWeHaveLogs.reduce(
                  (acc: number, year: any) => acc + section.logsPerYear[year],
                  totalLogs
                )
              : 0,
          });
        }
        setSectionsTableData(newSectionsData);
      });
      setSectionsTableData(newSectionsData);
    }
  }, [sectionsData, schoolsWithIntegral]);

  useEffect(() => {
    if (Array.isArray(schoolTableData)) {
      for (let i = 0; i < schoolTableData?.length; i++) {
        schoolTableData[i].schoolName = demoString({
          value: schoolTableData[i].schoolName,
          type: "school_name",
        });
      }
      setSchoolTableData(schoolTableData);
    }
  }, [schoolTableData]);

  /** initialize user preference*/
  const temp = {
    usagePreferences: {
      usageReportDataScope: {
        Schools: {},
        Teachers: {},
        Sections: {},
      },
    },
  };
  let tempPreferences = JSON.parse(
    localStorage.getItem("usagePreferences") || "{}"
  );
  Object.hasOwn(tempPreferences, "fetchPushed") === false
    ? (tempPreferences = temp)
    : null;
  useEffect(() => {
    if (selectedDataScope === "Schools") {
      temp.usagePreferences.usageReportDataScope = {
        Schools: {
          ...selectedSchools,
        },
        Teachers: {},
        Sections: {},
      };
    } else if (selectedDataScope === "Teachers") {
      temp.usagePreferences.usageReportDataScope = {
        Schools: {},
        Teachers: { ...selectedTeachers },
        Sections: {},
      };
    } else if (selectedDataScope === "Sections") {
      temp.usagePreferences.usageReportDataScope = {
        Schools: {},
        Teachers: {},
        Sections: { ...selectedSections },
      };
    }
    tempPreferences = temp;

    /** update localStorage.usagePreferences.usageReportDataScope when a new selection is made */
    localStorage.setItem(
      "usagePreferences",
      JSON.stringify({
        ...tempPreferences,
      })
    );
  }, [selectedSchools, selectedSections, selectedTeachers]);

  const schoolColumns = React.useMemo(
    () => [
      {
        Header: "School Name",
        accessor: "schoolName",
        Filter: TextFilter,
        align: "left",
      },
      {
        Header: "Grade Range",
        accessor: "lowGrade",
        Cell: (props: any) => {
          return `${props.row.original.lowGrade} - ${props.row.original.highGrade}`;
        },
        align: "left",
        sortType: customGradeSort,
      },
      {
        Header: "",
        accessor: "highGrade",
      },
      {
        Header: "# of Students",
        accessor: "theSchoolStudentsCount",
        align: "left",
        sortDescFirst: true,
      },
    ],
    []
  );

  useEffect(() => {
    const teacherColumnsDef: Array<any> = [
      {
        Header: "First Name",
        accessor: "first",
        Filter: TextFilter,
        align: "left",
      },
      {
        Header: "Last Name",
        accessor: "last",
        Filter: TextFilter,
        align: "left",
      },
      {
        Header: "# of Assignments",
        accessor: "assignments",
        Filter: NoFilter,
        align: "left",
        sortDescFirst: true,
      },
    ];

    const sectionColumnsDef: Array<any> = [
      {
        Header: "Section Name",
        accessor: "sectionName",
        Filter: TextFilter,
        align: "left",
      },
      {
        Header: "Teachers",
        accessor: "teachers",
        Filter: TextFilter,
        align: "left",
        Cell: (props: { value: string }) => (
          <CondensedCell value={props.value} />
        ),
      },
      {
        Header: "Problems Solved",
        Cell: (props: any) => {
          return <div>{formatNumber(props.value)}</div>;
        },
        accessor: "logs",
        Filter: NoFilter,
        align: "left",
        sortDescFirst: true,
      },
    ];
    if (schoolsCount > 1) {
      // only show the column if there's more than 1 school
      teacherColumnsDef.splice(2, 0, {
        Header: "School",
        accessor: "school",
        Filter: MultiCheckFilter,
        filter: "includesSome",
        align: "left",
        Cell: (props: any) => {
          return (
            <DemoMode
              value={props.value}
              type="school_name"
              district_size={schoolsCount}
            />
          );
        },
        minWidth: 300,
      });
      sectionColumnsDef.splice(2, 0, {
        Header: "School",
        accessor: "school",
        Filter: MultiCheckFilter,
        filter: "includesSome",
        align: "left",
        minWidth: 300,
      });
    }
    setTeacherColumns(teacherColumnsDef);
    setSectionColumns(sectionColumnsDef);
  }, [schoolsCount]);

  return (
    <div>
      {(teacherDataLoading ||
        teacherCountsDataLoading ||
        sectionsDataLoading) && <DmLoadingSpinner message={"Loading..."} />}

      {params.tab === "schools" && schoolColumns && (
        <>
          {schoolsWithIntegral &&
            teacherData &&
            teacherCountsData &&
            sectionsData &&
            Array.isArray(schoolTableData) &&
            schoolTableData.length > 0 && (
              <div>
                <Transition
                  as="div"
                  show={true}
                  appear={true}
                  enter="transition-opacity duration-300"
                  enterFrom="transform opacity-0"
                  enterTo="transform opacity-100"
                  leave="transition-opacity duration-300"
                  leaveFrom="transform opacity-100"
                  leaveTo="transform opacity-0"
                >
                  <DeltaMathTable
                    columns={schoolColumns}
                    data={schoolTableData}
                    options={{
                      selectable: true,
                      showSelectAll: true,
                      preSelectedRows: preSelectedSchools,
                      initialState: {
                        hiddenColumns: ["highGrade"],
                        sortBy: [
                          {
                            id: "schoolName",
                            desc: false,
                          },
                        ],
                      },
                    }}
                    setSelectedRows={setSelectedSchools}
                  />
                </Transition>
              </div>
            )}
        </>
      )}
      {params.tab === "teachers" &&
        teacherColumns &&
        teacherCountsData &&
        selectedTeachers &&
        preSelectedTeachers && (
          <>
            {teacherTableData && (
              <Transition
                as="div"
                show={true}
                appear={true}
                enter="transition-opacity duration-300"
                enterFrom="transform opacity-0"
                enterTo="transform opacity-100"
                leave="transition-opacity duration-300"
                leaveFrom="transform opacity-100"
                leaveTo="transform opacity-0"
              >
                <DeltaMathTable
                  columns={teacherColumns}
                  data={teacherTableData}
                  options={{
                    selectable: true,
                    showSelectAll: true,
                    preSelectedRows: preSelectedTeachers,
                  }}
                  setSelectedRows={setSelectedTeachers}
                />
              </Transition>
            )}
          </>
        )}
      {params.tab === "sections" && (
        <>
          {sectionsTableData && sectionColumns && (
            <Transition
              as="div"
              show={true}
              appear={true}
              enter="transition-opacity duration-300"
              enterFrom="transform opacity-0"
              enterTo="transform opacity-100"
              leave="transition-opacity duration-300"
              leaveFrom="transform opacity-100"
              leaveTo="transform opacity-0"
            >
              <DeltaMathTable
                columns={sectionColumns}
                data={sectionsTableData}
                options={{
                  selectable: true,
                  showSelectAll: true,
                  preSelectedRows: preSelectedSections,
                }}
                setSelectedRows={setSelectedSections}
              />
            </Transition>
          )}
        </>
      )}
    </div>
  );
};
