import { useCallback, useEffect, useRef, useState } from "react";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import { useParams } from "react-router-dom";
import { REACT_APP_MANAGER_LINK, useDMQuery } from "../../../utils";
import SkillCodes from "../skill-codes/SkillCodes";
import Button from "../../../student/components/generic/button";
import { ArrowLeftIcon } from "@heroicons/react/outline";
import SkillBox from "./SkillBox";
import { compact, debounce } from "lodash";
import { useMutation } from "react-query";
import axios from "axios";
import { deltamathAPI } from "../../utils";
import { CourseType, Skill, Subunit, Unit } from "../../types";
import { ObjectId } from "bson";
import DraggableTable from "../../../shared/DraggableTable/DraggableTable";
import EditName from "../GenericCourseBuilder/EditName";
import { Switch } from "@headlessui/react";
import clsx from "clsx";

const CourseSubunit = () => {
  const [skills, setSkills] = useState<Skill[]>([]);
  const [showSaved, setShowSaved] = useState<boolean>(true);
  const [enabled, setEnabled] = useState(true);
  const [hidden, setHidden] = useState<boolean>(false);
  const updatingName = useRef<boolean>(false);

  const toastContext = useDeltaToastContext();
  const params = useParams();
  const subunitId = params.subunitId ?? "";

  const saveSubunit = useMutation(
    (body: string) => {
      return axios.post(
        `${deltamathAPI()}/manager_new/parent-portal/subunit/${subunitId}`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    },
    {
      onSuccess() {
        setShowSaved(true);
      },
      onError() {
        toastContext.addToast({
          status: "Error",
          message: "Issue saving subunit",
        });
      },
    }
  );

  const reorderSkills = useMutation(
    (body: string) => {
      return axios.post(
        `${deltamathAPI()}/manager_new/parent-portal/order_skills`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    },
    {
      onSuccess() {
        toastContext.addToast({
          status: "Success",
          message: "Order Updated",
        });
      },
      onError() {
        toastContext.addToast({
          status: "Error",
          message: "Issue updating order",
        });
      },
    }
  );

  const {
    data: data,
    refetch: refetch,
    status: status,
  } = useDMQuery<{
    subunit: Subunit;
    unit: Unit;
    course: CourseType;
  }>({
    path: `/manager_new/parent-portal/subunit/${subunitId}`,
    queryOptions: {
      enabled: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      // stale after 1 second
      staleTime: 1000,
      cacheTime: 0,
    },
  });

  const { data: unitData, refetch: unitRefetch } = useDMQuery<Unit>({
    path: `/manager_new/parent-portal/unit/${data?.subunit.unitId}`,
    queryOptions: {
      enabled: false,
    },
  });
  const unitVersion = unitData?.versionHistory.find(
    (vh) => vh.versionKey === data?.subunit.unitVersionKey
  );

  const { data: allSkillsInCourse, refetch: allSkillsRefetch } = useDMQuery<
    {
      sk: string;
      typeKeys: string[];
      unitId: string;
      unitVersion: string;
      unitVersionState: string;
      unitName: string;
      subunitId: string;
      subunitName: string;
    }[]
  >({
    path: `/manager_new/parent-portal/allskills/${data?.course?._id}?courseVersion=${data?.unit.courseVersionKey}&unitVersion=${data?.subunit.unitVersionKey}`,
    queryOptions: {
      enabled: false,
      staleTime: 1000,
      refetchOnWindowFocus: false,
    },
  });

  useEffect(() => {
    if (status === "success" && data) {
      unitRefetch();
      setSkills(data.subunit.skills);
      allSkillsRefetch();
    }
  }, [data, status]);

  useEffect(() => {
    if (subunitId) {
      refetch();
    }
  }, [subunitId]);

  const skillAdd = (name: string, skillCode: string) => {
    if (unitVersion?.state !== "draft") {
      toastContext.addToast({
        status: "Error",
        message: "Cannot add skills to a subunit in a published unit",
      });
      return;
    }
    setShowSaved(false);
    setSkills([
      ...skills,
      {
        // add in this bson id to be able to differentiate the skills from each other if there
        // are multiple of the same skill codes added
        _id: new ObjectId().toString(),
        skillName: name,
        skillCode: skillCode,
        difficulty: "both",
        typeKeys: [],
        testWeight: 1,
      },
    ]);
  };

  const removeSkill = (id: string) => {
    setSkills([...skills.filter((s) => s._id !== id)]);
    setShowSaved(false);
  };

  const updateName = (id: string, newName: string) => {
    const skill = skills.find((s) => s._id === id);
    if (!skill || skill.skillCode === newName) {
      return;
    }

    setShowSaved(false);
    setSkills([
      ...skills.map((s) => {
        if (s._id !== id) {
          return s;
        }
        return {
          ...s,
          skillName: newName,
        };
      }),
    ]);
  };

  const difficultyUpdate = (
    id: string,
    difficulty: "easy" | "hard" | "both"
  ) => {
    const skill = skills.find((s) => s._id === id);
    if (!skill || skill.difficulty === difficulty) {
      return;
    }

    setSkills([
      ...skills.map((s) => {
        if (s._id !== id) {
          return s;
        }
        return {
          ...s,
          difficulty: difficulty,
        };
      }),
    ]);
    setShowSaved(false);
  };

  const typeUpdate = (id: string, typeKey: string, add: boolean) => {
    setShowSaved(false);
    setSkills([
      ...skills.map((s) => {
        if (s._id !== id) {
          return s;
        }
        return {
          ...s,
          typeKeys: compact([
            ...s.typeKeys.filter((k) => {
              if (add) {
                return true;
              }
              return typeKey !== k;
            }),
            add ? typeKey : undefined,
          ]),
        };
      }),
    ]);
  };

  const addAllTypes = (id: string, typeKeys: string[]) => {
    setShowSaved(false);
    setSkills([
      ...skills.map((s) => {
        if (s._id !== id) {
          return s;
        }
        return {
          ...s,
          typeKeys: typeKeys,
        };
      }),
    ]);
  };

  const testWeightUpdate = (id: string, weight: number) => {
    const skill = skills.find((s) => s._id === id);
    if (!skill || skill.testWeight === weight) {
      return;
    }

    setShowSaved(false);
    setSkills([
      ...skills.map((s) => {
        if (s._id !== id) {
          return s;
        }

        return {
          ...s,
          testWeight: weight,
        };
      }),
    ]);
  };

  const saveFunction = (updatedSkills: Skill[]) => {
    const body = {
      skills: updatedSkills,
    };

    if (!updatingName.current) {
      saveSubunit.mutate(JSON.stringify(body));
    }
  };

  const debouncedSave = useCallback(
    debounce((updatedSkills: Skill[]) => saveFunction(updatedSkills), 5000),
    []
  );

  useEffect(() => {
    if (!showSaved) {
      debouncedSave(skills);
    }
  }, [skills, showSaved]);

  if (status !== "success") {
    return <>Loading...</>;
  }

  const columns = [
    {
      Header: "",
      accessor: "skill",
      align: "center",
      Cell: (props: any) => {
        return (
          <SkillBox
            skill={props.row.original}
            removeSkill={removeSkill}
            testWeightUpdate={testWeightUpdate}
            typeUpdate={typeUpdate}
            difficultyUpdate={difficultyUpdate}
            addAllTypes={addAllTypes}
            key={`skill-box-${props.row.original._id}`}
            updateName={updateName}
            allSkillsInCourse={allSkillsInCourse?.filter(
              (as) => as.subunitId !== subunitId
            )}
            deleteDisabled={unitVersion?.state !== "draft"}
            showMessage={enabled}
            updatingName={updatingName}
          />
        );
      },
    },
  ];

  const updateOrder = (data: Skill[]) => {
    const skillCodeOrder = data.map((d) => skills.find((s) => s._id === d._id));
    setSkills(compact(skillCodeOrder));

    const body = {
      subunitId: subunitId,
      skillCodes: skillCodeOrder.map((x) => x?.skillCode),
    };
    reorderSkills.mutate(JSON.stringify(body));
  };

  return (
    <div className="relative flex max-h-[calc(100vh-64px)] gap-4 bg-white px-4">
      <div className="flex min-w-min flex-col justify-between overflow-y-scroll">
        <div>
          <div className="sticky top-0 z-20 bg-white pb-4 shadow-xl">
            <Button
              href={
                status !== "success"
                  ? `${REACT_APP_MANAGER_LINK}/course`
                  : `${REACT_APP_MANAGER_LINK}/unit/${data.subunit.unitId}?version=${data.subunit.unitVersionKey}`
              }
              type="link"
              className="flex w-96 min-w-max items-center gap-2 pt-10"
            >
              <ArrowLeftIcon
                className="h-5 w-5 text-dm-gray-200"
                aria-hidden="true"
              />
              {status !== "success"
                ? "Back to Course List"
                : "Back to Unit Details"}
            </Button>
            <div
              className={`duration-400 my-2 flex items-center justify-center rounded-lg p-2 transition ease-in ${
                showSaved ? "bg-dm-success-200" : "bg-dm-warning-500"
              } ${hidden ? "" : "hidden"}`}
            >
              {!showSaved ? (
                "Saving..."
              ) : (
                <>
                  <i className="far fa-check mr-2" />
                  Saved
                </>
              )}
            </div>

            <div className={hidden ? "hidden" : ""}>
              <div className="overflow-y-scrol my-4 flex justify-between">
                <h5 className="text-lg font-bold">{data.course.name}</h5>
                <div
                  className={`duration-400 flex items-center justify-center rounded-lg p-2 transition ease-in ${
                    showSaved ? "bg-dm-success-200" : "bg-dm-warning-500"
                  }`}
                >
                  {!showSaved ? (
                    "Saving..."
                  ) : (
                    <>
                      <i className="far fa-check mr-2" />
                      Saved
                    </>
                  )}
                </div>
              </div>
              <div className="mb-4">
                <h5 className="text-base font-bold">Unit: {data.unit.name}</h5>
              </div>
              <div className="mb-8">
                <EditName
                  name={data.subunit.name}
                  friendlyUrl={data.subunit.friendlyPath ?? ""}
                  description=""
                  type="subunit"
                  id={data.subunit._id}
                  callback={refetch}
                  versionState="draft"
                />
              </div>
              <Switch.Group>
                <div className="mb-4 flex items-center gap-x-2">
                  <Switch.Label
                    passive
                    className={clsx(
                      "min-w-[44px] text-left text-xs text-dm-charcoal-600",
                      enabled && "font-bold"
                    )}
                  >
                    Hide Duplicate Message From Deleted
                  </Switch.Label>
                  <Switch
                    checked={enabled}
                    onChange={setEnabled}
                    className={`${
                      enabled ? "bg-dm-brand-blue-800" : "bg-dm-brand-blue-500"
                    } relative inline-flex h-6 w-11 shrink-0 items-center rounded-full`}
                  >
                    <span className="sr-only">daily/weekly</span>
                    <span
                      aria-hidden="true"
                      className={`${
                        enabled ? "translate-x-6" : "translate-x-1"
                      } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                    />
                  </Switch>
                </div>
              </Switch.Group>
            </div>
            <Button
              onClick={() => setHidden(!hidden)}
              type="outline"
              size="small"
            >
              {hidden ? "Show" : "Hide"} Header
            </Button>
            <h5 className="font-bold">Skills</h5>
          </div>
          <div>
            <DraggableTable
              cols={columns}
              data={skills.map((s) => {
                return {
                  id: s.skillCode,
                  ...s,
                };
              })}
              updateData={updateOrder}
              // dragTop
              lessPadding
              tallRow
            />
          </div>
        </div>
        <div
          className="sticky bottom-0 z-20 w-full bg-white py-4"
          style={{
            boxShadow:
              "0px -25px 20px -20px rgba(0,0,0,0.45), 0px 25px 20px -20px rgba(0,0,0,0.45)",
          }}
        >
          <div className="mb-4 flex justify-center gap-4">
            <p className="font-bold">
              Easy: {skills.filter((s) => s.difficulty !== "hard").length}
            </p>
            <p className="font-bold">
              Hard: {skills.filter((s) => s.difficulty !== "easy").length}
            </p>
            <p className="font-bold">Total: {skills.length}</p>
          </div>
        </div>
      </div>
      <div className="overflow-scroll">
        <SkillCodes skillAdd={skillAdd} />
      </div>
    </div>
  );
};

export default CourseSubunit;
