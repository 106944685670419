import { processChoices } from "./snakeGameProblemGenerator";

const { rand, $ } = window;

/** Find double, triple or half of a number
 * @return problem object with the following properties: questionPrompt (string with html), question?  (string), choices (array of 4 strings representing answer choices with the correct answer always at index 0), solution function to run
 */
export function doubleTripleHalfLevel2() {
  const types = ["double", "triple", "half"];
  const type = types[rand(0, types.length - 1)];

  switch (type) {
    case "double":
      return double();
    case "triple":
      return triple();
    case "half":
      return half();
    default:
      break;
  }
}

/** Find double a number
 * @return problem object with the following properties: questionPrompt (string with html), question? (string), choices (array of 4 strings representing answer choices with the correct answer always at index 0), solution function to run
 */
function double() {
  const types = ["whole number", "decimal"];
  const type = types[rand(0, types.length - 1)];

  let value;
  do {
    value = rand(16, 99);
  } while (value % 10 === 0);

  let correctAnswer = value * 2;

  let choices = [
    correctAnswer + 10,
    correctAnswer - 10,
    Math.floor(value / 10) * 20 + (value % 10),
  ];

  if (value % 2 === 0) choices.push(value / 2);

  if (type === "decimal") {
    value /= 10;
    correctAnswer /= 10;
    choices = choices.map((choice) => choice / 10);
  }

  choices = processChoices(choices, correctAnswer);

  const solution = (id) => {
    $(id).html(`\\(${value}+${value}=2*${value}=${correctAnswer}\\)`);
  };

  return {
    questionPrompt: `What is double \\(${value}\\)?`,
    choices,
    solution,
  };
}

/** Find triple a number
 * @return problem object with the following properties: questionPrompt (string with html), question? (string), choices (array of 4 strings representing answer choices with the correct answer always at index 0), solution function to run
 */
function triple() {
  let value;
  do {
    value = rand(11, 33);
  } while (value % 10 === 0);

  const correctAnswer = value * 3;

  // distractor choices
  let choices = [
    correctAnswer + 10,
    correctAnswer - 10,
    Math.floor(value / 10) * 30 + (value % 10),
    value * 2,
  ];

  choices = processChoices(choices, correctAnswer);

  const solution = (id) => {
    $(id).html(`\\(${value}+${value}+${value}=3*${value}=${correctAnswer}\\)`);
  };

  return {
    questionPrompt: `What is triple \\(${value}\\)?`,
    choices,
    solution,
  };
}

/** Find half a number
 * @return problem object with the following properties: questionPrompt (string with html), question? (string), choices (array of 4 strings representing answer choices with the correct answer always at index 0), solution function to run
 */
function half() {
  let value;
  do {
    value = 2 * rand(8, 49);
  } while (value % 10 === 0);

  const correctAnswer = value / 2;

  // distractor choices
  let choices = [correctAnswer + 5, correctAnswer - 5, value * 2];
  // add extra distractor choices conditionally
  if (Math.floor(value / 10) % 2 === 1) choices.push(correctAnswer + 10);

  choices = processChoices(choices, correctAnswer);

  const solution = (id) => {
    $(id).html(`\\(\\frac{${value}}{2}=${correctAnswer}\\)`);
  };

  return {
    questionPrompt: `What is half of \\(${value}\\)?`,
    choices,
    solution,
  };
}
