import { capitalize } from "lodash";
import DeltaMathTable from "../../../shared/DeltaMathTable";
import clsx from "clsx";
import { BasicContentContainer } from "../BasicContentContainer";
import { PriceScheme } from "./types";

function formatDate(date: number): string {
  return Intl.DateTimeFormat("en-US", {
    dateStyle: "medium",
    timeStyle: "short",
  }).format(new Date(date * 1000));
}

export const PriceSchemesTable: React.FC<{
  schemes: PriceScheme[];
  setSelectedScheme: (scheme: PriceScheme | undefined) => void;
}> = ({ schemes, setSelectedScheme }) => {
  if (schemes.length === 0) {
    return (
      <BasicContentContainer>
        <p>There are no price schemes yet.</p>
      </BasicContentContainer>
    );
  }
  return (
    <DeltaMathTable
      data={schemes}
      columns={[
        {
          Header: "Display Name",
          accessor: "key",
          ...{ align: "left" },
        },
        {
          Header: "Status",
          accessor: "status",
          Cell: ({ value }) => {
            const bgColor =
              value === "draft"
                ? "bg-yellow-500"
                : value === "published"
                ? "bg-green-700"
                : "bg-gray-400";
            return (
              <span className={clsx("rounded px-2 py-1 text-white", bgColor)}>
                {capitalize(value)}
              </span>
            );
          },
          ...{ align: "left" },
        },
        {
          Header: "Type",
          accessor: "type",
          Cell: ({ value }) => capitalize(value.replace("_", " ")),
          ...{ align: "left" },
        },
        {
          Header: "Default",
          accessor: "default",
          Cell: ({ value }) => (value ? "Yes" : "No"),
          ...{ align: "center" },
        },
        {
          Header: "Expiration",
          accessor: "expiration",
          Cell: ({ value }) => formatDate(value),
          ...{ align: "left" },
        },
        {
          Header: "Prorated Discount",
          accessor: "proratedDiscount",
          ...{ align: "right" },
        },
        {
          Header: "Plus (Individual)",
          accessor: "individual.Plus",
          ...{ align: "right" },
        },
        {
          Header: "Integral (Individual)",
          accessor: "individual.Integral",
          ...{ align: "right" },
        },
        {
          Header: "Plus (Per Student)",
          accessor: "per_student.Plus",
          ...{ align: "right" },
        },
        {
          Header: "Integral (Per Student)",
          accessor: "per_student.Integral",
          ...{ align: "right" },
        },
        {
          Header: "Plus (Minimum)",
          accessor: "minimum.Plus",
          ...{ align: "right" },
        },
        {
          Header: "Integral (Minimum)",
          accessor: "minimum.Integral",
          ...{ align: "right" },
        },
      ]}
      options={{
        selectable: true,
        requireSelectOne: true,
      }}
      setSelectedRows={(rowsSelected: Record<number, true>) => {
        setSelectedScheme(schemes[Number(Object.keys(rowsSelected)[0])]);
      }}
    />
  );
};
