import { PropsWithChildren, ReactNode } from "react";

export const PrizeModalHeader: React.FC<
  PropsWithChildren<{
    image?: ReactNode;
  }>
> = ({ image, children }) => (
  <div className="mt-10 flex flex-col items-center justify-center gap-10 text-center sm:mt-0">
    <div className="font-serif font-bold">{children}</div>
    {image && <div className="overflow-hidden rounded">{image}</div>}
  </div>
);
