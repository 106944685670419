import { HTMLAttributes, useState } from "react";
import { AccountFormData, Learner } from "../../types";
import { EditAccountInformationModal } from "../Modals/EditAccountInformationModal";
import { useParentContext } from "../../contexts/ParentContext";
import { useLearnerContext } from "../../contexts/LearnerContext";
import { EditAccountIconButton } from "./EditAccountIconButton";
import { getLearnerAccountStatus } from "./getLearnerAccountStatus";
import { isSubscriptionActive } from "../../utils/isSubscriptionActive";

export const ParentEditAccountButton: React.FC<
  Omit<HTMLAttributes<HTMLButtonElement>, "onClick" | "aria-label"> & {
    user: Learner;
  }
> = ({ user, ...props }) => {
  const [showModal, setShowModal] = useState(false);
  const { fetchChildLearners } = useParentContext();
  const learnerContext = useLearnerContext();
  const accountStatus = getLearnerAccountStatus(user);

  const updateUser = (formData: AccountFormData) => {
    if (user.entitlements.includes("parent")) {
      learnerContext.updateLearner(formData);
    } else {
      fetchChildLearners();
    }
  };

  const updateUserUponAddPassword = () => {
    if (!user.entitlements.includes("parent") && accountStatus === "pending") {
      fetchChildLearners();
    }
  };

  if (!isSubscriptionActive(learnerContext.learner)) {
    return null;
  }

  return (
    <>
      <EditAccountIconButton onClick={() => setShowModal(true)} {...props} />
      <EditAccountInformationModal
        user={user}
        visible={showModal}
        onClose={() => setShowModal(false)}
        updateUser={updateUser}
        updateUserUponAddPassword={updateUserUponAddPassword}
        isParentEditingLearner={user._id !== learnerContext.learner?._id}
      />
    </>
  );
};
