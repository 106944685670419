import React, { useRef } from "react";
import ReactPlayer from "react-player";
import { useUserContext } from "../../shared/contexts/UserContext";

const StudentHelpVideo = () => {
  const videoRef = useRef<ReactPlayer>(null);
  const userContext = useUserContext();

  const videoPath =
    "https://videos.deltamath.com/public/Intro-to-DeltaMath/Default/HLS/Intro-to-DeltaMath.m3u8";
  const subtitlePath =
    "https://videos.deltamath.com/captions/Intro-to-DeltaMath.mp4.vtt";

  if (!userContext.getJWT()) {
    return <></>;
  }

  return (
    <div className="mb-8 mt-4 w-full flex-grow px-4">
      <ReactPlayer
        ref={videoRef}
        url={videoPath}
        controls={true}
        style={{
          margin: "0 auto auto",
        }}
        width={"100%"}
        height={"auto"}
        pip={true}
        config={{
          file: {
            hlsOptions: {
              forceHLS: true,
              maxMaxBufferLength: 30,
            },
            attributes: { crossOrigin: "anonymous" },
            tracks: [
              {
                label: "English",
                kind: "captions",
                src: subtitlePath,
                srcLang: "en",
                default: false,
              },
            ],
          },
        }}
      />
    </div>
  );
};

export default StudentHelpVideo;
