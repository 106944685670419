export const SnakeIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <rect width="24" height="24" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 5V3C21 2.44772 20.5523 2 20 2C19.4477 2 19 2.44772 19 3V5H9H8L7 5L7 13H8H9H12L12 15H5H3V17V21C3 21.5523 3.44772 22 4 22C4.55228 22 5 21.5523 5 21L5 17H14V15V13L14 12L14 11H9L9 7H21V6V5Z"
      fill="currentColor"
    />
  </svg>
);
