import { ReactNode, Dispatch, SetStateAction, useRef, useState } from "react";
import { Transition } from "@headlessui/react";
import { useOnClickOutside } from "usehooks-ts";
import Chevron from "../Chevron";
import clsx from "clsx";
import { uniqueId } from "lodash";

type Props = {
  id?: string;
  isOpen?: boolean;
  setIsOpen?: Dispatch<SetStateAction<boolean>>;
  titleButton: ReactNode;
  panel: ReactNode;
  isSummaryPage?: boolean;
  onToggle?: () => void;
  onPointerEnter?: () => void;
  onPointerLeave?: () => void;
  wrapperClasses?: string;
  buttonClasses?: string;
  panelClasses?: string;
  isPanelFloating?: boolean;
};

const Accordion = (props: Props) => {
  const buttonId = uniqueId("accordion-header");
  const panelId = uniqueId("panel");

  // Use if no isOpen/setIsOpen is passed in
  const [isThisOpen, setIsThisOpen] = useState(false);

  const panelTransition = {
    enter: "transition ease-in-out duration-100 transform",
    enterFrom: "-translate-y-full",
    enterTo: "translate-y-0",
    leave: "transition ease-in-out duration-150 transform",
    leaveFrom: "translate-y-0",
    leaveTo: "-translate-y-full",
    afterEnter: () => {
      (panelWrapperRef.current as HTMLElement | null)?.classList?.remove(
        "overflow-auto"
      );
    },
    beforeLeave: () => {
      (panelWrapperRef.current as HTMLElement | null)?.classList?.add(
        "overflow-auto"
      );
    },
  };

  const floatingPanelTransition = {
    enter: "transition-opacity duration-100",
    enterFrom: "opacity-0",
    enterTo: "opacity-100",
    leave: "transition-opacity duration-150",
    leaveFrom: "opacity-100",
    leaveTo: "opacity-0",
  };

  const handleClickOutside = () => {
    if (props.isPanelFloating) {
      props?.isOpen && props?.setIsOpen?.(false);
      typeof props.isOpen === "undefined" &&
        typeof props.setIsOpen === "undefined" &&
        setIsThisOpen(false);
    }
  };
  const accordionRef = useRef(null);
  const panelWrapperRef = useRef(null);
  useOnClickOutside(accordionRef, handleClickOutside, "touchstart");

  return (
    <div
      ref={accordionRef}
      className={clsx(
        "block bg-white",
        "relative",
        props.isSummaryPage
          ? "mb-3 rounded-lg border border-dm-charcoal-100 hover:border-dm-charcoal-200 hover:shadow-[2px_2px_10px_0px_rgba(0,0,0,0.08)]"
          : "",
        props.wrapperClasses
      )}
      id={props.id}
    >
      <h3>
        <button
          className={clsx(
            "group relative flex w-full justify-between gap-x-2 rounded-md",
            props.isSummaryPage
              ? "items-start p-4 text-left font-serif text-lg font-bold leading-none md:px-10 md:py-8"
              : "mb-0.5 select-none items-center p-2 text-sm leading-5 hover:bg-dm-brand-blue-100/50",
            props.buttonClasses,
            props.isPanelFloating &&
              (props.isOpen || isThisOpen) &&
              "rounded-b-none !bg-dm-brand-blue-100",
            props.isPanelFloating && "z-[11] !mb-0 !rounded-none"
          )}
          onClick={() => {
            props.setIsOpen?.(!props?.isOpen);
            typeof props.isOpen === "undefined" &&
              typeof props.setIsOpen === "undefined" &&
              setIsThisOpen(!isThisOpen);
            props.onToggle?.();
          }}
          onPointerEnter={props.onPointerEnter}
          onPointerLeave={props.onPointerLeave}
          aria-controls={panelId}
          aria-expanded={props.isOpen || isThisOpen}
          id={buttonId}
        >
          <>{props.titleButton}</>
          <Chevron open={props.isOpen || isThisOpen} />
        </button>
      </h3>
      <div
        ref={panelWrapperRef}
        className={clsx(
          !props.isPanelFloating && "relative overflow-auto",
          props.panelClasses
        )}
        role="region"
        aria-labelledby={buttonId}
        id={panelId}
      >
        <Transition
          className={clsx(
            props.isPanelFloating &&
              "absolute inset-x-0 top-[49px] z-10 mx-2 max-h-80 overflow-auto rounded-b-lg border border-t-0 border-dm-charcoal-100 bg-white px-2 py-1"
          )}
          show={props.isOpen || isThisOpen}
          unmount={false}
          {...(props.isPanelFloating
            ? floatingPanelTransition
            : panelTransition)}
        >
          {props.panel}
        </Transition>
      </div>
    </div>
  );
};

export default Accordion;
