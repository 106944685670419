import { useEffect, useState } from "react";
import { DashboardCard } from "../Dashboard/DashboardCard";
import { GraduationCapIcon } from "../../../shared/icons/GraduationCapIcon";
import { clampForSort } from "../../../utils";
import { generateCourseProgressAriaLabel } from "../../utils/generateCourseProgressAriaLabel";
import { useCourseContext } from "../../contexts/CourseContext";
import { useLearnerContext } from "../../contexts/LearnerContext";
import { Progress, myCourseDataType } from "../../types";
import { CourseProgressModal } from "../Modals/CourseProgressModal";
import CourseProgressCardHeader from "./CourseProgressCardHeader";
import { cloneDeep, compact } from "lodash";

export const CourseProgressCard: React.FC = () => {
  const [showModal, setShowModal] = useState<boolean>(false);

  const learnerContext = useLearnerContext();
  const courseContext = useCourseContext();

  const [assignmentIdsToLoad, setAssignmentIdsToLoad] = useState<string[]>([]);

  const [newestProgress] = learnerContext.state.progresses.sort(
    (progressA: Progress, progressB: Progress) =>
      clampForSort(
        new Date(progressB.updatedAt).getTime() -
          new Date(progressA.updatedAt).getTime()
      )
  );

  const isCurrentTestSubmitted =
    newestProgress?.courseTest !== undefined &&
    newestProgress?.courseTest?.assignment?.submitted !== undefined;
  const isFirstAttemptComplete =
    isCurrentTestSubmitted ||
    !!newestProgress?.courseTest?.assignment?.resultHist;

  // For generating aria label, from CourseLanding.tsx
  const currentCourses = courseContext.getCurrentLearnerCourses(
    newestProgress.courseId,
    learnerContext
  );

  const currentCourseData: myCourseDataType | undefined = cloneDeep(
    currentCourses.find(
      (course: myCourseDataType) => course.id === newestProgress.courseId
    )
  );

  // If progress is 0 but course test has been started, set progress
  // to a small amount so progress pie chart shows it in progress
  const courseTestProgress =
    newestProgress?.courseTest && newestProgress?.courseTest?.progress === 0
      ? 0.01
      : newestProgress?.courseTest?.progress;

  currentCourseData?.units?.push({
    id: `course-test-${newestProgress.courseId}`,
    path: "coursetest",
    name: "course-test",
    progress: isFirstAttemptComplete ? 1 : courseTestProgress || 0,
  });

  const courseProgressAriaLabel = generateCourseProgressAriaLabel(
    currentCourseData?.units
  );
  // End generating aria label

  const progressValueNumber = newestProgress
    ? Math.round(100 * newestProgress.progress)
    : 0;

  const course = courseContext.getCourseData(newestProgress?.courseId);

  useEffect(() => {
    // Call this to fetch subunit data
    courseContext.setActiveCourse(course?.id);
  }, [course, courseContext]);

  const segments = currentCourseData?.units
    ? currentCourseData?.units.map((unit) => {
        const suProgress = (unit?.subunits || []).filter(
          (x) => x.progress > 0
        ).length;
        const progress =
          unit.progress === 0 && suProgress > 0 ? 0.01 : unit.progress || 0;
        return {
          value: 1,
          color:
            progress === 0 ? "#E3E8EF" : progress === 1 ? "#2F52E9" : "#A9CCFA",
        };
      })
    : [];

  const sectionTotal = newestProgress?.units
    ? newestProgress?.units?.length
    : 0;

  const courseAssignmentId = !newestProgress.courseTest?.assignment
    ? compact([newestProgress.courseTest?.ref])
    : [];
  const unitAssignmentId = newestProgress.units.flatMap((u) =>
    u.unitTest?.ref && !u.unitTest.assignment ? compact([u.unitTest?.ref]) : []
  );
  const subunitAssignmentIds = newestProgress.units.flatMap((u) =>
    u?.subunits.flatMap((subunit) => [
      ...(subunit.preQuiz && !subunit.preQuiz.assignment
        ? [subunit.preQuiz.ref]
        : []),
      ...(subunit.practice && !subunit.practice.assignment
        ? [subunit.practice.ref]
        : []),
      ...(subunit.postQuiz && !subunit.postQuiz.assignment
        ? [subunit.postQuiz.ref]
        : []),
    ])
  );

  const allAssignmentIdsToLoad = compact([
    ...courseAssignmentId,
    ...unitAssignmentId,
    ...subunitAssignmentIds,
  ]);

  useEffect(() => {
    if (
      !allAssignmentIdsToLoad.every((a) =>
        assignmentIdsToLoad.some((id) => id === a)
      )
    ) {
      setAssignmentIdsToLoad(allAssignmentIdsToLoad);
      learnerContext.loadAssignmentsById(allAssignmentIdsToLoad);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allAssignmentIdsToLoad]);

  return (
    <>
      <DashboardCard
        size="flex"
        icon={<GraduationCapIcon />}
        title={`${course?.name} Course Progress`}
      >
        <div className="mt-3 flex gap-4">
          <CourseProgressCardHeader
            courseTotalProgress={progressValueNumber}
            totalSections={sectionTotal}
            segments={segments}
            courseIconUrl={course?.iconUrl}
            courseName={course?.name}
            courseProgressAriaLabel={courseProgressAriaLabel}
            setShowModal={setShowModal}
          />
        </div>
      </DashboardCard>
      <CourseProgressModal
        visible={showModal}
        onClose={() => setShowModal(false)}
        courseProgress={newestProgress}
        currentCourseData={currentCourseData}
        header={
          <CourseProgressCardHeader
            courseTotalProgress={progressValueNumber}
            totalSections={sectionTotal}
            segments={segments}
            courseIconUrl={course?.iconUrl}
            courseName={course?.name}
            courseProgressAriaLabel={courseProgressAriaLabel}
            setShowModal={setShowModal}
            isModal
          />
        }
      />
    </>
  );
};
