import clsx from "clsx";
import { useState, useEffect } from "react";
import {
  SubStandard,
  StandardRule,
} from "../../../manager/components/standards/common/types";

export const SelectStandard = (props: {
  standard: SubStandard;
  depth: number;
  path: string;
  activatedPath: string;
  rules: StandardRule[];
  stateCode: string;
  onClickFn: (e: React.MouseEvent<HTMLDivElement>, standard: any) => void;
  selectFn: any;
  closeFn: () => void;
}) => {
  const {
    standard,
    depth,
    stateCode,
    activatedPath,
    onClickFn,
    selectFn,
    closeFn,
  } = props;
  const [isActive, setActive] = useState(false);
  const [expanded, setExpanded] = useState(false);
  let { path, rules } = props;
  path = path.concat(standard.description);
  const ruleAtPath: StandardRule = rules.find((r) => r.path === path) || {
    path: path,
  };
  rules = rules.filter((r) => r.path.includes(path) && r.path !== path);

  const printLabel = (label: string | undefined, depth: number) => {
    if (label && [".", ":"].includes(label[label.length - 1])) {
      label = label.slice(0, -1);
    }
    if (depth === 2) return label ? " (" + label + ")" : "";
    else if ([3, 4, 5, 6].includes(depth)) {
      if (label && label.trim() === label) return label + ":";
      if (label && label.trim() !== label) return "\u2022"; // Bullet Point
      return "";
    }
  };

  const printDescription = (description: string | undefined) => {
    if (!description) return "";
    return description.replace(/\(([^)]*)\)$[^(]*$/, "");
  };

  // Because tailwind doesnt seem to like string manipulation (aka: `pl-${n}` does not work).
  const getPadding = (depth: number) => {
    if (depth === 2) return "pl-4";
    if (depth === 3) return "pl-8";
    if (depth === 4) return "pl-16";
    else if (depth === 5) return "pl-24";
    else if (depth === 6) return "pl-32";
    else return "";
  };

  const getNewPadding = (depth: number) => {
    if (depth === 2) return "pl-16";
    if (depth === 3) return "pl-24";
    if (depth === 4) return "pl-32";
    else if (depth === 5) return "pl-48";
    else if (depth === 6) return "pl-64";
    else return "";
  };

  const getCheckboxPosition = (depth: number) => {
    if (depth === 1) return "left-6";
    if (depth === 2) return "left-12";
    if (depth === 3) return "left-16";
    if (depth === 4) return "left-24";
    else if (depth === 5) return "left-40";
    else if (depth === 6) return "left-60";
    else return "";
  };

  // Controls which standard has been selected
  useEffect(() => {
    if (activatedPath === path) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [activatedPath]);

  // Auto-expand SubStandards down the "path" to selected module.
  useEffect(() => {
    if (activatedPath.indexOf(path) !== -1) {
      setExpanded(true);
    }
  }, []);

  const ExpandButton = ({ isExpanded }: { isExpanded: boolean }) => {
    return (
      <button
        className="mr-2 box-border inline-flex cursor-pointer appearance-none focus:outline-none"
        // onClick={toggleExpanded} - toggling is taken care of in the parent component
      >
        {isExpanded ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="9"
            viewBox="0 0 14 9"
            fill="none"
          >
            <path
              d="M1 7.5L7 1.5L13 7.5"
              stroke="#9CA3AF"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="9"
            viewBox="0 0 14 9"
            fill="none"
          >
            <path
              d="M13 1.5L7 7.5L1 1.5"
              stroke="#9CA3AF"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )}
      </button>
    );
  };

  // const SelectStandardButton = ({
  //   selectFn,
  //   skillcodes,
  // }: {
  //   selectFn: any;
  //   skillcodes: any;
  // }) => {
  //   return (
  //     <button
  //       type="button"
  //       onClick={selectFn(skillcodes)}
  //       className="m-2 inline-flex items-center rounded-full bg-dm-blue px-2 py-1 text-white shadow-sm hover:bg-dm-alt-blue focus:outline-none focus:ring-2 focus:ring-offset-2"
  //     >
  //       <svg
  //         xmlns="http://www.w3.org/2000/svg"
  //         fill="none"
  //         viewBox="0 0 24 24"
  //         strokeWidth={1.5}
  //         stroke="currentColor"
  //         className="mr-1 h-4 w-4"
  //       >
  //         <path
  //           strokeLinecap="round"
  //           strokeLinejoin="round"
  //           d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
  //         />
  //       </svg>
  //       Select
  //     </button>
  //   );
  // };

  return (
    <>
      <div className={clsx(ruleAtPath.hide === true ? "line-through" : "")}>
        <div className="flow-root px-4">
          {depth === 1 && (
            <div
              onClick={(e) => {
                console.log("clicky click");
                setExpanded((prev: boolean) => !prev);
                if (standard.module_data) {
                  onClickFn(e, standard);
                }
              }}
              className="mt-2 flex justify-between rounded-t border bg-white px-8 py-4 align-middle text-sm font-semibold hover:cursor-pointer hover:underline"
            >
              <h4
                id={path}
                className="standard-descrip text-center align-middle"
              >
                {(Array.isArray(standard.module_data) ||
                  (standard && typeof standard.data === undefined)) && (
                  <input
                    type="checkbox"
                    name="select-standard"
                    value={path}
                    className={clsx(
                      "custom-checkbox",
                      "left-0",
                      "absolute m-1 outline-1 hover:cursor-pointer disabled:hover:cursor-not-allowed"
                    )}
                    checked={isActive}
                    disabled={
                      (standard.module_data &&
                        standard?.module_data.length === 0) ||
                      (standard &&
                        typeof standard.data === "undefined" &&
                        typeof standard.module_data === "undefined")
                    }
                    onChange={() => {
                      if (Array.isArray(standard.module_data)) {
                        return selectFn(standard.module_data);
                      }
                    }}
                  />
                )}
                <span
                  className={clsx(
                    (standard?.module_data &&
                      standard?.module_data.length === 0) ||
                      (standard &&
                        typeof standard.data === "undefined" &&
                        typeof standard.module_data === "undefined")
                      ? "text-dm-gray-200"
                      : "bg-white"
                  )}
                >
                  {printDescription(
                    ruleAtPath.newDescription
                      ? ruleAtPath.newDescription
                      : standard.description
                  )}
                </span>
                {((Array.isArray(standard.module_data) &&
                  standard?.module_data.length === 0) ||
                  (standard &&
                    typeof standard.data === "undefined" &&
                    typeof standard.module_data === "undefined")) && (
                  <div className="invisible absolute -top-10 ml-8 items-center text-left group-hover:visible">
                    <span className="whitespace-no-wrap relative z-10 block bg-black p-2 text-xs text-white shadow-lg">
                      No skills on DeltaMath yet align to this standard.{" "}
                    </span>
                    <div className="align-center -my-2 mx-auto h-3 w-3 rotate-45 bg-black"></div>
                  </div>
                )}
              </h4>
              <div>
                {!(
                  standard.module_data ||
                  (standard && typeof standard.data === "undefined")
                ) && <ExpandButton isExpanded={expanded} />}
              </div>
            </div>
          )}
          {depth === 2 && (
            <div
              className="flex justify-between border border-t-0 bg-white py-4 pr-8 align-middle hover:cursor-pointer"
              onClick={(e) => {
                setExpanded((prev: boolean) => !prev);
                if (standard.module_data?.length) {
                  onClickFn(e, standard);
                  selectFn(standard.module_data);
                }
              }}
            >
              <h5
                id={path}
                className={clsx(
                  "standard-descrip text-sm hover:underline",
                  getNewPadding(depth),
                  Array.isArray(standard?.module_data) &&
                    standard?.module_data &&
                    "pl-8",
                  ((Array.isArray(standard?.module_data) &&
                    standard?.module_data.length === 0) ||
                    (standard &&
                      typeof standard.data === "undefined" &&
                      typeof standard.module_data === "undefined")) &&
                    "pl-8 text-dm-gray-200",
                  "hover:cursor-pointer hover:underline",
                  "group relative"
                )}
              >
                {(Array.isArray(standard.module_data) ||
                  (standard && typeof standard.data === "undefined")) && (
                  <input
                    type="checkbox"
                    name="select-standard"
                    value={path}
                    className={clsx(
                      "custom-checkbox",
                      "left-0",
                      "m-1 outline-1 hover:cursor-pointer disabled:hover:cursor-not-allowed"
                    )}
                    checked={isActive}
                    disabled={
                      (standard.module_data &&
                        standard?.module_data.length === 0) ||
                      (standard &&
                        typeof standard.data === "undefined" &&
                        typeof standard.module_data === "undefined")
                    }
                    onChange={() => {
                      if (Array.isArray(standard.module_data)) {
                        return selectFn(standard.module_data);
                      }
                    }}
                  />
                )}
                <span
                  className={clsx(
                    (standard?.module_data &&
                      standard?.module_data.length === 0) ||
                      (standard &&
                        typeof standard.data === "undefined" &&
                        typeof standard.module_data === "undefined")
                      ? "pointer-events-none text-dm-gray-200"
                      : "bg-white"
                  )}
                >
                  <span className="text-1xl dm-dark-blue mb-0 mr-1 mt-0 inline-block font-semibold leading-relaxed">
                    {printLabel(
                      ruleAtPath.label === undefined
                        ? standard.label
                        : ruleAtPath.label,
                      depth
                    )}
                  </span>
                  {printDescription(
                    ruleAtPath.newDescription
                      ? ruleAtPath.newDescription
                      : standard.description
                  )}{" "}
                </span>
                {((Array.isArray(standard.module_data) &&
                  standard?.module_data.length === 0) ||
                  (standard &&
                    typeof standard.data === "undefined" &&
                    typeof standard.module_data === "undefined")) && (
                  <div className="invisible absolute -top-10 ml-8 items-center text-left group-hover:visible">
                    <span className="whitespace-no-wrap relative z-10 block bg-black p-2 text-xs text-white shadow-lg">
                      No skills on DeltaMath yet align to this standard.{" "}
                    </span>
                    <div className="align-center -my-2 mx-auto h-3 w-3 rotate-45 bg-black"></div>
                  </div>
                )}
              </h5>
              <div>
                {!(
                  standard.module_data ||
                  (standard && typeof standard.data === "undefined")
                ) && <ExpandButton isExpanded={expanded} />}
              </div>
            </div>
          )}
          {depth > 2 && (
            <div
              className={clsx(
                "group flex justify-between border border-t-0 bg-white py-4 pr-8 align-middle",
                standard?.module_data
                  ? standard?.module_data.length
                    ? "hover:cursor-pointer hover:underline"
                    : ""
                  : standard && typeof standard.data === "undefined"
                  ? ""
                  : "hover:cursor-pointer hover:underline"
              )}
              onClick={(e) => {
                setExpanded((prev: boolean) => !prev);
                if (standard.module_data?.length) {
                  onClickFn(e, standard);
                  selectFn(standard.module_data);
                }
              }}
            >
              <h5
                id={path}
                className={clsx(
                  "standard-descrip text-sm",
                  getNewPadding(depth),
                  (standard.module_data?.length === 0 ||
                    (standard &&
                      typeof standard.data === "undefined" &&
                      typeof standard.module_data === "undefined")) &&
                    "text-dm-gray-200",
                  "group relative"
                )}
              >
                {(Array.isArray(standard?.module_data) ||
                  (standard && typeof standard.data === "undefined")) && (
                  <input
                    type="checkbox"
                    name="select-standard"
                    value={path}
                    style={{ position: "absolute" }}
                    className={clsx(
                      "custom-checkbox",
                      getCheckboxPosition(depth),
                      "absolute m-1 outline-1 hover:cursor-pointer disabled:hover:cursor-not-allowed"
                    )}
                    checked={isActive}
                    disabled={
                      (standard.module_data &&
                        standard?.module_data.length === 0) ||
                      (standard &&
                        typeof standard.data === "undefined" &&
                        typeof standard.module_data === "undefined")
                    }
                    onChange={() => {
                      if (Array.isArray(standard.module_data)) {
                        return selectFn(standard.module_data);
                      }
                    }}
                  />
                )}
                <span
                  className={clsx(
                    (standard?.module_data &&
                      standard?.module_data.length === 0) ||
                      (standard &&
                        typeof standard.data === "undefined" &&
                        typeof standard.module_data === "undefined")
                      ? "text-dm-gray-400 pointer-events-none"
                      : "bg-white"
                  )}
                >
                  <span className="text-1xl dm-dark-blue mb-0 mt-0 inline-block font-semibold leading-relaxed">
                    {printLabel(
                      ruleAtPath.label === undefined
                        ? standard.label
                        : ruleAtPath.label,
                      depth
                    )}
                  </span>
                  <span className="leading-relaxed">
                    {" "}
                    {printDescription(
                      ruleAtPath.newDescription
                        ? ruleAtPath.newDescription
                        : standard.description
                    )}
                  </span>
                </span>
                {((Array.isArray(standard.module_data) &&
                  standard?.module_data.length === 0) ||
                  (standard &&
                    typeof standard.data === "undefined" &&
                    typeof standard.module_data === "undefined")) && (
                  <>
                    <div className="invisible absolute -top-10 ml-8 items-center text-left group-hover:visible">
                      <span className="whitespace-no-wrap relative z-10 block bg-black p-2 text-xs text-white shadow-lg">
                        No skills on DeltaMath yet align to this standard.{" "}
                      </span>
                      <div className="align-center -my-2 mx-auto h-3 w-3 rotate-45 bg-black"></div>
                    </div>
                  </>
                )}
              </h5>
              <div>
                {!(
                  standard.module_data ||
                  (standard && typeof standard.data === "undefined")
                ) && <ExpandButton isExpanded={expanded} />}
              </div>
            </div>
          )}
        </div>
      </div>

      {expanded &&
        standard.data &&
        ruleAtPath.hide !== true &&
        Object.entries(standard.data).map(([k, v]) => (
          <SelectStandard
            key={k}
            standard={v}
            depth={depth + 1}
            path={path.concat(".data.")}
            activatedPath={activatedPath}
            rules={rules}
            stateCode={stateCode}
            onClickFn={onClickFn}
            selectFn={selectFn}
            closeFn={closeFn}
          />
        ))}
    </>
  );
};

export default SelectStandard;
