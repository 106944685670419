import clsx from "clsx";
import { DMPieChart } from "../../../student/components/generic/DMPieChart";
import { myCourseDataType } from "../../types";
import { generateCourseProgressAriaLabel } from "../../utils/generateCourseProgressAriaLabel";

export const CourseProgressRadial: React.FC<{
  courseProgress: myCourseDataType | undefined;
  iconUrl?: string;
  className?: string;
  imgClassName?: string;
}> = (props) => {
  const courseProgress = Math.round(
    (props.courseProgress?.progress || 0) * 100
  );

  const segments = props.courseProgress?.units
    ? props.courseProgress?.units.map((unit) => {
        const suProgress = (unit?.subunits || []).filter(
          (x) => x.progress > 0
        ).length;
        const progress =
          unit.progress === 0 && suProgress > 0 ? 0.01 : unit.progress || 0;
        return {
          value: 1,
          color:
            progress === 0 ? "#E3E8EF" : progress === 1 ? "#2F52E9" : "#A9CCFA",
        };
      })
    : [];

  // LARGE: 112px x 112 px square artboard, 70px width icon (height to scale)
  // SMALL: 32px x 32px square artboard, the icon width within that is 16px (height to scale)
  return (
    <div
      className={clsx(
        "relative flex shrink-0 items-center justify-center",
        props.className
      )}
    >
      <DMPieChart
        data={segments}
        lineWidth={15}
        paddingAngle={segments.length > 1 ? 3 : 0}
        totalValue={segments.length}
        startAngle={270}
        className="pointer-events-none absolute"
        ariaLabel={generateCourseProgressAriaLabel(props.courseProgress?.units)}
      />
      <img
        src={`${props.iconUrl}`}
        className={clsx(
          "left-0 top-0 h-12 w-12 shrink-0 object-cover text-center leading-none",
          props.imgClassName,
          courseProgress === 0 ? "stroke-[#9CA3AF]" : "stroke-[#2f52e9]"
        )}
        alt={`${props.courseProgress?.name} icon`}
      />
    </div>
  );
};
