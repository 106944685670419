import { useState } from "react";
import DatePicker from "react-datepicker";
import DeltaMathTable from "../../shared/DeltaMathTable";
import { Link } from "react-router-dom";
import { REACT_APP_MANAGER_LINK, useDMQuery } from "../../utils";
import { endOfDay, startOfDay } from "date-fns";
import { DmLoadingSpinner } from "../utils/functions";

export const ChangeLog: React.FC = () => {
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(new Date());

  const columns = [
    {
      Header: "User",
      accessor: "user",
    },
    {
      Header: "Number of Edits",
      accessor: "numEdits",
      align: "center",
    },
    {
      Header: "Edited Quotes",
      accessor: "editedQuotes",
      Cell: ({ cell }: { cell: any }) =>
        cell.value
          .sort((a: string, b: string) => Number(a) - Number(b))
          .map((quoteNumber: string, index: number) => (
            <span key={`${quoteNumber}-${index}`}>
              <Link
                className="text-indigo-500 hover:underline"
                to={`${REACT_APP_MANAGER_LINK}/quote-lookup/${quoteNumber}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {quoteNumber}
              </Link>
              {index < cell.value.length - 1 ? ", " : ""}
            </span>
          )),
      wrap: true,
      minWidth: 200,
    },
  ];

  const { data, refetch, isLoading } = useDMQuery({
    path: "/manager_new/invoices/changelog",
    queryOptions: {
      refetchOnWindowFocus: false,
    },
    params: {
      startDate: startOfDay(startDate || new Date()),
      endDate: endOfDay(endDate || new Date()),
    },
  });

  return (
    <div className="px-2 md:px-0">
      <div className="flex flex-col gap-4 p-4">
        <h1 className="text-2xl font-semibold text-gray-900">
          Quote Change Log
        </h1>
        <form
          className="inline-flex"
          onSubmit={(e) => {
            e.preventDefault();
            refetch();
          }}
        >
          <DatePicker
            selected={startDate}
            onChange={(dates) => {
              const [start, end] = dates;
              setStartDate(start);
              setEndDate(end);
            }}
            selectsRange
            startDate={startDate}
            endDate={endDate}
            maxDate={new Date()}
            nextMonthButtonLabel=">"
            previousMonthButtonLabel="<"
            className="h-12 min-w-8 border-gray-300 bg-white text-gray-800 focus:border-indigo-500 focus:ring-0 focus:ring-indigo-500 sm:text-sm md:rounded-md"
          />
          <button
            type="submit"
            className="ml-2 inline-flex items-center rounded-md border border-transparent bg-dm-light-blue px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-dm-dark-blue focus:outline-none focus:ring-2 focus:ring-dm-lightest-blue focus:ring-offset-2"
          >
            Show Report
          </button>
        </form>
      </div>
      {isLoading && <DmLoadingSpinner message={"Loading..."} />}
      {data && data.length > 0 && !isLoading && (
        <DeltaMathTable
          columns={columns}
          data={data}
          key="delta-math-table-quote-changelog"
          options={{
            autoResetSortBy: false,
            globalFilter: true,
            autoResetFilters: false,
            stickyHeader: true,
          }}
        />
      )}
    </div>
  );
};
