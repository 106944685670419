import { Dialog, Transition } from "@headlessui/react";
import { Fragment, Dispatch, SetStateAction } from "react";

type Props = {
  isShowing: boolean;
  setIsShowing: Dispatch<SetStateAction<any>>;
  setNextProblem: () => void;
};

export default function SkillCompletedWarningModal({
  isShowing,
  setIsShowing,
  setNextProblem,
}: Props): JSX.Element {
  function keepTrying() {
    setNextProblem();
    setIsShowing(false);
  }

  return (
    <Transition show={isShowing} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={() => setIsShowing(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-60" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="mb-3 text-lg font-medium leading-6 text-gray-900"
                >
                  Section Complete!
                </Dialog.Title>
                <Dialog.Description className="text-sm text-gray-500">
                  You have now completed this section. Solving more problems on
                  this section will not increase your grade on this assignment.
                  There are still problems in other sections you have yet to
                  complete. Are you sure you want to practice additional
                  problems of this type?
                </Dialog.Description>

                <div className="mt-4 flex justify-end gap-3">
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={keepTrying}
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={() => setIsShowing(false)}
                  >
                    No
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
