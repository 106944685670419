import Button from "../../../student/components/generic/button";

type FormFooterProps = {
  submitButtonText?: string;
  submitAriaLabel?: string;
  submitDisabled?: boolean;
  secondaryButtonText?: string;
  secondaryAriaLabel?: string;
  secondaryOnClick?: () => void;
  secondaryDisabled?: boolean;
};

export const FormFooter: React.FC<FormFooterProps> = (props) => {
  return (
    <div className="mt-4 flex flex-col justify-between gap-4 border-t pt-8 sm:flex-row">
      {props.secondaryButtonText && props.secondaryOnClick ? (
        <Button
          onClick={props.secondaryOnClick}
          type="outline"
          size="small"
          ariaLabel={props.secondaryAriaLabel}
          disabled={props.secondaryDisabled}
        >
          {props.secondaryButtonText}
        </Button>
      ) : (
        <div></div>
      )}

      {props.submitButtonText && (
        <Button
          submit
          size="small"
          ariaLabel={props.submitAriaLabel}
          disabled={props.submitDisabled}
        >
          {props.submitButtonText}
        </Button>
      )}
    </div>
  );
};
