import clsx from "clsx";
import { Link, useParams } from "react-router-dom";
import slugify from "slugify";
import { Tooltip } from "../../../shared/Tooltip";
import { REACT_APP_ADMIN_LINK } from "../../../utils";
import { Fragment } from "react";

const ReportTabs = ({ compareDisabled }: { compareDisabled: boolean }) => {
  const params = useParams();
  const tabs = [
    {
      name: "Problems Solved",
      disabled: false,
    },
    {
      name: "Time Spent",
      disabled: false,
    },
    {
      name: "Compare Problems Solved",
      disabled: compareDisabled,
    },
    {
      name: "Compare Time Spent",
      disabled: compareDisabled,
    },
    {
      name: "Problems Solved by Hour",
      disabled: false,
    },
  ];
  return (
    <div className="flex justify-between pr-12">
      {tabs.map((tab) => (
        <Fragment key={tab.name}>
          {tab.disabled ? (
            <Tooltip
              message={`Compare View only available
                for up to 5 items at a time.`}
              options={{ raise: "mb-10", aligned: "center" }}
            >
              {" "}
              <div
                className={clsx(
                  "group border-b-2 border-transparent py-4 text-center text-sm font-medium text-gray-500 opacity-50"
                )}
              >
                {tab.name}
              </div>
            </Tooltip>
          ) : (
            <Link
              to={`${REACT_APP_ADMIN_LINK}/reports/student-usage/view-report/${slugify(
                tab.name,
                {
                  lower: true,
                }
              )}`}
              replace
              aria-disabled={tab.disabled}
              className={clsx(
                slugify(tab.name, { lower: true }) === params.report_type
                  ? "border-dm-brand-blue-500 font-bold"
                  : "border-transparent text-dm-light-blue hover:border-gray-300 hover:text-gray-700",
                "group border-b-4 py-4 text-center text-sm text-gray-700"
              )}
              aria-current={
                slugify(tab.name, { lower: true }) === params.selected_tab
                  ? "page"
                  : undefined
              }
            >
              {tab.name}
              {/* {getCount(tab.name)} */}
            </Link>
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default ReportTabs;
