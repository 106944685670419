import { useEffect, useState } from "react";
import { useDMQuery } from "../../../utils";
import { Subunit, Unit } from "../../types";
import Select, { SingleValue } from "react-select";
import { FocusContextProvider } from "../../../learner/contexts/FocusContext";
import ExampleModal from "../../../learner/components/Modals/ExampleModal";
import Button from "../../../student/components/generic/button";
import { skillDataType } from "../CourseUnit/CourseUnit";
import { sum } from "lodash";

type Props = {
  unit: Unit;
  unitId: string;
  hideTypes: boolean;
};

const UnitDetails = (props: Props) => {
  const [selectedSkillCode, setSelectedSkillCode] = useState<
    string | undefined
  >(undefined);
  const [unitVersion, setUnitVersion] = useState({
    value:
      props.unit.versionHistory[props.unit.versionHistory.length - 1]
        .versionKey,
    label: `V${
      props.unit.versionHistory[props.unit.versionHistory.length - 1]
        .versionName
    }`,
  });

  const {
    data: subunitData,
    refetch: subunitRefetch,
    status: subunitStatus,
  } = useDMQuery<Subunit[]>({
    path: `/manager_new/parent-portal/unit/${props.unitId}/subunits?versionId=${unitVersion.value}`,
    queryOptions: {
      enabled: false,
    },
  });

  const { data: skillData, refetch: skillRefetch } = useDMQuery<
    skillDataType[]
  >({
    path: `/manager_new/parent-portal/unit/${props.unitId}/skills?versionId=${unitVersion.value}`,
    queryOptions: {
      enabled: false,
    },
  });

  useEffect(() => {
    if (unitVersion) {
      subunitRefetch();
      skillRefetch();
    }
  }, [unitVersion]);

  return (
    <>
      <table className="my-10 w-full">
        <tr>
          <th>Unit</th>
          <th>Subunit</th>
          <th>Skill Name</th>
          <th>Skill Code</th>
          <th>Weight</th>
          <th>Difficulty</th>
          {!props.hideTypes && (
            <>
              <th>Types</th>
              <th>Excluded Types</th>
            </>
          )}
        </tr>

        {subunitData && subunitData.length > 0 ? (
          subunitData.map((s, i) => {
            const easySkills = skillData
              ? skillData.filter((s) => s.difficulty !== "hard")
              : [];
            const hardSkills = skillData
              ? skillData.filter((s) => s.difficulty !== "easy")
              : [];

            const easyQuestions =
              easySkills.length > 20 ? 20 : easySkills.length;
            const hardQuestions =
              hardSkills.length > 20 ? 20 : hardSkills.length;

            const easyTestWeight = sum(easySkills.map((es) => es.testWeight));
            const easyTimeEstimate =
              (sum(easySkills.map((x) => x.seconds * x.testWeight)) /
                easyTestWeight) *
              easyQuestions;
            const easyTime = `${Math.floor(
              easyTimeEstimate / 60
            )} Minutes, ${Math.round(easyTimeEstimate % 60)} seconds`;

            const hardTestWeigh = sum(hardSkills.map((hs) => hs.testWeight));
            const hardTimeEstimate =
              (sum(hardSkills.map((x) => x.seconds * x.testWeight)) /
                hardTestWeigh) *
              hardQuestions;
            const hardTime = `${Math.floor(
              hardTimeEstimate / 60
            )} Minutes, ${Math.round(hardTimeEstimate % 60)} seconds`;

            return s.skills.map((sk, j) => {
              const skData = skillData?.find(
                (sd) => sd.skillCode === sk.skillCode
              );
              const types =
                sk.typeKeys.length === Object.keys(skData?.types || []).length
                  ? ["All"]
                  : sk.typeKeys.map((tk) => skData?.types[Number(tk)]);
              const allTypeKeys = Object.keys(skData?.types || []);
              const excludedTypeKeys =
                sk.typeKeys.length === Object.keys(skData?.types || []).length
                  ? []
                  : allTypeKeys.filter((tk) => !sk.typeKeys.includes(tk));
              const excludedTypes =
                excludedTypeKeys.length === 0
                  ? ["None"]
                  : excludedTypeKeys.map((tk) => skData?.types[Number(tk)]);

              return (
                <tr key={`subunit-skill-row-${s._id}-${j}`}>
                  {i === 0 && j === 0 && (
                    <td
                      className="w-56"
                      rowSpan={
                        subunitData && subunitData.length > 0
                          ? sum(subunitData.map((x) => x.skills.length))
                          : 1
                      }
                    >
                      <h1 className="bold text-xl underline">Unit Details</h1>
                      <h2 className="bold text-lg">{props.unit.name}</h2>
                      <Select
                        placeholder="Select Specific Version"
                        name="Unit Version"
                        options={props.unit.versionHistory.map((v) => {
                          return {
                            value: v.versionKey,
                            label: `V${v.versionName}`,
                          };
                        })}
                        isClearable={true}
                        isSearchable={false}
                        closeMenuOnSelect={false}
                        className="w-56"
                        value={unitVersion}
                        onChange={(
                          newValue: SingleValue<{
                            label: string;
                            value: string;
                          }>
                        ) => {
                          if (newValue?.value) {
                            setUnitVersion(newValue);
                          }
                        }}
                      />

                      <div>
                        <h5 className="text-lg font-bold">Easy</h5>
                        <p>
                          Sum Test Weight: <b>{easyTestWeight}</b>
                        </p>
                        <p>
                          Total Time Estimate: <b>{easyTime}</b>
                        </p>
                      </div>
                      <div className="py-10">
                        <h5 className="text-lg font-bold">Hard</h5>
                        <p>
                          Sum Test Weight:{" "}
                          <b>{sum(hardSkills.map((es) => es.testWeight))}</b>
                        </p>
                        <p>
                          Total Time Estimate: <b>{hardTime}</b>
                        </p>
                      </div>
                    </td>
                  )}
                  {j === 0 && (
                    <td
                      rowSpan={s.skills.length}
                      className="border border-black"
                    >
                      {" "}
                      <div className="text-center" key={s._id}>
                        <p>{s.name}</p>
                        <p>
                          Easy:{" "}
                          {
                            s.skills.filter((x) => x.difficulty !== "hard")
                              .length
                          }
                        </p>
                        <p>
                          Hard:{" "}
                          {
                            s.skills.filter((x) => x.difficulty !== "easy")
                              .length
                          }
                        </p>
                      </div>
                    </td>
                  )}
                  <td className="border-y border-l border-black p-2">
                    <div>
                      <Button
                        type="link"
                        onClick={() => setSelectedSkillCode(sk.skillCode)}
                        className="cursor-pointer"
                      >
                        {sk.skillName}
                      </Button>
                    </div>
                  </td>
                  <td className="border-y border-black text-center">
                    {sk.skillCode}
                  </td>
                  <td className="border-y border-black text-center">
                    {sk.testWeight}
                  </td>
                  <td
                    className={`border-y border-black text-center ${
                      props.hideTypes ? "border-r" : ""
                    }`}
                  >
                    {sk.difficulty}
                  </td>
                  {!props.hideTypes && (
                    <>
                      <td className="border-y border-black text-center">
                        <div className="flex flex-col">
                          {types.map((x, i) => (
                            <p key={`type-${s._id}-${skData?.skillCode}-${i}`}>
                              {x}
                            </p>
                          ))}
                        </div>
                      </td>
                      <td className="border-y border-r border-black text-center">
                        <div className="flex flex-col">
                          {excludedTypes.map((x, i) => (
                            <p key={`type-${s._id}-${skData?.skillCode}-${i}`}>
                              {x}
                            </p>
                          ))}
                        </div>
                      </td>
                    </>
                  )}
                </tr>
              );
            });
          })
        ) : (
          <tr>
            <td>No Subunits</td>
          </tr>
        )}
      </table>
      <FocusContextProvider>
        <ExampleModal
          skillCode={selectedSkillCode ?? ""}
          visible={selectedSkillCode !== undefined}
          onClose={() => setSelectedSkillCode(undefined)}
          toggleQuestion
        />
      </FocusContextProvider>
    </>
  );
};

export default UnitDetails;
