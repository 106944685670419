import React, { useState } from "react";

import { Dialog, RadioGroup } from "@headlessui/react";
import { TIntegration, TLicenseOption } from "../../utils/quoteUtils";
import { UseMutateFunction } from "react-query";
import {
  IRenewalRequestBody,
  rosteringFullNames,
  lmsFullNames,
} from "../../utils/quoteUtils";
import clsx from "clsx";

interface LicenseQuoteFormProps {
  showUpgradeForm: boolean;
  currentLicense: TLicenseOption | undefined;
  selectedUpgrade: TLicenseOption | undefined;
  currentIntegration: TIntegration | undefined;
  downloadPDF: UseMutateFunction<
    { responseData: any; responseHeaders: any },
    unknown,
    Record<string, unknown>,
    unknown
  >;
  quoteStatus: "" | "loading" | "pdf" | "json" | "error" | "renewingFromTrial";
  setQuoteStatus: React.Dispatch<
    React.SetStateAction<
      "" | "loading" | "pdf" | "json" | "error" | "renewingFromTrial"
    >
  >;
  closeDialog: () => void;
}

const UpgradeLicenseQuoteForm: React.FC<LicenseQuoteFormProps> = ({
  showUpgradeForm,
  currentLicense,
  selectedUpgrade,
  currentIntegration,
  downloadPDF,
  quoteStatus,
  setQuoteStatus,
  closeDialog,
}) => {
  const [integrationChosen, setIntegrationChosen] = useState<string>(
    currentIntegration || "none"
  );

  const possibleIntegrations = [
    { val: "none", display: "None" },
    { val: "clever", display: "Clever" },
    { val: "classlink", display: "ClassLink/OneRoster" },
    { val: "schoology", display: "Schoology" },
    { val: "canvas", display: "Canvas" },
  ];

  const handleIntegrationChange = (integration: TIntegration) => {
    setIntegrationChosen(integration);
  };

  const confirmRenewalRequest = () => {
    const requestBody = {
      type: selectedUpgrade?.type,
      licenseTier: selectedUpgrade?.tier,
      pathId: selectedUpgrade?.pathId,
    };

    if (quoteStatus !== "loading") {
      setQuoteStatus("loading");
      downloadPDF(requestBody);
    }
  };

  const onSumbit = (e: any) => {
    e.preventDefault();

    let integrations = {
      rosteringSystem: "",
      learningManagementSystem: "",
    };

    if (integrationChosen === "clever" || integrationChosen === "classlink") {
      integrations = {
        rosteringSystem:
          rosteringFullNames[integrationChosen as "clever" | "classlink"],
        learningManagementSystem: "",
      };
    } else if (
      integrationChosen === "schoology" ||
      integrationChosen === "canvas"
    ) {
      integrations = {
        learningManagementSystem:
          lmsFullNames[integrationChosen as "schoology" | "canvas"],
        rosteringSystem: "",
      };
    }

    const requestBody = {
      type: selectedUpgrade?.type,
      licenseTier: selectedUpgrade?.tier,
      pathId: selectedUpgrade?.pathId,
      ...integrations,
    };

    if (quoteStatus !== "loading") {
      setQuoteStatus("loading");
      downloadPDF(requestBody);
    }
  };

  //[MB] TODO - some of the variable naming and gneral scheme in here (and some conditional rendering) is a bit confusing or redundant b/c originally we would show a modal in all cases after the card button was clicked. We still want to show a loading modal (which is here) once the button is clicked but should only show a form if the user is upgrading/renewing to integral and needs to select an integration. This is a bit of a refactor so I'm leaving the variable names as is for now but should be cleaned up in the future - probably by splitting this into two components (one for the loading modal and one for the form modal).
  return (
    <Dialog
      id="upgrade-form-dialog"
      open={showUpgradeForm}
      onClose={closeDialog}
      aria-labelledby="upgrade-form-dialog-title"
    >
      <span id="upgrade-form-dialog-title" className="sr-only">
        Quote form dialog
      </span>
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div className="fixed inset-0 min-h-screen content-center p-4">
        {quoteStatus === "" && selectedUpgrade?.tier === "Integral" && (
          <Dialog.Panel
            className={clsx(
              "min-w-2xl relative mx-auto flex max-w-[700px] flex-col rounded-lg bg-white p-6 sm:mt-8 sm:w-3/4 md:w-1/2",
              selectedUpgrade?.tier === "Integral"
                ? "max-h-[90vh]"
                : "max-h-[75vh] sm:max-h-[35vh]"
            )}
          >
            <button
              className="absolute right-0 top-0 mr-2 text-gray-400 hover:text-gray-600"
              onClick={closeDialog}
            >
              <span className="text-2xl">×</span>
            </button>
            <Dialog.Title className="mb-6 mt-4 text-center font-serif font-bold sm:text-2xl">
              Request Quote to{" "}
              {selectedUpgrade.type === currentLicense?.type
                ? "Renew"
                : "Upgrade"}{" "}
              License
            </Dialog.Title>
            <form
              onSubmit={onSumbit}
              className="space-y-6 overflow-y-auto lg:px-8"
            >
              <div>
                <h2 className="mb-1 font-bold">Current License</h2>
                <h3>{currentLicense?.type}</h3>
              </div>

              {selectedUpgrade?.type !== currentLicense?.type && (
                <div className="">
                  <h2 className="font-bold">Upgraded License</h2>
                  <h3>{selectedUpgrade?.type}</h3>
                </div>
              )}
              {selectedUpgrade?.tier === "Integral" && (
                <RadioGroup
                  value={integrationChosen.toLowerCase()}
                  onChange={handleIntegrationChange}
                >
                  <RadioGroup.Label as="h3" className="block font-bold">
                    Integrations
                  </RadioGroup.Label>
                  <div className="mt-2 space-y-1">
                    {possibleIntegrations.map((integration) => (
                      <RadioGroup.Option
                        key={integration.val}
                        value={integration.val}
                        className={({ active, checked }) =>
                          `${
                            active
                              ? "ring-2 ring-dm-brand-blue-500 ring-offset-2"
                              : ""
                          }
                        ${
                          checked
                            ? "bg-dm-earth-yellow-500 bg-opacity-75"
                            : "bg-white"
                        }
                        relative flex cursor-pointer rounded-lg px-5 py-2 shadow-md focus:outline-none lg:py-4`
                        }
                      >
                        {({ active, checked }) => (
                          <>
                            <div className="w-full">
                              <div className="text-center text-sm md:text-lg">
                                <RadioGroup.Label
                                  as="p"
                                  className="font-medium text-gray-900"
                                >
                                  <span className="w-full">
                                    {integration.display}
                                  </span>
                                </RadioGroup.Label>
                              </div>
                            </div>
                          </>
                        )}
                      </RadioGroup.Option>
                    ))}
                  </div>
                </RadioGroup>
              )}

              <button
                type="submit"
                className="mb-2 w-full rounded-md bg-dm-brand-blue-500 p-2 text-white sm:mt-8"
              >
                Download Quote
              </button>
            </form>
          </Dialog.Panel>
        )}

        {["json", "error", "loading"].includes(quoteStatus) && (
          <Dialog.Panel className="min-w-2xl relative mx-auto my-0 mt-0 max-w-[700px] space-y-10 overflow-y-auto rounded-lg bg-white p-6 sm:mt-8 md:h-[30vh] md:w-1/2">
            <Dialog.Title className="mb-6 mt-4 text-center font-serif text-2xl font-bold">
              {quoteStatus === "json"
                ? "Thank you for your request!"
                : quoteStatus === "error"
                ? "Error"
                : "Generating Your Quote..."}
            </Dialog.Title>
            <div className="text-center">
              <p>
                {quoteStatus === "json"
                  ? "Thank you for your request. We cannot automatically provide you with a quote for this license type. Someone from our team will reach out to follow up with you."
                  : quoteStatus === "error"
                  ? "There was an error trying to create your quote. Please refresh this window and try again. If the problem persists please contact DeltaMath support."
                  : ""}
              </p>
            </div>
            {quoteStatus === "loading" && (
              <div className="p-8">
                <div
                  style={{ borderTopColor: "transparent" }}
                  className="mx-auto h-16 w-16 animate-spin rounded-full border-4 border-solid border-blue-400"
                ></div>
              </div>
            )}
          </Dialog.Panel>
        )}
        {quoteStatus === "renewingFromTrial" && (
          <Dialog.Panel className="min-w-2xl relative mx-auto my-0 mt-0 max-w-[700px] space-y-6 overflow-y-auto rounded-lg bg-white p-6 sm:mt-8 md:h-[30vh] md:w-1/2">
            <button
              className="absolute right-0 top-0 mr-2 text-gray-400 hover:text-gray-600"
              onClick={closeDialog}
            >
              <span className="text-2xl">×</span>
            </button>
            <Dialog.Title className="text-center font-serif text-2xl font-bold">
              Confirm PLUS Quote Request
            </Dialog.Title>
            <div className="text-center">
              <p className="text-lg">
                You currently have access to INTEGRAL features on a trial basis.
                If you purchase PLUS you will lose access to these features.
              </p>
            </div>
            <div className="px-16">
              <button
                type="submit"
                className="mb-2 w-full rounded-md bg-dm-brand-blue-500 p-2 text-white sm:mt-8"
                onClick={confirmRenewalRequest}
              >
                Download Quote for PLUS
              </button>
            </div>
          </Dialog.Panel>
        )}
      </div>
    </Dialog>
  );
};

export default UpgradeLicenseQuoteForm;
