import React, { useEffect, useMemo, useState } from "react";
import { DmLoadingSpinner } from "../../../manager/utils/functions";
import { REACT_APP_ADMIN_LINK, useDMQuery } from "../../../utils";
import DataCard from "./DataCard";
import { formatInTimeZone } from "date-fns-tz";
import { eachMonthOfInterval } from "date-fns/esm";
import HeaderCard from "./HeaderCard";
import DeltaMathToggle from "./DeltaMathToggle";
import ScatterPlot from "./ScatterPlot";
import DeltaMathAccordion from "../../../shared/DeltaMathAccordion";
import { DeltaMathSelect } from "../../../shared/DeltaMathSelect";
import {
  PerformanceRequestType,
  filterOptions,
} from "../StudentPerformanceUtils";
import { Link, useNavigate } from "react-router-dom";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import { QuestionMarkCircleIcon } from "@heroicons/react/outline";
import { Transition } from "@headlessui/react";
import LegendDialog from "./LegendDialog";
import { demoString } from "../../../utils/demo";
import { useUserContext } from "../../../shared/contexts/UserContext";
import BackArrowIcon from "../../../shared/icons/BackArrowIcon";
import GridIcon from "../../../shared/icons/GridIcon";
import GraphIcon from "../../../shared/icons/GraphIcon";
import { Tooltip } from "../../../shared/Tooltip";
import clsx from "clsx";

export interface ProgressError {
  code: number;
  message: string;
  statusText: string;
}

const DAY = 86400;
const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const NoDataMessage = () => (
  <p className="py-8 text-neutral-700">
    No data found for your selected scope and data range, please try another
    selection.
  </p>
);

const FilterMessage = (props: { filterType: string }) => (
  <div className="text-sm text-red-500">
    The selected standard and data scope have no data points for{" "}
    {props.filterType}. Change the filter to{" "}
    <span className="font-bold">All Assignments</span> to expand scope beyond{" "}
    {props.filterType}.
  </div>
);
export interface CardDataInterface {
  month: string;
  data: { [key: string]: number | string };
}
interface RowDataInterface {
  key: string;
  label: string;
  cardData: CardDataInterface[];
  meta?: {
    teachers: string | string[];
    schools: string | string[];
  };
}
export interface GraphDataInterface {
  label: string;
  data: {
    flu: number;
    perc: number;
    problemsPerStudent: number;
  };
}
interface TeacherInterface {
  all_time_logs: number;
  annual_logs: number;
  first: string;
  last: string;
  last_login: number;
  schoolPlus: string[];
  teachercode: number;
  _id: number;
}
interface SchoolInterface {
  district: { districtID: string };
  dmLicense: { domains: string[] };
  highGrade: string;
  lowGrade: string;
  schoolName: string;
  schoolid: string;
  timezone: string;
  _id: string;
  sections?: any;
}
interface SectionInterface {
  sectionId: number;
  sectionName: string;
  schools: string[];
  teachers: any[];
}

/**
 * Takes a filterOption key value and returns an object request params to get API data for that filter selection
 * @param selectedKey key value of the selected data filterOption
 * @returns object with the selected filterOption's corresponding request params set
 */
const parseFilterToPreference = (selectedKey: string) => {
  let preferenceObj = {};
  selectedKey === "tests"
    ? (preferenceObj = {
        fetchTests: true,
        fetchPushed: false,
        fetchStandard: false,
      })
    : selectedKey === "pushed-assignments"
    ? (preferenceObj = {
        fetchTests: false,
        fetchPushed: true,
        fetchStandard: false,
      })
    : (preferenceObj = {
        fetchTests: undefined,
        fetchPushed: undefined,
        fetchStandard: undefined,
      });
  return preferenceObj;
};
/**
 * check if an object with a given key is in an array of objects
 * @param obj an object
 * @param list an array (of objects)
 * @param key key value of the object to search for
 * @returns boolean true if an object with the specified key value is found, otherwise false
 */
const objectInArray = (obj: any, list: any[], key: string) => {
  let i;
  for (i = 0; i < list.length; i++) {
    if (list[i][key] === obj[key]) {
      return true;
    }
  }
  return false;
};

/**
 * sort function -- Array.sort(sortByFluency)
 * @param a an array of CardData representing a "row."  The first element should be the "Overall" (average) data of the remaining elements
 * @param b an array of CardData representing a "row."  The first element should be the "Overall" (average) data of the remaining elements
 * @returns an array of CardDataInterface[] sorted by the fluency value of each array element's first index.
 */
const sortByFluency = (
  a: { cardData: CardDataInterface[] },
  b: { cardData: CardDataInterface[] }
) => {
  const fluA = a.cardData[0].data.flu === "--" ? 0 : a.cardData[0].data.flu;
  const fluB = b.cardData[0].data.flu === "--" ? 0 : b.cardData[0].data.flu;

  return fluA > fluB ? -1 : fluA < fluB ? 1 : 0;
};

export const DisplayPerformanceGraphs = ({
  dataScope,
  requestParams,
  setRequestParams,
  filterValue,
  setFilterValue,
  standard,
  FormattedSelection,
  isGlobal,
  standardDescription,
}: {
  dataScope: string;
  requestParams: PerformanceRequestType;
  setRequestParams: (value: any) => void;
  filterValue: string;
  setFilterValue: (value: string) => void;
  standard?: string;
  FormattedSelection: React.ElementType;
  isGlobal: boolean;
  standardDescription?: string;
}) => {
  const navigate = useNavigate();
  const toastContext = useDeltaToastContext();
  const userContext = useUserContext();
  const token = userContext.getJWT();
  const reportEndpoint =
    window.location.href.indexOf("student-performance") > 0
      ? "progress"
      : "usage";

  // get tempPreference value from local storage
  const tempPreferences = JSON.parse(
    localStorage.getItem("tempPreferences") || "{}"
  );

  const [haveResults, setHaveResults] = useState(false);
  const [cardData, setCardData] = useState<RowDataInterface[]>();
  const [graphData, setGraphData] = useState<GraphDataInterface[]>();
  const [progressError, setProgressError] = useState<ProgressError>();
  const [showFluency, setShowFluency] = useState(true);
  const [showGrid, setShowGrid] = useState(true);
  const [incompleteData, setIncompleteData] = useState<any[]>();
  const [sectionsBySchool, setSectionsBySchool] = useState<any[]>();
  const [totalData, setTotalData] = useState<RowDataInterface>();
  const [filterError, setFilterError] = useState(false);

  const { data, refetch, isLoading, error } = useDMQuery({
    cacheKey: [
      `/admin_new/data/${reportEndpoint}`,
      JSON.stringify(requestParams),
    ],
    path: `/admin_new/data/${reportEndpoint}`,
    requestBody: requestParams,
    method: "post",
    additionalHeaders: {
      Authorization: `Bearer ${token}`,
    },
    queryOptions: {
      enabled: false,
      onError: (err: ProgressError) => setProgressError(err),
      retry: 0,
    },
  });

  const { data: schoolData } = useDMQuery({
    path: "/admin_new/data/school",
    queryOptions: {
      enabled: true,
      staleTime: 1000 * 60 * 15,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  });

  const { data: teacherData } = useDMQuery({
    path: "/admin_new/data/teacher",
    queryOptions: {
      staleTime: 1000 * 60 * 15,
    },
  });

  const { data: sectionData } = useDMQuery({
    path: "/admin_new/data/sections",
    queryOptions: {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 15,
    },
  });

  const showNoDataMessage = useMemo(() => {
    return (
      !haveResults && !error && !isLoading && typeof cardData !== "undefined"
    );
  }, [haveResults, error, isLoading, cardData]);

  // Switching to view-data and we have request params, actually make the API request
  useEffect(() => {
    if (
      (typeof requestParams.teacherIds !== "undefined" &&
        requestParams.teacherIds.length > 0) ||
      (typeof requestParams.sectionIds !== "undefined" &&
        requestParams.sectionIds.length > 0) ||
      (typeof requestParams.schoolIds !== "undefined" &&
        requestParams.schoolIds.length > 0)
    ) {
      refetch();
    }
  }, [requestParams]);

  useEffect(() => {
    if (dataScope) {
      let missingDataScope = false;
      switch (dataScope) {
        case "Schools":
          if (
            Array.isArray(requestParams["schoolIds"]) &&
            requestParams["schoolIds"].length < 1
          )
            missingDataScope = true;
          break;
        case "Teachers":
          if (
            Array.isArray(requestParams["teacherIds"]) &&
            requestParams["teacherIds"].length < 1
          )
            missingDataScope = true;
          break;
        case "Sections":
          if (
            Array.isArray(requestParams["sectionIds"]) &&
            requestParams["sectionIds"].length < 1
          )
            missingDataScope = true;
          break;
      }
      if (missingDataScope) {
        toastContext.addToast({
          status: "Error",
          message: "Adjust Data Scope to view data.",
        });
        navigate(
          `${REACT_APP_ADMIN_LINK}/reports/student-performance/data-scope`,
          {
            replace: true,
          }
        );
      }
    }
  }, [dataScope]);

  useEffect(() => {
    if (data) {
      setFilterError(false);
      setHaveResults(Object.keys(data?.data).length > 1 ? true : false);
      /**
       *  - if a filter ("Tests Only" or "Pushed Assignments Only") is applied and no data is returned:
       *    - display a message telling user to try expanding their search to "All"
       *    - do not show Grid or Graphs
       * */
      if (
        Object.keys(data?.data).length < 2 &&
        filterValue === "Tests only"
        // && Object.keys(data).indexOf("hasTests") < 0
      ) {
        setFilterError(true);
        setHaveResults(false);
      } else if (
        Object.keys(data?.data).length < 2 &&
        filterValue === "Pushed assignments only"
        // && Object.keys(data).indexOf("hasPushed") < 0
      ) {
        setFilterError(true);
        setHaveResults(false);
      }
    }
  }, [data]);

  useEffect(() => {
    // FOR DEV TESTING

    if (requestParams.start && requestParams.end) {
      const months = eachMonthOfInterval({
        start: new Date(requestParams.start * 1000),
        end: new Date(requestParams.end * 1000),
      });
      const displayMonths = months.map(
        (month: Date) =>
          new Date(
            formatInTimeZone(month, "America/New_York", "yyyy-MM-dd HH:mm:ss")
          )
      );

      // API call to /progress was successful
      if (data) {
        const displayData: any = [];
        const newGraphData: any = [];
        const newIncompleteData: any = [];
        const schoolsArray: SchoolInterface[] = [];

        if (Object.keys(data).length) {
          Object.keys(data).forEach((key: string) => {
            const newData = displayMonths.map((date: Date) => {
              // default to no data
              let thisEntry = {
                flu: "--",
                perc: "--",
                numStudentAssignments: "--",
                numStudents: "--",
              };
              const displayMonthEpochTime = date.getTime() / 1000;
              const progressDataKeys = Object.keys(data[key]).filter(
                (e) => e !== "total"
              ); // filter out the 'total' entry
              if (progressDataKeys.length > 0) {
                progressDataKeys.map((progressTimestampString: string) => {
                  const parsedProgressTimestamp = parseInt(
                    progressTimestampString
                  );
                  if (
                    Math.abs(displayMonthEpochTime - parsedProgressTimestamp) <=
                    DAY
                  ) {
                    // within 1 day in either direction
                    thisEntry = data[key][parsedProgressTimestamp];
                  }
                });
              }
              return {
                month: `${MONTHS[date.getMonth()]} ${date.getFullYear()}`,
                data: thisEntry,
              };
            });
            // set "Overall" data as start of array
            newData.unshift({
              month: "Overall",
              data: {
                numStudentAssignments: data[key].total.numStudentAssignments,
                numStudents: data[key].total.numStudents,
                flu: data[key].total.flu || "--",
                perc: data[key].total.perc || "--",
              },
            });

            const section: SectionInterface = sectionData.find(
              (sec: any) => sec.sectionId === parseInt(key)
            );
            if (section) {
              const getSchoolBySectionId = (secId: any) => {
                const section = sectionData.find(
                  (sec: any) => sec.sectionId === parseInt(secId)
                );
                if (section && Array.isArray(section.schools)) {
                  const school = schoolData.find(
                    (sch: any) => section.schools.indexOf(sch.schoolid) >= 0
                  );
                  return school;
                }
              };
              const school = getSchoolBySectionId(key);
              if (dataScope === "Sections") {
                key !== "data" &&
                  !objectInArray(school, schoolsArray, "_id") &&
                  schoolsArray.push(school);
              }

              schoolsArray.forEach((school: SchoolInterface) => {
                if (section.schools.includes(school.schoolid)) {
                  Array.isArray(school.sections) &&
                  !objectInArray(section, school.sections, "sectionId")
                    ? school["sections"].push(section)
                    : (school["sections"] = [section]);
                }
              });
            }

            const dataLabel: string =
              key === "data"
                ? "Total"
                : dataScope === "Schools"
                ? schoolData.find((school: any) => school.schoolid === key)
                    .schoolName
                : dataScope === "Teachers"
                ? `${
                    teacherData.find(
                      (teacher: any) => teacher._id === parseInt(key)
                    ).first
                  } ${
                    teacherData.find(
                      (teacher: any) => teacher._id === parseInt(key)
                    ).last
                  }`
                : dataScope === "Sections"
                ? sectionData.find((section: any) =>
                    section.sectionId === parseInt(key)
                      ? section.sectionName
                      : null
                  ).sectionName
                : "Total";

            // only add to chart for individual selections (School, Teacher, Section) that have Student Count data
            if (dataLabel !== "Total") {
              if (data[key].total.numStudentAssignments) {
                newGraphData.push({
                  label: parseDemoProps({
                    label: dataLabel,
                    dataScope: dataScope,
                  }),
                  data: {
                    flu: data[key].total.flu || 0,
                    perc: data[key].total.perc || 0,
                    problemsPerStudent:
                      data[key].total.numStudentAssignments /
                      data[key].total.numStudents,
                  },
                });
              } else
                newIncompleteData.push({
                  label: parseDemoProps({
                    label: dataLabel,
                    dataScope: dataScope,
                  }),

                  data: {
                    flu: data[key].total.flu || 0,
                    perc: data[key].total.perc || 0,
                    problemsPerStudent: Math.floor(
                      data[key].total.numStudentAssignments /
                        data[key].total.numStudents
                    ),
                  },
                });
            }
            // dataLabel !== "Total" &&
            //   data[key].total.numStudentAssignments &&
            //   newGraphData.push({
            //     label: dataLabel,
            //     data: {
            //       flu: data[key].total.flu || 0,
            //       perc: data[key].total.perc || 0,
            //       problemsPerStudent: data[key].total.numStudentAssignments,
            //       numStudents: data[key].total.numStudents
            //     },
            //   });

            // if (!data[key].total.numStudentAssignments) {
            //   setIncompleteData(true);
            // }

            /**
             *
             * @param teachId the teacher id whose school you would like to find
             * @return first School found that matches an entry on the teachers SchoolPlus array
             */
            const getTeacherSchool: any = (teachId: string) => {
              const teacherInfo: TeacherInterface = teacherData.find(
                (teacher: any) => teacher._id === parseInt(teachId)
              );
              const teacherSchoolId = teacherInfo.schoolPlus.find(
                (id: string) =>
                  schoolData.find(
                    (school: SchoolInterface) => id === school.schoolid
                  )
              );
              const schoolInfo = schoolData.find(
                (school: SchoolInterface) => teacherSchoolId === school.schoolid
              );
              return schoolInfo;
            };

            /**
             *
             * @param secId section ID
             * @returns array of teacher names for the requested section
             */
            const getSectionTeachers: any = (secId: string) => {
              const sectionInfo = sectionData.find(
                (section: any) => section.sectionId === parseInt(secId)
              );
              return sectionInfo.teachers.map(
                (teacher: { _id: number; teachercode: number; name: string }) =>
                  teacher.name
              );
            };

            const metaLabel =
              key === "data"
                ? null
                : dataScope === "Schools"
                ? null
                : dataScope === "Teachers"
                ? {
                    schools: getTeacherSchool(key)?.schoolName || null,
                  }
                : dataScope === "Sections"
                ? {
                    teachers: getSectionTeachers(key) || [],
                    schools: sectionData
                      .find(
                        (section: any) => section.sectionId === parseInt(key)
                      )
                      .schools.map(
                        (schoolID: any) =>
                          schoolData.find(
                            (school: any) => school.schoolid === schoolID
                          ).schoolName
                      ),
                  }
                : null;

            displayData.push({
              key: key,
              label: dataLabel,
              cardData: newData,
              meta: metaLabel,
            });
          });
        }

        const sortedData: RowDataInterface[] = displayData.sort(sortByFluency);

        if (schoolsArray.length > 1) {
          /**
           * sort schools array by the highest fluency value of their associated sections
           * @param a school to compare
           * @param b school to compare
           */
          const sortFn = (a: SchoolInterface, b: SchoolInterface) => {
            const aIndex = sortedData.findIndex((section: RowDataInterface) =>
              section.meta?.schools.includes(a.schoolName)
            );
            const bIndex = sortedData.findIndex((section: RowDataInterface) =>
              section.meta?.schools.includes(b.schoolName)
            );
            return aIndex < bIndex ? -1 : aIndex > bIndex ? 1 : 0;
          };
          schoolsArray.sort(sortFn);
          setSectionsBySchool(schoolsArray);
        }

        let index = 0;
        for (const rowEntry of sortedData) {
          if (rowEntry.label === "Total") {
            const totalRow = sortedData.splice(index, 1);
            const parsedData =
              displayData.length > 1
                ? totalRow.concat(displayData)
                : displayData;
            setCardData(parsedData);
          }
          index++;
        }
        setGraphData(newGraphData);
        setIncompleteData(newIncompleteData);
      }
    }
  }, [data]);

  useEffect(() => {
    if (cardData) {
      setTotalData(
        cardData.find((row: RowDataInterface) => row.label === "Total")
      );
    }
  }, [cardData]);

  const DisplayTotalRow = (rowData: RowDataInterface) => {
    const acc: number[] = [];
    rowData.cardData.map((item: any) => {
      if (item.month === "Overall") acc.push(0);
      else if (item.data.studentCount === "--") acc.push(0);
      else acc.push(item.data.studentCount);
    });
    return (
      <ul className="class">
        <div className="grid grid-cols-1 overflow-x-scroll sm:grid-cols-8">
          <HeaderCard
            dataScope={dataScope}
            title={rowData.label}
            meta={rowData.meta}
          />
          <li
            key={rowData.key}
            className={`grid-cols-${cardData?.length} ml-2 border-b-2 sm:col-span-7`}
          >
            <div className={`mt-2 grid grid-flow-col`}>
              {rowData.cardData.map((card: CardDataInterface) => (
                <DataCard
                  key={
                    rowData.key +
                    card.month +
                    card.data.flu.toString() +
                    card.data.perc.toString()
                  }
                  title={card.month}
                  stats={card?.data}
                  showFluency={showFluency}
                  isTotal={rowData.label === "Total" ? true : false}
                  isOverall={card.month === "Overall"}
                  allValuesInRow={acc}
                />
              ))}
            </div>
          </li>
        </div>
      </ul>
    );
  };

  /**
   * @param selectedKey key value of selected filterOption
   * updates component state based on filter selection
   */
  function handleFilterSelection(selectedKey: string) {
    /** update localStorage.tempPreferences.performanceViewPref when a new selection is made */
    localStorage.setItem(
      "tempPreferences",
      JSON.stringify({
        ...tempPreferences,
        performanceViewPref: parseFilterToPreference(selectedKey),
      })
    );
    setFilterValue(selectedKey);

    /** update requestParams value and trigger refetch for new data */
    const newFilterParams = parseFilterToPreference(selectedKey);
    setRequestParams({
      ...requestParams,
      ...newFilterParams,
    });
  }

  const [legendOpen, setLegendOpen] = useState(false);
  const handleInfoClick = () => {
    setLegendOpen(!legendOpen);
  };

  return (
    <>
      {!isGlobal && (
        <div id="report-header" className="border border-b-2 bg-white px-6">
          <Link
            to={`${REACT_APP_ADMIN_LINK}/reports/student-performance/select-standard`}
            className="align-center inline-flex py-4 font-bold"
          >
            <BackArrowIcon classes="mt-1.5" />
            <span className="pl-2 text-dm-brand-blue-500">
              Back to Standards
            </span>
          </Link>
          <h2 className="font-serif text-2xl font-bold">
            Student Performance Data
          </h2>
          <div className="flex flex-row justify-between pb-4">
            <div className="flex flex-row space-x-2">
              <FormattedSelection />
            </div>
          </div>

          <div className="flex flex-row justify-between pb-8">
            <span className="flex">
              <DeltaMathSelect
                label={""}
                defaultVal={"all"}
                options={filterOptions}
                onChangeFn={handleFilterSelection}
                value={filterValue}
              />
              <button
                className="relative z-10 ml-2 mt-2 flex flex-row text-sm"
                onClick={handleInfoClick}
              >
                <div>
                  <QuestionMarkCircleIcon
                    className={
                      "group mt-2.5 h-4 w-4 flex-shrink-0 text-gray-400 hover:cursor-pointer"
                    }
                  />
                </div>
                <span className="ml-1 mt-2 whitespace-nowrap">Help </span>
              </button>
              <Transition
                as="div"
                show={true}
                appear={true}
                enter="transition-opacity duration-1000"
                enterFrom="transform opacity-0"
                enterTo="transform opacity-100"
                leave="transition-opacity duration-1000"
                leaveFrom="transform opacity-100"
                leaveTo="transform opacity-0"
              >
                <LegendDialog
                  isOpen={legendOpen}
                  setIsOpen={setLegendOpen}
                  axisOption={showFluency ? "Fluency" : "Completion"}
                />
              </Transition>
            </span>
          </div>
        </div>
      )}

      <div className={clsx(isGlobal ? "" : "p-8", "bg-backgroun-blue-100")}>
        <div id={"report-body"} className="rounded-md border bg-white p-6">
          {error && !isLoading && (
            <div className="text-red-500">{progressError?.message}</div>
          )}
          {cardData && Array.isArray(cardData) && (
            <div>
              <div className="flex w-full justify-between">
                <div className="inline-flex flex-col items-start justify-start gap-2">
                  <div className="font-['Lora'] text-lg font-bold leading-relaxed text-neutral-900">
                    {standard}
                  </div>
                  <div className="mr-4 max-w-lg text-justify font-['Lato'] text-sm font-normal leading-relaxed text-neutral-900">
                    {standardDescription}
                  </div>
                </div>
                <div className="space-x-14 sm:flex xl:justify-between">
                  {!showNoDataMessage && (
                    <>
                      <DeltaMathToggle
                        optionA="Fluency"
                        optionB="Completion"
                        aSelected={showFluency}
                        onChangeFn={() => setShowFluency(!showFluency)}
                      />
                      <div className="py-2">
                        <Tooltip message="Grid">
                          <button onClick={() => setShowGrid(!showGrid)}>
                            <GridIcon
                              classes={
                                showGrid
                                  ? "stroke-dm-gray-600"
                                  : "stroke-dm-gray-200"
                              }
                            />
                          </button>
                        </Tooltip>

                        <Tooltip
                          message={
                            "Graph"
                            // Array.isArray(graphData) && graphData.length < 2
                            //   ? "Expand Data Scope to view graphs"
                            //   : "Graph"
                          }
                        >
                          <button
                            disabled={
                              Array.isArray(graphData) && graphData.length < 2
                            }
                            onClick={() => setShowGrid(!showGrid)}
                          >
                            <GraphIcon
                              classes={
                                showGrid
                                  ? "fill-dm-gray-200"
                                  : "fill-dm-gray-600"
                              }
                            />
                            {/* <i
                                    className={clsx(
                                      showGrid
                                        ? "text-dm-gray-200" :,
                                      "fas fa-chart-scatter fa-lg"
                                    )} */}
                            {/* ></i> */}
                          </button>
                        </Tooltip>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
          {isLoading && <DmLoadingSpinner message="Loading..." />}
          {showNoDataMessage && <NoDataMessage />}
          {filterError && !isLoading && (
            <FilterMessage filterType={filterValue} />
          )}

          {!error &&
            !isLoading &&
            graphData &&
            Array.isArray(graphData) &&
            haveResults &&
            !showGrid && (
              <Transition
                as="div"
                show={true}
                appear={true}
                enter="transition-opacity duration-300"
                enterFrom="transform opacity-0"
                enterTo="transform opacity-100"
                leave="transition-opacity duration-300"
                leaveFrom="transform opacity-100"
                leaveTo="transform opacity-0"
              >
                <div className="grid grid-flow-row">
                  <ScatterPlot
                    title={`${
                      showFluency ? "Fluency" : "Completion"
                    } by ${dataScope.slice(
                      0,
                      dataScope.length - 1
                    )} for Selected Standard`}
                    data={graphData}
                    yAxisVariable={showFluency ? "flu" : "perc"}
                  />
                  {Array.isArray(incompleteData) && incompleteData.length > 0 && (
                    <div className="mx-8 text-gray-500">
                      <p className="text-md font-semibold">{`${dataScope} Selected with No Data`}</p>
                      <ul>
                        {incompleteData
                          .map(
                            (dataPoint: GraphDataInterface) => dataPoint.label
                          )
                          .join(", ")}
                      </ul>
                    </div>
                  )}
                </div>
              </Transition>
            )}

          {!error &&
            !isLoading &&
            cardData &&
            Array.isArray(cardData) &&
            haveResults &&
            showGrid &&
            Array.isArray(sectionsBySchool) &&
            sectionsBySchool.length > 1 &&
            haveResults && (
              // display school accordions if many sections were selected
              <>
                <Transition
                  as="div"
                  show={true}
                  appear={true}
                  enter="transition-opacity duration-300"
                  enterFrom="transform opacity-0"
                  enterTo="transform opacity-100"
                  leave="transition-opacity duration-300"
                  leaveFrom="transform opacity-100"
                  leaveTo="transform opacity-0"
                >
                  {totalData && <DisplayTotalRow {...totalData} />}
                  {cardData.length < 100 ? (
                    sectionsBySchool.map((school: SchoolInterface) => {
                      return (
                        <DeltaMathAccordion
                          key={school.schoolName}
                          title={school.schoolName}
                          renderExpanded={true}
                          content={
                            <div>
                              {cardData.map((row: RowDataInterface) => (
                                <div key={row.key}>
                                  {school.sections.map(
                                    (section: SectionInterface) => {
                                      if (
                                        parseInt(row.key) === section.sectionId
                                      ) {
                                        const acc: number[] = [];
                                        row.cardData.map((item: any) => {
                                          if (item.month === "Overall")
                                            acc.push(0);
                                          else if (
                                            item.data.studentCount === "--"
                                          )
                                            acc.push(0);
                                          else acc.push(item.data.studentCount);
                                        });
                                        return (
                                          <ul
                                            className=""
                                            key={row.key + section.sectionId}
                                          >
                                            <div className="grid grid-cols-1 sm:grid-cols-8">
                                              <HeaderCard
                                                dataScope={dataScope}
                                                title={row.label}
                                                meta={row.meta}
                                                sameSchool={false}
                                              />
                                              <li
                                                key={row.key}
                                                className={`grid-cols-${cardData.length} ml-2 border-b-2 sm:col-span-7`}
                                              >
                                                <div
                                                  className={`mt-2 grid grid-flow-col`}
                                                >
                                                  {row.cardData.map(
                                                    (
                                                      card: CardDataInterface
                                                    ) => (
                                                      <DataCard
                                                        key={
                                                          row.key +
                                                          card.month +
                                                          card.data.flu.toString() +
                                                          card.data.perc.toString()
                                                        }
                                                        title={card.month}
                                                        stats={card?.data}
                                                        showFluency={
                                                          showFluency
                                                        }
                                                        isTotal={
                                                          row.label === "Total"
                                                            ? true
                                                            : false
                                                        }
                                                        isOverall={
                                                          card.month ===
                                                          "Overall"
                                                        }
                                                        allValuesInRow={acc}
                                                      />
                                                    )
                                                  )}
                                                </div>
                                              </li>
                                            </div>
                                          </ul>
                                        );
                                      }
                                    }
                                  )}
                                </div>
                              ))}
                            </div>
                          }
                          showExpand={true}
                        />
                      );
                    })
                  ) : (
                    <div>
                      <p className="mt-2">{`Compare up to 100 ${dataScope} at a time.`}</p>
                    </div>
                  )}
                </Transition>
              </>
            )}

          {!error &&
            !isLoading &&
            cardData &&
            Array.isArray(cardData) &&
            !sectionsBySchool &&
            !filterError &&
            showGrid &&
            haveResults && (
              <Transition
                as="div"
                show={true}
                appear={true}
                enter="transition-opacity duration-300"
                enterFrom="transform opacity-0"
                enterTo="transform opacity-100"
                leave="transition-opacity duration-300"
                leaveFrom="transform opacity-100"
                leaveTo="transform opacity-0"
              >
                <div
                  className="mt-4 overflow-x-auto"
                  key={JSON.stringify(requestParams)}
                >
                  {cardData.length < 100 ? (
                    cardData.map((row: RowDataInterface) => {
                      const acc: number[] = [];
                      row.cardData.map((item: any) => {
                        if (item.month === "Overall") acc.push(0);
                        else if (item.data.numStudentAssignments === "--")
                          acc.push(0);
                        else acc.push(item.data.numStudentAssignments);
                      });
                      return (
                        <ul key={row.key}>
                          <div className="grid grid-cols-12 sm:w-[200%]">
                            <div className="sticky left-0 col-span-1 my-auto h-full bg-white shadow-xl">
                              <HeaderCard
                                dataScope={dataScope}
                                title={row.label}
                                meta={row.meta}
                                sameSchool={true}
                              />
                            </div>
                            <li
                              key={row.key}
                              className={`grid-cols-${cardData.length} col-span-11 border-b-2`}
                            >
                              <div
                                className={`mt-2 grid grid-flow-col grid-cols-12`}
                              >
                                {row.cardData.map((card: CardDataInterface) => (
                                  <DataCard
                                    key={
                                      row.key +
                                      card.month +
                                      card.data.flu.toString() +
                                      card.data.perc.toString()
                                    }
                                    title={card.month}
                                    stats={card?.data}
                                    showFluency={showFluency}
                                    isTotal={
                                      row.label === "Total" ? true : false
                                    }
                                    isOverall={card.month === "Overall"}
                                    allValuesInRow={acc}
                                  />
                                ))}
                              </div>
                            </li>
                          </div>
                        </ul>
                      );
                    })
                  ) : (
                    <div>
                      {totalData && <DisplayTotalRow {...totalData} />}
                      <p className="mt-2">{`Compare up to 100 ${dataScope} at a time.`}</p>
                    </div>
                  )}
                </div>
              </Transition>
            )}
        </div>
      </div>
    </>
  );
};

const parseDemoProps = ({
  label,
  dataScope,
}: {
  label: string;
  dataScope: string;
}) => {
  let result;
  switch (dataScope) {
    case "Schools":
      result = demoString({ value: label, type: "school_name" });
      break;
    case "Teachers":
      {
        const fullName = label.split(" ");
        const first = demoString({ value: fullName[0], type: "person_first" });
        const last = demoString({ value: fullName[1], type: "person_last" });
        result = first + " " + last;
      }
      break;
    case "Sections":
      result = demoString({ value: label, type: "section_name" });
      break;
    default:
      result = "";
      break;
  }
  return result;
};
