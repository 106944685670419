import { format } from "date-fns/esm";
import clsx from "clsx";
import {
  LearnerAssignmentSkillData,
  LearnerAssignmentTypes,
  SimplifiedLearnerAssignment,
  Subunit,
  Unit,
} from "../types";
import { clone, findIndex } from "lodash";
import { useState } from "react";
import Accordion from "./Accordion";
import { Link } from "react-router-dom";
import { useCourseContext } from "../contexts/CourseContext";
import { REACT_APP_LEARNER_LINK } from "../../utils";
import { SparkleIcon } from "../../shared/icons/SparkleIcon";

export const TestSummaryItem: React.FC<{
  courseId: string;
  unitId: string | undefined;
  assignment: SimplifiedLearnerAssignment;
  type: LearnerAssignmentTypes;
}> = ({ courseId, unitId, assignment, type }) => {
  const courseContext = useCourseContext();
  const courseData = courseContext.getCourseData(courseId);
  const unitData = courseContext.getUnitData(unitId, courseId);
  const baseUrl =
    type === "unitTest"
      ? `${REACT_APP_LEARNER_LINK}/${courseData?.path || courseId}/${
          unitData?.path || unitId
        }/unittest`
      : `${REACT_APP_LEARNER_LINK}/${courseData?.path || courseId}/coursetest`;
  const getSectionName = (subunitId?: string) =>
    unitData?.subunits?.find((subunit: Subunit) => subunit.id === subunitId)
      ?.name;
  const getUnitName = (unitId?: string) =>
    courseData?.units?.find((unit: Unit) => unit.id === unitId)?.name;

  const date =
    assignment?.submitted &&
    format(new Date(assignment.submitted), "MMM do, yyyy, h:mmaaa");
  const score = Math.round((assignment?.grade || 0) * 100);
  const durationMins = Math.round((assignment.duration || 0) / 60);

  const isFirst = assignment.current === 1;

  const scoreDiff =
    assignment.scoreDifference !== undefined
      ? Math.round(assignment.scoreDifference * 100)
      : undefined;

  const correctFraction = () => {
    const numberCorrect = assignment.skills.filter(
      (s: LearnerAssignmentSkillData) => s.score === 1
    ).length;
    const numberTotal = assignment.skills.length;
    return `(${numberCorrect} out of ${numberTotal})`;
  };

  type skillGraphType = {
    sectionName: string;
    total: number;
    correct: number;
    refId: string;
  };

  const skillGraphData: Array<skillGraphType> = assignment.skills.reduce(
    (acc: any, val: LearnerAssignmentSkillData) => {
      if (type === "unitTest" && val.subunitId === undefined) return null;
      if (type === "courseTest" && val.unitId === undefined) return null;

      const accTotal = clone(acc);
      const currIndex = accTotal.findIndex((section: any) =>
        type === "unitTest"
          ? section.refId === val.subunitId
          : section.refId === val.unitId
      );

      const isNewSection = currIndex === -1;
      const sectionName = isNewSection
        ? type === "unitTest"
          ? getSectionName(val.subunitId)
          : getUnitName(val.unitId)
        : acc[currIndex].sectionName;
      const total = isNewSection ? 1 : acc[currIndex].total + 1;
      let correct = isNewSection ? 0 : acc[currIndex].correct;
      if (val.score === 1) correct++;

      const payload = {
        sectionName: sectionName,
        total: total,
        correct: correct,
        refId: type === "unitTest" ? val.subunitId : val.unitId,
      };

      if (currIndex >= 0) {
        accTotal[currIndex] = payload;
      } else {
        accTotal.push(payload);
      }

      return accTotal;
    },
    []
  );

  const firstIncorrect = () => {
    const firstWrong = findIndex(
      assignment.skills,
      (skill: LearnerAssignmentSkillData) => skill.score === 0
    );

    return `${baseUrl}/${firstWrong}${
      !isFirst && assignment.submitted
        ? `/${Number(new Date(assignment.submitted))}`
        : ""
    }`;
  };

  const [isOpen, setIsOpen] = useState<boolean>(!!isFirst);

  return (
    <Accordion
      key={`attempt-${assignment._id}`}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      isSummaryPage={true}
      titleButton={date}
      panel={
        <div
          className="mb-6 px-10 text-sm text-gray-500"
          key={`attemptPanel-${assignment._id}`}
        >
          <h3 className="mb-2 mt-1 w-full text-left text-base font-bold text-dm-charcoal-500">
            Test Stats
          </h3>
          <div className="mx-auto grid grid-cols-1 gap-x-6 gap-y-2 text-dm-charcoal-500 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            <div className="rounded-2xl border border-dm-charcoal-100 p-6 text-left">
              <h4 className="flex items-center justify-between align-middle">
                <span>
                  <i
                    className="fas fa-percentage pr-2 align-middle text-xl leading-none"
                    aria-hidden="true"
                  ></i>
                  <span className="align-middle">Score</span>
                </span>
                {scoreDiff !== undefined && scoreDiff > 0 && (
                  <div className="rounded-lg bg-dm-success-500 px-3 py-2 leading-none text-white">
                    {`+${scoreDiff}%`}
                  </div>
                )}
              </h4>
              <h5 className="mt-3 font-serif text-2xl font-bold">
                {score}% {correctFraction()}
              </h5>
            </div>
            <div className="rounded-2xl border border-dm-charcoal-100 p-6">
              <h5 className="text-left">
                <i
                  className="far fa-clock pr-2 align-middle text-xl leading-none"
                  aria-hidden="true"
                ></i>
                <span className="align-middle">Time Spent</span>
              </h5>
              <p className="mt-3 text-left font-serif text-2xl font-bold">
                {durationMins + " min" + (durationMins === 1 ? "" : "s")}
              </p>
            </div>
            <div className="rounded-2xl border border-dm-charcoal-100 p-6">
              <h5 className="text-left">
                <SparkleIcon className="h-7 w-7 pr-2 align-middle" />
                <span className="align-middle">Points Earned</span>
              </h5>
              <p className="mt-3 text-left font-serif text-2xl font-bold">
                {assignment.pointsEarned || 0}{" "}
                {assignment.pointsEarned === 1 ? "pt" : "pts"}
              </p>
            </div>
          </div>
          <div className="mt-6 rounded-2xl bg-dm-background-blue-100 p-6">
            <h3 className="mb-6 w-full text-left text-base font-bold text-dm-charcoal-500">
              {type === "unitTest" ? "Section Breakdown" : "Unit Breakdown"}
            </h3>
            <div className="dm-bargraph overflow-x-auto overflow-y-hidden">
              <div className="flex h-[400px] w-full flex-grow flex-row">
                <div className="mr-2 flex min-w-10 basis-10 flex-col content-center items-end justify-between text-right text-sm font-bold text-dm-gray-200">
                  <div className="spacer basis-6"></div>
                  <div className="leading-none">100%</div>
                  <div className="flex flex-grow items-center leading-none">
                    50%
                  </div>
                  <div className="leading-none">0%</div>
                </div>
                {skillGraphData.map(
                  (section: skillGraphType, sectionIndex: number) => (
                    <div
                      key={`bargraphCol-${assignment._id}-${section.refId}-${sectionIndex}`}
                      className="mr-5 flex min-w-20 flex-1 flex-col last-of-type:mr-0 lg:mr-7 xl:mr-12"
                    >
                      <div className="basis-6 font-sans text-xs text-dm-charcoal-800">{`${section.correct}/${section.total} correct`}</div>
                      {Array.from({ length: section.total }, (_, i) => i).map(
                        (el, index) => {
                          const isWrong = section.total - section.correct > el;
                          return (
                            <div
                              key={`repeater-${section.refId}-$${assignment._id}-{sectionIndex}-${el}`}
                              className={clsx(
                                "flex-grow",
                                index === 0
                                  ? "rounded-t-md md:rounded-t-lg"
                                  : "border-t",
                                isWrong
                                  ? "border-t-dm-gray-200 bg-dm-charcoal-100"
                                  : "border-t-dm-brand-blue-200 bg-dm-brand-blue-500"
                              )}
                            ></div>
                          );
                        }
                      )}
                    </div>
                  )
                )}
              </div>
              <div className="mt-2 flex w-full flex-grow flex-row">
                <div className="spacer mr-2 min-w-10 basis-10"></div>
                {skillGraphData.map(
                  (section: skillGraphType, labelIndex: number) => (
                    <div
                      key={`bargraphLabel-${assignment._id}-${section.refId}-${labelIndex}`}
                      className="mr-5 flex min-w-20 flex-1 flex-col font-sans text-xs text-dm-charcoal-800 last-of-type:mr-0 lg:mr-7 xl:mr-12"
                    >
                      <h4 className="font-bold">
                        {type === "unitTest" ? "Section" : "Unit"}{" "}
                        {labelIndex + 1}
                      </h4>
                      <h5>{section.sectionName}</h5>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
          {assignment?.grade < 1 && (
            <div className="mt-6 flex flex-col items-center justify-between gap-2 rounded-2xl border border-dm-charcoal-100 p-6 sm:flex-row">
              <h3 className="text-left text-base font-bold text-dm-charcoal-500">
                Ready for what&rsquo;s next?
              </h3>
              <Link
                to={firstIncorrect()}
                className={
                  "rounded bg-dm-brand-blue-500 px-8 py-1 text-sm leading-6 text-white hover:bg-dm-brand-blue-600 active:bg-dm-brand-blue-800 disabled:cursor-not-allowed disabled:opacity-50 max-md:w-full"
                }
              >
                Review Incorrect Answers
              </Link>
            </div>
          )}
        </div>
      }
    />
  );
};
