import clsx from "clsx";
import { FormattedLearnerSubscriptionPlan } from "../../../types";
import { formatPrice } from "../../../utils/formatPrice";
import { DiscountBanner } from "./DiscountBanner";
import { useHomepageSignupContext } from "../HomepageSignupContext";

export const PriceCard: React.FC<{
  plan: FormattedLearnerSubscriptionPlan;
  title: string;
  type: "month" | "year";
  discount?: number;
  displayOnly?: boolean;
}> = ({ plan, title, type, discount, displayOnly = false }) => {
  const { numLearners, selectedPlan, setSelectedPlan } =
    useHomepageSignupContext();
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (!displayOnly && (e.key === "Enter" || e.key === " ")) {
      e.preventDefault();
      setSelectedPlan(plan);
    }
  };

  const billingCopy = type === "month" ? "billed monthly" : `billed yearly`;

  return (
    <div
      role={displayOnly ? "figure" : "button"}
      onClick={() => !displayOnly && setSelectedPlan(plan)}
      onKeyDown={handleKeyDown}
      tabIndex={0}
      className={clsx(
        "relative flex-1 rounded-2xl border-[3px] bg-white p-6 text-dm-charcoal-800",
        displayOnly || plan._id === selectedPlan?._id
          ? "border-dm-brand-blue-500"
          : "border-dm-brand-blue-100"
      )}
    >
      <h5 className="mb-4 text-base font-bold">{title}</h5>
      <div>
        <span className="text-3xl font-bold !leading-10">
          {formatPrice({
            value:
              plan.learnerPrice.value +
              plan.additionalLearnerPrice.value * (numLearners - 1),
            currency: plan.learnerPrice.currency,
          })}
        </span>
        <span>/{type}</span>
      </div>
      <p className="text-sm !leading-[26px] text-dm-charcoal-500">
        {billingCopy} for {numLearners} learner{numLearners > 1 ? "s" : ""}
      </p>
      {!!discount && <DiscountBanner discount={discount} />}
    </div>
  );
};
