import { useCallback, useEffect, useState } from "react";
import Button from "../../../../student/components/generic/button";
import { NavLink } from "react-router-dom";
import {
  REACT_APP_HOMEPAGE_LINK,
  REACT_APP_LEARNER_LINK,
} from "../../../../utils";
import { SignupForm } from "./SignupForm";
import { GoogleLoginButton } from "../../../../manager/components/GoogleLoginButton";
import { processLoginResponse } from "../../../../utils/auth/processLoginResponse";
import { useUserContext } from "../../../../shared/contexts/UserContext";
import { GoogleLoginResponse } from "../../../../utils/auth/types";
import { useHomepageSignupContext } from "../HomepageSignupContext";
import { Infotip } from "../../../../shared/Infotip";
import clsx from "clsx";
import { useLearnerAnalytics } from "../../../analytics/useLearnerAnalytics";
import { homepageSignupStepEvent } from "../../../analytics/events";

export const SignupLanding: React.FC = () => {
  const { track } = useLearnerAnalytics();
  const userContext = useUserContext();
  const { accountType, setAccountType } = useHomepageSignupContext();
  const [isOver13, setIsOver13] = useState(false);
  const [showSignupForm, setShowSignupForm] = useState(false);
  const [initialSignupDataFromGoogle, setInitialSignupDataFromGoogle] =
    useState<{
      first: string;
      last: string;
      email: string;
    } | null>(null);

  const handleGoogleLogin = useCallback(
    (res: GoogleLoginResponse) => {
      if (res.found === false) {
        const { given_name, family_name, email } = res.ticket.payload;
        setInitialSignupDataFromGoogle({
          first: given_name,
          last: family_name,
          email,
        });
        setShowSignupForm(true);
      } else {
        processLoginResponse(res, userContext);
      }
    },
    [userContext]
  );

  useEffect(() => {
    track(homepageSignupStepEvent("signup"));
    // Only run on first render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-col items-center px-4 pb-12 pt-14 sm:px-8 sm:py-12">
      <button
        className="group absolute left-4 top-4 p-1 text-xl text-dm-gray-200 hover:text-dm-gray-500 sm:left-6 sm:top-5"
        onClick={() => setAccountType(undefined)}
      >
        <i className="far fa-arrow-left" />
        <span className="sr-only">Back</span>
      </button>

      <h3 className="mb-6 text-center font-serif text-lg font-bold text-dm-brand-blue-600 sm:mb-10 sm:text-2xl">
        Create your account
      </h3>

      <div className="w-full sm:w-[300px]">
        <div className="mb-10 flex flex-col gap-2 sm:gap-4">
          <h4 className="flex h-12 items-center justify-center gap-4 rounded-full bg-dm-brand-blue-800 font-serif text-white sm:text-lg">
            {accountType === "parent" ? (
              <i className="far fa-users" />
            ) : (
              <i className="far fa-user" />
            )}
            {accountType === "parent" ? "I'm a Parent" : "I'm a Learner"}
          </h4>
          <button
            className="sm:text-md text-sm text-dm-brand-blue-500 underline-offset-2 hover:text-dm-brand-blue-600 hover:underline"
            onClick={() =>
              setAccountType(accountType === "parent" ? "learner" : "parent")
            }
          >
            {accountType === "parent"
              ? "Oops, I'm actually a learner"
              : "Oops, I'm actually a parent"}
          </button>
        </div>

        {!showSignupForm && accountType === "learner" && (
          <label className="mb-6 mt-2 flex items-center gap-2">
            <input
              type="checkbox"
              checked={isOver13}
              onChange={() => setIsOver13(!isOver13)}
            />
            I am 13 or older
            <Infotip
              message={
                <span className="inline-block w-48 whitespace-normal">
                  DeltaMath for Home is open to all ages but requires all
                  learners to be at least 13 years old in order to sign up as an
                  independent leaner
                </span>
              }
              options={{ tooltipRight: true }}
            >
              <i className="fal fa-info-circle text-dm-gray-200" />
            </Infotip>
          </label>
        )}

        <div className="mb-8 flex flex-col items-stretch gap-4">
          {initialSignupDataFromGoogle === null && (
            <>
              <div
                className={clsx(
                  accountType === "learner" &&
                    !isOver13 &&
                    "pointer-events-none opacity-50"
                )}
              >
                <GoogleLoginButton onLogin={handleGoogleLogin} />
              </div>
              <span className="text-center text-sm font-bold">Or</span>
            </>
          )}
          {!showSignupForm ? (
            <>
              <Button
                className={clsx(
                  "flex h-12 items-center justify-center gap-4 hover:text-dm-charcoal-500 active:bg-white active:!text-dm-charcoal-600",
                  accountType === "learner" &&
                    !isOver13 &&
                    "pointer-events-none opacity-20"
                )}
                type="outline"
                onClick={() => setShowSignupForm(true)}
              >
                <i className="fas fa-envelope text-xl" />
                Continue with email
              </Button>
            </>
          ) : (
            <SignupForm initialData={initialSignupDataFromGoogle ?? {}} />
          )}
          <p className="mt-2 text-center text-sm font-bold">
            Already have an account?{" "}
            <NavLink
              className="text-dm-brand-blue-500 underline-offset-2 hover:underline"
              to={`${REACT_APP_LEARNER_LINK}/sign-in`}
            >
              Log In
            </NavLink>
          </p>
        </div>

        <p className="text-center text-xs text-dm-charcoal-500">
          By signing up, you accept DeltaMath for Home
          <br />
          <NavLink
            className="text-dm-charcoal-500 underline underline-offset-2 hover:text-dm-charcoal-800 hover:no-underline"
            to={`${REACT_APP_HOMEPAGE_LINK}/terms-of-service`}
          >
            Terms of Service
          </NavLink>{" "}
          and{" "}
          <NavLink
            className="text-dm-charcoal-500 underline underline-offset-2 hover:text-dm-charcoal-800 hover:no-underline"
            to={`${REACT_APP_HOMEPAGE_LINK}/privacy-policy`}
          >
            Privacy Policy
          </NavLink>
        </p>
      </div>
    </div>
  );
};
