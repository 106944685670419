import clsx from "clsx";
import { HTMLAttributes } from "react";

/**
 * This is a box with a bottom border on mobile. The last of these in an
 * element won't have a border. The border is removed on larger screens
 */
export const ProfileDividedContainer: React.FC<
  HTMLAttributes<HTMLDivElement>
> = ({ children, className, ...props }) => (
  <div
    className={clsx(
      "mb-4 border-b border-b-dm-charcoal-100 pb-4 last:mb-0 last:border-b-0 last:pb-0 sm:mb-0 sm:border-b-0 sm:pb-0",
      className
    )}
    {...props}
  >
    {children}
  </div>
);
