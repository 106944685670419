import { ReactNode } from "react";

export const FeatureCard: React.FC<{
  image: ReactNode;
  title: string;
  description: string;
}> = ({ image, title, description }) => (
  <div className="flex flex-col items-center rounded-3xl border-2 border-dm-brand-blue-500 bg-white px-6 py-6 text-center md:py-10">
    <div className="mb-6 flex h-20 w-20 items-center justify-center">
      {image}
    </div>
    <h3 className="mb-4 font-serif text-lg font-bold text-dm-brand-blue-600">
      {title}
    </h3>
    <p>{description}</p>
  </div>
);
