import { LearnerAssignmentTypes } from "../types";
import { getReadableAssignmentType } from "../utils";
import { LoadingIcon } from "./LoadingIcon";
import { useLoadingIcon } from "./useLoadingIcon";

export const AssignmentSubmissionLoading: React.FC<{
  assignmentType: LearnerAssignmentTypes;
}> = ({ assignmentType }) => {
  const loadingIcon = useLoadingIcon();

  return (
    <div className="px-4 md:px-0">
      <span className="fixed bottom-0 left-0 right-0 top-0 z-40 backdrop-blur" />
      <div className="relative z-[41] flex w-full flex-col items-center justify-center gap-8 rounded-lg border border-dm-charcoal-100 bg-white px-4 py-10 md:-mb-8 md:h-[calc(100dvh-200px)]">
        {loadingIcon && (
          <div className="w-[213px]">
            <LoadingIcon icon={loadingIcon} />
          </div>
        )}
        <h3 className="font-serif text-lg font-bold text-dm-charcoal-500">
          One sec, we&rsquo;re grading your{" "}
          {getReadableAssignmentType(assignmentType)}!
        </h3>
      </div>
    </div>
  );
};
