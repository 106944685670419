import { SubStandard } from "../../manager/components/standards/common/types";

export interface GlobalStandardRequestInterface {
  skillCodesToStandards: Record<string, string[]>;
  sectionIds?: string[] | string;
  teacherIds?: string[] | string;
  schoolIds?: string[] | string;
  start?: string;
  end?: string;
  series?: string[] | string;
  fetchStandard?: boolean;
  fetchTest?: boolean;
  fetchPushed?: boolean;
}

export type PerformanceRequestType = {
  start?: number;
  end?: number;
  series: string[];
  skillcodes: string[];
  teacherIds?: string[];
  schoolIds?: string[];
  sectionIds?: string[];
  fetchPushed?: boolean;
  fetchStandard?: boolean;
  fetchTests?: boolean;
};

export const filterOptions = [
  { key: "all", val: "All assignments and tests" },
  { key: "tests", val: "Tests only" },
  { key: "pushed-assignments", val: "Pushed assignments only" },
  // { key: "standard", val: "Standard" },
];

/**
 *
 * @param preferenceObj {
 * "fetchTests":boolean,
 * "fetchPushed":boolean,
 * "fetchStandard":boolean
 * } localStorage value of which assignment type to fetch
 * @returns the filterOption that corresponds to the preferred request type
 */
export const parsePreferenceToFilter = (
  preferenceObj: { [key: string]: boolean } | Record<string, unknown>
) => {
  if (Object.keys(preferenceObj).length > 0) {
    const pref = Object.keys(preferenceObj).find(
      (key: string) => preferenceObj[key] === true
    );
    const parsedFilterVal =
      pref === "fetchTests"
        ? filterOptions[1]
        : pref === "fetchPushed"
        ? filterOptions[2]
        : pref === "fetchStandard"
        ? filterOptions[0]
        : filterOptions[0];
    return parsedFilterVal;
  } else return filterOptions[0];
};
/**
 * Takes a filterOption key value and returns an object request params to get API data for that filter selection
 * @param selectedKey key value of the selected data filterOption
 * @returns object with the selected filterOption's corresponding request params set
 */
export const parseFilterToPreference = (selectedKey: string) => {
  let preferenceObj = {};
  selectedKey === "tests"
    ? (preferenceObj = {
        fetchTests: true,
        fetchPushed: false,
        fetchStandard: false,
      })
    : selectedKey === "pushed-assignments"
    ? (preferenceObj = {
        fetchTests: false,
        fetchPushed: true,
        fetchStandard: false,
      })
    : (preferenceObj = {
        fetchTests: undefined,
        fetchPushed: undefined,
        fetchStandard: undefined,
      });
  return preferenceObj;
};

export const skillCodeToStandardMap = (standard: SubStandard) => {
  function getSkillcodeMap(
    obj: SubStandard,
    map: Record<string, string[]> = {}
  ): Record<string, string[]> {
    if (obj.module_data) {
      for (const sk of obj.module_data) {
        const match = map[sk];

        if (match)
          map[sk] = Array.from(
            new Set([...match, obj.label || obj.description])
          );
        else map[sk] = [obj.label || obj.description];
      }
      return map;
    } else {
      for (const k in obj.data) {
        map = getSkillcodeMap(obj.data[k], map);
      }
    }
    return map;
  }

  let skToStandardMap: Record<string, string[]> = {};
  for (const key in standard.data) {
    skToStandardMap = getSkillcodeMap(standard.data[key], skToStandardMap);
  }
  return skToStandardMap;
};
