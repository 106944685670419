import { Testimonial } from "./types";

export const TestimonialList: React.FC<{
  testimonials: Testimonial[];
  itemsToDisplay: number;
  startIndex: number;
  gap: number;
  itemWidth: number;
}> = ({ testimonials, itemsToDisplay, startIndex, gap, itemWidth }) => (
  <ul className="flex" style={{ gap: `${gap}px` }}>
    {Array(itemsToDisplay)
      .fill(null)
      .map((_, index) => {
        const { name, role, text } =
          testimonials[(index + startIndex) % testimonials.length];
        return (
          <li
            key={`${name}-${index}`}
            className={
              "flex flex-none flex-col gap-4 rounded-lg border-2 border-dm-brand-blue-500 p-8 md:flex-row md:gap-8"
            }
            style={{ width: `${itemWidth}px` }}
          >
            <h5 className="flex-none">
              <b>{name}</b>
              <br />
              {role}
            </h5>
            <p>&ldquo;{text}&rdquo;</p>
          </li>
        );
      })}
  </ul>
);
