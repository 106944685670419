import { processChoices } from "./snakeGameProblemGenerator";

const { rand, $ } = window;

/** Find double, triple or half of a number
 * @return problem object with the following properties: questionPrompt (string with html), question?  (string), choices (array of 4 strings representing answer choices with the correct answer always at index 0), solution function to run
 */
export function divisibilityRules() {
  const types = [2, 3, 4];
  const type = types[rand(0, types.length - 1)];

  switch (type) {
    case 2:
      return divisibleBy2();
    case 3:
      return divisibleBy3();
    case 4:
      return divisibleBy4();
    default:
      break;
  }
}

/** Find a number that is divisible by 2
 * @return problem object with the following properties: questionPrompt (string with html), question? (string), choices (array of 4 strings representing answer choices with the correct answer always at index 0), solution function to run
 */
function divisibleBy2() {
  const correctAnswer = 2 * rand(5, 499);

  // 11, 33, 55, 77 or 99
  const digitFor1 = 2 * rand(0, 4) + 1;
  const distractor1 = digitFor1 * 10 + digitFor1;

  // 2 digits, even digit in tens place
  const tensDigFor2 = 2 * rand(1, 4);
  const digitFor2 = 2 * rand(0, 4) + 1;
  const distractor2 = tensDigFor2 * 10 + digitFor2;

  // 3 digits, even digits in hundreds and tens places
  const hundredsDigFor3 = 2 * rand(1, 4);
  const tensDigFor3 = 2 * rand(1, 4);
  const digitFor3 = 2 * rand(0, 4) + 1;
  const distractor3 = hundredsDigFor3 * 100 + tensDigFor3 * 10 + digitFor3;

  const choices = processChoices(
    [distractor1, distractor2, distractor3],
    correctAnswer
  );

  const solution = (id) => {
    $(id).html(`\\(${correctAnswer}\\) is even`);
  };

  return {
    questionPrompt: `Which number is divisible by \\(2\\)?`,
    choices,
    solution,
  };
}

/** Find a number that is divisible by 3
 * @return problem object with the following properties: questionPrompt (string with html), question? (string), choices (array of 4 strings representing answer choices with the correct answer always at index 0), solution function to run
 */
function divisibleBy3() {
  const correctAnswer = 3 * rand(4, 33);

  // 2 digit numbers that end in 3, 6, or 9
  const onesDigs = [3, 6, 9];
  const tensDigs = [1, 2, 4, 5, 7, 8];
  const distractor1 =
    tensDigs[rand(0, tensDigs.length - 1)] * 10 +
    onesDigs[rand(0, onesDigs.length - 1)];

  // any other 2 digit numbers that are not divisible by 3
  let distractor2, distractor3;
  do {
    distractor2 = rand(10, 98);
    distractor3 = rand(10, 98);
  } while (
    distractor2 % 3 === 0 ||
    distractor3 % 3 === 0 ||
    distractor2 === distractor3
  );

  const choices = processChoices(
    [distractor1, distractor2, distractor3],
    correctAnswer
  );
  if (choices.length !== 4) return divisibleBy3();

  const correctTensDig = Math.floor(correctAnswer / 10);
  const correctOnesDig = correctAnswer % 10;

  const solution = (id) => {
    $(id).html(
      `\\(${correctTensDig} + ${correctOnesDig} = ${
        correctTensDig + correctOnesDig
      }\\), which is divisible by \\(3\\)`
    );
  };

  return {
    questionPrompt: `Which number is divisible by \\(3\\)?`,
    choices,
    solution,
  };
}

/** Find a number that is divisible by 4
 * @return problem object with the following properties: questionPrompt (string with html), question? (string), choices (array of 4 strings representing answer choices with the correct answer always at index 0), solution function to run
 */
function divisibleBy4() {
  const correctAnswer = 4 * rand(3, 24);

  // 2 digit numbers that are even but not divisible by 4
  const distractor1 = 4 * rand(3, 25) - 2;
  const distractor2 = 4 * rand(3, 25) - 2;

  // 2 digit number where the first digit is 4 or 8, second digit is odd
  const tensDig = rand(0, 1) === 0 ? 4 : 8;
  const onesDig = 2 * rand(0, 4) + 1;
  const distractor3 = tensDig * 10 + onesDig;

  const choices = processChoices(
    [distractor1, distractor2, distractor3],
    correctAnswer
  );
  if (choices.length !== 4) return divisibleBy4();

  const solution = (id) => {
    $(id).html(`\\(${correctAnswer}\\) is divisible by \\(4\\)`);
  };

  return {
    questionPrompt: `Which number is divisible by \\(4\\)?`,
    choices,
    solution,
  };
}
