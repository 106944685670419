import { WEEKLY_GOAL_POINTS } from "../constants";
import { Learner } from "../types";

/** Add new points to a learner object */
export function addPointsToLearner(
  learner: Learner | undefined,
  points: number | undefined
): Learner | undefined {
  const pointsFinal = points || 0;

  // Increment streaks if this week's points cross the weekly threshold
  const streaks =
    learner?.pointsThisWeek &&
    learner.pointsThisWeek < WEEKLY_GOAL_POINTS &&
    learner.pointsThisWeek + pointsFinal >= WEEKLY_GOAL_POINTS
      ? (learner.streaks || 0) + 1
      : learner?.streaks || 0;

  return learner
    ? {
        ...learner,
        pointsEarned: learner.pointsEarned + pointsFinal,
        ...(learner.pointsThisWeek !== undefined && {
          pointsThisWeek: learner.pointsThisWeek + pointsFinal,
        }),
        streaks,
      }
    : undefined;
}
