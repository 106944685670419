import { SidebarLink } from "../SidebarLink";
import { REACT_APP_LEARNER_LINK } from "../../../../utils";
import AllCourses from "../../../../shared/icons/AllCourses";
import { useLearnerContext } from "../../../contexts/LearnerContext";
import TourCardWrapper from "../../Tour/TourCardWrapper";

const AllCourseButton = () => {
  const learnerContext = useLearnerContext();

  return (
    <TourCardWrapper step={4} tourType="learner" stickyElement>
      <SidebarLink
        to={`${REACT_APP_LEARNER_LINK}/courses`}
        icon={<AllCourses />}
      >
        {!learnerContext.isSidebarMinimized && "All Courses"}
      </SidebarLink>
    </TourCardWrapper>
  );
};

export default AllCourseButton;
