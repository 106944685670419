import { useState, useEffect } from "react";
import { useScrollLock } from "usehooks-ts";
import Portal from "./Portal";
import OpacityCover from "./OpacityCover";
import FullGenericModal, {
  ModalProps,
} from "../student/components/generic/FullGenericModal";

interface Props extends ModalProps {
  disableClickedOff?: boolean;
}

const PortalModal = ({ disableClickedOff = false, ...props }: Props) => {
  const [closing, setClosing] = useState(false);

  const { lock, unlock } = useScrollLock({
    autoLock: false,
  });

  useEffect(() => {
    props.visible ? lock() : unlock();

    return () => unlock();
  }, [props.visible, lock, unlock]);

  const clickedOff = () => {
    setClosing(true);
    // Give it time to allow the transition animations to display before calling the actual on close
    setTimeout(function () {
      setClosing(false);
      props.onClose();
    }, 299);
  };

  if (props.visible === false) {
    return <></>;
  }

  return (
    <Portal containerSelector="#modal-container">
      <OpacityCover
        closing={closing}
        visible={props.visible}
        {...(!disableClickedOff
          ? {
              onClick: clickedOff,
            }
          : {})}
      />
      <FullGenericModal {...props} onClose={clickedOff} closing={closing} />
    </Portal>
  );
};

export default PortalModal;
