import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import clsx from "clsx";

export default function DMPopover({
  list,
  title,
}: {
  list: any;
  title: string;
}): JSX.Element {
  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button className="mt-6 inline-flex items-center gap-x-1 rounded-sm text-sm leading-6 ring-dm-charcoal-100 ring-offset-8 focus:ring-1">
            <span>{title}</span>
            <ChevronDownIcon
              className={clsx(
                "h-5 w-5 text-dm-gray-200",
                open && "rotate-180 transform"
              )}
              aria-hidden="true"
            />
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute left-1/2 z-10 mt-3 flex w-screen max-w-max -translate-x-1/2 px-4 sm:left-1/4">
              <div className="mb-2 w-screen max-w-xs flex-auto overflow-hidden rounded-md border border-dm-charcoal-100 bg-white text-sm leading-6 shadow-lg">
                <div className="grid grid-cols-1 gap-y-4 p-4">{list}</div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}
