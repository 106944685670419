import { processChoices, getCommonFactors } from "./snakeGameProblemGenerator";

const { rand, $, gcd, number, frac } = window;

/** Fully simplify a fraction
 * @return problem object with the following properties: questionPrompt (string with html), question?  (string), choices (array of 4 strings representing answer choices with the correct answer always at index 0), solution function to run
 */
export function simplifyFrac() {
  let numerator, nFac1, nFac2, denominator, dFac1, dFac2;

  do {
    nFac1 = rand(2, 9);
    nFac2 = rand(2, 9);
    numerator = nFac1 * nFac2;
    dFac1 = rand(2, 9);
    dFac2 = rand(2, 9);
    denominator = dFac1 * dFac2;
  } while (denominator <= numerator || gcd(numerator, denominator) === 1);

  const value = number(numerator / denominator);
  const question = frac(numerator, denominator);
  const correctAnswer = value.num + "/" + value.den;

  let choices = [];
  const gcdValue = gcd(numerator, denominator);
  const commonFactors = getCommonFactors(numerator, denominator);

  if (commonFactors.length) {
    choices = commonFactors.map(
      (factor) => value.num * factor + "/" + value.den * factor
    );
  }
  if (value.num > 1) {
    choices.push(
      value.num - 1 + "/" + value.den,
      value.num - 1 + "/" + (value.den - 1)
    );
  }
  choices.push(
    value.num + 1 + "/" + (value.den + 1),
    value.num + "/" + (value.den + 1),
    1 + "/" + value.den
  );

  choices = processChoices(choices, correctAnswer);

  const solution = (id) => {
    $(id).html(
      `<span class="text-2xl">\\(${question}=${frac(
        numerator + "\\div" + gcdValue,
        denominator + "\\div" + gcdValue
      )}=${value.frac}\\)</span>`
    );
  };

  return {
    questionPrompt: `Simplify the fraction completely: `,
    question,
    choices,
    solution,
  };
}
