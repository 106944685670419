import clsx from "clsx";
import { uniqueId } from "lodash";
import { InputHTMLAttributes, forwardRef, useState } from "react";

interface Props
  extends Omit<
    InputHTMLAttributes<HTMLInputElement>,
    "type" | "id" | "aria-invalid"
  > {
  label: string;
  error: string | undefined;
  ["aria-invalid"]: React.AriaAttributes["aria-invalid"];
}

const PasswordField = forwardRef<HTMLInputElement, Props>(
  ({ label, error, "aria-invalid": ariaInvalid, className, ...props }, ref) => {
    const id = uniqueId(label.toLowerCase().replace(" ", "-"));
    const [showPassword, setShowPassword] = useState(false);

    return (
      <div className="flex flex-col gap-1">
        <label className="text-sm font-bold" htmlFor={id}>
          {label}
        </label>
        <div className="relative w-full">
          <input
            className={clsx(
              "w-full rounded border px-3 py-2",
              ariaInvalid ? "border-dm-error-500" : "border-dm-gray-200",
              className
            )}
            id={id}
            aria-invalid={ariaInvalid}
            type={showPassword ? "text" : "password"}
            ref={ref}
            {...props}
          />
          <button
            type="button"
            className="absolute right-2 top-1/2 -mt-3 flex h-6 w-6 items-center justify-center text-dm-gray-200"
            onClick={() => setShowPassword(!showPassword)}
          >
            <i
              className={clsx(showPassword ? "fas fa-eye-slash" : "far fa-eye")}
              aria-hidden
            />
            <span className="sr-only">
              {showPassword ? "Hide password" : "Show password"}
            </span>
          </button>
        </div>
        {error && (
          <p className="text-xs text-dm-error-500" role="alert">
            {error}
          </p>
        )}
      </div>
    );
  }
);

PasswordField.displayName = "PasswordField";

export default PasswordField;
