import { ReactNode } from "react";
import { ParentActivity, ParentActivityPastAttemptProps } from "../../types";
import { differenceInDays } from "date-fns/esm";
import Document from "../../../student/components/icons/Document";

const GetProgressLabel = ({
  timeSpent,
  submitted,
  pastAttempts,
  grade,
  maxGrade,
  quizImprovement,
}: {
  timeSpent?: number;
  submitted?: Date;
  pastAttempts?: ParentActivityPastAttemptProps[];
  grade?: number;
  maxGrade?: number;
  quizImprovement?: ReactNode;
}) => {
  const allAttempts: ParentActivityPastAttemptProps[] = [
    ...(pastAttempts || []),
    ...(submitted
      ? [
          {
            grade: grade || 0,
            timeSpent: timeSpent || 0,
            submitted: submitted.toString(),
          },
        ]
      : []),
  ];
  const bestResult: ParentActivityPastAttemptProps | undefined =
    allAttempts.length
      ? allAttempts?.reduce((acc, curr) => {
          return curr.grade > acc.grade ? curr : acc;
        })
      : undefined;

  const timeValue =
    bestResult?.timeSpent !== undefined
      ? Math.max(1, Math.round(bestResult?.timeSpent / 60))
      : undefined;
  const timeLabel =
    bestResult?.timeSpent !== undefined ? (
      <>
        <span className="mx-2 text-dm-charcoal-100">|</span>
        <strong>{timeValue}</strong>{" "}
        {timeValue === 1 ? "min spent" : "mins spent"}
      </>
    ) : null;
  if (!submitted && !pastAttempts && grade !== undefined) {
    return (
      <>
        : <strong>In Progress</strong>
      </>
    );
  } else if (submitted && !pastAttempts) {
    return (
      <>
        {" "}
        Score: {Math.round((maxGrade || grade || 0) * 100)}%{quizImprovement}
        {timeLabel}
      </>
    );
    // if this test is a retake, just show the best score, whether or not it's in progress
  } else if (allAttempts) {
    return (
      <>
        {" "}
        Score: {Math.round((maxGrade || 0) * 100)}%{" "}
        {allAttempts.length > 1 ? "(best)" : ""}
        {quizImprovement}
        {timeLabel}
      </>
    );
  }
  return (
    <>
      : <strong>Not Started</strong>
    </>
  );
};

type Props = {
  activity: ParentActivity;
};

const RecentActivityCard: React.FC<Props> = (props) => {
  const daysSinceLastActivity = differenceInDays(
    new Date(),
    new Date(props.activity.lastActivity)
  );
  const lastActivityLabel =
    daysSinceLastActivity === 0
      ? "Today"
      : daysSinceLastActivity === 1
      ? "1 day ago"
      : `${daysSinceLastActivity} days ago`;

  const type = props.activity.type;
  const progress = props.activity.progress;

  const GetPracticeLabel = () => {
    const practice = props.activity?.practice;
    if (practice?.progress === undefined) {
      return (
        <>
          : <strong>Not Started</strong>
        </>
      );
    }

    const progress = Math.round((practice?.progress || 0) * 100);
    return (
      <span className="ml-2 inline-flex items-center rounded-full bg-dm-brand-blue-500 px-1.5 py-1 text-xs font-normal leading-none text-white">
        {progress}% Complete
      </span>
    );
  };

  const postQuizImprovement = () => {
    if (type !== "Subunit") return null;
    const preQuizGrade = props.activity?.preQuiz?.grade || 0;
    const postQuizGrade =
      props.activity?.postQuiz?.maxGrade ||
      props.activity?.postQuiz?.grade ||
      0;
    if (postQuizGrade > preQuizGrade) {
      return (
        <span className="ml-2 inline-flex items-center rounded bg-dm-success-500 px-1.5 py-1 text-sm font-normal leading-none text-white">
          +{Math.round((postQuizGrade - preQuizGrade) * 100)}%
        </span>
      );
    }
    return null;
  };

  return (
    <article className="flex flex-col gap-y-2">
      <header className="flex justify-between gap-2 text-sm text-dm-charcoal-800">
        <h2 className="font-bold">{props.activity.title}</h2>
        <h3 className="shrink-0 italic">{lastActivityLabel}</h3>
      </header>
      <section className="flex flex-col gap-y-2">
        {type === "Subunit" && (
          <>
            <div className="flex items-center gap-x-2">
              <i className="far fa-list w-4 text-center text-xs text-dm-gray-200"></i>
              <span className="text-sm text-dm-charcoal-800">
                Pre-Quiz
                {props.activity?.preQuiz &&
                Object.keys(props.activity.preQuiz).length === 0 ? (
                  <>
                    : <strong>Skipped</strong>
                  </>
                ) : (
                  <GetProgressLabel
                    timeSpent={props.activity?.preQuiz?.timeSpent}
                    submitted={props.activity?.preQuiz?.submitted}
                    grade={props.activity?.preQuiz?.grade}
                  />
                )}
              </span>
            </div>
            <div className="flex items-center gap-x-2">
              <Document classes="w-4 text-center" strokeColor="#9CA3AF" />
              <span className="text-sm text-dm-charcoal-800">
                Practice
                <GetPracticeLabel />
              </span>
            </div>
            <div className="flex items-center gap-x-2">
              <i className="far fa-list w-4 text-center text-xs text-dm-gray-200"></i>
              <span className="text-sm text-dm-charcoal-800">
                Post-Quiz
                <GetProgressLabel
                  timeSpent={props.activity?.postQuiz?.timeSpent}
                  submitted={props.activity?.postQuiz?.submitted}
                  pastAttempts={props.activity?.postQuiz?.pastAttempts}
                  grade={props.activity?.postQuiz?.grade}
                  maxGrade={props.activity?.postQuiz?.maxGrade}
                  quizImprovement={postQuizImprovement()}
                />
              </span>
            </div>
          </>
        )}
        {type === "UnitTest" && (
          <div className="flex items-center gap-x-2">
            <i className="far fa-list w-4 text-center text-xs text-dm-gray-200"></i>
            <span className="text-sm text-dm-charcoal-800">
              Unit Test
              <GetProgressLabel
                timeSpent={progress?.timeSpent}
                submitted={progress?.submitted}
                pastAttempts={progress?.pastAttempts}
                grade={progress?.grade}
                maxGrade={progress?.maxGrade}
              />
            </span>
          </div>
        )}
        {type === "CourseTest" && (
          <div className="flex items-center gap-x-2">
            <i className="far fa-list w-4 text-center text-xs text-dm-gray-200"></i>
            <span className="text-sm text-dm-charcoal-800">
              Course Test
              <GetProgressLabel
                timeSpent={progress?.timeSpent}
                submitted={progress?.submitted}
                pastAttempts={progress?.pastAttempts}
                grade={progress?.grade}
                maxGrade={progress?.maxGrade}
              />
            </span>
          </div>
        )}
      </section>
    </article>
  );
};

export default RecentActivityCard;
