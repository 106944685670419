import { Tab } from "@headlessui/react";
import clsx from "clsx";
import { useContext } from "react";
import AdminFeatureContext from "../admin/_context/FeatureContext";

export interface TabInterface {
  title: string;
  panel: any;
}
/**
 *
 * @param tabs object of tab keyed by the tab name, with a panel value of what to display when a tab is selected.
 * @returns tab component
 */
export default function DmTabs({ tabs }: { tabs: TabInterface[] }) {
  return (
    <div className="block rounded-md border-b border-dm-charcoal-100 bg-white">
      <Tab.Group>
        <Tab.List className="-mb-px flex gap-x-1.5 px-2.5 sm:gap-x-16 sm:px-4">
          {tabs.map((tab: TabInterface, index: number) => (
            <Tab
              key={index}
              className={({ selected }) =>
                clsx(
                  "whitespace-nowrap border-b-4 px-2 py-4 text-sm focus:outline-none sm:px-4",
                  selected
                    ? "border-dm-brand-blue-500 font-bold text-dm-charcoal-800"
                    : "border-transparent text-dm-gray-500 hover:border-dm-charcoal-200 hover:text-dm-charcoal-800"
                )
              }
            >
              {/* this could be made more generic */}
              {({ selected }) => (
                <>
                  {tab.title}
                  {tab.panel.props?.data?.length ? (
                    <span
                      className={clsx(
                        "ml-2 rounded-full px-1.5 py-0.5 text-xs font-normal sm:ml-3 sm:px-2.5",
                        selected
                          ? "bg-dm-brand-blue-500 text-white"
                          : "bg-dm-charcoal-100 text-dm-charcoal-800"
                      )}
                    >
                      {tab.panel.props?.data?.length}
                    </span>
                  ) : null}
                </>
              )}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="border border-t border-dm-charcoal-100">
          {tabs.map((tab: TabInterface, index: number) => (
            <Tab.Panel key={index}>{tab.panel}</Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
}
