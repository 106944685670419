export const FactorBubblesIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 12C10.2091 12 12 10.2091 12 8C12 5.79086 10.2091 4 8 4C5.79086 4 4 5.79086 4 8C4 10.2091 5.79086 12 8 12ZM8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14ZM14.5 20C15.8807 20 17 18.8807 17 17.5C17 16.1193 15.8807 15 14.5 15C13.1193 15 12 16.1193 12 17.5C12 18.8807 13.1193 20 14.5 20ZM14.5 22C16.9853 22 19 19.9853 19 17.5C19 15.0147 16.9853 13 14.5 13C12.0147 13 10 15.0147 10 17.5C10 19.9853 12.0147 22 14.5 22ZM20 9.5C20 10.3284 19.3284 11 18.5 11C17.6716 11 17 10.3284 17 9.5C17 8.67157 17.6716 8 18.5 8C19.3284 8 20 8.67157 20 9.5ZM22 9.5C22 11.433 20.433 13 18.5 13C16.567 13 15 11.433 15 9.5C15 7.567 16.567 6 18.5 6C20.433 6 22 7.567 22 9.5Z"
      fill="currentColor"
    />
  </svg>
);
