import { useEffect, useMemo, Fragment, useRef } from "react";
import { displayInlineMath, resizeKatexLine } from "../../utils";
import renderMathInElement from "../../utils/auto-render";
import clsx from "clsx";

type FormattedAnswerProps = {
  ansType: string | number;
  answer?: Array<string> | object;
  leftLatex?: string;
  rightLatex?: string;
  noSolutionText: string;
  choices?: Array<string>;
  setNotation?: boolean;
  customMsg?: string;
  setFormattedAnswer?: (str: string) => void;
  studentSolution?: string;
};

export default function FormattedAnswer({
  ansType,
  answer,
  leftLatex,
  rightLatex,
  noSolutionText,
  choices,
  setNotation,
  customMsg,
  studentSolution,
}: FormattedAnswerProps): JSX.Element {
  const ref = useRef<HTMLDivElement>(null);

  // check if student was stuck for this answer
  const studentIsStuck =
    Array.isArray(answer) &&
    answer.length === 1 &&
    answer[0] === "\\text{Problem skipped}";

  // check if student did not answer the problem in time allotted
  const noAnswerSubmitted = answer === undefined && customMsg !== undefined;

  const { formattedAnsArray, formattedAnsStrArray } = useMemo(
    () =>
      formatAns({
        ansType,
        answer,
        leftLatex,
        rightLatex,
        noSolutionText,
        setNotation,
        customMsg,
        studentIsStuck,
        noAnswerSubmitted,
        studentSolution,
      }),
    [answer, customMsg]
  );

  /**
   * Renders the math in the format-ans element and resizes, if necessary
   * Note: The math rendering is only for custom problem types and multiple choice where the answer choices are in html format -> custom problems must use the \[...\] convention for latex (as opposed to displayStyle) due to how the modules have been coded, requiring renderMathInElement
   */
  useEffect(() => {
    if (
      ansType === "custom" ||
      (ansType === 2 && choices && choices.length > 0 && choices[0][0] === "<")
    ) {
      renderMathInElement(ref.current);
    }
    const resizeNeeded = ref.current?.querySelector(".katex") !== null;
    const handleResize = () => resizeKatexLine(ref.current);
    if (resizeNeeded) {
      handleResize();
      window.addEventListener("resize", handleResize);
    }
    return () => {
      if (resizeNeeded) window.removeEventListener("resize", handleResize);
    };
  }, [answer, customMsg]);

  return (
    <>
      <div
        id="format-ans"
        ref={ref}
        className={clsx(
          ansType === "custom"
            ? "text-[1.3125em]"
            : "text-center text-[1.3125em]"
        )}
      >
        {studentIsStuck || noAnswerSubmitted || ansType === 1 ? (
          <Fragment>
            {displayInlineMath(formattedAnsStrArray.join(""), true)}
          </Fragment>
        ) : (
          formattedAnsArray
        )}
      </div>
    </>
  );
}

/* **************** */
/* Helper Functions */
/* **************** */

/* Formats the answer based on the answer type, returns an object with two properties, an array of JSX elements to render and an array of strings for setFormattedAnswer */
export const formatAns = ({
  ansType,
  answer,
  leftLatex,
  rightLatex,
  noSolutionText,
  setNotation,
  customMsg,
  studentIsStuck,
  noAnswerSubmitted,
  studentSolution = undefined,
}: {
  ansType: string | number;
  answer?: Array<string> | object;
  leftLatex?: string;
  rightLatex?: string;
  noSolutionText: string;
  setNotation?: boolean;
  customMsg?: string;
  studentIsStuck: boolean;
  noAnswerSubmitted: boolean;
  studentSolution?: string;
}) => {
  const formattedAnsArray: Array<string | JSX.Element> = [];
  const formattedAnsStrArray: Array<string> = [];

  // special cases:
  // 1. student is stuck => answer is a string array with one value, "Problem skipped"
  // 2. student did not attempt the problem before time runs out => answer will be undefined, but customMsg will have the string "no answer submitted"

  if (studentIsStuck) {
    // special case #1
    const stuckAnswer = answer as string[];
    formattedAnsStrArray.push(stuckAnswer[0]);
  } else if (noAnswerSubmitted) {
    // special case #2
    const noAnswerMsg = customMsg as string;
    formattedAnsStrArray.push(noAnswerMsg);
  } else if (ansType === 1) {
    const simpleAnswer = answer as string[];
    if (simpleAnswer.length && simpleAnswer[0] !== "") {
      if (setNotation) formattedAnsStrArray.push("\\{");
      simpleAnswer.forEach((e, i) => {
        if (leftLatex) {
          formattedAnsStrArray.push(leftLatex, e);
        } else {
          formattedAnsStrArray.push(e);
        }
        if (rightLatex) {
          if (rightLatex.indexOf("\\text") === 0) {
            formattedAnsStrArray.push("\\hspace{1px}");
          } else {
            // prevents two latex commands from bumping up against each other (esp for teacher created problems)
            formattedAnsStrArray.push(" ");
          }
          formattedAnsStrArray.push(rightLatex);
        }
        if (i < simpleAnswer.length - 1) {
          formattedAnsStrArray.push(",", "\\hspace{4px}");
        }
      });
      if (setNotation) formattedAnsStrArray.push("\\}");
    } else {
      if (setNotation) {
        formattedAnsStrArray.push("\\{\\hspace{2px}\\}");
      } else {
        formattedAnsStrArray.push("\\text{" + noSolutionText + "}");
      }
    }
  } else if (ansType === 2) {
    if (studentSolution) {
      formattedAnsStrArray.push(studentSolution);
      if (studentSolution[0] === "<") {
        formattedAnsArray.push(
          <div
            key={`mcAns_${studentSolution}`}
            dangerouslySetInnerHTML={{ __html: studentSolution }}
          ></div>
        );
      } else {
        formattedAnsArray.push(
          <Fragment key={`mcAns_${studentSolution}`}>
            {displayInlineMath(studentSolution, true)}
          </Fragment>
        );
      }
    } else {
      formattedAnsStrArray.push("\\text{No choice selected.}");
      formattedAnsArray.push(
        <Fragment key="ansBlank">
          {displayInlineMath("\\text{No choice selected.}", true)}
        </Fragment>
      );
    }
  } else if (ansType === "custom") {
    let msg = customMsg as string;
    formattedAnsStrArray.push(msg);
    // if the custom message is not HTML, add in the brackets for latex rendering
    if (msg && msg.length && msg[0] !== "<") msg = "\\[" + msg + "\\]";
    formattedAnsArray.push(
      <div
        key={"customAns"}
        className="verify-math"
        dangerouslySetInnerHTML={{ __html: msg }}
      ></div>
    );
  }
  return { formattedAnsArray, formattedAnsStrArray };
};
