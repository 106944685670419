import React, { useState, useEffect } from "react";
import { matchSorter } from "match-sorter";
import { HeaderGroup } from "react-table";
import clsx from "clsx";
import SearchIcon from "../icons/SearchIcon";

export function GlobalFilter({
  globalFilter,
  setGlobalFilter,
}: {
  globalFilter: any;
  setGlobalFilter: any;
}) {
  const [value, setValue] = useState(globalFilter);

  const onChange = (value: string) => {
    setGlobalFilter(value || undefined);
  };

  return (
    <div className="relative">
      <span>
        <SearchIcon classes="absolute ml-8 mt-7" />
      </span>
      <input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        className="my-4 ml-6 w-1/4 rounded-md border border-b p-2 pl-8"
        placeholder="Search"
      />
    </div>
  );
}

// Define a UI for text filtering
export function TextFilter({
  column: { filterValue, preFilteredRows, setFilter },
  id,
}: {
  column: HeaderGroup;
  id: any;
}) {
  const tempPreferences = JSON.parse(
    localStorage.getItem("tempPreferences") || "{}"
  );
  const filterPreferenceArray = tempPreferences?.filterPreferences || [
    { id: id, value: "" },
  ];
  const thisFilter =
    Array.isArray(filterPreferenceArray) &&
    filterPreferenceArray.find(
      (pref: { id: string; value: string }) => pref.id === id
    );

  const [thisValue, setThisValue] = useState(thisFilter?.value || "");

  useEffect(() => {
    if (thisValue) {
      setFilter(thisValue);
    } else {
      setThisValue(filterValue || undefined);
    }
  }, []);

  const handleFilterInput = (e: any) => {
    setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
    setThisValue(e.target.value || undefined);
    const newPref = {
      id: id,
      value: e.target.value,
    };
    if (
      !filterPreferenceArray.find(
        (pref: { id: string; value: string }) => pref.id === id
      )
    ) {
      filterPreferenceArray.push(newPref);
    } else {
      const newPreferenceArray = filterPreferenceArray.map(
        (pref: { id: string; value: string }) => {
          if (pref.id === id) {
            return {
              id: id,
              value: e.target.value,
            };
          } else return pref;
        }
      );
      filterPreferenceArray.splice(
        0,
        filterPreferenceArray.length,
        ...newPreferenceArray
      );
    }
    localStorage.setItem(
      "tempPreferences",
      JSON.stringify({
        ...tempPreferences,
        filterPreferences: filterPreferenceArray,
      })
    );
  };

  const count = preFilteredRows.length;
  return count > 1 ? (
    <div className="relative mt-2">
      <span>
        <SearchIcon classes={"absolute ml-2 mt-3"} />
      </span>
      <input
        value={thisValue || ""}
        onChange={(e) => handleFilterInput(e)}
        className="rounded-md border border-b p-2 pl-8 text-dm-gray-600"
        placeholder="Search"
      />
    </div>
  ) : (
    <NoFilter />
  );
}

export function TextFilterNoPersist({
  column: { filterValue, preFilteredRows, setFilter },
}: {
  column: HeaderGroup;
}) {
  const count = preFilteredRows.length;
  return count > 1 ? (
    <>
      {
        <input
          value={filterValue || ""}
          onChange={(e) => {
            setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
          }}
          className="mt-1 p-2 text-dm-blue"
          placeholder={`Search ${count} records...`}
        />
      }
    </>
  ) : (
    <NoFilter />
  );
}

// Define a default UI for filtering
export function SearchColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
  setFilterValue,
}: {
  column: HeaderGroup;
  setFilterValue: any;
}) {
  const count = preFilteredRows.length;

  return (
    <input
      className="mt-2 p-2"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        setFilterValue(e.target.value || undefined);
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

export function NoFilter() {
  return <></>;
}

// This is a custom filter UI for selecting
// a unique option from a list
export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}: {
  column: HeaderGroup;
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options: Set<string> = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return Array.from(options);
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        e.preventDefault();
        setFilter(e.target.value || undefined);
      }}
      className="form-select m-0
        mt-2
        block
        w-full
        appearance-none
        rounded
        border
        border-solid
        border-gray-300
        bg-white bg-clip-padding bg-no-repeat
        px-3 py-2 text-base
        font-normal
        text-gray-700
        transition
        ease-in-out
        focus:border-blue-600 focus:bg-white focus:text-gray-700 focus:outline-none"
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

export function fuzzyTextFilterFn(
  rows: readonly string[],
  id: string | number,
  filterValue: string
) {
  return matchSorter(rows, filterValue, {
    keys: [(row: any) => row.values[id]],
  });
}
