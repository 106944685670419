import { Dispatch, SetStateAction } from "react";
import Button from "../../../student/components/generic/button";
import { DMPieChart } from "../../../student/components/generic/DMPieChart";
import clsx from "clsx";

type CourseGraphHeaderProps = {
  isModal?: boolean;
  segments: { value: number; color: string }[];
  courseIconUrl?: string;
  courseName?: string;
  courseTotalProgress: number;
  totalSections: number;
  setShowModal?: Dispatch<SetStateAction<boolean>>;
  courseProgressAriaLabel?: string;
};

export default function CourseProgressCardHeader(
  props: CourseGraphHeaderProps
) {
  return (
    <>
      <div
        className={clsx(
          "relative flex h-16 w-16 shrink-0 items-center justify-center",
          props.isModal ? "h-20 w-20" : "h-16 w-16"
        )}
      >
        <DMPieChart
          data={props.segments}
          lineWidth={15}
          paddingAngle={props.segments.length > 1 ? 3 : 0}
          totalValue={props.segments.length}
          startAngle={270}
          className="pointer-events-none absolute"
          ariaLabel={props.courseProgressAriaLabel}
        />
        {props.courseIconUrl ? (
          <img
            src={`${props.courseIconUrl}`}
            className={clsx(
              "left-0 top-0 shrink-0 text-center leading-none",
              props.isModal ? "h-[72px] w-[72px]" : "h-14 w-14"
            )}
            alt={`${props.courseName || ""} icon`}
            aria-hidden="true"
          />
        ) : (
          <i
            className={clsx(
              "fas fa-th shrink-0 text-center leading-none",
              props.isModal ? "text-2xl" : "text-xl"
            )}
            aria-hidden="true"
          />
        )}
      </div>
      <div>
        <h3
          className={clsx(
            "font-serif font-bold text-dm-charcoal-800",
            props.isModal ? "text-lg" : "text-2xl"
          )}
        >
          {props.courseTotalProgress}% complete
        </h3>
        {!props.isModal ? (
          <Button
            size="small"
            className="mt-1"
            onClick={() => props.setShowModal?.(true)}
          >
            View Course Progress Details
          </Button>
        ) : (
          <h3 className="mt-1 text-sm font-normal leading-none text-dm-charcoal-500">{`${
            props.totalSections
          } ${props.totalSections !== 1 ? "Units" : "Unit"}`}</h3>
        )}
      </div>
    </>
  );
}
