import { SubmitHandler, useForm } from "react-hook-form";
import Button from "../../student/components/generic/button";
import { useParentContext } from "../contexts/ParentContext";
import { AddLearnersHeader } from "./Parent/AddLearners/AddLearnersHeader";
import { useEffect, useState } from "react";
import { AddLearnersForm } from "./Parent/AddLearners/AddLearnersForm";
import { useLearnerContext } from "../contexts/LearnerContext";
import { QuantityChangeModal } from "./Profile/QuantityChangeModal";
import { useSubscription } from "../utils/useSubscription";
import { useNavigate, useSearchParams } from "react-router-dom";
import { REACT_APP_LEARNER_LINK } from "../../utils";
import { useMutation } from "react-query";
import axios from "axios";
import { deltamathAPI } from "../../manager/utils";
import { withJsonHeader } from "../../shared/axiosUtils";
import { compact } from "lodash";
import { getLearnerAccountStatus } from "./Profile/getLearnerAccountStatus";
import { EmptyLearnerPage } from "./Layouts/EmptyLearnerPage";

interface FormInputs {
  // If only one learner, react-hook-form will return a string, otherwise an array
  learners: string[] | string;
}

export const ConfirmLearnersPage: React.FC = () => {
  const navigate = useNavigate();
  const learnerContext = useLearnerContext();
  const { learners, fetchChildLearners } = useParentContext();
  const { subscription } = useSubscription();
  const [showAddLearnerForm, setShowAddLearnerForm] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [newQuantity, setNewQuantity] = useState(0);
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    trigger,
    formState: { isValid },
  } = useForm<FormInputs>({ mode: "onChange" });

  const [searchParams] = useSearchParams();

  const updateExistingSubscription = searchParams.has("update");

  const accountOwner = learnerContext.learner;
  const existingQuantity = accountOwner?.subscriptionQuantity ?? 0;
  const quantityDiff = newQuantity - existingQuantity;

  const { mutateAsync: reactivateMutation } = useMutation(() => {
    const learnerIds = getValues("learners");
    return axios.post(
      `${deltamathAPI()}/payments/subscriptions/reactivate/children`,
      JSON.stringify({
        learnerIds: typeof learnerIds === "string" ? [learnerIds] : learnerIds,
      }),
      withJsonHeader()
    );
  });

  const handleAddedLearner = () => {
    setShowAddLearnerForm(false);
  };

  const onConfirm = async () => {
    await fetchChildLearners();
    navigate(`${REACT_APP_LEARNER_LINK}/parent`);
  };

  const onConfirmQuantityChange = async () => {
    await reactivateMutation();
    const quantity = learnerContext.learner?.subscriptionQuantity ?? 0;
    learnerContext.updateLearner({ subscriptionQuantity: quantity + 1 });
    setShowConfirmModal(false);
    onConfirm();
  };

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    // If `string`, only one learner
    const updatedQuantity =
      typeof data.learners === "string" ? 1 : data.learners.length;
    setNewQuantity(updatedQuantity);
    if (updatedQuantity > existingQuantity) {
      setShowConfirmModal(true);
    } else {
      await reactivateMutation();
      onConfirm();
    }
  };

  const validateCheckboxes = () => {
    const selectedLearners = getValues("learners");
    if (!selectedLearners) {
      return false;
    }
    return selectedLearners.length > 0;
  };

  useEffect(() => {
    setValue(
      "learners",
      compact(
        learners.map((l) =>
          getLearnerAccountStatus(l) === "inactive" ? null : l._id
        )
      )
    );
    trigger("learners");
  }, [learners, setValue, trigger]);

  return (
    <EmptyLearnerPage title="Confirm Learners" dontTrackTime>
      <main className="flex h-dvh w-dvw flex-col items-center px-4">
        <AddLearnersHeader title="" showSkipLink={true} />
        {showAddLearnerForm ? (
          <AddLearnersForm
            numLearners={1}
            next={handleAddedLearner}
            unconfirmed
          />
        ) : (
          <>
            <div className="mb-8 flex flex-col gap-2 text-center font-bold text-dm-brand-blue-600">
              <h1 className="font-serif text-2xl">Confirm Learners</h1>
              <h2 className="font-serif text-lg">
                {updateExistingSubscription
                  ? "These learners are part of your account."
                  : "These learners were part of your previous subscription."}
              </h2>
              <p>
                {updateExistingSubscription
                  ? "Check the learners you'd like to include in your subscription."
                  : "Check the learners you'd like to include in this new subscription."}
              </p>
            </div>

            <form
              className="flex w-[300px] max-w-full flex-col"
              onSubmit={handleSubmit(onSubmit)}
            >
              <fieldset className="mb-10 flex flex-col items-start gap-4">
                {learners.map((learner) => (
                  <label key={learner._id} className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      value={learner._id}
                      {...register("learners", {
                        validate: validateCheckboxes,
                      })}
                    />
                    <span>
                      {learner.first} {learner.last}
                    </span>
                  </label>
                ))}
              </fieldset>
              <Button className="mb-8 w-full" disabled={!isValid}>
                Confirm Learners
              </Button>
              <div className="flex w-full justify-center">
                <button
                  className="font-bold text-dm-brand-blue-600 underline-offset-2 hover:underline"
                  type="button"
                  onClick={() => setShowAddLearnerForm(true)}
                >
                  Add New Learner
                </button>
              </div>
            </form>

            {subscription && (
              <QuantityChangeModal
                visible={showConfirmModal}
                onClose={() => setShowConfirmModal(false)}
                subscription={subscription}
                newQuantity={newQuantity}
                title={quantityDiff === 1 ? "Add a Learner" : "Add Learners"}
                confirmButtonText={
                  quantityDiff === 1 ? "Add Learner" : "Add Learners"
                }
                onConfirm={onConfirmQuantityChange}
              />
            )}
          </>
        )}
      </main>
    </EmptyLearnerPage>
  );
};
