import { FormEventHandler, useState } from "react";
import { Learner } from "../../../learner/types";
import { useDMQuery } from "../../../utils";
import { DmLoadingSpinner } from "../../utils/functions";
import { BasicContentContainer } from "../BasicContentContainer";
import Button from "../../../student/components/generic/button";
import { useMutation } from "react-query";
import axios from "axios";
import { withJsonHeader } from "../../../shared/axiosUtils";
import { deltamathAPI } from "../../utils";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import DeltaMathTable from "../../../shared/DeltaMathTable";
import { useDocumentTitle } from "usehooks-ts";

export const FreeSubscriptionsPage = () => {
  useDocumentTitle("Free Subscriptions");
  const [newLearnerId, setNewLearnerId] = useState("");
  const [newExpiration, setNewExpiration] = useState("");
  const toastContext = useDeltaToastContext();

  const {
    data: freeSubscriptionData,
    isLoading,
    refetch: refetchFreeSubscriptions,
  } = useDMQuery<{ learners: Learner[] }>({
    path: "/payments/subscriptions/free/list",
    queryOptions: {
      refetchOnWindowFocus: false,
    },
  });

  const { mutateAsync: grantFreeAccessMutation } = useMutation(() => {
    return axios.post(
      `${deltamathAPI()}/payments/subscriptions/free/grant`,
      {
        learnerId: newLearnerId,
        expiration: newExpiration,
      },
      withJsonHeader()
    );
  });

  const { mutateAsync: revokeFreeAccessMutation } = useMutation(
    (learnerId: string) => {
      return axios.post(
        `${deltamathAPI()}/payments/subscriptions/free/revoke`,
        { learnerId },
        withJsonHeader()
      );
    }
  );

  const grantFreeAccess: FormEventHandler = async (e) => {
    e.preventDefault();
    try {
      await grantFreeAccessMutation();
      await refetchFreeSubscriptions();
      setNewLearnerId("");
      setNewExpiration("");
    } catch (error: any) {
      toastContext.addToast({
        message: error.response.data.message || "Failed to grant free access.",
        status: "Error",
      });
    }
  };

  const revokeFreeAccess = async (learnerId: string) => {
    try {
      await revokeFreeAccessMutation(learnerId);
      await refetchFreeSubscriptions();
    } catch (error: any) {
      toastContext.addToast({
        message: error.response.data.message || "Failed to revoke free access.",
        status: "Error",
      });
    }
  };

  return (
    <div className="flex flex-col gap-6 p-6">
      <header>
        <h1 className="text-2xl font-bold">Free subscriptions</h1>
        <p>Grant free access to specific users.</p>
      </header>

      <form className="flex flex-col gap-2" onSubmit={grantFreeAccess}>
        <h2 className="col-span-2 text-lg font-bold">Grant access</h2>
        <div className="flex gap-6">
          <div className="flex items-center gap-2">
            <label htmlFor="learnerid">Learner id</label>
            <input
              className="border-dm-charcoal-300 rounded-md border p-1"
              id="learnerid"
              type="text"
              value={newLearnerId}
              onChange={(e) => setNewLearnerId(e.target.value)}
            />
          </div>
          <div className="flex items-center gap-2">
            <label htmlFor="expiration">Expiration date</label>
            <input
              className="border-dm-charcoal-300 rounded-md border p-1"
              id="expiration"
              type="datetime-local"
              value={newExpiration}
              onChange={(e) => setNewExpiration(e.target.value)}
            />
          </div>
          <Button size="small">Grant free access</Button>
        </div>
      </form>

      {isLoading ? (
        <BasicContentContainer>
          <DmLoadingSpinner message="" />
        </BasicContentContainer>
      ) : !freeSubscriptionData?.learners.length ? (
        <BasicContentContainer>
          <p>
            There are no learners with free access. Add a learner using the form
            above.
          </p>
        </BasicContentContainer>
      ) : (
        <DeltaMathTable
          data={freeSubscriptionData.learners}
          columns={[
            {
              Header: "Learner ID",
              accessor: "_id",
              ...{ align: "left" },
            },
            {
              Header: "Email or username",
              accessor: "email",
              ...{ align: "left" },
            },
            {
              Header: "Name",
              ...{ align: "left" },
              Cell: ({ row }) => {
                const { first, last } = row.original as Learner;
                return `${first} ${last}`;
              },
            },
            {
              Header: "Expires",
              accessor: "subscriptionExpiration",
              ...{ align: "left" },
              Cell: ({ value }) =>
                Intl.DateTimeFormat("en-us", {
                  dateStyle: "medium",
                  timeStyle: "medium",
                }).format(new Date(value)),
            },
            {
              Header: "Revoke",
              ...{ align: "left" },
              Cell: ({ row }) => {
                return (
                  <Button
                    className="bg-red-700 hover:bg-red-600"
                    size="small"
                    onClick={() =>
                      revokeFreeAccess((row.original as Learner)._id)
                    }
                  >
                    Revoke access
                  </Button>
                );
              },
            },
          ]}
        />
      )}
    </div>
  );
};
