import { useState } from "react";
import { FooterButton } from "./FooterButton";
import { GiveUsFeedbackModal } from "./GiveUsFeedbackModal";

export const GiveUsFeedback: React.FC = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <FooterButton onClick={() => setShowModal(true)}>
        Give Us Feedback
      </FooterButton>
      <GiveUsFeedbackModal
        showModal={showModal}
        onClose={() => setShowModal(false)}
      />
    </>
  );
};
