import { REACT_APP_HOMEPAGE_LINK } from "../../utils";

export default function Copyright(): JSX.Element {
  return (
    <footer className="flex-none px-6 pb-4 pt-5 text-xs text-dm-gray-200 max-sm:leading-5 sm:px-8">
      Copyright ©{new Date().getFullYear()} DeltaMath.com All Rights Reserved.
      <br className="sm:hidden" />
      <a
        target="_blank"
        rel="noreferrer"
        className="hover:text-dm-gray-500 sm:pl-4"
        href={`${REACT_APP_HOMEPAGE_LINK}/privacy-policy`}
      >
        Privacy Policy
      </a>{" "}
      |{" "}
      <a
        target="_blank"
        rel="noreferrer"
        className="hover:text-dm-gray-500"
        href={`${REACT_APP_HOMEPAGE_LINK}/terms-of-service`}
      >
        Terms of Service
      </a>
    </footer>
  );
}
