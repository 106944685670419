import { useMemo, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ComponentTimer, useComponentTimer } from "./useComponentTimer";
import { LearnerAssignment } from "../types";

/**
 * Hook to manage a quiz and test timer (for pre-quiz, post-quiz, unit test and course test)
 * @param assignment the learner assignment that the user is currently viewing
 */
export function useTestTimer(
  assignment: LearnerAssignment | undefined
): ComponentTimer {
  const { submittedTime, indexOfSkill } = useParams();

  const isTimerDisabled = useMemo(() => {
    return (
      !assignment ||
      assignment.type === "practice" ||
      assignment.submitted !== undefined ||
      submittedTime !== undefined ||
      indexOfSkill === undefined
    );
  }, [assignment, submittedTime, indexOfSkill]);

  const truncateId = (str: string | undefined) => {
    if (str === undefined) return "";
    return str.substring(str.length - 6);
  };
  const testTimer = useComponentTimer(
    [
      assignment?.type,
      `l${truncateId(assignment?.learnerId)}`,
      `c${truncateId(assignment?.courseId)}`,
      `u${truncateId(assignment?.unitId ?? "")}`,
      `s${truncateId(assignment?.subunitId ?? "")}`,
    ].join(":"),
    isTimerDisabled
  );

  useEffect(() => {
    if (!isTimerDisabled) {
      testTimer.restartTimer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTimerDisabled]);

  return testTimer;
}
