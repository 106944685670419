import { DMPieChart } from "../../../student/components/generic/DMPieChart";
import { getUnitProgressAriaLabel } from "../../utils/index";

type Props = {
  title: string;
  sectionProgress: {
    id: string;
    progress: number;
  }[];
  description?: string;
  unitProgress?: number;
};

const UnitSummaryHeader = (props: Props) => {
  // last item in sectionProgress is the unitTest info, so subtract 1 from length to get
  // number of sections
  const sectionTotal = props?.sectionProgress
    ? props?.sectionProgress?.length - 1
    : 0;

  const segments = props?.sectionProgress
    ? props?.sectionProgress.map((su) => {
        const progress = su.progress || 0;
        return {
          value: 1,
          color:
            progress === 0 ? "#E3E8EF" : progress === 1 ? "#2F52E9" : "#A9CCFA",
        };
      })
    : [];

  return (
    <div className="mb-4 flex w-full gap-x-6 rounded-lg border border-dm-charcoal-100 bg-white px-6 py-4">
      <div className="relative flex h-12 w-12 shrink-0 items-center">
        {props.unitProgress !== 1 ? (
          <>
            <DMPieChart
              data={segments}
              lineWidth={30}
              paddingAngle={5}
              totalValue={segments.length}
              startAngle={270}
              className="pointer-events-none absolute"
              ariaLabel={getUnitProgressAriaLabel(props.sectionProgress)}
            />
            <i
              className="fas fa-th left-0 top-0 w-12 text-center text-xl leading-none text-dm-brand-blue-500"
              aria-hidden="true"
            ></i>
          </>
        ) : (
          <i
            className="far fa-check-circle text-5xl leading-none !text-dm-brand-blue-500"
            aria-hidden="true"
          ></i>
        )}
      </div>
      <div className="flex-grow">
        <h1 className="font-serif text-2xl font-bold">{props.title}</h1>
        <h3 className="mt-2 text-sm">
          {`${sectionTotal} Section${sectionTotal !== 1 ? "s" : ""}`}
          <span className="ml-2 rounded-full bg-dm-brand-blue-500 px-2 py-1 text-xs font-medium leading-none text-white">
            {`${Math.round((props.unitProgress || 0) * 100)}% Complete`}
          </span>
        </h3>
        <p className="mt-2 max-w-[650px] text-base text-dm-charcoal-500">
          {props.description}
        </p>
      </div>
    </div>
  );
};

export default UnitSummaryHeader;
