import { useState } from "react";
import Button from "../../../student/components/generic/button";
import { PlusCircleIcon, MinusCircleIcon } from "@heroicons/react/outline";
import InputFieldWrapper from "./InputFieldWrapper";
import { SubmitAnswerButton } from "./SubmitAnswerButton";

type Props = {
  problemData: any;
  handleSubmit: (answers: string[] | object) => void;
  isCheckAnswerLoading: boolean;
};

const InputFields = (props: Props) => {
  const [inputFields, setInputFields] = useState<
    Array<{
      num: number;
      ref: React.Ref<HTMLSpanElement>;
    }>
  >([]);
  const [numOfInputs, setNumOfInputs] = useState<number>(1);
  const [answerArray, setAnswerArray] = useState<
    {
      ans: string;
      index: number;
    }[]
  >([
    {
      ans: "",
      index: 0,
    },
  ]);

  /* Math Input Field Event Handler */
  const handleChange = (mq: any, index: number): void => {
    // commas should trigger adding a field if multiple solutions are possible
    const inputStr = mq.latex();
    const hasParenthesis =
      mq.latex() &&
      (mq.latex().indexOf("(") > -1 || mq.latex().indexOf("[") > -1);
    const allowCommas =
      props.problemData.allowComma ||
      props.problemData.allowCommasForNumbers ||
      props.problemData.pointComma;
    const commaIndex = inputStr.indexOf(",");

    if (
      props.problemData.multipleSolutionsPossible &&
      !allowCommas &&
      commaIndex !== -1 &&
      !hasParenthesis
    ) {
      mq.latex(inputStr.slice(0, commaIndex));
      const newNumOfInputs = numOfInputs + 1;
      setNumOfInputs(newNumOfInputs);
      setAnswerArray([
        ...answerArray.filter((aa) => aa.index !== index),
        {
          ans: mq.latex(),
          index: index,
        },
        {
          ans: "",
          index: newNumOfInputs - 1,
        },
      ]);
    } else {
      setAnswerArray([
        ...answerArray.filter((aa) => aa.index !== index),
        {
          ans: inputStr,
          index: index,
        },
      ]);
    }
  };

  const addInputFields = () => {
    const newIndex = numOfInputs + 1;
    setNumOfInputs(newIndex);
    setAnswerArray([
      ...answerArray,
      {
        ans: "",
        index: newIndex - 1,
      },
    ]);
  };

  const removeInputFields = () => {
    if (numOfInputs !== 0) {
      const indexToRemove = numOfInputs - 1;
      setNumOfInputs(indexToRemove);
      setAnswerArray([
        ...answerArray.filter((aa) => aa.index !== indexToRemove),
      ]);
    }
  };

  const submit = () => {
    props.handleSubmit(answerArray.map((a) => a.ans));
  };

  return (
    <div className="flex w-full flex-col gap-4">
      {props.problemData.multipleSolutionsPossible && (
        <>
          <div className="flex flex-row flex-wrap justify-start gap-x-4 gap-y-2 font-sans text-sm font-medium">
            <Button
              type="link"
              className="inline-flex items-center hover:cursor-pointer"
              onClick={() => addInputFields()}
            >
              <PlusCircleIcon className="mr-2 h-5 w-5" aria-hidden="true" />
              {props.problemData.multipleSolutionsBtns
                ? props.problemData.multipleSolutionsBtns[1]
                : "Additional Solution"}
            </Button>
            {numOfInputs > 0 ? (
              <Button
                type="link"
                className="inline-flex items-center hover:cursor-pointer"
                onClick={() => removeInputFields()}
              >
                <MinusCircleIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                {numOfInputs === 1
                  ? props.problemData.multipleSolutionsBtns
                    ? props.problemData.multipleSolutionsBtns[0]
                    : "No Solution"
                  : props.problemData.multipleSolutionsBtns
                  ? props.problemData.multipleSolutionsBtns[2]
                  : "Remove Solution"}
              </Button>
            ) : null}
          </div>
        </>
      )}
      <div className="flex flex-wrap gap-4">
        {Array.from({ length: numOfInputs }).map((x, i) => (
          <InputFieldWrapper
            inputNum={i + 1}
            inputFields={inputFields}
            setInputFields={setInputFields}
            totalFields={numOfInputs}
            problemData={props.problemData}
            handleChange={(mq) => handleChange(mq, i)}
            handleSubmit={submit}
            key={`input-${i + 1}`}
            defaultFocus={i + 1 === numOfInputs}
          />
        ))}
        <SubmitAnswerButton
          submit={submit}
          isCheckAnswerLoading={props.isCheckAnswerLoading}
        />
      </div>
    </div>
  );
};

export default InputFields;
