import { useEffect, useMemo, useState } from "react";
import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
  DragEndEvent,
  DragStartEvent,
  UniqueIdentifier,
  DragMoveEvent,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { Column, useTable } from "react-table";
import DraggableRow from "./DraggableRow";

type Props<T extends { _id: string }> = {
  cols: Column[];
  data: any[];
  updateData: (data: T[]) => void;
  dragTop?: boolean;
  lessPadding?: boolean;
  tallRow?: boolean;
};

const DraggableTable = <T extends { _id: string }>(props: Props<T>) => {
  const [activeData, setActiveData] = useState<any>(props.data);
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns: props.cols,
      data: activeData,
    });
  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  );

  function handleDragStart(event: DragStartEvent) {
    setActiveId(event.active.id);
  }

  function handleDragEnd(event: DragEndEvent) {
    props.updateData(activeData);

    setActiveId(null);
  }

  function handleDragCancel() {
    setActiveData(props.data);
    setActiveId(null);
  }

  const selectedRow = useMemo(() => {
    if (!activeId) {
      return null;
    }
    const row = rows.find((row) => (row.original as T)._id === activeId);
    if (row) {
      prepareRow(row);
    }
    return row;
  }, [activeId, rows, prepareRow]);

  const dragOver = (event: DragMoveEvent) => {
    const { active, over } = event;
    if (over) {
      const oldIndex = activeData.findIndex((d: any) => d._id === active.id);
      const newIndex = activeData.findIndex((d: any) => d._id === over.id);
      const arrMove: T[] = arrayMove(activeData, oldIndex, newIndex);
      setActiveData(arrMove);
    }
  };

  useEffect(() => {
    setActiveData(props.data);
  }, [props.data]);

  // Render the UI for your table
  return (
    <DndContext
      sensors={sensors}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      onDragCancel={handleDragCancel}
      // onDragMove={dragMove}
      onDragOver={dragOver}
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis]}
    >
      <table {...getTableProps()} className="w-full py-6">
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              key={`header-group-${index}`}
              className="bg-white"
            >
              <th />
              {headerGroup.headers.map((column, columnIndex) => (
                <th
                  className="py-3"
                  {...column.getHeaderProps()}
                  key={`header-${index}-${columnIndex}`}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          <SortableContext
            items={activeData}
            strategy={verticalListSortingStrategy}
          >
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <DraggableRow
                  key={`${(row.original as T)._id}-${i}`}
                  row={row}
                  dragTop={props.dragTop}
                  lessPadding={props.lessPadding}
                  tallRow={props.tallRow}
                />
              );
            })}
          </SortableContext>
        </tbody>
      </table>
      <DragOverlay className="w-full">
        {activeId && selectedRow && (
          <table className="w-full">
            <tbody className="w-full">
              <tr className="w-full shadow-xl outline outline-1">
                <DraggableRow
                  key={(selectedRow.original as T)._id}
                  row={selectedRow}
                  shadow
                  dragTop={props.dragTop}
                  lessPadding={props.lessPadding}
                  tallRow={props.tallRow}
                />
              </tr>
            </tbody>
          </table>
        )}
      </DragOverlay>
    </DndContext>
  );
};

export default DraggableTable;
