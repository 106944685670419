import { useState } from "react";
import { FooterButton } from "./FooterButton";
import { ContactSupportModal } from "./ContactSupportModal";
import { FormIcon } from "../../../shared/icons/FormIcon";

export const ContactSupport: React.FC = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <FooterButton
        onClick={() => setShowModal(true)}
        className="flex items-center gap-2 rounded-[4px] bg-dm-purple-100 px-[10px] py-[6px]"
      >
        <span aria-hidden>
          <FormIcon />
        </span>
        Contact Support
      </FooterButton>
      <ContactSupportModal
        showModal={showModal}
        onClose={() => setShowModal(false)}
      />
    </>
  );
};
