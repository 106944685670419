/**
 * IDs for the parent div of a DM question or solution.
 * Note: modal implies that a solution is displaying in a modal.
 */
export const PAGE_TYPE_ID_MAP = {
  modal: "modal_page",
  solution: "problem_page",
  question: "question_page",
};

const exclusionArr = [
  "019999925996",
  "019999926046",
  "059999920459",
  "060185110983",
  "060194513029",
  "069999940283",
  "069999955925",
  "099999947323",
  "120009004304",
  "120144007801",
  "129999914185",
  "139999933334",
  "139999949153",
  "139999953018",
  "139999953041",
  "249999921923",
  "249999922549",
  "249999947970",
  "269999945345",
  "320000100825",
  "349999950498",
  "370003502340",
  "379999924873",
  "379999925142",
  "379999943042",
  "379999969079",
  "429999938646",
  "429999954511",
  "450390901513",
  "489999916919",
  "489999917449",
  "489999918031",
  "489999918054",
  "489999918080",
  "489999946879",
  "489999950298",
  "489999952716",
  "490012401202",
  "490114000601",
  "519999926415",
  "379999924948",
  "063525007967",
  "069999909172",
  "080594001016",
  "210028902485",
  "450249000647",
  "481970001912",
  "120087000930",
  "120087000972",
  "062271002873",
  "062271003108",
  "062271003139",
  "062271003171",
  "062271003301",
  "062271011652",
  "062271012439",
  "062271012885",
  "062271012971",
  "120018000169",
  "120018000176",
  "120018000186",
  "120018000217",
  "120018000241",
  "120018000261",
  "120018002120",
  "120018002721",
  "120018002981",
  "120018007555",
  "5101890",
  "060220603050",
  "062025002441",
  "063432014499",
  "080333001334",
  "080336001955",
  "129999914916",
  "139999946508",
  "2500518",
  "269999900891",
  "340280000300",
  "349999967313",
  "370032303256",
  "390015104763",
  "479999920616",
  "482892013975",
  "4830120",
  "490003000018",
  "622230",
  "139999933380",
  "390011804148",
  "390448001668",
  "399999920321",
  "482892011441",
  "483012003379",
  "custom_eb2e04a74f6d5b614000",
  "custom_598392f30d279da579af",
  "custom_598392f30d279da579af",
  "custom_ad88bdd31e11b6727839",
  "custom_ad88bdd31e11b6727839",
  "custom_675be20d1b5fffdd66da",
  "custom_675be20d1b5fffdd66da",
  "custom_2bda23886d266ac361fa",
  "custom_2bda23886d266ac361fa",
  "custom_483c7d932af7080c9f13",
  "custom_483c7d932af7080c9f13",
  "custom_483c7d932af7080c9f13",
  "custom_c79f7e3239c86ae733bc",
  "custom_c79f7e3239c86ae733bc",
  "custom_8872b8ee05318fc4d642",
  "custom_fc0a5ec1a9291b32bcb4",
  "custom_fc0a5ec1a9291b32bcb4",
  "custom_fc0a5ec1a9291b32bcb4",
  "custom_0a4466d3955123b6f03b",
  "custom_0a4466d3955123b6f03b",
  "custom_bebd468008d6a91a11aa",
  "624620f1b7d25d42b374d410_551a012551ee6c180012b915",
  "624620f1b7d25d42b374d410_551a012551ee6c180012b915",
  "369999947006",
  "2400480_custom_3",
  "2400480_custom_3",
  "370297003405",
  "370297003405",
  "611a904da944795644b593db_5f280c818c9eb81a940c721d",
  "custom_07241e55fd2fb8c3f33a",
  "custom_0adfc22846ed5dac29b2",
  "custom_0adfc22846ed5dac29b2",
  "custom_106ff0e5f5f051776201",
  "custom_157a0e7251f0f00e213d",
  "custom_157a0e7251f0f00e213d",
  "custom_157a0e7251f0f00e213d",
  "custom_2cdf80c5495e87e61017",
  "custom_2cdf80c5495e87e61017",
  "custom_313376ca5376d3c81cb2",
  "custom_313376ca5376d3c81cb2",
  "custom_313376ca5376d3c81cb2",
  "custom_313376ca5376d3c81cb2",
  "custom_3b95c50deecfd6b63f5f",
  "custom_3b95c50deecfd6b63f5f",
  "custom_546bfb3ba492d0b064d5",
  "custom_5b40577f630fcd2d6808",
  "custom_626e074cfacfa29d8317",
  "custom_6c8fa6072597cf75b9c4",
  "custom_720051739f59a0ff8562",
  "custom_720051739f59a0ff8562",
  "custom_720051739f59a0ff8562",
  "custom_7225d5136519295c4a3b",
  "custom_7be3a72e6ca7f1f8ff7f",
  "custom_7be3a72e6ca7f1f8ff7f",
  "custom_83bff791b55caaa90984",
  "custom_83bff791b55caaa90984",
  "custom_83bff791b55caaa90984",
  "custom_83bff791b55caaa90984",
  "custom_8831e99355d86eefc82d",
  "custom_8bc91aa1c9ea174cd57e",
  "custom_9d0b0a133a0f1140637a",
  "custom_9d9d1c9bfab70f77ad24",
  "custom_a2e79ba4ee17277bd65c",
  "custom_a61e0054392846a38840",
  "custom_a61e0054392846a38840",
  "custom_a61e0054392846a38840",
  "custom_a866ff46a583b6326457",
  "custom_a866ff46a583b6326457",
  "custom_a866ff46a583b6326457",
  "custom_add0e3dfe20eb51e8d1f",
  "custom_add0e3dfe20eb51e8d1f",
  "custom_add0e3dfe20eb51e8d1f",
  "custom_b8111d8594b9b16fa5fe",
  "custom_b8111d8594b9b16fa5fe",
  "custom_b8111d8594b9b16fa5fe",
  "custom_b8d22aeee9c441fd4c56",
  "custom_b8d22aeee9c441fd4c56",
  "custom_c3c9fb4f2e602b5bd03d",
  "custom_c3c9fb4f2e602b5bd03d",
  "custom_c3c9fb4f2e602b5bd03d",
  "custom_d42de312dd0906ed5701",
  "custom_d42de312dd0906ed5701",
  "custom_de94ba4dc352cbfd03c2",
  "custom_e12fac72168d8c592a31",
  "custom_ec3713736268c0d2d696",
  "custom_ec3713736268c0d2d696",
  "custom_ed10b205707622ae846e",
  "custom_f00dc9d8ba85bed912c0",
  "custom_f00dc9d8ba85bed912c0",
  "custom_f00dc9d8ba85bed912c0",
  "custom_f00dc9d8ba85bed912c0",
  "custom_f1a3c3695fc14633f18d",
  "custom_f39d30810b8b0d659cdc",
  "custom_f39d30810b8b0d659cdc",
  "custom_f39d30810b8b0d659cdc",
  "3600141_3600080",
  "3600141_3600080",
  "3600141_3600080",
  "3600141_3600080",
  "3600141_3600080",
  "3600141_3600080",
  "3600141_3600080",
  "3600141_3600080",
  "3600141_3600080",
  "custom_36e605411975025430cf",
  "custom_36e605411975025430cf",
  "custom_4825ee1b18d465eb4540",
  "custom_4b1e9b8c75abda5b2d7e",
  "custom_52de31fdb95532b51cb3",
  "custom_52de31fdb95532b51cb3",
  "custom_57efebae7cbd114f91a0",
  "custom_57efebae7cbd114f91a0",
  "custom_5fbcc04eba1987e1c1b6",
  "custom_5fbcc04eba1987e1c1b6",
  "custom_6e297a612fbedea052fc",
  "custom_6e297a612fbedea052fc",
  "custom_6e297a612fbedea052fc",
  "custom_6e297a612fbedea052fc",
  "custom_6e297a612fbedea052fc",
  "custom_8a0bc734aed6d2de0ab7",
  "custom_8d6a256c9b282e2f1110",
  "custom_8fc0db1dc33cba0affd1",
  "custom_8fc0db1dc33cba0affd1",
  "custom_943b0cc25b050d4c6cc9",
  "custom_94ce57b60d4403008a53",
  "custom_97b585e53ca2aad15c4f",
  "custom_a82e1cfad296dcc89578",
  "custom_b3cef8283b7a7a37e3d6",
  "custom_b3cef8283b7a7a37e3d6",
  "custom_bd3bedaa97094b6ab498",
  "custom_bd3bedaa97094b6ab498",
  "custom_bd3bedaa97094b6ab498",
  "custom_cdc690d0d05b93e23aeb",
  "custom_ceb2256233e1b0775b8e",
  "custom_cef45ff36ef474ab46ac",
  "custom_cef45ff36ef474ab46ac",
  "custom_dae6874d2a26ce21c0a9",
  "custom_dae6874d2a26ce21c0a9",
  "custom_e5b74dd19dffb07ddebd",
  "custom_e5b74dd19dffb07ddebd",
  "custom_e5b74dd19dffb07ddebd",
  "custom_f7b8426ff7a692a7e855",
  "custom_f80f240251c50fe8281f",
  "custom_f80f240251c50fe8281f",
  "custom_fef88c4d2220e8d2f207",
  "custom_e68dd374d80f42ba2cd6",
  "custom_23a016d03b344eb0ad91",
  "custom_8b36464181ee0546f9a5",
  "368054004643",
  "368072004652",
  "3680300",
  "custom_5fbcc04eba1987e1c1b6",
  "custom_5fbcc04eba1987e1c1b6",
  "custom_6e297a612fbedea052fc",
  "custom_6e297a612fbedea052fc",
  "custom_6e297a612fbedea052fc",
  "custom_6e297a612fbedea052fc",
  "custom_6e297a612fbedea052fc",
  "custom_94ce57b60d4403008a53",
  "custom_d42de312dd0906ed5701",
  "custom_d42de312dd0906ed5701",
  "custom_de94ba4dc352cbfd03c2",
  "369999947006",
  "3628590",
  "362475006177",
  "3631920",
];

/**
 * NCES ids that refer to schools / districts
 * that are excluded receiving auto generated links
 * to quotes.
 * */
export const EXCLUSION_LIST = Object.fromEntries(
  exclusionArr.map((value) => [value, true])
);
