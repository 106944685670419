import { useEffect } from "react";
import DmAlertModal from "../answerForm/DmAlertModal";
import { evaluate } from "./DMKeyboard";
import React from "react";
const dec2Frac = (window as any).dec2frac;

const FracModal = ({
  ansToShow,
  decimal,
  open,
  setOpen,
}: {
  ansToShow: string;
  decimal: number;
  open: boolean;
  setOpen: (state: boolean) => void;
}) => {
  const [message, setMessage] = React.useState<string>();

  useEffect(() => {
    const fraction = dec2Frac(decimal);
    if (fraction.toString().includes("NaN")) {
      setMessage("No fraction can be found");
    } else {
      setMessage(
        `The decimal ${evaluate(
          ansToShow
        )} converts into the fraction ${fraction}`
      );
    }
  }, [decimal]);

  return (
    <DmAlertModal
      showModal={open}
      setShowModal={setOpen}
      headline={"Decimal to Fraction"}
      message={message}
      modalAction={() => setOpen(!open)}
    />
  );
};

export default FracModal;
