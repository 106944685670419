import { useEffect, useState } from "react";
import { RadioGroup } from "@headlessui/react";
import clsx from "clsx";
import { CheckCircleIcon } from "@heroicons/react/solid";
import EmojiPicker from "emoji-picker-react";
import {
  ConfigPrizeModalProps,
  ConfettiType,
  ConfettiShape,
} from "../../../types";
import { useLearnerContext } from "../../../contexts/LearnerContext";
import runConfetti from "../../../utils/confetti";

const ConfettiConfig: React.FC<ConfigPrizeModalProps> = ({
  config,
  setConfig,
  setTitle,
  setConfirmButtonText,
  setConfirmButtonDisabled,
}) => {
  const { learner } = useLearnerContext();

  const CONFETTI_TYPES: ConfettiType[] = ["default", "cannon", "fireworks"];
  const CONFETTI_SHAPES: ConfettiShape[] = ["default", "emoji"];

  const [selectedConfettiType, setSelectedConfettiType] =
    useState<ConfettiType>(
      learner?.prizesState?.confetti?.type ?? CONFETTI_TYPES[0]
    );

  const [selectedConfettiShape, setSelectedConfettiShape] =
    useState<ConfettiShape>(
      learner?.prizesState?.confetti?.shape ?? CONFETTI_SHAPES[0]
    );

  const [emojiText, setEmojiText] = useState(
    learner?.prizesState?.confetti?.emoji ?? "❤️"
  );

  useEffect(() => {
    runConfetti(
      learner,
      selectedConfettiType,
      selectedConfettiShape,
      emojiText
    );
    setConfig({
      type: selectedConfettiType,
      shape: selectedConfettiShape,
      ...(selectedConfettiShape === "emoji" ? { emoji: emojiText } : {}),
    });
  }, [
    selectedConfettiType,
    selectedConfettiShape,
    emojiText,
    setConfig,
    learner,
  ]);

  useEffect(() => {
    setTitle("Customize Confetti");
    setConfirmButtonText("Customize Confetti");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [learner?.prizesState]);

  useEffect(() => {
    setConfirmButtonDisabled(false);
  }, [setConfirmButtonDisabled]);

  if (Object.keys(config).length === 0) {
    return null;
  }

  return (
    <>
      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">
          Select a Confetti Effect
        </legend>
        <RadioGroup
          value={selectedConfettiType}
          onChange={setSelectedConfettiType}
          className="mt-1 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4"
        >
          {CONFETTI_TYPES.map((confettiType) => (
            <RadioGroup.Option
              key={confettiType}
              value={confettiType}
              aria-label={confettiType}
              aria-description={`${confettiType}`}
              className={({ active }) =>
                clsx(
                  active
                    ? "border-indigo-600 ring-indigo-600"
                    : "border-gray-300",
                  "relative flex cursor-pointer items-center gap-x-2 rounded-lg border bg-white p-4 shadow-sm hover:border-indigo-600"
                )
              }
            >
              {({ checked }) => (
                <>
                  <span className="block grow text-sm capitalize text-gray-900">
                    {confettiType}
                  </span>
                  <CheckCircleIcon
                    className={clsx(
                      !checked ? "invisible" : "",
                      "h-5 w-5 text-indigo-600"
                    )}
                    aria-hidden="true"
                  />
                  <span
                    className={clsx(
                      checked ? "border-indigo-600" : "border-transparent",
                      "pointer-events-none absolute -inset-px rounded-lg border-2"
                    )}
                    aria-hidden="true"
                  />
                </>
              )}
            </RadioGroup.Option>
          ))}
        </RadioGroup>
      </fieldset>

      <fieldset className="mt-6">
        <legend className="text-sm font-semibold leading-6 text-gray-900">
          Select a Confetti Shape
        </legend>
        <RadioGroup
          value={selectedConfettiShape}
          onChange={setSelectedConfettiShape}
          className="mt-1 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4"
        >
          <RadioGroup.Option
            key="defaultShape"
            value="default"
            aria-label="default shape"
            aria-description="default confetti shape"
            className={({ active }) =>
              clsx(
                active
                  ? "border-indigo-600 ring-indigo-600"
                  : "border-gray-300",
                "relative flex cursor-pointer items-center gap-x-2 rounded-lg border bg-white p-4 shadow-sm hover:border-indigo-600"
              )
            }
          >
            {({ checked }) => (
              <>
                <span className="block grow text-sm text-gray-900">
                  Default
                </span>
                <CheckCircleIcon
                  className={clsx(
                    !checked ? "invisible" : "",
                    "h-5 w-5 text-indigo-600"
                  )}
                  aria-hidden="true"
                />
                <span
                  className={clsx(
                    checked ? "border-indigo-600" : "border-transparent",
                    "pointer-events-none absolute -inset-px rounded-lg border-2"
                  )}
                  aria-hidden="true"
                />
              </>
            )}
          </RadioGroup.Option>
          <RadioGroup.Option
            key="emojiShape"
            value="emoji"
            aria-label="emoji shape"
            aria-description="Emoji confetti shape"
            className={({ active }) =>
              clsx(
                active
                  ? "border-indigo-600 ring-indigo-600"
                  : "border-gray-300",
                "relative flex cursor-pointer items-center gap-x-2 rounded-lg border bg-white p-4 shadow-sm hover:border-indigo-600"
              )
            }
          >
            {({ checked }) => (
              <>
                <span className="text-sm text-gray-900">Emoji</span>
                <span className="grow text-left text-2xl">{emojiText}</span>
                <CheckCircleIcon
                  className={clsx(
                    !checked ? "invisible" : "",
                    "h-5 w-5 text-indigo-600"
                  )}
                  aria-hidden="true"
                />
                <span
                  className={clsx(
                    checked ? "border-indigo-600" : "border-transparent",
                    "pointer-events-none absolute -inset-px rounded-lg border-2"
                  )}
                  aria-hidden="true"
                />
              </>
            )}
          </RadioGroup.Option>
        </RadioGroup>
      </fieldset>
      {selectedConfettiShape === "emoji" && (
        <fieldset className="mt-6">
          <legend className="text-sm font-semibold leading-6 text-gray-900">
            Select an Emoji
          </legend>
          <EmojiPicker
            width={"100%"}
            className="!border-0"
            // searchDisabled={true}
            onEmojiClick={(emojiData, event) => {
              setEmojiText(emojiData.emoji);
            }}
            previewConfig={{
              showPreview: false,
            }}
          />
        </fieldset>
      )}
    </>
  );
};

export default ConfettiConfig;
