import { useParams } from "react-router-dom";
import { useCourseContext } from "../contexts/CourseContext";
import { useComponentTimer } from "./useComponentTimer";
import { useAppTimersMutation } from "./useAppTimersMutation";
import {
  TIMER_LOCALSTORAGE_APP_KEY,
  TIMER_LOCALSTORAGE_BASE_COURSE_KEY,
} from "../constants";

/**
 * Hook to manage app and course timers. Must call on every page to track time
 * spent on courses and on learner app.
 */
export function useAppTimers() {
  const { coursePath } = useParams();
  const courseContext = useCourseContext();
  const courseData = courseContext.getCourseData(coursePath);

  const fallbackCourseKey = `${TIMER_LOCALSTORAGE_BASE_COURSE_KEY}:null`;
  const courseKey = courseData
    ? `${TIMER_LOCALSTORAGE_BASE_COURSE_KEY}:${courseData?.id}`
    : fallbackCourseKey;

  const appTimer = useComponentTimer(TIMER_LOCALSTORAGE_APP_KEY);
  const courseTimer = useComponentTimer(
    courseKey,
    courseKey === fallbackCourseKey
  );

  useAppTimersMutation(() => {
    appTimer.resetTimer();
    courseTimer.resetTimer();
  });
}
