import { useState, useEffect } from "react";
import { CalendarIcon } from "@heroicons/react/outline";
import {
  isMonday,
  isSunday,
  previousMonday,
  nextSunday,
  format,
} from "date-fns/esm";
import clsx from "clsx";
import { DashboardCard } from "./DashboardCard";
import { useDMQuery } from "../../../utils";
import { WeeklyGoalModal } from "../Modals/WeeklyGoalModal";
import { useLearnerContext } from "../../contexts/LearnerContext";
import { DMPieChart } from "../../../student/components/generic/DMPieChart";
import { WEEKLY_GOAL_POINTS } from "../../constants";

export const WeeklyGoalCard: React.FC = () => {
  const [showGoalsModal, setShowGoalsModal] = useState<boolean>(false);
  const { learner } = useLearnerContext();

  const [weekRange, setWeekRange] = useState<Record<string, number>>({
    min: 0,
    max: 4,
  });
  const [weeklyPointsData, setWeeklyPointsData] = useState<
    Record<string, number>[]
  >([]);

  // *****************
  // * Points data
  // *****************
  const { refetch: fetchPointsData } = useDMQuery({
    path: "/learner/data/points-data",
    params: {
      range: [weekRange.min, weekRange.max],
    },
    queryOptions: {
      enabled: false,
      onSuccess(pointsData: Record<string, number>[]) {
        const reversedPointsData = pointsData.reverse();
        setWeeklyPointsData([...weeklyPointsData, ...reversedPointsData]);
        // grab the previous 5 weeks of data on each fetch
        setWeekRange({
          min: weekRange.min + 5,
          max: weekRange.max + 5,
        });
      },
    },
  });

  useEffect(() => {
    fetchPointsData();
  }, [fetchPointsData]);
  const totalWeeklyPoints = learner?.pointsThisWeek;
  const pointPercentage = ((totalWeeklyPoints || 0) / WEEKLY_GOAL_POINTS) * 100;
  const todaysDate = new Date();
  const startOfWeek = format(
    isMonday(todaysDate) ? todaysDate : previousMonday(todaysDate),
    "MMM d"
  );
  const endOfWeek = format(
    isSunday(todaysDate) ? todaysDate : nextSunday(todaysDate),
    "MMM d"
  );
  return (
    <>
      <DashboardCard
        title="Weekly Goal"
        icon={<CalendarIcon />}
        childClasses={clsx(
          "text-center transition-opacity duration-150 max-w-[220px] mx-auto @container w-full relative",
          totalWeeklyPoints === undefined && "opacity-0"
        )}
        tooltip="Your weekly goals resets every Monday at 6 am"
      >
        <DMPieChart
          className="pb-[4.5rem] @[105px]:pb-16 @[115px]:pb-12 @[135px]:pb-5 @[175px]:pb-3"
          data={[
            {
              value: Math.min(totalWeeklyPoints || 0, WEEKLY_GOAL_POINTS),
              color: pointPercentage > 20 ? "url(#goalGradient)" : "#BB94EF",
            },
          ]}
          totalValue={WEEKLY_GOAL_POINTS}
          startAngle={-180}
          lengthAngle={180}
          viewBoxSize={[100, 55]}
          lineWidth={15}
          background="#E3E8EF"
          rounded
        >
          <defs>
            <linearGradient id="goalGradient">
              <stop offset="0%" stopColor="#BB94EF" />
              <stop offset="100%" stopColor="#4C0097" />
            </linearGradient>
          </defs>
        </DMPieChart>
        <div className="absolute inset-x-0 bottom-0 z-[7] text-center">
          <h2 className="leading-none">
            <span className="align-middle font-serif text-[22px] font-bold text-dm-gray-800 @[150px]:text-[28px]">
              {totalWeeklyPoints}
            </span>{" "}
            <span className="font-sans text-xs text-dm-gray-600">
              {totalWeeklyPoints !== 1 ? "total pts" : "total pt"}
            </span>
          </h2>
          <h3 className="mt-0.5 text-xs text-dm-gray-500">
            {WEEKLY_GOAL_POINTS} pt goal
          </h3>
          <button
            onClick={() => setShowGoalsModal(true)}
            className="mt-4 block w-full cursor-pointer font-sans text-xs text-dm-brand-blue-500 hover:underline"
          >
            Week of {startOfWeek} - {endOfWeek}
          </button>
        </div>
      </DashboardCard>
      <WeeklyGoalModal
        fetchPointsData={fetchPointsData}
        visible={showGoalsModal}
        onClose={() => {
          setShowGoalsModal(false);
        }}
        streak={learner?.streaks || 0}
        totalPoints={learner?.pointsEarned || 0}
        pointsData={weeklyPointsData}
      />
    </>
  );
};
