import clsx from "clsx";
import { HTMLAttributes } from "react";
import { useMediaQuery } from "usehooks-ts";

export const DiscountBanner: React.FC<
  HTMLAttributes<HTMLHeadingElement> & {
    discount: number;
  }
> = ({ discount, ...props }) => {
  const isSmallDevice = useMediaQuery("(max-width : 767px)");

  return (
    <h5
      className={clsx(
        "absolute -top-4 rounded-full bg-[#7BD78C] px-4 py-1 font-bold",
        isSmallDevice ? "right-2" : "left-16"
      )}
      {...props}
    >
      {discount}% off
    </h5>
  );
};
