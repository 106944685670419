import { useEffect, useRef, useState } from "react";
import { PascalsCell } from "./PascalsCell";
import { useLearnerContext } from "../../contexts/LearnerContext";
import { usePascalsTriangleMutation } from "../../utils/usePascalsTriangleMutation";
import { LockedRow } from "./LockedRow";
import { generatePascalsTriangle } from "../../utils/generatePascalsTriangle";
import { useNextPrize } from "../../utils/useNextPrize";
import clsx from "clsx";

export const PascalsTriangle: React.FC = () => {
  const { pointsAvailable, learner } = useLearnerContext();
  const { mutate } = usePascalsTriangleMutation();
  const nthRow = learner?.pascalsRow ?? [];
  const nextPrize = useNextPrize();
  const containerRef = useRef<HTMLDivElement>(null);
  const [activeCell, setActiveCell] = useState<number | null>(null);
  const rows = generatePascalsTriangle(nthRow);
  const [liveRegionText, setLiveRegionText] = useState<string | null>(null);
  const initialRender = useRef<boolean>(true);

  const isHighlighted = (rowIndex: number, cellIndex: number): boolean => {
    if (activeCell === null) {
      return false;
    }
    if (activeCell === 0 && cellIndex === 0) {
      return true;
    }
    if (activeCell === nthRow.length - 1 && cellIndex === rowIndex) {
      return true;
    }
    if (rowIndex === nthRow.length - 2) {
      if (cellIndex === activeCell || cellIndex === activeCell - 1) {
        return true;
      }
    }
    return false;
  };

  const generateAriaLabel = (
    rowIndex: number,
    cellIndex: number,
    expectedValue: number
  ): string | undefined => {
    // no labels for locked row cells
    if (rowIndex === nthRow.length) return undefined;

    // labels for input cells
    if (rowIndex === nthRow.length - 1) {
      const introText = `Enter value for cell ${
        cellIndex + 1
      } in the bottom row.`;
      if (cellIndex === 0) {
        return `${introText} Hint: In all rows, the first cell is a 1.`;
      } else if (cellIndex === rowIndex) {
        return `${introText} Hint: ${
          cellIndex + 1
        } is the last cell in the row. In all rows, the last cell is a 1.`;
      } else {
        const prevRow = rows[rowIndex - 1];
        const leftAddend = prevRow[cellIndex - 1][1];
        const rightAddend = prevRow[cellIndex][1];
        return `${introText} Hint: The two numbers above this cell in the triangle are ${leftAddend} and ${rightAddend}. Add them to determine the value of the cell.`;
      }
    }

    // default: labels for static cells
    return `Row: ${rowIndex + 1}, Cell: ${
      cellIndex + 1
    }, Value: ${expectedValue}.`;
  };

  const redeemPoints = async () => {
    const nthRowCopy = [...nthRow];
    if (activeCell !== null) {
      nthRowCopy[activeCell] = true;
      setActiveCell(null);
      await mutate({ newRow: nthRowCopy, nextPrize: nextPrize?.key });
    }
  };

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      setLiveRegionText(
        `Correct! You now have ${pointsAvailable} points available.`
      );
      setTimeout(() => {
        setLiveRegionText(null);
      }, 5000);
    }
  }, [pointsAvailable]);

  return (
    <div
      ref={containerRef}
      className={clsx(
        nthRow.length >= 9 ? "scale-[0.6]" : "scale-90",
        "mx-6 flex flex-col justify-start gap-[11px] md:scale-100"
      )}
      aria-label="Pascal's Triangle"
      role="region"
    >
      {rows.map((row, rowIndex) => (
        <div key={`row-${rowIndex}`} className="z-10 flex justify-center">
          {row.map(([actualValue, expectedValue], cellIndex) => (
            <PascalsCell
              key={`cell-${rowIndex}-${cellIndex}`}
              currentValue={actualValue}
              expectedValue={expectedValue}
              isEditable={actualValue === null}
              isHighlighted={isHighlighted(rowIndex, cellIndex)}
              showPlus={
                activeCell !== 0 &&
                activeCell === cellIndex &&
                rowIndex === nthRow.length - 2
              }
              setActiveCell={() => setActiveCell(cellIndex)}
              redeemPoints={() => redeemPoints()}
              ariaLabel={generateAriaLabel(rowIndex, cellIndex, expectedValue)}
            />
          ))}
        </div>
      ))}

      <LockedRow length={nthRow.length + 1} />
      <span className="sr-only" role="alert">
        {liveRegionText}
      </span>
    </div>
  );
};
