import { AxiosRequestConfig } from "axios";

/** Add Content-Type: application/json to an Axios request config */
export function withJsonHeader<T>(
  config: AxiosRequestConfig<T> = {}
): AxiosRequestConfig<T> {
  return {
    ...config,
    headers: {
      ...config.headers,
      "Content-Type": "application/json",
    },
  };
}
