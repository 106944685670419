import clsx from "clsx";
import Button from "../../../student/components/generic/button";
import { FeatureCard } from "./FeatureCard";
import { FeatureSection } from "./FeatureSection";
import { PageFooter } from "../Layouts/PageFooter";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { REACT_APP_LEARNER_LINK, getFilePath } from "../../../utils";
import { useDocumentTitle, useMediaQuery } from "usehooks-ts";
import { BetaWaitlistForm } from "./BetaWaitlistForm";
import { ResponsiveImage } from "../ResponsiveImage";
import { GoogleAnalyticsTag } from "../GoogleAnalyticsTag";

type PageLocation = "upper" | "lower";

export const SplashPage: React.FC = () => {
  useDocumentTitle("DeltaMath for Home");
  const containerSize = "w-[1281px] px-4 md:px-6 max-w-full";
  const formSectionId = "join";
  const { hash } = useLocation();
  const [expanded, setExpanded] = useState(false);

  const isSmallDevice = useMediaQuery("(max-width : 767px)");
  const isHighDpi = useMediaQuery("screen and (min-resolution: 73dpi)");
  const texturedBgTile = (loc: PageLocation) => {
    if (loc === "upper") {
      return getFilePath(
        isHighDpi
          ? "/images/learner/textured-blue-tile@2x.png"
          : "/images/learner/textured-blue-tile.png"
      );
    }
    return getFilePath(
      isHighDpi
        ? "/images/learner/textured-blue-tile-light@2x.png"
        : "/images/learner/textured-blue-tile-light.png"
    );
  };
  const texturedBgBorder = (loc: PageLocation) => {
    if (loc === "upper") {
      return getFilePath(
        isSmallDevice
          ? isHighDpi
            ? "/images/learner/textured-blue-border-small@2x.png"
            : "/images/learner/textured-blue-border-small.png"
          : isHighDpi
          ? "/images/learner/textured-blue-border@2x.png"
          : "/images/learner/textured-blue-border.png"
      );
    }
    return getFilePath(
      isSmallDevice
        ? isHighDpi
          ? "/images/learner/textured-blue-border-small-light@2x.png"
          : "/images/learner/textured-blue-border-small-light.png"
        : isHighDpi
        ? "/images/learner/textured-blue-border-light@2x.png"
        : "/images/learner/textured-blue-border-light.png"
    );
  };
  const texturedBorderSize = (loc: PageLocation) => {
    if (loc === "upper") {
      return isSmallDevice
        ? "bg-[length:375px_16px]"
        : "bg-[length:1440px_41px]";
    }
    return isSmallDevice ? "bg-[length:375px_20px]" : "bg-[length:1440px_48px]";
  };
  const scrollToForm = () => {
    document
      .getElementById(formSectionId)
      ?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (hash === `#${formSectionId}`) {
      scrollToForm();
    }
  }, [hash]);

  return (
    <main className="bg-dm-background-blue flex min-h-dvh w-full flex-col items-center gap-20 bg-dm-background-blue-100 md:gap-32">
      <GoogleAnalyticsTag />
      <section
        className="relative flex w-full flex-col items-center bg-[length:200px_200px]"
        style={{ backgroundImage: `url('${texturedBgTile("upper")}')` }}
      >
        <header className="flex w-full justify-between px-4 pb-3 pt-4 md:px-14 md:pb-6 md:pt-6">
          <ResponsiveImage
            srcs={[getFilePath("/images/DeltaMath-Logo_Home.svg")]}
            alt="DeltaMath Home"
          />
          <Button
            className="h-10 bg-white py-0 text-sm"
            href={`${REACT_APP_LEARNER_LINK}/sign-in`}
            type="outline"
          >
            Log In
          </Button>
        </header>

        <div
          className={clsx(
            "flex flex-col-reverse gap-10 pb-20 pt-6 md:flex-row md:gap-12 md:pb-24 md:pt-24",
            containerSize
          )}
        >
          <div className="flex-1">
            <h1 className="mb-1 font-serif text-4xl font-medium !leading-snug text-dm-brand-blue-600 md:text-6xl">
              DeltaMath for Home
            </h1>
            <p className="font-serif font-medium !leading-normal text-dm-brand-blue-600 md:text-2xl">
              Discover new ways to succeed in math with our flexible platform
              designed for effective at-home learning.
            </p>
            <Button
              className="mt-7 h-12 w-full text-sm md:w-72"
              onClick={scrollToForm}
            >
              Join Waitlist
            </Button>
          </div>
          <div className="hidden w-96 md:flex xl:w-[540px]">
            <ResponsiveImage
              className="object-contain"
              srcs={[
                getFilePath("/images/learner/splash/deltie-thumbs-up-grid.png"),
                getFilePath(
                  "/images/learner/splash/deltie-thumbs-up-grid@2x.png"
                ),
              ]}
              alt="Deltie smiling and giving a thumbs up"
            />
          </div>
          <div className="flex justify-center md:hidden">
            <ResponsiveImage
              className=""
              srcs={[
                getFilePath(
                  "/images/learner/splash/deltie-thumbs-up-grid-alt.png"
                ),
                getFilePath(
                  "/images/learner/splash/deltie-thumbs-up-grid-alt@2x.png"
                ),
              ]}
              alt="Deltie smiling and giving a thumbs up"
            />
          </div>
        </div>

        <div
          className={clsx(
            "absolute left-0 top-full h-4 w-full md:h-[41px]",
            texturedBorderSize("upper")
          )}
          style={{
            backgroundImage: `url('${texturedBgBorder("upper")}')`,
          }}
        />
      </section>

      <section
        className={clsx("flex flex-col items-center gap-10", containerSize)}
      >
        <h2 className="text-center font-serif text-[32px] font-bold text-dm-brand-blue-600 md:text-left md:text-[40px]">
          Math Learning That Clicks
        </h2>
        <div className="flex flex-col gap-2 md:flex-row md:gap-6">
          <FeatureCard
            image={
              <ResponsiveImage
                srcs={[
                  getFilePath("/images/learner/splash/bullseye.png"),
                  getFilePath("/images/learner/splash/bullseye@2x.png"),
                ]}
                alt="Target with an arrow in the center"
              />
            }
            title="Targeted Practice"
            description="Problems and practice that adapt to skill level for faster progress."
          />
          <FeatureCard
            image={
              <ResponsiveImage
                srcs={[
                  getFilePath("/images/learner/splash/bar-chart.png"),
                  getFilePath("/images/learner/splash/bar-chart@2x.png"),
                ]}
                alt="Bar chart with multiple bars of different heights and colors"
              />
            }
            title="Instant Feedback"
            description="Detailed explanations to turn mistakes into learning opportunities."
          />
          <FeatureCard
            image={
              <ResponsiveImage
                srcs={[
                  getFilePath("/images/learner/splash/a-plus.png"),
                  getFilePath("/images/learner/splash/a-plus@2x.png"),
                ]}
                alt="Spiral notebook opened to a page with A+ circled"
              />
            }
            title="Proven Results"
            description="Trusted methods support skill development and true understanding."
          />
        </div>
      </section>

      <FeatureSection
        className={containerSize}
        title="Math Success for Students"
        onClick={scrollToForm}
        image={
          <>
            <div className="hidden w-96 justify-center md:flex xl:w-[525px]">
              <ResponsiveImage
                className="object-contain"
                srcs={[
                  getFilePath("/images/learner/splash/math-tools-grid.png"),
                  getFilePath("/images/learner/splash/math-tools-grid@2x.png"),
                ]}
                alt="Yellow background with a calculator, rulers, and a chalkboard overlaid"
              />
            </div>
            <div className="flex justify-center md:hidden">
              <ResponsiveImage
                srcs={[
                  getFilePath("/images/learner/splash/math-tools-grid-alt.png"),
                  getFilePath(
                    "/images/learner/splash/math-tools-grid-alt@2x.png"
                  ),
                ]}
                alt="Yellow background with a calculator, rulers, and a chalkboard overlaid"
              />
            </div>
          </>
        }
      >
        Our platform accelerates your learning whether you need to catch up, get
        ahead, or simply feel more confident in math. Track your progress, earn
        rewards, and celebrate your wins!
      </FeatureSection>

      <FeatureSection
        className={clsx(containerSize, "md:flex-row-reverse")}
        title="Learning Insights for Parents"
        onClick={scrollToForm}
        image={
          <>
            <div className="hidden w-96 justify-center md:flex xl:w-[525px]">
              <ResponsiveImage
                className="object-contain"
                srcs={[
                  getFilePath("/images/learner/splash/math-diagrams-grid.png"),
                  getFilePath(
                    "/images/learner/splash/math-diagrams-grid@2x.png"
                  ),
                ]}
                alt="Purple background with an abacus, a triangle with labeled angles, and a line graph with labeled axes"
              />
            </div>
            <div className="flex justify-center md:hidden">
              <ResponsiveImage
                srcs={[
                  getFilePath(
                    "/images/learner/splash/math-diagrams-grid-alt.png"
                  ),
                  getFilePath(
                    "/images/learner/splash/math-diagrams-grid-alt@2x.png"
                  ),
                ]}
                alt="Green background with an abacus, a triangle with labeled angles, and a line graph with labeled axes"
              />
            </div>
          </>
        }
      >
        Our parent dashboard provides insights to guide your child&apos;s
        learning journey. Uncover strengths, target areas for improvement, and
        offer the right support, when they need it.
      </FeatureSection>

      <section className="w-full" id={formSectionId}>
        <div
          className={clsx(
            "h-4 w-full md:h-[41px]",
            texturedBorderSize("lower")
          )}
          style={{ backgroundImage: `url('${texturedBgBorder("lower")}')` }}
        />
        <div
          className={clsx(
            "flex w-full flex-col items-center overflow-hidden bg-[length:200px_200px] py-10 transition-all md:pb-[60px] md:pt-32",
            // Reduce the gap when the form is expanded. The transition-all here makes this not jump
            // while the rest of the form is fading in
            expanded ? "md:gap-32" : "md:gap-72"
          )}
          style={{ backgroundImage: `url('${texturedBgTile("lower")}')` }}
        >
          <div
            className={clsx(
              "relative flex flex-col gap-14 md:flex-row md:gap-6",
              containerSize
            )}
          >
            <div className="flex grow flex-col gap-6 md:mt-28 md:gap-8 lg:gap-6">
              <h2 className="font-serif text-[26px] font-bold text-dm-brand-blue-600 md:text-[32px]">
                Get ready for DeltaMath for Home!
              </h2>
              <ul className="flex list-disc flex-col gap-4 pl-6 font-serif text-dm-brand-blue-600 md:text-lg">
                <li>
                  <strong>Special Offers:</strong> Get a free trial and our best
                  discounts.
                </li>
                <li>
                  <strong>Early Access:</strong> Experience the platform before
                  anyone else.
                </li>
                <li>
                  <strong>Get Rewarded:</strong> Earn prizes and rewards.
                </li>
              </ul>
            </div>
            <BetaWaitlistForm
              className="box-content w-full shrink-0 md:w-[300px] md:px-6 lg:pl-32 lg:pr-40"
              setExpanded={setExpanded}
            />
            <div className="left-0 top-[400px] mb-10 -translate-x-20 md:absolute md:mb-0">
              <ResponsiveImage
                srcs={[
                  getFilePath("/images/learner/splash/deltie-pointing.png"),
                  getFilePath("/images/learner/splash/deltie-pointing@2x.png"),
                ]}
                alt="Deltie smiling and pointing towards the beta waitlist form"
              />
            </div>
          </div>
          <div className={containerSize}>
            <PageFooter />
          </div>
        </div>
      </section>
    </main>
  );
};
