import { useEffect, useRef, useState } from "react";
import { useLearnerContext } from "../../contexts/LearnerContext";
import { isPascalsRowCompletable } from "../../utils/isPascalsRowCompletable";
import { MIN_POINTS_TO_UNLOCK } from "../../constants";
import { Transition } from "@headlessui/react";
import { GiftIcon, SparklesIcon } from "@heroicons/react/outline";
import { NavLink, useNavigate } from "react-router-dom";
import { REACT_APP_LEARNER_LINK, getFilePath } from "../../../utils";
import clsx from "clsx";
import Modal from "../../../student/components/generic/Modal";
import { ResponsiveImage } from "../ResponsiveImage";
import { useUserContext } from "../../../shared/contexts/UserContext";

const TOAST_TIMEOUT = 5000;

export const PointsToast: React.FC = () => {
  const navigate = useNavigate();
  const { learner, pointsAvailable, state } = useLearnerContext();
  const userContext = useUserContext();
  const decampTarget = userContext.state?.decodedJwt?.data?.decampTarget;
  const prevPoints = useRef(pointsAvailable);
  const prevCompletable = useRef<boolean | null>(null);

  const [initialPointsLoaded, setInitialPointsLoaded] =
    useState<boolean>(false);
  const [toastType, setToastType] = useState<"unlock" | "points">("unlock");
  const [showToast, setShowToast] = useState(false);
  const [pointsGained, setPointsGained] = useState<number>(0);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    prevPoints.current = null;
    setInitialPointsLoaded(false);
  }, [decampTarget]);

  useEffect(() => {
    if (
      pointsAvailable &&
      !initialPointsLoaded &&
      prevPoints.current === null
    ) {
      prevPoints.current = pointsAvailable;
      setInitialPointsLoaded(true);
    }
  }, [pointsAvailable, initialPointsLoaded]);

  // Hide the toast after a timeout
  useEffect(() => {
    if (showToast) {
      setTimeout(() => {
        setShowToast(false);
      }, TOAST_TIMEOUT);
    }
  }, [showToast]);

  // Force the toast to show for testing
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get("toast") === "unlock") {
      setTimeout(() => {
        setToastType("unlock");
        setShowToast(true);
      }, 1000);
    } else if (params.get("toast") === "points") {
      setTimeout(() => {
        setPointsGained(123);
        setToastType("points");
        setShowToast(true);
      }, 1000);
    }
  }, []);

  // Set initial completable value
  useEffect(() => {
    if (
      pointsAvailable !== null &&
      prevCompletable.current === null &&
      learner?.pascalsRow
    ) {
      prevCompletable.current = isPascalsRowCompletable(
        learner.pascalsRow,
        pointsAvailable
      );
    }
  }, [learner?.pascalsRow, pointsAvailable]);

  // Toast display logic
  useEffect(() => {
    if (
      pointsAvailable === null ||
      prevPoints.current === null ||
      prevPoints.current === pointsAvailable ||
      showToast
    ) {
      return;
    }

    let completable = false;
    let showingToast = false;

    // Just crossed MIN_POINTS_TO_UNLOCK
    if (
      prevPoints.current < MIN_POINTS_TO_UNLOCK &&
      pointsAvailable >= MIN_POINTS_TO_UNLOCK
    ) {
      setToastType("unlock");
      showingToast = true;
      setShowToast(true);
    }

    // User has unlocked Pascal's Triangle and row is completable
    else if (learner?.pascalsRow) {
      completable = isPascalsRowCompletable(
        learner.pascalsRow,
        pointsAvailable
      );
      if (
        pointsAvailable > prevPoints.current &&
        completable &&
        prevCompletable.current !== null &&
        !prevCompletable.current
      ) {
        setToastType("unlock");
        showingToast = true;
        setShowToast(true);
      }
    }

    const points = pointsAvailable - prevPoints.current;
    if (points >= 50) {
      setPointsGained(points);
      setShowModal(true);
    } else if (points > 0 && showingToast !== true) {
      // User has gained points without unlocking a new row
      setPointsGained(points);
      setToastType("points");
      setShowToast(true);
    }

    prevCompletable.current = completable;
    prevPoints.current = pointsAvailable;
  }, [learner, pointsAvailable, showToast]);

  return (
    <>
      <Modal
        onClose={() => setShowModal(false)}
        visible={showModal}
        confirmButtonText="View My Points"
        closeX
        onConfirm={() => navigate(`${REACT_APP_LEARNER_LINK}/points`)}
        confirmAriaLabel="View My Points"
        secondaryButtonText="See Test Summary"
        secondaryOnClick={() => setShowModal(false)}
        secondaryAriaLabel="See Test Summary"
        body={
          <div className="flex flex-col items-center">
            <ResponsiveImage
              srcs={[
                getFilePath(
                  "/images/learner/points/deltie-50-extra-points.png"
                ),
                getFilePath(
                  "/images/learner/points/deltie-50-extra-points@2x.png"
                ),
              ]}
              alt="Deltie with star eyes awarding 50 extra points"
            />
            <h2 className="mt-10 font-serif text-2xl font-bold leading-10">
              50 Bonus Points Awarded!
            </h2>
            <p className="w-full">
              This calls for a celebration! You&rsquo;ve earned 50 bonus points
              for completing every section of this course with a score of 70% or
              higher on all quizzes and tests. Keep it up, math champ!
            </p>
          </div>
        }
      />
      <Transition
        show={showToast}
        enter="transition-all duration-300 ease-out"
        enterFrom="opacity-0 translate-y-10"
        enterTo="transform opacity-100"
        leave="transition-all duration-300 ease-in"
        leaveFrom="transform opacity-100"
        leaveTo="opacity-0 translate-y-10"
      >
        <NavLink
          className={clsx(
            "fixed right-4 flex gap-2 rounded-lg bg-dm-charcoal-800 px-3 py-2 font-bold text-white shadow-lg",
            toastType === "unlock" ? "w-72 items-start" : "items-center",
            state.currentProblemSolved ? "bottom-20 md:bottom-4" : "bottom-4"
          )}
          to={`${REACT_APP_LEARNER_LINK}/points`}
        >
          {toastType === "unlock" ? (
            <>
              <GiftIcon className="mt-1 w-6" aria-hidden="true" />
              <span aria-live="assertive">
                You have enough points to unlock a prize.
              </span>
            </>
          ) : (
            <>
              <SparklesIcon className="w-5 rotate-180" aria-hidden="true" />
              <span aria-live="assertive" className="sr-only">
                {pointsGained === 1
                  ? `Plus 1 point`
                  : `Plus ${pointsGained} points`}
              </span>
              <span aria-hidden>
                {pointsGained === 1 ? "+1 pt" : `+${pointsGained} pts`}
              </span>
            </>
          )}
        </NavLink>
      </Transition>
    </>
  );
};
