import { round } from "lodash";
import { Fragment } from "react";

type Props = {
  isLinkedCorrection: boolean;
  teacherId: string;
  questions: any;
  questionOrder: string[];
  testgrade: any;
  totalPoints: any;
  correctionValue: (key: string, totalValue: number) => void;
  pointValues: (key: any, pointsValue: number) => void;
  isPointsTypeEarned: boolean;
  testdata: any;
  extraPoints: any;
  extra: any;
};

const CorrectionQuestions = (props: Props) => {
  // If we dont have any questions or we are a linked correction we dont need to render any of this component really
  if (
    props.isLinkedCorrection ||
    !props.questions ||
    Object.keys(props.questions).length === 0
  ) {
    return <></>;
  }

  return (
    <>
      <Fragment key={`key-${props.teacherId}`}>
        <label
          id={`correctionLabel-${props.teacherId}`}
          htmlFor={`correction-${props.teacherId}`}
          className="text-right text-sm font-bold text-dm-charcoal-500"
        >
          Test Grade
        </label>
        <input
          type="text"
          defaultValue={
            isNaN(props.testgrade) ? "" : round(parseFloat(props.testgrade), 3)
          }
          name={`correction-${props.teacherId}`}
          id={`correction-${props.teacherId}`}
          aria-labelledby={`correctionLabel-${props.teacherId} correctionPostLabel-${props.teacherId}`}
          onChange={(e) =>
            props.correctionValue("test", round(parseFloat(e.target.value), 3))
          }
          className="block rounded-md border-[#979797] px-2.5 py-2 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        />
        <div
          id={`correctionPostLabel-${props.teacherId}`}
          className="col-span-2 items-center text-sm text-dm-charcoal-500"
        >
          <strong>/{props.totalPoints}</strong>
        </div>
      </Fragment>
      {props.questionOrder.map((key: string) => {
        const question = props.questions[key];

        return (
          <Fragment key={`${encodeURI(question.name)}-key`}>
            <label
              id={`${question.name}-inputLabel`}
              htmlFor={`${question.name}-input`}
              className="text-right text-sm text-dm-charcoal-500"
            >
              Question {question?.name}
            </label>
            <input
              type="text"
              defaultValue={
                props.testdata
                  ? isNaN(props.testdata[key])
                    ? ""
                    : props.testdata[key]
                  : ""
              }
              name={`${question.name}-input`}
              id={`${question.name}-input`}
              aria-labelledby={`${question.name}-inputLabel ${question.name}-inputPostLabel`}
              onChange={(e) =>
                props.pointValues(key, round(parseFloat(e.target.value), 3))
              }
              className="block rounded-md border-[#979797] px-2.5 py-2 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
            <div
              id={`${question.name}-inputPostLabel`}
              className="col-span-2 items-center text-sm text-dm-charcoal-500"
            >
              <strong>/{question.points}</strong>{" "}
              {props.isPointsTypeEarned ? "(points earned)" : "(points lost)"}
            </div>
          </Fragment>
        );
      })}
      {!!props.extraPoints && (
        <>
          <label
            id={`extraLabel-${props.teacherId}`}
            htmlFor={`extra-${props.teacherId}`}
            className="text-right text-sm text-gray-700"
          >
            Extra Credit
          </label>
          <input
            type="text"
            defaultValue={isNaN(props.extra) ? "" : props.extra}
            name={`extra-${props.teacherId}`}
            id={`extra-${props.teacherId}`}
            aria-labelledby={`extraLabel-${props.teacherId} extraPostLabel-${props.teacherId}`}
            onChange={(e) =>
              props.correctionValue(
                "extraPoints",
                round(parseFloat(e.target.value), 3)
              )
            }
            className="block rounded-md border-[#979797] px-2.5 py-2 text-sm focus:border-indigo-500 focus:ring-indigo-500"
          />
          <div
            id={`extraPostLabel-${props.teacherId}`}
            className="col-span-2 items-center text-sm text-dm-charcoal-500"
          >
            <strong>/{props.extraPoints}</strong> (points earned)
          </div>
        </>
      )}
    </>
  );
};

export default CorrectionQuestions;
