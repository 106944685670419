import { useState } from "react";
import { LearnerAssignment, LearnerAssignmentTypes } from "../../../types";
import SolutionModal from "../../Modals/SolutionModal";
import clsx from "clsx";
import Button from "../../../../student/components/generic/button";

type Props = {
  questionIndex: number;
  preQuizAssignment?: LearnerAssignment;
  postQuizAssignment?: LearnerAssignment;
};

const QuestionResults = (props: Props) => {
  const [visible, setVisible] = useState<
    | {
        skillCode: string;
        assignmentId: string;
        assignmentType: LearnerAssignmentTypes;
        version?: number;
        skillId: string;
      }
    | undefined
  >(undefined);
  const preQuizMatchingQuestion = props.preQuizAssignment
    ? props.preQuizAssignment.skills[props.questionIndex]
    : undefined;
  // If the post quiz is submitted, snag the skill from the skills array
  // if it isn't submitted then snag it from result history if they exist
  const postQuizMatchingQuestion = props.postQuizAssignment
    ? props.postQuizAssignment.submitted
      ? props.postQuizAssignment.skills[props.questionIndex]
      : props.postQuizAssignment.resultHist &&
        props.postQuizAssignment.resultHist.length
      ? props.postQuizAssignment.resultHist[
          props.postQuizAssignment.resultHist.length - 1
        ].skills[props.questionIndex]
      : undefined
    : undefined;

  return (
    <div>
      <div className="flex flex-col gap-x-4 md:flex-row">
        {preQuizMatchingQuestion && (
          <div className="flex items-center gap-2">
            <p>Pre-Quiz Results:</p>
            <Button
              type="link"
              className="flex cursor-pointer items-center gap-x-0.5"
              onClick={() => {
                if (props.preQuizAssignment) {
                  setVisible({
                    assignmentId: props.preQuizAssignment._id,
                    assignmentType: "preQuiz",
                    skillCode: preQuizMatchingQuestion.sk,
                    skillId: preQuizMatchingQuestion._id,
                  });
                }
              }}
            >
              <i
                className={clsx(
                  "far w-5 text-center text-xl leading-none",
                  preQuizMatchingQuestion.score === 1
                    ? "fa-check !text-dm-success-500"
                    : "fa-times !text-dm-error-500"
                )}
                aria-hidden="true"
              ></i>
              <span className="text-sm text-dm-brand-blue-500 hover:underline">
                Question {props.questionIndex + 1}
              </span>
            </Button>
          </div>
        )}
        {preQuizMatchingQuestion && postQuizMatchingQuestion && (
          <p className="border-l"></p>
        )}
        {postQuizMatchingQuestion && (
          <div className="flex items-center gap-2">
            <p>Post-Quiz Results:</p>
            <Button
              type="link"
              className="flex cursor-pointer items-center gap-x-0.5"
              onClick={() => {
                if (props.postQuizAssignment) {
                  // If the post quiz is submitted, snag the skill from the skills array
                  // if it isn't submitted then add in the version index to get the correct question results
                  setVisible({
                    assignmentId: props.postQuizAssignment._id,
                    assignmentType: "postQuiz",
                    skillCode: postQuizMatchingQuestion.sk,
                    version: props.postQuizAssignment.submitted
                      ? undefined
                      : props.postQuizAssignment.resultHist
                      ? props.postQuizAssignment.resultHist.length - 1
                      : 0,
                    skillId: postQuizMatchingQuestion._id,
                  });
                }
              }}
            >
              <i
                className={clsx(
                  "far w-5 text-xl leading-none",
                  postQuizMatchingQuestion.score === 1
                    ? "fa-check !text-dm-success-500"
                    : "fa-times !text-dm-error-500"
                )}
                aria-hidden="true"
              ></i>
              <span className="text-sm text-dm-brand-blue-500 hover:underline">
                Question {props.questionIndex + 1}
              </span>
            </Button>
          </div>
        )}
      </div>
      <SolutionModal
        visible={visible !== undefined}
        onClose={() => setVisible(undefined)}
        assignmentId={visible?.assignmentId}
        assignmentType={visible?.assignmentType ?? "preQuiz"}
        version={visible?.version}
        skillCode={visible?.skillCode}
        questionIndex={props.questionIndex}
        skillId={visible?.skillId}
      />
    </div>
  );
};

export default QuestionResults;
