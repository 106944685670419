import { useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  LearnerAssignmentTypes,
  LearnerAssignment,
  LearnerAssignmentSkillData,
  SubmitAssignmentResponse,
} from "../types";
import Button from "../../student/components/generic/button";
import ProgressBar from "../../shared/ProgressBar";
import { useMediaQuery } from "usehooks-ts";
import { clamp, findLastIndex, findIndex } from "lodash";
import { useMutation } from "react-query";
import axios from "axios";
import clsx from "clsx";
import { deltamathAPI } from "../../manager/utils";
import { useLearnerContext } from "../contexts/LearnerContext";
import { useDeltaToastContext } from "../../shared/contexts/ToasterContext";
import { Infotip } from "../../shared/Infotip";
import QuestionResults from "./Subunit/Practice/QuestionResults";
import SubmitQuizModal from "./Modals/SubmitQuizModal";
import { ComponentTimer } from "../utils/useComponentTimer";
import { REACT_APP_LEARNER_LINK } from "../../utils";
import { PostQuizSummaryNextButton } from "./Subunit/PostQuiz/PostQuizSummaryNextButton";
import { useLoadingIcon } from "./useLoadingIcon";
import { useCourseContext } from "../contexts/CourseContext";
import {
  startAssessmentEvent,
  submitAssessmentEvent,
} from "../analytics/events";
import { useLearnerAnalytics } from "../analytics/useLearnerAnalytics";
import { getNextUnfinished } from "../utils/getNextUnfinished";
import { useCreateAssignmentMutation } from "../utils";

const ASSIGNMENT_SUBMISSION_DELAY = 4000;

type Props = {
  pageState: LearnerAssignmentTypes;
  assignment?: LearnerAssignment;
  title: string;
  preQuizAssignment?: LearnerAssignment;
  postQuizAssignment?: LearnerAssignment;
  skippedPreQuiz?: boolean;
  callback?: (param?: "refetch" | "complete" | undefined) => void;
  finishedSubunit: boolean;
  preQuiz100: boolean;
  testTimer?: ComponentTimer;
  onSubmitAssignment: () => void;
};

export default function ScoreBar(props: Props): JSX.Element {
  const courseContext = useCourseContext();
  const nextProblemBtnRef = useRef<HTMLButtonElement>(null);
  const nextProblemTestBtnRef = useRef<HTMLButtonElement>(null);
  const nextSkillBtnRef = useRef<HTMLButtonElement>(null);
  const submitBtnRef = useRef<HTMLButtonElement>(null);
  const {
    coursePath,
    unitPath,
    subunitPath,
    assignmentType,
    indexOfSkill,
    submittedTime,
  } = useParams();
  const navigate = useNavigate();
  const learnerContext = useLearnerContext();
  const toast = useDeltaToastContext();
  const loadingIcon = useLoadingIcon();
  const courseData = courseContext.getCourseData(coursePath);
  const unitData = courseContext.getUnitData(unitPath, courseData?.id);
  const subunitData = courseContext.getSubunitData(
    subunitPath,
    unitPath,
    coursePath
  );
  const courseProgress = learnerContext.getProgress(courseData?.id || "");
  const unitProgress =
    courseProgress &&
    courseProgress.units.find((u) => u.unitId === unitData?.id);
  const createAssignmentMutation = useCreateAssignmentMutation();

  const isSmallDevice = useMediaQuery("(max-width : 1023px)");

  const [showSubmitModal, setShowSubmitModal] = useState<boolean>(false);
  const [isWaitingForNetwork, setIsWaitingForNetwork] =
    useState<boolean>(false);

  const { track, getAssignmentData } = useLearnerAnalytics();

  const questionIndex = isNaN(Number(indexOfSkill)) ? 0 : Number(indexOfSkill);
  const skillTotal = props.assignment?.skills?.length || undefined;
  const skillComplete =
    props?.assignment?.skills?.[questionIndex]?.skillComplete || false;

  const currentSkill =
    indexOfSkill !== undefined
      ? props.assignment?.skills?.[questionIndex]
      : undefined;

  const baseUrl =
    props.pageState === "unitTest"
      ? `${REACT_APP_LEARNER_LINK}/${coursePath}/${unitPath}/unittest`
      : props.pageState === "courseTest"
      ? `${REACT_APP_LEARNER_LINK}/${coursePath}/coursetest`
      : `${REACT_APP_LEARNER_LINK}/${coursePath}/${unitPath}/${subunitPath}/${assignmentType}`;

  // Gives "Next Problem" button on a test focus when student completes a problem
  useEffect(() => {
    if (learnerContext.state.currentProblemSolved) {
      handleBtnFocus(nextProblemTestBtnRef);
    }
  }, [learnerContext.state.currentProblemSolved]);

  const nextProblem = () => {
    if (skillTotal) {
      learnerContext.navigateProblem();
      navigate(`${baseUrl}/${questionIndex}?next=true`);
    }
  };

  // Using lodash functions due to TS errors w/ findLastIndex
  const nextRequiredSkill = (moveForward = true) => {
    let upcomingSkill = moveForward
      ? findIndex(
          props.assignment?.skills,
          (skill: LearnerAssignmentSkillData) => !skill.skillComplete,
          questionIndex + 1
        )
      : findLastIndex(
          props.assignment?.skills,
          (skill: LearnerAssignmentSkillData) => !skill.skillComplete,
          questionIndex - 1
        );

    // if there is no next/previous required problem, search again from the beginning/end of the skill array
    if (upcomingSkill === -1) {
      upcomingSkill = moveForward
        ? findIndex(
            props.assignment?.skills,
            (skill: LearnerAssignmentSkillData) => !skill.skillComplete
          )
        : findLastIndex(
            props.assignment?.skills,
            (skill: LearnerAssignmentSkillData) => !skill.skillComplete
          );

      // if the only required problem is the currently selected one, pass -1
      if (upcomingSkill === questionIndex) upcomingSkill = -1;
    }

    return upcomingSkill;
  };

  const changeProblem = (moveForward = true) => {
    learnerContext.navigateProblem();
    if (skillTotal) {
      const nextProblem: number =
        props.pageState === "practice"
          ? nextRequiredSkill(moveForward)
          : moveForward
          ? questionIndex + 1
          : questionIndex - 1;
      if (nextProblem !== -1) {
        const nextProblemClamp = clamp(nextProblem, 0, skillTotal - 1);
        navigate(
          `${baseUrl}/${nextProblemClamp}${
            submittedTime ? `/${submittedTime}` : ""
          }`
        );
      }
    }
  };

  const readableAssignmentType = () => {
    if (props.pageState === "preQuiz") {
      return "Pre-Quiz";
    } else if (props.pageState === "practice") {
      return "Practice";
    } else if (props.pageState === "postQuiz") {
      return "Post-Quiz";
    } else if (props.pageState === "unitTest") {
      return "Unit Test";
    } else if (props.pageState === "courseTest") {
      return "Course Test";
    }
  };

  const practiceContinue =
    (props?.assignment?.skills?.[questionIndex]?.record || 0) < 3;

  const allSkillsComplete =
    props.assignment?.skills &&
    props.assignment.skills.every((s) => s.skillComplete === true);

  const showSubmitAssignment =
    props.pageState !== "practice" &&
    allSkillsComplete &&
    !props.assignment?.submitted;

  const pastAssignment = submittedTime
    ? (props.assignment?.resultHist || []).find(
        (rh) => Number(new Date(rh.submitted)) === Number(submittedTime)
      )
    : undefined;

  const versionIndex =
    pastAssignment && props.assignment?.resultHist && submittedTime
      ? props.assignment?.resultHist.indexOf(pastAssignment)
      : undefined;

  const isTestOrPostQuiz = ["unitTest", "courseTest", "postQuiz"].includes(
    props.pageState
  );

  const showRetakePostQuiz =
    props.pageState === "postQuiz" &&
    props.assignment?.submitted &&
    isNaN(Number(indexOfSkill));

  const showRetakeTest =
    isTestOrPostQuiz &&
    props.pageState !== "postQuiz" &&
    props.assignment?.submitted &&
    isNaN(Number(indexOfSkill));

  const nextUp =
    subunitData && unitData
      ? getNextUnfinished(
          subunitData.id,
          unitData.id,
          courseProgress,
          courseData
        )
      : undefined;

  const nextAssignmentType = () => {
    if (
      props.pageState === "preQuiz" &&
      props.assignment?.grade === 1 &&
      nextUp
    ) {
      const nextUpUnitData =
        nextUp.type !== "course"
          ? courseContext.getUnitData(nextUp.unitId, undefined)
          : undefined;
      const nextUpSubunitData =
        nextUp.type === "subunit"
          ? courseContext.getSubunitData(
              nextUp.subunitId,
              nextUp.unitId,
              undefined
            )
          : undefined;

      const url =
        nextUp.type === "course"
          ? `${REACT_APP_LEARNER_LINK}/${
              nextUp.courseProgress.courseTest?.submitted
                ? `course/${coursePath}/`
                : `${coursePath}/courseTest${
                    nextUp.courseProgress.courseTest ? "/0" : ""
                  }`
            }`
          : nextUp.type === "unit"
          ? `${REACT_APP_LEARNER_LINK}/${coursePath}/${
              nextUpUnitData?.friendlyPath || nextUp.unitProgress.unitId
            }${nextUp.unitProgress.unitTest ? "/0" : ""}`
          : nextUp.type === "subunit"
          ? `${REACT_APP_LEARNER_LINK}/${coursePath}/${
              nextUpUnitData?.friendlyPath || nextUp.unitProgress.unitId
            }/${
              nextUpSubunitData?.friendlyPath ||
              nextUp.subunitProgress.subunitId
            }`
          : undefined;

      if (url) {
        navigate(url, { state: { landing: true } });
      } else {
        navigate(
          `${REACT_APP_LEARNER_LINK}/${coursePath}/${unitPath}/${subunitPath}/practice`,
          { state: { landing: true } }
        );
      }
    } else if (props.pageState === "preQuiz") {
      navigate(
        `${REACT_APP_LEARNER_LINK}/${coursePath}/${unitPath}/${subunitPath}/practice`,
        { state: { landing: true } }
      );
    } else if (
      props.pageState === "practice" ||
      props.pageState === "postQuiz"
    ) {
      navigate(
        `${REACT_APP_LEARNER_LINK}/${coursePath}/${unitPath}/${subunitPath}/postquiz`
      );
    }
  };

  const nextAssignmentText = () => {
    if (props.pageState === "preQuiz" && props.assignment?.grade === 1) {
      if (nextUp) {
        if (
          nextUp.type === "course" &&
          nextUp.courseProgress.courseTest?.submitted
        ) {
          return "Review Course";
        } else if (nextUp.type === "course") {
          return "Next Up: Course Test";
        } else if (nextUp.type === "unit") {
          return "Next Up: Unit Test";
        } else if (nextUp.subunitProgress.progress > 0) {
          return "Continue Next Section";
        }
      }
      return "Start Next Section";
    } else if (props.pageState === "preQuiz") {
      return "Next Up: Practice";
    } else if (props.pageState === "practice") {
      return "Next Up: Post-Quiz";
    } else if (props.pageState === "postQuiz") {
      return "Back to Summary";
    }
  };

  const submit = useMutation(
    (body: string) => {
      return axios.post<SubmitAssignmentResponse>(
        `${deltamathAPI()}/learner/assignment/submit`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    },
    {
      onSuccess: (data) => {
        learnerContext.updateAssignmentAndProgress(data.data);
        const grade = Math.round((data.data.assignment?.grade || 0) * 100);
        if (
          (props.pageState === "preQuiz" && grade >= 100) ||
          (isTestOrPostQuiz && grade >= 70)
        ) {
          props.callback?.("complete");
        } else {
          props.callback?.();
        }
        props.testTimer?.clearTimer();
        setIsWaitingForNetwork(false);
        track(
          submitAssessmentEvent({
            ...getAssignmentData(props.assignment?.type),
            score: data.data.assignment.grade,
            attemptNumber: (data.data.assignment.resultHist?.length ?? 0) + 1,
          })
        );
      },
      onError: () => {
        toast.addToast({
          message: "There was an error submitting your assignment.",
          status: "Error",
        });
        setIsWaitingForNetwork(false);
      },
    }
  );

  const submitAssignment = () => {
    if (props.assignment) {
      setIsWaitingForNetwork(true);
      if (loadingIcon !== "default") {
        props.onSubmitAssignment();
      }
      const body = {
        assignmentId: props.assignment._id,
        courseId: props.assignment.courseId,
        ...(props.testTimer
          ? {
              duration: props.testTimer.elapsed
                ? Math.round(props.testTimer.elapsed / 1000)
                : undefined,
            }
          : {}),
      };
      setTimeout(
        () => {
          submit.mutate(JSON.stringify(body));
        },
        loadingIcon === "default" ? 1 : ASSIGNMENT_SUBMISSION_DELAY
      );
    }
  };

  /* Retake Post-Quiz */
  const retake = useMutation(
    (body: string) => {
      return axios.post<SubmitAssignmentResponse>(
        `${deltamathAPI()}/learner/assignment/retake`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    },
    {
      onSuccess: (data) => {
        learnerContext.updateAssignmentAndProgress(data.data);

        track(
          startAssessmentEvent({
            ...getAssignmentData(props.assignment?.type),
            attemptNumber: (data.data.assignment.resultHist?.length ?? 0) + 1,
          })
        );

        props.callback?.("refetch");
        setIsWaitingForNetwork(false);
      },
      onError: () => {
        toast.addToast({
          message: "There was an error submitting your assignment.",
          status: "Error",
        });
        setIsWaitingForNetwork(false);
      },
    }
  );

  const retakeAssignment = () => {
    if (props.assignment) {
      setIsWaitingForNetwork(true);
      const body = {
        assignmentId: props.assignment._id,
        courseId: props.assignment.courseId,
      };
      retake.mutate(JSON.stringify(body));
    }
  };

  const handleBtnFocus = (ref: React.RefObject<HTMLButtonElement>) => {
    ref.current?.focus();
  };

  const previousBtnText =
    isSmallDevice || props.assignment?.submitted || allSkillsComplete
      ? "Previous"
      : "Previous Problem";
  const nextBtnText =
    isSmallDevice || props.assignment?.submitted || allSkillsComplete
      ? "Next"
      : "Next Problem";
  const buttonType = props.assignment?.submitted ? "outline" : "primary";

  const unitIndex =
    courseData && unitData
      ? courseData.unitOrder.indexOf(unitData.id)
      : undefined;

  const nextUnit =
    props.pageState === "unitTest" &&
    courseData &&
    unitIndex !== undefined &&
    unitIndex < courseData.unitOrder.length - 1
      ? courseContext.getUnitData(
          courseData.unitOrder[unitIndex + 1],
          undefined
        )
      : undefined;

  return (
    <>
      <div
        className={clsx(
          "dm-scorebar flex w-full flex-col gap-4 border-b border-dm-charcoal-100 bg-white px-6 py-4",
          "max-lg:sticky max-lg:bottom-0 max-lg:z-[7] max-lg:order-3 max-lg:items-stretch max-lg:border-t",
          "lg:flex-row lg:flex-wrap lg:items-center"
        )}
      >
        {!isSmallDevice && (
          <>
            {props.pageState === "practice" && currentSkill ? (
              <div
                className={clsx(
                  "flex flex-grow gap-y-1 text-sm",
                  currentSkill.required === 0 ? "flex-row gap-x-4" : "flex-col"
                )}
              >
                <h3 className="flex items-center gap-x-2 font-bold">
                  Score: {currentSkill.score}
                  {currentSkill.required > 0
                    ? `/${currentSkill.required}`
                    : null}{" "}
                  {currentSkill.required === 0 && (
                    <Infotip
                      options={{ inline: true, tooltipRight: true }}
                      message="This skill is optional because you demonstrated understanding on the pre-quiz."
                      ariaLabel="Optional Skill"
                    >
                      <i
                        className="far fa-question-circle text-lg font-normal leading-none text-dm-gray-200"
                        aria-hidden="true"
                      ></i>
                    </Infotip>
                  )}
                </h3>
                {currentSkill.required > 0 && (
                  <ProgressBar
                    totalSegments={currentSkill.required}
                    currentScore={currentSkill.score}
                    currentRecord={currentSkill.record}
                    showTickMarks={true}
                    solvePage={true}
                  />
                )}
                {props.pageState === "practice" && (
                  <QuestionResults
                    questionIndex={questionIndex}
                    preQuizAssignment={props.preQuizAssignment}
                    postQuizAssignment={props.postQuizAssignment}
                  />
                )}
              </div>
            ) : (
              <h2 className="flex-grow text-sm font-bold max-lg:hidden">{`${
                props.title
              } ${readableAssignmentType()}`}</h2>
            )}
          </>
        )}
        {props.pageState !== "practice" && skillTotal && indexOfSkill && (
          <div className="flex flex-row flex-nowrap items-stretch justify-between gap-x-4">
            <Button
              size="small"
              type={buttonType}
              className="shrink-0 max-lg:flex-grow"
              onClick={() => changeProblem(false)}
              disabled={questionIndex <= 0}
            >
              <i className="far fa-arrow-left mr-1" aria-hidden="true"></i>{" "}
              {previousBtnText}
            </Button>
            <Button
              size="small"
              type={buttonType}
              className="shrink-0 max-lg:flex-grow"
              onClick={() => changeProblem()}
              disabled={questionIndex >= (skillTotal || 0) - 1}
              ref={nextProblemTestBtnRef}
            >
              {nextBtnText}{" "}
              <i className="far fa-arrow-right ml-1" aria-hidden="true"></i>
            </Button>
          </div>
        )}
        {((props.pageState === "courseTest" &&
          courseProgress &&
          !courseProgress.courseTest?.ref) ||
          (props.pageState === "unitTest" &&
            unitProgress &&
            !unitProgress.unitTest?.ref)) && (
          <Button
            onClick={() => {
              createAssignmentMutation(
                props.pageState,
                courseProgress.courseId,
                unitProgress?.unitId
              );
            }}
          >
            Start {props.pageState === "unitTest" ? "Unit" : "Course"} Test
          </Button>
        )}
        {learnerContext.state.currentProblemSolved &&
          (!skillComplete || practiceContinue) &&
          props.pageState === "practice" && (
            <Button
              size="small"
              type={buttonType}
              className="shrink-0 max-md:w-full"
              onClick={() => nextProblem()}
              ref={nextProblemBtnRef}
              onMountFunc={() => handleBtnFocus(nextProblemBtnRef)}
            >
              Next Problem
              <i className="far fa-arrow-right ml-1" aria-hidden="true"></i>
            </Button>
          )}
        {props.pageState === "practice" &&
          skillComplete &&
          !practiceContinue &&
          nextRequiredSkill() !== -1 && (
            <Button
              size="small"
              className="shrink-0 max-md:w-full"
              onClick={() => changeProblem()}
              ref={nextSkillBtnRef}
              onMountFunc={() => handleBtnFocus(nextSkillBtnRef)}
            >
              Next Skill
              <i className="far fa-arrow-right ml-1" aria-hidden="true"></i>
            </Button>
          )}
        {showSubmitAssignment && (
          <Button
            size="small"
            className="shrink-0 max-md:w-full"
            onClick={() => {
              setShowSubmitModal(true);
            }}
            disabled={isWaitingForNetwork}
            ref={submitBtnRef}
            onMountFunc={() => handleBtnFocus(submitBtnRef)}
          >
            Submit {readableAssignmentType()}
          </Button>
        )}
        {((props.pageState === "preQuiz" &&
          (props.skippedPreQuiz || props.assignment?.submitted)) ||
          (props.pageState === "practice" &&
            skillTotal &&
            allSkillsComplete)) &&
          !showRetakePostQuiz && (
            <Button
              size="small"
              className="shrink-0 max-md:w-full"
              onClick={nextAssignmentType}
            >
              {nextAssignmentText()}
            </Button>
          )}
        {(showRetakePostQuiz || showRetakeTest) && (
          <Button
            size="small"
            type="outline"
            className="shrink-0 max-md:w-full"
            onClick={retakeAssignment}
            disabled={isWaitingForNetwork}
          >
            Retake{" "}
            {props.pageState === "unitTest"
              ? "Unit Test"
              : props.pageState === "courseTest"
              ? "Course Test"
              : "Post-Quiz"}
          </Button>
        )}
        {showRetakeTest &&
          unitProgress &&
          props.pageState === "unitTest" &&
          nextUnit && (
            <Button
              size="small"
              className="shrink-0 max-md:w-full"
              href={`${REACT_APP_LEARNER_LINK}/${coursePath}/${
                unitProgress.progress === 1
                  ? nextUnit.friendlyPath || nextUnit.id
                  : unitPath
              }`}
            >
              {unitProgress.progress === 1
                ? "Start Next Unit"
                : "View This Unit"}
            </Button>
          )}
        {props.finishedSubunit && showRetakePostQuiz && (
          <PostQuizSummaryNextButton assignment={props.assignment} />
        )}
        {isTestOrPostQuiz &&
        (props.assignment?.submitted || versionIndex) &&
        indexOfSkill !== undefined ? (
          <Button
            size="small"
            className="shrink-0 max-md:w-full"
            href={baseUrl}
          >
            Back to Summary
          </Button>
        ) : null}
      </div>
      {showSubmitAssignment && (
        <SubmitQuizModal
          visible={showSubmitModal}
          onClose={() => {
            setShowSubmitModal(false);
          }}
          onConfirm={() => {
            submitAssignment();
            setShowSubmitModal(false);
          }}
          pageState={props.pageState}
        />
      )}
    </>
  );
}
