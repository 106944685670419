import React, { ReactNode } from "react";
import { convertMathToBraille } from "./render-braille-string";

type Props = {
  children?: ReactNode;
  onKeydown?: (event: KeyboardEvent) => void;
};

const BrailleConversionWrapper = (props: Props) => {
  const body = document.getElementsByTagName("body")[0];

  body.onkeydown = async function (event: KeyboardEvent) {
    if (props.onKeydown) {
      props.onKeydown(event);
    }
    if (event.shiftKey && event.ctrlKey) {
      const type =
        event.key === "E" ? "Nemeth" : event.key === "U" ? "UEB" : undefined;
      if (type) {
        const returnValue = await downloadSvg(type);

        if (returnValue !== false) {
          event.preventDefault();
        }
      }
    }
  };

  const convertToBraille = (type: "UEB" | "Nemeth", element: HTMLElement) => {
    const textElements = element.getElementsByTagName("text");
    const fontSize = element.getAttribute("braillefontsize") || 29;
    const killDegrees = element.getAttribute("braillekilldegrees");
    const killNumbers = element.getAttribute("braillekillnumbers");
    for (let i = 0; i < textElements.length; i++) {
      const el = textElements[i];
      if (el.innerHTML) {
        if (
          (killDegrees && el.innerHTML.indexOf("°") > -1) ||
          (killNumbers && !isNaN(parseInt(el.innerHTML)))
        ) {
          el.setAttribute("opacity", "0");
          continue;
        }
        el.classList.add("braille");
        if (el.getAttribute("braille")) {
          el.innerHTML = el.getAttribute("braille") || "";
        }
        el.innerHTML = convertMathToBraille(el.innerHTML, type).replace(
          "°",
          ""
        ); // nemeth uses three symbols for degree, UEB uses 2. not worth the space. just put the number and let them assume degrees
        (window as any).DeltaGraph.prototype.repositionBraille(el, {
          fontSize,
        });
      }
    }
  };

  const downloadSvg = async (type: "UEB" | "Nemeth") => {
    // All SVG elements
    const svgElements = document.getElementsByTagName("svg");
    if (!svgElements || svgElements.length === 0) {
      return false;
    }
    const svgArray = Array.from(svgElements);

    // Filtering so only finding svgs with the class braille
    const brailleElement = svgArray.filter((svg) =>
      svg.classList.contains("braille")
    );
    if (!brailleElement || brailleElement.length === 0) {
      return false;
    }
    // Get the HTMLElement instead of SVGSVGElement
    const allBrailleSvgElement = brailleElement.map((el) =>
      document.getElementById(el.id)
    );

    // Find active SVG or return if there are no active svgs
    const svgElement = allBrailleSvgElement.find(
      (el) => el === document.activeElement
    );
    if (!svgElement) {
      return false;
    }

    // Using this to replace the SVG back when we're done with conversion / downloading
    // const svgHtml = svgElement.outerHTML;

    // Removes potential of blue outline around svg
    svgElement.blur();

    // Adding white background to svg
    const rects = svgElement.getElementsByClassName(
      "transparent-svg-background"
    );
    Array.from(rects).map((rect) => {
      rect.setAttribute("fill", "white");
    });

    // Adjusting viewbox to have more space around so there are hopefully no cutoff elements
    const padding = Number(svgElement.getAttribute("braillepadding")) || 0;
    const viewbox = svgElement.attributes.getNamedItem("viewBox");
    if (viewbox) {
      const viewBoxValues = viewbox.value.split(" ");
      if (viewBoxValues.length >= 4) {
        // Preserving viewbox ratio while adding padding
        const ratio = Number(viewBoxValues[3]) / Number(viewBoxValues[2]);
        const newViewBoxValue = `${Number(viewBoxValues[0]) - padding} ${
          Number(viewBoxValues[1]) - padding * ratio
        } ${Number(viewBoxValues[2]) + padding * 2} ${
          Number(viewBoxValues[3]) + padding * ratio * 2
        }`;
        svgElement.setAttribute("viewBox", newViewBoxValue);
      }
    }

    // Converting all text / numbers in svg to either UEB or Nemeth braille
    convertToBraille(type, svgElement);
    // We're importing here so we dont load this when we don't need it
    const htmlToImage = await import("html-to-image");

    // Downloading image
    htmlToImage
      .toPng(svgElement)
      .then((dataUrl: string) => {
        const link = document.createElement("a");
        link.download = "image.png";
        link.href = dataUrl;
        link.click();

        // Reverting svg to what it was before messing with things
        //svgElement.outerHTML = svgHtml;
      })
      .catch((error: Error) => {
        console.error(error);
      });
  };

  return <>{props.children}</>;
};

export default BrailleConversionWrapper;
