import ProgressBar from "../../../shared/ProgressBar";
import Button from "../../../student/components/generic/button";
import { Subunit, SubunitProgress } from "../../types";
import SkillsTooltip from "../Subunit/SkillsTooltip";
import { useEffect, useRef, useState } from "react";
import { useLearnerContext } from "../../contexts/LearnerContext";
import { useResizeObserver } from "usehooks-ts";

type Props = {
  subunit: Subunit;
  subunitProgresses: SubunitProgress[] | undefined;
  sectionNumber: number;
  subunitID: string;
  href: string;
  isOpen: boolean;
};

const MARGIN = 8;

export const UnitSection: React.FC<Props> = ({
  subunit,
  subunitProgresses,
  sectionNumber,
  subunitID,
  href,
  isOpen,
}) => {
  const sectionTitleRef = useRef<HTMLSpanElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const { width: containerWidth } = useResizeObserver({
    ref: containerRef,
  });
  const iconContainerRef = useRef<HTMLDivElement>(null);
  const [offsetWidth, setOffsetWidth] = useState<number>(0);
  const [iconWidth, setIconWidth] = useState<number>(0);
  const { learner } = useLearnerContext();

  /** To prevent having the Infotip within the button (which will not work for accessibility purposes), the Infotip icon must be absolutely positioned within the UnitSection component. This useEffect measures the elements for that positioning. */
  useEffect(() => {
    if (isOpen) {
      // calculating width must be outside of the render cycle due to the transitions in the accordion
      setTimeout(() => {
        if (sectionTitleRef.current && iconContainerRef.current) {
          const { width: textWidth } =
            sectionTitleRef.current.getBoundingClientRect();
          setOffsetWidth(textWidth);

          const { width: iconContainerWidth } =
            iconContainerRef.current.getBoundingClientRect();
          setIconWidth(iconContainerWidth);
        }
      }, 0);
    }
  }, [isOpen]);

  const subunitProgress = subunitProgresses?.find(
    (s) => s.subunitId === subunitID
  );

  const difficulty = subunitProgress?.difficulty || learner?.level || "easy";

  return (
    <div className="relative" ref={containerRef}>
      <h4 className="text-dm-charcoal-800">
        <Button
          type="link"
          href={href}
          className="mr-2 flex h-full w-full flex-col justify-between !py-0 text-sm text-dm-charcoal-800"
        >
          <span className="mr-8">
            <span ref={sectionTitleRef}>
              <strong>Section {sectionNumber}:</strong> {subunit.name}
            </span>
          </span>
          <div className="flex flex-col">
            <ProgressBar
              percentCompleted={(subunitProgress?.progress || 0) * 100}
              solvePage={false}
              tooltipEnabled={false}
              gradientBknd={true}
              width="w-full"
              height="h-6"
            />
            <p className="text-xs">
              <strong>
                {Math.round((subunitProgress?.progress || 0) * 100)}%
              </strong>{" "}
              complete
            </p>
          </div>
        </Button>
      </h4>
      <div
        className="absolute -top-1"
        style={
          containerWidth && containerWidth >= offsetWidth + iconWidth + MARGIN
            ? {
                left: offsetWidth + MARGIN,
              }
            : {
                right: 0,
              }
        }
        ref={iconContainerRef}
      >
        <SkillsTooltip
          skills={subunit.skills}
          subunitId={subunit.id}
          difficulty={difficulty}
        />
      </div>
    </div>
  );
};
