import { useMediaQuery } from "usehooks-ts";
import { FooterCtaNarrow } from "./FooterCtaNarrow";
import { FooterCtaWide } from "./FooterCtaWide";

export const FooterCta: React.FC = () => {
  const isSmallDevice = useMediaQuery("(max-width : 550px)");
  if (isSmallDevice) {
    return <FooterCtaNarrow />;
  }
  return <FooterCtaWide />;
};
