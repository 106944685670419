interface Dictionary<T> {
  [Key: string]: T;
}

export const UebAsciiToBrailleMap: Map<string, string> = new Map([
  ["A", "⠁"],
  ["B", "⠃"],
  ["C", "⠉"],
  ["D", "⠙"],
  ["E", "⠑"],
  ["F", "⠋"],
  ["G", "⠛"],
  ["H", "⠓"],
  ["I", "⠊"],
  ["J", "⠚"],
  ["K", "⠅"],
  ["L", "⠇"],
  ["M", "⠍"],
  ["N", "⠝"],
  ["O", "⠕"],
  ["P", "⠏"],
  ["Q", "⠟"],
  ["R", "⠗"],
  ["S", "⠎"],
  ["T", "⠞"],
  ["U", "⠥"],
  ["V", "⠧"],
  ["W", "⠺"],
  ["X", "⠭"],
  ["Y", "⠽"],
  ["Z", "⠵"],
  ["0", "⠚"],
  ["1", "⠁"],
  ["2", "⠃"],
  ["3", "⠉"],
  ["4", "⠙"],
  ["5", "⠑"],
  ["6", "⠋"],
  ["7", "⠛"],
  ["8", "⠓"],
  ["9", "⠊"],
  [".", "⠄"],
  [",", "⠂"],
  [";", "⠆"],
  [":", "⠒"],
  ["!", "⠖"],
  ["?", "⠦"],
  ["(", "⠐⠣"],
  [")", "⠐⠜"],
  ["+", "⠐⠬"],
  ["-", "⠤"],
  ["/", "⠸⠌"],
  ["=", "⠐⠶"],
  ['"', "⠐⠶"],
  ["'", "⠄⠄"],
  ["[", "⠐⠣"],
  ["]", "⠐⠜"],
  ["*", "⠔⠡"],
  ["#", "⠼⠋"],
  ["<", "⠈⠣"],
  [">", "⠈⠜"],
  ["fraction", "⠸⠌"],
  ["fractionBar", "⠲"],
]);

export const NemethAsciiToBrailleMap: Map<string, string> = new Map([
  ["0", "⠴"],
  ["1", "⠂"],
  ["2", "⠆"],
  ["3", "⠒"],
  ["4", "⠲"],
  ["5", "⠢"],
  ["6", "⠖"],
  ["7", "⠶"],
  ["8", "⠦"],
  ["9", "⠔"],
  ["A", "⠁"],
  ["B", "⠃"],
  ["C", "⠉"],
  ["D", "⠙"],
  ["E", "⠑"],
  ["F", "⠋"],
  ["G", "⠛"],
  ["H", "⠓"],
  ["I", "⠊"],
  ["J", "⠚"],
  ["K", "⠅"],
  ["L", "⠇"],
  ["M", "⠍"],
  ["N", "⠝"],
  ["O", "⠕"],
  ["P", "⠏"],
  ["Q", "⠟"],
  ["R", "⠗"],
  ["S", "⠎"],
  ["T", "⠞"],
  ["U", "⠥"],
  ["V", "⠧"],
  ["W", "⠺"],
  ["X", "⠭"],
  ["Y", "⠽"],
  ["Z", "⠵"],
  ["+", "⠬"],
  ["-", "⠨"],
  ["*", "⠣"],
  ["/", "⠌"],
  ["=", "⠨⠅"],
  ["<", "⠐⠅"],
  [">", "⠨⠂"],
  ["^", "⠶⠶"],
  ["_", "⠲"],
  ["%", "⠼⠃⠴"],
  ["@", "⠈⠜"],
  ["&", "⠘⠯"],
  ["#", "⠼⠂⠜"],
  ["(", "⠷"],
  [")", "⠾"],
  ["[", "⠈⠷"],
  ["]", "⠈⠾"],
  ["{", "⠨⠷"],
  ["}", "⠨⠾"],
  ["$", "⠸⠎"],
  [",", "⠠⠂"],
  [";", "⠠⠆"],
  [":", "⠠⠒"],
  ["?", "⠠⠦"],
  ["!", "⠠⠖"],
  [".", "⠠⠄"],
  ["'", "⠄⠄"],
  ['"', "⠄⠶"],
  ["\\", "⠠⠠⠌"],
  ["|", "⠠⠠⠴"],
  ["~", "⠸⠐⠶"],
  ["fraction", "⠨⠌"],
  ["fractionBar", "⠸"],
]);

export const stringMap: Dictionary<string> = {
  "(": "left parenthesis",
  ")": "right parenthesis",
  "[": "open square bracket",
  "]": "close square bracket",
  "\\{": "left curly bracket",
  "\\}": "right curly bracket",
  "\\lbrace": "left curly bracket",
  "\\rbrace": "right curly bracket",
  "\\lvert": "open vertical bar",
  "\\rvert": "close vertical bar",
  "|": "vertical bar",
  "\\vert": "vertical bar",
  "\\uparrow": "up arrow",
  "\\Uparrow": "up arrow",
  "\\downarrow": "down arrow",
  "\\Downarrow": "down arrow",
  "\\updownarrow": "up down arrow",
  "\\leftarrow": "left arrow",
  "\\Leftarrow": "left arrow",
  "\\rightarrow": "right arrow",
  "\\Rightarrow": "right arrow",
  "\\langle": "open angle bracket",
  "\\rangle": "close angle bracket",
  "\\lfloor": "open floor",
  "\\rfloor": "close floor",
  "\\int": "integral",
  "\\intop": "integral",
  "\\lim": "limit",
  "\\ln": "natural log",
  "\\log": "log",
  "\\sin": "sine",
  "\\cos": "cosine",
  "\\tan": "tangent",
  "\\cot": "cotangent",
  "\\csc": "cosecant",
  "\\sec": "secant",
  "\\arcsin": "arc sine",
  "\\arccos": "arc cosine",
  "\\arctan": "arc tangent",
  "\\arccot": "arc cotangent",
  "\\arccsc": "arc cosecant",
  "\\arcsec": "arc secant",
  "\\sum": "sum",
  "/": "slash",
  ",": "comma",
  "...": "dot dot dot",
  ".": "point",
  "-": "negative",
  "+": "plus",
  "~": "tilde",
  ":": "colon",
  "?": "question mark",
  "'": "apostrophe",
  "\\#": "hash symbol",
  "\\%": "percent",
  " ": "space",
  "\\ ": "space",
  "\\$": "dollar sign",
  "\\angle": "angle",
  "\\degree": "degree",
  "\\circ": "circle",
  "\\vec": "vector",
  "\\triangle": "triangle",
  "\\pi": "pi",
  "\\prime": "prime",
  "\\infty": "infinity",
  "\\alpha": "alpha",
  "\\beta": "beta",
  "\\gamma": "gamma",
  "\\omega": "omega",
  "\\theta": "theta",
  "\\sigma": "sigma",
  "\\lambda": "lambda",
  "\\tau": "tau",
  "\\Delta": "delta",
  "\\delta": "delta",
  "\\mu": "mu",
  "\\rho": "rho",
  "\\nabla": "del",
  "\\ell": "ell",
  "\\ldots": "dot dot dot",
  "\\@cdots": "centered dot dot dot",
  "\\lnot": "not",
  "\\emptyset": "empty set",
  // TODO: add entries for all accents
  "\\hat": "hat",
  "\\acute": "acute",
  //geometry added
  "\\perp": "is perpendicular to",
  "\\|": "is parallel to",
  //misc added
  "\\to": "right arrow",
  "\\checkmark": "check mark",
  "\\_": "blank space",
};

export const powerMap: Dictionary<string> = {
  prime: "prime",
  degree: "degrees",
  circle: "degrees",
  "2": "squared",
  "3": "cubed",
};

export const openMap: Dictionary<string> = {
  "|": "open vertical bar",
  ".": "",
  "<": "open angle bracket",
};

export const closeMap: Dictionary<string> = {
  "|": "close vertical bar",
  ".": "",
  ">": "close angle bracket",
  "!": "factorial",
};

export const binMap: Dictionary<string> = {
  "+": "plus",
  "-": "minus",
  "\\pm": "plus or minus",
  "\\cdot": "dot",
  "*": "times",
  "/": "divided by",
  "\\times": "times",
  "\\div": "divided by",
  "\\circ": "circle",
  "\\bullet": "bullet",
  "\\land": "and",
  "\\lor": "or",
  "\\veebar": "xor",
  "\\cup": "union",
  "\\cap": "intersection",
  "\\setminus": "difference",
};

export const relMap: Dictionary<string> = {
  "=": "equals",
  "\\approx": "approximately equals",
  "\\neq": "is not equal to",
  "\\geq": "is greater than or equal to",
  "\\ge": "is greater than or equal to",
  "\\leq": "is less than or equal to",
  "\\le": "is less than or equal to",
  "\\lt": "is less than",
  "\\gt": "is greater than",
  ">": "is greater than",
  "<": "is less than",
  "\\leftarrow": "left arrow",
  "\\Leftarrow": "left arrow",
  "\\rightarrow": "right arrow",
  "\\Rightarrow": "right arrow",
  ":": "colon",
  "\\in": "in",
  "\\notin": "not in",
  "\\subset": "proper subset of",
  "\\subseteq": "subset of",
  "\\supset": "proper superset of",
  "\\supseteq": "superset of",
};

/* some over accents are treated as superscripts in the tree but adding to powerMap is inappropriate b/c powers will be read after the base*/

export const descriptiveAccentOverMap: Dictionary<string> = {
  "\\frown": "arc",
};

export const accentOverMap: Dictionary<string> = {
  "\\overleftarrow": "left arrow",
  "\\overrightarrow": "right arrow",
  "\\overleftrightarrow": "left-right arrow",
};

export const accentUnderMap: Dictionary<string> = {
  "\\underleftarrow": "left arrow",
  "\\underrightarrow": "right arrow",
  "\\underleftrightarrow": "left-right arrow",
  "\\undergroup": "group",
  "\\underlinesegment": "line segment",
  "\\utilde": "tilde",
};

export const denominatorMap: Dictionary<string> = {
  "2": "half",
  "3": "third",
  "4": "quarter",
  "5": "fifth",
  "6": "sixth",
  "7": "seventh",
  "8": "eigth",
  "9": "ninth",
  "10": "tenth",
  "100": "hundredth",
};

export const arrowMap: Dictionary<string> = {
  "\\xleftarrow": "arrow left",
  "\\xrightarrow": "arrow right",
  "\\xLeftarrow": "arrow left",
  "\\xRightarrow": "arrow right",
  "\\xleftrightarrow": "arrow left and right",
  "\\xLeftrightarrow": "arrow left and right",
  "\\xmapsto": "maps to",
  "\\xrightleftarrows": "arrow left and right",
};
