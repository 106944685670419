import { useEffect } from "react";
import {
  REACT_APP_LEARNER_LINK,
  clampForSort,
  unreachableCase,
} from "../../../utils";
import { useCourseContext } from "../../contexts/CourseContext";
import { useLearnerContext } from "../../contexts/LearnerContext";
import { Progress, SubunitProgress } from "../../types";
import { compact } from "lodash";
import { getIncompleteProgress } from "../../utils/getIncompleteProgress";
import { ContinueLearnerCardContent } from "./ContinueLearnerCardContent";

function getSubunitProgressContentType(
  subunitProgress: SubunitProgress | undefined
): "preQuiz" | "practice" | "postQuiz" | undefined {
  if (
    (subunitProgress?.postQuiz && subunitProgress.postQuiz.progress !== 1) ||
    subunitProgress?.practice?.progress === 1
  ) {
    return "postQuiz";
  }
  if (
    (subunitProgress?.practice && subunitProgress.practice.progress !== 1) ||
    subunitProgress?.preQuiz?.progress === 1
  ) {
    return "practice";
  }
  if (subunitProgress?.preQuiz) {
    return "preQuiz";
  }
  return undefined;
}

export const ContinueLearningCard: React.FC = () => {
  const learnerContext = useLearnerContext();
  const courseContext = useCourseContext();

  const [newestCourseProgress] = learnerContext.state.progresses.sort(
    (progressA: Progress, progressB: Progress) =>
      clampForSort(
        new Date(progressB.updatedAt).getTime() -
          new Date(progressA.updatedAt).getTime()
      )
  );

  const course = courseContext.getCourseData(newestCourseProgress?.courseId);

  useEffect(() => {
    // Call this to fetch subunit data
    courseContext.setActiveCourse(course?.id);
  }, [course, courseContext]);

  const result = newestCourseProgress
    ? getIncompleteProgress(newestCourseProgress)
    : undefined;

  if (!result) {
    return (
      <ContinueLearnerCardContent
        isLoaded={false}
        heading={course?.name ?? "Course loading"}
        subheading="Section loading"
        progressAmount={0}
        ctaHref=""
        ctaText="Go to Section"
      />
    );
  }

  if (result.kind === "subunit") {
    const unit = courseContext.getUnitData(
      result.unitProgress.unitId,
      undefined
    );
    const subunit = courseContext.getSubunitData(
      result.subunitProgress.subunitId,
      undefined,
      undefined
    );
    const subunitProgressContentType = getSubunitProgressContentType(
      result.subunitProgress
    );
    const subunitNumber =
      (unit?.subunitOrder?.findIndex((o) => o === subunit?.id) ?? 0) + 1;
    return (
      <ContinueLearnerCardContent
        isLoaded={!!course && !!subunit}
        heading={`${course?.name ?? "Course loading"}: ${
          unit?.name ?? "Loading unit"
        }`}
        subheading={`Section ${subunitNumber}: ${
          subunit?.name ?? "Loading subunit"
        }`}
        progressAmount={result.subunitProgress.progress ?? 0}
        ctaText={
          !result.subunitProgress.progress ? "Go to Section" : "Resume Section"
        }
        ctaHref={compact([
          `${REACT_APP_LEARNER_LINK}/${course?.path}/${unit?.path}/${subunit?.path}`,
          subunitProgressContentType
            ? `/${subunitProgressContentType.toLowerCase()}`
            : undefined,
        ]).join("")}
      />
    );
  }

  if (result.kind === "unit") {
    const unit = courseContext.getUnitData(
      result.unitProgress.unitId,
      undefined
    );
    return (
      <ContinueLearnerCardContent
        isLoaded={!!course && !!unit}
        heading={`${course?.name ?? "Course loading"}: ${
          unit?.name ?? "Loading unit"
        }`}
        subheading="Unit Test"
        progressAmount={result.unitProgress.unitTest?.progress ?? 0}
        ctaText={
          result.unitProgress.unitTest?.progress === undefined
            ? "Go to Unit Test"
            : "Resume Unit Test"
        }
        ctaHref={
          result.unitProgress.unitTest?.progress !== undefined
            ? `${REACT_APP_LEARNER_LINK}/${course?.path}/${unit?.path}/unittest/0`
            : `${REACT_APP_LEARNER_LINK}/${course?.path}/${unit?.path}/unittest`
        }
      />
    );
  }

  if (result.kind === "course") {
    return (
      <ContinueLearnerCardContent
        isLoaded={!!course}
        heading={course?.name ?? "Course loading"}
        subheading="Course Test"
        progressAmount={result.courseProgress.courseTest?.progress ?? 0}
        secondaryButton={result.isEndOfCourse}
        ctaText={
          result.isEndOfCourse
            ? "Course Complete"
            : result.courseProgress.courseTest?.progress === undefined
            ? "Go to Course Test"
            : "Resume Course Test"
        }
        ctaHref={
          result.isEndOfCourse
            ? `${REACT_APP_LEARNER_LINK}/${course?.path}`
            : result.courseProgress.courseTest?.progress === undefined ||
              result.courseProgress.courseTest?.progress === 1
            ? `${REACT_APP_LEARNER_LINK}/${course?.path}/coursetest`
            : `${REACT_APP_LEARNER_LINK}/${course?.path}/coursetest/0`
        }
      />
    );
  }

  return unreachableCase(result);
};
