import { useParams } from "react-router-dom";
import { LearnerAssignment, LearnerAssignmentSkillData } from "../types";
import QuestionResults from "./Subunit/Practice/QuestionResults";
import DonutChart from "../../student/components/DonutChart";

type Props = {
  currentSkill?: LearnerAssignmentSkillData;
  preQuizAssignment?: LearnerAssignment;
  postQuizAssignment?: LearnerAssignment;
};

export default function ScoreBarPracticeMobile(props: Props): JSX.Element {
  const { indexOfSkill } = useParams();
  const questionIndex = isNaN(Number(indexOfSkill)) ? 0 : Number(indexOfSkill);

  if (props.currentSkill === undefined) return <></>;

  return (
    <div className="-mt-2 flex w-full flex-col gap-4 border-b border-dm-charcoal-100 bg-white px-6 py-4">
      <div className="flex flex-grow flex-col gap-2 text-sm">
        <div className="flex items-center gap-x-1.5">
          {!props.currentSkill.record ||
          (props.currentSkill.required &&
            props.currentSkill.record < props.currentSkill.required) ? (
            <DonutChart
              isMaxOne={false}
              progress={{
                total: props.currentSkill.required,
                score: props.currentSkill.score,
              }}
              altClasses="pointer-events-auto h-5 w-5"
            />
          ) : (
            <i
              className="far fa-check text-xl leading-none !text-dm-success-500"
              aria-hidden="true"
            ></i>
          )}
          <h3 className="flex items-center gap-x-2 font-bold">
            Score: {props.currentSkill.score}
            {props.currentSkill.required > 0
              ? `/${props.currentSkill.required}`
              : null}{" "}
            <i
              className="far fa-info-circle text-lg font-normal leading-none text-dm-gray-200"
              aria-hidden="true"
            ></i>
          </h3>
        </div>
        <div className="ml-1">
          <QuestionResults
            questionIndex={questionIndex}
            preQuizAssignment={props.preQuizAssignment}
            postQuizAssignment={props.postQuizAssignment}
          />
        </div>
      </div>
    </div>
  );
}
