/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import { useEffect, useState } from "react";
import PortalModal from "../../../shared/PortalModal";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/outline";
import { SparkleIcon } from "../../../shared/icons/SparkleIcon";
import { format, parse } from "date-fns/esm";
import clsx from "clsx";
import { clampForSort } from "../../../utils";
import { last } from "lodash";
import { useLearnerAnalytics } from "../../analytics/useLearnerAnalytics";
import { openedWeeklyGoalEvent } from "../../analytics/events";

type Props = {
  visible: boolean;
  onClose: () => void;
  streak?: number;
  totalPoints: number;
  pointsData: Record<string, number>[];
  fetchPointsData: () => void;
};

export const WeeklyGoalModal: React.FC<Props> = (props: Props) => {
  const [weekNumber, setWeekNumber] = useState<number>(0);
  const { track } = useLearnerAnalytics();

  const MAX_POINTS = [50, 100, 250, 500, 1000, 2500, 5000];
  const highestPoints: number = props.pointsData?.[weekNumber]
    ? Object.values(props.pointsData[weekNumber]).reduce(
        (acc: number, val: number) => (val > acc ? val : acc),
        0
      )
    : 0;
  const yAxisIndex = MAX_POINTS.findIndex((max: number) => max > highestPoints);
  const yAxis =
    yAxisIndex !== -1
      ? MAX_POINTS[yAxisIndex]
      : MAX_POINTS[MAX_POINTS.length - 1];

  const referenceDate = new Date();
  const sortedDates = props.pointsData[weekNumber]
    ? Object.keys(props.pointsData[weekNumber])
        .map((d) => parse(d, "yyyy-MMM-dd", referenceDate))
        .sort((a, b) => clampForSort(a.getTime() - b.getTime()))
    : [];

  const firstDate = sortedDates.length && format(sortedDates[0], "MMM d");
  const lastDate =
    sortedDates.length && format(last(sortedDates) || new Date(), "MMM d");

  const changeWeek = (direction: "previous" | "next") => {
    const nextWeek = direction === "previous" ? weekNumber + 1 : weekNumber - 1;
    if (nextWeek > props.pointsData.length - 1) {
      props.fetchPointsData();
    }
    setWeekNumber(Math.max(nextWeek, 0));
  };

  const closeModal = () => {
    setWeekNumber(0);
    props.onClose();
  };

  useEffect(() => {
    if (props.visible) {
      track(openedWeeklyGoalEvent());
    }
  }, [props.visible, track]);

  return (
    <PortalModal
      visible={props.visible}
      onClose={closeModal}
      noLine
      title={
        <div className="text-center font-serif text-xl font-bold sm:text-2xl">
          Weekly Points Breakdown
        </div>
      }
      body={
        <section
          className={clsx(!props.pointsData?.[weekNumber] && "invisible")}
        >
          <nav className="flex items-center justify-between sm:px-[15%] md:px-[7%]">
            <button
              onClick={() => changeWeek("previous")}
              aria-label="Go to previous week"
            >
              <ArrowLeftIcon
                className={clsx(
                  "h-5 w-5 text-dm-gray-200 hover:text-dm-gray-500"
                )}
                aria-hidden="true"
              />
            </button>
            <h3 className="select-none text-center font-sans text-sm">
              Week of {firstDate} - {lastDate}
            </h3>
            <button
              className={clsx(weekNumber === 0 && "invisible")}
              onClick={() => changeWeek("next")}
              aria-label="Go to next week"
            >
              <ArrowRightIcon
                className={clsx(
                  "h-5 w-5 text-dm-gray-200 hover:text-dm-gray-500"
                )}
                aria-hidden="true"
              />
            </button>
          </nav>
          <div className="mb-1 mt-6 flex items-center justify-center gap-x-4 text-sm font-bold">
            <div>{props.totalPoints || 0} total pts</div>
            {props.streak && props.streak > 0 ? (
              <div className="flex items-center justify-center gap-x-1">
                <span className="inline-flex h-6 w-6 items-center justify-center rounded-full bg-dm-purple-500 text-white">
                  <SparkleIcon className="h-4 w-4" />
                </span>{" "}
                {props.streak} week streak!
              </div>
            ) : null}
          </div>
          <figure
            className="dm-bargraph overflow-x-auto overflow-y-hidden"
            aria-label="Bar graph showing points earned during the chosen week"
          >
            <div className="mt-5 flex h-60 w-full flex-grow flex-row justify-center">
              <div
                className="mr-1 flex min-w-[46px] flex-col content-center items-end justify-between text-right text-sm text-dm-gray-500"
                aria-label={`Vertical axis from 0 to ${yAxis} points`}
                role="img"
              >
                <div className="flex-grow leading-none" aria-hidden={true}>
                  {yAxis} pts
                </div>
                <div className="leading-none" aria-hidden={true}>
                  0 pts
                </div>
                <div className="spacer basis-5" aria-hidden={true}></div>
              </div>
              {props.pointsData?.[weekNumber] &&
                sortedDates.map((date) => {
                  const key = format(date, "yyyy-MMM-dd");
                  const value = props.pointsData[weekNumber][key];
                  const dayOfWeek = format(date, "ccc");
                  const dayOfWeekAria = format(date, "cccc");
                  const height = Math.min((value / yAxis) * 100, 100);
                  return (
                    <div
                      className="group mr-2 flex h-full w-8 min-w-8 flex-col last-of-type:mr-0 md:mr-3 md:w-12 lg:mr-5"
                      key={key}
                      tabIndex={0}
                      role="img"
                      aria-label={`${value} points on ${dayOfWeekAria}`}
                    >
                      <div
                        className="relative flex flex-grow rounded bg-dm-purple-100"
                        aria-hidden={true}
                      >
                        <div
                          className={clsx(
                            "flex-1 self-end rounded bg-gradient-to-t from-dm-purple-500 to-dm-purple-200",
                            value > yAxis ? "from-80%" : null
                          )}
                          style={{ height: `${height}%` }}
                        ></div>
                        <div
                          className="absolute inset-x-0 hidden pb-0.5 text-center text-sm font-bold text-dm-brand-blue-800 group-hover:block group-focus:block max-lg:block"
                          style={{ bottom: `${height}%` }}
                        >
                          {value}
                        </div>
                      </div>
                      <h6
                        className="h-5 text-center text-sm text-dm-gray-200"
                        aria-hidden={true}
                      >
                        {dayOfWeek}
                      </h6>
                    </div>
                  );
                })}
            </div>
          </figure>
        </section>
      }
    />
  );
};
