import { Dispatch, SetStateAction } from "react";
import clsx from "clsx";
import { DMPieChart } from "../../student/components/generic/DMPieChart";
import { getUnitProgressAriaLabel } from "../utils";

type ProgressType = {
  progressObject?:
    | {
        id: string;
        progress: number;
      }[]
    | undefined;
  index: number;
  name?: string; // to check if a course test
  id?: string;
  progress?: number; // course test progress
  isSticky?: boolean;
  setLastClickedId?: Dispatch<SetStateAction<string>>;
};

const ProgressRadial = (props: ProgressType) => {
  const gotoPanel = () => {
    props.setLastClickedId?.(`panel-${props.id}` || "");
    const element = document.getElementById(`panel-${props.id}`);
    element?.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest",
    });
  };

  const generateAriaLabel = () => {
    if (props.name === "course-test") {
      if (props.progress === 1) return "Course test is complete.";
      const progress = Math.round(props.progress || 0 * 100);
      return `Course test is ${progress} percent complete.`;
    } else if (props.progress === 1) {
      return "Unit is complete.";
    } else {
      return getUnitProgressAriaLabel(props.progressObject, props.index + 1);
    }
  };

  if (props.progress === 1 || props.name === "course-test") {
    return (
      <>
        <button
          className={clsx(
            "relative flex shrink-0 cursor-pointer items-center transition-all",
            "after:absolute after:bottom-0 after:left-0 after:h-1 after:w-full after:rounded after:bg-dm-brand-blue-500 after:opacity-0 after:transition after:duration-300",
            "after:hover:translate-y-1 after:hover:opacity-100",
            props.isSticky ? "h-14 w-12" : "h-16 w-14"
          )}
          onClick={() => gotoPanel()}
        >
          <DMPieChart
            data={[
              {
                value: props.progress || 0,
                color: props.progress === 1 ? "#2F52E9" : "#A9CCFA",
              },
            ]}
            lineWidth={25}
            paddingAngle={0}
            totalValue={1}
            startAngle={270}
            background="#E3E8EF"
            className="pointer-events-none absolute"
            ariaLabel={`${generateAriaLabel()} Press to scroll to ${
              props.name === "course-test" ? "course test" : "unit"
            }.`}
          />
          <i
            className={clsx(
              "left-0 top-0 text-center leading-none transition-all",
              props.isSticky ? "w-12" : "w-14",
              props.progress === 1
                ? "fas fa-check text-2xl !text-dm-brand-blue-500"
                : "far fa-list text-base"
            )}
            aria-hidden="true"
          ></i>
        </button>
      </>
    );
  }

  const segments = props.progressObject?.map((unit: any) => {
    const progress = unit.progress || 0;
    return {
      value: 1,
      color:
        progress === 0 ? "#E3E8EF" : progress === 1 ? "#2F52E9" : "#A9CCFA",
    };
  });

  return (
    <>
      <button
        className={clsx(
          "relative flex shrink-0 cursor-pointer items-center transition-all",
          "after:absolute after:bottom-0 after:left-0 after:h-1 after:w-full after:rounded after:bg-dm-brand-blue-500 after:opacity-0 after:transition after:duration-300",
          "after:hover:translate-y-1 after:hover:opacity-100",
          props.isSticky ? "h-14 w-12" : "h-16 w-14"
        )}
        onClick={() => gotoPanel()}
      >
        <DMPieChart
          data={segments || []}
          lineWidth={25}
          paddingAngle={5}
          totalValue={segments?.length}
          startAngle={270}
          className="pointer-events-none absolute"
          ariaLabel={`${generateAriaLabel()} Press to scroll to unit.`}
        />
        <div
          className={clsx(
            "left-0 top-0 text-center leading-none text-dm-charcoal-800 transition-all",
            props.isSticky ? "w-12 text-sm" : "w-14 text-base"
          )}
          aria-hidden="true"
        >
          {props.index + 1}
        </div>
      </button>
    </>
  );
};

export default ProgressRadial;
