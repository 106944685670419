import axios from "axios";
import { useMutation } from "react-query";
import { deltamathAPI } from "../../../../manager/utils";
import { withJsonHeader } from "../../../../shared/axiosUtils";
import { ProfileField } from "../ProfileField";
import { LearnerSubscriptionDetails } from "../../../types";
import { capitalize } from "lodash";
import { SkeletonText } from "../../Skeleton";
import { useCancellationContext } from "../Cancellation/CancellationContext";
import { useLearnerContext } from "../../../contexts/LearnerContext";
import Button from "../../../../student/components/generic/button";
import { useParentContext } from "../../../contexts/ParentContext";
import { useDeltaToastContext } from "../../../../shared/contexts/ToasterContext";
import { isSubscriptionActive } from "../../../utils/isSubscriptionActive";

export const PaymentInformationField: React.FC<{
  subscription: LearnerSubscriptionDetails | undefined;
}> = ({ subscription }) => {
  const toastContext = useDeltaToastContext();
  const { fetchChildLearners } = useParentContext();
  const learnerContext = useLearnerContext();
  const { learners } = useParentContext();
  const { setShowCancelModal, refetchSubscriptionData, setSelectedLearners } =
    useCancellationContext();

  const { card } = subscription || {};

  const { mutateAsync: paymentMethodUpdateMutation } = useMutation<
    { data: { url: string } },
    unknown
  >(() => {
    return axios.post(
      `${deltamathAPI()}/payments/subscriptions/update/payment-method`,
      JSON.stringify({
        url: window.location.origin + window.location.pathname,
      }),
      withJsonHeader()
    );
  });

  const { mutateAsync: reactivateMutation, isLoading: isReactivating } =
    useMutation(() => {
      return axios.post(
        `${deltamathAPI()}/payments/subscriptions/reactivate/parent`,
        "",
        withJsonHeader()
      );
    });

  const updatePaymentMethod = async () => {
    const { data } = await paymentMethodUpdateMutation();
    window.location.href = data.url;
  };

  const handleReactivate = async () => {
    try {
      await reactivateMutation();
      await Promise.all([
        refetchSubscriptionData(),
        learnerContext.refetchLearner(),
        fetchChildLearners(),
      ]);
    } catch (e: any) {
      toastContext.addToast({
        message: e.response.data.message || "Failed to reactivate subscription",
        status: "Error",
      });
    }
  };

  const handleCancelSubscription = async () => {
    if (
      learnerContext.learner &&
      !learnerContext.learner.entitlements.includes("parent")
    ) {
      setSelectedLearners([learnerContext.learner?._id]);
    } else if (subscription?.quantity === 1 && learners.length === 1) {
      setSelectedLearners(learners.map((l) => l._id));
    }
    setShowCancelModal(true);
  };

  const expMonth =
    card?.expMonth === undefined
      ? "??"
      : card.expMonth < 10
      ? `0${card.expMonth}`
      : card.expMonth;
  const expYear =
    card?.expYear === undefined ? "??" : card.expYear.toString().slice(-2);

  return (
    <ProfileField label="Payment Information">
      {card ? (
        <>
          <span>Name: {card.cardholder}</span>
          <span>
            {capitalize(card.brand)}: **** **** ****{" "}
            <strong>{card.last4}</strong>
          </span>
          <span>
            Expires: {expMonth}/{expYear}
          </span>
          {learnerContext.learner?.subscriptionWillCancel !== true &&
            subscription?.status !== "canceled" && (
              <>
                <Button type="link" onClick={updatePaymentMethod}>
                  Update Payment
                </Button>
                <Button
                  type="link"
                  className="mt-6"
                  onClick={handleCancelSubscription}
                >
                  Cancel Subscription
                </Button>
              </>
            )}
          {/* If they've canceled but the subscription hasn't expired yet, they can still just turn it back on */}
          {learnerContext.learner?.subscriptionWillCancel &&
            isSubscriptionActive(learnerContext.learner) && (
              <Button
                type="link"
                className="mt-6"
                onClick={handleReactivate}
                isLoading={isReactivating}
              >
                Reactivate Subscription
              </Button>
            )}
        </>
      ) : (
        <>
          <SkeletonText>Cardholder</SkeletonText>
          <SkeletonText>Card brand</SkeletonText>
          <SkeletonText>Card expiration</SkeletonText>
          <SkeletonText>Update payment</SkeletonText>
        </>
      )}
    </ProfileField>
  );
};
