import { format } from "date-fns";
import { LearnerSubscriptionDetails } from "../../../types";
import { ProfileField } from "../ProfileField";
import { formatPrice } from "../../../utils/formatPrice";
import { SkeletonText } from "../../Skeleton";
import { getLearnerAccountStatus } from "../getLearnerAccountStatus";
import { useLearnerContext } from "../../../contexts/LearnerContext";

export const UpcomingChargesField: React.FC<{
  subscription: LearnerSubscriptionDetails | undefined;
}> = ({ subscription }) => {
  const { learner } = useLearnerContext();

  if (!subscription) {
    return (
      <ProfileField label="Upcoming charges">
        <SkeletonText>Loading billing data</SkeletonText>
      </ProfileField>
    );
  }

  const { discount } = subscription;
  const status = learner ? getLearnerAccountStatus(learner) : null;
  const isCanceled = status === "canceled";

  return (
    <ProfileField label="Upcoming charges">
      <span>{format(new Date(subscription.periodEnd), "MM/dd/yy")}</span>
      <div className="flex items-center gap-2">
        <span className="font-serif text-lg font-bold">
          {discount && !isCanceled
            ? formatPrice({
                value: subscription.amount.value * (discount.percentOff / 100),
                currency: subscription.amount.currency,
              })
            : formatPrice(subscription.amount)}
        </span>
        {discount && !isCanceled && (
          <span className="italic">Limited time offer</span>
        )}
      </div>
    </ProfileField>
  );
};
