import Button from "./generic/button";

export default function AdditionalPractice({
  handleClick,
}: {
  handleClick: () => void;
}) {
  return (
    <div className="mt-4 flex flex-col gap-2 rounded-lg border border-[#E4E6EA] bg-white px-2 py-9 sm:px-5 lg:px-9">
      <h2 className="font-lg font-sans font-semibold">
        Want to practice more?
      </h2>
      <p className="fonts-sans">
        Get extra practice on this skill for even better understanding.
      </p>
      <div className="mt-2 justify-self-stretch sm:justify-self-auto">
        <Button
          type="outline"
          onClick={handleClick}
          className="min-w-full sm:min-w-fit"
        >
          Keep Practicing
        </Button>
      </div>
    </div>
  );
}
