import { useFocusContext } from "../../contexts/FocusContext";
import { displayInlineMath } from "../../../student/utils";

type Props = {
  buttonText: string | any;
  buttonCmd?: boolean;
  buttonOutput?: string;
};

const CommonButton = (props: Props) => {
  const focusContext = useFocusContext();

  const handleCommonBtnClick = (btnLatex: string) => {
    const node = focusContext.getMQ();

    if (!node) {
      return;
    }

    // replace all parentheses and square brackets with correct latex
    btnLatex = btnLatex
      .replaceAll("(", "\\left(")
      .replaceAll(")", "\\right)")
      .replaceAll("[", "\\left[")
      .replaceAll("]", "\\right]");

    if (props.buttonCmd) {
      // use the command mq method
      node.cmd(btnLatex);
    } else {
      // account for special buttons
      if (btnLatex.includes(",")) {
        // such as [,] for interval notation
        node.write(btnLatex).keystroke("Left Left");
      } else if (btnLatex.includes("cuberoot")) {
        node
          .cmd("nthroot")
          .typedText("3")
          .keystroke("Tab")
          .keystroke("Left Right");
      } else if (btnLatex.includes("nthroot")) {
        node.cmd("nthroot").keystroke("Left Right");
      } else if (btnLatex.includes("logbase")) {
        node.write("log_{}").keystroke("Tab").keystroke("Left");
      } else if (btnLatex.includes("sqrt")) {
        node.cmd(btnLatex);
      } else {
        // all other latex
        node.write(btnLatex);
      }
    }
    node.focus();
  };

  return (
    <button
      type="button"
      name={props.buttonText}
      className="inline-flex items-center rounded-md border border-transparent bg-blue-100 px-3 py-2 text-sm font-medium leading-4 text-dm-dark-blue hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
      onClick={() => {
        const btnLaTeX =
          props.buttonOutput !== undefined
            ? props.buttonOutput
            : props.buttonText;
        handleCommonBtnClick(btnLaTeX);
      }}
    >
      {displayInlineMath(props.buttonText)}
    </button>
  );
};

export default CommonButton;
