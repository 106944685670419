import { useEffect, useState } from "react";
import { useLearnerDocumentTitle } from "../../utils/useLearnerDocumentTitle";
import { useLearnerContext } from "../../contexts/LearnerContext";
import { useLocation, useParams } from "react-router-dom";
import { useReadLocalStorage } from "usehooks-ts";
import { Learner } from "../../types";
import { useLearnerAnalytics } from "../../analytics/useLearnerAnalytics";
import { useUserContext } from "../../../shared/contexts/UserContext";
import { AppTimers } from "./AppTimers";
import {
  initialDataLoadEvent,
  parentInitialDataLoadEvent,
} from "../../analytics/events";

// This is outside of the component because we want to happen exactly once when
// the page is loaded--never again until the page is refreshed
let trackedInitialLoad = false;

export const EmptyLearnerPage: React.FC<{
  title: string | undefined;
  dontTrackTime?: boolean;
}> = ({ title, dontTrackTime, children }) => {
  useLearnerDocumentTitle(title);

  const { learner } = useLearnerContext();
  const userContext = useUserContext();
  const location = useLocation();
  const { assignmentType } = useParams();
  const user = useReadLocalStorage<Learner>("user");
  const { pageView, identify, track, getAssignmentData } =
    useLearnerAnalytics();
  const [previousPath, setPreviousPath] = useState<string>("unknown");

  // Identify the learner when we have learner data
  useEffect(() => {
    if (learner) {
      identify(learner);
    } else if (user && (user?.entitlements ?? []).length > 0) {
      identify(user);
    }
  }, [identify, learner, user]);

  // Track page views
  useEffect(() => {
    pageView({
      ...getAssignmentData(assignmentType),
      previousPath,
    });
    setPreviousPath(location.pathname);
    // Only track when the location changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  // Track initial data load
  useEffect(() => {
    if (!trackedInitialLoad && learner) {
      trackedInitialLoad = true;
      track(
        learner.entitlements.includes("parent")
          ? parentInitialDataLoadEvent()
          : initialDataLoadEvent()
      );
    }
    // Only want this to run once, not when any dependency changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [learner]);

  return (
    <>
      {/*
        Note: if you _aren't_ using the LearnerPage component to lay out a
        page, you'll need to call useAppTimers() or include the AppTimers
        component manually for that page. This is necessary because we're
        using the Router component within the App component instead of an
        App component with an Outlet.
      */}
      {!dontTrackTime && !userContext.isTeacherImpersonating && <AppTimers />}

      {children}
    </>
  );
};
