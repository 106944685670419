import { useEffect } from "react";
import ReactTooltip from "react-tooltip";
import { TotalPointsCard } from "../Dashboard/TotalPointsCard";
import { LearnerPage } from "../Layouts/LearnerPage";
import { PascalsTriangleCard } from "./PascalsTriangleCard";
import { WeeklyGoalCard } from "../Dashboard/WeeklyGoalCard";
import { NextPrizeCard } from "./NextPrizeCard";
import { useLearnerContext } from "../../contexts/LearnerContext";
import { usePrizes } from "../../utils/usePrizes";
import clsx from "clsx";
import { GameCard } from "./GameCard";
import { ContentPrize, Prize } from "../../types";

export const PointsPage: React.FC = () => {
  const { learner } = useLearnerContext();
  const prizes = usePrizes();

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const unlockedGamePrizes = prizes.filter(
    (p: Prize): p is ContentPrize =>
      p.type === "content" && !!learner?.prizes?.includes(p.key)
  );

  return (
    <>
      <LearnerPage title="Points">
        <h1 className="mb-4 mt-6 pl-6 font-serif text-2xl font-bold leading-10">
          Points
        </h1>
        <div className="flex w-full grid-cols-4 flex-col gap-4 md:grid">
          <TotalPointsCard />
          <WeeklyGoalCard />
          <div className="col-span-2">
            <NextPrizeCard />
          </div>
          <div className="col-span-4">
            <PascalsTriangleCard />
          </div>
          {unlockedGamePrizes.map((prize) => (
            <div
              key={prize.key}
              className={clsx(
                unlockedGamePrizes.length === 1 ? "col-span-4" : "col-span-2"
              )}
            >
              <GameCard
                prize={prize}
                layout={unlockedGamePrizes.length === 1 ? "wide" : "narrow"}
              />
            </div>
          ))}
        </div>
      </LearnerPage>
      <ReactTooltip
        id="pointsPageTooltip"
        className="!rounded !px-3 font-sans text-xs leading-normal before:!-z-10"
        effect="solid"
        delayShow={150}
        delayHide={50}
        multiline={true}
        place="top"
        html={true}
        getContent={(dataTip) => dataTip}
      />
    </>
  );
};
