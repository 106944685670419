import { useState } from "react";
import Button from "../../student/components/generic/button";
import BetaAppInviteModal from "./BetaAppInviteModal";
import DeltaMathTable from "../../shared/DeltaMathTable";
import { Column } from "react-table";
import {
  REACT_APP_HOMEPAGE_LINK,
  REACT_APP_LEARNER_LINK,
  useDMQuery,
} from "../../utils";
import { format } from "date-fns/esm";
import { DMToggleSingleLabel } from "../utils/functions";
import { filterEmployeeData } from "../utils";
import { useDocumentTitle } from "usehooks-ts";

const BetaAppInvite = () => {
  useDocumentTitle("Beta Invites");
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [includeEmployees, setIncludeEmployees] = useState<boolean>(false);

  const { data, refetch } = useDMQuery({
    path: "/manager_new/parent-portal/beta_invites",
  });

  const columns: Column[] = [
    {
      Header: "First Name",
      accessor: "first",
    },
    {
      Header: "Last Name",
      accessor: "last",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Course",
      accessor: "courseName",
    },
    {
      Header: "Invite Link",
      accessor: "key",
      Cell: (props: any) => {
        if (props.row.original.key) {
          return (
            <p>
              {REACT_APP_HOMEPAGE_LINK}
              {REACT_APP_LEARNER_LINK}/beta_invite?key=
              {props.row.original.key}
            </p>
          );
        }
      },
    },
    {
      Header: "Invite Created",
      accessor: "createdAt",
      Cell: (props: any) => {
        if (props.row.original.createdAt) {
          return (
            <p>
              {format(
                new Date(props.row.original.createdAt),
                "MMM d, yyyy HH:MM"
              )}
            </p>
          );
        }
      },
    },
    {
      Header: "Accepted Invite",
      accessor: "acceptedDate",
      Cell: (props: any) => {
        if (props.row.original.acceptedDate) {
          return (
            <p>
              {format(
                new Date(props.row.original.acceptedDate),
                "MMM d, yyyy HH:MM"
              )}
            </p>
          );
        }
      },
    },
  ];

  return (
    <div className="p-4">
      <div className="flex flex-col gap-4">
        <Button onClick={() => setModalOpen(true)} className="max-w-fit">
          New Invite
        </Button>
        <DMToggleSingleLabel
          checked={includeEmployees}
          onChange={(checked: boolean) => setIncludeEmployees(checked)}
          label={"Include Employee Created Invites"}
        />
        {data && (
          <DeltaMathTable
            columns={columns}
            data={filterEmployeeData(includeEmployees, data)}
          />
        )}
      </div>
      {modalOpen && (
        <BetaAppInviteModal
          visible={modalOpen}
          onClose={() => setModalOpen(false)}
          callback={refetch}
        />
      )}
    </div>
  );
};

export default BetaAppInvite;
