const TrashIcon = ({ classes }: { classes?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      className={classes}
    >
      <path
        d="M15.1112 4.55556L14.3402 15.3489C14.2737 16.2792 13.4996 17 12.567 17H5.21093C4.27824 17 3.50413 16.2792 3.43767 15.3489L2.66672 4.55556M7.11117 8.11111V13.4444M10.6667 8.11111V13.4444M11.5556 4.55556V1.88889C11.5556 1.39797 11.1577 1 10.6667 1H7.11117C6.62025 1 6.22228 1.39797 6.22228 1.88889V4.55556M1.77783 4.55556H16.0001"
        stroke="#9CA3AF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default TrashIcon;
