/* TODO: Fix overflow of long titles, esp on mobile. Fix mobile layout of assignment titles, badges, and due date */

import { Disclosure, Transition } from "@headlessui/react";
import { useContext, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import StudentSectionsContext from "../../_context/StudentSectionsContext";
import {
  SkillDataType,
  AssignmentDueDateType,
  AssignmentType,
} from "../../_constants";
import {
  skillDataDisplay,
  scrollToView,
  timedAssg,
  updateFullAssignmentData,
} from "../../utils";
import { useShowResults } from "../../utils/api";
import SeeSolutionsModal from "../SeeSolutionsModal";
import clsx from "clsx";
import AssignmentLineItem from "./AssignmentLineItem";
import IconProgress from "../IconProgress";
import UploadWork from "../UploadWork";
import { REACT_APP_STUDENT_LINK } from "../../../utils";

type Props = {
  section: any;
  future?: boolean;
};

export default function Assignments({ section, future }: Props): JSX.Element {
  const {
    openAssignment,
    setOpenAssignment,
    dmAssignmentData,
    setDmAssignmentData,
    activeSection,
    setTimedModalShowing,
    setTooltipText,
    openAccordions,
    setOpenAccordions,
  } = useContext(StudentSectionsContext);

  const assignmentType: AssignmentDueDateType | undefined =
    section?.sa?.status || "";
  const sectionType: AssignmentType | string = section?.ta?.type;
  const teacherId = section?.ta?._id;
  const isCorrectionType = sectionType == AssignmentType.correction;
  const isTestType = section?.ta?.is_test;
  const timedAssignment = timedAssg(section, true);

  const showSolutionsBtn =
    isTestType &&
    !!section?.ta?.obscureResults &&
    (!!section?.ta?.solutionsAvailable ||
      // show if a timed test runs out of time and was set to show results 'At test completion'
      // b/c solutionsAvailable will still be false until refetch or refresh of browser
      (timedAssignment?.isTimed &&
        section?.ta?.showSolutionsOnTimeOver === true &&
        timedAssignment?.isOver));

  const isStandardType = sectionType === AssignmentType.standard && !isTestType;
  const previouslyOpen = openAccordions.indexOf(teacherId) > -1;
  const defaultOpen = teacherId === openAssignment || previouslyOpen;

  const [uploadNotesAssignment] = useState({
    ...section.sa,
  });
  const [isSeeSolutionsModalShowing, setSeeSolutionsModalShowing] =
    useState(false);

  const setAccordions = (addTo: boolean) => {
    if (addTo) {
      setOpenAccordions((prevState: any) => [
        ...prevState.filter((a: any) => a !== teacherId),
        teacherId,
      ]);
    } else {
      setOpenAccordions((prevState: any) =>
        prevState.filter((a: any) => a !== teacherId)
      );
    }
  };

  useEffect(() => {
    if (teacherId == openAssignment) {
      const assignment = document.getElementById(`assg-ta-${openAssignment}`);
      if (assignment)
        assignment.scrollIntoView({
          behavior: "auto",
          block: "start",
        });

      setOpenAssignment(null);
    }
  }, []);

  const showResultsMutation = useShowResults(section?.ta?._id);

  const handleShowSolutions = () => {
    const taId = section?.ta?._id;
    if (taId === undefined) return;
    setSeeSolutionsModalShowing(true);
  };

  const handleShowSolutionsData = (taId: any, skillName?: string) => {
    const body = skillName ? JSON.stringify({ sk: skillName }) : "{}";
    showResultsMutation.mutate(body, {
      onSuccess: (data) => {
        if (data?.data?.assignment) {
          updateFullAssignmentData(
            data.data.assignment,
            taId,
            activeSection,
            dmAssignmentData,
            setDmAssignmentData
          );
        }
      },
    });
  };

  const skillOnClick = (e: React.MouseEvent<HTMLElement>) => {
    if (
      !timedAssignment?.isOver ||
      (timedAssignment?.isOver &&
        isTestType &&
        section?.ta?.obscureResults !== true)
    ) {
      scrollToView();
      return;
    }

    e.preventDefault();
    setTimedModalShowing(true);
  };

  // ---------------
  // PASSCODES

  const user = JSON.parse(localStorage.getItem("user") || "{}");

  const mapAssignmentSkills = (): JSX.Element => {
    if (!sectionType) return <></>;
    if (
      isStandardType ||
      (isCorrectionType && section?.sa?.correctionInitialized) ||
      isTestType
    ) {
      const order = section?.ta?.order;

      if (!order.length)
        return (
          <div className="px-6 py-3.5">
            There are no questions for this assignment
          </div>
        );

      return order.map((skill: string) => {
        const skillData: SkillDataType = skillDataDisplay(
          skill,
          section,
          activeSection
        );
        const weight = section?.ta?.skills[skill]?.weight;
        const score = (skillData.progress?.score || 0) * weight;
        const [isHover, setIsHover] = useState<boolean>(false);

        return (
          <NavLink
            key={`skill:${skillData.uid}`}
            to={`${skillData.url}`}
            className="group mx-2 my-1.5 flex items-center gap-2 rounded px-2 py-3 text-dm-gray-600 hover:bg-dm-brand-blue-100 hover:font-bold hover:text-dm-gray-800 sm:px-4"
            onClick={skillOnClick}
            onMouseEnter={() => {
              if (!isHover) setIsHover(true);
            }}
            onMouseLeave={() => {
              if (isHover) setIsHover(false);
            }}
          >
            <IconProgress
              skillData={skillData}
              setTooltipText={setTooltipText}
              tooltipId="piechart-app"
              extraClasses="ml-1"
              isActive={isHover}
            />
            <h3
              className={clsx(
                "flex flex-grow items-center gap-x-1",
                skillData.isCompleted && skillData.isMaxProblemType
                  ? "text-dm-gray-500"
                  : "text-dm-gray-800"
              )}
            >
              {skillData.name}
            </h3>
            {!isTestType ? (
              <>
                <span className="w-[40px] text-right">
                  {skillData.completion}
                </span>
              </>
            ) : (
              <span className="w-auto text-right">
                {!skillData.obscureResults ? `${score}/` : null}
                {weight} points
              </span>
            )}
          </NavLink>
        );
      });
    }
    return <></>;
  };

  /* ************************ */
  /* initializeTestCorrection */
  /* ************************ */
  const showButtons =
    (timedAssignment?.isTimed && !timedAssignment.endTime) ||
    (isCorrectionType && !section?.sa?.correctionInitialized) ||
    showSolutionsBtn ||
    (isTestType &&
      section?.ta?.passcode_required === "yes" &&
      (localStorage.getItem("pc_" + section?.ta?._id + "_" + user?._id) ===
        null ||
        section?.sa?.passcodeEntered !== true) &&
      !section?.sa?.solutions_seen);

  return (
    <>
      <Disclosure
        as="div"
        defaultOpen={showButtons ? false : defaultOpen}
        className={clsx(
          "mb-2 rounded-lg border border-dm-charcoal-100 bg-white",
          "hover:border-dm-charcoal-200 hover:shadow-[2px_2px_10px_0px_rgba(0,0,0,0.08)]",
          future ? "border-dm-warning-800 hover:border-dm-warning-800" : ""
        )}
        data-sa={section?.sa?._id}
        id={`assg-ta-${section?.ta?._id}`}
      >
        {({ open }) => (
          /* Use the `open` state to conditionally change the direction of an icon. */
          <>
            {" "}
            {showButtons ? (
              <AssignmentLineItem
                section={section}
                open={open}
                type={
                  isCorrectionType
                    ? "correction"
                    : isTestType
                    ? "test"
                    : "standard"
                }
                timedAssignment={timedAssignment}
                assignmentType={assignmentType}
                uploadNotesAssignment={uploadNotesAssignment}
                showButton={showButtons}
              />
            ) : (
              <Disclosure.Button className="w-full">
                <AssignmentLineItem
                  section={section}
                  open={open}
                  type={
                    isCorrectionType
                      ? "correction"
                      : isTestType
                      ? "test"
                      : "standard"
                  }
                  timedAssignment={timedAssignment}
                  assignmentType={assignmentType}
                  uploadNotesAssignment={uploadNotesAssignment}
                />
              </Disclosure.Button>
            )}
            <Transition
              enter="transition-opacity duration-75"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-150"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              beforeEnter={() => setAccordions(true)}
              beforeLeave={() => setAccordions(false)}
            >
              <Disclosure.Panel
                unmount={true}
                className="text-sm text-dm-gray-500" //overflow-auto border-dm-charcoal-100 bg-white rounded-b-lg border-2 border-t-0
              >
                {mapAssignmentSkills()}
                <div className="flex items-end justify-between px-4 sm:px-6">
                  {section?.ta?.notes === 2 ? (
                    <div className="my-3.5">
                      <h4 className="font-sans font-bold text-black">
                        Your Work
                      </h4>
                      <UploadWork section={section} />
                    </div>
                  ) : (
                    <div></div>
                  )}
                  {isCorrectionType && section?.sa?.correctionInitialized && (
                    <NavLink
                      to={`${REACT_APP_STUDENT_LINK}/link/${section?.ta?._id}?reset=true`}
                      className="text-charcoal-800 my-3.5 flex items-center gap-x-2 rounded border border-dm-charcoal-800 px-8 py-1 font-bold leading-6"
                    >
                      <i className="far fa-redo"></i>
                      Reset Correction
                    </NavLink>
                  )}
                </div>
                {showSolutionsBtn ? (
                  <>
                    <div className="flex justify-end border-t px-6 py-3.5">
                      <button
                        className={clsx(
                          "inline-flex min-w-0 flex-nowrap items-center justify-between rounded-md border border-gray-300 px-1 py-1 text-xs text-gray-600 hover:bg-gray-200"
                        )}
                        onClick={() => handleShowSolutions()}
                      >
                        {section?.ta?.show_solutions &&
                        section.ta.show_solutions === "never"
                          ? "show results"
                          : "show solutions"}
                      </button>
                    </div>
                    <SeeSolutionsModal
                      isShowing={isSeeSolutionsModalShowing}
                      setIsShowing={setSeeSolutionsModalShowing}
                      handleShowSolutionsData={handleShowSolutionsData}
                      taId={section?.ta?._id}
                    />
                  </>
                ) : null}
              </Disclosure.Panel>
            </Transition>
          </>
        )}
      </Disclosure>
      {isCorrectionType && section?.sa?.correctionInitialized ? (
        <ReactTooltip
          id={`assignments-tooltip-${teacherId}`}
          className="max-w-[16rem]"
        />
      ) : null}
    </>
  );
}
