import { useEffect, useState } from "react";
import { ContentPrize, GameKey, PrizeModalProps } from "../../../types";
import { PrizeModalHeader } from "./PrizeModalHeader";
import { useMutation } from "react-query";
import axios from "axios";
import { deltamathAPI } from "../../../../manager/utils";
import { useDeltaToastContext } from "../../../../shared/contexts/ToasterContext";
import { useLearnerContext } from "../../../contexts/LearnerContext";
import PortalModal from "../../../../shared/PortalModal";
import runConfetti from "../../../utils/confetti";
import { useLearnerAnalytics } from "../../../analytics/useLearnerAnalytics";
import {
  gameLostEvent,
  gameModalClosedEvent,
  gameWonEvent,
} from "../../../analytics/events";
import { addPointsToLearner } from "../../../utils/addPointsToLearner";

interface Props extends PrizeModalProps {
  prize: ContentPrize;
  showContent?: boolean;
}

export const ContentPrizeModal = (props: Props) => {
  const toastContext = useDeltaToastContext();
  const learnerContext = useLearnerContext();
  const [title, setTitle] = useState<string>("");
  const [showContent, setShowContent] = useState<boolean>(
    props.showContent === true
  );
  const [wasVisible, setWasVisible] = useState<boolean>(false);
  const { track } = useLearnerAnalytics();

  const prizesState = learnerContext.learner?.prizesState;
  const numWins = (prizesState && prizesState[props.prize.key]?.numWins) || 0;

  const onConfirm = () => {
    setShowContent(true);
  };

  const gameWin = useMutation(
    (body: { gameKey: GameKey; userSpeed?: number }) => {
      return axios.post(
        `${deltamathAPI()}/learner/data/game`,
        JSON.stringify(body),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    },
    {
      onSuccess: (data) => {
        const updatedLearner = addPointsToLearner(
          learnerContext.state.learner,
          1
        );
        learnerContext.updateLearner({
          ...updatedLearner,
          prizesState: {
            ...learnerContext?.state?.learner?.prizesState,
            [props.prize.key]: data.data,
          },
        });
        runConfetti(learnerContext.learner);
      },
      onError: () => {
        toastContext.addToast({
          title: "Error",
          message: "Unable to save your points",
          status: "Error",
        });
      },
    }
  );

  const winFunc = (userSpeed?: number) => {
    gameWin.mutate({
      gameKey: props.prize.key,
      ...(userSpeed ? { userSpeed } : {}),
    });
    track(gameWonEvent({ prize: props.prize.key }));
  };

  const loseFunc = (data: {
    snakeNumCorrect?: number;
    snakeLoseReason?: string;
  }) => {
    track(
      gameLostEvent({
        prize: props.prize.key,
        snakeNumCorrect: data.snakeNumCorrect || -1,
        snakeLoseReason: data.snakeLoseReason || "unknown",
      })
    );
  };

  useEffect(() => {
    if (!props.visible && wasVisible) {
      track(gameModalClosedEvent({ prize: props.prize.key }));
    } else if (props.visible && !wasVisible) {
      setWasVisible(true);
    }
  }, [props.prize.key, props.visible, track, wasVisible]);

  return (
    <PortalModal
      visible={props.visible}
      onClose={props.onClose}
      maximizeContentSmallScreen={true}
      title={
        showContent ? (
          <PrizeModalHeader>{title}</PrizeModalHeader>
        ) : (
          <PrizeModalHeader image={props.prize.image}>
            {props.prize.unlockTitle}
          </PrizeModalHeader>
        )
      }
      body={
        showContent ? (
          <props.prize.ContentComponent
            setTitle={setTitle}
            winFunc={winFunc}
            loseFunc={loseFunc}
            numWins={numWins}
            description={props.prize.description}
          />
        ) : (
          props.prize.unlockText
        )
      }
      {...(!showContent
        ? {
            secondaryButtonText: "Maybe Later",
            secondaryOnClick: props.onClose,
            confirmButtonText: props.prize.contentCta,
            onConfirm: onConfirm,
          }
        : {
            footer: <></>,
            disableClickedOff: true,
          })}
    />
  );
};
