import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { useMediaQuery } from "usehooks-ts";
import { useCourseContext } from "../../contexts/CourseContext";
import { useLearnerContext } from "../../contexts/LearnerContext";
import Sidebar from "../Sidebar/unittest";
import ScoreBar from "../ScoreBar";
import SubunitsSolving from "../Subunit/SubunitsSolving";
import { findIndex, clamp, compact } from "lodash";
import { Skill, LearnerAssignmentSkillData, Unit } from "../../types";
import TestSummary from "../TestSummary";
import { urlParam, urlParamType, getReadableAssignmentType } from "../../utils";
import MobileQuestionDropdown from "../Sidebar/MobileQuestionDropdown";
import { useTestTimer } from "../../utils/useTestTimer";
import { REACT_APP_LEARNER_LINK } from "../../../utils";
import runConfetti from "../../utils/confetti";
import { LearnerPage } from "../Layouts/LearnerPage";
import { SkeletonText } from "../Skeleton";
import { AssignmentSubmissionLoading } from "../AssignmentSubmissionLoading";
import TestLanding from "../Unit/TestLanding";

const CourseTest = () => {
  const { coursePath, indexOfSkill } = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  const retake = urlParams.get("retake");
  const navigate = useNavigate();
  const isSmallDevice = useMediaQuery("(max-width : 1023px)");

  const courseContext = useCourseContext();
  const learnerContext = useLearnerContext();
  const courseData = courseContext.getCourseData(coursePath);
  const courseProgress = learnerContext.getProgress(courseData?.id || "");
  const courseAssignment = courseProgress?.courseTest?.assignment;

  const queryString = window.location.search;

  const testTimer = useTestTimer(courseAssignment);

  const [isWaitingForAssignmentSubmit, setIsWaitingForAssignmentSubmit] =
    useState(false);

  // This needs to be here for hook rules to utilize useDmQuery and set state in the context to fetch
  // this component needs to be fully mounted before updating that state
  useEffect(() => {
    courseContext.setActiveCourse(courseData?.id);
  }, [courseData]);

  const submitCallback = (param?: urlParamType) => {
    navigate(
      `${REACT_APP_LEARNER_LINK}/${coursePath}/coursetest${urlParam(param)}`
    );
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [indexOfSkill]);

  const skillsCount = courseAssignment?.skills?.length || 0;
  const firstUnsolved = clamp(
    findIndex(
      courseAssignment?.skills,
      (skill: LearnerAssignmentSkillData) => !skill.skillComplete
    ),
    0,
    skillsCount - 1
  );

  const firstPostQuizAttempt = !courseAssignment?.resultHist;
  useEffect(() => {
    if (!firstPostQuizAttempt || !courseProgress?.courseTest) {
      return;
    } else if (
      !indexOfSkill &&
      courseAssignment &&
      !courseAssignment?.submitted
    ) {
      navigate(
        `${REACT_APP_LEARNER_LINK}/${coursePath}/coursetest/${firstUnsolved}`,
        {
          replace: true,
        }
      );
    }
  }, [indexOfSkill, courseAssignment]);

  useEffect(() => {
    if (urlParams.get("complete") === "true") {
      runConfetti(learnerContext.learner);
      const params = new URLSearchParams(urlParams.toString());
      params.delete("complete");
      navigate(
        `${REACT_APP_LEARNER_LINK}/${coursePath}/coursetest${
          params.toString() !== "" ? `?${params.toString()}` : ""
        }`,
        { replace: true }
      );
    }
  }, [urlParams]);

  useEffect(() => {
    if (isWaitingForAssignmentSubmit && indexOfSkill === undefined) {
      setIsWaitingForAssignmentSubmit(false);
    }
  }, [indexOfSkill, isWaitingForAssignmentSubmit]);

  if (
    !!courseProgress &&
    (!courseProgress?.courseTest?.ref ||
      (courseProgress.courseTest?.submitted && retake)) &&
    !learnerContext.state.isLoadingProgress &&
    courseData
  ) {
    return (
      <TestLanding
        courseId={courseData.id}
        assignmentId={courseProgress?.courseTest?.ref}
      />
    );
  }

  if (
    courseContext.state.isLoadingCourse ||
    courseContext.state.isLoadingSubunits ||
    learnerContext.state.isLoadingProgress
  ) {
    return (
      <LearnerPage title="Course Test" noPadding>
        <div className="dm-scorebar w-full border-b border-dm-charcoal-100 bg-white px-6 py-5">
          <SkeletonText>Loading Course Test</SkeletonText>
        </div>
      </LearnerPage>
    );
  }

  if (!courseData || !courseAssignment) {
    return (
      <LearnerPage title="Course Test">
        <div className="flex h-full w-full items-center justify-center text-dm-error-500">
          There was an error loading this page
        </div>
      </LearnerPage>
    );
  }

  if (courseData?.unitOrder.length === 0 || !courseData?.units) {
    return (
      <LearnerPage title="Course Test">
        <div className="p-1 text-sm text-dm-gray-500">
          There are no units for this course or the order is not set.
        </div>
      </LearnerPage>
    );
  }

  const allSkillInfo: Record<string, Skill> = courseData.units.reduce(
    (acc: any, unit: Unit) => ({
      ...acc,
      ...courseContext.getSkillData(unit.id),
    }),
    []
  );

  const skillNameList: Skill[] = compact(
    courseAssignment.skills.map((skill: LearnerAssignmentSkillData) => {
      return allSkillInfo[skill.sk];
    })
  );

  if (indexOfSkill !== undefined && skillNameList.length === 0) return <></>;
  return (
    <LearnerPage
      title={`${courseData.name} Test`}
      sidebar={<Sidebar type="courseTest" />}
      noPadding
    >
      <ScoreBar
        pageState={"courseTest"}
        assignment={courseAssignment}
        title={courseData.name}
        preQuizAssignment={undefined}
        postQuizAssignment={undefined}
        skippedPreQuiz={undefined}
        preQuiz100={false}
        callback={submitCallback}
        finishedSubunit={false}
        testTimer={testTimer}
        onSubmitAssignment={() => {
          setIsWaitingForAssignmentSubmit(true);
        }}
      />
      {isSmallDevice && indexOfSkill !== undefined && (
        <>
          <h2 className="bg-white px-4 py-2 text-sm font-bold lg:hidden">{`${
            courseData.name
          } ${getReadableAssignmentType("courseTest")}`}</h2>
          <MobileQuestionDropdown type={"courseTest"} />
        </>
      )}
      <div className="flex-grow bg-dm-background-blue-100 pb-8 text-center text-dm-charcoal-500 sm:p-6 lg:p-8">
        {indexOfSkill !== undefined && courseAssignment ? (
          isWaitingForAssignmentSubmit ? (
            <AssignmentSubmissionLoading assignmentType="courseTest" />
          ) : (
            <SubunitsSolving
              assignment={courseAssignment}
              skills={skillNameList}
              testTimer={testTimer}
            />
          )
        ) : (
          <TestSummary assignment={courseAssignment} courseId={courseData.id} />
        )}
      </div>
      <ReactTooltip id="sidebar-tooltip" effect="solid" />
    </LearnerPage>
  );
};

export default CourseTest;
