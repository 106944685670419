import clsx from "clsx";
import { NavLink } from "react-router-dom";
import { useLearnerContext } from "../../contexts/LearnerContext";
import { SkeletonText } from "../Skeleton";
import { WEEKLY_GOAL_POINTS } from "../../constants";
import { REACT_APP_LEARNER_LINK } from "../../../utils";

export const SidebarPoints: React.FC = () => {
  const { isSidebarMinimized, learner } = useLearnerContext();

  const pointsEarned = learner?.pointsEarned;

  const pointsEarnedText = isSidebarMinimized
    ? `${pointsEarned} pts`
    : `${pointsEarned} points`;

  return (
    <NavLink
      className={({ isActive }) =>
        clsx(
          isActive ? "bg-dm-brand-blue-100" : "",
          "flex justify-between rounded px-2 py-1 text-sm font-bold text-dm-gray-600 hover:bg-dm-brand-blue-100"
        )
      }
      to={`${REACT_APP_LEARNER_LINK}/points`}
      end
    >
      <div
        className={clsx(
          "flex items-center",
          isSidebarMinimized ? "flex-col gap-1" : "gap-2"
        )}
        aria-label={`${pointsEarned} total points earned.`}
      >
        <i
          className="far fa-stars h-6 w-6 text-xl leading-none text-dm-purple-800"
          aria-hidden="true"
        ></i>

        <SkeletonText loaded={pointsEarned !== undefined}>
          {pointsEarnedText}
        </SkeletonText>
      </div>

      {!isSidebarMinimized && (
        <div
          className="flex items-center gap-2"
          aria-label={`${learner?.pointsThisWeek} points earned this week; your goal is ${WEEKLY_GOAL_POINTS} per week.`}
        >
          <i
            className="far fa-calendar text-lg leading-none text-dm-gray-200"
            aria-hidden="true"
          ></i>
          <SkeletonText loaded={!!learner}>
            {learner?.pointsThisWeek}/{WEEKLY_GOAL_POINTS} points
          </SkeletonText>
        </div>
      )}
    </NavLink>
  );
};
