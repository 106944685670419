import clsx from "clsx";
import { ReactNode } from "react";
import { NavLink } from "react-router-dom";
import { useLearnerContext } from "../../contexts/LearnerContext";

export const SidebarLink: React.FC<{
  to: string;
  icon: ReactNode;
}> = ({ to, icon, children }) => {
  const { isSidebarMinimized } = useLearnerContext();
  const isActive = window.location.pathname.endsWith(to);
  return (
    <NavLink
      to={to}
      className={clsx(
        "group flex items-center gap-2 rounded-md p-2 text-sm text-dm-gray-600 hover:text-black",
        isActive
          ? "bg-dm-brand-blue-100 text-black"
          : "hover:bg-dm-brand-blue-100/50",
        isSidebarMinimized && "w-12 justify-center"
      )}
    >
      <span
        className={clsx(
          "flex h-8 w-8 items-center justify-center group-hover:text-black",
          isActive ? "text-dm-gray-600" : "text-dm-gray-200"
        )}
      >
        {icon}
      </span>
      {children}
    </NavLink>
  );
};
