import React, { Fragment, useState } from "react";

import { useMutation, QueryClient } from "react-query";
import { executeQuery } from "../../../utils";
import { Dialog, Transition } from "@headlessui/react";
import {
  IRenewalType,
  getIndividualQuoteMachineLink,
} from "../../utils/quoteUtils";
import IndividualQuoteForm from "./IndividualQuoteForm";

const RenewalLinkForm: React.FC<{
  renewalID: string;
  disabled: boolean;
  type: IRenewalType;
  nces: string;
  individualLicenses?: Array<{
    licenseTier: "Plus" | "Integral";
    licenseCount: number;
  }>;
}> = ({ renewalID, disabled = false, type, nces, individualLicenses }) => {
  const [showForm, setShowForm] = useState<boolean>(false);
  const [quoteMachineLink, setQuoteMachineLink] = useState<string>("");
  const [contact, setContact] = useState<{
    email: string;
    "first-name": string;
    "last-name": string;
  }>({ email: "", "first-name": "", "last-name": "" });

  const queryClient = new QueryClient();

  const getRenewalLink = async ({
    email,
    first,
    last,
  }: {
    email: string;
    first?: string;
    last?: string;
  }) => {
    const data = await executeQuery({
      path: `/manager_new/renewals/link`,
      method: "post",
      requestBody: {
        renewalID,
        contact: {
          email,
          first,
          last,
        },
      },
    });
    return data;
  };

  const {
    data: linkData,
    mutate,
    isError,
    isSuccess,
  } = useMutation(getRenewalLink, {
    onSuccess: () => {
      queryClient.invalidateQueries("renewals");
    },
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const email = formData.get("email") as string;
    const first = formData.get("first-name") as string;
    const last = formData.get("last-name") as string;
    mutate({
      email,
      ...(first && first !== "" ? { first } : {}),
      ...(last && last !== "" ? { last } : {}),
    });
    if (type === "individual") {
      const license =
        formData.get("license-tier") !== "PlusNoCompare"
          ? (formData.get("license-tier") as "Plus" | "Integral" | "") || ""
          : "Plus";
      const licenseCount = formData.get("license-count") as string;
      const name = `${first} ${last}`;
      setQuoteMachineLink(
        getIndividualQuoteMachineLink({
          email,
          nces,
          license,
          licenseCount,
          name,
          ...(formData.get("license-tier") === "PlusNoCompare"
            ? { noCompare: "true" }
            : {}),
        })
      );
    }
  };

  const copyLinkToClipboard = (which: "renewal" | "quoteMachine") => {
    if (which === "renewal") {
      if (!linkData?.link) {
        return;
      }
      navigator.clipboard
        .writeText(linkData.link)
        .then(() => {
          alert("Renewal Link copied to clipboard!");
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
        });
    } else if (which === "quoteMachine") {
      if (!quoteMachineLink) {
        return;
      }
      navigator.clipboard
        .writeText(quoteMachineLink)
        .then(() => {
          alert("Quote Machine Link copied to clipboard!");
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
        });
    }
  };

  const buttonTitle = {
    title:
      "This NCES has been flagged and a renewal link should not be generated.",
  };

  const handleContactChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setContact({ ...contact, [e.target.name]: e.target.value });
  };

  return (
    <Fragment>
      <button
        disabled={disabled}
        onClick={() => setShowForm(true)}
        className={
          !disabled
            ? "text-indigo-600 hover:text-indigo-900"
            : "cursor-not-allowed text-gray-400"
        }
        {...(disabled ? buttonTitle : {})}
      >
        Get Renewal Link
      </button>
      <Transition appear show={showForm} as={Fragment}>
        <Dialog
          id={`renewal-link-form-${renewalID}`}
          open={showForm}
          onClose={() => setShowForm(false)}
          as="div"
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/30" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-[1000px] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title className="text-xl font-bold">
                    Get Renewal Link
                  </Dialog.Title>
                  <div className="w-100 mt-4">
                    <form id={"link-form"} onSubmit={handleSubmit}>
                      <div className="grid grid-cols-1 gap-4">
                        <div>
                          <label
                            htmlFor="email"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Email
                          </label>
                          <input
                            type="email"
                            id="email"
                            name="email"
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            required
                            onChange={handleContactChange}
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="first-name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            First Name
                          </label>
                          <input
                            type="text"
                            id="first-name"
                            name="first-name"
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            onChange={handleContactChange}
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="last-name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Last Name
                          </label>
                          <input
                            type="text"
                            id="last-name"
                            name="last-name"
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            onChange={handleContactChange}
                          />
                        </div>
                        {type === "individual" && (
                          <Fragment>
                            <div>
                              <label
                                htmlFor="license-tier"
                                className="block text-sm font-medium text-gray-700"
                              >
                                License Tier (for Quote Machine link)
                              </label>
                              <select
                                id="license-tier"
                                name="license-tier"
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              >
                                <option value={"PlusNoCompare"}>
                                  Plus (Without Comparison)
                                </option>
                                <option value="Plus">Plus (Compare)</option>
                                <option value="Integral">Integral</option>
                              </select>
                            </div>
                            <div>
                              <label
                                htmlFor="license-count"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Previous License Count (optional for Quote
                                Machine - shows customer how many licenses they
                                had)
                              </label>
                              <input
                                type="number"
                                className="mt-1 block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                id="license-count"
                                name="license-count"
                              ></input>
                            </div>
                          </Fragment>
                        )}
                      </div>
                      <div className="mt-4">
                        <button
                          type="submit"
                          className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                          Options
                        </button>
                      </div>
                    </form>
                    {isSuccess && linkData?.link && (
                      <div className="mt-2 break-words text-xl">
                        <p className="mb-4 font-bold">
                          Renewal Link (site licenses):
                        </p>
                        <p className="mb-4">{linkData.link}</p>
                      </div>
                    )}
                    {quoteMachineLink !== "" && (
                      <div className="mt-2 break-words text-xl">
                        <p className="mb-4 font-bold">
                          Quote Machine Link (individual licenses):
                        </p>
                        <p className="mb-4">{quoteMachineLink}</p>
                      </div>
                    )}

                    <div className="mt-2">
                      {isSuccess && linkData?.link && (
                        <button
                          onClick={() => copyLinkToClipboard("renewal")}
                          className="mr-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                          <div className="flex flex-col space-y-1">
                            <div>Copy Renewal Link</div>
                            <div>(Site Licenses)</div>
                          </div>
                        </button>
                      )}
                      {quoteMachineLink !== "" && (
                        <button
                          onClick={() => copyLinkToClipboard("quoteMachine")}
                          className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                          <div className="flex flex-col space-y-1">
                            <div>Copy Quote Machine Link</div>
                            <div>(Individual Licenses)</div>
                          </div>
                        </button>
                      )}
                    </div>
                    {isError ||
                      (linkData?.success === false && (
                        <div>{linkData.message || "Something Went Wrong"}</div>
                      ))}
                    {type === "individual" && (
                      <IndividualQuoteForm
                        nces={nces}
                        individualLicenses={individualLicenses}
                        contactEmail={contact.email}
                        contactFirst={contact["first-name"]}
                        contactLast={contact["last-name"]}
                      />
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </Fragment>
  );
};

export default RenewalLinkForm;
