import clsx from "clsx";
import { ContentPrize } from "../../../types";
import { useIsGameUnlocked } from "../../../utils/useIsGameUnlocked";
import { LOCKED_PRIZE_TOOLTIP } from "../../../constants";

type Props = {
  setShowGame: React.Dispatch<React.SetStateAction<boolean>>;
  isPrizeUnlocked: boolean;
  prize: ContentPrize;
  title?: string;
  className?: string;
};

export const ContentPrizeButton: React.FC<Props> = (props) => {
  const isGameUnlocked = useIsGameUnlocked(props.prize.key);

  const tooltipText = !props.isPrizeUnlocked
    ? LOCKED_PRIZE_TOOLTIP
    : "<strong>Victory!</strong> 🏆<br />Come back tomorrow to win again.";

  const unlocked = props.isPrizeUnlocked && isGameUnlocked;

  const contentClick = () => {
    if (unlocked) {
      props.setShowGame(true);
    }
  };

  return (
    <button
      onClick={contentClick}
      className={clsx(
        "shrink-0 rounded bg-dm-brand-blue-500 px-8 py-1 text-sm leading-6 text-white disabled:cursor-not-allowed disabled:opacity-50 max-sm:w-full",
        unlocked
          ? "hover:bg-dm-brand-blue-600 active:bg-dm-brand-blue-800"
          : "cursor-not-allowed opacity-50",
        props.className
      )}
      {...(!unlocked
        ? {
            "data-tip": tooltipText,
            "data-for": "pointsPageTooltip",
            "aria-disabled": true,
          }
        : {})}
    >
      {props.title ? props.title : props.prize.contentCta}
    </button>
  );
};
