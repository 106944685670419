import { HTMLAttributes } from "react";

interface ResponsiveImageProps
  extends Exclude<HTMLAttributes<HTMLImageElement>, "src"> {
  alt: string;
  /**
   * A list of images in order of resolution
   * @example srcs={[ 'image.png', 'image@2x.png', 'image@3x.png' ]}
   */
  srcs: string[];
}

/**
 * Image component that accepts a list of images at different resolutions and
 * renders them as `src` and `srcset` attributes
 */
export const ResponsiveImage: React.FC<ResponsiveImageProps> = ({
  srcs,
  alt,
  ...props
}) => {
  if (srcs.length === 0) {
    throw new Error("ResponsiveImage requires at least one src");
  }
  return (
    <img
      {...props}
      src={srcs[0]}
      srcSet={srcs.map((src, index) => `${src} ${index + 1}x`).join(", ")}
      alt={alt}
    />
  );
};
