import { myCoursesUnitDataType } from "../types";

/** Generate an aria label for a course */
export const generateCourseProgressAriaLabel = (
  unitProgress?: myCoursesUnitDataType[]
) => {
  const summary = {
    complete: 0,
    inProgress: 0,
    notStarted: 0,
  };
  unitProgress?.forEach((unit) => {
    if (unit.progress === 1) summary.complete += 1;
    else if (unit.progress === 0 || unit.progress === undefined)
      summary.notStarted += 1;
    else summary.inProgress += 1;
  });

  return `Course has ${summary.notStarted} unit${
    summary.notStarted === 1 ? "" : "s"
  } not started, ${summary.inProgress} unit${
    summary.inProgress === 1 ? "" : "s"
  } in progress, and ${summary.complete} unit${
    summary.complete === 1 ? "" : "s"
  } complete, including the course test.`;
};
