export const QuestionMarkCircleIcon2: React.FC<{ showAria?: boolean }> = ({
  showAria = false,
}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    aria-label="question mark icon"
    aria-hidden={!showAria}
  >
    <path
      d="M8.64681 9.33333C9.13492 8.29761 10.4519 7.55556 12.0001 7.55556C13.9637 7.55556 15.5556 8.74947 15.5556 10.2222C15.5556 11.4661 14.4199 12.5112 12.8838 12.8059C12.4017 12.8983 12.0001 13.2868 12.0001 13.7778M12 16.4444H12.0089M20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
