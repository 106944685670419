import { uniqueId } from "lodash";
import { ChangeEventHandler, PropsWithChildren } from "react";

export const Checkbox: React.FC<
  PropsWithChildren<{
    checked: boolean;
    error: string | undefined;
    onChange: ChangeEventHandler;
  }>
> = ({ checked, error, onChange, children }) => {
  const id = uniqueId("checkbox-");
  return (
    <div className="flex flex-col gap-1">
      <label
        className="flex items-center gap-2 text-dm-charcoal-600"
        htmlFor={id}
      >
        <input id={id} type="checkbox" checked={checked} onChange={onChange} />
        <span>{children}</span>
      </label>
      {error && (
        <p className="text-xs text-dm-error-500" role="alert">
          {error}
        </p>
      )}
    </div>
  );
};
