import React, { ReactElement, useEffect, useState, useContext } from "react";

interface ScriptsLoadedState {
  dmGraphIsLoaded?: boolean | undefined;
  dmKASIsLoaded?: boolean | undefined;
  autoGrowIsLoaded?: boolean | undefined;
}

export class DMScriptHolder {
  readonly state: ScriptsLoadedState;
  private setState: (state: ScriptsLoadedState) => void;
  loadFunc: () => void;

  constructor(
    state: ScriptsLoadedState,
    setState: (state: ScriptsLoadedState) => void,
    loadFunc: () => void
  ) {
    this.state = state;
    this.setState = setState;
    this.loadFunc = loadFunc;
  }
}

const DMScriptsContext = React.createContext<DMScriptHolder>(null!);

export default DMScriptsContext;

export function DMScriptsContextProvider({
  baseURL,
  children,
}: {
  baseURL: string;
  children: ReactElement;
}) {
  const [state, setState] = useState<ScriptsLoadedState>({
    dmGraphIsLoaded: false,
    dmKASIsLoaded: false,
    autoGrowIsLoaded: false,
  });

  const loadScripts = (): void => {
    const newState = { ...state };
    scriptsToLoad.forEach((scriptObj) => {
      const stateKey = scriptObj.state as keyof ScriptsLoadedState;
      const existing: HTMLElement | null = document.querySelector(
        `[src*="${scriptObj.url}"]`
      );

      if (existing === null) {
        try {
          const script = document.createElement("script");
          script.src = baseURL + scriptObj.url;
          document.head.appendChild(script);
          newState[stateKey] = true;
        } catch (e) {
          newState[stateKey] = false;
          console.log(e);
        }
      } else {
        newState[stateKey] = true;
      }
    });
    setState(newState);
  };

  const dmGraphURL = `/global/dmGraph.js`;
  const dmKASURL = `/global/dmKAS.js`;
  const autoGrowURL = `/assets/jquery.auto-grow-input.min.js`;

  const scriptsToLoad = [
    { url: dmGraphURL, state: "dmGraphIsLoaded" },
    { url: dmKASURL, state: "dmKASIsLoaded" },
    { url: autoGrowURL, state: "autoGrowIsLoaded" },
  ];
  //For now, We are leaving the scripts loaded in <head> in index.html. So this use effect should only call to load scripts after the page is loaded and can check for scripts. Each time load scripts runs, it should only load the scripts that are not already loaded.
  useEffect(() => {
    if (document.readyState === "complete") {
      loadScripts();
    } else {
      window.addEventListener("load", loadScripts);
      return () => {
        window.removeEventListener("load", loadScripts);
      };
    }
  }, []);

  return (
    <DMScriptsContext.Provider
      value={new DMScriptHolder(state, setState, loadScripts)}
    >
      {children}
    </DMScriptsContext.Provider>
  );
}

export function useScriptContext(): DMScriptHolder {
  return useContext(DMScriptsContext);
}
