import { processChoices } from "./snakeGameProblemGenerator";
import { colors } from "./gameFunctions";

const { rand, frac, shuffle, $, DeltaGraph } = window;

/** Which fraction is closest to __ or between ___ and ___
 * @return problem object with the following properties: questionPrompt (string with html), question?  (string), choices (array of 4 strings representing answer choices with the correct answer always at index 0), solution function to run
 */
export function benchmarkFractions() {
  const types = ["closest", "between"];
  const type = types[rand(0, types.length - 1)];

  switch (type) {
    case "closest":
      return closestTo();
    case "between":
      return between();
    default:
      break;
  }
}

/** Which fraction is closest to __
 * @return problem object with the following properties: question (string), choices (array of 4 strings representing answer choices with the correct answer always at index 0), solution function to run
 */
function closestTo() {
  const types = ["0", frac(1, 2), "1"];
  const type = types[rand(0, types.length - 1)];

  let choices, correctAnswer;
  const sameDen = rand(0, 1) === 0;

  switch (type) {
    case "0": {
      if (sameDen) {
        // common denominator
        const den = rand(6, 12);
        // generate 4 unique numerators, sorted from greatest to least
        let numerators = [];
        for (let i = 1; i < den; i++) numerators.push(i);
        shuffle(numerators);
        numerators = numerators.slice(0, 4).sort((a, b) => b - a);
        // assign all answers to choices array
        const correctNum = numerators.pop(); // remove correct answer
        choices = numerators.map((num) => `${num}/${den}`);
        correctAnswer = `${correctNum}/${den}`;
      } else {
        // common numerator
        const num = rand(1, 6);
        // generate for unique denominators, sorted from least to greatest
        let denominators = [];
        for (let i = num + 1; i < 13; i++) denominators.push(i);
        shuffle(denominators);
        denominators = denominators.slice(0, 4).sort((a, b) => a - b);
        // assign all answers to choices array
        const correctDen = denominators.pop(); // remove correct answer
        choices = denominators.map((den) => `${num}/${den}`);
        correctAnswer = `${num}/${correctDen}`;
      }
      break;
    }
    case frac(1, 2): {
      if (sameDen) {
        // common denominator
        const den = rand(7, 15);
        let correctNum;
        const belowHalf = rand(0, 1) === 0;
        if (den % 2 === 0) {
          correctNum = belowHalf ? den / 2 - 1 : den / 2 + 1;
        } else {
          correctNum = belowHalf ? Math.floor(den / 2) : Math.ceil(den / 2);
        }
        let numerators = [];
        if (belowHalf) {
          for (let i = 1; i < correctNum; i++) numerators.push(i);
          for (let i = correctNum + 3; i < den; i++) numerators.push(i);
        } else {
          for (let i = 1; i <= correctNum - 3; i++) numerators.push(i);
          for (let i = correctNum + 1; i < den; i++) numerators.push(i);
        }
        shuffle(numerators);
        numerators = numerators.slice(0, 4);
        choices = numerators.map((num) => `${num}/${den}`);
        correctAnswer = `${correctNum}/${den}`;
      } else {
        // common numerator
        const num = rand(2, 7);
        const belowHalf = rand(0, 1) === 0;
        const correctDen = belowHalf ? num * 2 + 1 : num * 2 - 1;
        let denominators = [];
        if (belowHalf) {
          for (let i = num + 1; i < correctDen - 3; i++) denominators.push(i);
          for (let i = correctDen + 1; i < correctDen + 4; i++)
            denominators.push(i);
        } else {
          for (let i = num + 1; i < correctDen; i++) denominators.push(i);
          for (let i = correctDen + 4; i < correctDen + 6; i++)
            denominators.push(i);
        }
        shuffle(denominators);
        denominators = denominators.slice(0, 4);
        choices = denominators.map((den) => `${num}/${den}`);
        correctAnswer = `${num}/${correctDen}`;
      }
      break;
    }
    case "1": {
      // common denominator
      const den = rand(4, 15);
      const belowOne = rand(0, 1) === 0;
      const correctNum = belowOne ? den - 1 : den + 1;
      let numerators = [];
      if (belowOne) {
        for (let i = 1; i < correctNum; i++) numerators.push(i);
        for (let i = correctNum + 3; i < correctNum + 6; i++)
          numerators.push(i);
      } else {
        for (let i = 1; i <= correctNum - 3; i++) numerators.push(i);
        for (let i = correctNum + 1; i < correctNum + 4; i++)
          numerators.push(i);
      }
      shuffle(numerators);
      numerators = numerators.slice(0, 4);
      choices = numerators.map((num) => `${num}/${den}`);
      correctAnswer = `${correctNum}/${den}`;
      break;
    }
    default:
      break;
  }

  choices = processChoices(choices, correctAnswer);
  if (choices.length !== 4) return closestTo();

  const solution = (id) => {
    $(id).html(`<svg id="numberLine"></svg>`);

    const valuesToPlot = choices.map((choice) => {
      const divLoc = choice.indexOf("/");
      const numerator = Number(choice.slice(0, divLoc));
      const denominator = Number(choice.slice(divLoc + 1));
      return {
        value: numerator / denominator,
        numerator,
        denominator,
      };
    });

    const graph = new DeltaGraph("numberLine", {
      xmin: -0.1,
      xmax: type === "1" ? 2.1 : 1.1,
      ymin: -1.5,
      ymax: 2.5,
      xcl: 1,
      yscl: 1,
      grid: false,
      dashes: true,
      ticksX: type === frac(1, 2) ? 0.5 : 1,
      tickSizeX: "1.3em",
      tickPaddingX: 8,
      fractionX: true,
      yAxis: false,
      xAxisLabelText: "",
      wideContainer: true,
      width: 500,
      height: 50,
      margins: { top: -25, bottom: 20, left: 30, right: 30 },
    });

    valuesToPlot.forEach((number, index) => {
      const color =
        index === 0 ? colors["success-500"] : colors["dm-brand-blue-500"];
      graph.circle(number.value, 0, "5px", { strokeWidth: 1, fill: color });
      if (index === 0) {
        graph.text(
          number.value,
          0,
          `${number.numerator}/${number.denominator}`,
          {
            align: "mt",
            fill: color,
            fontSize: "1.3em",
            padding: 8,
            textRectangle: true,
          }
        );
      }
    });
  };

  return {
    questionPrompt: `Which of these fractions is closest to \\(${type}\\)?`,
    choices,
    solution,
  };
}

/** Which fraction is between ___ and ___
 * @return problem object with the following properties: question (string), choices (array of 4 strings representing answer choices with the correct answer always at index 0), solution function to run
 */
function between() {
  const types = ["0-1/2", "1/2-1"];
  const type = types[rand(0, types.length - 1)];

  let choices, correctAnswer, max, min;
  const sameDen = rand(0, 1) === 0;

  switch (type) {
    case "0-1/2": {
      if (sameDen) {
        const den = rand(7, 14);
        const isDenEven = den % 2 === 0;
        const maxNumCorrect = isDenEven ? den / 2 - 1 : Math.floor(den / 2);
        const correctNum = rand(1, maxNumCorrect);
        let numerators = [];
        for (
          let i = isDenEven ? maxNumCorrect + 2 : maxNumCorrect + 1;
          i < den;
          i++
        )
          numerators.push(i);
        shuffle(numerators);
        numerators = numerators.slice(0, 4);
        choices = numerators.map((num) => `${num}/${den}`);
        correctAnswer = `${correctNum}/${den}`;
      } else {
        const num = rand(4, 9);
        const minDenCorrect = num * 2 + 1;
        const correctDen = rand(minDenCorrect, minDenCorrect + 4);
        let denominators = [];
        for (let i = num + 1; i < minDenCorrect - 1; i++) denominators.push(i);
        shuffle(denominators);
        denominators = denominators.slice(0, 4);
        choices = denominators.map((den) => `${num}/${den}`);
        correctAnswer = `${num}/${correctDen}`;
      }
      min = "0";
      max = frac(1, 2);
      break;
    }
    case "1/2-1": {
      if (sameDen) {
        const den = rand(7, 14);
        const isDenEven = den % 2 === 0;
        const minNumCorrect = isDenEven ? den / 2 + 1 : Math.ceil(den / 2);
        const correctNum = rand(minNumCorrect, den - 1);
        let numerators = [];
        const max = isDenEven ? minNumCorrect - 1 : minNumCorrect;
        for (let i = 1; i < max; i++) numerators.push(i);
        for (let i = den + 1; i < den + 5; i++) numerators.push(i);
        shuffle(numerators);
        numerators = numerators.slice(0, 4);
        choices = numerators.map((num) => `${num}/${den}`);
        correctAnswer = `${correctNum}/${den}`;
      } else {
        const num = rand(4, 9);
        const maxDenCorrect = num * 2 - 1;
        const correctDen = rand(num + 1, maxDenCorrect);
        let denominators = [];
        for (let i = maxDenCorrect + 2; i < maxDenCorrect + 5; i++)
          denominators.push(i);
        for (let i = Math.floor(num / 2) + 1; i < num; i++)
          denominators.push(i);
        shuffle(denominators);
        denominators = denominators.slice(0, 4);
        choices = denominators.map((den) => `${num}/${den}`);
        correctAnswer = `${num}/${correctDen}`;
      }
      min = frac(1, 2);
      max = "1";
      break;
    }
    default:
      break;
  }

  choices = processChoices(choices, correctAnswer);
  if (choices.length !== 4) return between();

  const solution = (id) => {
    $(id).html(`<svg id="numberLine"></svg>`);

    const valuesToPlot = choices.map((choice) => {
      const divLoc = choice.indexOf("/");
      const numerator = Number(choice.slice(0, divLoc));
      const denominator = Number(choice.slice(divLoc + 1));
      return {
        value: numerator / denominator,
        numerator,
        denominator,
      };
    });

    const graph = new DeltaGraph("numberLine", {
      xmin: -0.1,
      xmax: type === "0-1/2" ? 1.1 : 2.1,
      ymin: -1.5,
      ymax: 2.5,
      xcl: 1,
      yscl: 1,
      grid: false,
      dashes: true,
      ticksX: 0.5,
      tickSizeX: "1.3em",
      tickPaddingX: 8,
      fractionX: true,
      yAxis: false,
      xAxisLabelText: "",
      wideContainer: true,
      width: 500,
      height: 50,
      margins: { top: -25, bottom: 20, left: 30, right: 30 },
    });

    valuesToPlot.forEach((number, index) => {
      const color =
        index === 0 ? colors["success-500"] : colors["dm-brand-blue-500"];
      graph.circle(number.value, 0, "5px", { strokeWidth: 1, fill: color });
      if (index === 0) {
        graph.text(
          number.value,
          0,
          `${number.numerator}/${number.denominator}`,
          {
            align: "mt",
            fill: color,
            fontSize: "1.3em",
            padding: 8,
            textRectangle: true,
          }
        );
      }
    });
  };

  return {
    questionPrompt: `Which of these fractions is between \\(${min}\\) and \\(${max}\\)?`,
    choices,
    solution,
  };
}
