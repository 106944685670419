import { ProfileSection } from "./ProfileSection";
import { useParentContext } from "../../contexts/ParentContext";
import { Learner } from "../../types";
import { ProfileField } from "./ProfileField";
import { ProfilePage } from "./ProfilePage";
import { ProfileFieldGroup } from "./ProfileFieldGroup";
import { ProfileColumn } from "./ProfileColumn";
import { PaymentInformationField } from "./Fields/PaymentInformationField";
import { BillingHistoryField } from "./Fields/BillingHistoryField";
import { BillingFrequencyField } from "./Fields/BillingFrequencyField";
import { AccountsTable } from "./AccountsTable";
import { UpcomingChargesField } from "./Fields/UpcomingChargesField";
import { SubscriptionDetailsField } from "./Fields/SubscriptionDetailsField";
import { CancelSubscriptionModal } from "./Cancellation/CancelSubscriptionModal";
import { CancellationContextProvider } from "./Cancellation/CancellationContext";
import Modal from "../../../student/components/generic/Modal";
import { useEffect, useState } from "react";
import { Payment } from "../Home/Payment/Payment";
import { isSubscriptionActive } from "../../utils/isSubscriptionActive";
import { useLearnerContext } from "../../contexts/LearnerContext";
import { HomepageSignupContextProvider } from "../Home/HomepageSignupContext";
import { useSubscription } from "../../utils/useSubscription";
import { canModifySubscription } from "../../utils/canModifySubscription";
import { OpenSeatsWarningBanner } from "./OpenSeatsWarningBanner";

export const ParentProfile: React.FC = () => {
  const { learners } = useParentContext();
  const learnerContext = useLearnerContext();
  const jsonUser = localStorage.getItem("user");
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const { subscription, refetchSubscription } = useSubscription();

  useEffect(() => {
    if (
      learnerContext.learner &&
      !isSubscriptionActive(learnerContext.learner)
    ) {
      setShowPaymentModal(true);
    }
  }, [learnerContext.learner]);

  // TODO: error handling for when/if user is null / no access to local storage?
  if (!jsonUser) {
    return null;
  }

  const user: Learner = JSON.parse(jsonUser);

  return (
    <CancellationContextProvider
      refetchSubscriptionData={refetchSubscription}
      subscription={subscription}
    >
      <div className="flex w-full flex-col">
        <ProfilePage isParentPage>
          {subscription && canModifySubscription(subscription) && (
            <OpenSeatsWarningBanner
              subscription={subscription}
              learners={learners}
            />
          )}
          <ProfileSection title="Account & Subscription Information">
            <ProfileColumn>
              <ProfileFieldGroup title="Your Account">
                <ProfileField label="Account Holder Name">
                  {user.first} {user.last}
                </ProfileField>
                <ProfileField label="Account Holder Email">
                  {user.email}
                </ProfileField>
                <ProfileField label="Password">{"*".repeat(16)}</ProfileField>
              </ProfileFieldGroup>

              <ProfileFieldGroup title="Your Subscription">
                <SubscriptionDetailsField
                  subscription={subscription}
                  setShowPaymentModal={() => setShowPaymentModal(true)}
                />
              </ProfileFieldGroup>
            </ProfileColumn>

            <ProfileColumn>
              <ProfileFieldGroup title="Billing">
                <UpcomingChargesField subscription={subscription} />

                <BillingFrequencyField subscription={subscription} />

                {subscription?.status !== "trialing" && (
                  <BillingHistoryField
                    periodStart={subscription?.periodStart}
                  />
                )}

                <PaymentInformationField subscription={subscription} />
              </ProfileFieldGroup>
            </ProfileColumn>
          </ProfileSection>

          <ProfileSection title="Account Management">
            <AccountsTable subscription={subscription} />
          </ProfileSection>

          {/* <TempSubscriptionDebug /> */}
        </ProfilePage>
      </div>

      <Modal
        visible={showPaymentModal}
        onClose={() => setShowPaymentModal(false)}
        closeX
        bodyClassName="!p-0"
        widthClassName="max-w-full w-[1041px] transition-none"
        body={
          <HomepageSignupContextProvider plansRef={null}>
            <Payment
              title="Select a Plan to Renew Access"
              buttonText="Reactivate My Subscription"
              isRenewal
            />
          </HomepageSignupContextProvider>
        }
      />

      <CancelSubscriptionModal />
    </CancellationContextProvider>
  );
};
