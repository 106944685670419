import { useContext, useRef } from "react";
import UploadWork from "./UploadWork";
import BackToHome from "./BackToHome";
import Button from "./generic/button";
import StudentSectionsContext from "../_context/StudentSectionsContext";

type AssignmentCallToActionProps = {
  showSolution: boolean;
  isSkillComplete: boolean;
  isAssignmentComplete: boolean;
  isTimedModule: boolean;
  percentCompleted: number;
  isVideo: boolean;
  hasNextSection: boolean;
  hasWorkToUpload: boolean;
  hasEndEarly: boolean;
  hasEndEarlyUnlimited: boolean;
  isUnlimited?: boolean;
  handleNextSection: () => void;
  handleNextProblem: () => void;
  handleEndEarly: (isTest?: boolean, isUnlimited?: boolean) => void;
};

type ButtonInfo = {
  text?: string;
  action?: () => void;
};

export default function AssignmentCallToAction({
  showSolution,
  isSkillComplete,
  isAssignmentComplete,
  isTimedModule,
  percentCompleted,
  isVideo,
  hasNextSection,
  hasWorkToUpload,
  hasEndEarly,
  hasEndEarlyUnlimited,
  isUnlimited = false,
  handleNextProblem,
  handleNextSection,
  handleEndEarly,
}: AssignmentCallToActionProps): JSX.Element {
  const buttonInfo: ButtonInfo = {};
  const buttonInfo2: ButtonInfo = {};
  const { globalFocusedInput } = useContext(StudentSectionsContext);

  const buttonRef = useRef<HTMLElement>(null);

  let showUploadWork = false;
  let showBackToHome = false;

  const changeFocus = () =>
    setTimeout(() => {
      if (buttonRef?.current && globalFocusedInput !== "math-input-0") {
        // scrollToView(buttonRef, "auto");
        buttonRef?.current?.focus();
      }
    }, 50);

  const endTimeText = isUnlimited
    ? "End Assignment Now"
    : "End Assignment Early";

  if (hasEndEarlyUnlimited) {
    buttonInfo2.text = endTimeText;
    buttonInfo2.action = () => handleEndEarly(false, isUnlimited);
  }

  // showSolution is exclusively for standard skills (where there is a solution to show)
  if (showSolution || isTimedModule || isVideo) {
    if (!isSkillComplete) {
      if (!isTimedModule && !isVideo) {
        buttonInfo.text = "Next Problem";
        buttonInfo.action = handleNextProblem;
        changeFocus();
      } else if (isTimedModule && hasNextSection && percentCompleted > 0) {
        buttonInfo.text = "Next Section";
        buttonInfo.action = handleNextSection;
        changeFocus();
      }
    } else {
      if (hasNextSection) {
        buttonInfo.text = "Next Section";
        buttonInfo.action = handleNextSection;
        changeFocus();
      } else if (hasWorkToUpload) {
        showUploadWork = true;
        if (hasEndEarly) {
          buttonInfo2.text = endTimeText;
          buttonInfo2.action = () => handleEndEarly(false, isUnlimited);
        }
      } else if (isAssignmentComplete) {
        if (hasEndEarly) {
          buttonInfo.text = endTimeText;
          buttonInfo.action = () => handleEndEarly(false, isUnlimited);
        } else {
          showBackToHome = true;
        }
      }
    }
  } else if (hasEndEarly) {
    buttonInfo.text = endTimeText;
    buttonInfo.action = () => handleEndEarly(false, isUnlimited);
  }

  return (
    <>
      {buttonInfo2.text !== undefined && buttonInfo2.action !== undefined && (
        <Button
          onClick={buttonInfo2.action}
          type="primary"
          className="max-sm:w-full"
        >
          {buttonInfo2.text}
        </Button>
      )}
      {showUploadWork && <UploadWork buttonType="primary" />}
      {showBackToHome && <BackToHome type="primary" />}
      {buttonInfo.text !== undefined && buttonInfo.action !== undefined && (
        <Button
          onClick={buttonInfo.action}
          type="primary"
          ref={buttonRef}
          className="max-sm:w-full"
        >
          {buttonInfo.text}
        </Button>
      )}
    </>
  );
}
