import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { findIndex, clamp } from "lodash";
import {
  Skill,
  SubunitProgress,
  LearnerAssignmentSkillData,
} from "../../../types";
import PreQuizLanding from "./PreQuizLanding";
import SubunitsSolving from "../SubunitsSolving";
import SkipPreQuiz from "./SkipPreQuiz";
import { ComponentTimer } from "../../../utils/useComponentTimer";
import { PreQuizSummary } from "./PreQuizSummary";
import { REACT_APP_LEARNER_LINK, getFilePath } from "../../../../utils";
import { ResponsiveImage } from "../../ResponsiveImage";
import { useLearnerAnalytics } from "../../../analytics/useLearnerAnalytics";
import { skipPreQuizEvent } from "../../../analytics/events";

type Props = {
  skills: Skill[];
  courseId: string;
  coursePath?: string;
  unitId: string;
  unitPath?: string;
  subunitId: string;
  subunitPath?: string;
  subunitProgress: SubunitProgress | undefined;
  subunitCopy: string;
  testTimer?: ComponentTimer;
  estimatedTime: number;
};

const PreQuiz = (props: Props) => {
  // TODO: More robust state initalization
  const { indexOfSkill } = useParams();
  const navigate = useNavigate();
  const { track, getAssignmentData } = useLearnerAnalytics();

  const skillGroup = props.subunitProgress?.preQuiz?.assignment?.skills || [];
  const skillsCount = props.skills?.length || 0;

  const submitCallback = () => {
    track(
      skipPreQuizEvent({
        ...getAssignmentData("preQuiz"),
        numCompleted:
          props.subunitProgress?.preQuiz?.assignment?.skills.filter(
            (s) => s.skillComplete
          ).length || 0,
      })
    );
    navigate(
      `${REACT_APP_LEARNER_LINK}/${props.coursePath}/${props.unitPath}/${props.subunitPath}/prequiz`
    );
  };

  const getPageState = () => {
    if (
      !props.subunitProgress?.preQuiz &&
      props.subunitProgress?.skippedPreQuiz
    ) {
      return "skipped";
    } else if (!props.subunitProgress?.preQuiz) {
      return "notInitialized";
    } else if (props.subunitProgress?.preQuiz?.assignment?.submitted) {
      return "finished";
    } else {
      return "solving";
    }
  };

  const pageState = getPageState();

  const firstUnsolved = clamp(
    findIndex(
      skillGroup,
      (skill: LearnerAssignmentSkillData) => !skill.skillComplete
    ),
    0,
    skillsCount - 1
  );

  const baseUrl = `${REACT_APP_LEARNER_LINK}/${props.coursePath}/${props.unitPath}/${props.subunitPath}`;

  // This will handle to make sure that we don't see the default landing page if we already initialize
  // It will also make sure users are not navigating to questions without initializing the pre-quiz first
  useEffect(() => {
    if (pageState === "solving" && !indexOfSkill) {
      navigate(`${baseUrl}/prequiz/${firstUnsolved}`, { replace: true });
    } else if (pageState === "notInitialized" && indexOfSkill) {
      navigate(`${baseUrl}/prequiz`, { replace: true });
    }
  }, [pageState, indexOfSkill, navigate, baseUrl, firstUnsolved]);

  const allSkillsComplete =
    props.subunitProgress?.preQuiz?.assignment &&
    skillGroup.every((s) => s.skillComplete === true);

  return (
    <>
      {pageState === "notInitialized" && (
        <>
          <PreQuizLanding {...props} skillsCount={skillsCount} />
        </>
      )}
      {pageState === "skipped" && (
        <div className="rounded-lg border border-dm-charcoal-100 bg-white px-4 py-6 max-sm:m-4">
          <ResponsiveImage
            className="mx-auto h-auto w-56"
            srcs={[
              getFilePath("/images/learner/prequiz/prequiz-skipped.png"),
              getFilePath("/images/learner/prequiz/prequiz-skipped@2x.png"),
            ]}
            alt=""
            aria-hidden="true"
          />
          <h4 className="mx-3 mt-8 font-serif text-2xl font-bold">
            Pre-quiz skipped
          </h4>
          <p className="mx-3 mt-2">
            You&apos;ve skipped the pre-quiz for {props.subunitCopy}.
          </p>
        </div>
      )}
      {pageState === "finished" && indexOfSkill === undefined && (
        <PreQuizSummary
          assignment={props.subunitProgress?.preQuiz?.assignment}
          subunitCopy={props.subunitCopy}
        />
      )}
      {(pageState === "solving" ||
        (pageState === "finished" && indexOfSkill !== undefined)) && (
        <>
          {props.subunitProgress?.preQuiz?.assignment && (
            <SubunitsSolving
              assignment={props.subunitProgress?.preQuiz?.assignment}
            />
          )}
          {!props.subunitProgress?.skippedPreQuiz &&
            !props.subunitProgress?.preQuiz?.assignment?.submitted &&
            !allSkillsComplete && (
              <div className="mt-4 text-left max-md:flex max-md:flex-col max-sm:px-4 md:text-right">
                Want to skip the quiz and practice instead?
                <SkipPreQuiz
                  courseId={props.courseId}
                  unitId={props.unitId}
                  subunitId={props.subunitId}
                  started={true}
                  callback={() => submitCallback()}
                  className="md:ml-2"
                  testTimer={props.testTimer}
                />
              </div>
            )}
        </>
      )}
    </>
  );
};

export default PreQuiz;
