import { useContext, useState, useLayoutEffect } from "react";
import { SectionDataStatus } from "../../_constants";
import StudentSectionsContext from "../../_context/StudentSectionsContext";
import Assignments from "../../components/assignments";
import { getSectionData } from "../../utils";
import FadeInSection from "../FadeInSection";
import clsx from "clsx";

export default function PastDue(): JSX.Element {
  const { activeSection, dmAssignmentData, openAssignment } = useContext(
    StudentSectionsContext
  );

  const assignmentList = getSectionData(
    SectionDataStatus["past-due"],
    activeSection,
    dmAssignmentData
  );

  const filteredList = (label: SectionDataStatus) =>
    assignmentList?.filter((section: any) => section.sa?.status == label);

  const hasCreditList = filteredList(SectionDataStatus.late_credit_available);
  const noCreditList = filteredList(SectionDataStatus.no_late_available);
  const hasCreditCount = hasCreditList?.length;
  const noCreditCount = noCreditList?.length;
  const showFilters = hasCreditCount > 0 && noCreditCount > 0;

  // if no assignments w/ available credit, default to assignments w/o available credit
  const [isCreditAvailable, setIsCreditAvailable] = useState<boolean>(
    hasCreditCount > 0 ? true : false
  );

  useLayoutEffect(() => {
    setIsCreditAvailable(hasCreditCount > 0 ? true : false);
  }, [hasCreditCount]);

  // if student hit back button from problem page, activate proper status section
  // so window can scroll to the assignment
  useLayoutEffect(() => {
    if (openAssignment) {
      const AssgStatus = assignmentList?.find(
        (section: any) => section.ta?._id == openAssignment
      )?.sa?.status;

      setIsCreditAvailable(
        AssgStatus === SectionDataStatus.late_credit_available ? true : false
      );
    }
  }, []);

  const listToShow = isCreditAvailable ? hasCreditList : noCreditList;

  const assignmentCount = assignmentList?.length;

  return (
    <>
      {assignmentCount ? (
        <>
          {showFilters && (
            <div className="overflow-hidden">
              <div className="flex flex-nowrap gap-3 overflow-auto border-b border-dm-charcoal-100 bg-white px-4 py-4 text-dm-charcoal-800 sm:px-6">
                <FadeInSection fadeClass="fade-in-badge" threshold={0.65}>
                  <button
                    className={clsx(
                      "group whitespace-nowrap rounded-full border-2 px-4 py-1.5 text-sm",
                      isCreditAvailable
                        ? "border-dm-brand-blue-800 bg-dm-brand-blue-800 text-white"
                        : "border-dm-brand-blue-200 bg-transparent hover:bg-dm-crystal-blue-100"
                    )}
                    onClick={() => setIsCreditAvailable(true)}
                  >
                    Late Credit Available
                    <span
                      className={clsx(
                        "ml-2 rounded-full px-2.5 py-0.5 text-xs",
                        isCreditAvailable
                          ? "bg-white text-dm-charcoal-800"
                          : "bg-dm-charcoal-100 text-dm-charcoal-800"
                      )}
                      aria-label={`${hasCreditCount} task${
                        hasCreditCount !== 1 ? "s" : ""
                      }.`}
                    >
                      {hasCreditCount}
                    </span>
                  </button>
                </FadeInSection>
                <FadeInSection fadeClass="fade-in-badge" threshold={0.65}>
                  <button
                    className={clsx(
                      "group whitespace-nowrap rounded-full border-2 px-4 py-1.5 text-sm",
                      !isCreditAvailable
                        ? "border-dm-brand-blue-800 bg-dm-brand-blue-800 text-white"
                        : "border-dm-brand-blue-200 bg-transparent hover:bg-dm-crystal-blue-100"
                    )}
                    onClick={() => setIsCreditAvailable(false)}
                  >
                    No Late Credit Available
                    <span
                      className={clsx(
                        "ml-2 rounded-full px-2.5 py-0.5 text-xs",
                        !isCreditAvailable
                          ? "bg-white text-dm-charcoal-800"
                          : "bg-dm-charcoal-100 text-dm-charcoal-800"
                      )}
                      aria-label={`${noCreditCount} task${
                        noCreditCount !== 1 ? "s" : ""
                      }.`}
                    >
                      {noCreditCount}
                    </span>
                  </button>
                </FadeInSection>
              </div>
            </div>
          )}
          <div className="mt-4 px-4 sm:mt-6 sm:px-6">
            {listToShow.map((section: any) => (
              <Assignments
                section={section}
                key={`pd-section:${encodeURI(section?.ta?._id)}`}
              />
            ))}
          </div>
        </>
      ) : null}
    </>
  );
}
