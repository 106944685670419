import React from "react";

interface JsonDisplayProps {
  data: unknown;
  className?: string;
  style?: React.CSSProperties;
}

const JsonDisplay: React.FC<JsonDisplayProps> = ({
  data,
  className = "min-w-[400px] max-h-[200px] overflow-y-scroll",
  style = { whiteSpace: "pre-wrap", wordWrap: "break-word" },
}) => {
  return (
    <pre className={className} style={style}>
      <code>{JSON.stringify(data, null, 2)}</code>
    </pre>
  );
};

export default JsonDisplay;
