import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";

export default function Chevron({ open }: { open: boolean }): JSX.Element {
  if (open) {
    return (
      <ChevronUpIcon
        className="h-5 w-5 flex-shrink-0 text-dm-gray-800"
        aria-hidden="true"
      />
    );
  }
  return (
    <ChevronDownIcon
      className="h-5 w-5 flex-shrink-0 text-dm-gray-200"
      aria-hidden="true"
    />
  );
}
