export const FireIcon: React.FC<{ showAria?: boolean }> = ({
  showAria = false,
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-label="fire icon"
      aria-hidden={!showAria}
    >
      <path
        d="M15.3621 5.214C17.087 5.98477 18.4951 7.32406 19.3513 9.00814C20.2076 10.6922 20.46 12.6191 20.0665 14.4669C19.6729 16.3147 18.6573 17.9715 17.1891 19.1606C15.721 20.3496 13.8894 20.9989 12.0001 21C10.3839 21.0001 8.8033 20.5255 7.45449 19.6351C6.10568 18.7448 5.0481 17.4778 4.41306 15.9916C3.77802 14.5054 3.59349 12.8654 3.88239 11.2753C4.17129 9.68513 4.92089 8.21485 6.03812 7.047C6.80754 8.12246 7.82315 8.99818 9.00012 9.601C9.02073 8.27963 9.33264 6.97908 9.9136 5.79209C10.4946 4.6051 11.3303 3.56092 12.3611 2.734C13.1479 3.78919 14.1768 4.63976 15.3611 5.214H15.3621Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0001 18C12.9528 18.0016 13.8704 17.6405 14.5665 16.9901C15.2626 16.3396 15.6851 15.4486 15.7481 14.498C15.8111 13.5474 15.5099 12.6085 14.9058 11.8718C14.3016 11.1352 13.4397 10.6562 12.4951 10.532C11.4625 11.4561 10.7822 12.7096 10.5701 14.079C9.79972 13.8902 9.07463 13.5499 8.43712 13.078C8.25178 13.6413 8.20268 14.2406 8.29386 14.8266C8.38503 15.4125 8.61389 15.9685 8.96163 16.4489C9.30937 16.9293 9.76609 17.3204 10.2943 17.59C10.8225 17.8596 11.4071 18.0001 12.0001 18Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
