import { ANALYTICS_PLUGINS } from "./plugins";
import { AnalyticsEvent } from "./types";

/** Returns an AnalyticsEvent. This is mostly to simplify types and usage. */
export function createAnalyticsEvent<T extends Record<string, unknown>>(
  event: string,
  properties: T,
  plugins: Array<keyof typeof ANALYTICS_PLUGINS>
): AnalyticsEvent<T> {
  return {
    event,
    properties,
    plugins,
  };
}
