import { PropsWithChildren } from "react";

export const LandingPageError: React.FC<PropsWithChildren<unknown>> = ({
  children,
}) => (
  <div className="flex h-full items-center justify-center">
    <p className="text-red-700">
      <i className="fas fa-exclamation-circle mr-2"></i>
      {children}
    </p>
  </div>
);
