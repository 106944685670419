import clsx from "clsx";
import {
  SectionDataStatus,
  AssignmentDueDateType,
  AssignmentType,
} from "../../_constants";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/solid";
import { Tooltip } from "../../../shared/Tooltip";
import IconAssignmentProgress from "../IconAssignmentProgress";
import Countdown from "../Countdown";
import { timeLimitText } from "../../utils";
import SubTextBlock from "./SubTextBlock";
import AssignmentAccordionButton from "./AssignmentAccordionButton";

type Props = {
  section: any;
  open: boolean;
  type: "correction" | "test" | "standard";
  timedAssignment: {
    isTimed: boolean;
    timeLimit: any;
    endTime: number | undefined;
    serverTime: any;
    additionalTime: any;
    timerText: string;
    isUnlimited: boolean;
    isOverUnlimitedTime: boolean | 0 | undefined;
    isOver: any;
  };
  assignmentType: AssignmentDueDateType | undefined;
  uploadNotesAssignment: any;
  showButton?: boolean;
};

const AssignmentLineItem = (props: Props) => {
  // const showSolutionsBtn =
  //   props.section?.sa?.actuallyComplete >= 100 ||
  //   (props.timedAssignment.isTimed &&
  //     props.timedAssignment.endTime &&
  //     props.timedAssignment.isOver);

  const dateObject =
    props.assignmentType == SectionDataStatus.late_credit_available
      ? props.section?.sa?.lateDate?.date
      : props.section?.sa?.dueDate;

  const dueDate =
    dateObject?.date && dateObject?.tz
      ? new Date(dateObject?.date).toLocaleString("en-US", {
          timeZone: dateObject?.tz,
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        })
      : "";
  const originalDueDate =
    props.section?.sa?.dueDate?.date && props.section?.sa?.dueDate?.tz
      ? new Date(props.section?.sa?.dueDate?.date).toLocaleString("en-US", {
          timeZone: dateObject?.tz,
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        })
      : "";

  const allSpecialTypes = props.section.sa?.actuallyComplete === null;

  const isCorrectionType =
    props.section?.ta?.type === AssignmentType.correction;

  const showGrade =
    props.assignmentType !== SectionDataStatus.late_credit_available &&
    props.section?.sa?.grade !== undefined &&
    props.section?.sa?.grade !== null &&
    props.section?.sa?.grade !== -1 &&
    !props.section?.ta?.obscureResults &&
    !(props.timedAssignment?.isTimed && !props.timedAssignment.isOver) &&
    !allSpecialTypes;

  const totalPoints =
    props.section?.ta?.is_test && props.section?.ta?.type === "standard"
      ? props.section?.ta?.totalPoints
      : undefined;
  const studentPoints =
    totalPoints &&
    props.section?.sa?.grade !== undefined &&
    props.section?.sa?.grade !== null &&
    props.section?.sa?.grade !== -1 &&
    !props.section?.ta?.obscureResults
      ? (props.section?.sa?.complete / 100) * props.section?.ta?.totalPoints
      : undefined;

  return (
    <div
      className={clsx(
        "flex w-full content-start items-start justify-between text-left text-sm text-gray-900 sm:items-center", //bg-white //border border-dm-charcoal-100
        // "sm:flex-row",// sm:flex-nowrap",
        "flex-nowrap",
        // props.showButton ? "flex-wrap" : "flex-nowrap",
        "gap-x-2",
        "focus:outline-none focus-visible:ring focus-visible:ring-opacity-75",
        "p-4 sm:px-6"
      )}
      {...(props.timedAssignment?.isTimed && props.timedAssignment.endTime
        ? { "aria-describedby": `timer-${props.section?.sa?._id}` }
        : null)}
    >
      <IconAssignmentProgress
        section={props.section}
        timedAssignment={props.timedAssignment}
      />
      <div
        className={clsx(
          "flex max-w-full flex-grow flex-col gap-x-3 gap-y-0.5 sm:flex-row",
          "truncate sm:items-center"
        )}
      >
        <div className="truncate text-base font-bold">
          {props.section?.ta?.name}
        </div>
        <span className="flex flex-grow flex-col justify-normal gap-1 gap-y-0.5 leading-normal text-dm-charcoal-500 sm:flex-row sm:justify-end">
          <>
            {props.timedAssignment?.isTimed ? (
              props.timedAssignment.endTime ? (
                <div className="whitespace-nowrap text-dm-error-500">
                  <Countdown
                    targetTime={props.timedAssignment.endTime}
                    verticalBar={true}
                    isUnlimited={props.timedAssignment.isUnlimited}
                  />
                </div>
              ) : (
                <SubTextBlock
                  label="Time"
                  value={
                    props.timedAssignment.isUnlimited
                      ? "Unlimited"
                      : timeLimitText(
                          props.section?.ta?.timeLimitData?.timeLimit
                        )
                  }
                />
              )
            ) : (
              <></>
            )}
            {!!studentPoints && !!totalPoints && (
              <SubTextBlock label="Points" value={totalPoints} />
            )}
            {isCorrectionType &&
            props.section?.sa?.correctionInitialized &&
            props.section?.ta?.creditBack > 0 ? (
              props.section?.sa?.newtest ? (
                <Tooltip
                  message={`Original Test Grade: ${props.section.sa.test}%`}
                  options={{ aligned: "default" }}
                >
                  <SubTextBlock
                    label="New Test Grade"
                    value={`${props.section.sa.newtest}%`}
                  />
                </Tooltip>
              ) : (
                <SubTextBlock
                  label="Test Grade"
                  value={`${props.section.sa.test}%`}
                />
              )
            ) : (
              <></>
            )}
            {/* 
              if it's a correction and not initialized, don't show the grade.
              Else, if it's a correction and initialized, only show the grade
              if the student is not getting credit back OR when the grade is
              less than actuallyComplete
            */}
            {isCorrectionType && !props.section?.sa?.correctionInitialized ? (
              <></>
            ) : (isCorrectionType &&
                (props.section?.ta?.creditBack === 0 ||
                  props.section?.sa?.grade <
                    props.section?.sa?.actuallyComplete)) ||
              (!isCorrectionType && showGrade && !allSpecialTypes) ? (
              <SubTextBlock
                label="Grade"
                value={`${props.section?.sa?.grade}%`}
              />
            ) : props.section?.ta?.obscureResults &&
              !(
                props.timedAssignment?.isTimed && !props.timedAssignment.endTime
              ) &&
              !allSpecialTypes ? (
              <SubTextBlock
                label="Complete"
                value={`${props.section?.sa?.actuallyComplete || 0}%`}
              />
            ) : (
              <></>
            )}
          </>
          <Tooltip
            message={
              props.assignmentType === SectionDataStatus.late_credit_available
                ? `Originally Due: ${originalDueDate}`
                : undefined
            }
            options={{
              aligned: "default",
            }}
          >
            {props.assignmentType ===
            SectionDataStatus.late_credit_available ? (
              <SubTextBlock
                label={`${props.section?.sa?.lateDate?.credit}% Credit until`}
                value={dueDate}
                noBar
              />
            ) : (
              <SubTextBlock label="Due" value={dueDate} noBar />
            )}
          </Tooltip>
        </span>
        {props.showButton && (
          <AssignmentAccordionButton
            section={props.section}
            type={props.type}
          />
        )}
      </div>
      {!props.showButton &&
        (props.open ? (
          <ChevronUpIcon
            className="h-5 w-5 flex-shrink-0 text-dm-gray-200"
            aria-hidden="true"
          />
        ) : (
          <ChevronDownIcon
            className="h-5 w-5 flex-shrink-0 text-dm-gray-200"
            aria-hidden="true"
          />
        ))}
    </div>
  );
};

export default AssignmentLineItem;
