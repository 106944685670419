import ProgressBar from "../../../shared/ProgressBar";
import { Subunit, SubunitProgress } from "../../types";
import SkillsTooltip from "../Subunit/SkillsTooltip";

type Props = {
  subunit: Subunit;
  index: number;
  difficulty: "easy" | "hard";
  subunitProgress?: SubunitProgress;
};

const SubunitProgressSection = (props: Props) => {
  const preQuizState = props.subunitProgress?.skippedPreQuiz
    ? "skipped"
    : !props.subunitProgress?.preQuiz
    ? "notStarted"
    : props.subunitProgress?.preQuiz.submitted
    ? "complete"
    : "inProgress";

  const postQuizState = props.subunitProgress?.postQuiz?.submitted
    ? "complete"
    : !props.subunitProgress?.postQuiz &&
      props.subunitProgress?.preQuiz?.maxGrade === 1
    ? "notRequired"
    : !props.subunitProgress?.postQuiz
    ? "notStarted"
    : "inProgress";

  const postQuizAttemptNumber =
    (props.subunitProgress?.postQuiz?.assignment?.resultHist?.length || 0) + 1;

  return (
    <div
      className="flex h-full w-full flex-col justify-between"
      key={`sectionSkills-${props.subunit.id}-${props.index}`}
    >
      <h4 className="text-dm-charcoal-800">
        <span className="mr-2 text-sm text-dm-charcoal-800">
          <strong>Section {props.index + 1}:</strong> {props.subunit.name}
        </span>
        <SkillsTooltip
          skills={props.subunit.skills}
          difficulty={props.difficulty}
          subunitId={props.subunit.id}
        />
      </h4>
      <div className="flex flex-col">
        <ProgressBar
          percentCompleted={(props.subunitProgress?.progress || 0) * 100}
          solvePage={false}
          tooltipEnabled={false}
          gradientBknd={true}
          width="w-full"
          height="h-6"
        />
        <p className="text-xs">
          <strong>
            {Math.round((props.subunitProgress?.progress || 0) * 100)}%
          </strong>{" "}
          complete
        </p>
        <div className="mt-2 flex gap-4">
          <p className="text-sm">
            Pre-quiz
            {preQuizState === "complete" ? " Score" : ""}:{" "}
            <b>
              {preQuizState === "notStarted"
                ? "Not Started"
                : preQuizState === "skipped"
                ? "Skipped"
                : preQuizState === "complete" &&
                  props.subunitProgress?.preQuiz?.maxGrade !== undefined
                ? `${Math.round(props.subunitProgress.preQuiz.maxGrade * 100)}%`
                : "In Progress"}
            </b>
          </p>
          {preQuizState !== "notStarted" && (
            <>
              <p className="border-l pl-4 text-sm">
                Post-Quiz {postQuizState === "complete" ? " Score" : ""}:{" "}
                <b>
                  {postQuizState === "notStarted"
                    ? "Not Started"
                    : postQuizState === "notRequired"
                    ? "Not Required"
                    : postQuizState === "complete" &&
                      props.subunitProgress?.postQuiz?.maxGrade !== undefined
                    ? `${Math.round(
                        props.subunitProgress?.postQuiz?.maxGrade * 100
                      )}%${postQuizAttemptNumber > 1 ? " (best)" : ""}`
                    : `In Progress${
                        postQuizAttemptNumber > 1
                          ? ` (Attempt ${postQuizAttemptNumber})`
                          : ""
                      }`}
                </b>
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SubunitProgressSection;
