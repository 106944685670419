import clsx from "clsx";
import { NavLink, useLocation } from "react-router-dom";
import { GridSquareIcon } from "../../../shared/icons/GridSquareIcon";
import { REACT_APP_LEARNER_LINK } from "../../../utils";
import { Learner } from "../../types";

export const InsightsButton: React.FC<{
  learner: Learner;
  currentLearner: Learner;
  onClick: () => void;
}> = ({ learner, currentLearner, onClick }) => {
  const { pathname } = useLocation();
  const isDashboardActive = pathname === `${REACT_APP_LEARNER_LINK}/parent`;
  const isCurrentLearner = learner._id === currentLearner._id;
  const isActive = isDashboardActive && isCurrentLearner;

  return (
    <NavLink
      className={clsx(
        "flex items-center gap-2 rounded-[4px] border px-4 py-2 text-[14px] leading-[26px]",
        isActive
          ? "border-dm-brand-blue-100 bg-dm-brand-blue-100 font-bold hover:border-dm-brand-blue-200"
          : "border-transparent"
      )}
      onClick={onClick}
      to={`${REACT_APP_LEARNER_LINK}/parent`}
    >
      <span
        className={clsx(isActive ? "text-dm-gray-800" : "text-dm-gray-200")}
        aria-hidden
      >
        <GridSquareIcon />
      </span>
      {learner.first}&apos;s Insights
    </NavLink>
  );
};
