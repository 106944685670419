export function secondsToDuration(timeInSeconds: number): string {
  const oneHour = 60 * 60;
  const hours = Math.floor(timeInSeconds / oneHour);
  const minutes = Math.floor((timeInSeconds % oneHour) / 60);

  const result: string[] = [];

  if (hours > 0) {
    result.push(
      hours === 1
        ? "1 hour"
        : `${Intl.NumberFormat("en-us").format(hours)} hours`
    );
  }
  if (minutes > 0) {
    result.push(minutes === 1 ? "1 min" : `${minutes} mins`);
  }
  if (!hours && !minutes) {
    result.push("0 mins");
  }

  return result.join(" ");
}
