import { useEffect, useRef, useState } from "react";
import { SwitchHorizontalIcon } from "@heroicons/react/outline";
import { useCourseContext } from "../../../contexts/CourseContext";
import {
  LearnerAssignmentSkillData,
  LearnerAssignmentTypes,
} from "../../../types";
import { scrollToView } from "../../../../student/utils";
import IconStatus from "../../IconStatus";
import { NavLink } from "react-router-dom";
import clsx from "clsx";

export const PastAttemptItem: React.FC<{
  skill: LearnerAssignmentSkillData;
  skills: LearnerAssignmentSkillData[];
  index: number;
  indexOfSkill: string | undefined;
  defaultOpen: boolean;
  submittedTime: string | undefined;
  type: LearnerAssignmentTypes;
  submitted?: Date | undefined;
  baseUrl: string;
  lastAttempt: boolean;
  attemptTime: number | false;
  wasSwapped?: boolean;
}> = ({
  skill,
  skills,
  index,
  indexOfSkill,
  defaultOpen,
  submittedTime,
  type,
  submitted,
  baseUrl,
  lastAttempt,
  attemptTime,
  wasSwapped,
}) => {
  const [isSkillHover, setIsSkillHover] = useState<boolean>(false);
  const buttonRef = useRef<HTMLDivElement>(null);
  const courseContext = useCourseContext();
  const unit = courseContext.getUnitData(skill.unitId, undefined);
  const course = courseContext.getCourseData(unit?.courseId);

  useEffect(() => {
    if (index.toString() === indexOfSkill && defaultOpen) {
      scrollToView(buttonRef, "instant", "center");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexOfSkill, submittedTime]);

  return (
    <>
      {type === "unitTest" &&
        submitted !== undefined &&
        unit &&
        skill?.subunitId !== skills[index - 1]?.subunitId && (
          <h3 className="mb-1 mt-4 flex items-center gap-x-4 px-3 text-xs uppercase text-dm-gray-500">
            Section{" "}
            {unit.subunitOrder.findIndex((id) => id === skill.subunitId) + 1}
            <hr className="flex-grow" />
          </h3>
        )}
      {type === "courseTest" &&
        submitted !== undefined &&
        course &&
        skill?.unitId !== skills[index - 1]?.unitId && (
          <h3
            className="mb-1 mt-4 flex cursor-help items-center gap-x-4 px-3 text-xs uppercase text-dm-gray-500"
            data-tip={unit?.name}
            data-for={"sidebar-tooltip"}
            data-delay-show="200"
            data-delay-hide="40"
            data-place="top"
            data-class="py-1"
          >
            Unit {course.unitOrder.findIndex((id) => id === skill.unitId) + 1}
            <hr className="flex-grow" />
          </h3>
        )}
      <div className="mb-2 last:mb-1" ref={buttonRef}>
        <NavLink
          to={`${baseUrl}/${index}${!lastAttempt ? `/${attemptTime}` : ""}`}
          end
          className={({ isActive }) =>
            clsx(
              isActive ? "bg-dm-brand-blue-100" : "",
              "block rounded px-3 py-1 text-sm !leading-none text-dm-charcoal-800 hover:bg-dm-brand-blue-100",
              "flex flex-row items-center gap-x-2"
            )
          }
          onPointerEnter={() => {
            if (!isSkillHover) setIsSkillHover(true);
          }}
          onPointerLeave={() => {
            if (isSkillHover) setIsSkillHover(false);
          }}
        >
          {({ isActive }) => (
            <>
              <IconStatus
                skillComplete={skill.skillComplete}
                assignmentComplete={!!submitted}
                skillCorrect={skill.score}
                isActive={isActive || isSkillHover}
              />
              <div className="text-sm">{`Question ${index + 1}`}</div>
              {wasSwapped && (
                <span className="flex items-center rounded-full bg-dm-purple-200 p-0.5 leading-none text-dm-purple-800">
                  <SwitchHorizontalIcon className="w-3" />
                </span>
              )}
            </>
          )}
        </NavLink>
      </div>
    </>
  );
};
