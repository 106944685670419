import { Skeleton, SkeletonCircle, SkeletonText } from "./Skeleton";
import { CourseLandingWrapper } from "./CourseLandingWrapper";

export const CourseLandingLoading: React.FC = () => {
  const fakeUnits = [
    "Occaecat magna eu officia",
    "Incididunt sint magna",
    "Quis dolore nulla",
  ];

  return (
    <CourseLandingWrapper
      mobileCourseSearch={
        <div className="h-10 w-full">
          <Skeleton></Skeleton>
        </div>
      }
      header={
        <div className="flex gap-x-6 rounded-lg border border-dm-charcoal-100 bg-white px-6 py-4">
          <div className="mt-8 h-16 w-16 shrink-0 md:mt-0 md:h-32 md:w-32">
            <SkeletonCircle />
          </div>
          <div className="flex flex-grow flex-col gap-5">
            <SkeletonText>
              <h1 className="flex-grow font-serif text-2xl font-bold">
                Waiting for course data...
              </h1>
            </SkeletonText>
            <div className="flex flex-col gap-2 text-sm">
              <SkeletonText>
                <p>Lorem ipsum</p>
              </SkeletonText>
              <SkeletonText>
                <p>
                  Dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                  tempor incididunt ut labore et dolore magna aliqua
                </p>
              </SkeletonText>
              <SkeletonText>
                <p>Ut enim ad minim veniam, quis nostrud exercitation</p>
              </SkeletonText>
            </div>
            <SkeletonText>
              <p>Units</p>
            </SkeletonText>
            <div className="flex gap-6">
              {fakeUnits.map((u) => (
                <div key={`circle-${u}`} className="h-14 w-14 shrink-0">
                  <SkeletonCircle />
                </div>
              ))}
            </div>
            <div className="flex flex-col gap-1 text-sm md:flex-row md:gap-6">
              <SkeletonText>
                <p>Veniam officia</p>
              </SkeletonText>
              <SkeletonText>
                <p>amet minim id</p>
              </SkeletonText>
              <SkeletonText>
                <p>esse dolore</p>
              </SkeletonText>
            </div>
          </div>
        </div>
      }
    >
      <div className="flex flex-col gap-4">
        {fakeUnits.map((u) => (
          <div
            key={`unit-${u}`}
            className="flex gap-2 rounded-lg border border-dm-charcoal-100 bg-white px-4 py-4 md:px-6"
          >
            <div className="h-8 w-8">
              <SkeletonCircle />
            </div>
            <div className="flex flex-col gap-1">
              <SkeletonText>
                <div className="text-base md:text-lg">{u}</div>
              </SkeletonText>
              <SkeletonText>
                <p className="text-sm">? Sections</p>
              </SkeletonText>
            </div>
          </div>
        ))}
      </div>
    </CourseLandingWrapper>
  );
};
