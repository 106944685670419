import { Infotip } from "../../../shared/Infotip";
import { Skill } from "../../types";

type Props = {
  skills: Skill[];
  subunitId: string;
  difficulty: "easy" | "hard";
};

const SkillsTooltip = (props: Props) => {
  return (
    <Infotip
      options={{ tooltipRight: true }}
      ariaLabel="More information"
      message={
        <div className="flex flex-col gap-2">
          <h5 className="text-sm font-bold">Skills in this section:</h5>
          {props.skills
            .filter(
              (sk) =>
                sk.difficulty === "both" || sk.difficulty === props.difficulty
            )
            .map((x, i) => (
              <p
                className="text-xs font-normal"
                key={`tooltip-summary-${props.subunitId}-${x.skillName}-${i}`}
              >
                {x.skillName}
              </p>
            ))}
        </div>
      }
    >
      <i
        className="far fa-info-circle cursor-pointer text-dm-gray-200"
        aria-hidden="true"
      />
    </Infotip>
  );
};

export default SkillsTooltip;
