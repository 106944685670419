import { FormEventHandler, useState } from "react";
import { PartialFeatureFlag } from "./types";
import Button from "../../../student/components/generic/button";

export const CreateFeatureFlagForm: React.FC<{
  refetchFlags: () => void;
  updateFlag: (flag: PartialFeatureFlag) => void;
}> = ({ refetchFlags, updateFlag }) => {
  const [newFlagName, setNewFlagName] = useState<string>("");
  const createNewFlag: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    await updateFlag({
      name: newFlagName,
      state: "disabled",
      users: [],
    });
    setNewFlagName("");
    refetchFlags();
  };

  return (
    <form onSubmit={createNewFlag} className="flex items-center gap-3">
      {/* <label htmlFor="flag-name">Flag name</label> */}
      <input
        type="text"
        id="flag-name"
        onChange={(e) => setNewFlagName(e.target.value)}
        value={newFlagName}
        className="w-96 rounded border border-dm-charcoal-200 px-3 py-1"
        placeholder="Feature flag name"
      />
      <Button size="small">Create</Button>
    </form>
  );
};
