import { Fragment, useEffect, useMemo, useRef } from "react";
import { CheckAnswerResponse, Problem } from "../../types";
import { displayInlineMath, resizeKatexLine } from "../../../student/utils";
import renderMathInElement from "../../../student/utils/auto-render";
import { formatAns } from "../../../student/components/answerForm/FormattedAnswer";
import Button from "../../../student/components/generic/button";
import clsx from "clsx";
import { getSelectedChoice } from "../../../utils";
import { useProblemSolvingContext } from "../../contexts/ProblemSolvingContext";
import DisplaySolution from "./DisplaySolution";

type Props = {
  problem: Problem;
  checkAnswerResponse?: CheckAnswerResponse;
  unsubmitAllowed: boolean;
  userSolution?: object | string[];
  noSolutionText: string;
  noAnswerSubmitted: boolean;
  unsubmit: () => void;
  customMessage?: string;
  showMultipleAnswers: boolean;
};

const YourSolution = (props: Props) => {
  const { logData } = useProblemSolvingContext();

  if (!props.userSolution) {
    return <></>;
  }

  const answersToDisplay = [
    ...(logData?.attempts_data &&
    logData?.attempts_data.length > 0 &&
    props.showMultipleAnswers
      ? logData.attempts_data.map((x) => {
          return {
            ans: x.ans,
            sol: x.sol,
            correct: false,
            messages: x.messages,
          };
        })
      : []),
    {
      ans: props.userSolution,
      sol: props.customMessage,
      correct: logData?.correct === 1,
      messages: logData?.messages,
    },
  ];

  return (
    <>
      {answersToDisplay.map((ans, i) => (
        <DisplaySolution
          problem={props.problem}
          answer={ans.ans}
          noSolutionText={props.noSolutionText}
          noAnswerSubmitted={props.noAnswerSubmitted}
          customMessage={ans.sol}
          index={i}
          totalAttempts={answersToDisplay.length}
          correct={ans.correct}
          key={`answer-display-${i}`}
          messages={ans.messages}
        />
      ))}

      {props.unsubmitAllowed && (
        <Button className="mt-6" onClick={props.unsubmit}>
          Unsubmit Answer
        </Button>
      )}
    </>
  );
};

export default YourSolution;
