import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";
import CustomGraphingIcon from "../../../student/components/icons/CustomGraphIcon";
import { useFocusContext } from "../../contexts/FocusContext";
import { useLearnerContext } from "../../contexts/LearnerContext";
import { REACT_APP_STUDENT_LINK } from "../../../utils";

const CalculatorButton = () => {
  const { isSidebarMinimized } = useLearnerContext();
  const focusContext = useFocusContext();

  return (
    <Disclosure
      as="div"
      // key="archiveCourses"
      className="space-y-1"
      defaultOpen={false}
    >
      {({ open }) => (
        <>
          <div className="inline-flex w-full items-center justify-between text-sm text-dm-gray-200 hover:text-dm-gray-600">
            <button
              aria-label="Calculator"
              type="button"
              onClick={() => {
                // in a setTimeout to beat the <Transition> of the closing
                // sidebar, which caused the calculator to show and
                // quickly disappear b/c the sidebar refocuses on the last
                // focused element using <FocusTrap>
                setTimeout(() => {
                  if (
                    focusContext.keyboardOpen &&
                    !focusContext.calculatorOpen
                  ) {
                    focusContext.openCalculator(true);
                  } else if (!focusContext.keyboardOpen) {
                    focusContext.openKeyboard(true);
                    focusContext.openCalculator(true);
                  } else {
                    focusContext.openKeyboard(false);
                    focusContext.openCalculator(false);
                  }
                }, 300);
              }}
            >
              <i className="far fa-calculator mr-2 h-6 w-6 text-xl leading-none"></i>
              {!isSidebarMinimized && (
                <span className="flex-1 text-left text-sm text-dm-gray-600">
                  Calculator
                </span>
              )}
            </button>
            <Disclosure.Button
              className="group ml-2 h-5 w-5 items-end text-sm"
              aria-label="Show advanced calculator options."
            >
              {open ? (
                <ChevronUpIcon
                  className="text-dm-gray-200"
                  aria-hidden="true"
                />
              ) : (
                <ChevronDownIcon
                  className="text-dm-gray-200"
                  aria-hidden="true"
                />
              )}
            </Disclosure.Button>
          </div>

          <Disclosure.Panel className="space-y-1">
            <Link
              to={`${REACT_APP_STUDENT_LINK}/graphingCalculator`}
              key={`calckey-0`}
              className="group flex items-center rounded-md px-3 py-2 pl-8 text-sm text-dm-gray-600"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="mr-2 h-6 w-6">
                <CustomGraphingIcon />
              </span>
              <span className="text-dm-gray-600">Graphing Calculator</span>
            </Link>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default CalculatorButton;
