import { useEffect } from "react";
import { getFilePath } from "../../../../utils";
import { ResponsiveImage } from "../../ResponsiveImage";
import { useHomepageSignupContext } from "../HomepageSignupContext";
import { useLearnerAnalytics } from "../../../analytics/useLearnerAnalytics";
import { homepageSignupStepEvent } from "../../../analytics/events";

export const AccountTypeChooser: React.FC = () => {
  const { track } = useLearnerAnalytics();
  const { setAccountType } = useHomepageSignupContext();

  useEffect(() => {
    track(homepageSignupStepEvent("accountType"));
    // Only run on first render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-full px-4 pb-10 pt-14 sm:px-8 sm:pt-12">
      <header className="mb-6 text-center font-serif font-bold sm:mb-10">
        <h3 className="mb-1 text-lg text-dm-brand-blue-600 sm:text-2xl">
          Let&apos;s Get Started!
        </h3>
        <h4 className="sm:text-lg">Are you a parent or a learner?</h4>
      </header>
      <div className="flex w-full flex-col gap-4 sm:h-[234px] sm:flex-row sm:gap-6">
        <button
          className="flex w-full flex-col items-center justify-center rounded-2xl border-[3px] border-dm-brand-blue-200 px-2 py-4 hover:border-dm-brand-blue-500 sm:h-full sm:w-1/2"
          onClick={() => setAccountType("parent")}
        >
          <ResponsiveImage
            className="mb-4"
            srcs={[
              getFilePath("/images/learner/home/deltie-parent.png"),
              getFilePath("/images/learner/home/deltie-parent@2x.png"),
            ]}
            alt="Parent"
            aria-hidden
          />
          <h4 className="font-serif text-lg font-bold text-dm-brand-blue-600">
            I&apos;m a Parent
          </h4>
          <p>Partnering in my child&apos;s learning</p>
        </button>
        <button
          className="flex w-full flex-col items-center justify-center rounded-2xl border-[3px] border-dm-brand-blue-200 px-2 py-4 hover:border-dm-brand-blue-500 sm:h-full sm:w-1/2"
          onClick={() => setAccountType("learner")}
        >
          <ResponsiveImage
            className="mb-4"
            srcs={[
              getFilePath("/images/learner/home/deltie-learner.png"),
              getFilePath("/images/learner/home/deltie-learner@2x.png"),
            ]}
            alt="Parent"
            aria-hidden
          />
          <h4 className="font-serif text-lg font-bold text-dm-brand-blue-600">
            I&apos;m a Learner
          </h4>
          <p>Ready to level up my math skills</p>
        </button>
      </div>
    </div>
  );
};
