import { MIN_ROWS_TO_UNLOCK } from "../constants";
import { usePrizes } from "./usePrizes";
import { PascalsTriangleRow, Prize } from "../types";
import { useLearnerContext } from "../contexts/LearnerContext";

/** Given the learner current pascalsRow, find the next prize they can unlock */
export function getNextPrize(
  prizes: Prize[],
  currentPascalsRow: PascalsTriangleRow | undefined
): Prize | null {
  const nextPrizeIndex = currentPascalsRow
    ? currentPascalsRow.length - MIN_ROWS_TO_UNLOCK
    : 0;
  // return null instead of undefined so we can tell the difference between loaded and not loaded
  return prizes[nextPrizeIndex] || null;
}

/** Find the next prize they can unlock */
export function useNextPrize(): Prize | undefined | null {
  const { learner } = useLearnerContext();
  const prizes = usePrizes();
  if (!learner) {
    // Undefined means the learner isn't loaded yet
    return undefined;
  }
  return getNextPrize(prizes, learner?.pascalsRow);
}
