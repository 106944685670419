export const FormIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <g clipPath="url(#clip0_11937_5056)">
      <path
        d="M11.8465 6.50018V2.50005C11.8465 2.10221 11.6593 1.72067 11.3261 1.43935C10.9928 1.15804 10.5408 1 10.0696 1H1.77698C1.3057 1 0.853714 1.15804 0.520466 1.43935C0.187217 1.72067 0 2.10221 0 2.50005V13.5004C0 13.8982 0.187217 14.2798 0.520466 14.5611C0.853714 14.8424 1.3057 15.0005 1.77698 15.0005H10.0696C10.5408 15.0005 10.9928 14.8424 11.3261 14.5611C11.6593 14.2798 11.8465 13.8982 11.8465 13.5004V10.0003C11.5324 10.0003 11.231 10.1057 11.0089 10.2932C10.7867 10.4807 10.6619 10.7351 10.6619 11.0003V13.5004C10.6619 13.633 10.5995 13.7602 10.4884 13.854C10.3773 13.9477 10.2267 14.0004 10.0696 14.0004H1.77698C1.61989 14.0004 1.46923 13.9477 1.35814 13.854C1.24706 13.7602 1.18465 13.633 1.18465 13.5004V2.50005C1.18465 2.36744 1.24706 2.24026 1.35814 2.14648C1.46923 2.05271 1.61989 2.00003 1.77698 2.00003H10.0696C10.2267 2.00003 10.3773 2.05271 10.4884 2.14648C10.5995 2.24026 10.6619 2.36744 10.6619 2.50005V7.50021C10.9761 7.50021 11.2774 7.39485 11.4996 7.20731C11.7217 7.01977 11.8465 6.7654 11.8465 6.50018Z"
        fill="#0C1116"
      />
      <path
        d="M9.23045 4.07812H3.0764C2.91319 4.07812 2.75666 4.14296 2.64125 4.25837C2.52584 4.37378 2.461 4.53031 2.461 4.69353C2.461 4.85675 2.52584 5.01328 2.64125 5.12869C2.75666 5.2441 2.91319 5.30893 3.0764 5.30893H9.23045C9.39367 5.30893 9.5502 5.2441 9.66561 5.12869C9.78102 5.01328 9.84586 4.85675 9.84586 4.69353C9.84586 4.53031 9.78102 4.37378 9.66561 4.25837C9.5502 4.14296 9.39367 4.07812 9.23045 4.07812ZM9.84586 7.15515C9.84586 6.99193 9.78102 6.8354 9.66561 6.71999C9.5502 6.60458 9.39367 6.53974 9.23045 6.53974H3.0764C2.91319 6.53974 2.75666 6.60458 2.64125 6.71999C2.52584 6.8354 2.461 6.99193 2.461 7.15515C2.461 7.31836 2.52584 7.47489 2.64125 7.59031C2.75666 7.70572 2.91319 7.77055 3.0764 7.77055H9.23045C9.39367 7.77055 9.5502 7.70572 9.66561 7.59031C9.78102 7.47489 9.84586 7.31836 9.84586 7.15515ZM3.0764 9.00136C2.91319 9.00136 2.75666 9.0662 2.64125 9.18161C2.52584 9.29702 2.461 9.45355 2.461 9.61677C2.461 9.77998 2.52584 9.93651 2.64125 10.0519C2.75666 10.1673 2.91319 10.2322 3.0764 10.2322H5.53802C5.70124 10.2322 5.85777 10.1673 5.97318 10.0519C6.08859 9.93651 6.15343 9.77998 6.15343 9.61677C6.15343 9.45355 6.08859 9.29702 5.97318 9.18161C5.85777 9.0662 5.70124 9.00136 5.53802 9.00136H3.0764Z"
        fill="#0C1116"
      />
      <path
        d="M7.02394 13.6161C6.93239 13.616 6.84201 13.5956 6.75938 13.5562C6.67675 13.5168 6.60395 13.4594 6.54629 13.3883C6.48863 13.3172 6.44756 13.2341 6.42607 13.1451C6.40458 13.0562 6.40322 12.9635 6.42207 12.8739L6.88486 10.6701C6.9095 10.5536 6.96743 10.4467 7.05163 10.3624L12.8487 4.56595C13.2005 4.23026 13.6681 4.04297 14.1543 4.04297C14.6406 4.04297 15.1081 4.23026 15.4599 4.56595C15.6314 4.73739 15.7674 4.94093 15.8602 5.16494C15.953 5.38895 16.0008 5.62905 16.0008 5.87153C16.0008 6.114 15.953 6.3541 15.8602 6.57812C15.7674 6.80213 15.6314 7.00567 15.4599 7.17711L9.66156 12.9724C9.57753 13.057 9.47057 13.1152 9.35386 13.1398L7.1501 13.6032C7.10858 13.6117 7.06632 13.616 7.02394 13.6161ZM8.05474 11.1028L7.82335 12.2044L8.92308 11.973L14.5897 6.3057C14.7051 6.19029 14.7699 6.03379 14.7699 5.8706C14.7699 5.70742 14.7051 5.55092 14.5897 5.43551C14.4727 5.32321 14.3168 5.2605 14.1546 5.2605C13.9925 5.2605 13.8366 5.32321 13.7195 5.43551L8.05474 11.1028Z"
        fill="#0C1116"
      />
    </g>
    <defs>
      <clipPath id="clip0_11937_5056">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
