import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { REACT_APP_LEARNER_LINK } from "../../../utils";
import { useLearnerContext } from "../../contexts/LearnerContext";
import { useParentContext } from "../../contexts/ParentContext";
import { LearnerPage } from "../Layouts/LearnerPage";
import { ParentDashboard } from "./ParentDashboard";
import { ParentNavAndSidebar } from "./ParentNavAndSidebar";
import { DashboardForInvitedLearner } from "./DashboardForInvitedLearner";
import { DashboardForNewLearner } from "./DashboardForNewLearner";
import { DashboardHeader } from "./DashboardHeader";
import { Skeleton } from "../Skeleton";

export const ParentDashboardSwitcher: React.FC = () => {
  // 3 (ish) major states
  // 0. Parent has not invited learner yet (redirect to profile page)
  // 1. Parent has invited learner but they haven't accepted
  // 2. Parent has invited and learner has accepted but has not started
  // 3. Learner has started

  const {
    currentLearner,
    learners,
    childLearnersFetched,
    isUpdatingLearnerProgress,
  } = useParentContext();
  const { state: learnerState } = useLearnerContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (childLearnersFetched && learners.length === 0) {
      navigate(`${REACT_APP_LEARNER_LINK}/parent/profile`);
    }
  }, [learners, childLearnersFetched, navigate]);

  const pageTitle = currentLearner
    ? `${currentLearner.first}'s Insights`
    : "Learner Insights";
  const welcomeMessage = `Welcome, ${learnerState.learner?.first}`;

  if (!currentLearner || isUpdatingLearnerProgress)
    return (
      <div className="flex w-full flex-col">
        <LearnerPage
          title={pageTitle}
          sidebar={<ParentNavAndSidebar />}
          dontTrackTime
          noDesktopSidebar
        >
          <DashboardHeader headerText={welcomeMessage} loaded={false} />
          <div className="flex h-dvh w-[1288px] max-w-full">
            <Skeleton />
          </div>
        </LearnerPage>
      </div>
    );

  let dashboardState: "invited" | "noProgress" | "hasProgress";
  if (currentLearner.inviteCode && !currentLearner.inviteAcceptedAt) {
    dashboardState = "invited";
  } else if (learnerState.progresses.length === 0) {
    dashboardState = "noProgress";
  } else {
    dashboardState = "hasProgress";
  }

  return (
    <div className="flex w-full flex-col">
      <LearnerPage
        title={pageTitle}
        sidebar={<ParentNavAndSidebar />}
        dontTrackTime
        noDesktopSidebar
      >
        {dashboardState === "invited" && (
          <DashboardForInvitedLearner
            currentLearner={currentLearner}
            header={<DashboardHeader headerText={welcomeMessage} />}
          />
        )}
        {dashboardState === "noProgress" && (
          <DashboardForNewLearner
            currentLearner={currentLearner}
            header={<DashboardHeader headerText={welcomeMessage} />}
          />
        )}
        {dashboardState === "hasProgress" && (
          <ParentDashboard
            currentLearner={currentLearner}
            header={<DashboardHeader headerText={pageTitle} />}
          />
        )}
      </LearnerPage>
    </div>
  );
};
