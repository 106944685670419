import { useEffect } from "react";

/* **************** */
/* Helper Functions */
/* **************** */

/**
 * Uses luminance in order to calculate color contrast per WCAG guidelines
 * @param foregroundColor array with 3 numbers, representing r g b values, for the foreground color (or null)
 * @param backgroundColor array with 3 numbers, representing r g b values, for the background color (or null)
 * @return calculated contrast ratio, if possible, otherwise undefined
 */
function calculateColorContrast(
  foregroundColor: number[] | null,
  backgroundColor: number[] | null
): number | undefined {
  if (!foregroundColor || !backgroundColor) return undefined;

  const foregroundLuminance = luminance(foregroundColor);
  const backgroundLuminance = luminance(backgroundColor);

  const contrastRatio =
    foregroundLuminance > backgroundLuminance
      ? (backgroundLuminance + 0.05) / (foregroundLuminance + 0.05)
      : (foregroundLuminance + 0.05) / (backgroundLuminance + 0.05);

  return contrastRatio;
}

/**
 * "Walks" the DOM to find and return an array containing all text nodes currently displayed under the provided element
 * @param el DOM container element to "walk"
 * @return an array of text nodes that are currently displayed on the screen, 'under' the provided element
 */
function textNodesUnder(el: Element): Node[] {
  const a = [];
  const walk = document.createTreeWalker(el, NodeFilter.SHOW_TEXT, (node) => {
    const display = node.parentElement
      ? window.getComputedStyle(node.parentElement).display
      : false;
    const hiddenFromView =
      (display && (display === "none" || display === "block math")) ||
      node.parentElement?.classList.contains("sr-only");
    if (hiddenFromView) return NodeFilter.FILTER_REJECT;
    else return NodeFilter.FILTER_ACCEPT;
  });
  let n;
  while ((n = walk.nextNode())) a.push(n);
  return a;
}

/**
 * Calculates and returns the luminance value of an rgb triplet
 * @param colorArr array with 3 numbers, representing r g b values
 * @return numerical luminance value
 */
function luminance(colorArr: number[]): number {
  const a = colorArr.map(function (v) {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  });
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

/**
 * Converts hex into rbg
 * Unused as of now, since window.getComputedStyle(el) will always return rgb or rgba!
 * @param hex string representing hex value
 * @return array with 3 numbers, representing r g b values (or null)
 */
function hexToRgb(hex: string): number[] | null {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(
    shorthandRegex,
    function (match: string, r: string, g: string, b: string) {
      return r + r + g + g + b + b;
    }
  );

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16),
      ]
    : null;
}

/**
 * Given a string with rgb or rgba values, generate and return an array where the values at indices 0, 1, 2 are the r, g, b values, respectively
 * @param color string with rbg or rgba values
 * @return array with 3 numbers, representing r g b values
 */
function parseRGB(color: string) {
  const numbers = color.startsWith("rgba")
    ? color.slice(5, -1)
    : color.slice(4, -1);

  const parts = numbers.split(",");

  return parts.slice(0, 3).map((part) => parseInt(part));
}

/**
 * Recursively determines background color of an HTMLElement with text
 * Unused as of now: operating under the assumption for modules that the text is on a white background / will not work for SVG elements
 * @param el element with text
 * @return string representing the background color for el
 */
const getBackgroundColor = (el: HTMLElement): string => {
  const style = window.getComputedStyle(el);
  // recursive case: keep searching for background color
  if (
    el.parentElement &&
    (style.backgroundColor === "transparent" ||
      style.backgroundColor === "rgba(0, 0, 0, 0)")
  ) {
    return getBackgroundColor(el.parentElement);
  } // base case 1: there is a background color that is not transparent
  else if (style.backgroundColor) {
    return style.backgroundColor;
  }
  // base case 2: cannot determine background color
  return "";
};

/**
 * Iteratively darkens the color of an HTMLElement with text until the color contrast ratio is less than 4.5:1, per WCAG guidelines for small text
 * @param el an HTMLElement with text
 * @param textColor rgb / rgba string
 * @param backgroundColor rgb / rgba string
 * @param isSvgText flag for whether the element is an SVG element
 * @param commonColorMap map of common colors used and their corresponding darker, WCAG compliant shade
 */
function handleElementContrast(
  el: HTMLElement,
  textColor: string,
  backgroundColor: string,
  isSvgText: boolean,
  commonColorMap: Map<string, string>
): void {
  // calculate contrasting color, if it's not already in the common color map
  if (!commonColorMap.has(textColor)) {
    let updatedColorArr = parseRGB(textColor); // variable to modify with progressively darker colors
    const backgroundColorArr = parseRGB(backgroundColor);

    let colorContrastRatio = calculateColorContrast(
      updatedColorArr,
      backgroundColorArr
    );

    // if the text color is white or if the ratio is WCAG compliant, return early
    if (
      colorContrastRatio &&
      (colorContrastRatio === 1 || colorContrastRatio <= 1 / 4.5)
    )
      return;

    let i = 0; // counter is a safety feature to prevent too many iterations
    // while the colorContrastRatio is greater than the requirement, darken the color
    while (colorContrastRatio && colorContrastRatio > 1 / 4.5 && i < 10) {
      updatedColorArr = updatedColorArr.map(
        (rgbValue: number) => rgbValue * 0.95
      );
      colorContrastRatio = calculateColorContrast(
        updatedColorArr,
        backgroundColorArr
      );
      i++;
    }
    // update the map
    commonColorMap.set(
      textColor,
      `rgb(${updatedColorArr[0]}, ${updatedColorArr[1]}, ${updatedColorArr[2]})`
    );
  }

  // reset the element color / fill using the updated color
  const updatedColor = commonColorMap.get(textColor);
  if (updatedColor) {
    if (isSvgText) el.style.fill = updatedColor;
    else el.style.color = updatedColor;
  }
}

/**
 * Updates the text color, if necessary, to meet WCAG guidelines
 * @param regionsToUpdate containers / regions to update the color contrast within
 * @param commonColorMap map of common colors used and their corresponding darker, WCAG compliant shade
 */
function updateTextColorContrast(
  regionsToUpdate: NodeListOf<Element>,
  commonColorMap: Map<string, string>
): void {
  // find all text nodes within the region of the modules
  const textElements: Node[] = [];
  regionsToUpdate.forEach((el: Element) => {
    textElements.push(...textNodesUnder(el));
  });

  // create an array of objects with the parent element for the text node, the computed styles and a toggle for whether the parent element is an svg
  const styles: {
    style: CSSStyleDeclaration;
    el: HTMLElement;
    svgText: boolean;
  }[] = [];
  textElements.forEach((el) => {
    if (el.parentElement) {
      styles.push({
        style: window.getComputedStyle(el.parentElement),
        el: el.parentElement,
        svgText: el.parentElement.tagName === "text",
      });
    }
  });

  // filter out any parent elements that don't have a color / fill property (depending on whether it's an svg or html element)
  const filteredStyles = styles.filter(
    (x) => (!x.svgText && x.style.color) || (x.svgText && x.style.fill)
  );

  // calculate the color contrast for each element against a white background; progressively darken any colors that don't meet the WCAG 4.5 contrast ratio
  filteredStyles.forEach((x) => {
    // determine the color of the text through either fill or color properties, depending on if the element is an svg text node
    const foregroundColor = x.svgText ? x.style.fill : x.style.color;

    handleElementContrast(
      x.el,
      foregroundColor,
      "rgb(255, 255, 255)", // operate under assumption that the background color is white for text elements in modules
      x.svgText,
      commonColorMap
    );
  });
}

/**
 * Darkens grid lines on DeltaGraphs for "high-contrast mode"
 * @param gridLinesToUpdate all SVGLineElements that represent grid lines on a DeltaGraph
 */
function updateGridLineOpacity(
  gridLinesToUpdate: NodeListOf<SVGLineElement>
): void {
  gridLinesToUpdate.forEach((gridLine) => {
    gridLine.style.strokeOpacity = "0.6";
  });
}

/* ************ */
/* Custom Hooks */
/* ************ */

/**
 * Custom hook to trigger "high-contrast mode", if the user opts-in
 * Updates color contrast for text in modules AND makes grid lines more opaque on DeltaGraphs
 * @param highContrast determines if user opts in high contrast mode
 * @param commonColorMap map of common colors and their corresponding darker, WCAG compliant shade
 */
export function useHighContrastMode(
  highContrast: boolean,
  commonColorMap: Map<string, string>
) {
  const moduleElements = document.querySelectorAll(
    "#mathBlock, #mathBlockExample"
  );
  const gridLines = document.querySelectorAll<SVGLineElement>("svg .grid line");

  useEffect(() => {
    if (highContrast) {
      if (moduleElements)
        updateTextColorContrast(moduleElements, commonColorMap);
      if (gridLines) updateGridLineOpacity(gridLines);
    }
  }, [highContrast, moduleElements, gridLines]);
}
