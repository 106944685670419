const BackArrowIcon = ({ classes }: { classes: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      className={classes}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.70711 13.7071C7.31658 14.0976 6.68342 14.0976 6.29289 13.7071L0.29289 7.7071C-0.09763 7.3166 -0.09763 6.68342 0.29289 6.29289L6.29289 0.29289C6.68342 -0.09763 7.31658 -0.09763 7.70711 0.29289C8.0976 0.68342 8.0976 1.31658 7.70711 1.70711L3.41421 6H15C15.5523 6 16 6.44772 16 7C16 7.5523 15.5523 8 15 8H3.41421L7.70711 12.2929C8.0976 12.6834 8.0976 13.3166 7.70711 13.7071Z"
        fill="#2F52E9"
      />
    </svg>
  );
};

export default BackArrowIcon;
