import { Dispatch, SetStateAction } from "react";
import { Switch } from "@headlessui/react";
import clsx from "clsx";

type Props = {
  weeklySwitch: boolean;
  setWeeklySwitch: Dispatch<SetStateAction<boolean>>;
};

export const LearnerStatsWeeklySwitch: React.FC<Props> = ({
  weeklySwitch,
  setWeeklySwitch,
}) => {
  return (
    <Switch.Group>
      <div className="flex items-center gap-x-2">
        <Switch.Label
          passive
          className={clsx(
            "min-w-[30px] text-right text-xs text-dm-charcoal-600",
            !weeklySwitch && "font-bold"
          )}
          aria-label="To access daily data, press 'Off'"
        >
          Daily
        </Switch.Label>
        <Switch
          checked={weeklySwitch}
          onChange={setWeeklySwitch}
          className={`${
            weeklySwitch ? "bg-dm-brand-blue-800" : "bg-dm-brand-blue-500"
          } relative inline-flex h-6 w-11 shrink-0 items-center rounded-full`}
        >
          <span
            aria-hidden="true"
            className={`${
              weeklySwitch ? "translate-x-6" : "translate-x-1"
            } inline-block h-4 w-4 transform rounded-full bg-white transition`}
          />
        </Switch>
        <Switch.Label
          passive
          className={clsx(
            "min-w-[44px] text-left text-xs text-dm-charcoal-600",
            weeklySwitch && "font-bold"
          )}
          aria-label="To access weekly data, press 'On'"
        >
          Weekly
        </Switch.Label>
      </div>
    </Switch.Group>
  );
};
