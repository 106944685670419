import axios from "axios";
import { useState } from "react";
import { useMutation } from "react-query";
import { deltamathAPI } from "../../../manager/utils";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import Button from "../../../student/components/generic/button";
import {
  ConditionalPasswordErrorMessages,
  evaluatePassword,
} from "../../../utils";
import { SubmitHandler, useForm } from "react-hook-form";
import PasswordField from "./PasswordField";
import { Learner } from "../../types";
import { processPasswordForRequest } from "../../../utils/processPasswordForRequest";
import { useUpdatePassword } from "../../utils/useUpdatePassword";

type FormData = {
  currentPassword?: string;
  password: string;
  confirmPassword: string;
};

export const ChangePasswordForm: React.FC<{
  hideForm: () => void;
  user: Learner;
  showCurrentPasswordField: boolean;
  noPassword: boolean;
  updateUserUponAddPassword?: () => void;
}> = ({
  hideForm,
  user,
  showCurrentPasswordField,
  noPassword,
  updateUserUponAddPassword,
}) => {
  const toastContext = useDeltaToastContext();
  const {
    handleSubmit,
    register,
    getValues,
    formState: { errors, isValid },
  } = useForm<FormData>({ mode: "onChange" });

  const [nonFieldError, setNonFieldError] = useState<string | null>(null);

  const handleSuccess = (showCurrentPasswordField: boolean) => {
    const message = showCurrentPasswordField
      ? "You have successfully changed your password."
      : "You have successfully updated the password.";
    toastContext.addToast({
      message,
      status: "Success",
    });
    hideForm();
    updateUserUponAddPassword?.();
  };

  const handleError = (showCurrentPasswordField: boolean) => {
    const message = showCurrentPasswordField
      ? "An error occurred while changing your password. Please try again."
      : "An error occurred while updating the password. Please try again.";
    setNonFieldError(message);
  };

  const updatePassword = useUpdatePassword({
    successFunc: () => handleSuccess(showCurrentPasswordField),
    errorFunc: () => handleError(showCurrentPasswordField),
  });

  const updateLearnerPassword = useMutation(
    (body: { password: string; id: string }) => {
      return axios.put<{ status: "success" | "error" }>(
        `${deltamathAPI()}/learner/parent/update_learner_password`,
        JSON.stringify(body),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    },
    {
      onSuccess: (data) => {
        handleSuccess(showCurrentPasswordField);
      },
      onError: (e) => {
        handleError(showCurrentPasswordField);
      },
    }
  );

  const onSubmit: SubmitHandler<FormData> = (data) => {
    if (data.currentPassword) {
      const body = {
        password: processPasswordForRequest(data.password),
        currentPassword: processPasswordForRequest(data.currentPassword),
      };
      updatePassword.mutate(body);
    } else {
      const body = {
        password: processPasswordForRequest(data.password),
        id: user._id,
      };
      updateLearnerPassword.mutate(body);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col gap-4 rounded bg-dm-background-blue-100 p-4 sm:w-96"
    >
      {showCurrentPasswordField && (
        <PasswordField
          label="Current Password"
          aria-invalid={!!errors.currentPassword}
          error={errors.currentPassword?.message}
          {...register("currentPassword", {
            required: "Current password is required",
          })}
        />
      )}
      <div>
        <PasswordField
          label={noPassword ? "Create Learner Password" : "Create New Password"}
          aria-invalid={!!errors.password}
          error={
            errors.password &&
            errors.password.type !== "meetsPasswordRequirements"
              ? errors.password.message
              : undefined
          }
          {...register("password", {
            required: "Password is required",
            validate: {
              meetsPasswordRequirements: (v) =>
                // If invalid, return a random value so the error component re-renders
                !evaluatePassword(v) ? Math.random().toString() : true,
            },
          })}
        />
        {errors.password?.type === "meetsPasswordRequirements" && (
          <ConditionalPasswordErrorMessages
            password={getValues("password") || ""}
          />
        )}
      </div>
      <PasswordField
        label={noPassword ? "Confirm Learner Password" : "Confirm New Password"}
        aria-invalid={!!errors.confirmPassword}
        error={errors.confirmPassword?.message}
        {...register("confirmPassword", {
          required: "Confirm password is required",
          validate: {
            confirmMatch: (v) => {
              const { password } = getValues();
              return password === v || "Passwords do not match";
            },
          },
        })}
      />
      <Button
        className="w-full"
        disabled={!isValid || updatePassword.isLoading}
        submit
      >
        {noPassword ? "Create Password" : "Update Password"}
      </Button>
      <Button className="w-full !py-0" type="link" onClick={hideForm}>
        Cancel
      </Button>
      {nonFieldError && (
        <div className="text-dm-error-500">{nonFieldError}</div>
      )}
    </form>
  );
};
