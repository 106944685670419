import { Progress, Unit, LearnerAssignmentSkillData } from "../../types";
import IconAssignmentProgress from "../IconAssignmentProgress";
import Button from "../../../student/components/generic/button";
import AssignmentPreviewModal from "../Modals/AssignmentPreviewModal";
import { useState } from "react";
import TestConfirmationModal from "../Modals/TestConfirmationModal";
import { useLearnerContext } from "../../contexts/LearnerContext";
import { useNavigate } from "react-router-dom";
import { sum, clamp, findIndex } from "lodash";
import { PROGRESS_MINIMUM } from "../../constants";
import clsx from "clsx";
import { TooltipButton } from "../../../shared/TooltipButton";
import { REACT_APP_LEARNER_LINK } from "../../../utils";

type Props = {
  unit: Unit;
  progress: Progress | undefined;
  coursePath?: string;
  unitPath?: string;
  estimatedTime: number;
  isFirstAttemptComplete: boolean;
  isInProgress: boolean;
};

/** @deprecated To be replaced with TestCard if more work needs to be done */
const UnitTestCard = (props: Props) => {
  const learnerContext = useLearnerContext();
  const navigate = useNavigate();
  const baseUrl = `${REACT_APP_LEARNER_LINK}/${props.coursePath}/${props.unitPath}/unittest`;
  const [showAssignmentModal, setShowAssignmentModal] =
    useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);

  const unit = (props.progress?.units || []).find(
    (u) => u.unitId === props.unit.id
  );
  const allSubunitsProgress =
    unit?.subunits && unit.subunits.length > 0
      ? sum(unit.subunits.map((x) => x.progress)) / unit.subunits.length
      : 0;

  const progress =
    (!!unit?.unitTest && unit?.unitTest?.progress === 0
      ? 0.01
      : unit?.unitTest?.progress || 0) * 100;
  const maxGrade = Math.round((unit?.unitTest?.maxGrade || 0) * 100);

  const buttonText = unit?.unitTest?.submitted
    ? "Review Unit Test"
    : unit?.unitTest
    ? "Resume Unit Test"
    : "Open Unit Test";

  const initializeProgressForCourse = async () => {
    navigate(baseUrl);
  };

  const startTest = () => {
    if (allSubunitsProgress < PROGRESS_MINIMUM) {
      setShowConfirmationModal(true);
    } else {
      initializeProgressForCourse();
    }
  };

  const learnerDifficulty = learnerContext.getLearnerLevel();
  const flatSubunitProgress =
    props.progress?.units.flatMap((u) => u.subunits) || [];

  const skillsInUnit = (props.unit.subunits || []).flatMap((su) =>
    su.skills.filter((sk) => {
      const subunitProgress = flatSubunitProgress.find(
        (su) => su.subunitId === sk.subunitId
      );

      return (
        sk.difficulty === (subunitProgress?.difficulty || learnerDifficulty) ||
        sk.difficulty === "both"
      );
    })
  ).length;

  const skillNumbers =
    (unit?.unitTest?.assignment?.skills || []).length ||
    (skillsInUnit <= 20 ? skillsInUnit : 20);
  const skillsCompleted = (unit?.unitTest?.assignment?.skills || []).filter(
    (x) => x.skillComplete
  ).length;

  const skillsCount = unit?.unitTest?.assignment?.skills?.length || 0;
  const firstUnsolved = clamp(
    findIndex(
      unit?.unitTest?.assignment?.skills,
      (skill: LearnerAssignmentSkillData) => !skill.skillComplete
    ),
    0,
    skillsCount - 1
  );

  const primaryButtonUrl = unit?.unitTest
    ? unit?.unitTest?.submitted
      ? baseUrl
      : `${baseUrl}/${firstUnsolved}`
    : undefined;

  const unitTestButtons = (
    <>
      {unit?.unitTest?.submitted && (
        <Button
          href={`${baseUrl}?retake=true`}
          type="outline"
          size="small"
          className="max-md:basis-full"
        >
          Retake Unit Test
        </Button>
      )}
      <Button
        onClick={!unit?.unitTest ? startTest : undefined}
        href={primaryButtonUrl}
        type={
          unit?.unitTest || allSubunitsProgress >= PROGRESS_MINIMUM
            ? "primary"
            : "outline"
        }
        size="small"
        className="max-md:basis-full"
      >
        {buttonText}
      </Button>
    </>
  );

  const currDuration = unit?.unitTest?.assignment?.duration;
  const bestScoreTime = !props.isFirstAttemptComplete
    ? currDuration
    : unit?.unitTest?.assignment?.resultHist
        ?.filter(
          (attempt) =>
            attempt.duration !== undefined &&
            attempt.grade === unit?.unitTest?.maxGrade
        )
        .reduce(
          (acc, curr) =>
            acc < (curr.duration ?? 0) ? acc : curr.duration ?? 0,
          0
        ) || currDuration;

  const duration = bestScoreTime
    ? Math.max(1, Math.round(bestScoreTime / 60))
    : undefined;

  return (
    <div className="w-full rounded-lg border border-dm-charcoal-100 bg-white px-6 py-4">
      <div className="flex items-center justify-between gap-x-2">
        <div className="flex basis-full items-center gap-2">
          <IconAssignmentProgress
            type={"unitTest"}
            progress={progress}
            submitted={unit?.unitTest?.submitted !== undefined}
            maxGrade={maxGrade}
          />
          <h3 className="text-lg font-bold">
            <span className={clsx(!unit?.unitTest ? "mr-2" : "")}>
              {props.unit.name} Test{props.isInProgress && ": In Progress"}
            </span>
            {!unit?.unitTest && (
              <TooltipButton
                message="Preview Test Questions"
                onClick={() => setShowAssignmentModal(true)}
              >
                <i className="far fa-eye text-dm-gray-200" aria-hidden="true" />
              </TooltipButton>
            )}
          </h3>
        </div>
        <div className="flex basis-full gap-4 max-md:hidden md:flex-shrink-0 md:basis-auto">
          {unitTestButtons}
        </div>
      </div>
      <div className="my-4 flex gap-2 text-sm">
        {!props.isInProgress && !props.isFirstAttemptComplete ? (
          <>
            <p>{skillNumbers} Questions</p>
            {" | "}
            <p>{props.estimatedTime} Minutes (estimated)</p>
          </>
        ) : props.isInProgress ? (
          <p>
            {skillsCompleted}/{skillNumbers} Questions Answered
          </p>
        ) : (
          <>
            <p>Score: {maxGrade}%</p>
            {duration && (
              <>
                {" | "}
                <p>
                  {duration}
                  {duration === 1 ? " Minute" : " Minutes"} Spent
                </p>
              </>
            )}
          </>
        )}
      </div>
      <div className="flex basis-full flex-wrap gap-2 md:hidden">
        {unitTestButtons}
      </div>
      <AssignmentPreviewModal
        title="Unit Test Preview"
        courseId={props.unit.courseId}
        unitId={props.unit.id}
        type="unitTest"
        visible={showAssignmentModal}
        toggleVisible={setShowAssignmentModal}
        navigateButtonText="Go To Unit Test"
        navigateHref={baseUrl}
      />
      <TestConfirmationModal
        visible={showConfirmationModal}
        toggleVisible={setShowConfirmationModal}
        onConfirm={initializeProgressForCourse}
        confirmButtonText="Open Unit Test"
        closeButtonText="Back To Unit Sections"
        type="unitTest"
      />
    </div>
  );
};

export default UnitTestCard;
