// import { useState } from "react";
import clsx from "clsx";
import DonutChart from "../../student/components/DonutChart";

type Props = {
  isActive?: boolean;
  extraClasses?: string;
  showThumbsUp?: boolean;
  score: number;
  required: number;
  record: number;
};

export default function IconProgress({
  isActive = false,
  extraClasses = "",
  showThumbsUp = true,
  score,
  required,
  record,
}: Props): JSX.Element {
  // const [textforTooltip, setTextForTooltip] = useState<string>("");

  if (required === 0 && showThumbsUp) {
    return (
      <div data-for="icontip" data-tip="Optional">
        <i
          className="far fa-thumbs-up fa-fw text-xl leading-none !text-dm-success-500"
          role="img"
          aria-label="Skill is optional."
        ></i>
      </div>
    );
  }

  return (
    <>
      <div
        className={clsx(
          "relative shrink-0 text-center !leading-none",
          "h-5 w-6",
          extraClasses
        )}
        data-for="icontip"
        data-tip={
          !record || (required && record < required) ? "Required" : "Complete"
        }
      >
        {!record || (required && record < required) ? (
          <DonutChart
            isMaxOne={false}
            progress={{ total: required, score: score }}
            isActive={isActive}
            altClasses="pointer-events-auto absolute h-5 w-6"
          />
        ) : (
          <i
            className="far fa-check text-xl leading-none !text-dm-success-500"
            aria-hidden="true"
          ></i>
        )}
        <span className="sr-only">
          {record} out of {required} questions completed.
        </span>
      </div>
    </>
  );
}
