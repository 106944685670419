import { capitalize } from "lodash";
import DeltaMathTable from "../../../shared/DeltaMathTable";
import clsx from "clsx";
import { LearnerSubscriptionPlan } from "../../../learner/types";
import { BasicContentContainer } from "../BasicContentContainer";

function formatDate(date: string): string {
  return Intl.DateTimeFormat("en-US", {
    dateStyle: "medium",
    timeStyle: "short",
  }).format(new Date(date));
}

export const SubscriptionPlansTable: React.FC<{
  plans: LearnerSubscriptionPlan[];
}> = ({ plans }) => {
  if (plans.length === 0) {
    return (
      <BasicContentContainer>
        <p>
          There are no plans yet. Create a new draft subscription plan above.
        </p>
      </BasicContentContainer>
    );
  }
  return (
    <DeltaMathTable
      data={plans}
      columns={[
        {
          Header: "State",
          accessor: "state",
          ...{ align: "left" },
          Cell: ({ value }) => {
            const bgColor =
              value === "draft"
                ? "bg-yellow-500"
                : value === "published"
                ? "bg-green-700"
                : "bg-gray-400";
            return (
              <span className={clsx("rounded px-2 py-1 text-white", bgColor)}>
                {capitalize(value)}
              </span>
            );
          },
        },
        {
          Header: "Interval",
          accessor: "interval",
          Cell: ({ value }) => capitalize(value),
        },
        {
          Header: "Price, first learner",
          accessor: "learnerPrice",
          ...{ align: "right" },
          Cell: ({ value }) => `$ ${value}`,
        },
        {
          Header: "Price, additional learners",
          accessor: "additionalLearnerPrice",
          ...{ align: "right" },
          Cell: ({ value }) => `$ ${value}`,
        },
        {
          Header: "Trial length",
          accessor: "trialLength",
          ...{ align: "left" },
          Cell: ({ value }) => (value === 1 ? "1 day" : `${value} days`),
        },
        {
          Header: "Stripe product",
          accessor: "stripeProductId",
          ...{ align: "left" },
          Cell: ({ value }) => (
            <a
              className="text-blue-500 underline hover:no-underline"
              href={
                process.env.NODE_ENV === "production"
                  ? `https://dashboard.stripe.com/products/${value}`
                  : `https://dashboard.stripe.com/test/products/${value}`
              }
              target="_blank"
              rel="noreferrer"
            >
              {value}
            </a>
          ),
        },
        {
          Header: "Stripe plan",
          accessor: "stripePlanId",
          ...{ align: "left" },
          Cell: ({ value }) => (
            <a
              className="text-blue-500 underline hover:no-underline"
              href={
                process.env.NODE_ENV === "production"
                  ? `https://dashboard.stripe.com/prices/${value}`
                  : `https://dashboard.stripe.com/test/prices/${value}`
              }
              target="_blank"
              rel="noreferrer"
            >
              {value}
            </a>
          ),
        },
        {
          Header: "Created",
          accessor: "createdAt",
          ...{ align: "left" },
          Cell: ({ value }) => formatDate(value),
        },
        {
          Header: "Published",
          accessor: "publishedAt",
          ...{ align: "left" },
          Cell: ({ value }) => (value ? formatDate(value) : "-"),
        },
        {
          Header: "Archived",
          accessor: "archivedAt",
          ...{ align: "left" },
          Cell: ({ value }) => (value ? formatDate(value) : "-"),
        },
      ]}
    />
  );
};
