import { Learner, LearnerAccountStatus } from "../../types";
import { isSubscriptionActive } from "../../utils/isSubscriptionActive";

export function getLearnerAccountStatus(
  learner: Pick<
    Learner,
    | "subscriptionExpiration"
    | "subscriptionWillCancel"
    | "inviteCode"
    | "inviteAcceptedAt"
  >
): LearnerAccountStatus {
  if (!isSubscriptionActive(learner)) {
    return "inactive";
  }
  if (learner.subscriptionWillCancel) {
    return "canceled";
  }
  if (learner.inviteCode && !learner.inviteAcceptedAt) {
    return "pending";
  }
  return "active";
}
