import { useCallback, useEffect, useState } from "react";
import {
  TIMER_LOCALSTORAGE_KEY_PREFIX,
  TIMER_LOCALSTORAGE_KEY_VERSION,
  TIMER_LOCALSTORAGE_ROOT_KEY,
} from "../constants";

const INTERVAL_TIME = 500;
const LOCALSTORAGE_CLEAN_INTERVAL = 24 * 60 * 60 * 1000; // 1 day
const LOCALSTORAGE_EXPIRATION = 7 * 24 * 60 * 60 * 1000; // 7 days
const LOCALSTORAGE_LAST_CLEANED_KEY = `${TIMER_LOCALSTORAGE_ROOT_KEY}:meta`;

// Set this to true to turn on timer logging
const DEBUG_LOGGING_ENABLED = false;

interface TimerStoragePayload {
  elapsed: number;
  updated: number;
}

function timerLog(msg: string, ...args: any[]) {
  if (DEBUG_LOGGING_ENABLED) {
    console.log(`%c${msg}`, "color: seagreen", ...args);
  }
}

function validatePayload(payload: any): payload is TimerStoragePayload {
  return (
    typeof payload.elapsed === "number" && typeof payload.updated === "number"
  );
}

function getLocalStorgeKey(baseKey: string): string {
  return `${TIMER_LOCALSTORAGE_ROOT_KEY}:${baseKey}`;
}

function readFromLocalStorage(key: string): TimerStoragePayload | null {
  try {
    const value = localStorage.getItem(getLocalStorgeKey(key));
    if (value !== null) {
      try {
        const payload = JSON.parse(value);
        if (validatePayload(payload)) {
          return payload;
        }
      } catch (e) {
        // ignore
      }
    }
  } catch (e) {
    // ignore
  }
  return null;
}

function removeFromLocalStorage(key: string) {
  try {
    localStorage.removeItem(getLocalStorgeKey(key));
  } catch (e) {
    // ignore
  }
}

function saveToLocalStorage(key: string, value: number) {
  try {
    const payload: TimerStoragePayload = {
      elapsed: value,
      updated: Date.now(),
    };
    localStorage.setItem(getLocalStorgeKey(key), JSON.stringify(payload));
  } catch (e) {
    // ignore
  }
}

type CleanReason =
  | "unsupported_version"
  | "invalid_data"
  | "expired"
  | "json_error";

function cleanLocalStorage() {
  const now = Date.now();
  const lastCleanedStr =
    localStorage.getItem(LOCALSTORAGE_LAST_CLEANED_KEY) || "0";
  const lastCleaned = parseInt(lastCleanedStr, 10);
  if (now - LOCALSTORAGE_CLEAN_INTERVAL > lastCleaned) {
    const removedKeys: [string, CleanReason][] = [];

    Object.entries(localStorage).forEach(([key, value]) => {
      if (
        key.startsWith(TIMER_LOCALSTORAGE_KEY_PREFIX) &&
        key !== LOCALSTORAGE_LAST_CLEANED_KEY
      ) {
        const [, versionStr] = key.split(":");
        if (versionStr !== `v${TIMER_LOCALSTORAGE_KEY_VERSION}`) {
          removedKeys.push([key, "unsupported_version"]);
          return;
        }
        try {
          const payload = JSON.parse(value);
          if (!validatePayload(payload)) {
            removedKeys.push([key, "invalid_data"]);
            return;
          }
          if (now - LOCALSTORAGE_EXPIRATION > payload.updated) {
            removedKeys.push([key, "expired"]);
            return;
          }
        } catch (e) {
          removedKeys.push([key, "json_error"]);
        }
      }
    });

    removedKeys.forEach(([k]) => {
      try {
        localStorage.removeItem(k);
      } catch (e) {
        // ignore
      }
    });

    timerLog("CLEANED LOCALSTORAGE", removedKeys);
    localStorage.setItem(LOCALSTORAGE_LAST_CLEANED_KEY, now.toString());
  }
}

export interface ComponentTimer {
  /** The number of milliseconds the user has viewed the component */
  elapsed: number;
  /** Set the elapsed time to 0 */
  resetTimer: () => void;
  /** Restart the timer if it has been stopped. Note: will not reset a disabled timer! */
  restartTimer: () => void;
  /** Stop the timer and remove the key from `localStorage` */
  clearTimer: () => void;
  /** True if the timer is currently running */
  isActive: boolean;
}

/**
 * Hook to manage a timer. The timer tracks elapsed time while the parent
 * component is rendered on the page. The timer pauses if the user switches
 * focus outside of the window or to another tab. The time is stored in
 * `localStorage` per a `localstorageKey`, and timing resumes if there's
 * an existing value for the key.
 */
export function useComponentTimer(
  localstorageKey: string,
  disabled = false,
  maxElapsedTime = Infinity
): ComponentTimer {
  const initializeElapsed = useCallback(
    () => readFromLocalStorage(localstorageKey)?.elapsed ?? 0,
    [localstorageKey]
  );
  const [elapsed, setElapsed] = useState(initializeElapsed);
  const [lastTs, setLastTs] = useState<number>(() => Date.now());
  const [isActive, setIsActive] = useState(true);

  const reset = () => {
    timerLog("RESET");
    setElapsed(0);
    setLastTs(Date.now());
  };

  const restart = useCallback(() => {
    timerLog("RESTART", localstorageKey, initializeElapsed());
    setElapsed(initializeElapsed());
    setLastTs(Date.now());
    setIsActive(true);
  }, [initializeElapsed, localstorageKey]);

  const handleFocus = useCallback(() => restart(), [restart]);
  const handleBlur = useCallback(() => setIsActive(false), []);

  cleanLocalStorage();

  // Log when starting/stopping
  useEffect(() => {
    if (isActive && disabled) {
      timerLog("NOT STARTED (DISABLED)");
    } else if (isActive) {
      timerLog("STARTED");
    } else {
      timerLog("STOPPED");
    }
  }, [isActive, disabled]);

  useEffect(() => {
    timerLog("DISABLED", disabled);
  }, [disabled]);

  // Handle window/blur events
  useEffect(() => {
    window.addEventListener("focus", handleFocus);
    window.addEventListener("blur", handleBlur);
    return () => {
      window.removeEventListener("focus", handleFocus);
      window.removeEventListener("blur", handleBlur);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elapsed]);

  // Reset everything when the localstorage key changes
  useEffect(() => {
    restart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localstorageKey]);

  // Timer and state management
  useEffect(() => {
    if (!isActive || disabled) {
      return;
    }
    if (elapsed > maxElapsedTime) {
      timerLog("MAX REACHED");
      setIsActive(false);
      return;
    }
    const timeout = setTimeout(() => {
      const nextTs = Date.now();
      const nextElapsed = elapsed + (nextTs - lastTs);
      setLastTs(nextTs);
      setElapsed(nextElapsed);
      saveToLocalStorage(localstorageKey, nextElapsed);
      timerLog("--", nextElapsed);
    }, INTERVAL_TIME);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive, lastTs, localstorageKey, elapsed]);

  return {
    elapsed: Math.min(elapsed, maxElapsedTime),
    isActive,
    resetTimer: () => reset(),
    restartTimer: () => restart(),
    clearTimer: () => {
      timerLog("CLEAR");
      setIsActive(false);
      removeFromLocalStorage(localstorageKey);
    },
  };
}
