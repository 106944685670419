import Line from "../../../student/components/standard-problems/Line";
import HiddenLines from "../../../student/components/standard-problems/HiddenLines";

type Props = {
  displayData: any;
  problemData: any;
  resizingData?: any;
  locString?: string;
};

const RenderLines = (props: Props) => {
  if (!props.displayData) {
    return <></>;
  }

  const groupedDisplayData: Array<Array<any>> = [];
  let prevHidden = false;
  props.displayData.forEach((line: any, index: number) => {
    const currHidden = line.format?.hidden ? true : false;
    if (prevHidden && currHidden) {
      groupedDisplayData[groupedDisplayData.length - 1]?.push({
        index,
        line,
        currHidden,
      });
    } else {
      groupedDisplayData.push([{ index, line, currHidden }]);
    }
    prevHidden = currHidden;
  });

  return (
    <div className="relative pt-8">
      {groupedDisplayData.map((lineChunk, index: number) =>
        lineChunk[0].currHidden === false ? (
          <Line
            key={"line" + index}
            lineData={lineChunk[0]}
            problemData={props.problemData}
            resizingData={props.resizingData}
            locString={props.locString}
          />
        ) : (
          <HiddenLines
            key={"line_group" + index}
            lineDataArray={lineChunk}
            problemData={props.problemData}
            resizingData={props.resizingData}
            locString={props.locString}
          />
        )
      )}
    </div>
  );
};

export default RenderLines;
