import React, {
  useState,
  Dispatch,
  SetStateAction,
  useLayoutEffect,
} from "react";
import axios from "axios";
import { useMutation } from "react-query";
import { useScrollLock } from "usehooks-ts";
import { useDeltaToastContext } from "../../shared/contexts/ToasterContext";
import { useDMQuery } from "../../utils";
import { UPGRADE_FEATURES, UPGRADE_PRICES } from "../constants";
import { CheckoutStatus } from "../types";
import VideoModal from "../../learner/components/Modals/VideoModal";
import FeatureBlock from "../components/FeatureBlock";
import { EXPIRATION_OCTOBER_2025, deltamathAPI } from "../../manager/utils";
import { DmLoadingSpinner } from "../../manager/utils/functions";
import { getFilePath } from "../../utils";
import clsx from "clsx";

const FeatureImage = ({
  image,
  title,
  imageFirst,
}: {
  image: string;
  title: string;
  imageFirst?: boolean;
}) => {
  return (
    <img
      className={clsx(
        "shrink-0 rounded-lg border border-slate-200 object-cover object-top max-md:max-h-96 max-md:w-full max-sm:max-h-72 md:h-60 md:w-[464px] lg:h-72 lg:w-[556px]",
        !imageFirst && "md:order-1"
      )}
      src={image}
      alt={`Screenshot of ${title}`}
    />
  );
};

const Feature = ({
  title,
  description,
  image,
  plus,
  integral,
  videoSlug,
  videoTime,
  helpCenterURL,
  imageFirst,
  setVideoFile,
  setShowVideo,
}: {
  title: string;
  description: JSX.Element;
  image?: string;
  plus: boolean;
  integral: boolean;
  videoSlug?: string;
  videoTime?: string;
  helpCenterURL: string;
  imageFirst?: boolean;
  setVideoFile: Dispatch<SetStateAction<string>>;
  setShowVideo: Dispatch<SetStateAction<boolean>>;
}) => {
  return (
    <div className="flex flex-col items-start justify-start gap-6 pb-8 text-dm-charcoal-800">
      <div className="flex items-center justify-start gap-4">
        <h3 className="font-serif text-lg font-bold">{title}</h3>
        {plus && (
          <h4 className="rounded-sm bg-dm-brand-blue-200 px-2 font-sans text-sm leading-7">
            PLUS
          </h4>
        )}
        {integral && (
          <h4 className="rounded-sm bg-dm-earth-yellow-500 px-2 font-sans text-sm leading-7">
            {!plus ? "INTEGRAL ONLY" : "INTEGRAL"}
          </h4>
        )}
      </div>

      <div className="flex flex-col items-start justify-start gap-6 md:flex-row">
        {image ? (
          <FeatureImage image={image} title={title} imageFirst={imageFirst} />
        ) : null}
        <div className="flex flex-col items-start justify-start gap-4">
          <p className="font-sans text-base font-normal">{description}</p>
          <div className="flex flex-col justify-start gap-x-6 gap-y-2 sm:flex-row sm:items-center">
            {videoSlug && (
              <button
                className="group inline-flex items-center justify-start gap-2 font-sans text-base font-bold text-dm-brand-blue-500"
                onClick={() => {
                  setVideoFile(videoSlug);
                  setShowVideo(true);
                }}
              >
                <i className="fal fa-play-circle"></i>
                <span className="text-left leading-snug group-hover:underline">
                  See How It Works
                </span>
                {videoTime && (
                  <span className="text-dm-brand-blue-200">({videoTime})</span>
                )}
              </button>
            )}
            {videoSlug && helpCenterURL ? (
              <div className="h-8 border-r border-dm-charcoal-200 leading-8 max-sm:hidden"></div>
            ) : null}
            {helpCenterURL && (
              <a
                className="group inline-flex items-center justify-start gap-2 font-sans text-base font-bold text-dm-brand-blue-500"
                href={helpCenterURL}
                target="_blank"
                rel="noreferrer"
              >
                <i className="far fa-external-link"></i>
                <span className="text-left leading-snug group-hover:underline">
                  Learn More in our Help Center
                </span>
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const PlusFeatures: React.FC = () => {
  const toastContext = useDeltaToastContext();
  const { lock: lockScreen, unlock: unlockScreen } = useScrollLock({
    autoLock: false,
  });
  const { data: accountInfoData } = useDMQuery({
    path: "/teacher/account/info",
    queryOptions: {
      refetchOnReconnect: true,
    },
  });

  //TODO: checking if page is embedded in Angular iframe
  const browserWindow = window.self === window.top ? window : window.parent;

  const [showSpinner, setShowSpinner] = useState<boolean>(false);

  useLayoutEffect(() => {
    if (showSpinner) {
      lockScreen();
    } else {
      unlockScreen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSpinner]);

  const { mutate: checkoutStatusMutate } = useMutation(
    () => {
      return axios.post<CheckoutStatus>(
        `${deltamathAPI()}/payments/checkout/status`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    },
    {
      onSuccess(data) {
        if (data.data.kind === "success") {
          setIsWaitingForStripe(false);
          setShowSpinner(false);
          toastContext.addToast({
            title: "Success!",
            message: `Your payment was successful. You now have access to DeltaMath ${
              data.data.hasIntegral ? "INTEGRAL" : "PLUS"
            }.`,
            status: "Success",
          });
        } else if (data.data.kind === "error") {
          setIsWaitingForStripe(false);
          setShowSpinner(false);
          toastContext.addToast({
            title: "Error",
            message: "There was an error with processing your payment.",
            status: "Error",
            dismiss: "manual",
          });
        } else {
          setShowSpinner(true);
          setTimeout(() => {
            checkoutStatusMutate();
          }, 1000);
        }
      },
    }
  );

  const [isWaitingForStripe, setIsWaitingForStripe] = useState<boolean>(
    browserWindow.location.search.includes("paid")
  );

  useLayoutEffect(() => {
    if (isWaitingForStripe) {
      checkoutStatusMutate();
      browserWindow.history.replaceState(
        null,
        "",
        browserWindow.location.href.split("?")[0]
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWaitingForStripe]);

  const PRORATED_DISCOUNT = 0; //TODO: will eventually come from the backend

  const expirationPretty = accountInfoData?.license?.expires
    ? new Date(accountInfoData.license.expires * 1000).toLocaleDateString(
        "en-US",
        {
          dateStyle: "medium",
        }
      )
    : undefined;

  const isExpired =
    accountInfoData?.license?.expires !== undefined
      ? accountInfoData.license.expires * 1000 - new Date().getTime() < 0
      : undefined;

  const subscriptionType: "none" | "school" | "individual" | undefined =
    accountInfoData?.license?.operative_subscription_type;

  // show both purchase options
  const showBothUpgrades =
    accountInfoData?.license?.has_longest_license === false;

  // show only Integral upgrade purchase option
  const showIntegralUpgrade =
    subscriptionType === "individual" &&
    accountInfoData?.license?.has_longest_license === true &&
    accountInfoData?.license?.plus_active === true &&
    accountInfoData?.license?.integral_active === false;

  const hideAllUpgrades =
    subscriptionType === "school" &&
    accountInfoData?.license?.plus_active === true &&
    !accountInfoData?.license?.non_renewal_confirmed;

  const isExtendCopy =
    accountInfoData?.license?.has_longest_license === false &&
    accountInfoData?.license?.plus_active === true;

  const [isFeatureListOpen, setIsFeatureListOpen] = useState<boolean>(false);
  const [showVideo, setShowVideo] = useState<boolean>(false);
  const [videoFile, setVideoFile] = useState<string>("");

  //TODO: added max width, top and side padding to main div
  //      b/c this will be shown in an Angular iframe.
  //
  //      Remove when not using Angular anymore.
  return (
    <>
      <div className="m-auto h-auto w-full max-w-[1280px] px-4 pt-4 md:px-8 md:pt-12">
        <div className="inline-flex w-full items-start justify-start gap-2.5 rounded-lg bg-blue-100 p-10">
          <div className="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-2">
            <div className="font-serif text-2xl font-bold leading-10 text-neutral-900">
              Your Subscription
            </div>
            {accountInfoData && (
              <div className="font-sans text-base font-normal text-neutral-700">
                {accountInfoData?.license?.plus_active === false && (
                  <p>
                    You have a free DeltaMath account. Upgrade below for more
                    exclusive features to DeltaMath!
                  </p>
                )}
                {subscriptionType === "individual" &&
                  accountInfoData?.license?.expires !== 0 &&
                  isExpired && (
                    <p className="pt-2">
                      Your individual{" "}
                      <strong>
                        {accountInfoData?.teacher?.has_integral === true
                          ? "INTEGRAL"
                          : "PLUS"}
                      </strong>{" "}
                      subscription expired on{" "}
                      <span className="font-bold">{expirationPretty}</span>
                    </p>
                  )}
                {subscriptionType === "school" &&
                  accountInfoData?.license?.expires !== 0 &&
                  isExpired && (
                    <p className="pt-2">
                      Your site license expired on{" "}
                      <span className="font-bold">{expirationPretty}</span>
                    </p>
                  )}
                {accountInfoData.license.plus_active === true && (
                  <>
                    {accountInfoData.license.integral_active === false && (
                      <span className="rounded-sm bg-dm-brand-blue-200 px-2 py-0.5 font-sans text-lg tracking-wide text-black">
                        <strong>Delta</strong>Math PLUS
                      </span>
                    )}

                    {accountInfoData.license.integral_active === true && (
                      <span className="rounded-sm bg-dm-earth-yellow-500 px-2 py-0.5 font-sans text-lg tracking-wide text-black">
                        <strong>Delta</strong>Math INTEGRAL
                      </span>
                    )}

                    {expirationPretty && (
                      <p className="pt-2">
                        You have an active{" "}
                        {subscriptionType === "individual"
                          ? "individual subscription "
                          : subscriptionType === "school"
                          ? "site license "
                          : "subscription "}
                        until{" "}
                        <span className="font-bold">{expirationPretty}</span>
                      </p>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        </div>

        {!hideAllUpgrades && (showBothUpgrades || showIntegralUpgrade) ? (
          <>
            <div className="mb-6 mt-12 text-center font-serif text-2xl font-bold leading-10 text-neutral-900">
              {isExtendCopy ? "Extend " : "Upgrade "}Your Subscription Today
              {PRORATED_DISCOUNT > 0 && (
                <>
                  <div className="py-1 text-center font-sans text-base font-normal">
                    <span className="text-dm-gray-600">
                      The prices below reflect a prorated{" "}
                    </span>
                    <span className="font-bold text-dm-success-500 underline">
                      {PRORATED_DISCOUNT}% discount
                    </span>
                  </div>
                </>
              )}
            </div>

            <div
              className={clsx(
                showBothUpgrades && "m-auto grid gap-6 md:grid-cols-2"
              )}
            >
              {showBothUpgrades ? (
                <FeatureBlock
                  type="plus"
                  fullPrice={UPGRADE_PRICES.PLUS}
                  expiration={EXPIRATION_OCTOBER_2025}
                  isOpen={isFeatureListOpen}
                  setIsOpen={setIsFeatureListOpen}
                  productKey="oct2025_plus"
                />
              ) : null}
              <FeatureBlock
                type="integral"
                fullPrice={
                  showIntegralUpgrade
                    ? UPGRADE_PRICES.INTEGRAL - UPGRADE_PRICES.PLUS
                    : UPGRADE_PRICES.INTEGRAL
                }
                expiration={EXPIRATION_OCTOBER_2025}
                twoColumnDisplay={showIntegralUpgrade}
                isOpen={isFeatureListOpen}
                setIsOpen={setIsFeatureListOpen}
                productKey={
                  showIntegralUpgrade
                    ? "oct2025_integral_upgrade"
                    : "oct2025_integral"
                }
              />
            </div>

            <p className="pb-6 pt-4 text-center font-sans text-xs font-normal leading-none text-neutral-700">
              All payments are processed securely with{" "}
              <img
                className="inline"
                width="40"
                src={getFilePath("/images/stripelogo.svg")}
                alt="Stripe Logo"
              />
            </p>
          </>
        ) : null}

        <div className="mt-1.5 text-center">
          <div className="inline-flex items-start justify-start gap-4">
            <div className="flex items-center justify-center gap-2 border-r border-slate-400 pl-2 pr-4">
              <a
                className="font-sans text-sm font-normal leading-tight text-dm-brand-blue-500"
                href="/contact"
                target="_blank"
              >
                Contact Us
              </a>
            </div>
            <a
              className="font-sans text-sm font-normal leading-tight text-dm-brand-blue-500"
              href="/teachers-schools?createQuote=true"
              target="_blank"
            >
              Get a Quote for Your School/District
            </a>
          </div>
        </div>

        <div className="mt-24 inline-flex w-full flex-col items-center justify-start gap-12">
          <div className="font-serif text-2xl font-bold leading-10">
            Plus & Integral Features We Love{" "}
          </div>
          <div className="flex flex-col items-start justify-start gap-10">
            {UPGRADE_FEATURES.map((feature) => (
              <Feature
                key={feature.title}
                title={feature.title}
                description={feature.description}
                image={feature.image}
                plus={feature.plus}
                integral={feature.integral}
                videoSlug={feature.videoSlug || ""}
                videoTime={feature.videoTime || ""}
                helpCenterURL={feature.helpCenterURL || ""}
                imageFirst={feature.imageFirst || false}
                setVideoFile={setVideoFile}
                setShowVideo={setShowVideo}
              />
            ))}
          </div>
        </div>
      </div>
      <VideoModal
        visible={showVideo}
        onClose={() => setShowVideo(false)}
        skillCode={videoFile}
        public={true}
      />
      {showSpinner && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <DmLoadingSpinner
            className="pointer-events-none select-none rounded-lg border border-transparent bg-white px-2 py-1 text-sm font-medium text-black shadow-sm"
            spinnerColor="text-black"
            message="Processing Payment..."
          />
        </div>
      )}
    </>
  );
};

export default PlusFeatures;
