import { useState } from "react";
import { Problem } from "../../types";
import clsx from "clsx";
import { displayInlineMath } from "../../../student/utils";
import InputFields from "./InputFields";
import CommonButton from "./CommonButton";
import { computeAlertMessage } from "../../../student/components/answerForm/SimpleAnswer";
import Modal from "../../../student/components/generic/Modal";

type Props = {
  problem: Problem;
  problemData: any;
  handleSubmit: (answers: string[] | object) => void;
  isCheckAnswerLoading: boolean;
};

const DefaultAnswer = (props: Props) => {
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [showAlert, setShowAlert] = useState<boolean>(false);

  const warnings = {
    ...props.problem?.warnings,
    simplifyRadicalWarning: props.problem?.data?.simplifyRadicalWarning,
    studentWarningFunction: props.problem?.data?.studentWarningFunction,
  };

  const submit = (answers: string[] | object) => {
    const alert = computeAlertMessage(
      answers as string[],
      warnings,
      props.problemData
    );

    if (alert) {
      setAlertMessage(alert);
      setShowAlert(true);
      return;
    }

    props.handleSubmit(answers);
  };

  return (
    <div>
      {
        <Modal
          visible={showAlert}
          onClose={() => setShowAlert(false)}
          title="Alert"
          body={alertMessage}
          noLine
          confirmButtonText="Okay"
          onConfirm={() => setShowAlert(false)}
          renderMath
        />
      }
      <div
        id="answer-block"
        className="flex flex-row flex-wrap items-center gap-x-4 gap-y-2"
      >
        {props.problemData.setNotation ? (
          <div className={clsx("text-xl")}>{displayInlineMath("\\{")}</div>
        ) : null}
        <InputFields
          problemData={props.problemData}
          handleSubmit={submit}
          isCheckAnswerLoading={props.isCheckAnswerLoading}
        />
        {props.problemData.setNotation ? (
          <div className={clsx("text-xl")}>{displayInlineMath("\\}")}</div>
        ) : null}
      </div>
      {props.problemData.commonButtons !== undefined ? (
        <div className="mx-4 my-2 flex flex-row flex-wrap items-center gap-x-4 gap-y-2">
          {props.problemData.commonButtons.map((btn: any, index: number) => (
            <CommonButton
              key={index}
              buttonText={btn[0] as string}
              buttonCmd={btn[1] ? (btn[1] as boolean) : undefined}
              buttonOutput={btn[2] ? (btn[2] as string) : undefined}
            />
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default DefaultAnswer;
