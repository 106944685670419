type SubmitButtonProps = {
  handleSubmit: () => void;
};

export default function SubmitButton({
  handleSubmit,
}: SubmitButtonProps): JSX.Element {
  return (
    <>
      <button
        type="button"
        id="submit-answer-form"
        className="submit-answer-form m-1 inline-flex items-center rounded bg-dm-brand-blue-500 px-4 py-2 text-sm font-medium text-white hover:bg-dm-brand-blue-600 active:bg-dm-brand-blue-800"
        onClick={handleSubmit}
      >
        Submit Answer
      </button>
    </>
  );
}
