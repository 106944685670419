import { useState } from "react";
import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import { deltamathAPI } from "../../manager/utils";
import { useDeltaToastContext } from "../../shared/contexts/ToasterContext";
import { getAdminParams } from "../../admin/utils";
import { useUserContext } from "../../shared/contexts/UserContext";

export default function AddSchool({ schoolRefetch }: { schoolRefetch: any }) {
  const userContext = useUserContext();
  const token = userContext.getJWT();
  const adminParams = getAdminParams();
  const queryClient = useQueryClient();
  const toastContext = useDeltaToastContext();
  const [schoolName, setSchoolName] = useState("");
  const [lowGrade, setLowGrade] = useState("PK");
  const [highGrade, setHighGrade] = useState("12");

  const addSchool = useMutation(
    (body: {
      schoolName: string;
      lowGrade: string;
      highGrade: string;
      customer_service_token: string;
      districtID: string;
    }) => {
      return axios.post(
        deltamathAPI() + "/admin_new/license/add_school_to_district",
        body,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    {
      onSuccess() {
        setSchoolName("");
        setLowGrade("PK");
        setHighGrade("12");
        toastContext.addToast({
          status: "Success",
          message: "School successfully added",
        });
        queryClient.invalidateQueries("/admin_new/data/school");
        queryClient.invalidateQueries("/admin_new/data/district");
        schoolRefetch();
      },
      onError() {
        toastContext.addToast({
          status: "Error",
          message: "There was an error.",
        });
      },
    }
  );

  return (
    <div className="m-8 flex flex-col space-y-4 p-4">
      <h2 className="text-2xl font-medium leading-6 text-gray-900">
        Add School
      </h2>
      <p className="mt-1 text-sm text-gray-500"></p>
      <div className="max-w-[500px]">
        <label
          htmlFor="email"
          className="block text-sm font-medium text-gray-700"
        >
          School Name
        </label>
        <div className="mt-1">
          <input
            type="text"
            name="name"
            id="name"
            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            onChange={(e) => {
              setSchoolName(e.target.value);
            }}
            value={schoolName}
          />
        </div>
      </div>

      <div className="max-w-[100px]">
        <label
          htmlFor="location"
          className="block text-sm font-medium text-gray-700"
        >
          Low Grade
        </label>
        <select
          id="location"
          name="location"
          className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          onChange={(e) => {
            setLowGrade(e.target.value);
          }}
          value={lowGrade}
        >
          <option>PK</option>
          <option>K</option>
          <option>1</option>
          <option>2</option>
          <option>3</option>
          <option>4</option>
          <option>5</option>
          <option>6</option>
          <option>7</option>
          <option>8</option>
          <option>9</option>
          <option>10</option>
          <option>11</option>
          <option>12</option>
        </select>
      </div>
      <div className="max-w-[100px]">
        <label
          htmlFor="highGrade"
          className="block text-sm font-medium text-gray-700"
        >
          High Grade
        </label>

        <select
          id="highGrade"
          name="highGrade"
          className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          onChange={(e) => {
            setHighGrade(e.target.value);
          }}
          value={highGrade}
        >
          <option>PK</option>
          <option>K</option>
          <option>1</option>
          <option>2</option>
          <option>3</option>
          <option>4</option>
          <option>5</option>
          <option>6</option>
          <option>7</option>
          <option>8</option>
          <option>9</option>
          <option>10</option>
          <option>11</option>
          <option>12</option>
        </select>
      </div>
      <div className="w-lg">
        <button
          type="submit"
          onClick={() => {
            addSchool.mutate({
              schoolName,
              lowGrade,
              highGrade,
              customer_service_token:
                localStorage.getItem("customer_service_token") || "",
              districtID: adminParams.districtID,
            });
          }}
          className="w-lg justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          Add School
        </button>
      </div>
    </div>
  );
}
