/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import { useState, useEffect, useRef } from "react";
import { useResizeObserver } from "usehooks-ts";
import ReactTooltip from "react-tooltip";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/outline";
import { format, parse, isSameMonth, isFuture } from "date-fns/esm";
import clsx from "clsx";
import {
  LearnerStatsKind,
  LearnerStatsQuestionDataResponse,
} from "../../types";
import { sum } from "lodash";
import { unreachableCase } from "../../../utils";

const statsLegend = (kind: LearnerStatsKind, isWeekly: boolean) => {
  return (
    <section
      className="mb-9 flex flex-col items-start gap-x-6 gap-y-2 text-sm sm:flex-row"
      aria-hidden={true}
    >
      <aside className="inline-flex h-4 items-center gap-x-2 text-sm">
        <div
          className={clsx(
            "inline-block h-full w-6 rounded-sm",
            kind === "time" &&
              "bg-gradient-to-t from-dm-warning-800 to-dm-warning-500",
            kind === "points" &&
              "bg-gradient-to-t from-dm-purple-500 to-dm-purple-200",
            kind === "questions" && "bg-dm-brand-blue-500"
          )}
        ></div>
        <span>
          {kind === "points"
            ? "Points Earned"
            : kind === "time"
            ? "Time Spent"
            : "Questions Answered Correctly"}
        </span>
      </aside>
      {kind === "questions" && (
        <aside className="inline-flex h-4 items-center gap-x-2 text-sm">
          <div className="inline-block h-full w-6 rounded-sm bg-dm-brand-blue-200"></div>
          <span>Total Questions Answered</span>
        </aside>
      )}
      {isWeekly && kind === "points" && (
        <aside className="inline-flex h-4 items-center gap-x-2 text-sm">
          <hr className="inlne-block w-6 border-t-2 border-dotted border-dm-purple-500" />
          <span className="">Weekly Points Goal</span>
        </aside>
      )}
    </section>
  );
};

type GraphBarProps = {
  kind: LearnerStatsKind;
  isWeekly: boolean;
  yAxis: number;
  value: number | LearnerStatsQuestionDataResponse;
  dayOfWeek: string;
};
function GraphBar(props: GraphBarProps): JSX.Element {
  const barValue: number =
    props.kind === "time"
      ? Math.ceil((props.value as number) / 60)
      : props.kind === "questions"
      ? (props.value as LearnerStatsQuestionDataResponse).total
      : (props.value as number);
  const height = Math.min((barValue / props.yAxis) * 100, 100);

  const tooltipText =
    props.kind === "questions"
      ? `Questions Answered Correctly: ${
          (props.value as LearnerStatsQuestionDataResponse).correct
        }<br />Total Questions Answered: ${
          (props.value as LearnerStatsQuestionDataResponse).total
        }`
      : props.kind === "time"
      ? `Time Spent: ${barValue} mins`
      : `Points Earned: ${barValue}<br />Weekly Goal: ${WEEKLY_GOAL_POINTS}`;
  return (
    <div className="group relative mr-2 flex h-full min-w-8 max-w-44 grow flex-col last-of-type:mr-0 md:mr-3 lg:mr-5">
      <div className="relative flex flex-grow rounded">
        {props.kind === "questions" && (
          <>
            <div
              className="for-tooltip absolute z-10 w-full self-end"
              style={{ height: `${height}%` }}
              data-tip={tooltipText}
              data-for="statsTooltip"
              role="img"
              tabIndex={0}
              aria-label={`${props.dayOfWeek}: ${tooltipText.replace(
                "<br />",
                ","
              )}`}
            ></div>
            <div
              className={clsx(
                "w-1/2 self-end rounded rounded-br-none bg-dm-brand-blue-500",
                (props.value as LearnerStatsQuestionDataResponse).correct >
                  props.yAxis
                  ? "from-80%"
                  : null
              )}
              style={{
                height: `${Math.min(
                  ((props.value as LearnerStatsQuestionDataResponse).correct /
                    props.yAxis) *
                    100,
                  100
                )}%`,
              }}
              aria-hidden={true}
            ></div>
          </>
        )}
        <div
          className={clsx(
            "self-end rounded",
            props.kind === "questions"
              ? "w-1/2 rounded-bl-none bg-dm-brand-blue-200"
              : "flex-1 bg-gradient-to-t",
            props.kind === "points" && "from-dm-purple-500 to-dm-purple-200",
            props.kind === "time" && "from-dm-warning-800 to-dm-warning-500",

            barValue > props.yAxis ? "from-80%" : null
          )}
          style={{ height: `${height}%` }}
          {...(props.kind !== "questions"
            ? {
                "data-for": "statsTooltip",
                "data-tip": tooltipText,
                tabIndex: 0,
                role: "img",
                "aria-label": `${props.dayOfWeek}: ${tooltipText.replace(
                  "<br />",
                  ","
                )}`,
              }
            : { "aria-hidden": "true" })}
        ></div>
      </div>
      <h6
        className="absolute -bottom-4 flex h-max w-full origin-bottom -rotate-[160deg] items-center text-right text-sm text-dm-gray-200"
        style={{ writingMode: "vertical-rl" }}
        aria-hidden={true}
      >
        {props.dayOfWeek}
      </h6>
    </div>
  );
}

type Props = {
  kind: LearnerStatsKind;
  kindData:
    | Record<string, LearnerStatsQuestionDataResponse>[]
    | Record<string, number>[];
  fetchKindData: () => void;
  isWeekly: boolean;
  isStatsFetching: boolean;
};

const WEEKLY_GOAL_POINTS = 50;
const WEEKS_IN_GROUP = 8;
const MAX_POINTS = {
  points: [50, 100, 250, 500, 1000, 2500, 5000],
  questions: [10, 25, 50, 100, 250, 500, 1000, 2500, 5000],
};

export const LearnerStatsBarGraph: React.FC<Props> = (props: Props) => {
  const [weekNumber, setWeekNumber] = useState<number>(0);
  const [weekGroup, setWeekGroup] = useState<number>(0);

  const weeklyDateRange = () => {
    if (
      props.kindData?.[weekGroup + WEEKS_IN_GROUP - 1] &&
      props.kindData.length > 0
    ) {
      const beginningDate = parse(
        Object.keys(props.kindData[weekGroup + WEEKS_IN_GROUP - 1])[0],
        "yyyy-MMM-dd",
        new Date()
      );
      const endingLength = Object.keys(props.kindData[weekGroup]).length;
      const endingDate = parse(
        Object.keys(props.kindData[weekGroup])[endingLength - 1],
        "yyyy-MMM-dd",
        new Date()
      );

      return `${format(beginningDate, "MMM d")} - ${format(
        endingDate,
        "MMM d"
      )}`;
    }
  };

  const weeklyData = props.kindData
    ?.map(
      (
        week:
          | Record<string, LearnerStatsQuestionDataResponse>
          | Record<string, number>
      ) => {
        const length = Object.keys(week).length;
        const startDate = parse(
          Object.keys(week)[0],
          "yyyy-MMM-dd",
          new Date()
        );
        const endDate = parse(
          Object.keys(week)[length - 1],
          "yyyy-MMM-dd",
          new Date()
        );
        const label = `${format(startDate, "MMM d")} - ${
          isSameMonth(startDate, endDate)
            ? format(endDate, "d")
            : format(endDate, "MMM d")
        }`;

        const formattedData =
          props.kind === "questions"
            ? Object.values(week).reduce(
                (
                  acc: LearnerStatsQuestionDataResponse,
                  val: LearnerStatsQuestionDataResponse
                ) => {
                  return {
                    total: acc.total + val.total,
                    correct: acc.correct + val.correct,
                  };
                },
                { total: 0, correct: 0 }
              )
            : Object.values(week).reduce(
                (acc: number, val: number) => acc + val,
                0
              );
        return { [label]: formattedData };
      }
    )
    .slice(weekGroup, weekGroup + WEEKS_IN_GROUP)
    .reverse();

  const nextHour = (x: number) => Math.ceil(x / 60) * 60;

  const currentData = props.isWeekly
    ? weeklyData
    : props.kindData?.[weekNumber];

  const highestValue = () => {
    if (!currentData) return 0;
    return Object.values(currentData).reduce(
      (acc: number, val: number | LearnerStatsQuestionDataResponse) => {
        const currentVal = !props.isWeekly ? val : Object.values(val)[0];
        const tempVal: number =
          props.kind !== "questions"
            ? (currentVal as number)
            : (currentVal as LearnerStatsQuestionDataResponse).total;
        return tempVal > acc ? tempVal : acc;
      },
      0
    );
  };

  const highestValueByType =
    props.kind === "time" ? Math.ceil(highestValue() / 60) : highestValue();

  const yAxisIndex =
    props.kind !== "time"
      ? MAX_POINTS[props.kind].findIndex(
          (max: number) => max > highestValueByType
        )
      : -1;

  const yAxis =
    props.kind === "time"
      ? Math.max(nextHour(highestValue() / 60), 60)
      : yAxisIndex !== -1
      ? MAX_POINTS[props.kind][yAxisIndex]
      : MAX_POINTS[props.kind][MAX_POINTS[props.kind].length - 1];

  const yAxisLabel =
    props.kind === "points" ? "pts" : props.kind === "time" ? "mins" : "Q";

  const yAxisAriaLabel =
    props.kind === "points"
      ? "points"
      : props.kind === "time"
      ? "minutes"
      : "questions";

  const firstDate =
    props.kindData?.[weekNumber] &&
    format(
      parse(
        Object.keys(props.kindData[weekNumber])[0],
        "yyyy-MMM-dd",
        new Date()
      ),
      "MMM d"
    );

  const changeWeek = (direction: "previous" | "next") => {
    if (currentData && !props.isStatsFetching) {
      if (!props.isWeekly) {
        const nextWeek =
          direction === "previous" ? weekNumber + 1 : weekNumber - 1;
        if (nextWeek > props.kindData.length - 1) {
          props.fetchKindData();
        }
        setWeekNumber(Math.max(nextWeek, 0));
      } else {
        const nextGroup =
          direction === "previous"
            ? weekGroup + WEEKS_IN_GROUP
            : weekGroup - WEEKS_IN_GROUP;
        if (nextGroup > props.kindData.length - 1) {
          props.fetchKindData();
        }
        setWeekGroup(Math.max(nextGroup, 0));
      }
    }
    ReactTooltip.rebuild();
  };

  useEffect(() => {
    setWeekNumber(0);
    setWeekGroup(0);
    ReactTooltip.rebuild();
  }, [props.kind, props.isWeekly]);

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const statsBarGraphRef = useRef<HTMLDivElement>(null);
  const { height: barHeight = 0 } = useResizeObserver({
    ref: statsBarGraphRef,
  });

  // subtract 1/2 the height (14px) of the dotted line element
  const dottedLineHeight =
    barHeight - (WEEKLY_GOAL_POINTS / yAxis) * barHeight - 7;

  const barGraphAriaLabel =
    props.kind === "points"
      ? "points earned"
      : props.kind === "time"
      ? "time spent"
      : "questions answered";

  const barGraphAriaLabelCopy = props.isWeekly ? "each week" : "each day";

  const statTotalText = () => {
    if (currentData === undefined) {
      return "...";
    }
    if (props.kind === "points") {
      const points = Array.isArray(currentData)
        ? sum(currentData.flatMap(Object.values))
        : sum(Object.values(currentData));
      return points === 1
        ? "1 pt"
        : `${Intl.NumberFormat().format(points)} pts`;
    }
    if (props.kind === "time") {
      const seconds = Array.isArray(currentData)
        ? sum(currentData.flatMap(Object.values))
        : sum(Object.values(currentData));
      const minutes = Math.ceil(seconds / 60);
      const formattedMinutes = Intl.NumberFormat().format(minutes);
      return minutes === 1 ? "1 min" : `${formattedMinutes} mins`;
    }
    if (props.kind === "questions") {
      const questions = Array.isArray(currentData)
        ? sum(currentData.map((d) => Object.values(d).pop().total))
        : sum(Object.values(currentData).map(({ total }) => total));
      const formattedQuestions = Intl.NumberFormat().format(questions);
      return questions === 1
        ? "1 total question"
        : `${formattedQuestions} total questions`;
    }
    return unreachableCase(props.kind);
  };

  return (
    <>
      {statsLegend(props.kind, props.isWeekly)}
      <nav className="mb-8 flex items-center justify-between">
        <button
          aria-label={
            props.isWeekly ? "Go to previous weeks" : "Go to previous week"
          }
          onClick={() => changeWeek("previous")}
        >
          <ArrowLeftIcon
            className="h-5 w-5 text-dm-gray-200 hover:text-dm-gray-500"
            aria-hidden="true"
          />
        </button>
        <h3 className="flex select-none gap-4 text-center font-sans text-sm">
          {!props.isWeekly && firstDate !== undefined && (
            <span>Week of {firstDate}</span>
          )}
          {props.isWeekly && weeklyDateRange() !== undefined && (
            <span>Weeks of {weeklyDateRange()}</span>
          )}
          {!props.isStatsFetching && <b>({statTotalText()})</b>}
        </h3>
        <button
          className={clsx(
            !props.isWeekly && weekNumber === 0 && "invisible",
            props.isWeekly && weekGroup === 0 && "invisible"
          )}
          aria-label={props.isWeekly ? "Go to next weeks" : "Go to next week"}
          onClick={() => changeWeek("next")}
        >
          <ArrowRightIcon
            className="h-5 w-5 text-dm-gray-200 hover:text-dm-gray-500"
            aria-hidden="true"
          />
        </button>
      </nav>

      <figure
        className="dm-bargraph overflow-x-auto overflow-y-hidden"
        aria-label={`Bar graph showing ${barGraphAriaLabel} ${barGraphAriaLabelCopy}`}
      >
        <div
          id="statsBarGraph"
          ref={statsBarGraphRef}
          className={clsx(
            "relative mt-5 flex w-full flex-grow flex-row justify-normal",
            props.isWeekly ? "h-[20.5rem] pb-[6.5rem]" : "h-72 pb-16"
          )}
        >
          {currentData && !props.isStatsFetching && (
            <div
              className="mr-3 flex min-w-[46px] flex-col content-center items-end justify-between text-right text-sm text-dm-gray-500"
              aria-label={`Vertical axis from 0 to ${yAxis} ${yAxisAriaLabel}`}
              role="img"
            >
              <div
                className="-mt-[7px] flex-grow leading-none"
                aria-hidden={true}
              >
                {yAxis} {yAxisLabel}
              </div>
              <div className="leading-none" aria-hidden={true}>
                0 {yAxisLabel}
              </div>
            </div>
          )}
          {props.isWeekly && props.kind === "points" && (
            <div
              className="pointer-events-none absolute z-[7] flex w-full items-center"
              style={{ top: `${dottedLineHeight}px` }}
            >
              <span
                className={clsx(
                  "mr-3 min-w-[46px] flex-shrink-0 text-right text-sm leading-none text-dm-gray-500",
                  !(yAxis > WEEKLY_GOAL_POINTS && yAxis < 1000) && "invisible"
                )}
              >{`${WEEKLY_GOAL_POINTS} ${yAxisLabel}`}</span>
              <hr className="flex-grow border-t-2 border-dotted border-dm-purple-500" />
            </div>
          )}
          {!props.isWeekly &&
            props.kindData?.[weekNumber] &&
            Object.entries(props.kindData[weekNumber]).map((entry) => {
              const [key, value]: [string, number | any] = entry;
              const date = parse(key, "yyyy-MMM-dd", new Date());
              if (isFuture(date)) return null;
              const dayOfWeek = format(date, "MMM d");
              return (
                <GraphBar
                  key={`${key}-${props.kind}`}
                  kind={props.kind}
                  isWeekly={props.isWeekly}
                  yAxis={yAxis}
                  value={value}
                  dayOfWeek={dayOfWeek}
                />
              );
            })}
          {props.isWeekly &&
            weeklyData &&
            Object.values(weeklyData).map((entry) => {
              const dayOfWeek = Object.keys(entry)[0];
              return (
                <GraphBar
                  key={`${dayOfWeek}-${props.kind}`}
                  kind={props.kind}
                  isWeekly={props.isWeekly}
                  yAxis={yAxis}
                  value={Object.values(entry)[0]}
                  dayOfWeek={dayOfWeek}
                />
              );
            })}
        </div>
      </figure>
    </>
  );
};
