import clsx from "clsx";
import { InputHTMLAttributes, forwardRef } from "react";

interface IconRadioInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  icon: React.ReactElement;
  isSelected: boolean;
}

export const IconRadioInput = forwardRef<HTMLInputElement, IconRadioInputProps>(
  ({ label, icon, isSelected, ...props }, ref) => {
    return (
      <label className="flex cursor-pointer items-center gap-2">
        <input
          className="sr-only"
          type="radio"
          ref={ref}
          value={label}
          {...props}
        />
        <div className="flex flex-col items-center justify-center gap-2">
          <div
            className={clsx(
              "w-[80px] rounded-md border-2 p-2",
              isSelected ? "border-dm-brand-blue-500" : "border-transparent"
            )}
            aria-hidden
          >
            {icon}
          </div>
          <div>{label}</div>
        </div>
      </label>
    );
  }
);
IconRadioInput.displayName = "IconRadioInput";
