import React, { useState } from "react";
import IconAssignmentProgress from "../IconAssignmentProgress";
import { TooltipButton } from "../../../shared/TooltipButton";
import Button from "../../../student/components/generic/button";
import { useNavigate, useNavigation } from "react-router-dom";
import AssignmentPreviewModal from "../Modals/AssignmentPreviewModal";
import TestConfirmationModal from "../Modals/TestConfirmationModal";

type Props = {
  type: "unit" | "course";
  courseId: string;
  unitId?: string;
  testName: string;
  progress?: number;
  submitted?: Date;
  maxGrade?: number;
  testUrl: string;
  showConfirmationModalFirst: boolean;
  isFirstAttemptComplete: boolean;
  numberOfSkills: number;
  numberOfSkillsCompleted: number;
  duration?: number;
  estimatedTime: number;
};

const TestCard = (props: Props) => {
  const navigate = useNavigate();
  const [showAssignmentModal, setShowAssignmentModal] =
    useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const testWording = props.type === "course" ? "Course" : "Unit";
  const buttonText = props.submitted
    ? `Review ${testWording} Test`
    : props.progress !== undefined
    ? `Resume ${testWording} Test`
    : `Open ${testWording} Test`;

  const initializeProgressForCourse = async () => {
    navigate(props.testUrl);
  };

  const startTest = () => {
    if (props.showConfirmationModalFirst) {
      setShowConfirmationModal(true);
    } else {
      initializeProgressForCourse();
    }
  };

  return (
    <div
      id={`panel-${props.type}-test-${props.courseId}${
        props.unitId ? `-${props.unitId}` : ""
      }`}
      className="w-full rounded-lg border border-dm-charcoal-100 bg-white px-6 py-4"
    >
      <div className="flex items-center justify-between gap-x-2">
        <div className="flex basis-full items-center gap-2">
          <IconAssignmentProgress
            type={props.type === "course" ? "courseTest" : "unitTest"}
            progress={props.progress || 0}
            submitted={props.submitted !== undefined}
            maxGrade={props.maxGrade}
          />
          <h3 className="text-lg font-bold">
            <span>
              {props.testName}
              {props.progress !== undefined &&
                !props.submitted &&
                ": In Progress"}
            </span>
            {props.progress === undefined && (
              <TooltipButton
                message="Preview Test Questions"
                onClick={() => setShowAssignmentModal(true)}
              >
                <p className="hidden">Open Preview Modal</p>
                <i className="far fa-eye text-dm-gray-200" aria-hidden="true" />
              </TooltipButton>
            )}
          </h3>
        </div>
        <div className="flex flex-shrink-0 basis-full gap-4 max-md:hidden md:flex-shrink-0 md:basis-auto">
          {props.submitted && (
            <Button
              href={`${props.testUrl}?retake=true`}
              type="outline"
              size="small"
              className="max-md:basis-full"
              testId="retakeButton"
            >
              Retake {testWording} Test
            </Button>
          )}
          <Button
            onClick={props.progress === undefined ? startTest : undefined}
            href={props.testUrl}
            type={
              props.progress !== undefined || props.showConfirmationModalFirst
                ? "primary"
                : "outline"
            }
            size="small"
            className="max-md:basis-full"
            testId="testCardPrimaryButton"
          >
            {buttonText}
          </Button>
        </div>
      </div>
      <div className="my-4 flex gap-2 text-sm">
        {props.progress === undefined && !props.isFirstAttemptComplete ? (
          <>
            <p>{props.numberOfSkills} Questions</p>
            {" | "}
            <p>{props.estimatedTime} Minutes (estimated)</p>
          </>
        ) : props.progress !== undefined && !props.submitted ? (
          <>
            <p>
              {props.numberOfSkillsCompleted}/{props.numberOfSkills} Questions
              Answered
            </p>
          </>
        ) : (
          <>
            <p>Score: {props.maxGrade}%</p>
            {props.duration && (
              <>
                {" | "}
                <p>
                  {props.duration}
                  {props.duration === 1 ? " Minute" : " Minutes"} Spent
                </p>
              </>
            )}
          </>
        )}
      </div>
      <div className="flex basis-full flex-wrap gap-2 md:hidden">
        {props.submitted && (
          <Button
            href={`${props.testUrl}?retake=true`}
            type="outline"
            size="small"
            className="max-md:basis-full"
          >
            Retake {testWording} Test
          </Button>
        )}
        <Button
          onClick={props.progress === undefined ? startTest : undefined}
          href={props.testUrl}
          type={
            props.progress !== undefined || props.showConfirmationModalFirst
              ? "outline"
              : "primary"
          }
          size="small"
          className="max-md:basis-full"
        >
          {buttonText}
        </Button>
      </div>
      <AssignmentPreviewModal
        title={`${testWording} Test Preview`}
        courseId={props.courseId}
        unitId={props.unitId}
        type={props.type === "course" ? "courseTest" : "unitTest"}
        visible={showAssignmentModal}
        toggleVisible={setShowAssignmentModal}
        navigateButtonText="Go To Course Test"
        navigateHref={props.testUrl}
      />
      <TestConfirmationModal
        visible={showConfirmationModal}
        toggleVisible={setShowConfirmationModal}
        onConfirm={initializeProgressForCourse}
        confirmButtonText="Open Course Test"
        closeButtonText="Back To Course Units"
        type="courseTest"
      />
    </div>
  );
};

export default TestCard;
