import React from "react";
import { IIntegration } from "./LTILogs";
import JsonDisplay from "../../../shared/JSONDisplay";

interface IntegrationProps {
  integrations: IIntegration[];
}

const Integrations: React.FC<IntegrationProps> = ({ integrations }) => {
  return (
    <div className="m-4 overflow-auto p-2">
      <h1 className="mb-2 text-2xl font-semibold text-gray-900">
        Integrations
      </h1>
      <div className="flex flex-row space-x-2">
        {integrations?.map((integration, index) => (
          <JsonDisplay key={index} data={integration} />
        ))}
      </div>
    </div>
  );
};

export default Integrations;
