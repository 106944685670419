import {
  Dispatch,
  SetStateAction,
  forwardRef,
  useContext,
  useEffect,
} from "react";
import StudentSectionsContext from "../../_context/StudentSectionsContext";
import { MQReact } from "../MQReact";
import { CalculatorIcon, XIcon } from "@heroicons/react/outline";
import { AnswerData } from "./DMKeyboard";
const MQ = (window as any).MQ;

type CalculatorRowProps = {
  handleChange: (mq: any) => void;
  answerData: AnswerData;
  setAnswerData: Dispatch<SetStateAction<AnswerData>>;
  handleEnter: () => void;
  handleGlobalFocus?: (mqID: any) => void;
  globalInputsMap?: Map<any, any>;
};

const CalculatorRow = forwardRef((props: CalculatorRowProps, mathref: any) => {
  const { handleChange, answerData, setAnswerData, handleEnter } = props;
  // A little hacky, this could become an issue if it was passed in later, could break hook rules
  // This was put in place 2/7 by Raleigh for the learner app
  // TODO: refactor this to be more generic
  const { handleGlobalFocus, globalInputsMap } =
    props.handleGlobalFocus && props.globalInputsMap
      ? {
          handleGlobalFocus: props.handleGlobalFocus,
          globalInputsMap: props.globalInputsMap,
        }
      : useContext(StudentSectionsContext);

  useEffect(() => {
    handleGlobalFocus("math-input-0");
    globalInputsMap.get("math-input-0")?.focus();
  }, []);

  useEffect(() => {
    const htmlElement = document.getElementById("static-math");
    const mathField = MQ.MathField(htmlElement);
    let latex = answerData.prevLatex ?? "";
    try {
      latex = latex.replace(/\\operatorname\{Ans\}/gi, "\\text{Ans}");
    } catch (e) {
      setAnswerData({
        ...answerData,
        prevLatex: "",
      });
    }
    if (htmlElement) {
      mathField.latex(latex);
    }
  }, [answerData.prevLatex]);

  return (
    <div className="w-full bg-white">
      {answerData.prevLatex !== "" && (
        <div className="relative inline-flex w-full border border-b-0 border-dm-gray-200 bg-white">
          <button
            className="absolute top-[50%] -mt-3 h-6 w-6 text-dm-gray-200 hover:text-dm-gray-500"
            onClick={() => {
              setAnswerData({
                ...answerData,
                prevLatex: "",
                prevAnswer: "",
                prevAnswerFull: 0,
              });
            }}
          >
            <XIcon className="h-6 w-6" />
          </button>
          <span className="w-full bg-white py-2.5 pl-8 pr-10 align-middle">
            <span id="static-math" className="pointer-events-none border-0">
              {answerData.prevLatex}
            </span>
          </span>
          <span className="absolute right-2.5 top-[50%] -mt-3 text-dm-gray-200">
            <span className="m-2">=</span>
            {answerData.prevAnswer ?? "..."}
          </span>
        </div>
      )}
      <div className="relative inline-flex w-full">
        <CalculatorIcon className="absolute top-[50%] -mt-3 h-6 w-6 text-dm-gray-200" />
        <MQReact
          id="math-input-0"
          handleFocus={handleGlobalFocus}
          ariaLabel={"Calculator input area"}
          onChange={handleChange}
          handleSubmit={handleEnter}
          ref={mathref}
          /**Should we round the corners?
           * border-radius: 0.5rem 0.5rem 0 0;
           */
          cssText={`width: 100%; padding: 10px 150px 10px 30px;`}
          isCalc={true}
        />
        {answerData.currentAnswer ? (
          <span className="absolute right-2.5 top-[50%] -mt-3">
            <span className="m-2">=</span>
            {answerData.currentAnswer}
          </span>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
});
CalculatorRow.displayName = "CalculatorRow";

export default CalculatorRow;
