import clsx from "clsx";

const QuestionMarkCircleIcon = ({ classes }: { classes?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      className={clsx(classes, "m-1")}
    >
      <path
        d="M5.64681 6.33333C6.13492 5.29761 7.45191 4.55556 9.00009 4.55556C10.9637 4.55556 12.5556 5.74947 12.5556 7.22222C12.5556 8.46613 11.4199 9.5112 9.88382 9.80587C9.40169 9.89831 9.00009 10.2868 9.00009 10.7778M9 13.4444H9.00889M17 9C17 13.4183 13.4183 17 9 17C4.58172 17 1 13.4183 1 9C1 4.58172 4.58172 1 9 1C13.4183 1 17 4.58172 17 9Z"
        stroke="#9CA3AF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default QuestionMarkCircleIcon;
