import { Dialog, Transition } from "@headlessui/react";
import { Fragment, Dispatch, SetStateAction, useRef } from "react";
import renderMathInElement from "../utils/auto-render";

type Props = {
  isShowing: boolean;
  error: boolean;
  title?: string;
  message?: string;
  btnText?: string;
  hasMath?: boolean;
  leaveAction?: () => void;
  setLoadingData: Dispatch<SetStateAction<any>>;
};

export default function Loading({
  error = false,
  isShowing = true,
  title = "",
  message = "",
  btnText = "",
  hasMath = false,
  leaveAction = undefined,
  setLoadingData,
}: Props): JSX.Element {
  const messageRef = useRef(null);
  const focusRef = useRef(null);
  /* Global function to trigger a notice modal with a specific message */
  const alertDialog = (
    msg: string,
    title?: string,
    buttonText?: string,
    leaveAction?: () => void
  ) => {
    setLoadingData((prevState: any) => ({
      ...prevState,
      error: true,
      isShowing: true,
      title: title || "Notice",
      message: msg,
      btnText: buttonText,
      leaveAction,
    }));
  };

  /* Global function to trigger a notice modal with embedded latex */
  const alertMath = (msg: string, title?: string, buttonText?: string) => {
    setLoadingData((prevState: any) => ({
      ...prevState,
      error: true,
      isShowing: true,
      title: title || "Notice",
      message: msg,
      btnText: buttonText,
      hasMath: true,
    }));
  };

  /* Set global functions */
  window.alertDialog = alertDialog;
  window.alertMath = alertMath;

  function closeModal() {
    if (error) {
      setLoadingData({ isShowing: false, error, title, message, leaveAction });
    }
    // setIsModalOpen(false);
    // if (showField) {
    //   inputRef?.current?.focus();
    // }
  }

  const resetModal = () => {
    setLoadingData({
      error: false,
      isShowing: false,
      title: "Error",
      message: "You encountered an error",
      btnText: "",
      hasMath: false,
      leaveAction: undefined,
    });
  };

  // if (!isShowing) return <></>;
  return (
    <Transition
      show={isShowing}
      as={Fragment}
      afterLeave={() => {
        if (leaveAction) leaveAction();
        resetModal();
      }}
    >
      <Dialog
        as="div"
        className="relative z-50"
        onClose={closeModal}
        initialFocus={focusRef}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-60" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div
            className="flex min-h-full items-center justify-center p-4 text-center"
            ref={focusRef}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
              beforeEnter={() => {
                if (hasMath) renderMathInElement(messageRef.current);
              }}
            >
              {error ? (
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="mb-3 text-lg font-medium leading-6 text-gray-900"
                  >
                    {title}
                  </Dialog.Title>
                  <Dialog.Description
                    className="text-sm text-gray-500"
                    ref={messageRef}
                  >
                    <span
                      role="alertdialog"
                      dangerouslySetInnerHTML={{ __html: message }}
                    ></span>
                  </Dialog.Description>

                  <div className="mt-4 flex justify-end">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      {btnText || "Ok"}
                    </button>
                  </div>
                </Dialog.Panel>
              ) : (
                <Dialog.Panel className="flex flex-col items-center justify-center">
                  <div
                    style={{ borderTopColor: "transparent" }}
                    className="h-16 w-16 animate-spin rounded-full border-4 border-solid border-blue-400"
                  ></div>
                  <h2 className="mt-2 text-center text-xl font-bold text-white">
                    Loading...
                  </h2>
                </Dialog.Panel>
              )}
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
