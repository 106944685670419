import { useCourseContext } from "../../contexts/CourseContext";
import { LearnerPage } from "../Layouts/LearnerPage";
import { useLearnerContext } from "../../contexts/LearnerContext";
import { LandingPageError } from "../LandingPageError";
import { CoursesLoading } from "./CoursesLoading";
import { CoursesLanding } from "./CoursesLanding";

const Courses: React.FC = () => {
  const courseContext = useCourseContext();
  const learnerContext = useLearnerContext();
  const courses = courseContext.getAllCoursesData();

  return (
    <LearnerPage title="Courses">
      {courseContext.state.isLoadingCourse ||
      courseContext.state.isLoadingSubunits ||
      learnerContext.state.isLoadingProgress ? (
        <CoursesLoading />
      ) : courses ? (
        <CoursesLanding courses={courses} />
      ) : (
        <LandingPageError>Unable to load courses</LandingPageError>
      )}
    </LearnerPage>
  );
};

export default Courses;
