import { useRef } from "react";
import { displayInlineMath } from "../../utils";
const MQ = (window as any).MQ;

type DMKeyProps = {
  id?: string;
  btnText: string | any; //| React.ReactElement
  btnCmd?: boolean;
  btnAction?: boolean;
  btnOutput?: string;
  addParens?: boolean;
  ansType?: string | number;
  className?: string;
  ariaLabel?: string;
  moveCursor?: { dir: string; num: number };
  inputsRef?: any;
  focusedInput: string | null;
  previousAnswer?: string;
};

const DMKey = ({
  btnText,
  btnCmd,
  btnAction,
  btnOutput,
  addParens,
  ansType,
  className,
  ariaLabel,
  moveCursor,
  inputsRef,
  focusedInput,
  previousAnswer,
}: DMKeyProps) => {
  const btnRef = useRef<HTMLButtonElement>(null);

  const handleKeyClick = (btnLatex: string) => {
    let node: any;

    if (inputsRef) {
      const map = inputsRef ?? new Map();
      node = focusedInput ? map.get(focusedInput) : map.get("math-input-1");
    }
    if (!node) {
      return;
    }

    if (btnAction) {
      if (btnLatex.includes("start")) {
        node.moveToLeftEnd();
        btnLatex = btnLatex.replace("start", "");
      }
      node.keystroke(btnLatex);
    } else if (btnCmd) {
      // use the command mq method
      node.cmd(btnLatex);
    } else {
      // account for special buttons
      if (btnLatex.includes(",") && btnLatex.length > 1) {
        // such as [,] for interval notation
        node.write(btnLatex).keystroke("Left Left");
      } else if (btnLatex.includes("cuberoot")) {
        node
          .cmd("nthroot")
          .typedText("3")
          .keystroke("Tab")
          .keystroke("Left Right");
      } else if (btnLatex.includes("nthroot")) {
        node.cmd("nthroot").keystroke("Left Right");
      } else if (btnLatex.includes("logbase")) {
        node.write("log_{}").keystroke("Tab").keystroke("Left");
      } else if (btnLatex.includes("\\left(")) {
        node.write(btnLatex);
        node.keystroke("Left");
      } else if (btnText === "Ans") {
        if (focusedInput === "math-input-0") {
          node.typedText("Ans");
        } else {
          node.write(previousAnswer);
        }
      } else {
        // all other latex
        node.write(btnLatex);
      }
      if (moveCursor) {
        for (let i = 0; i < moveCursor.num; i++) {
          node.keystroke(moveCursor.dir);
        }
      }
      if (addParens) {
        node.cmd("(");
        node.keystroke("Left Right").focus();
      }
    }
    node.focus();
  };

  return (
    <button
      type={"button"}
      ref={btnRef}
      aria-label={ariaLabel || "button"}
      name={btnText}
      onClick={() => {
        const btnLaTeX = btnOutput !== undefined ? btnOutput : btnText;
        handleKeyClick(btnLaTeX);
      }}
      className={
        className ||
        `border-1 col col-span-1 mb-[3px] mr-[2%] h-[35px] max-h-[100%] rounded-md bg-gray-300 hover:bg-gray-400`
      }
    >
      {" "}
      {ansType === "icon" ? btnText : displayInlineMath(btnText)}
    </button>
  );
};

export default DMKey;
