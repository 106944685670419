type Props = {
  isDragging: boolean;
  onKeyDown: any;
  onMouseDown: any;
  onTouchStart: any;
  lessPadding?: boolean;
};

const DragHandle = (props: Props) => {
  return (
    <div
      className={`h-6 ${
        props.lessPadding ? "h-full" : "mr-2"
      } inline-block align-bottom ${
        props.isDragging ? "cursor-grabbing" : "cursor-grab"
      }`}
      onKeyDown={props.onKeyDown}
      onMouseDown={props.onMouseDown}
      onTouchStart={props.onTouchStart}
      style={{ minWidth: "24px" }}
    >
      {props.lessPadding ? (
        <svg
          width="24"
          height="180"
          viewBox="0 0 24 180"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 87C10.5523 87 11 86.5523 11 86C11 85.4477 10.5523 85 10 85C9.44772 85 9 85.4477 9 86C9 86.5523 9.44772 87 10 87ZM10 91C10.5523 91 11 90.5523 11 90C11 89.4477 10.5523 89 10 89C9.44772 89 9 89.4477 9 90C9 90.5523 9.44772 91 10 91ZM11 94C11 94.5523 10.5523 95 10 95C9.44772 95 9 94.5523 9 94C9 93.4477 9.44772 93 10 93C10.5523 93 11 93.4477 11 94ZM14 95C14.5523 95 15 94.5523 15 94C15 93.4477 14.5523 93 14 93C13.4477 93 13 93.4477 13 94C13 94.5523 13.4477 95 14 95ZM15 90C15 90.5523 14.5523 91 14 91C13.4477 91 13 90.5523 13 90C13 89.4477 13.4477 89 14 89C14.5523 89 15 89.4477 15 90ZM14 87C14.5523 87 15 86.5523 15 86C15 85.4477 14.5523 85 14 85C13.4477 85 13 85.4477 13 86C13 86.5523 13.4477 87 14 87Z"
            fill="#9CA3AF"
          />
        </svg>
      ) : (
        <svg
          width="100%"
          height="100%"
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="grip-vertical"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 320 512"
        >
          <path
            fill="currentColor"
            d="M96 32H32C14.33 32 0 46.33 0 64v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zm0 160H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm0 160H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zM288 32h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zm0 160h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm0 160h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32z"
          ></path>
        </svg>
      )}
    </div>
  );
};

export default DragHandle;
