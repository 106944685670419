import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { useCourseContext } from "../contexts/CourseContext";
import { useLearnerContext } from "../contexts/LearnerContext";
import { Course, LearnerAssignmentSkillData } from "../types";
import CourseSummaryHeader from "./Course/CourseSummaryHeader";
import UnitSummaryCard from "./Unit/UnitSummaryCard";
import { CourseSearch } from "./Search/CourseSearch";
import { CourseLandingWrapper } from "./CourseLandingWrapper";
import { CourseLandingLoading } from "./CourseLandingLoading";
import { LandingPageError } from "./LandingPageError";
import { LearnerPage } from "./Layouts/LearnerPage";
import { useCurrentCourseData } from "../utils/useCurrentCourseData";
import TestCard from "./TestCard/TestCard";
import { REACT_APP_LEARNER_LINK } from "../../utils";
import { clamp, findIndex, sum } from "lodash";
import { PROGRESS_MINIMUM } from "../constants";

const CourseLanding = () => {
  const { coursePath } = useParams();
  const courseContext = useCourseContext();
  const learnerContext = useLearnerContext();
  const courseData = courseContext.getCourseData(coursePath);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [coursePath]);

  useEffect(() => {
    courseContext.setActiveCourse(courseData?.id);
  }, [courseContext, courseData]);

  return (
    <LearnerPage title={courseData?.name}>
      {courseContext.state.isLoadingCourse ||
      courseContext.state.isLoadingSubunits ||
      learnerContext.state.isLoadingProgress ? (
        <CourseLandingLoading />
      ) : courseData ? (
        <CourseLandingWithData courseData={courseData} />
      ) : (
        <LandingPageError>Unable to load this course</LandingPageError>
      )}
    </LearnerPage>
  );
};

const CourseLandingWithData: React.FC<{ courseData: Course }> = ({
  courseData,
}) => {
  const { coursePath } = useParams();
  const learnerContext = useLearnerContext();
  const courseProgress = learnerContext.getProgress(courseData?.id || "");
  const { isFirstAttemptComplete, isInProgress, currentCourseData } =
    useCurrentCourseData(courseData?.id);

  const [lastClickedId, setLastClickedId] = useState("");

  ReactTooltip.rebuild();

  const estimatedTime = Math.max(
    5,
    Math.ceil((courseData?.avgQuizTime || 0) / 60 / 5) * 5
  );

  const allSubunitsProgress =
    courseProgress?.units && courseProgress.units.length > 0
      ? sum(courseProgress.units.map((x) => x.progress)) /
        courseProgress.units.length
      : 0;

  const firstUnsolved = clamp(
    findIndex(
      courseProgress?.courseTest?.assignment?.skills || [],
      (skill: LearnerAssignmentSkillData) => !skill.skillComplete
    ),
    0,
    (courseProgress?.courseTest?.assignment?.skills?.length || 1) - 1
  );

  const learnerDifficulty = learnerContext.getLearnerLevel();
  const flatSubunitProgress =
    courseProgress?.units?.flatMap((u) => u.subunits) || [];
  const skillsInCourse = (courseData?.units || []).flatMap((unit) =>
    unit?.subunits
      ?.flatMap((su) => su.skills)
      .filter((sk) => {
        const subunitProgress = flatSubunitProgress.find(
          (su) => su.subunitId === sk.subunitId
        );

        return (
          sk.difficulty ===
            (subunitProgress?.difficulty || learnerDifficulty) ||
          sk.difficulty === "both"
        );
      })
  ).length;

  const skillNumbers =
    (courseProgress?.courseTest?.assignment?.skills || []).length ||
    (skillsInCourse <= 30 ? skillsInCourse : 30);
  const skillsCompleted = (
    courseProgress?.courseTest?.assignment?.skills || []
  ).filter((x) => x.skillComplete).length;

  const currDuration = courseProgress?.courseTest?.assignment?.duration;
  const bestScoreTime = !isFirstAttemptComplete
    ? currDuration
    : courseProgress?.courseTest?.assignment?.resultHist
        ?.filter(
          (attempt) =>
            attempt.duration !== undefined &&
            attempt.grade === courseProgress?.courseTest?.maxGrade
        )
        .reduce(
          (acc, curr) =>
            acc < (curr.duration ?? 0) ? acc : curr.duration ?? 0,
          0
        ) || currDuration;

  const duration = bestScoreTime
    ? Math.max(1, Math.round(bestScoreTime / 60))
    : undefined;

  return (
    <CourseLandingWrapper
      mobileCourseSearch={<CourseSearch type="course" />}
      header={
        <CourseSummaryHeader
          courseProgress={currentCourseData}
          course={courseData}
          iconUrl={courseData.iconUrl}
          description={courseData.description}
          setLastClickedId={setLastClickedId}
        />
      }
    >
      {courseData.unitOrder.map((u, i) => {
        const unit = (courseData.units ?? []).find((unit) => unit.id === u);
        if (!unit || unit?.subunitOrder.length <= 0) {
          return null;
        }

        return (
          <UnitSummaryCard
            unit={unit}
            course={courseData}
            key={`unitcard-${unit.id}`}
            cardId={`panel-${unit.id}`}
            unitNumber={i + 1}
            lastClickedId={lastClickedId}
            defaultOpen={i === 0 && !courseProgress}
          />
        );
      })}

      <h3 className="mb-2 mt-6 font-serif text-lg font-bold">Course Test</h3>
      <TestCard
        type="course"
        courseId={courseData.id}
        testName={`${courseData.name} Test`}
        progress={
          courseProgress?.courseTest?.progress
            ? Math.round(courseProgress?.courseTest?.progress * 100)
            : undefined
        }
        submitted={courseProgress?.courseTest?.submitted}
        maxGrade={Math.round((courseProgress?.courseTest?.maxGrade || 0) * 100)}
        testUrl={
          courseProgress?.courseTest
            ? isInProgress
              ? `${REACT_APP_LEARNER_LINK}/${coursePath}/coursetest/${firstUnsolved}`
              : `${REACT_APP_LEARNER_LINK}/${coursePath}/coursetest/`
            : `${REACT_APP_LEARNER_LINK}/${coursePath}/coursetest/`
        }
        showConfirmationModalFirst={allSubunitsProgress < PROGRESS_MINIMUM}
        isFirstAttemptComplete={isFirstAttemptComplete}
        numberOfSkills={skillNumbers}
        numberOfSkillsCompleted={skillsCompleted}
        duration={duration}
        estimatedTime={estimatedTime}
      />
      <ReactTooltip
        id="sidebar-tooltip"
        className="font-sans text-xs"
        effect="solid"
        delayShow={800}
        delayHide={50}
        place="right"
      />
    </CourseLandingWrapper>
  );
};

export default CourseLanding;
