type Props = {
  label?: string;
  value: string;
  color?: string;
  noBar?: boolean;
};

const SubTextBlock = (props: Props) => {
  return (
    <div className={`truncate ${props.color || ""}`}>
      {props.label && <span className="font-bold">{props.label}: </span>}
      {props.value}
      {!props.noBar && (
        <span className="hidden text-dm-gray-200 sm:inline"> |</span>
      )}
    </div>
  );
};

export default SubTextBlock;
