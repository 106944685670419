import { Switch } from "@headlessui/react";
import clsx from "clsx";

const DeltaMathToggle = ({
  optionA,
  optionB,
  aSelected,
  onChangeFn,
  enabled = true,
  disabledMessage = "Disabled",
}: {
  optionA: string;
  optionB: string;
  aSelected: boolean;
  onChangeFn: (value: any) => void;
  enabled?: boolean;
  disabledMessage?: string;
}) => {
  return (
    <Switch.Group
      as="div"
      className="group flex items-center justify-start p-2 sm:justify-end"
    >
      <Switch.Label as="span" className="mr-3">
        <span
          className={clsx(
            aSelected ? "text-dm-charcoal-600" : "text-gray-300",
            "cursor-pointer text-sm font-medium"
          )}
        >
          {optionA}
        </span>
      </Switch.Label>
      <Switch
        checked={aSelected}
        onChange={onChangeFn}
        disabled={!enabled}
        className={clsx(
          enabled ? "bg-dm-brand-blue-500" : "bg-gray-100",
          "relative inline-flex h-6 w-10 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent p-0.5 transition-colors duration-200 ease-in-out focus:outline-none"
        )}
      >
        <span
          aria-hidden="true"
          className={clsx(
            aSelected ? "translate-x-0" : "translate-x-4",
            "pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
          )}
        />
        {!enabled && (
          <div className="invisible absolute -inset-x-16 -top-12 w-max items-center text-left group-hover:visible group-hover:delay-300">
            <span className="whitespace-no-wrap relative z-10 block bg-black p-2 text-xs text-white shadow-lg">
              {disabledMessage}
            </span>
            <div className="align-center -my-2 mx-auto h-3 w-3 rotate-45 bg-black"></div>
          </div>
        )}
      </Switch>
      <Switch.Label as="span" className="ml-3">
        <span
          className={clsx(
            aSelected ? "text-gray-300" : "text-dm-charcoal-600",
            "cursor-pointer text-sm font-medium"
          )}
        >
          {optionB}
        </span>
      </Switch.Label>
    </Switch.Group>
  );
};

export default DeltaMathToggle;
