import { UnitProgress } from "../../types";

type Props = {
  unitNumber: number;
  unitProgress?: UnitProgress;
};

const UnitTestProgressSection = (props: Props) => {
  const testAttemptNumber =
    (props.unitProgress?.unitTest?.assignment?.resultHist?.length || 0) + 1;

  return (
    <div className="flex items-center gap-1">
      <div className="text-sm text-dm-charcoal-800 hover:text-dm-charcoal-800">
        {`Unit ${props.unitNumber} Test${
          props.unitProgress?.unitTest?.submitted ? " score" : ""
        }:`}
      </div>
      {props.unitProgress?.unitTest ? (
        props.unitProgress.unitTest.submitted ? (
          <p className="text-sm font-bold">
            {Math.round(props.unitProgress.unitTest.maxGrade * 100)}%
          </p>
        ) : (
          <>
            <p className="text-sm font-bold">
              In Progress
              {testAttemptNumber > 1 ? ` (Attempt ${testAttemptNumber})` : ""}
            </p>
          </>
        )
      ) : (
        <p className="text-sm">Not Started</p>
      )}
    </div>
  );
};

export default UnitTestProgressSection;
