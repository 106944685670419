import { Dispatch, Fragment, SetStateAction } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { useMediaQuery } from "usehooks-ts";
import SideBarMenu from "../components/SideBarMenu";
import SideBarSolveMenu from "../components/SideBarSolveMenu";
import { SideBarQRMenu } from "../../shared/QRCodeSolutions";
import clsx from "clsx";

type Props = {
  sidebarOpen: boolean;
  setSidebarOpen: Dispatch<SetStateAction<boolean>>;
  pageType?: "standard" | "solve" | "qr";
  widthClasses?: string;
  qrOptions?: {
    currentProblemIndex: number;
    setCurrentProblemIndex: Dispatch<SetStateAction<number>>;
    total: number;
  };
};

export default function Sidebar({
  sidebarOpen,
  setSidebarOpen,
  pageType = "standard",
  widthClasses,
  qrOptions,
}: Props): JSX.Element {
  const isSmallDevice = useMediaQuery("(max-width : 1023px)");
  const SideBarBlock = () =>
    pageType === "solve" ? (
      <SideBarSolveMenu setSidebarOpen={setSidebarOpen} />
    ) : pageType === "qr" && qrOptions ? (
      <SideBarQRMenu
        sidebarOpen={sidebarOpen}
        currentProblemIndex={qrOptions.currentProblemIndex}
        setCurrentProblemIndex={qrOptions.setCurrentProblemIndex}
        total={qrOptions.total}
      />
    ) : (
      <SideBarMenu setSidebarOpen={setSidebarOpen} />
    );
  return (
    <div
      className={clsx(
        "flex-shrink-0 border-r border-dm-charcoal-100 bg-white",
        widthClasses === undefined ? "lg:w-1/4" : widthClasses
      )}
    >
      {isSmallDevice ? (
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-[1001] lg:hidden"
            onClose={() => setSidebarOpen(false)}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-60" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-100 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex max-w-full flex-1 flex-col bg-white pb-4 pt-5 sm:max-w-sm">
                  <div className="mt-2 h-0 flex-1 overflow-y-auto px-5">
                    <nav aria-label="Sidebar" className="h-full">
                      <SideBarBlock />
                      <Transition.Child
                        as={Fragment}
                        enter="ease-in-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in-out duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <div className="absolute right-0 top-0 mr-4 pt-2">
                          <button
                            type="button"
                            className="ml-1 flex h-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                            onClick={() => setSidebarOpen(false)}
                          >
                            <span className="sr-only">Close sidebar</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </Transition.Child>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      ) : (
        <div className="hidden h-screen px-6 pb-4 pt-8 lg:sticky lg:inset-y-0 lg:flex lg:w-full lg:flex-col">
          <nav
            className={`flex h-0 flex-1 flex-col overflow-y-auto`}
            aria-label="Sidebar"
          >
            <SideBarBlock />
          </nav>
        </div>
      )}
    </div>
  );
}
