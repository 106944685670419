const GraphIcon = ({ classes }: { classes?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={classes}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 4C6 3.44772 5.55228 3 5 3C4.44772 3 4 3.44772 4 4V19V20V21H5L20 21C20.5523 21 21 20.5523 21 20C21 19.4477 20.5523 19 20 19H6L6 4Z"
      />
      <circle cx="10" cy="15" r="2" strokeWidth="2" />
      <circle cx="12" cy="8" r="2" strokeWidth="2" />
      <circle cx="18" cy="12" r="2" strokeWidth="2" />
    </svg>
  );
};

export default GraphIcon;
