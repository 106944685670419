import { uniqueId } from "lodash";

export const UnitsIcon: React.FC<{ showAria?: boolean }> = ({
  showAria = false,
}) => {
  const id = uniqueId();

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-label="grid icon"
      aria-hidden={!showAria}
    >
      <mask id={`path-1-inside-1_${id}`} fill="white">
        <rect x="4" y="4" width="4" height="4" rx="1" />
      </mask>
      <rect
        x="4"
        y="4"
        width="4"
        height="4"
        rx="1"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="4"
        mask={`url(#path-1-inside-1_${id})`}
      />
      <mask id={`path-2-inside-2_${id}`} fill="white">
        <rect x="10" y="4" width="4" height="4" rx="1" />
      </mask>
      <rect
        x="10"
        y="4"
        width="4"
        height="4"
        rx="1"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="4"
        mask={`url(#path-2-inside-2_${id})`}
      />
      <mask id={`path-3-inside-3_${id}`} fill="white">
        <rect x="16" y="4" width="4" height="4" rx="1" />
      </mask>
      <rect
        x="16"
        y="4"
        width="4"
        height="4"
        rx="1"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="4"
        mask={`url(#path-3-inside-3_${id})`}
      />
      <mask id={`path-4-inside-4_${id}`} fill="white">
        <rect x="4" y="10" width="4" height="4" rx="1" />
      </mask>
      <rect
        x="4"
        y="10"
        width="4"
        height="4"
        rx="1"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="4"
        mask={`url(#path-4-inside-4_${id})`}
      />
      <mask id={`path-5-inside-5_${id}`} fill="white">
        <rect x="10" y="10" width="4" height="4" rx="1" />
      </mask>
      <rect
        x="10"
        y="10"
        width="4"
        height="4"
        rx="1"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="4"
        mask={`url(#path-5-inside-5_${id})`}
      />
      <mask id={`path-6-inside-6_${id}`} fill="white">
        <rect x="16" y="10" width="4" height="4" rx="1" />
      </mask>
      <rect
        x="16"
        y="10"
        width="4"
        height="4"
        rx="1"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="4"
        mask={`url(#path-6-inside-6_${id})`}
      />
      <mask id={`path-7-inside-7_${id}`} fill="white">
        <rect x="4" y="16" width="4" height="4" rx="1" />
      </mask>
      <rect
        x="4"
        y="16"
        width="4"
        height="4"
        rx="1"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="4"
        mask={`url(#path-7-inside-7_${id})`}
      />
      <mask id={`path-8-inside-8_${id}`} fill="white">
        <rect x="10" y="16" width="4" height="4" rx="1" />
      </mask>
      <rect
        x="10"
        y="16"
        width="4"
        height="4"
        rx="1"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="4"
        mask={`url(#path-8-inside-8_${id})`}
      />
      <mask id={`path-9-inside-9_${id}`} fill="white">
        <rect x="16" y="16" width="4" height="4" rx="1" />
      </mask>
      <rect
        x="16"
        y="16"
        width="4"
        height="4"
        rx="1"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="6"
        mask={`url(#path-9-inside-9_${id})`}
      />
    </svg>
  );
};
