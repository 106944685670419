import { Fragment, useState, useEffect } from "react";
import { useDMQuery } from "../../utils";
import { useMutation } from "react-query";
import axios from "axios";
import { deltamathAPI } from "../utils";
import { LearnerData, LearnerSummary } from "../types";
import DeltaMathTable from "../../shared/DeltaMathTable";
import { DMToggleSingleLabel } from "../utils/functions";
import { TextFilterNoPersist } from "../../shared/table/filters";
import { REACT_APP_LEARNER_LINK } from "../../utils";
import { useNavigate } from "react-router-dom";
import { useDeltaToastContext } from "../../shared/contexts/ToasterContext";
import { LoginIcon } from "@heroicons/react/outline";
import { useDocumentTitle } from "usehooks-ts";

const AllLearners = () => {
  const [rowData, setRowData] = useState<Array<LearnerData>>([]);
  const [filteredData, setFilteredData] = useState<Array<LearnerData>>([]);
  const [includeEmployees, setIncludeEmployees] = useState<boolean>(false);
  const [summary, setSummary] = useState<Array<LearnerSummary>>([]);
  const [recent, setRecent] = useState<number>(0);
  const [canceledDuringTrial, setCanceledDuringTrial] = useState<number>(0);

  const navigate = useNavigate();
  const toastContext = useDeltaToastContext();

  useDocumentTitle("All Learners");

  const { isLoading, data } = useDMQuery({
    path: "/manager_new/parent-portal/learners",
    method: "get",
  });

  const { mutateAsync: impersonateMutate } = useMutation(
    (learnerId: string) => {
      return axios.post(
        `${deltamathAPI()}/impersonate/staff/learner/${learnerId}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    }
  );

  const impersonate = async (learnerId: string) => {
    try {
      const response = await impersonateMutate(learnerId);
      localStorage.setItem("user", JSON.stringify(response.data.user));
      navigate(`${REACT_APP_LEARNER_LINK}/dashboard`, {
        state: { from: "/all-learners" },
      });
    } catch (error: any) {
      const message =
        error?.response?.data?.message ||
        "There was an error impersonating the learner";

      toastContext.addToast({
        title: "Error",
        message,
        status: "Error",
      });
    }
  };

  useEffect(() => {
    if (data?.allLearners?.length > 0) {
      setRowData(data.allLearners);
      setFilteredData(
        data.allLearners.filter(
          (learner: LearnerData) =>
            learner.email.indexOf("@deltamath.com") === -1 &&
            (!learner.parent || learner.parent.indexOf("@deltamath.com") === -1)
        )
      );
    }
    if (data?.summary?.length > 0) {
      //add statuses for summary that are not there
      ["Trialist", "Active", "Canceled", "Inactive"].forEach((status) => {
        if (!data.summary.find((s: LearnerSummary) => s.status === status)) {
          data.summary.push({ status, monthly: 0, annual: 0, count: 0 });
        }
      });
      setSummary(data.summary);
      setCanceledDuringTrial(
        data?.allLearners.filter(
          (learner: LearnerData) =>
            learner.email.indexOf("@deltamath.com") === -1 &&
            (!learner.parent ||
              learner.parent.indexOf("@deltamath.com") === -1) &&
            learner.status === "Trial Canceled"
        ).length
      );
    }
    if (data?.recent?.[0]?.count) {
      setRecent(data.recent[0].count);
    }
  }, [data]);

  useEffect(() => {
    if (!includeEmployees) {
      setFilteredData(
        rowData.filter(
          (learner: LearnerData) =>
            learner.email.indexOf("@deltamath.com") === -1 &&
            (!learner.parent || learner.parent.indexOf("@deltamath.com") === -1)
        )
      );
    } else {
      setFilteredData(rowData);
    }
  }, [includeEmployees]);

  const columns = [
    {
      Header: "First",
      accessor: "first",
    },
    {
      Header: "Last",
      accessor: "last",
    },
    {
      Header: "Username/Email",
      accessor: "email",
      Filter: TextFilterNoPersist,
      Cell: ({ value, row }: { value: string; row: any }) => {
        const learnerId = row.original._id;
        return (
          <div className="flex" key={learnerId}>
            <div>{value}</div>
            <LoginIcon
              className="ml-1 h-5 w-5 cursor-pointer text-indigo-500"
              onClick={() => impersonate(learnerId)}
            />
          </div>
        );
      },
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Login Count",
      accessor: "login_count",
    },
    {
      Header: "Last Login",
      accessor: "last_login",
      Cell: ({ value }: { value: string }) => {
        if (value) {
          const date = new Date(value).toLocaleDateString();
          return date;
        } else {
          return "N/A";
        }
      },
    },
    {
      Header: "Points Earned",
      accessor: "pointsEarned",
    },
    {
      Header: "Points Redeemed",
      accessor: "pointsUsed",
    },
    {
      Header: "Sections Completed",
      accessor: "sections_completed",
    },
    {
      Header: "Units Completed",
      accessor: "units_completed",
    },
    {
      Header: "Start Date",
      accessor: "subscriptionTrialStartedAt",
      Cell: ({ value }: { value: string }) => {
        if (value) {
          const date = new Date(value).toLocaleDateString();
          return date;
        } else {
          return "N/A";
        }
      },
    },
    {
      Header: "End Date",
      accessor: "endDate",
      Cell: ({ value }: { value: string }) => {
        if (value) {
          const date = new Date(value).toLocaleDateString();
          return date;
        } else {
          return "-";
        }
      },
    },
    {
      Header: "Parent",
      accessor: "parent",
      Filter: TextFilterNoPersist,
      Cell: ({ value, row }: { value: string; row: any }) => {
        const learnerId = row.original.parentId;
        if (learnerId) {
          return (
            <div className="flex" key={learnerId}>
              <div>{value}</div>
              <LoginIcon
                className="ml-1 h-5 w-5 cursor-pointer text-indigo-500"
                onClick={() => impersonate(learnerId)}
              />
            </div>
          );
        } else {
          return "Self";
        }
      },
    },
    {
      Header: "Billing",
      accessor: "interval",
      Cell: ({ value }: { value: "annual" | "monthly" }) => {
        if (value === "annual") {
          return "Annual";
        } else if (value === "monthly") {
          return "Monthly";
        } else {
          return "Unknown";
        }
      },
    },
    {
      Header: "Cost",
      accessor: "cost",
    },
  ];

  return (
    <div className="flex flex-col gap-4 px-4 py-10">
      <h1 className="text-2xl font-bold">All Learners</h1>
      {isLoading && <div className="m-4 text-xl font-semibold">Loading...</div>}
      <div className="flex w-[90%] flex-row justify-between gap-x-4">
        {summary.map((s: LearnerSummary) => {
          const { status, annual, monthly, count } = s;
          return (
            <Fragment key={status}>
              <div className="flex w-[18%] flex-col items-center">
                <div className="m-4 grid h-40 w-full grid-cols-1 grid-rows-4 gap-1 rounded-lg bg-white shadow-md">
                  <div className="col-span-1 m-2 self-start text-sm font-semibold">
                    {status}
                  </div>
                  <div className="col-span-1 row-span-2 w-full grow self-center text-center text-3xl text-indigo-500">
                    {count}
                  </div>
                </div>
                <div className="m-4 h-40 w-full rounded-lg bg-white py-1 shadow-md">
                  <div className="m-2 text-sm font-semibold">{status}</div>
                  <div className="mx-2 w-full text-xl">
                    Monthly: ${monthly.toFixed(2)}
                  </div>
                  <div className="mx-2 mb-2 w-full text-xl">
                    Annual: ${annual.toFixed(2)}
                  </div>
                </div>
              </div>
            </Fragment>
          );
        })}
        <div className="flex w-[18%] flex-col items-center">
          <div className="m-4 grid h-40 w-full grid-cols-1 grid-rows-4 rounded-lg bg-white shadow-md">
            <div className="col-span-1 m-2 text-sm font-semibold">
              Active Subscribers - Past 30 Days
            </div>
            <div className="row-span-2 w-full self-center text-center text-3xl text-indigo-500">
              {recent}
            </div>
          </div>
          <div className="m-4 grid h-40 w-full grid-cols-1 grid-rows-4 rounded-lg bg-white shadow-md">
            <div className="col-span-1 m-2 text-sm font-semibold">
              Canceled During Trial
            </div>
            <div className="col-span-1 row-span-2 w-full self-center text-center text-3xl text-indigo-500">
              {canceledDuringTrial}
            </div>
          </div>
        </div>
      </div>
      <DMToggleSingleLabel
        checked={includeEmployees}
        onChange={(checked: boolean) => setIncludeEmployees(checked)}
        label={"Include Employee Created Users"}
      />
      <DeltaMathTable
        data={filteredData}
        columns={columns}
        options={{ globalFilter: true }}
      />
    </div>
  );
};

export default AllLearners;
