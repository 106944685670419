export function oxfordCommaJoin(items: string[]): string {
  if (items.length === 1) {
    return items[0];
  }
  if (items.length === 2) {
    return items.join(" and ");
  }
  return items
    .map((item, index) => (index === items.length - 1 ? `and ${item}` : item))
    .join(", ");
}
