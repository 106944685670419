import { noop } from "lodash";
import { createContext, useContext, useState } from "react";
import { ProblemIds } from "../types";

interface ProblemSupportContextState {
  problemIds: ProblemIds | undefined;
  setProblemIds: (ids: ProblemIds | undefined) => void;
}

const ProblemSupportContext = createContext<ProblemSupportContextState>({
  problemIds: undefined,
  setProblemIds: noop,
});

/**
 * Component that stores the ids for a specific problem
 */
export const ProblemSupportContextProvider: React.FC = ({ children }) => {
  const [problemIds, setProblemIds] = useState<ProblemIds | undefined>();

  return (
    <ProblemSupportContext.Provider
      value={{
        problemIds,
        setProblemIds,
      }}
    >
      {children}
    </ProblemSupportContext.Provider>
  );
};

/** Hook to get problem support context */
export function useProblemSupportContext() {
  return useContext(ProblemSupportContext);
}
