import axios from "axios";
import React, { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { deltamathAPI } from "../../../manager/utils";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import { getAdminId } from "../../../admin/utils";
import DeltaMathConfirmation from "../../../shared/DeltaMathConfirmation";
import { TrashIcon } from "@heroicons/react/outline";
import { DemoMode } from "../../../utils/demo";
import { useUserContext } from "../../../shared/contexts/UserContext";

export default function InviteTeachers({
  school,
  pendingTeacherInvites,
}: {
  school: any;
  pendingTeacherInvites: any;
}) {
  const userContext = useUserContext();
  const token = userContext.getJWT();
  const user = JSON.parse(localStorage.getItem("user") || "{}");

  const toastContext = useDeltaToastContext();
  const queryClient = useQueryClient();

  const [teacherEmails, setTeacherEmails] = useState("");
  const [emailError, setEmailError] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [showRemoveInviteConfirmation, setShowRemoveInviteConfirmation] =
    useState(false);
  const [inviteToDelete, setInviteToDelete] = useState({
    _id: "",
    email: "",
    schools: [],
  });

  const inviteTeachers = useMutation(
    (body: { emails: string[]; schoolid: string }) => {
      return axios.post(
        deltamathAPI() + "/admin_new/manage/invite_teachers",
        body,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    {
      onSuccess() {
        setTeacherEmails("");
        queryClient.invalidateQueries(["/admin_new/data/teacher"]);
        queryClient.invalidateQueries([
          "/admin_new/data/teach/admin_new/data/teacher",
        ]);
        queryClient.invalidateQueries(["/admin_new/data/teacherCounts"]);
        queryClient.invalidateQueries(["/admin_new/data/pendingInvites"]);

        toastContext.addToast({
          status: "Success",
          message: "Email Invitation Sent",
        });
        setSubmitting(false);
      },
      onError() {
        toastContext.addToast({
          status: "Error",
          message: "There was an error sending your invite.",
        });
        setSubmitting(false);
      },
    }
  );

  const removeTeacherInvite = useMutation(
    (body: { email: string; schoolid: string }) => {
      return axios.post(
        deltamathAPI() + "/admin_new/manage/remove_pending_invite",
        body,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("/admin_new/data/pendingInvites");
        setShowRemoveInviteConfirmation(false);
        toastContext.addToast({
          status: "Success",
          message: "Invite Removed Successfully",
        });
      },
      onSettled: () => {
        setShowRemoveInviteConfirmation(false);
      },
    }
  );

  useEffect(() => {
    setShowRemoveInviteConfirmation(false);
  }, [removeTeacherInvite.isSuccess]);

  const pendingTeacherInviteColumns = React.useMemo(
    () => [
      {
        Header: "Email",
        accessor: "email",
        Cell: (props: any) => {
          return <DemoMode value={props.value} type="person_email" />;
        },
      },
      {
        Header: "Delete",
        accessor: "showDeleteButton",
        disableSortBy: true,
        Cell: (props: { row: { original: any } }) => (
          <>
            <span
              onClick={() => {
                setShowRemoveInviteConfirmation(true);
                setInviteToDelete(props.row.original);
              }}
            >
              <TrashIcon className="h-6 w-full cursor-pointer text-gray-600" />
            </span>
          </>
        ),
      },
    ],
    []
  );

  return (
    <>
      <div>
        <div className="rounded-md border bg-white">
          <div className="border-b-2 p-6">
            <h3 className="text-lg font-semibold leading-6 text-dm-charcoal-800">
              Invite Teachers
            </h3>
            <p className="mt-1 text-sm text-dm-charcoal-800">
              List up to 50 email addresses separated by commas.
            </p>
          </div>
          <div className="px-6 py-8">
            <div className="grid grid-cols-4 gap-6">
              <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                <label
                  htmlFor="email_addresses"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email(s)
                </label>
                <input
                  type="text"
                  name="email_addresses"
                  id="email_addresses"
                  value={teacherEmails}
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  onChange={(e) => {
                    const value = e.target.value;
                    setTeacherEmails(value);
                    const emails = value
                      ? value.replace(/\s/g, "").split(",")
                      : ""; // remove whitespace and split by comma
                    const regex =
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    setEmailError("");
                    if (emails) {
                      if (emails.length > 50) {
                        setEmailError(
                          "You can only invite up to 50 teachers at once."
                        );
                      }
                      emails.forEach((email) => {
                        if (!regex.test(email)) {
                          setEmailError("Please enter a valid email address.");
                        }
                      });
                    }
                  }}
                />
                {emailError && <p>{emailError}</p>}
              </div>
            </div>
            <div className="grid grid-cols-4 gap-6 pt-6">
              <div className="col-span-2 sm:col-span-2 lg:col-span-1">
                {" "}
                <button
                  type="submit"
                  disabled={submitting}
                  onClick={() => {
                    if (getAdminId()) {
                      setSubmitting(true);
                      inviteTeachers.mutate({
                        emails: teacherEmails.replace(/\s/g, "").split(","),
                        schoolid: school.schoolid,
                        // user: getAdminId(),
                      });
                    }
                  }}
                  className="inline-flex w-full justify-center rounded-md border border-transparent bg-dm-brand-blue-500 px-8 py-3 text-sm font-medium text-white shadow-sm hover:bg-dm-brand-blue-500 focus:bg-dm-brand-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:opacity-50"
                >
                  Send Invite
                  {teacherEmails.includes(",") &&
                    teacherEmails.split(",").length > 1 && <>(s)</>}
                  {submitting && (
                    <svg
                      className="ml-3 mr-3 h-5 w-5 animate-spin text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-6 rounded-md border bg-white">
        <h3 className="text-md border-b px-4 py-6 text-lg font-semibold leading-6 text-dm-charcoal-800">
          Pending Teacher Invites
        </h3>
        <div>
          {pendingTeacherInvites && pendingTeacherInvites.length === 0 && (
            <p className="text-dm-charcoal-800">No pending invites found.</p>
          )}
          {pendingTeacherInvites &&
            pendingTeacherInvites.length > 0 &&
            pendingTeacherInvites.map((invite: any, index: number) => (
              <div
                key={index}
                className="flex w-full justify-between border-b px-4 py-4"
              >
                <span className="">{invite.email}</span>
                <span
                  className=""
                  onClick={() => {
                    setShowRemoveInviteConfirmation(true);
                    setInviteToDelete({ ...invite });
                  }}
                >
                  <TrashIcon className="h-6 cursor-pointer text-dm-gray-200" />
                </span>
              </div>
            ))}
        </div>
      </div>

      {showRemoveInviteConfirmation && inviteToDelete && (
        <DeltaMathConfirmation
          title={`Remove Invite`}
          message={
            <>
              Are you sure you want to remove{" "}
              <DemoMode value={inviteToDelete.email} type="person_email" />
              &apos;s Teacher invite?
            </>
          }
          confirm="Remove"
          confirmAction={() => {
            if (!user.admin) {
              toastContext.addToast({
                status: "Error",
                message: "You don't have permissions to remove this invite.",
              });
            } else {
              removeTeacherInvite.mutate({
                email: inviteToDelete.email,
                schoolid: school.schoolid,
              });
            }
          }}
          cancel="Cancel"
          cancelAction={() => {
            setShowRemoveInviteConfirmation(false);
            setInviteToDelete({ _id: "", email: "", schools: [] });
          }}
        />
      )}
    </>
  );
}
