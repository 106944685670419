import { useEffect, useState } from "react";
import Modal from "../../../student/components/generic/Modal";

type alertType = {
  message: string;
  title?: string;
  buttonText?: string;
  leaveAction?: () => void;
};

const AlertDialogs = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [dialog, setDialog] = useState<alertType | undefined>();

  // Note: the message string in the custom modules can have html
  const alertDialog = (
    message: string,
    title?: string,
    buttonText?: string,
    leaveAction?: () => void
  ) => {
    setDialog({
      message,
      title,
      buttonText,
      leaveAction,
    });
    setShowModal(true);
  };

  const alertMath = (message: string, title?: string, buttonText?: string) => {
    setDialog({
      message,
      title,
      buttonText,
    });
    setShowModal(true);
  };

  const closeModal = () => {
    if (dialog?.leaveAction) {
      dialog?.leaveAction();
    }
    setShowModal(false);
  };

  useEffect(() => {
    window.alertDialog = alertDialog;
    window.alertMath = alertMath;

    return () => {
      delete window.alertDialog;
      delete window.alertMath;
    };
  }, []);

  return (
    <>
      <Modal
        visible={showModal}
        onClose={closeModal}
        title={dialog?.title || "Notice"}
        body={
          dialog ? (
            <span
              role="alertdialog"
              dangerouslySetInnerHTML={{ __html: dialog?.message }}
            ></span>
          ) : undefined
        }
        onConfirm={closeModal}
        confirmButtonText={dialog?.buttonText || "Ok"}
        confirmAriaLabel={dialog?.buttonText || "Ok"}
        noLine
      />
    </>
  );
};

export default AlertDialogs;
