import Button from "../../../student/components/generic/button";
import { LearnerSubscriptionDetails } from "../../types";
import { useState } from "react";
import clsx from "clsx";
import { QuantityChangeModal } from "./QuantityChangeModal";
import { useNavigate } from "react-router-dom";
import { REACT_APP_LEARNER_LINK } from "../../../utils";
import { useMutation } from "react-query";
import axios from "axios";
import { deltamathAPI } from "../../../manager/utils";
import { withJsonHeader } from "../../../shared/axiosUtils";
import { useLearnerContext } from "../../contexts/LearnerContext";

export const AddLearnerButton: React.FC<{
  subscription: LearnerSubscriptionDetails;
  className?: string;
  outline?: boolean;
}> = ({ subscription, className = "", outline = false }) => {
  const navigate = useNavigate();
  const learnerContext = useLearnerContext();
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const newQuantity = subscription.quantity + 1;

  const { mutateAsync: changeQuantityMutation } = useMutation(() => {
    return axios.post(
      `${deltamathAPI()}/payments/subscriptions/update/quantity`,
      JSON.stringify({ quantity: newQuantity }),
      withJsonHeader()
    );
  });

  const handleConfirmChangeQuantity = async () => {
    await changeQuantityMutation();

    const quantity = learnerContext.learner?.subscriptionQuantity ?? 0;
    learnerContext.updateLearner({ subscriptionQuantity: quantity + 1 });

    navigate(`${REACT_APP_LEARNER_LINK}/parent/add-learners`);
  };

  return (
    <>
      <Button
        className={clsx(
          "flex h-10 items-center justify-center gap-2 p-0",
          className
        )}
        type={outline ? "outline" : undefined}
        onClick={() => setShowConfirmModal(true)}
      >
        <i className="far fa-plus-circle text-xl" aria-hidden />
        Add Learner
      </Button>
      <QuantityChangeModal
        visible={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        subscription={subscription}
        newQuantity={newQuantity}
        title="Add a Learner"
        confirmButtonText="Add Learner"
        onConfirm={handleConfirmChangeQuantity}
      />
    </>
  );
};
