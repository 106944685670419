/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
// The above is terrible and needs to be removed before going live.
import React from "react";
import { useMutation } from "react-query";
import axios from "axios";
import { Link } from "react-router-dom";
import DeltaMathTable from "../../shared/DeltaMathTable";
import { deltamathAPI } from "../../manager/utils";
import { DmLoadingSpinner } from "../utils/functions";
import { useDMQuery, timeConverter, REACT_APP_MANAGER_LINK } from "../../utils";
import { EditableTextarea } from "../../shared/table/editable";
import { NoFilter, TextFilter } from "../../shared/table/filters";
import { useDeltaToastContext } from "../../shared/contexts/ToasterContext";
import { useDocumentTitle } from "usehooks-ts";

function InvoicesReport() {
  useDocumentTitle("Invoices Report");
  const toastContext = useDeltaToastContext();

  const { data, isLoading } = useDMQuery({
    path: "/manager_new/quickbooks/invoices",
    customerServiceHeader: true,
    queryOptions: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: 1000 * 60 * 5,
    },
  });

  const updateQuoteMutation = useMutation(
    ({ quoteNumber, invoiceDocNumber, field, value }) => {
      const payload: {
        [key: string]: number | null;
      } = {};

      if (field === "notes") {
        payload[field] = value;
      } else {
        payload[field] = value ? Math.floor(Date.now() / 1000) : null;
      }
      if (invoiceDocNumber) {
        payload["invoiceNumber"] = invoiceDocNumber;
      }

      let apiUrl = deltamathAPI() + `/manager_new/invoices/${quoteNumber}`;
      if (quoteNumber) {
        apiUrl = deltamathAPI() + `/manager_new/invoices/${quoteNumber}`;
      } else if (invoiceDocNumber) {
        apiUrl =
          deltamathAPI() +
          `/manager_new/invoices/docNumber/${invoiceDocNumber}`;
      }
      const token = localStorage.getItem("customer_service_token");
      axios
        .put(apiUrl, payload, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .catch(function (error) {
          if (error.response?.data?.message) {
            toastContext.addToast({
              status: "Error",
              message: error.response.data.message,
            });
          }
        });
    }
  );

  function QuoteStatusFilter({ column: { filterValue, setFilter } }) {
    return (
      <select
        value={filterValue}
        onChange={(e) => {
          e.preventDefault();
          setFilter(e.target.value || undefined);
        }}
        className="form-select m-0
        mt-2
        block
        w-full
        appearance-none
        rounded
        border
        border-solid
        border-gray-300
        bg-white bg-clip-padding bg-no-repeat
        px-3 py-1.5 text-base
        font-normal
        text-gray-700
        transition
        ease-in-out
        focus:border-blue-600 focus:bg-white focus:text-gray-700 focus:outline-none"
      >
        <option value="">All</option>
        <option value="CC/Check Payment Requested">
          CC/Check Payment Requested
        </option>
        <option value="everything_else">Everything Else</option>
      </select>
    );
  }

  function quoteStatusFilterPattern(rows, id, filterValue) {
    if (filterValue === "everything_else") {
      return rows.filter((row) => {
        const rowValue = row.values[id];
        return rowValue !== "CC/Check Payment Requested";
      });
    } else if (filterValue === "CC/Check Payment Requested") {
      return rows.filter((row) => {
        const rowValue = row.values[id];
        return rowValue === filterValue;
      });
    } else {
      return rows;
    }
  }

  const columns = React.useMemo(
    () => [
      {
        Header: "Invoice",
        accessor: "DocNumber",
        minWidth: 80,
        Filter: NoFilter,
        Cell: ({ cell }: { cell: any }) => {
          return (
            <div className="w-100 text-center">
              <a
                className="text-indigo-500 hover:underline"
                href={`https://app.qbo.intuit.com/app/invoice?txnId=${cell.row.original.TxnId}`}
                target="_blank"
                rel="noreferrer"
              >
                {cell.row.original.DocNumber}
              </a>
            </div>
          );
        },
      },
      {
        Header: "Quote",
        minWidth: 80,
        accessor: "dm_quoteNumber",
        Filter: NoFilter,
        Cell: ({ cell }) => (
          <div className="w-100 text-center">
            {cell.row.values.dm_quoteNumber ? (
              <Link
                className="text-indigo-500 hover:underline"
                to={`${REACT_APP_MANAGER_LINK}/quote-lookup/${cell.row.values.dm_quoteNumber}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {cell.row.values.dm_quoteNumber}
              </Link>
            ) : (
              <>-</>
            )}
          </div>
        ),
      },
      {
        Header: "Quote Status",
        minWidth: 190,
        accessor: "dm_status",
        Filter: QuoteStatusFilter,
        filter: quoteStatusFilterPattern,
      },
      {
        Header: "Date",
        minWidth: 120,
        accessor: "TxnDate",
        Filter: NoFilter,
      },
      {
        Header: "Customer",
        minWidth: 180,
        accessor: "CustomerRef",
        Filter: TextFilter,
      },
      {
        Header: "Invoice Printed",
        accessor: "printed_date",
        Filter: NoFilter,
        width: 60,
        Cell: ({ row }: { row: any }) => {
          return (
            <div className="text-center">
              <input
                type="checkbox"
                key={`printed_date_${row.original.DocNumber}`}
                defaultChecked={row.values.printed_date ? true : false}
                onChange={(event) =>
                  updateQuoteMutation.mutate({
                    quoteNumber: row.original.dm_quoteNumber,
                    invoiceDocNumber: row.original.DocNumber,
                    field: "printed_date",
                    value: event.target.checked ? true : false,
                  })
                }
              />
              <div className="mt-2 h-4">
                {row.original.printed_date &&
                  timeConverter(row.original.printed_date)}
              </div>
            </div>
          );
        },
      },
      {
        Header: "Invoice Emailed",
        accessor: "emailed_date",
        Filter: NoFilter,
        width: 80,
        Cell: ({ row }: { row: any }) => {
          return (
            <div className="text-center">
              <input
                key={`emailed_date_${row.original.DocNumber}`}
                type="checkbox"
                defaultChecked={row.values.emailed_date ? true : false}
                onChange={(event) =>
                  updateQuoteMutation.mutate({
                    quoteNumber: row.original.dm_quoteNumber,
                    invoiceDocNumber: row.original.DocNumber,
                    field: "emailed_date",
                    value: event.target.checked ? true : false,
                  })
                }
              />
              <div className="mt-2 h-4">
                {row.original.emailed_date &&
                  timeConverter(row.original.emailed_date)}
              </div>
            </div>
          );
        },
      },
      {
        Header: "Invoice Mailed",
        accessor: "mailed_date",
        width: 80,
        Filter: NoFilter,
        Cell: ({ row }: { row: any }) => {
          return (
            <div className="text-center">
              <input
                type="checkbox"
                key={`mailed_date_${row.original.DocNumber}`}
                defaultChecked={row.values.mailed_date ? true : false}
                onChange={(event) =>
                  updateQuoteMutation.mutate({
                    quoteNumber: row.original.dm_quoteNumber,
                    invoiceDocNumber: row.original.DocNumber,
                    field: "mailed_date",
                    value: event.target.checked ? true : false,
                  })
                }
              />
              <div className="mt-2 h-4">
                {row.original.mailed_date &&
                  timeConverter(row.original.mailed_date)}
              </div>
            </div>
          );
        },
      },
      {
        Header: "Print Ready",
        accessor: "sent_ack",
        width: 80,
        Filter: NoFilter,
        Cell: ({ row }: { row: unknown }) => {
          return (
            <div className="text-center">
              <input
                type="checkbox"
                key={`sent_ack_${row.original.DocNumber}`}
                defaultChecked={row.values.sent_ack ? true : false}
                onChange={(event) =>
                  updateQuoteMutation.mutate({
                    quoteNumber: row.original.dm_quoteNumber,
                    invoiceDocNumber: row.original.DocNumber,
                    field: "sent_ack",
                    value: event.target.checked ? true : false,
                  })
                }
              />
              <div className="mt-2 h-4">
                {row.original.sent_ack && timeConverter(row.original.sent_ack)}
              </div>
            </div>
          );
        },
      },
      {
        Header: "Invoice",
        accessor: "",
        minWidth: 80,
        Filter: "",
        Cell: ({ cell }: { cell: any }) => {
          return (
            <div className="w-100 text-center">
              <a
                className="text-indigo-500 hover:underline"
                href={`https://app.qbo.intuit.com/app/invoice?txnId=${cell.row.original.TxnId}`}
                target="_blank"
                rel="noreferrer"
              >
                {cell.row.original.DocNumber}
              </a>
            </div>
          );
        },
      },
      {
        Header: "Quote Notes",
        accessor: "dm_notes",
        minWidth: 360,
        Cell: ({ cell }: { cell: any }) => (
          <EditableTextarea
            key={cell.row.original.DocNumber}
            value={cell.row.values.dm_notes}
            row={cell.row}
            updateMutation={updateQuoteMutation}
          />
        ),
      },
    ],
    []
  );

  return (
    <>
      {isLoading && (
        <DmLoadingSpinner message="Loading data from Quickbooks..." />
      )}

      {data && Array.isArray(data) && data.length > 0 && (
        <DeltaMathTable
          columns={columns}
          data={data}
          updateMutation={updateQuoteMutation}
          options={{
            globalFilter: true,
            initialState: {
              filters: [
                {
                  id: "dm_status",
                  value: "everything_else",
                },
              ],
            },
            stickyHeader: true,
          }}
        />
      )}

      {!isLoading && data && !Array.isArray(data) && (
        <div>There was an issue loading invoice data from Quickbooks.</div>
      )}
    </>
  );
}

export default InvoicesReport;
