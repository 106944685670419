import { useLearnerContext } from "../contexts/LearnerContext";
import { LoadingIconKey } from "../types";

/** Get the current loading icon choice from learner context */
export function useLoadingIcon(): LoadingIconKey | undefined {
  const { learner } = useLearnerContext();
  const { image } = learner?.prizesState?.loadingIcon ?? {};
  if (!learner) {
    return undefined;
  }
  switch (image) {
    case "disco":
    case "idea":
    case "skateboard":
    case "default":
      return image;
    default:
      return "default";
  }
}
