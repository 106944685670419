import { printClockSentence } from "../utils";
import { useState, useRef } from "react";

type Props = {
  targetTime: number;
  verticalBar?: boolean;
  isUnlimited?: boolean;
};
export default function Countdown({
  targetTime,
  verticalBar,
  isUnlimited = false,
}: Props): JSX.Element {
  const countDownDate = new Date(targetTime * 1000).getTime();

  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);

  const [timeLeft, setTimeLeft] = useState(
    countDownDate - new Date().getTime()
  );

  const [timerText, setTimerText] = useState(
    isUnlimited
      ? "Unlimited"
      : printClockSentence(countDownDate - new Date().getTime())
  );

  if (intervalRef.current) clearInterval(intervalRef.current);

  intervalRef.current = setInterval(() => {
    const nowTime = new Date().getTime();
    setTimeLeft(countDownDate - nowTime);
    if (!isUnlimited) setTimerText(printClockSentence(countDownDate - nowTime));

    if (countDownDate - nowTime <= 0 && intervalRef?.current)
      clearInterval(intervalRef.current);
  }, 1000);

  const nothingLeft = timeLeft <= 0;

  if (nothingLeft) {
    return (
      <div className="whitespace-nowrap text-dm-error-500">
        <span className="font-bold">No Time Remaining</span>
        {verticalBar && (
          <span className="hidden text-dm-gray-200 sm:inline"> |</span>
        )}
      </div>
    );
  }

  return (
    <div className="whitespace-nowrap text-dm-error-500">
      <span className="font-bold">Time Remaining: </span>
      {timerText}
      {verticalBar && (
        <span className="hidden text-dm-gray-200 sm:inline"> |</span>
      )}
    </div>
  );
}
