import { PropsWithChildren, useEffect, useState } from "react";
import { useDebounceValue } from "usehooks-ts";
import { Hexagon } from "./Hexagon";
import clsx from "clsx";
import { useLearnerContext } from "../../contexts/LearnerContext";
import { uniqueId } from "lodash";

export const PascalsCell: React.FC<
  PropsWithChildren<{
    currentValue: number | null;
    expectedValue: number;
    isEditable: boolean;
    isAutofill?: boolean;
    isHighlighted: boolean;
    isSelected?: boolean;
    showPlus: boolean;
    setActiveCell: () => void;
    redeemPoints: () => void;
    ariaLabel?: string;
  }>
> = ({
  currentValue,
  expectedValue,
  isEditable,
  isAutofill = false,
  isHighlighted,
  isSelected = false,
  showPlus,
  setActiveCell,
  redeemPoints,
  ariaLabel,
}) => {
  const { pointsAvailable } = useLearnerContext();
  const [newValue, setNewValue] = useState<number | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [debouncedNewValue] = useDebounceValue(newValue, 1000);
  const id = uniqueId("cell");
  const [blurred, setBlurred] = useState(false);

  const handleSubmit = (value: number | null) => {
    if (value !== null) {
      if (value !== expectedValue) {
        setError("Try again");
      } else if (pointsAvailable !== null && value > pointsAvailable) {
        setError("Not enough points");
      } else {
        redeemPoints();
        setError(null);
      }
    } else {
      setError(null);
    }
  };

  useEffect(() => {
    if (!blurred) {
      handleSubmit(debouncedNewValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedNewValue]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError(null);
    const v = parseInt(e.target.value, 10);
    setNewValue(isNaN(v) ? null : v);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();
      handleSubmit(newValue);
    }
  };

  const handleFocus = () => {
    setBlurred(false);
    setActiveCell();
  };

  const handleBlur = () => {
    setBlurred(true);
    handleSubmit(newValue);
  };

  const hexagonState = () => {
    if (error) {
      return "error";
    }
    if (isEditable || isAutofill) {
      return "available";
    }
    if (isHighlighted) {
      return "hint";
    }
    if (isSelected) {
      return "selected";
    }
    if (expectedValue === 0) {
      return "not_available";
    }
    return "filled";
  };

  const zIndex = () => {
    if (error) {
      return "z-20";
    }
    if (isEditable) {
      return "z-0";
    }
    return "z-10";
  };

  return (
    <>
      <div
        className={clsx(
          "relative -ml-[1px] flex h-[24px] w-[42px] shrink-0 items-center justify-center",
          zIndex()
        )}
      >
        {isEditable ? (
          <input
            className={clsx(
              "text-md z-10 h-6 w-6 border-0 border-b bg-transparent p-0 text-center font-sans focus:ring-0",
              error
                ? "!border-b-dm-error-600 text-dm-error-600"
                : "border-b-dm-purple-800 text-dm-purple-800"
            )}
            type="number"
            value={newValue || ""}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onFocus={handleFocus}
            onBlur={handleBlur}
            name={id}
            aria-label={ariaLabel}
          />
        ) : (
          <span
            className={clsx(
              "z-10",
              isSelected ? "text-white" : "text-dm-purple-800"
            )}
            {...(ariaLabel
              ? {
                  "aria-label": ariaLabel,
                  role: "img",
                }
              : {
                  "aria-hidden": true,
                })}
          >
            {currentValue}
          </span>
        )}
        <span
          className={clsx(
            "absolute -top-[11px] left-0",
            isEditable && "hexagonPulse"
          )}
          aria-hidden={true}
        >
          <Hexagon state={hexagonState()} />
        </span>
        {showPlus && (
          <span
            className="leading-0 absolute -left-[5px] top-1/2 z-10 -mt-[7px] flex h-[16px] items-center bg-dm-purple-200 text-dm-purple-800"
            aria-hidden={true}
          >
            +
          </span>
        )}
        {error && (
          <span className="absolute left-1/2 top-full mt-3 flex w-0 justify-center">
            <span
              className="whitespace-nowrap rounded-md border border-dm-error-500 bg-dm-error-200 px-2 py-1 text-xs text-dm-error-600"
              role="alert"
            >
              {error}
            </span>
          </span>
        )}
        {}
      </div>
    </>
  );
};
