import React, { useMemo, useState } from "react";
import { useDMQuery } from "../../../utils";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import DeltaMathTable from "../../../shared/DeltaMathTable";
import JsonDisplay from "../../../shared/JSONDisplay";
import { useDocumentTitle } from "usehooks-ts";
import { TextFilterNoPersist } from "../../../shared/table/filters";

interface ITeacherAssignment {
  name?: string;
  last_manual_sync_time?: number;
}

interface IStudentAssignment {
  _id: number;
  teacher_id: number;
  grade: number;
  lti_grade_passback?: {
    passback_timestamp?: number;
    lti_auth_req?: Record<string, unknown>;
    failure_timestamp?: number;
    success_timestamp?: number;
    passback_attempts?: number;
    grade?: number;
    err?: string;
  };
  student_info: {
    first: string;
    last: string;
    login: string;
    lms_email?: string;
  }[];
}

const GradePassback = () => {
  useDocumentTitle("Grade Passback");
  const [teacherId, setTeacherId] = useState<string>("");
  const [studentAssignments, setStudentAssignments] = useState<
    IStudentAssignment[] | undefined
  >(undefined);
  const [teacherAssignment, setTeacherAssignment] = useState<
    ITeacherAssignment | undefined
  >(undefined);

  const toastContext = useDeltaToastContext();

  const { isFetching } = useDMQuery({
    path: `/manager_new/integrations/grade-passback/${teacherId}`,
    queryOptions: {
      enabled: teacherId.length > 0,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      onSuccess: (data: any) => {
        setTeacherAssignment(data.teacherAssignment);
        setStudentAssignments(data.studentAssignments);
      },
      onError: (error: any) => {
        setStudentAssignments([]);
        setTeacherAssignment(undefined);
        toastContext.addToast({
          status: "Error",
          message:
            error.message ||
            "An error occurred while fetching the grade passback data.",
        });
      },
    },
  });

  const getMeaningFromCode = (code: number) => {
    switch (code) {
      case 5000000000:
        return "teacher must press the cloud for sync";
      case 6000000000:
        return "set when processing, should be overwritten on success or failure";
      case 7000000000:
        return "set on success";
      case 8000000000:
        return "set on specific failure cases";
      case 9000000000:
        return "set after too many failures";
      default:
        return new Date(code * 1000).toLocaleString();
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Student ID",
        accessor: "student_id",
        align: "left",
        Filter: TextFilterNoPersist,
      },
      {
        Header: "Student Name",
        accessor: (row: any): string =>
          `${row.student_info?.[0]?.first} ${row.student_info?.[0]?.last}`,
        align: "left",
        Filter: TextFilterNoPersist,
      },
      {
        Header: "Login / LMS Email",
        accessor: (row: any): string => {
          const lms_email = row.student_info?.[0]?.lms_email;
          const login = row.student_info?.[0]?.login;
          return lms_email && lms_email !== "" ? lms_email : login;
        },
        align: "left",
        Filter: TextFilterNoPersist,
      },
      {
        Header: "Grade",
        accessor: "grade",
        align: "left",
      },
      {
        Header: "Grade Passback (LTI or Google)",
        accessor: (row: any) => {
          const { lti_grade_passback, google_classroom_grade_passback } = row;
          if (
            typeof lti_grade_passback === "object" &&
            Object.keys(lti_grade_passback).length > 0
          ) {
            return lti_grade_passback;
          } else if (
            typeof google_classroom_grade_passback === "object" &&
            Object.keys(google_classroom_grade_passback).length > 0
          ) {
            return google_classroom_grade_passback;
          } else {
            return null;
          }
        },
        Cell: ({ value }: { value: any }) => {
          return value ? <JsonDisplay data={value} /> : "none / empty";
        },
        align: "left",
      },

      {
        Header: "Passback Time / Code Meaning",
        accessor: "lti_grade_passback.passback_timestamp_meaning",
        Cell: ({ row }: { row: any }) => {
          if (row.original.lti_grade_passback?.passback_timestamp) {
            return getMeaningFromCode(
              row.original.lti_grade_passback.passback_timestamp
            );
          } else if (
            row.original.google_classroom_grade_passback?.passback_timestamp
          ) {
            return getMeaningFromCode(
              row.original.google_classroom_grade_passback.passback_timestamp
            );
          } else {
            return "--";
          }
        },
        align: "left",
      },
    ],
    []
  );

  return (
    <>
      <div className="group relative bg-white p-6">
        <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div className="sm:col-span-4">
            <form
              className="mt-2"
              onSubmit={(event) => {
                event.preventDefault();
                const teacherID = new FormData(
                  event.target as HTMLFormElement
                ).get("teacher_id") as string;
                setTeacherId(teacherID);
              }}
            >
              <label
                htmlFor="nces"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Search by Assignment Teacher ID
              </label>
              <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                <input
                  type="text"
                  name="teacher_id"
                  id="teacher_id"
                  className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6"
                />
                <button
                  type="submit"
                  className="rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                  Search &rarr;
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {isFetching && <p className="m-4">Searching...</p>}
      {studentAssignments?.length === 0 && !isFetching && (
        <p className="m-4">No Results to Display</p>
      )}

      {teacherAssignment &&
        studentAssignments &&
        studentAssignments.length > 0 &&
        !isFetching && (
          <>
            <h1 className="mx-4 py-4 text-2xl font-semibold text-gray-900">
              Student Assignments for {teacherAssignment?.name}
            </h1>
            <h2 className="mx-4 text-lg font-semibold">
              Last Manual Sync:{" "}
              {teacherAssignment?.last_manual_sync_time
                ? new Date(
                    teacherAssignment.last_manual_sync_time * 1000
                  ).toLocaleDateString()
                : "Not Available"}
            </h2>

            <div className="m-4">
              <DeltaMathTable
                columns={columns}
                data={studentAssignments}
                key="launch-logs"
              />
            </div>
          </>
        )}
    </>
  );
};

export default GradePassback;
