const AssignmentsIcon = ({ classes = "" }: { classes?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      className={classes}
    >
      <rect width="40" height="40" rx="20" fill="#DBEAFE" />
      <path
        d="M17.5556 20H22.8889M17.5556 23.5556H22.8889M24.6667 28H15.7778C14.7959 28 14 27.2041 14 26.2222V13.7778C14 12.7959 14.7959 12 15.7778 12H20.7429C20.9787 12 21.2048 12.0937 21.3715 12.2603L26.1841 17.073C26.3508 17.2397 26.4444 17.4658 26.4444 17.7015V26.2222C26.4444 27.2041 25.6485 28 24.6667 28Z"
        stroke="#2F52E9"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default AssignmentsIcon;
