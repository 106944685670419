import clsx from "clsx";
import Button from "../../../student/components/generic/button";
import { useProblemSolvingContext } from "../../contexts/ProblemSolvingContext";

export const SubmitAnswerButton: React.FC<{
  submit: () => void;
  isCheckAnswerLoading: boolean;
  className?: string;
}> = ({ submit, isCheckAnswerLoading, className }) => {
  const { assignment } = useProblemSolvingContext();

  return (
    <Button
      onClick={submit}
      id="submit-answer-form"
      type="submitAnswer"
      size="xsmall"
      className={clsx("submit-answer-form m-1", className)}
      {...(assignment.type !== "practice"
        ? {
            isLoading: isCheckAnswerLoading,
          }
        : {})}
    >
      Submit Answer
    </Button>
  );
};
