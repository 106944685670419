import { useEffect } from "react";
import { snakeScript } from "../../../utils/snakeScript";
import { ContentPrizeModalProps } from "../../../types";

export const SnakeGame: React.FC<ContentPrizeModalProps> = ({
  setTitle,
  winFunc,
  loseFunc,
  numWins,
}) => {
  useEffect(() => {
    setTitle("DeltaMath Snake");
    snakeScript(winFunc, loseFunc, numWins);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div
        className="clear-both my-5 text-center font-serif text-lg font-bold sm:text-2xl"
        style={{ minHeight: "2rem" }}
        id="live-question-prompt"
      />
      <div
        className="canv-div-wrapper col-xs-12"
        style={{ margin: "0", padding: "0" }}
      >
        <svg id="snake-game-canvas" className="touch-none" />
      </div>
      <div
        className="col-xs-12 clear-both my-5 text-center"
        style={{ height: "2rem" }}
        id="live-solution"
      />
    </>
  );
};
