import { processChoices, getCommonFactors } from "./snakeGameProblemGenerator";
import { colors } from "./gameFunctions";
import { union } from "lodash";

const { rand, gcd, $, color } = window;

/** Find double, triple or half of a number
 * @return problem object with the following properties: questionPrompt (string with html), question?  (string), choices (array of 4 strings representing answer choices with the correct answer always at index 0), solution function to run
 */
export function gcfLcm() {
  const types = ["gcf", "lcm"];
  const type = types[rand(0, types.length - 1)];

  switch (type) {
    case "gcf":
      return gcf();
    case "lcm":
      return lcm();
    default:
      break;
  }
}

/** Find the gcf of 2 numbers
 * @return problem object with the following properties: questionPrompt (string with html), question? (string), choices (array of 4 strings representing answer choices with the correct answer always at index 0), solution function to run
 */
function gcf() {
  const common = rand(4, 10);

  let a, b;
  do {
    a = common * rand(1, Math.floor(50 / common));
    b = common * rand(1, Math.floor(50 / common));
  } while (a === b);

  const correctAnswer = gcd(a, b);

  let choices = union(
    getCommonFactors(a, b),
    getCommonFactors(a, a),
    getCommonFactors(b, b)
  );
  choices.push((a / correctAnswer) * b); // lcm

  choices = processChoices(choices, correctAnswer);
  if (choices.length !== 4) return gcf();

  const solution = (id) => {
    const green = colors["success-500"];
    $(id).html(
      `\\(${a}=${a / correctAnswer}\\times${color(correctAnswer, green)}
      \\hspace{10px}${b}=${b / correctAnswer}\\times${color(
        correctAnswer,
        green
      )}
      \\hspace{10px}${color("GCF=" + correctAnswer, green)}\\)`
    );
  };

  return {
    questionPrompt: `What is the greatest common factor of \\(${a}\\) and \\(${b}\\)?`,
    choices,
    solution,
  };
}

/** Find the lcm of 2 numbers
 * @return problem object with the following properties: questionPrompt (string with html), question? (string), choices (array of 4 strings representing answer choices with the correct answer always at index 0), solution function to run
 */
function lcm() {
  const numbers = [2, 3, 4, 5, 6, 8, 9, 10, 12, 15];

  const a1 = numbers[rand(0, numbers.length - 1)];
  const numbs = numbers.filter((x) => x !== a1 && gcd(x, a1) > 1);
  const b1 = numbs[rand(0, numbs.length - 1)];

  const a = Math.min(a1, b1);
  const b = Math.max(a1, b1);

  const gcdValue = gcd(a, b);
  const correctAnswer = (a / gcdValue) * b;

  const incorrectMultiples = [];
  let i = 2;
  while (i * a < correctAnswer * 2) {
    if (i * a !== correctAnswer) incorrectMultiples.push(i * a);
    i++;
  }

  let choices = [
    gcdValue,
    correctAnswer * 2,
    correctAnswer * 3,
    ...incorrectMultiples,
  ];

  choices = processChoices(choices, correctAnswer);

  const solution = (id) => {
    const green = colors["success-500"];
    const blue = colors["dm-brand-blue-500"];
    const red = colors["error-500"];
    const aFactor = a / gcdValue;
    const bFactor = b / gcdValue;
    $(id).html(
      `\\(${a}=${color(aFactor, blue)}\\times${color(gcdValue, red)}
      \\hspace{10px}${b}=${color(bFactor, blue)}\\times${color(gcdValue, red)}
      \\hspace{10px}${color("GCF=" + gcdValue, red)}
      \\hspace{10px}${color("LCM", green)}=${color(
        aFactor,
        blue
      )} \\times ${color(bFactor, blue)} \\times ${color(
        gcdValue,
        red
      )} = ${color(correctAnswer, green)}\\)`
    );
  };

  return {
    questionPrompt: `What is the least common multiple of \\(${a}\\) and \\(${b}\\)?`,
    choices,
    solution,
  };
}
