import { useEffect } from "react";
import { findIndex, clamp } from "lodash";
import {
  Skill,
  SubunitProgress,
  LearnerAssignmentSkillData,
} from "../../../types";
import PracticeLanding from "./PracticeLanding";
import SubunitsSolving from "../SubunitsSolving";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { REACT_APP_LEARNER_LINK } from "../../../../utils";

type Props = {
  skills: Skill[];
  courseId: string;
  coursePath?: string;
  unitId: string;
  unitPath?: string;
  subunitId: string;
  subunitPath?: string;
  subunitProgress: SubunitProgress | undefined;
};

const Practice = (props: Props) => {
  const { indexOfSkill } = useParams();
  const nav = useNavigate();

  const location = useLocation();

  const isLanding = !!location.state?.landing;

  const skillGroup = props.subunitProgress?.practice?.assignment?.skills || [];
  const skillsCount = props.skills?.length || 0;

  const allSkillsComplete =
    props.subunitProgress?.practice?.assignment &&
    skillGroup.every((s) => s.skillComplete === true);

  const getPageState = () => {
    if (!props.subunitProgress?.practice) {
      return "notInitialized";
    } else if (allSkillsComplete) {
      return "finished";
    } else {
      return "solving";
    }
  };

  const pageState = getPageState();

  const firstUnsolved = clamp(
    findIndex(
      skillGroup,
      (skill: LearnerAssignmentSkillData) => !skill.skillComplete
    ),
    0,
    skillsCount - 1
  );

  const preQuizComplete =
    !!props.subunitProgress?.skippedPreQuiz ||
    !!props.subunitProgress?.preQuiz?.assignment?.submitted;

  const skippedPreQuiz =
    !!props.subunitProgress?.skippedPreQuiz && !props.subunitProgress?.preQuiz;

  const baseUrl = `${REACT_APP_LEARNER_LINK}/${props.coursePath}/${props.unitPath}/${props.subunitPath}`;

  // This will handle to make sure that we don't see the default landing page if we already initialize
  // It will also make sure users are not navigating to questions without initializing the pre-quiz first
  useEffect(() => {
    if (isLanding) {
      return;
    } else if (!preQuizComplete) {
      nav(`${baseUrl}/prequiz`, { replace: true });
    } else if (
      ["solving", "finished"].includes(pageState) &&
      indexOfSkill === undefined
    ) {
      // Initialize to first required/unsolved skill
      nav(`${baseUrl}/practice/${firstUnsolved}`, { replace: true });
    } else if (pageState === "notInitialized" && indexOfSkill !== undefined) {
      nav(`${baseUrl}/practice`, { replace: true });
    }
  }, [
    pageState,
    indexOfSkill,
    isLanding,
    preQuizComplete,
    nav,
    baseUrl,
    firstUnsolved,
  ]);

  return (
    <>
      {(isLanding ||
        pageState === "notInitialized" ||
        !props.subunitProgress) && (
        <PracticeLanding
          {...props}
          firstUnsolved={firstUnsolved}
          skippedPreQuiz={skippedPreQuiz}
        />
      )}
      {!isLanding && props.subunitProgress?.practice?.assignment && (
        <SubunitsSolving
          assignment={props.subunitProgress.practice.assignment}
          preQuizAssignment={props.subunitProgress.preQuiz?.assignment}
          skills={props.skills}
        />
      )}
    </>
  );
};

export default Practice;
