import clsx from "clsx";
import { ChangeEventHandler } from "react";

export const TextField: React.FC<{
  label: string;
  value: string;
  error: string | undefined;
  onChange: ChangeEventHandler<HTMLInputElement>;
  type?: "text" | "email";
}> = ({ label, value, error, onChange, type = "text" }) => {
  const id = label.toLowerCase().replace(" ", "-");
  return (
    <div className="flex flex-col gap-1">
      <label
        className={clsx(
          "text-sm font-bold leading-6",
          error ? "text-dm-error-500" : "text-dm-charcoal-600"
        )}
        htmlFor={id}
      >
        {label}
      </label>
      <input
        className={clsx(
          "rounded-md",
          error ? "border-dm-error-500" : "border-dm-gray-200"
        )}
        id={id}
        type={type}
        value={value}
        onChange={onChange}
        required
      />
      {error && (
        <p className="text-xs text-dm-error-500" role="alert">
          {error}
        </p>
      )}
    </div>
  );
};
