import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/solid";
import { LogData, LearnerAssignmentTypes } from "../../types";
import Button from "../../../student/components/generic/button";
import { PlayIcon } from "@heroicons/react/outline";
import { useState } from "react";
import ExampleModal from "../Modals/ExampleModal";
import VideoModal from "../Modals/VideoModal";
import Document from "../../../student/components/icons/Document";
import QuestionSwap from "../Points/Prizes/QuestionSwap";
import TestHint from "../Points/Prizes/TestHint";
import { useLearnerAnalytics } from "../../analytics/useLearnerAnalytics";
import { viewedExamplesPracticeEvent } from "../../analytics/events";
import { useParams } from "react-router-dom";

type Props = {
  skillCode?: string;
  logData?: LogData | undefined;
  skippedProblem?: boolean;
  optional?: boolean;
  questionTitle?: string;
  solutionShowing?: boolean;
  showExampleButton?: boolean;
  postQuizOrTest?: boolean;
  skillName?: string;
  baseUrl?: string;
  skillPracticeUrl?: string;
  skillIndex?: number;
  practiceInitialized?: boolean;
  courseId?: string;
  assignmentId?: string;
  assignmentType: LearnerAssignmentTypes;
  questionSwap?: {
    unlocked: boolean;
    type?: LearnerAssignmentTypes;
  };
  testHint?: {
    unlocked: boolean;
    used: boolean;
    type?: LearnerAssignmentTypes;
  };
  showVideo?: boolean;
};

const StatusTags = (props: Props) => {
  const { indexOfSkill } = useParams();
  const [showExampleModal, setShowExampleModal] = useState<boolean>(false);
  const [showVideo, setShowVideo] = useState<boolean>(false);

  const { track, getAssignmentData } = useLearnerAnalytics();

  const handleShowExampleModal = () => {
    setShowExampleModal(true);
    track(
      viewedExamplesPracticeEvent({
        ...getAssignmentData(props.assignmentType),
        skillCode: props.skillCode || "unknown",
        skillNumber: indexOfSkill ? parseInt(indexOfSkill, 10) : -1,
      })
    );
  };

  // TODO: Wire up linking to practice, this will get a bit weirder when linking to practice from post-quiz

  return (
    <div className="mb-6">
      {props.skillCode && (
        <VideoModal
          visible={showVideo}
          onClose={() => setShowVideo(false)}
          skillCode={props.skillCode}
          courseId={props.courseId}
          assignmentId={props.assignmentId}
          assignmentType={props.assignmentType}
        />
      )}
      {props.skillCode &&
        (props.showExampleButton ||
          (props.postQuizOrTest &&
            props.solutionShowing &&
            !props.practiceInitialized)) && (
          <ExampleModal
            visible={showExampleModal}
            onClose={() => {
              setShowExampleModal(false);
            }}
            skillCode={props.skillCode}
          />
        )}
      <div className="flex w-full items-center justify-between pb-2">
        <div className="flex flex-wrap gap-x-2 gap-y-1">
          <h5 className="text-lg font-bold" aria-live="assertive">
            {props.solutionShowing
              ? "Solution"
              : props.questionTitle || "Question"}
          </h5>
          {props.postQuizOrTest &&
            props.questionSwap?.unlocked &&
            props.assignmentId && (
              <QuestionSwap
                assignmentId={props.assignmentId}
                type={props.questionSwap?.type}
                isSolutionShowing={props.solutionShowing}
              />
            )}
          {props.postQuizOrTest &&
            props.testHint?.unlocked &&
            !props.testHint?.used &&
            props.assignmentId && (
              <TestHint
                assignmentId={props.assignmentId}
                type={props.questionSwap?.type}
                isSolutionShowing={props.solutionShowing}
              />
            )}
          {props.optional !== undefined ? (
            props.optional ? (
              <div className="whitespace-nowrap rounded-full border bg-dm-success-200 px-2 py-0.5 font-sans text-sm">
                <i
                  className="far fa-thumbs-up fa-fw mr-1 leading-none !text-dm-success-500"
                  aria-hidden="true"
                />
                Optional
              </div>
            ) : (
              <div className="rounded-full border bg-dm-charcoal-800 px-2 py-0.5 font-sans text-sm text-white">
                Required
              </div>
            )
          ) : (
            <div></div>
          )}
        </div>
        {props.logData &&
        props.logData.correct !== undefined &&
        props.solutionShowing ? (
          <>
            {!props.skippedProblem && props.logData?.correct === 1 ? (
              <div className="inline-flex items-center rounded-full border border-dm-success-500 bg-dm-success-100 py-0.5 pl-0.5 pr-4 font-sans sm:py-1 sm:pl-2 sm:pr-6">
                <CheckCircleIcon className="w-10 pr-1 text-dm-success-500 sm:w-14" />
                <span id="correct">Correct</span>
              </div>
            ) : (
              <div className="inline-flex items-center rounded-full border border-dm-error-500 bg-dm-error-100 py-0.5 pl-0.5 pr-4 font-sans sm:py-1 sm:pl-2 sm:pr-6">
                <XCircleIcon className="w-10 pr-1 text-dm-error-500 sm:w-14" />
                <span id="incorrect">Incorrect</span>
              </div>
            )}
          </>
        ) : (
          <>
            {props.showExampleButton && props.skillCode && (
              <div>
                <div className="flex items-center gap-4">
                  {props.showVideo && (
                    <Button
                      type="outline"
                      onClick={() => setShowVideo(true)}
                      className="inline-flex cursor-pointer items-center gap-x-1.5 max-sm:text-sm"
                    >
                      <PlayIcon className="-ml-0.5 w-5" />
                      <p className="hidden md:block">Watch Video</p>
                      <p className="block md:hidden">Video</p>
                    </Button>
                  )}
                  <Button
                    type="outline"
                    onClick={() => {
                      handleShowExampleModal();
                    }}
                    className="inline-flex cursor-pointer items-center gap-x-1.5 max-sm:text-sm"
                  >
                    <p className="hidden md:block">Show Examples</p>
                    <p className="block md:hidden">Examples</p>
                  </Button>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      {props.postQuizOrTest && props.solutionShowing && (
        <div className="flex gap-x-6 max-md:flex-col max-md:text-sm md:items-center">
          {!props.practiceInitialized && props.skillCode ? (
            <Button
              type="link"
              onClick={() => setShowExampleModal(true)}
              className="cursor-pointer !py-1"
            >
              <div className="flex items-center gap-1">
                <Document classes="w-4" strokeColor={"#9CA3AF"} />
                View Examples of {props.skillName}
              </div>
            </Button>
          ) : (
            <Button
              type="link"
              className="cursor-pointer !py-1"
              href={
                props.skillPracticeUrl
                  ? props.skillPracticeUrl
                  : `${props.baseUrl}/practice/${props.skillIndex}`
              }
              target="_blank"
            >
              <div className="flex items-center gap-1">
                <Document classes="w-4" strokeColor={"#9CA3AF"} />
                Practice {props.skillName}
              </div>
            </Button>
          )}
          <div className="text-dm-charcoal-200 max-md:hidden">|</div>
          <Button
            type="link"
            className="cursor-pointer !py-1"
            onClick={() => setShowVideo(true)}
          >
            <div className="flex items-center gap-1">
              <PlayIcon className="-ml-0.5 w-5 text-dm-gray-200" />
              Watch {props.skillName}
            </div>
          </Button>
        </div>
      )}
    </div>
  );
};

export default StatusTags;
