import { Skeleton, SkeletonCircle, SkeletonText } from "../Skeleton";

export const CoursesLoading: React.FC = () => {
  const fakeCourses = [
    {
      name: "Algebra",
      units: "8 units",
      description:
        "Lorem ipsum dolor sit amet consectetur. Tellus lacus et lorem vulputate. Amet donec vivamus",
    },
    {
      name: "Algebra 2",
      units: "8 units",
      description:
        "Lorem ipsum dolor sit amet consectetur. Tellus lacus et lorem vulputate. Amet donec vivamus",
    },
    {
      name: "Geometry",
      units: "8 units",
      description:
        "Lorem ipsum dolor sit amet consectetur. Tellus lacus et lorem vulputate. Amet donec vivamus",
    },
    {
      name: "Calculus",
      units: "8 units",
      description:
        "Lorem ipsum dolor sit amet consectetur. Tellus lacus et lorem vulputate. Amet donec vivamus",
    },
    {
      name: "Statistics",
      units: "8 units",
      description:
        "Lorem ipsum dolor sit amet consectetur. Tellus lacus et lorem vulputate. Amet donec vivamus",
    },
    {
      name: "Computer Science",
      units: "8 units",
      description:
        "Lorem ipsum dolor sit amet consectetur. Tellus lacus et lorem vulputate. Amet donec vivamus",
    },
  ];

  return (
    <>
      <header className="mb-6 flex flex-row items-center gap-4 rounded-lg border border-dm-charcoal-100 bg-white px-8 py-6">
        <h1 className="flex-1 font-serif text-2xl font-bold !leading-10 text-dm-charcoal-800">
          <SkeletonText>All Courses</SkeletonText>
        </h1>
        <div className="h-10 w-full flex-1">
          <Skeleton></Skeleton>
        </div>
      </header>
      <div className="grid grid-cols-1 content-stretch justify-stretch gap-4 md:grid-cols-2">
        {fakeCourses.map((course) => (
          <div
            key={course.name}
            className="flex h-full flex-row gap-6 rounded-lg border border-dm-charcoal-100 bg-white px-8 py-6"
          >
            <div className="flex h-12 w-12 shrink-0 items-center justify-center md:h-16 md:w-16 xl:h-32 xl:w-32">
              <SkeletonCircle />
            </div>
            <div>
              <h2 className="mb-1 text-lg">
                <SkeletonText>{course.name}</SkeletonText>
              </h2>
              <div className="my-2 text-sm !leading-none">
                <SkeletonText>{course.units}</SkeletonText>
              </div>
              <div className="text-sm !leading-7">
                <SkeletonText>{course.description}</SkeletonText>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
