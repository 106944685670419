const StudentsIcon = ({ classes = "" }: { classes?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      className={classes}
    >
      <rect width="40" height="40" rx="20" fill="#E9F9F1" />
      <path
        d="M25 28H30V26C30 24.3431 28.6569 23 27 23C26.0444 23 25.1931 23.4468 24.6438 24.1429M25 28H15M25 28V26C25 25.3438 24.8736 24.717 24.6438 24.1429M24.6438 24.1429C23.9065 22.301 22.1052 21 20 21C17.8948 21 16.0935 22.301 15.3562 24.1429M15 28H10V26C10 24.3431 11.3432 23 13 23C13.9556 23 14.8069 23.4468 15.3562 24.1429M15 28V26C15 25.3438 15.1264 24.717 15.3562 24.1429M23 15C23 16.6568 21.6569 18 20 18C18.3431 18 17 16.6568 17 15C17 13.3432 18.3431 12 20 12C21.6569 12 23 13.3432 23 15ZM29 18C29 19.1046 28.1046 20 27 20C25.8954 20 25 19.1046 25 18C25 16.8954 25.8954 16 27 16C28.1046 16 29 16.8954 29 18ZM15 18C15 19.1046 14.1046 20 13 20C11.8954 20 11 19.1046 11 18C11 16.8954 11.8954 16 13 16C14.1046 16 15 16.8954 15 18Z"
        stroke="#078445"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default StudentsIcon;
