import React, { useEffect, useRef } from "react";
import Button from "./button";
import renderMathInElement from "../../utils/auto-render";
import clsx from "clsx";
import FocusTrap from "focus-trap-react";
import { IconButton } from "./IconButton";
import { uniqueId } from "lodash";

export type ModalProps = {
  visible: boolean;
  onClose: () => void;
  onConfirm?: () => void;
  header?: string;
  title?: string | React.ReactElement;
  body?: string | React.ReactElement;
  secondaryButtonText?: string;
  secondaryAriaLabel?: string;
  secondaryOnClick?: () => void;
  secondaryDisabled?: boolean;
  confirmButtonText?: string;
  confirmAriaLabel?: string;
  confirmDisabled?: boolean;
  noLine?: boolean;
  largeModal?: boolean;
  renderMath?: boolean;
  footer?: React.ReactElement;
  closing?: boolean;
  noEsc?: boolean;
  closeX?: boolean;
  initialFocus?: "close" | "confirm"; // could add "secondary" here later
  maximizeContentSmallScreen?: boolean;
};

const FullGenericModal = ({
  onClose,
  onConfirm,
  title,
  body,
  secondaryButtonText,
  secondaryAriaLabel,
  secondaryOnClick,
  secondaryDisabled,
  confirmButtonText,
  confirmAriaLabel,
  confirmDisabled,
  noLine,
  largeModal,
  renderMath,
  footer,
  closing,
  noEsc,
  closeX = true,
  initialFocus = "close",
  maximizeContentSmallScreen,
}: ModalProps) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const confirmButtonRef = useRef<HTMLButtonElement>(null);
  const cancelButtonRef = useRef<HTMLButtonElement>(null);
  const messageRef = useRef(null);
  const modalTitleId = uniqueId("ModalTitle");

  const stopPropagation = (e: any) => {
    e.stopPropagation();
  };

  useEffect(() => {
    if (renderMath && messageRef.current) {
      renderMathInElement(messageRef.current);
    }
  }, [renderMath]);

  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === "Escape" && noEsc !== true) {
        onClose();
      }
    };
    window.addEventListener("keydown", handleEscape);
    return () => window.removeEventListener("keydown", handleEscape);
  }, [onClose]);

  return (
    <FocusTrap
      focusTrapOptions={{
        clickOutsideDeactivates: true,
        initialFocus: () => {
          const el =
            initialFocus === "confirm"
              ? confirmButtonRef.current
              : initialFocus === "close"
              ? cancelButtonRef.current
              : undefined;
          return el || false;
        },
      }}
    >
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
      <div
        className={clsx(
          "fixed left-0 right-0 mx-auto",
          "top-4 max-lg:top-20 max-lg:w-[90%]",
          "max-h-[calc(100%-96px)] lg:max-h-[calc(100%-32px)]",
          "z-50 overflow-y-auto overflow-x-hidden rounded-2xl bg-white shadow-xl",
          !largeModal
            ? "sm:w-[672px]"
            : "h-[calc(100%-96px)] max-w-5xl lg:h-[calc(100%-32px)] lg:w-full",
          // keyframes defined in index.css
          closing ? "portalModal-closing" : "portalModal-opening"
        )}
        onClick={stopPropagation}
        ref={modalRef}
        role="dialog"
        aria-modal={true}
        aria-labelledby={title ? modalTitleId : ""}
      >
        {closeX && (
          <IconButton
            ref={cancelButtonRef}
            onClick={onClose}
            className="absolute right-6 top-4 z-10"
            icon={<i className="far fa-times text-3xl !text-dm-gray-500" />}
            aria-label="Close"
          />
        )}
        <div
          className={clsx(
            "flex max-h-full flex-col pt-4 text-left align-middle sm:px-12 sm:py-10",
            maximizeContentSmallScreen ? "px-1" : "px-6",
            largeModal ? "h-full" : ""
          )}
        >
          {title && (
            <h4
              className="sticky top-0 z-[7] mb-6 bg-gradient-to-t from-transparent via-white/50 via-25% to-white text-2xl font-medium leading-6 text-gray-900"
              id={modalTitleId}
            >
              {title}
            </h4>
          )}
          <div className="max-h-full flex-grow overflow-auto">
            {body && typeof body === "string" ? (
              <p className="mb-6 text-base" ref={messageRef}>
                {body}
              </p>
            ) : (
              <div className="text-base" ref={messageRef}>
                {body}
              </div>
            )}
          </div>

          <div className="sticky bottom-0 mt-auto bg-white">
            {footer ? (
              footer
            ) : (
              <div
                className={clsx(
                  "mt-4 flex justify-between gap-4 max-lg:mb-4 max-lg:flex-col-reverse",
                  !noLine ? "border-t pt-8" : "",
                  !((secondaryButtonText && secondaryOnClick) || onConfirm) &&
                    "hidden"
                )}
              >
                {secondaryButtonText && secondaryOnClick ? (
                  <Button
                    onClick={secondaryOnClick}
                    type="outline"
                    size="small"
                    ariaLabel={secondaryAriaLabel}
                    disabled={secondaryDisabled}
                  >
                    {secondaryButtonText}
                  </Button>
                ) : (
                  <div></div>
                )}

                {onConfirm && (
                  <Button
                    size="small"
                    onClick={onConfirm}
                    ref={confirmButtonRef}
                    ariaLabel={confirmAriaLabel}
                    disabled={confirmDisabled}
                  >
                    {confirmButtonText || "Yes"}
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </FocusTrap>
  );
};

export default FullGenericModal;
