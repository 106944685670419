import clsx from "clsx";

type Props = {
  visible?: boolean;
  onClick?: () => void;
  closing: boolean;
};

const OpacityCover = (props: Props) => {
  return props.visible ? (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      className={clsx(
        "fixed bottom-0 left-0 right-0 top-0 z-50 h-full bg-black opacity-60 transition-opacity duration-300",
        // keyframes defined in index.css
        props.closing ? "portalModal-closing" : "portalModal-opening"
      )}
      onClick={props.onClick}
    ></div>
  ) : (
    <></>
  );
};

export default OpacityCover;
