import { useEffect, useRef, useState } from "react";
import { scrollToView } from "../../../../student/utils";
import { Course, myCoursesUnitDataType } from "../../../types";
import { NavLink, useParams } from "react-router-dom";
import clsx from "clsx";
import { useLearnerContext } from "../../../contexts/LearnerContext";
import { REACT_APP_LEARNER_LINK } from "../../../../utils";

export const UnitNav: React.FC<{
  unit: myCoursesUnitDataType;
  defaultOpen: boolean;
  courseData: Course | undefined;
  index: number;
  id: string;
}> = ({ unit, defaultOpen, courseData, index, id }) => {
  const { isSidebarMinimized } = useLearnerContext();
  const { unitPath } = useParams();
  const [isSkillHover, setIsSkillHover] = useState<boolean>(false);
  const buttonRef = useRef<HTMLDivElement>(null);
  const progress = Math.round((unit?.progress || 0) * 100);

  useEffect(() => {
    if (unit.path === unitPath && defaultOpen) {
      scrollToView(buttonRef, "instant", "center");
    }
  }, [defaultOpen, unit.path, unitPath]);

  return (
    <div
      ref={buttonRef}
      key={`unit-${unit?.id}`}
      data-tip={`${progress}% complete`}
      data-for={"sidebar-tooltip"}
    >
      <NavLink
        to={`${REACT_APP_LEARNER_LINK}/${courseData?.path || id}/${unit.path}`}
        className={({ isActive }) =>
          clsx(
            isActive ? "!bg-dm-brand-blue-100" : "",
            "group rounded px-2 py-1 text-sm !leading-none hover:bg-dm-brand-blue-100/50",
            "flex flex-row items-center justify-center gap-x-2"
          )
        }
        onPointerEnter={() => {
          if (!isSkillHover) setIsSkillHover(true);
        }}
        onPointerLeave={() => {
          if (isSkillHover) setIsSkillHover(false);
        }}
      >
        {({ isActive }) => (
          <>
            <span
              className={clsx(
                "shrink-0 basis-[3.3rem] text-xs uppercase group-hover:text-dm-gray-600",
                isActive ? "text-dm-gray-600" : "text-dm-gray-200"
              )}
            >{`Unit ${index + 1}:`}</span>
            {!isSidebarMinimized && (
              <span
                className={clsx(
                  "flex-grow text-sm group-hover:text-dm-gray-800",
                  isActive ? "text-dm-gray-800" : "text-dm-gray-600"
                )}
              >
                {unit?.name}
              </span>
            )}
          </>
        )}
      </NavLink>
    </div>
  );
};
