import { useEffect, CSSProperties } from "react";
import clsx from "clsx";
import { PieChart } from "react-minimal-pie-chart";
import ReactTooltip from "react-tooltip";
import { AssignmentType } from "../_constants";
import Correction from "./icons/Correction";
import Clock from "./icons/Clock";

type Props = {
  tooltipId?: string;
  extraClasses?: string;
  section: any;
  timedAssignment: any;
};

export default function IconAssignmentProgress({
  tooltipId = "asstProgress",
  extraClasses = "",
  section,
  timedAssignment,
}: Props): JSX.Element {
  const sectionType: AssignmentType | string = section?.ta?.type;
  const isCorrectionType = sectionType == AssignmentType.correction;
  const isTestType = section?.ta?.is_test;

  const allSpecialTypes = section?.sa?.actuallyComplete === null;
  const allSpecialCompleted =
    allSpecialTypes && section?.sa?.status === "completed";

  const completed = !allSpecialCompleted
    ? section?.sa?.actuallyComplete || 0
    : 100;

  const asstType = () => {
    if (isCorrectionType) {
      return "Test Correction";
    } else if (isTestType) {
      return "Test";
    }
    return "Assignment";
  };

  const tooltipText = allSpecialTypes
    ? `This ${asstType().toLowerCase()} has no completion percentage.`
    : `${asstType()}<br />Complete: ${completed}%`;

  const tooltipAriaText = allSpecialTypes
    ? tooltipText
    : tooltipText.replace("<br />", ", ") + ".";

  const progressColor = () => {
    if (completed === 0) {
      return "dm-gray-200";
    } else if (completed >= 100) {
      return "dm-success-500";
    } else {
      return "dm-brand-blue-500";
    }
  };

  const asstTypeIcon = () => {
    if (isCorrectionType) {
      return (
        <Correction
          classes={`w-4 stroke-0 stroke-${progressColor()} fill-${progressColor()}`}
        />
      );
    } else if (isTestType) {
      return (
        <i
          className={`far fa-list fa-lg leading-none text-${progressColor()}`}
          aria-hidden={true}
        ></i>
      );
    } else {
      return (
        <i
          className={`far fa-file fa-lg leading-none text-${progressColor()}`}
          aria-hidden={true}
        ></i>
      );
    }
    return "";
  };

  const clockBadge = (color = "dm-brand-blue-500") => {
    return (
      <Clock
        classes={`absolute -right-0.5 -top-0.5 w-[14px] h-[14px] stroke-0 stroke-${color}`}
      />
    );
  };

  const checkBadge = (
    <i
      className="fad fa-check-circle absolute -right-0.5 -top-0.5"
      aria-hidden={true}
      style={
        {
          "--fa-primary-color": "#078445",
          "--fa-secondary-color": "#ffffff",
          "--fa-secondary-opacity": "1",
        } as CSSProperties
      }
    ></i>
  );

  const badgeIcon = () => {
    if (completed >= 100) {
      return checkBadge;
    } else if (timedAssignment?.isTimed) {
      if (!timedAssignment.endTime) {
        return clockBadge("dm-brand-blue-500");
      } else if (!timedAssignment.isOver) {
        return clockBadge("dm-error-500");
      }
      // else {
      //   return checkBadge;
      // }
    }
    return null;
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [completed]);

  const HIDE_ELEMENT = false;

  return (
    <>
      <div
        className={clsx(
          "relative block shrink-0 text-center leading-none",
          extraClasses
        )}
        data-tip={tooltipText}
        data-for={tooltipId}
      >
        <div
          className="relative flex h-8 w-8 items-center justify-center text-xs leading-none"
          aria-hidden={true}
        >
          <PieChart
            data={[
              {
                value: completed,
                color: completed >= 100 ? "#078445" : "#2F52E9",
              },
              { value: 100 - completed, color: "#E3E8EF" },
            ]}
            lineWidth={15}
            paddingAngle={0}
            totalValue={100}
            startAngle={270}
            className="pointer-events-none absolute"
          />
          {asstTypeIcon()}
        </div>
        {badgeIcon()}
      </div>
      {tooltipAriaText !== null ? (
        <p
          className="sr-only order-last"
          //dangerouslySetInnerHTML={{ __html: tooltipText }}
        >
          {tooltipAriaText}
        </p>
      ) : null}
      {/*** IMPORTANT *****
        dynamically generated classes in Tailwind do not work, such as `stroke-${color}`
        because the classes are preprocessed.
        So we need to prerender all the possibilities below so the classes already exist
      */}
      {HIDE_ELEMENT && (
        <div
          className="hidden 
            fill-dm-brand-blue-500
            fill-dm-gray-200
            fill-dm-success-500
            stroke-dm-brand-blue-500
            stroke-dm-error-500
            stroke-dm-gray-200
            stroke-dm-success-500
            text-dm-brand-blue-500
            text-dm-gray-200
            text-dm-success-500"
        ></div>
      )}
    </>
  );
}
