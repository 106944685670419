import clsx from "clsx";
import Button from "../../../student/components/generic/button";
import { PropsWithChildren, ReactNode } from "react";

export const FeatureSection: React.FC<
  PropsWithChildren<{
    className: string;
    onClick: () => void;
    title: string;
    image: ReactNode;
  }>
> = ({ className, onClick, title, image, children }) => (
  <section
    className={clsx(
      "flex flex-col-reverse items-center gap-8 md:flex-row md:gap-14 xl:gap-40",
      className
    )}
  >
    <div className="flex-1">
      <h3 className="mb-4 font-serif text-[2em] font-bold text-dm-brand-blue-600">
        {title}
      </h3>
      <p className="mb-6">{children}</p>
      <Button className="h-12 w-72 text-sm" onClick={onClick}>
        Learn More Today
      </Button>
    </div>
    {image}
  </section>
);
