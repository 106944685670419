import { FireIcon } from "../../../shared/icons/FireIcon";
import { DashboardCard } from "./DashboardCard";
import { SparkleIcon } from "../../../shared/icons/SparkleIcon";
import clsx from "clsx";

export const LearningStreakCard: React.FC<{
  streaks?: number;
  longestStreak?: number;
  isParent?: boolean;
}> = ({ streaks = 0, longestStreak = undefined, isParent = false }) => {
  return (
    <DashboardCard title="Learning Streak" icon={<FireIcon />}>
      <main className="flex items-center justify-between gap-x-1">
        <section>
          <h3
            className={clsx(
              "mt-4 font-serif font-bold",
              isParent ? "text-2xl" : "mb-2 text-3xl"
            )}
          >
            {streaks} {streaks !== 1 ? "weeks" : "week"}
          </h3>
          {!isParent ? (
            <p className="mb-1 text-xs text-gray-500">
              {streaks === 0
                ? "Start a new streak by hitting your weekly points goal!"
                : longestStreak !== undefined
                ? longestStreak > streaks
                  ? `Your longest streak is ${longestStreak} ${
                      longestStreak === 1 ? "week" : "weeks"
                    }. Can you beat it!?`
                  : "You've hit your longest streak. Keep the momentum going!"
                : "You're on a roll! Keep practicing to extend your streak."}
            </p>
          ) : (
            <p className="mb-1 text-sm text-gray-500">Current streak</p>
          )}
        </section>
        {[5, 10, 15, 20].includes(streaks) && (
          <figure
            className={clsx(
              "flex shrink-0 items-center justify-center rounded-full",
              "h-16 w-16 md:max-xl:scale-[.8]",
              streaks < 15 && "bg-dm-purple-500",
              streaks >= 15 && streaks < 20
                ? "bg-gradient-to-tr from-[#7001DE] from-15% via-[#BB94EF] via-50% to-[#3D0178] to-85%"
                : null,
              streaks >= 20
                ? "bg-gradient-to-tr from-[#F1C320] from-15% via-[#FFF1BE] via-50% to-[#D3A605] to-85%"
                : null
            )}
            aria-hidden={true}
          >
            <span
              className={clsx(
                "ml-1 mt-3 text-right font-serif text-lg font-bold leading-none",
                streaks >= 20 ? "text-dm-charcoal-800" : "text-white"
              )}
            >
              {streaks}
            </span>
            <SparkleIcon
              strokeWidth={0.6}
              className={clsx(
                "-ml-1.5 mb-3 h-6 w-6",
                streaks >= 20 ? "text-dm-charcoal-800" : "text-white"
              )}
            />
          </figure>
        )}
      </main>
    </DashboardCard>
  );
};
