import React from "react";
import { NavLink } from "react-router-dom";
import { REACT_APP_STUDENT_LINK } from "../../../utils";

type Props = {
  section?: any;
  type: "correction" | "test" | "standard";
};

const AssignmentAccordionButton = (props: Props) => {
  return (
    <NavLink
      // key={`button-${props.section?.ta?._id}`}
      to={`${REACT_APP_STUDENT_LINK}/link/${props.section?.ta?._id}`}
      className="mt-2 flex-shrink-0 basis-full rounded bg-dm-brand-blue-500 px-8 py-1 text-center font-bold leading-6 text-white hover:bg-dm-brand-blue-600 sm:mt-0 sm:basis-auto"
    >
      Open{" "}
      {props.type === "correction"
        ? "Correction"
        : props.type === "test"
        ? "Test"
        : "Assignment"}
    </NavLink>
  );
};

export default AssignmentAccordionButton;
