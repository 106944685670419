import { SkeletonText } from "../Skeleton";

export const SubunitLandingLoading: React.FC = () => {
  return (
    <>
      <main className="flex flex-grow flex-col bg-dm-background-blue-100">
        <div className="flex w-full flex-col items-center gap-4 border-b border-dm-charcoal-100 bg-white px-6 py-4 md:flex-row md:justify-between">
          <SkeletonText>
            <h2 className="flex-grow text-sm font-bold">
              Waiting for this section to load
            </h2>
          </SkeletonText>
        </div>
        <div className="flex h-96 flex-col items-center justify-center gap-2">
          <SkeletonText>
            <p className="text-2xl">Consectetur anim est amet</p>
          </SkeletonText>
          <SkeletonText>
            <p>
              Amet nulla dolor excepteur ullamco ad nostrud veniam in deserunt
              id.
            </p>
          </SkeletonText>
          <SkeletonText>
            <p>Minim id magna enim proident ut amet enim fugiat.</p>
          </SkeletonText>
        </div>
      </main>
    </>
  );
};
