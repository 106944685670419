import { useQuery } from "react-query";
import { LearnerSubscriptionDetails } from "../types";
import { executeQuery } from "../../utils";

export function useSubscription(): {
  subscription: LearnerSubscriptionDetails | undefined;
  refetchSubscription: () => Promise<void>;
} {
  const { data: subscriptionData, refetch: refetchSubscriptionData } =
    useQuery<{
      subscription: LearnerSubscriptionDetails;
    }>(
      "/payments/subscriptions/active",
      () => executeQuery({ path: "/payments/subscriptions/active" }),
      { refetchOnWindowFocus: false }
    );

  return {
    subscription: subscriptionData?.subscription,
    refetchSubscription: async () => {
      await refetchSubscriptionData();
    },
  };
}
