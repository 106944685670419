import { useEffect, useState } from "react";
import Portal from "../../../shared/Portal";
import { getFilePath, REACT_APP_LEARNER_LINK } from "../../../utils";
import Button from "../../../student/components/generic/button";
import clsx from "clsx";
import {
  DASHBOARD_ONBOARDING_STEPS,
  LEARNER_ONBOARDING_STEPS,
  PARENT_ONBOARDING_STEPS,
} from "../Points/onboarding/constants";
import { TourTypes } from "../../contexts/TourContext";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "usehooks-ts";

const content = [
  {
    tour: "dashboard",
    step: 1,
    title: "Continue Learning",
    body: "Pick up right where you left off when you're ready to jump back into your course.",
    mobile: "bottom",
  },
  {
    tour: "dashboard",
    step: 2,
    title: "Points, Weekly Goal, & Learning Streaks",
    body: "Here you can see your total points, view progress towards your weekly goal, and track your current learning streak!",
    mobile: "top",
  },
  {
    tour: "dashboard",
    step: 3,
    title: "Play Pascal's Triangle to Unlock Prizes",
    body: "Use your hard-earned points to unlock Pascal's Triangle. Score awesome new prizes for every row you unlock!",
    mobile: "top",
  },
  {
    tour: "dashboard",
    step: 4,
    title: "Learning Stats Summary",
    body: "See your progress at a glance! Track how many units and sections you've completed, and how many questions you've answered correctly.",
    end: true,
    mobile: "top",
  },
  {
    tour: "parent",
    step: 1,
    title: "Course Progress",
    body: "Get a snapshot of  where your learner is within their course.  Click in for a detailed breakdown of their course progress.",
    mobile: "bottom",
  },
  {
    tour: "parent",
    step: 2,
    title: "Points & Learning Streak",
    body: "See your learner’s total points earned, and stay on top of their current learning streak as they reach their weekly goals.",
    mobile: "top",
  },
  {
    tour: "parent",
    step: 3,
    title: "Quick Stats",
    body: "See key stats like questions answered, correct answers, and total time spent learning.",
    mobile: "top",
  },
  {
    tour: "parent",
    step: 4,
    title: "Stats Graphs",
    body: "Get a visual overview of your learner's progress. See their work by day or week, tracked by questions, points, or time.",
    mobile: "bottom",
  },
  {
    tour: "parent",
    step: 5,
    title: "Recent Activity",
    body: "Stay connected to your learner’s math journey with a snapshot of their recent activity on the platform.  Click in to see more details.",
    mobile: "bottom",
  },
  {
    tour: "parent",
    step: 6,
    title: "Switch to Learner View",
    body: "Want to see what your learner sees? Use this feature to mirror their account and  explore the platform as if you were logged in as them!",
    mobile: "bottom",
    end: true,
  },
  {
    tour: "learner",
    step: 1,
    title: "Points",
    body: "Score points, unlock rewards, and watch your math skills grow! Earn points for correct answers, watching videos, improving scores, and even playing  games. Make math a habit, and the points will keep rolling in!",
    mobile: "top",
  },
  {
    tour: "learner",
    step: 2,
    title: "Menu",
    body: "Use the menu to navigate to your dashboard and explore courses.",
    mobile: "bottom",
    mobileOnly: true,
  },
  {
    tour: "learner",
    step: 3,
    title: "Dashboard",
    body: "The dashboard is your personalized command center for your math journey! Track your progress, view your stats, and jump back into your course.",
    mobile: "bottom",
  },
  {
    tour: "learner",
    step: 4,
    title: "All Courses",
    body: "Discover a variety of courses to help you achieve your goals. Find the course you need and start learning.",
    mobile: "bottom",
    navigate: "/courses",
  },
  {
    tour: "learner",
    step: 5,
    title: "Select Course",
    body: "Browse our complete library of math courses here! Click on a course to begin learning.",
    mobile: "top",
    removeNextButton: true,
  },
  {
    tour: "learner",
    step: 6,
    title: "Course Outline",
    body: "This is your course roadmap! Easily navigate through units and sections, track your progress, and find the skills you want to practice.",
    mobile: "bottom",
  },
  {
    tour: "learner",
    step: 7,
    title: "Units & Sections",
    body: "This is your course roadmap! Easily navigate through units and sections, track your progress, and find the skills you want to practice.",
    mobile: "bottom",
    end: true,
    secondaryConfirm: "Browse Courses",
    navigate: "/courses",
    confirmText: "Start This Course",
  },
];

type Props = {
  position: {
    top: number;
    left: number;
    bottom: number;
    right: number;
    position: "top" | "bottom" | "left" | "right";
  };
  visible: boolean;
  tour: TourTypes;
  step: number;
  onNext: () => void;
  remindLater: () => void;
  onComplete: () => void;
  stickyElement?: boolean;
};

const TourCard = (props: Props) => {
  const navigate = useNavigate();
  const isSmallDevice = useMediaQuery("(max-width : 1023px)");

  const totalSteps =
    props.tour === "dashboard"
      ? DASHBOARD_ONBOARDING_STEPS
      : props.tour === "learner"
      ? LEARNER_ONBOARDING_STEPS
      : PARENT_ONBOARDING_STEPS;

  const copy = content.find(
    (c) => c.tour === props.tour && c.step === props.step
  );

  const [scrolled, setScrolled] = useState(0);

  useEffect(() => {
    window.addEventListener("scroll", () => setScrolled(window.scrollY));

    return () => {
      window.removeEventListener("scroll", () => setScrolled(window.scrollY));
    };
  }, []);

  if (!props.visible || !copy || props.step > totalSteps) {
    return <></>;
  }

  const keepLeftInbound = (left: number) => {
    if (left + 170 > window.innerWidth) {
      return window.innerWidth - 170;
    }
    if (left < 0) {
      return 170;
    }
    return left;
  };

  const keepTopInbound = (top: number) => {
    const difference = Math.abs(top - 300);

    if (top - 300 <= 0) {
      return top + difference;
    }

    return top;
  };

  const position =
    props.position.position === "top" || props.position.position === "bottom"
      ? {
          top:
            props.position.position === "top"
              ? keepTopInbound(props.position.top)
              : props.position.bottom,
          left: keepLeftInbound(
            props.position.left +
              (props.position.right - props.position.left) / 2
          ),
        }
      : {
          top: keepTopInbound(
            props.position.top +
              (props.position.bottom - props.position.top) / 2
          ),
          left: keepLeftInbound(
            props.position.position === "left"
              ? props.position.left
              : props.position.right
          ),
        };

  return (
    <Portal containerSelector="#modal-container">
      <div
        className={clsx(
          "pointer-events-none absolute z-[1001] w-[296px]",
          props.position.position === "top"
            ? "-translate-x-1/2 -translate-y-full transform"
            : props.position.position === "bottom"
            ? "-translate-x-1/2 transform"
            : props.position.position === "left"
            ? "-translate-x-full -translate-y-1/2 transform"
            : "-translate-y-1/2 transform"
        )}
        style={{
          top: position.top + (props.stickyElement ? scrolled : 0),
          // Minimum of 170 to make sure deltie and the card are always on the screen
          left: position.left,
        }}
      >
        <div
          className={clsx(
            "relative transform rounded bg-dm-charcoal-600 p-4 text-white",
            props.position.position === "left"
              ? "-left-8"
              : props.position.position === "right"
              ? "left-8"
              : props.position.position === "bottom"
              ? "top-4"
              : "-top-4"
          )}
        >
          <img
            src={getFilePath("/images/learner/tour/deltie-triangle.png")}
            className="absolute left-[-22px] top-[-24px] h-11 w-12"
          />
          <div className="pointer-events-auto flex justify-between">
            <h5 className="text-sm font-bold leading-[26px]">{copy.title}</h5>
            <button className="cursor-pointer" onClick={props.onComplete}>
              <i className="far fa-times text-xl !text-dm-charcoal-200" />
            </button>
          </div>
          <p className="text-sm leading-[26px]">{copy.body}</p>
          <div className="pointer-events-auto mt-4">
            <p className="text-xs leading-4 text-dm-charcoal-200">
              {props.step -
                (props.step === 1 || props.tour !== "learner"
                  ? 0
                  : isSmallDevice
                  ? 0
                  : 1)}
              /
              {totalSteps -
                ((isSmallDevice && props.step === 1) || props.tour !== "learner"
                  ? 0
                  : isSmallDevice
                  ? 0
                  : 1)}
            </p>
            <div
              className={clsx(
                "flex items-center",
                copy.end ? "justify-end" : "justify-between"
              )}
            >
              {!copy.end && (
                <div className="flex items-center gap-2">
                  <p className="text-xs leading-4">Busy?</p>
                  <Button
                    type="link"
                    className="text-xs leading-4 !text-dm-brand-blue-200"
                    onClick={props.remindLater}
                  >
                    Remind me Later
                  </Button>
                </div>
              )}
              {!copy.removeNextButton && (
                <Button
                  type="link"
                  className="text-xs leading-4 !text-dm-brand-blue-200"
                  onClick={() => {
                    if (copy.end) {
                      props.onComplete();
                      if (copy.navigate) {
                        navigate(`${REACT_APP_LEARNER_LINK}${copy.navigate}`);
                      }
                    } else {
                      if (copy.navigate) {
                        navigate(`${REACT_APP_LEARNER_LINK}${copy.navigate}`);
                      }
                      props.onNext();
                    }
                  }}
                >
                  {copy.secondaryConfirm
                    ? copy.secondaryConfirm
                    : copy.end
                    ? "Got It!"
                    : "Next"}
                  {copy.end && !copy.secondaryConfirm ? (
                    <i className="far fa-check ml-2" />
                  ) : (
                    <i className="far fa-arrow-right ml-2" />
                  )}
                </Button>
              )}
            </div>
            {copy.end && copy.confirmText && (
              <div>
                <Button
                  type="outline"
                  size="small"
                  className="mt-4 w-full bg-white"
                  onClick={props.onComplete}
                >
                  {copy.confirmText} <i className="far fa-arrow-right ml-2" />
                </Button>
              </div>
            )}
          </div>

          {props.position.position === "top" ? (
            <div className="absolute -bottom-[10px] left-1/2 -translate-y-1/2 transform">
              <div className="h-0 w-0 border-l-[8px] border-r-[7px] border-t-[7px] border-l-transparent border-r-transparent border-t-gray-800"></div>
            </div>
          ) : props.position.position === "bottom" ? (
            <div className="absolute -top-[4px] left-1/2 -translate-y-1/2 transform">
              <div className="h-0 w-0 border-b-[7px] border-l-[8px] border-r-[7px] border-b-gray-800 border-l-transparent border-r-transparent"></div>
            </div>
          ) : props.position.position === "left" ? (
            <div className="absolute right-[-7px] top-1/2 -translate-y-1/2 transform">
              <div className="h-0 w-0 border-b-[7px] border-l-[8px] border-t-[7px] border-b-transparent border-l-gray-800 border-t-transparent"></div>
            </div>
          ) : (
            <div className="absolute left-[-7px] top-1/2 -translate-y-1/2 transform">
              <div className="h-0 w-0 border-b-[7px] border-r-[8px] border-t-[7px] border-b-transparent border-r-gray-800 border-t-transparent"></div>
            </div>
          )}
        </div>
      </div>
    </Portal>
  );
};

export default TourCard;
