import { SectionTitle } from "./SectionTitle";
import { TiltedBanner } from "./TiltedBanner";
import { Pricing } from "./Pricing";
import { getFilePath } from "../../../utils";
import { useMediaQuery } from "usehooks-ts";
import { ResponsiveImage } from "../ResponsiveImage";
import { forwardRef } from "react";

export const PricingContainer = forwardRef<HTMLElement>((props, ref) => {
  const isSmallDevice = useMediaQuery("(max-width : 767px)");
  const isHighDpi = useMediaQuery("screen and (min-resolution: 73dpi)");
  const texturedBgTile = getFilePath(
    isHighDpi
      ? "/images/learner/home/textured-purple-tile@2x.png"
      : "/images/learner/home/textured-purple-tile.png"
  );

  const styles = {
    borderImage: `url(${getFilePath(
      isHighDpi
        ? "/images/learner/home/textured-purple-full-border@2x.png"
        : "/images/learner/home/textured-purple-full-border.png"
    )})`,
    borderImageSlice: "60",
    borderWidth: isSmallDevice ? "37px 0px" : "37px 31px",
  };

  return (
    <section ref={ref} className="flex w-full justify-center md:px-6">
      <div
        className="relative flex w-full items-stretch justify-stretch xl:w-[1329px]"
        style={styles}
      >
        <div
          className="flex h-full w-full flex-col items-center justify-center bg-transparent bg-[length:200px_200px] px-4 pb-16 pt-20 md:px-10 md:py-16"
          style={{ backgroundImage: `url('${texturedBgTile}')` }}
        >
          <SectionTitle
            title="Invest in Your Child's Math Success"
            subtitle="Choose the plan that best fits your family's needs and budget"
          />
          <TiltedBanner
            rotateClockwise={false}
            className="left-2 top-0 bg-dm-purple-500 text-white md:-left-10 md:-top-4"
          />
          <Pricing />
        </div>
        <ResponsiveImage
          className="absolute -bottom-36 -left-24 hidden lg:block"
          srcs={[
            getFilePath("/images/learner/home/math-textbook.png"),
            getFilePath("/images/learner/home/math-textbook@2x.png"),
          ]}
          alt="Math textbook showing the Pythagorean Theorem"
        />
      </div>
    </section>
  );
});

PricingContainer.displayName = "PricingContainer";
