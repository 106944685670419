import {
  BackspaceIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
} from "@heroicons/react/outline";
import { IconBan } from "@tabler/icons-react";
import DMKey from "./DMKey";

const KeyboardControls = ({
  input,
  focusedInput,
  handleEnter,
}: {
  input: any;
  focusedInput: any;
  handleEnter: () => any;
}) => {
  return (
    <div className="col col-span-2 space-y-1">
      <div className="grid grid-cols-2 space-x-1">
        <DMKey
          btnText={<BackspaceIcon className="h-6 w-6" />}
          ansType={"icon"}
          ariaLabel={"backspace"}
          btnOutput={"Backspace"}
          btnAction={true}
          focusedInput={focusedInput}
          inputsRef={input}
        />
        <DMKey
          btnText={<IconBan className="h-6 w-6" aria-hidden="true" />}
          ansType={"icon"}
          ariaLabel={"delete"}
          btnOutput={"startShift-End Backspace"}
          btnAction={true}
          focusedInput={focusedInput}
          inputsRef={input}
        />
      </div>
      <div className="grid grid-cols-2 space-x-1">
        <DMKey
          btnText={
            <span className="inline-flex align-middle">
              <ArrowLeftIcon className="h-6 w-6" />
              <ArrowRightIcon className="-ml-2 h-6 w-6" />
            </span>
          }
          ariaLabel={"select all"}
          ansType={"icon"}
          btnOutput={"startShift-End"}
          btnAction={true}
          focusedInput={focusedInput}
          inputsRef={input}
          className="border-1 col col-span-2 mb-[3px] h-[35px] max-h-[100%] rounded-md bg-gray-300 hover:bg-gray-400"
        />
      </div>
      <div className="grid grid-cols-2 space-x-1">
        <DMKey
          btnText={<ArrowLeftIcon className="h-6 w-6 align-middle" />}
          ansType={"icon"}
          ariaLabel={"cursor left"}
          btnOutput={"Left"}
          btnAction={true}
          focusedInput={focusedInput}
          inputsRef={input}
        />
        <DMKey
          btnText={<ArrowRightIcon className="h-6 w-6 align-middle" />}
          ansType={"icon"}
          ariaLabel={"cursor right"}
          btnOutput={"Right"}
          btnAction={true}
          focusedInput={focusedInput}
          inputsRef={input}
        />
      </div>
      <div className="grid grid-cols-2 space-x-1">
        <button
          className="border-1 col col-span-2 h-[35px] max-h-[100%] rounded-md bg-gray-300 hover:bg-gray-400"
          onClick={() => handleEnter()}
        >
          ENTER
        </button>
      </div>
    </div>
  );
};

export default KeyboardControls;
