//Function which takes a nested array representing a matrix where each element array is a 'matrix row' which contains strings generated from renderA11yString to describe the elements in the row. Returns JSX which will be rendered where the call to renderA11yString was made
export const genMatrixA11yJSX = (matrixRows: any): JSX.Element => {
  const allRowsJSX = matrixRows.map((row: any, index: number) => {
    return (
      <div key={index}>
        {row.map(
          (e: string, i: number): JSX.Element => (
            <div key={i}>{e}</div>
          )
        )}
      </div>
    );
  });

  const matrixJSX = (
    <div>
      <div>begin matrix</div>
      {allRowsJSX}
      <div>end matrix</div>
    </div>
  );

  return matrixJSX;
};

export const genArrayA11yJSX = (arrayRows: any): JSX.Element => {
  const allRowsJSX = arrayRows.map((row: any, index: number) => {
    return (
      <div key={index}>
        {row.map((e: string, i: number) => (
          <div key={i}>{e}</div>
        ))}
      </div>
    );
  });

  const arrayJSX = (
    <div>
      <div>begin array</div>
      {allRowsJSX}
      <div>end array</div>
    </div>
  );

  return arrayJSX;
};

export const genAlignedA11yJSX = (arrayRows: any): JSX.Element => {
  const alignedRows = arrayRows.map((row: any, index: number) => {
    return (
      <div key={index}>
        {row.filter((e: string) => !["", " "].includes(e)).join(", ")}
      </div>
    );
  });
  return alignedRows;
};
