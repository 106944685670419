import clsx from "clsx";
import { ReactElement } from "react";
import Button from "../../../student/components/generic/button";
import { SectionTitle } from "./SectionTitle";
import { MAX_CONTAINER_WIDTH } from "./constants";
import { useHomepageSignupContext } from "./HomepageSignupContext";
import { getFilePath } from "../../../utils";

interface Feature {
  title: string;
  description: string;
}

const FeatureListSection: React.FC<{
  title: string;
  features: Feature[];
  gif: ReactElement;
  gifAlign: "right" | "left";
}> = ({ title, features, gif, gifAlign }) => {
  const { startTrialText, scrollToPlans } = useHomepageSignupContext();
  return (
    <div
      className={clsx(
        "flex flex-col items-center gap-8 md:flex-row md:gap-6",
        gifAlign === "right" && "md:flex-row-reverse"
      )}
    >
      <div className="flex w-full flex-none items-center justify-center self-stretch rounded-xl md:w-[300px] lg:w-[500px] xl:w-[607px]">
        {gif}
      </div>
      <div className="flex flex-col gap-6 md:gap-8">
        <h5 className="font-serif text-lg font-bold text-dm-brand-blue-600 md:text-2xl">
          {title}
        </h5>
        <ul className="flex flex-col gap-6">
          {features.map((feature) => (
            <li key={feature.title}>
              <h5 className="mb-2 font-serif font-bold text-dm-brand-blue-600 md:text-lg">
                {feature.title}
              </h5>
              <p>{feature.description}</p>
            </li>
          ))}
        </ul>
        <Button
          className="mt-1 h-10 w-full text-sm md:mt-0"
          onClick={() => scrollToPlans("features")}
        >
          {startTrialText}
        </Button>
      </div>
    </div>
  );
};

export const FeatureList: React.FC = () => {
  return (
    <section
      className={clsx(
        "-mt-14 w-full px-4 md:px-6 xl:px-0",
        MAX_CONTAINER_WIDTH
      )}
    >
      <SectionTitle
        title="Build Skills, Gain Confidence"
        subtitle="An all-in-one platform for effective learning and measurable progress"
      />

      <div className="flex flex-col gap-20 md:gap-32">
        <FeatureListSection
          title="For Learners"
          features={[
            {
              title: "Personalized Learning Path",
              description:
                "DeltaMath for Home adapts to your individual skill level, ensuring you're constantly challenged and engaged with targeted practice problems.",
            },
            {
              title: "Stay Motivated & Celebrate Success",
              description:
                "Earn points, unlock rewards, and track your progress in real-time. DeltaMath makes learning fun and rewarding, keeping you motivated every step of the way",
            },
            {
              title: "Learn Anytime, Anywhere",
              description:
                "Access a vast library of math courses from any device, anytime. Learn at your own pace and revisit concepts whenever you need.",
            },
            {
              title: "Interactive & Engaging Content",
              description:
                "Experience math in a new way with interactive exercises, videos, and games that make learning fun and effective.",
            },
          ]}
          gif={
            <video autoPlay loop muted>
              <source
                src={getFilePath("/images/learner/home/learner.mp4")}
                type="video/mp4"
              />
            </video>
          }
          gifAlign="left"
        />
        <FeatureListSection
          title="For Parents"
          features={[
            {
              title: "Empower Your Child's Success",
              description:
                "Gain valuable insights into your child's math journey with a comprehensive parent dashboard. Track their progress, strengths, and areas where they might need a little extra help.",
            },
            {
              title: "Monitor Progress & Performance",
              description:
                "Go beyond grades with detailed reports that showcase your child's understanding. Celebrate their achievements and identify opportunities for growth.",
            },
            {
              title: "Stay Connected & Informed",
              description:
                "Stay in the know so you can help your child develop a positive attitude towards learning and build lasting confidence.",
            },
            {
              title: "Peace of Mind",
              description:
                "Know that your child is receiving high-quality math instruction and support, even when you're not there to help.",
            },
          ]}
          gif={
            <video autoPlay loop muted>
              <source
                src={getFilePath("/images/learner/home/parent.mp4")}
                type="video/mp4"
              />
            </video>
          }
          gifAlign="right"
        />
      </div>
    </section>
  );
};
