import { PropsWithChildren } from "react";

export const ProfileSection: React.FC<
  PropsWithChildren<{
    title: string;
  }>
> = ({ title, children }) => (
  <div className="flex w-[1288px] max-w-full flex-col gap-8 rounded-2xl border border-dm-charcoal-100 bg-white p-6">
    <h1 className="flex items-center gap-2 text-sm text-dm-gray-600">
      <i className="fal fa-cog" aria-hidden="true" />
      {title}
    </h1>
    <div className="flex flex-col justify-between sm:flex-row sm:gap-6">
      {children}
    </div>
  </div>
);
