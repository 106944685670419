import { useEffect } from "react";
import { useScript } from "usehooks-ts";
import { isRequestFromProd } from "../../utils";

const GOOGLE_ID = "G-Z0H6VB9Y1Q";

declare global {
  interface Window {
    dataLayer: any[];
  }
}

const GoogleAnalyticsLoader: React.FC = () => {
  useScript(`https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ID}`);

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    function gtag(...args: any[]) {
      window.dataLayer.push(args);
    }
    gtag("js", new Date());
    gtag("config", GOOGLE_ID);
  }, []);

  return null;
};

/** Add the Google Analytics script to the page. Only loads on prod */
export const GoogleAnalyticsTag: React.FC = () =>
  isRequestFromProd(window.location.origin) ? <GoogleAnalyticsLoader /> : null;
