import { useNavigate } from "react-router-dom";

type SectionEmptyStateProps = {
  icon: JSX.Element;
  header: string;
  subheader?: string;
  message: string;
  btnText?: string;
  btnLink?: string;
};

export default function SectionEmptyState({
  icon,
  header,
  subheader,
  message,
  btnText,
  btnLink,
}: SectionEmptyStateProps): JSX.Element {
  const navigate = useNavigate();

  return (
    <>
      <div className="mx-4 mt-4 flex flex-col flex-wrap items-center rounded-lg border border-dm-charcoal-100 bg-white px-6 pb-10 pt-8 text-center font-serif sm:mx-6 sm:mt-6">
        <div className="w-12 text-dm-brand-blue-500">{icon}</div>
        <h3 className="text-md mt-2 font-serif">{header}</h3>
        {subheader !== undefined && (
          <h3 className="text-md font-serif">{subheader}</h3>
        )}
        <p className="mt-2 font-sans text-sm">{message}</p>
        {btnText !== undefined && btnLink !== undefined && (
          <div className="mt-6">
            <button
              type="button"
              className="focus-visible:outline-bg-dm-brand-blue-500 inline-flex items-center rounded-md bg-dm-brand-blue-500 px-3 py-2 font-sans text-sm text-white shadow-sm hover:bg-dm-brand-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
              onClick={() => {
                navigate(btnLink);
              }}
            >
              {btnText}
            </button>
          </div>
        )}
      </div>
    </>
  );
}
