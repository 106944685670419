import { HTMLAttributes } from "react";
import { IconButton } from "../../../student/components/generic/IconButton";

export const EditAccountIconButton: React.FC<
  Omit<HTMLAttributes<HTMLButtonElement>, "aria-label">
> = (props) => (
  <IconButton
    icon={<i className="fal fa-pencil text-dm-gray-200" />}
    aria-label="Edit account information"
    onClick={props.onClick}
    {...props}
  />
);
