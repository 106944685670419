import React from "react";
import { LoadingIconKey } from "../types";
import { getFilePath } from "../../utils";
import { ResponsiveImage } from "./ResponsiveImage";

const StyledImage: React.FC<{ srcs: string[]; alt: string }> = ({
  srcs,
  alt,
}) => (
  <ResponsiveImage srcs={srcs} className="aspect-1 h-auto w-full" alt={alt} />
);

export const LoadingIcon: React.FC<{
  icon: LoadingIconKey;
  staticImg?: boolean;
}> = ({ icon, staticImg = false }) => {
  switch (icon) {
    case "disco":
      return (
        <StyledImage
          srcs={
            staticImg
              ? [
                  getFilePath("/images/learner/gifs/deltie-disco-static.png"),
                  getFilePath(
                    "/images/learner/gifs/deltie-disco-static@2x.png"
                  ),
                ]
              : [getFilePath("/images/learner/gifs/deltie-disco.gif")]
          }
          alt="An animation of Deltie dancing in front of a disco ball"
        />
      );
    case "idea":
      return (
        <StyledImage
          srcs={
            staticImg
              ? [
                  getFilePath("/images/learner/gifs/deltie-idea-static.png"),
                  getFilePath("/images/learner/gifs/deltie-idea-static@2x.png"),
                ]
              : [getFilePath("/images/learner/gifs/deltie-idea.gif")]
          }
          alt="An animation of Deltie having an idea and lightbulb illuminating"
        />
      );
    case "skateboard":
      return (
        <StyledImage
          srcs={
            staticImg
              ? [
                  getFilePath(
                    "/images/learner/gifs/deltie-skateboard-static.png"
                  ),
                  getFilePath(
                    "/images/learner/gifs/deltie-skateboard-static@2x.png"
                  ),
                ]
              : [getFilePath("/images/learner/gifs/deltie-skateboard.gif")]
          }
          alt="An animation of Deltie skateboarding and wearing sunglasses"
        />
      );
    default:
      return <></>;
  }
};
