import Sidebar from "../Sidebar/unit";
import { PropsWithChildren, ReactNode } from "react";
import { PageFooter } from "./PageFooter";
import clsx from "clsx";
import ImpersonationHeader from "../Dashboard/ImpersonationHeader";
import { PointsToast } from "./PointsToast";
import { EmptyLearnerPage } from "./EmptyLearnerPage";

export const LearnerPage: React.FC<
  PropsWithChildren<{
    sidebar?: ReactNode;
    noFooter?: boolean;
    noPadding?: boolean;
    dontTrackTime?: boolean;
    noDesktopSidebar?: boolean;
    title: string | undefined;
  }>
> = ({
  sidebar,
  noFooter = false,
  noPadding = false,
  children,
  dontTrackTime,
  noDesktopSidebar = false,
  title,
}) => {
  return (
    <EmptyLearnerPage title={title} dontTrackTime={dontTrackTime}>
      <div className="h-full w-full">
        <ImpersonationHeader />
        <div
          className={clsx("flex flex-col", !noDesktopSidebar && "lg:flex-row")}
        >
          {sidebar ? <>{sidebar}</> : <Sidebar />}
          <main
            className={clsx(
              "relative flex min-h-dvh w-full flex-col gap-12 overflow-hidden",
              noPadding ? "pb-6" : "py-6"
            )}
          >
            <div className={clsx("flex-grow", !noPadding && "px-6")}>
              {children}
            </div>
            {!noFooter && <PageFooter />}
          </main>
        </div>

        <PointsToast />
      </div>
    </EmptyLearnerPage>
  );
};
