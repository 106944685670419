import React from "react";
import { DashboardCard } from "../Dashboard/DashboardCard";
import { GiftIcon } from "@heroicons/react/outline";
import { useNextPrize } from "../../utils/useNextPrize";
import Button from "../../../student/components/generic/button";
import { REACT_APP_LEARNER_LINK, getFilePath } from "../../../utils";
import { Skeleton, SkeletonText } from "../Skeleton";
import { ResponsiveImage } from "../ResponsiveImage";

export const NextPrizeCard: React.FC = () => {
  const nextPrize = useNextPrize();

  return (
    <DashboardCard title="Prizes" icon={<GiftIcon />}>
      <div className="h-full">
        <div className="mb-10 flex items-start justify-between gap-6 md:items-center">
          <div className="flex w-full max-w-[calc(100%-100px)] flex-col md:max-w-[calc(100%-173px)]">
            <div className="mb-1 mt-4 md:w-auto">
              <SkeletonText loaded={nextPrize !== undefined}>
                <h4 className="font-serif text-2xl font-bold">
                  {nextPrize?.name ?? "More Prizes Coming Soon!"}
                </h4>
              </SkeletonText>
            </div>
            {nextPrize !== null && (
              <p className="text-xs font-bold">Next Prize</p>
            )}
          </div>
          <div className="absolute right-6 top-6 flex h-[64px] w-[64px] flex-none overflow-hidden rounded-lg md:right-8 md:top-12 md:h-[125px] md:w-[125px]">
            {nextPrize === null ? (
              <ResponsiveImage
                srcs={[
                  getFilePath("/images/learner/prizes/deltie-prizes-soon.png"),
                  getFilePath(
                    "/images/learner/prizes/deltie-prizes-soon@2x.png"
                  ),
                ]}
                alt="More prizes soon"
              />
            ) : nextPrize === undefined ? (
              <Skeleton />
            ) : (
              nextPrize.image
            )}
          </div>
        </div>
        <Button
          href={`${REACT_APP_LEARNER_LINK}/points/prizes`}
          className="w-full md:w-48"
        >
          Explore All Prizes
        </Button>
      </div>
    </DashboardCard>
  );
};
