import { Course, Progress, SubunitProgress, UnitProgress } from "../types";

export function getNextUnfinishedUnit(
  currentUnitId: string,
  courseProgress: Progress | undefined,
  courseData: Course | undefined
): string | undefined {
  if (!courseData || !courseProgress) {
    return;
  }

  const unitProgressInOrder = courseData.unitOrder.map((uo) =>
    courseProgress.units.find((u) => u.unitId === uo)
  );

  const currentUnitIndex = courseData.unitOrder.indexOf(currentUnitId);

  if (currentUnitIndex === undefined) {
    return;
  }
  const currentUnit = unitProgressInOrder[currentUnitIndex];

  if (currentUnit?.progress !== 1) {
    return currentUnitId;
  }

  const nextUnfinishedUnits = unitProgressInOrder
    .slice(currentUnitIndex)
    .filter((u) => u?.progress !== 1);
  const previousUnfinishedUnits = unitProgressInOrder
    .slice(0, currentUnitIndex)
    .filter((u) => u?.progress !== 1);
  if (nextUnfinishedUnits.length > 0) {
    return nextUnfinishedUnits[0]?.unitId;
  }

  return previousUnfinishedUnits[0]?.unitId;
}

// if all subunits in current unit or next unfinished unit but the unit test is not then we return that unit
export function getNextUnfinished(
  currentSubunitId: string,
  currentUnitId: string,
  courseProgress: Progress | undefined,
  courseData: Course | undefined
):
  | {
      type: "subunit";
      subunitId: string;
      subunitProgress: SubunitProgress;
      unitId: string;
      unitProgress: UnitProgress;
    }
  | {
      type: "unit";
      unitId: string;
      courseId: string;
      unitProgress: UnitProgress;
    }
  | {
      type: "course";
      courseId: string;
      courseProgress: Progress;
    }
  | undefined {
  if (!courseProgress) {
    return undefined;
  }

  if (courseProgress.units.every((u) => u.progress === 1)) {
    return {
      type: "course",
      courseId: courseProgress.courseId,
      courseProgress: courseProgress,
    };
  }

  const nextUnfinishedUnitId = getNextUnfinishedUnit(
    currentUnitId,
    courseProgress,
    courseData
  );
  if (!nextUnfinishedUnitId) {
    return undefined;
  }

  const unitData = courseData?.units?.find(
    (u) => u.id === nextUnfinishedUnitId
  );
  if (!unitData) {
    return undefined;
  }

  const unitProgress = courseProgress?.units.find(
    (u) => u.unitId === nextUnfinishedUnitId
  );
  if (!unitProgress) {
    return undefined;
  }
  if (unitProgress.progress === 0) {
    return {
      type: "subunit",
      subunitId: unitData.subunitOrder[0],
      unitId: nextUnfinishedUnitId,
      subunitProgress: {
        progress: 0,
        subunitId: unitData.subunitOrder[0],
        difficulty: "easy",
        updatedAt: new Date().toString(),
      },
      unitProgress: unitProgress,
    };
  }

  const currentSubunitIndex = unitData.subunitOrder.indexOf(currentSubunitId);
  const subunitProgressInOrder = unitData.subunitOrder.map((suo) =>
    unitProgress.subunits?.find((su) => su.subunitId === suo)
  );

  if (
    currentSubunitIndex === undefined &&
    nextUnfinishedUnitId === currentUnitId
  ) {
    return undefined;
  }

  const currentSubunitProgress = subunitProgressInOrder[currentSubunitIndex];
  if (!currentSubunitProgress && nextUnfinishedUnitId === currentUnitId) {
    return undefined;
  }

  if (currentSubunitProgress && currentSubunitProgress.progress !== 1) {
    return {
      type: "subunit",
      subunitId: currentSubunitId,
      subunitProgress: currentSubunitProgress,
      unitId: nextUnfinishedUnitId,
      unitProgress: unitProgress,
    };
  }

  const nextUnfinishedSubunits = subunitProgressInOrder
    .slice(currentSubunitIndex || 0)
    .filter((su) => su?.progress !== 1);
  const previousUnfinishedSubunits = subunitProgressInOrder
    .slice(0, currentSubunitIndex)
    .filter((su) => su?.progress !== 1);
  if (
    nextUnfinishedSubunits.length > 0 &&
    nextUnfinishedSubunits[0]?.subunitId
  ) {
    return {
      type: "subunit",
      subunitId: nextUnfinishedSubunits[0].subunitId,
      subunitProgress: nextUnfinishedSubunits[0],
      unitId: nextUnfinishedUnitId,
      unitProgress: unitProgress,
    };
  } else if (
    previousUnfinishedSubunits.length > 0 &&
    previousUnfinishedSubunits[0]?.subunitId
  ) {
    return {
      type: "subunit",
      subunitId: previousUnfinishedSubunits[0].subunitId,
      subunitProgress: previousUnfinishedSubunits[0],
      unitId: nextUnfinishedUnitId,
      unitProgress: unitProgress,
    };
  }

  return {
    type: "unit",
    unitId: currentUnitId,
    courseId: courseProgress.courseId,
    unitProgress: unitProgress,
  };
}
