export const SectionTitle: React.FC<{ title: string; subtitle: string }> = ({
  title,
  subtitle,
}) => (
  <div className="mb-10 text-center md:mb-16">
    <h3 className="mb-2 font-serif text-[28px] font-bold leading-normal text-dm-brand-blue-600 md:text-[40px]">
      {title}
    </h3>
    <h4 className="text-lg md:text-2xl">{subtitle}</h4>
  </div>
);
