import { useState, useEffect, Fragment } from "react";
import { Routes, Route, NavLink, Link } from "react-router-dom";
import clsx from "clsx";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { ChevronLeftIcon, MenuIcon, XIcon } from "@heroicons/react/outline";
import Login from "./components/Login";
import CreateCode from "./components/CreateCode";
import Search from "./components/Search";
import SiteInfo from "./components/SiteInfo";
import LicenseLookup from "./components/LicenseLookup";
import Purgatory from "./components/Purgatory";
import ErrorBoundary from "../shared/ErrorBoundary";
import Quote from "./components/Quote";
import QuoteFinder from "./components/QuoteFinder";
import QuoteDistrict from "./components/QuoteDistrict";
import Invoice from "./components/Invoice/Invoice";
import InvoicesReport from "./components/InvoicesReport";
import Standards from "./components/standards/Standards";
import BulkEditEmails from "./components/BulkEditEmails";
import AppSwitcher from "../shared/AppSwitcher";
import InvoiceSearch from "./components/InvoiceSearch";
import EstimateInfo from "./components/EstimateInfo";
import { QueryCache, QueryClient } from "react-query";
import Heaven from "./components/Heaven";
import NcesFlags from "./components/NcesFlags";
import StateTaxReport from "./components/StateTaxReport";
import { executeQuery, getFilePath } from "../utils";
import SkillCodes from "./components/skill-codes/SkillCodes";
import CourseBuilder from "./components/course-builder/CourseBuilder";
import {
  ToasterContextProvider,
  useDeltaToastContext,
} from "../shared/contexts/ToasterContext";
import Course from "./components/Course/Course";
import CourseUnit from "./components/CourseUnit/CourseUnit";
import CourseSubunit from "./components/courseSubunit/CourseSubunit";
import SearchStudent from "./components/student/SearchStudent";
import jwtDecode from "jwt-decode";
import CourseDetail from "./components/Course/CourseDetail";
import BetaAppInvite from "./components/BetaAppInvite";
import "./index.css";
import Renewals from "./components/Renewals/Renewals";
import {
  ChevronDownIcon,
  ChevronRightIcon,
  ChevronUpIcon,
} from "@heroicons/react/solid";
import BetaUsersList from "./components/BetaUsersList";
import { ChangeLog } from "./components/ChangeLog";
import LearnerPasswordReset from "./components/LearnerPasswordReset";
import { FeatureFlagsPage } from "./components/FeatureFlags/FeatureFlagsPage";
import { SubscriptionPlansPage } from "./components/subscriptions/SubscriptionPlansPage";
import { PriceSchemesPage } from "./components/PriceSchemes/PriceSchemesPage";
import LTILogs from "./components/LTILookup/LTILogs";
import GradePassback from "./components/LTILookup/GradePassback";
import AssignmentDiffSearch from "./components/AssignmentDiffSearch";
import { FreeSubscriptionsPage } from "./components/subscriptions/FreeSubscriptionsPage";
import AllLearners from "./components/AllLearners";

export default function App() {
  const toastContext = useDeltaToastContext();
  const queryClient = new QueryClient({
    queryCache: new QueryCache({
      onError: (error: any) => {
        toastContext.addToast({ status: "Error", message: error.message });
      },
      onSuccess: (data: any) => {
        if (data.success) {
          toastContext.addToast({
            status: "Success",
            message: data.message || "",
          });
        }
      },
    }),
  });
  const [loggedIn, setLoggedIn] = useState<boolean | undefined>(false);
  const [userPermissionsData, setUserPermissionsData] = useState<string[]>([]);

  useEffect(() => {
    document.title = "DeltaMath Manager";
  }, []);

  const navigation = [
    // { name: "Manage Code", href: `create-code`, privileges: ["manager"] },
    // {
    //   name: "Standards",
    //   href: `standards`,
    //   privileges: ["standardEditor", "standardApprover"],
    // },
    { name: "Feature Flags", href: "feature-flags", privileges: ["manager"] },
  ];

  const contentMenu = [
    {
      name: "Standards",
      href: `standards`,
      privileges: ["standardEditor", "standardApprover"],
    },
  ];

  const navigationSalesMenu = [
    { name: "School / District Search", href: ``, privileges: ["manager"] },
    { name: "Quote Finder", href: `quote-finder`, privileges: ["manager"] },
    //{ name: "Lookup", href: `quote-lookup`, privileges: ["manager"] },
    { name: "Purgatory", href: `purgatory`, privileges: ["manager"] },
    { name: "Heaven", href: `heaven`, privileges: ["manager"] },
    {
      name: "Invoices Report",
      href: `invoices-report`,
      privileges: ["manager"],
    },
    { name: "Flag NCES ID", href: `nces-flags`, privileges: ["manager"] },
    {
      name: "State Tax Report",
      href: `state-tax-report`,
      privileges: ["manager"],
    },
    { name: "Renewals", href: `renewals`, privileges: ["manager"] },
    { name: "Manage Codes", href: `create-code`, privileges: ["manager"] },
    { name: "Change Log", href: `change-log`, privileges: ["managerAdmin"] },
  ];

  const supportMenu = [
    {
      name: "Assignment Search",
      href: `assignment-diff-search`,
      privileges: ["manager"],
    },
    { name: "LTI Lookup", href: `lti-lookup`, privileges: ["manager"] },
    { name: "Grade Passback", href: `grade-passback`, privileges: ["manager"] },
    {
      name: "Bulk Edit Emails",
      href: `bulk-edit-emails`,
      privileges: ["bulkActionEditor"],
    },
    {
      name: "Manage Prices",
      href: `manage-prices`,
      privileges: ["priceSchemeEditor"],
    },
  ];

  const searchMenu = [
    { name: "Teacher Search", href: `search`, privileges: ["manager"] },
    { name: "Student Search", href: `search-student`, privileges: ["manager"] },
    { name: "License Lookup", href: `license-lookup`, privileges: ["manager"] },
    { name: "Site Info", href: `site-info`, privileges: ["manager"] },
  ];

  const learnerMenu = [
    { name: "Course Builder", href: `course-builder`, privileges: ["manager"] },
    {
      name: "Subscription Plans",
      href: `subscriptions/plans`,
      privileges: ["manager"],
    },
    {
      name: "Free Subscriptions",
      href: `subscriptions/free`,
      privileges: ["manager"],
    },
    { name: "Beta App Invite", href: `beta-invite`, privileges: ["manager"] },
    { name: "Beta App Users", href: `beta-users`, privileges: ["manager"] },
    {
      name: "Learner Password Reset",
      href: `learner-password-reset`,
      privileges: ["manager"],
    },
    {
      name: "All Learners",
      href: `all-learners`,
      privileges: ["manager"],
    },
  ];

  const smallNavigation: Array<
    | {
        groupName: string;
        menu: { name: string; href: string; privileges: string[] }[];
      }
    | { name: string; href: string; privileges: string[] }
  > = [
    { groupName: "Sales", menu: navigationSalesMenu },
    { groupName: "Search", menu: searchMenu },
    { groupName: "Support", menu: supportMenu },
    { groupName: "Content", menu: contentMenu },
    { name: "Feature Flags", href: "feature-flags", privileges: ["manager"] },
    { groupName: "Learner", menu: learnerMenu },
  ];

  // const smallNavigation = [
  //   { name: "Quote Purgatory", href: `purgatory`, privileges: ["manager"] },
  //   { name: "Quote Heaven", href: "heaven", privileges: ["manager"] },
  //   { name: "Quote Lookup", href: `quote-lookup`, privileges: ["manager"] },
  //   { name: "School / District Search", href: ``, privileges: ["manager"] },
  //   { name: "Teacher Search", href: `search`, privileges: ["manager"] },
  //   { name: "Student Search", href: `search-student`, privileges: ["manager"] },
  //   {
  //     name: "Invoices Report",
  //     href: `invoices-report`,
  //     privileges: ["manager"],
  //   },
  //   { name: "Manage Code", href: `create-code`, privileges: ["manager"] },
  //   { name: "Site Info", href: `site-info`, privileges: ["manager"] },
  //   {
  //     name: "Standards",
  //     href: `standards`,
  //     privileges: ["standardEditor", "standardApprover"],
  //   },
  //   {
  //     name: "State Tax Report",
  //     href: `state-tax-report`,
  //     privileges: ["manager"],
  //   },
  //   { name: "Change Log", href: `change-log`, privileges: ["managerAdmin"] },
  // ];

  // const prefetchSiteInfo = async () => {
  //   await queryClient.prefetchQuery("site-info", () =>
  //     executeQuery({
  //       path: "/manager_new/manage/get-site-info",
  //     })
  //   );
  // };

  useEffect(() => {
    try {
      const decoded = jwtDecode(
        localStorage.getItem("customer_service_token") || ""
      ) as { data: { teacherPrivileges: string[] } };
      setUserPermissionsData(decoded.data.teacherPrivileges);
    } catch (e) {
      // Ignore
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("customer_service_token")) {
      setLoggedIn(true);
    }
  }, []);

  // useEffect(() => {
  //   if (loggedIn) {
  //     prefetchSiteInfo();
  //   }
  // }, [loggedIn]);

  return (
    <>
      <ToasterContextProvider>
        <div className="min-h-screen">
          <Disclosure as="nav" className="bg-dm-blue">
            {({ open }) => (
              <>
                <div className="mx-auto px-2 sm:px-6 lg:px-8">
                  <div className="relative flex h-16 items-center justify-between">
                    <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                      {loggedIn && (
                        <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                          <span className="sr-only">Open main menu</span>
                          {open ? (
                            <XIcon
                              className="block h-6 w-6"
                              aria-hidden="true"
                            />
                          ) : (
                            <MenuIcon
                              className="block h-6 w-6"
                              aria-hidden="true"
                            />
                          )}
                        </Disclosure.Button>
                      )}
                    </div>
                    <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                      {loggedIn && (
                        <div className="flex-shrink-0">
                          <Menu as="div" className="relative flex-shrink-0">
                            <div>
                              <Menu.Button className="flex rounded-full text-sm text-white focus:outline-none">
                                <span className="sr-only">Open user menu</span>
                                <img
                                  className="block h-8 w-auto"
                                  src={getFilePath(
                                    "/images/DeltaMath-Logo_Regular.png"
                                  )}
                                  alt="DeltaMath"
                                />
                              </Menu.Button>
                            </div>
                            <AppSwitcher
                              loggedIn={loggedIn}
                              setLoggedIn={setLoggedIn}
                            />
                          </Menu>
                        </div>
                      )}
                    </div>

                    <div className="absolute inset-y-0 right-0 hidden items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0 md:block">
                      {loggedIn && (
                        <>
                          <Menu as="span" className="relative z-50">
                            {({ open: menuOpen }) => (
                              <>
                                <div className="mr-3 inline-block">
                                  <Menu.Button className="rounded-full text-sm text-white focus:outline-none">
                                    <span className="sr-only">
                                      Open user menu
                                    </span>
                                    Sales
                                    {menuOpen ? (
                                      <ChevronUpIcon
                                        className="h-5 w-5 flex-shrink-0 text-white"
                                        aria-hidden="true"
                                      />
                                    ) : (
                                      <ChevronDownIcon
                                        className="h-5 w-5 flex-shrink-0 text-white"
                                        aria-hidden="true"
                                      />
                                    )}
                                  </Menu.Button>
                                </div>
                                <Transition
                                  as={Fragment}
                                  enter="transition ease-out duration-100"
                                  enterFrom="transform opacity-0 scale-95"
                                  enterTo="transform opacity-100 scale-100"
                                  leave="transition ease-in duration-75"
                                  leaveFrom="transform opacity-100 scale-100"
                                  leaveTo="transform opacity-0 scale-95"
                                >
                                  <Menu.Items className="absolute left-0 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    {userPermissionsData &&
                                      navigationSalesMenu.map((item) => (
                                        <>
                                          {item?.privileges.some((element) =>
                                            userPermissionsData.includes(
                                              element
                                            )
                                          ) && (
                                            <Menu.Item key={item.href}>
                                              <Link
                                                className="block px-4 py-2 text-sm text-gray-700"
                                                to={item.href}
                                              >
                                                {item.name}
                                              </Link>
                                            </Menu.Item>
                                          )}
                                        </>
                                      ))}
                                  </Menu.Items>
                                </Transition>
                              </>
                            )}
                          </Menu>

                          {userPermissionsData &&
                            searchMenu.some((component) =>
                              component.privileges.some((priv) =>
                                userPermissionsData.includes(priv)
                              )
                            ) && (
                              <Menu as="span" className="relative z-50">
                                {({ open: menuOpen }) => (
                                  <>
                                    <div className="mr-3 inline-block">
                                      <Menu.Button className="rounded-full text-sm text-white focus:outline-none">
                                        <span className="sr-only">
                                          Open user menu
                                        </span>
                                        Search
                                        {menuOpen ? (
                                          <ChevronUpIcon
                                            className="h-5 w-5 flex-shrink-0 text-white"
                                            aria-hidden="true"
                                          />
                                        ) : (
                                          <ChevronDownIcon
                                            className="h-5 w-5 flex-shrink-0 text-white"
                                            aria-hidden="true"
                                          />
                                        )}
                                      </Menu.Button>
                                    </div>
                                    <Transition
                                      as={Fragment}
                                      enter="transition ease-out duration-100"
                                      enterFrom="transform opacity-0 scale-95"
                                      enterTo="transform opacity-100 scale-100"
                                      leave="transition ease-in duration-75"
                                      leaveFrom="transform opacity-100 scale-100"
                                      leaveTo="transform opacity-0 scale-95"
                                    >
                                      <Menu.Items className="absolute right-0 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        {userPermissionsData &&
                                          searchMenu.map((item) => (
                                            <>
                                              {item?.privileges.some(
                                                (element) =>
                                                  userPermissionsData.includes(
                                                    element
                                                  )
                                              ) && (
                                                <Menu.Item key={item.href}>
                                                  <Link
                                                    className="block px-4 py-2 text-left text-sm text-gray-700"
                                                    to={item.href}
                                                  >
                                                    {item.name}
                                                  </Link>
                                                </Menu.Item>
                                              )}
                                            </>
                                          ))}
                                      </Menu.Items>
                                    </Transition>
                                  </>
                                )}
                              </Menu>
                            )}

                          <Menu as="span" className="relative z-50">
                            {({ open: menuOpen }) => (
                              <>
                                <div className="mr-3 inline-block">
                                  <Menu.Button className="rounded-full text-sm text-white focus:outline-none">
                                    <span className="sr-only">
                                      Open user menu
                                    </span>
                                    Support
                                    {menuOpen ? (
                                      <ChevronUpIcon
                                        className="h-5 w-5 flex-shrink-0 text-white"
                                        aria-hidden="true"
                                      />
                                    ) : (
                                      <ChevronDownIcon
                                        className="h-5 w-5 flex-shrink-0 text-white"
                                        aria-hidden="true"
                                      />
                                    )}
                                  </Menu.Button>
                                </div>
                                <Transition
                                  as={Fragment}
                                  enter="transition ease-out duration-100"
                                  enterFrom="transform opacity-0 scale-95"
                                  enterTo="transform opacity-100 scale-100"
                                  leave="transition ease-in duration-75"
                                  leaveFrom="transform opacity-100 scale-100"
                                  leaveTo="transform opacity-0 scale-95"
                                >
                                  <Menu.Items className="absolute right-0 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    {userPermissionsData &&
                                      supportMenu.map((item) => (
                                        <>
                                          {item?.privileges.some((element) =>
                                            userPermissionsData.includes(
                                              element
                                            )
                                          ) && (
                                            <Menu.Item key={item.href}>
                                              <Link
                                                className="block px-4 py-2 text-sm text-gray-700"
                                                to={item.href}
                                              >
                                                {item.name}
                                              </Link>
                                            </Menu.Item>
                                          )}
                                        </>
                                      ))}
                                  </Menu.Items>
                                </Transition>
                              </>
                            )}
                          </Menu>

                          {userPermissionsData &&
                            contentMenu.some((component) =>
                              component.privileges.some((priv) =>
                                userPermissionsData.includes(priv)
                              )
                            ) && (
                              <Menu as="span" className="relative z-50">
                                {({ open: menuOpen }) => (
                                  <>
                                    <div className="mr-3 inline-block">
                                      <Menu.Button className="rounded-full text-sm text-white focus:outline-none">
                                        <span className="sr-only">
                                          Open user menu
                                        </span>
                                        Content
                                        {menuOpen ? (
                                          <ChevronUpIcon
                                            className="h-5 w-5 flex-shrink-0 text-white"
                                            aria-hidden="true"
                                          />
                                        ) : (
                                          <ChevronDownIcon
                                            className="h-5 w-5 flex-shrink-0 text-white"
                                            aria-hidden="true"
                                          />
                                        )}
                                      </Menu.Button>
                                    </div>
                                    <Transition
                                      as={Fragment}
                                      enter="transition ease-out duration-100"
                                      enterFrom="transform opacity-0 scale-95"
                                      enterTo="transform opacity-100 scale-100"
                                      leave="transition ease-in duration-75"
                                      leaveFrom="transform opacity-100 scale-100"
                                      leaveTo="transform opacity-0 scale-95"
                                    >
                                      <Menu.Items className="absolute right-0 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        {userPermissionsData &&
                                          contentMenu.map((item) => (
                                            <>
                                              {item?.privileges.some(
                                                (element) =>
                                                  userPermissionsData.includes(
                                                    element
                                                  )
                                              ) && (
                                                <Menu.Item key={item.href}>
                                                  <Link
                                                    className="block px-4 py-2 text-sm text-gray-700"
                                                    to={item.href}
                                                  >
                                                    {item.name}
                                                  </Link>
                                                </Menu.Item>
                                              )}
                                            </>
                                          ))}
                                      </Menu.Items>
                                    </Transition>
                                  </>
                                )}
                              </Menu>
                            )}

                          {userPermissionsData &&
                            navigation.map((item) => (
                              <>
                                {item?.privileges.some((element) =>
                                  userPermissionsData.includes(element)
                                ) && (
                                  <NavLink
                                    key={item.name}
                                    to={item.href}
                                    className={({ isActive }) =>
                                      clsx(
                                        "rounded-md px-3 py-2 text-sm font-medium hover:bg-gray-700 hover:text-white",
                                        isActive
                                          ? "text-white"
                                          : "text-gray-300 hover:text-white"
                                      )
                                    }
                                  >
                                    {item.name}
                                  </NavLink>
                                )}
                              </>
                            ))}

                          {userPermissionsData &&
                            learnerMenu.some((component) =>
                              component.privileges.some((priv) =>
                                userPermissionsData.includes(priv)
                              )
                            ) && (
                              <Menu as="span" className="relative z-50">
                                {({ open: menuOpen }) => (
                                  <>
                                    <div className="mr-4 inline-block">
                                      <Menu.Button className="rounded-full text-sm text-white focus:outline-none">
                                        <span className="sr-only">
                                          Open learner menu
                                        </span>
                                        Learner App
                                        {menuOpen ? (
                                          <ChevronUpIcon
                                            className="h-5 w-5 flex-shrink-0 text-white"
                                            aria-hidden="true"
                                          />
                                        ) : (
                                          <ChevronDownIcon
                                            className="h-5 w-5 flex-shrink-0 text-white"
                                            aria-hidden="true"
                                          />
                                        )}
                                      </Menu.Button>
                                    </div>
                                    <Transition
                                      as={Fragment}
                                      enter="transition ease-out duration-100"
                                      enterFrom="transform opacity-0 scale-95"
                                      enterTo="transform opacity-100 scale-100"
                                      leave="transition ease-in duration-75"
                                      leaveFrom="transform opacity-100 scale-100"
                                      leaveTo="transform opacity-0 scale-95"
                                    >
                                      <Menu.Items className="absolute right-0 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        {userPermissionsData &&
                                          learnerMenu.map((item) => (
                                            <>
                                              {item?.privileges.some(
                                                (element) =>
                                                  userPermissionsData.includes(
                                                    element
                                                  )
                                              ) && (
                                                <Menu.Item key={item.href}>
                                                  <Link
                                                    className="block px-4 py-2 text-left text-sm text-gray-700"
                                                    to={item.href}
                                                  >
                                                    {item.name}
                                                  </Link>
                                                </Menu.Item>
                                              )}
                                            </>
                                          ))}
                                      </Menu.Items>
                                    </Transition>
                                  </>
                                )}
                              </Menu>
                            )}
                        </>
                      )}
                    </div>
                  </div>
                </div>

                <Disclosure.Panel className="grid w-full grid-cols-8 sm:hidden">
                  <div className="col-span-5 grid grid-cols-subgrid space-y-1 px-2 pb-3 pt-2">
                    {loggedIn && (
                      <>
                        {userPermissionsData &&
                          smallNavigation.map((item: any) => {
                            if (!item.groupName) {
                              return (
                                <>
                                  {item?.privileges.some((element: any) =>
                                    userPermissionsData.includes(element)
                                  ) && (
                                    <NavLink
                                      key={item.name}
                                      to={item.href}
                                      className={({ isActive }) =>
                                        clsx(
                                          "col-span-5 block rounded-md px-3 py-2 text-base font-medium",
                                          isActive
                                            ? "text-white"
                                            : "text-gray-300 hover:text-white"
                                        )
                                      }
                                    >
                                      {item.name}
                                    </NavLink>
                                  )}
                                </>
                              );
                            } else if (Array.isArray(item.menu)) {
                              return (
                                <>
                                  {item.menu.some((component: any) =>
                                    component.privileges.some((priv: string) =>
                                      userPermissionsData.includes(priv)
                                    )
                                  ) && (
                                    <Menu
                                      as="div"
                                      className="z-50 col-span-5 grid grid-cols-subgrid"
                                    >
                                      {({ open: menuOpen }) => (
                                        <>
                                          <div className="col-span-2">
                                            <Menu.Button
                                              className="rounded-md px-3 py-2 text-base font-medium text-gray-300
                                        hover:text-white "
                                            >
                                              {item.groupName}
                                              {menuOpen ? (
                                                <ChevronLeftIcon
                                                  className="h-5 w-5 flex-shrink-0 text-white"
                                                  aria-hidden="true"
                                                />
                                              ) : (
                                                <ChevronRightIcon
                                                  className="h-5 w-5 flex-shrink-0 text-white"
                                                  aria-hidden="true"
                                                />
                                              )}
                                            </Menu.Button>
                                          </div>
                                          <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                          >
                                            <Menu.Items className="col-span-3 col-start-3 mt-2 rounded-md bg-white px-2 py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                              <div className="flex flex-col space-y-1 py-1">
                                                {item.menu.map(
                                                  (component: any) => (
                                                    <>
                                                      {component?.privileges.some(
                                                        (element: string) =>
                                                          userPermissionsData.includes(
                                                            element
                                                          )
                                                      ) && (
                                                        <Menu.Item
                                                          key={component.href}
                                                        >
                                                          <Link
                                                            className="text-sm text-gray-700"
                                                            to={component.href}
                                                          >
                                                            {component.name}
                                                          </Link>
                                                        </Menu.Item>
                                                      )}
                                                    </>
                                                  )
                                                )}
                                              </div>
                                            </Menu.Items>
                                          </Transition>
                                        </>
                                      )}
                                    </Menu>
                                  )}
                                </>
                              );
                            }
                          })}
                      </>
                    )}
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>

          {/* bg-sky-100 */}

          <div className="mx-auto min-h-[calc(100vh-64px)] bg-slate-200">
            <div id="modal-container"></div>
            {loggedIn && (
              <Routes>
                <Route
                  path="/"
                  element={
                    <ErrorBoundary>
                      <Quote />
                    </ErrorBoundary>
                  }
                />
                <Route
                  path="/quote-finder"
                  element={
                    <ErrorBoundary>
                      <QuoteFinder />
                    </ErrorBoundary>
                  }
                />
                <Route
                  path="search"
                  element={
                    <ErrorBoundary>
                      <Search />
                    </ErrorBoundary>
                  }
                />
                <Route
                  path="create-code"
                  element={
                    <ErrorBoundary>
                      <CreateCode />
                    </ErrorBoundary>
                  }
                />
                <Route
                  path="site-info"
                  element={
                    <ErrorBoundary>
                      <SiteInfo />
                    </ErrorBoundary>
                  }
                />
                <Route
                  path="license-lookup"
                  element={
                    <ErrorBoundary>
                      <LicenseLookup />
                    </ErrorBoundary>
                  }
                />
                <Route
                  path="license-lookup/id/:id"
                  element={
                    <ErrorBoundary>
                      <LicenseLookup />
                    </ErrorBoundary>
                  }
                />
                <Route
                  path="license-lookup/admin_email/:admin_email"
                  element={
                    <ErrorBoundary>
                      <LicenseLookup />
                    </ErrorBoundary>
                  }
                />
                <Route
                  path="standards"
                  element={
                    <ErrorBoundary>
                      <Standards />
                    </ErrorBoundary>
                  }
                />
                <Route
                  path="standards/:child"
                  element={
                    <ErrorBoundary>
                      <Standards />
                    </ErrorBoundary>
                  }
                />
                <Route
                  path="standards/:child/:id"
                  element={
                    <ErrorBoundary>
                      <Standards />
                    </ErrorBoundary>
                  }
                />
                <Route
                  path="feature-flags"
                  element={
                    <ErrorBoundary>
                      <FeatureFlagsPage />
                    </ErrorBoundary>
                  }
                />
                <Route
                  path="purgatory"
                  element={
                    <ErrorBoundary>
                      <Purgatory />
                    </ErrorBoundary>
                  }
                />
                <Route
                  path="state-tax-report"
                  element={
                    <ErrorBoundary>
                      <StateTaxReport />
                    </ErrorBoundary>
                  }
                />
                <Route
                  path="heaven"
                  element={
                    <ErrorBoundary>
                      <Heaven />
                    </ErrorBoundary>
                  }
                />
                <Route
                  path="heaven/:year"
                  element={
                    <ErrorBoundary>
                      <Heaven />
                    </ErrorBoundary>
                  }
                />
                <Route
                  path="/:type/:id"
                  element={
                    <ErrorBoundary>
                      <QuoteDistrict />
                    </ErrorBoundary>
                  }
                />
                <Route
                  path="/invoices-report"
                  element={
                    <ErrorBoundary>
                      <InvoicesReport />
                    </ErrorBoundary>
                  }
                />

                <Route
                  path="/quote-lookup"
                  element={
                    <ErrorBoundary>
                      <InvoiceSearch />
                    </ErrorBoundary>
                  }
                />
                <Route
                  path="/quote-lookup/:quoteNumber"
                  element={
                    <ErrorBoundary>
                      <Invoice />
                    </ErrorBoundary>
                  }
                />
                <Route
                  path="/line-items"
                  element={
                    <ErrorBoundary>
                      <EstimateInfo />
                    </ErrorBoundary>
                  }
                />
                <Route
                  path="/line-items/:quoteNumber"
                  element={
                    <ErrorBoundary>
                      <EstimateInfo />
                    </ErrorBoundary>
                  }
                />
                <Route
                  path="/nces-flags"
                  element={
                    <ErrorBoundary>
                      <NcesFlags />
                    </ErrorBoundary>
                  }
                />
                <Route
                  path="/skill-codes"
                  element={
                    <ErrorBoundary>
                      <SkillCodes />
                    </ErrorBoundary>
                  }
                />
                <Route
                  path="/course-builder"
                  element={
                    <ErrorBoundary>
                      <CourseBuilder />
                    </ErrorBoundary>
                  }
                />
                <Route
                  path="/subscriptions/plans"
                  element={
                    <ErrorBoundary>
                      <SubscriptionPlansPage />
                    </ErrorBoundary>
                  }
                />
                <Route
                  path="/subscriptions/free"
                  element={
                    <ErrorBoundary>
                      <FreeSubscriptionsPage />
                    </ErrorBoundary>
                  }
                />
                <Route
                  path="/course/:courseId"
                  element={
                    <ErrorBoundary>
                      <Course />
                    </ErrorBoundary>
                  }
                />
                <Route
                  path="/course-detail/:courseId"
                  element={
                    <ErrorBoundary>
                      <CourseDetail />
                    </ErrorBoundary>
                  }
                />
                <Route
                  path="/unit/:unitId"
                  element={
                    <ErrorBoundary>
                      <CourseUnit />
                    </ErrorBoundary>
                  }
                />
                <Route
                  path="/subunit/:subunitId"
                  element={
                    <ErrorBoundary>
                      <CourseSubunit />
                    </ErrorBoundary>
                  }
                />
                <Route
                  path="search-student"
                  element={
                    <ErrorBoundary>
                      <SearchStudent />
                    </ErrorBoundary>
                  }
                />
                <Route
                  path="beta-invite"
                  element={
                    <ErrorBoundary>
                      <BetaAppInvite />
                    </ErrorBoundary>
                  }
                />
                <Route
                  path="beta-users"
                  element={
                    <ErrorBoundary>
                      <BetaUsersList />
                    </ErrorBoundary>
                  }
                />
                <Route
                  path="renewals"
                  element={
                    <ErrorBoundary>
                      <Renewals />
                    </ErrorBoundary>
                  }
                />
                <Route
                  path="bulk-edit-emails"
                  element={
                    <ErrorBoundary>
                      <BulkEditEmails />
                    </ErrorBoundary>
                  }
                />
                {userPermissionsData &&
                  userPermissionsData.includes("priceSchemeEditor") && (
                    <Route
                      path="manage-prices"
                      element={
                        <ErrorBoundary>
                          <PriceSchemesPage />
                        </ErrorBoundary>
                      }
                    />
                  )}
                {userPermissionsData &&
                  userPermissionsData.includes("managerAdmin") && (
                    <Route
                      path="change-log"
                      element={
                        <ErrorBoundary>
                          <ChangeLog />
                        </ErrorBoundary>
                      }
                    />
                  )}
                <Route
                  path="learner-password-reset"
                  element={
                    <ErrorBoundary>
                      <LearnerPasswordReset />
                    </ErrorBoundary>
                  }
                />
                <Route
                  path="lti-lookup"
                  element={
                    <ErrorBoundary>
                      <LTILogs />
                    </ErrorBoundary>
                  }
                />
                <Route
                  path="assignment-diff-search"
                  element={
                    <ErrorBoundary>
                      <AssignmentDiffSearch />
                    </ErrorBoundary>
                  }
                />
                <Route
                  path="grade-passback"
                  element={
                    <ErrorBoundary>
                      <GradePassback />
                    </ErrorBoundary>
                  }
                />
                <Route
                  path="all-learners"
                  element={
                    <ErrorBoundary>
                      <AllLearners />
                    </ErrorBoundary>
                  }
                />
              </Routes>
            )}

            {!loggedIn && (
              <Login setLoggedIn={setLoggedIn} accountType="admin" />
            )}
          </div>
        </div>
      </ToasterContextProvider>
    </>
  );
}
