import clsx from "clsx";
import { PropsWithChildren, ReactNode } from "react";
import { Infotip } from "../../../shared/Infotip";

export const DashboardCard: React.FC<
  PropsWithChildren<{
    size?: "large" | "flex";
    childClasses?: string;
    icon: ReactNode;
    title: string;
    tooltip?: string;
  }>
> = ({ size = "flex", childClasses = "", icon, title, children, tooltip }) => (
  <div
    className={clsx(
      "relative flex w-full flex-col justify-between overflow-clip rounded-xl border border-dm-charcoal-100 bg-white p-6",
      size === "large" ? "min-h-[291px]" : "h-full"
    )}
  >
    <h3 className="flex items-center gap-2 text-sm">
      <span className="w-6">{icon}</span>
      {title}
      {tooltip && (
        <Infotip message={tooltip}>
          <i className="far fa-info-circle cursor-pointer text-dm-gray-200" />
        </Infotip>
      )}
    </h3>
    <div className={childClasses}>{children}</div>
  </div>
);
