import { CourseSearch } from "../Search/CourseSearch";

export const CoursesHeader: React.FC = () => (
  <header className="mb-6 flex flex-row items-center gap-4 rounded-lg border border-dm-charcoal-100 bg-white px-8 py-6">
    <h1 className="flex-1 font-serif text-2xl font-bold !leading-10 text-dm-charcoal-800">
      All Courses
    </h1>
    <div className="flex-1">
      <CourseSearch type="courses" />
    </div>
  </header>
);
