import { useParams } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { useCourseContext } from "../../../contexts/CourseContext";
import { useLearnerContext } from "../../../contexts/LearnerContext";
import { LearnerAssignment } from "../../../types";
import Button from "../../../../student/components/generic/button";
import { REACT_APP_LEARNER_LINK } from "../../../../utils";
import { PropsWithChildren } from "react";

export const PostQuizSummaryNextButton: React.FC<{
  assignment: LearnerAssignment | undefined;
}> = ({ assignment }) => {
  const learnerContext = useLearnerContext();
  const courseContext = useCourseContext();
  const { coursePath, unitPath } = useParams();
  const tooltipId = "postquiz-summary-next-button-tooltip";

  if (!assignment) {
    return null;
  }

  const allUnitProgress =
    learnerContext.getProgress(assignment.courseId)?.units || [];
  const allSubunitProgress =
    allUnitProgress.find(({ unitId }) => unitId === assignment.unitId)
      ?.subunits || [];
  const currentSubunitIndex = allSubunitProgress.findIndex(
    ({ subunitId }) => subunitId === assignment.subunitId
  );

  // If the next subunit is incomplete, use that. Otherwise, look back through
  // the unit for other incomplete subunits.
  const nextSubunitProgress = allSubunitProgress[currentSubunitIndex + 1];
  const incompleteSubunitProgress =
    nextSubunitProgress && nextSubunitProgress.progress < 1
      ? nextSubunitProgress
      : allSubunitProgress.find(({ progress }) => progress < 1);

  const StyledButton: React.FC<PropsWithChildren<{ href: string }>> = ({
    href,
    children,
  }) => (
    <Button size="small" className="shrink-0 max-md:w-full" href={href}>
      {children}
    </Button>
  );

  if (incompleteSubunitProgress) {
    const units = courseContext.getCourseData(assignment.courseId)?.units || [];
    const subunits =
      units.find(({ id }) => id === assignment.unitId)?.subunits || [];
    const subunit = subunits.find(
      ({ id }) => id === incompleteSubunitProgress.subunitId
    );

    if (subunit) {
      return (
        <>
          <ReactTooltip
            id={tooltipId}
            className="!px-2 !py-1 !text-xs"
            effect="solid"
          />
          <span data-for={tooltipId} data-tip={subunit.name}>
            <StyledButton
              href={`${REACT_APP_LEARNER_LINK}/${coursePath}/${unitPath}/${subunit.path}`}
            >
              Start Next Section
            </StyledButton>
          </span>
        </>
      );
    }
  }

  return (
    <StyledButton href={`${REACT_APP_LEARNER_LINK}/${coursePath}/${unitPath}`}>
      Unit Summary
    </StyledButton>
  );
};
