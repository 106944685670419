import { Link } from "react-router-dom";
import { REACT_APP_LEARNER_LINK } from "../../../utils";
import { Course } from "../../types";
import { useCurrentCourseData } from "../../utils/useCurrentCourseData";
import { CourseProgressRadial } from "../Course/CourseProgressRadial";
import { uniqueId } from "lodash";

export const CourseCard: React.FC<{ course: Course }> = ({ course }) => {
  const courseNameId = uniqueId("courseName");
  const courseDescId = uniqueId("courseDesc");
  const { currentCourseData } = useCurrentCourseData(course.id);
  const numUnits = course.units?.length || 0;
  const courseProgressPercent = Math.round(
    (currentCourseData?.progress || 0) * 100
  );

  return (
    <Link
      to={`${REACT_APP_LEARNER_LINK}/course/${course.path}`}
      aria-labelledby={courseNameId}
      aria-describedby={courseDescId}
    >
      <div className="flex h-full flex-row gap-6 rounded-lg border border-dm-charcoal-100 bg-white px-8 py-6">
        <CourseProgressRadial
          courseProgress={currentCourseData}
          iconUrl={course.iconUrl}
          className="h-[70px] w-[70px] lg:h-[94px] lg:w-[94px]"
          imgClassName="h-auto w-[51px] lg:w-[77px]" // Note: see CourseProgressRadial component for artboard size to icon size ratios
        />
        <div>
          <h2
            className="mb-1 font-serif text-lg font-bold text-dm-charcoal-800"
            id={courseNameId}
          >
            {course.name}
          </h2>
          <div className="my-2 flex flex-row items-center gap-2">
            <p className="font-sans text-sm !leading-none text-dm-charcoal-500">
              {numUnits} {numUnits === 1 ? "Unit" : "Units"}
            </p>
            {courseProgressPercent > 0 && (
              <div className="rounded-full bg-dm-brand-blue-500 px-2 py-1 text-xs font-medium !leading-none text-white">
                {`${courseProgressPercent}% Complete`}
              </div>
            )}
          </div>
          <p
            className="font-sans text-sm !leading-7 text-dm-charcoal-500"
            id={courseDescId}
          >
            {course.description}
          </p>
        </div>
      </div>
    </Link>
  );
};
