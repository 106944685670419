import { useState } from "react";
import { useMutation } from "react-query";
import axios from "axios";
import { cloneDeep } from "lodash";
import { deltamathAPI } from "../../../../manager/utils";
import Button from "../../../../student/components/generic/button";
import { EyeIcon } from "@heroicons/react/outline";
import { LearnerAssignmentTypes } from "../../../types";
import { getFilePath } from "../../../../utils";
import { useDeltaToastContext } from "../../../../shared/contexts/ToasterContext";
import { useProblemSolvingContext } from "../../../contexts/ProblemSolvingContext";
import { useLearnerContext } from "../../../contexts/LearnerContext";
import { Checkbox } from "../../Splash/Checkbox";
import PortalModal from "../../../../shared/PortalModal";
import { useLearnerAnalytics } from "../../../analytics/useLearnerAnalytics";
import { usedTestHintEvent } from "../../../analytics/events";

type Props = {
  assignmentId: string;
  type?: LearnerAssignmentTypes;
  isSolutionShowing?: boolean;
};

const TestHint: React.FC<Props> = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [incorrectResults, setIncorrectResults] = useState<string[]>([]);
  const [answersDisappear, setAnswersDisappear] = useState(false);

  const toastContext = useDeltaToastContext();
  const learnerContext = useLearnerContext();

  const viewedTestHint = learnerContext.learner?.viewedTestHint ?? false;

  const assignmentLabel =
    props.type === "postQuiz"
      ? "Post Quiz"
      : props.type === "unitTest"
      ? "Unit Test"
      : "Course Test";

  const { assignment } = useProblemSolvingContext();

  const [nextStep, setNextStep] = useState(false);

  const { track, getAssignmentData } = useLearnerAnalytics();

  const { mutate: testHintMutate, isLoading } = useMutation(
    (body: { assignmentId: string }) => {
      return axios.post(
        `${deltamathAPI()}/learner/assignment/test_hint`,
        JSON.stringify(body),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    },
    {
      onSuccess: (data) => {
        const wrongResults = data.data.results.reduce(
          (
            acc: any,
            result: { sk: string; hint: boolean | null },
            index: number
          ) => {
            if (result.hint === false) {
              return [...acc, `Question ${index + 1}`];
            }
            return acc;
          },
          []
        );
        const numCorrect = data.data.results.filter(
          ({ hint }: { hint: boolean | null }) => hint === true
        ).length;
        const numBlank =
          data.data.results.length - numCorrect - wrongResults.length;
        setIncorrectResults(wrongResults);
        setNextStep(true);

        track(
          usedTestHintEvent({
            ...getAssignmentData(props.type),
            numCorrect,
            numIncorrect: wrongResults.length,
            numBlank,
          })
        );
      },
      onError: (error: any) => {
        toastContext.addToast({
          title: "Error",
          message:
            error?.response?.data?.message ||
            error?.message ||
            "An error occurred",
          status: "Error",
        });
      },
    }
  );

  const { mutateAsync: viewedTestHintMutate } = useMutation(
    () => {
      return axios.post(`${deltamathAPI()}/learner/shared/viewed_test_hint`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
    },
    {
      onSuccess: (data) => {
        learnerContext.updateLearner({ viewedTestHint: true });
      },
    }
  );

  const close = async () => {
    if (nextStep) {
      const clonedAssignment = cloneDeep(assignment);
      clonedAssignment.testHint = new Date();

      learnerContext.updateAssignmentAndProgress({
        assignment: clonedAssignment,
      });
      if (!viewedTestHint) {
        await viewedTestHintMutate();
      }
    }
    setShowModal(false);
  };

  const testHintClick = () => {
    const body = {
      assignmentId: props.assignmentId,
    };
    testHintMutate(body);
  };

  const step1 = {
    title: (
      <>
        <img
          className="mx-auto mb-4 w-36"
          src={getFilePath("/images/learner/magnifying-glass.png")}
          alt="Question Swap"
        />
        <span className="font-serif font-bold">Use Test Hint?</span>
      </>
    ),
    body: "This will reveal any incorrect answers on this test. Remember, you can only use this hint once per test attempt!",
    confirmButtonText: "View My Answers",
    secondaryButtonText:
      props.type === "postQuiz" ? "Back to Quiz" : "Back to Test",
    secondaryOnClick: () => setShowModal(false),
    onConfirm: () => {
      testHintClick();
    },
    secondaryDisabled: isLoading,
    confirmDisabled: isLoading,
  };

  const step2 = {
    title: (
      <>
        <span className="block font-serif font-bold">
          {assignmentLabel} Incorrect Answers
        </span>
        <span className="mt-1 block text-sm">
          <strong>Heads up!</strong> Test hints will disappear when you close
          this window, so be sure to jot down what you need.
        </span>
      </>
    ),
    body:
      incorrectResults.length === 0 ? (
        <div className="mb-4 text-sm">
          There are no incorrect answered questions so far!
        </div>
      ) : (
        <>
          {incorrectResults.map((result, index) => (
            <div
              key={`${result}-key-${index}`}
              className="mb-2 flex items-center gap-x-1 text-sm last-of-type:mb-4"
            >
              <i className="far fa-times fa-fw text-xl leading-none !text-dm-error-500"></i>
              <span className="">{result}</span>
            </div>
          ))}
        </>
      ),
    footer: (
      <div className="flex flex-col justify-center border-t pt-3">
        {!viewedTestHint && (
          <Checkbox
            checked={answersDisappear}
            error={undefined}
            onChange={() => setAnswersDisappear(!answersDisappear)}
          >
            <span className="text-sm">
              Noted! I understand these answers will be hidden once I close this
              window.
            </span>
          </Checkbox>
        )}
        <Button
          {...(!viewedTestHint ? { disabled: !answersDisappear } : {})}
          onClick={close}
          type="primary"
          className="mx-auto mt-4 max-sm:w-full sm:w-80"
        >
          Got It!
        </Button>
      </div>
    ),
  };

  return (
    <>
      {!props.isSolutionShowing ? (
        <>
          <Button
            className="flex items-center gap-x-1 !px-3 !py-0 font-sans text-sm text-dm-brand-blue-500"
            type="link"
            onClick={() => {
              setShowModal(true);
            }}
          >
            <EyeIcon className="w-6 text-dm-gray-200" />
            Use Test Hints
          </Button>
          <PortalModal
            visible={showModal}
            onClose={close}
            disableClickedOff={true}
            noEsc={true}
            closeX={false}
            {...(!nextStep ? step1 : step2)}
          />
        </>
      ) : null}
    </>
  );
};

export default TestHint;
