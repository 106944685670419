import { LearnerAssignmentTypes } from "../../types";
import Modal from "../../../student/components/generic/Modal";
import { ResponsiveImage } from "../ResponsiveImage";
import { getFilePath } from "../../../utils";

type Props = {
  visible: boolean;
  onClose: () => void;
  onConfirm: () => void;
  pageState: LearnerAssignmentTypes;
};

const SubmitQuizModal = (props: Props) => {
  const readableAssignmentType = () => {
    if (props.pageState === "preQuiz") {
      return "Pre-Quiz";
    } else if (props.pageState === "practice") {
      return "Practice";
    } else if (props.pageState === "postQuiz") {
      return "Post-Quiz";
    } else if (props.pageState === "unitTest") {
      return "Unit Test";
    } else if (props.pageState === "courseTest") {
      return "Course Test";
    }
  };
  const isTest = ["unitTest", "courseTest"].includes(props.pageState);
  return (
    <Modal
      visible={props.visible}
      onClose={props.onClose}
      onConfirm={props.onConfirm}
      title={
        <>
          <ResponsiveImage
            className="mx-auto h-auto w-56"
            srcs={[
              getFilePath(
                "/images/learner/prequiz/submit-prequiz-you-sure.png"
              ),
              getFilePath(
                "/images/learner/prequiz/submit-prequiz-you-sure@2x.png"
              ),
            ]}
            alt=""
            aria-hidden="true"
          />
          <span className="mt-4 block font-serif text-2xl font-bold">
            Submit your {readableAssignmentType()?.toLowerCase()}?
          </span>
        </>
      }
      confirmButtonText={`Submit ${readableAssignmentType()}`}
      body={
        <span className="text-sm">
          Once you submit your {readableAssignmentType()?.toLowerCase()} you
          won&rsquo;t be able to change your answers. Make sure you take a
          moment to review!
        </span>
      }
      confirmAriaLabel={`Submit ${readableAssignmentType()}`}
      secondaryButtonText={`Back To ${isTest ? "Test" : "Quiz"}`}
      secondaryAriaLabel={`Back To ${isTest ? "Test" : "Quiz"}`}
      secondaryOnClick={() => {
        props.onClose();
      }}
    />
  );
};

export default SubmitQuizModal;
