export const ProfileSection: React.FC<{
  header: string;
  text: string;
  showText?: boolean;
  children?: React.ReactNode;
}> = ({ header, text, showText = true, children }) => (
  <div className="flex flex-col gap-1 text-sm leading-6 text-black">
    <div className="font-bold">{header}</div>
    {showText && <div>{text}</div>}
    {children}
  </div>
);
