import { Skeleton, SkeletonText } from "../Skeleton";
import { ProblemWrapper } from "./ProblemWrapper";

export const ProblemLoading: React.FC = () => (
  <ProblemWrapper>
    <div className="flex w-full flex-col gap-8 text-lg">
      <SkeletonText>
        <p>Cupidatat cillum do eu consequat irure</p>
      </SkeletonText>
      <div className="flex flex-col gap-2">
        <SkeletonText>
          <p>Incididunt qui voluptate laboris exercitation tempor</p>
        </SkeletonText>
        <SkeletonText>
          <p>
            Consequat in sint amet minim laborum aute excepteur laborum aliqua
            sint
          </p>
        </SkeletonText>
        <SkeletonText>
          <p>Cupidatat ex non exercitation voluptate</p>
        </SkeletonText>
      </div>
      <div className="w-full flex-grow">
        <Skeleton />
      </div>
    </div>
  </ProblemWrapper>
);
