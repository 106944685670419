import { REACT_APP_LEARNER_LINK } from "../../utils";
import { Course } from "../types";

/**
 * Returns an href (if it exists) to start the course (takes the user to the prequiz on first subunit of the first unit)
 */
export function generateStartCourseLink(course: Course): string | undefined {
  if (!course.unitOrder.length) return;

  const firstUnitID = course.unitOrder[0];
  const unit = (course.units ?? []).find((unit) => unit.id === firstUnitID);

  if (!unit || unit?.subunitOrder.length === 0) return;

  const firstSubunitID = unit.subunitOrder[0];
  const subunit = (unit.subunits ?? []).find(
    (subunit) => subunit.id === firstSubunitID
  );

  if (!subunit) return;

  return `${REACT_APP_LEARNER_LINK}/${course.path}/${unit.path}/${subunit.path}`;
}
