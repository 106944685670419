import { PropsWithChildren, Dispatch, SetStateAction } from "react";
import clsx from "clsx";

const ListItem: React.FC<
  PropsWithChildren<{
    important?: boolean;
  }>
> = ({ important, children }) => (
  <li>
    <span className="fa-li">
      <i
        className={clsx(
          "far fa-check",
          important ? "!text-dm-earth-yellow-500" : "!text-dm-brand-blue-500"
        )}
      ></i>
    </span>
    {important ? <strong>{children}</strong> : children}
  </li>
);

const PLUS_FEATURES = (
  <>
    <ListItem>Instructional videos for each module</ListItem>
    <ListItem>
      Assign your own videos with YouTube links and track student progress
    </ListItem>
    <ListItem>Create a test and delay solutions</ListItem>
    <ListItem>Create your own problems</ListItem>
    <ListItem>
      Make assignments for individual students or groups of students
    </ListItem>
    <ListItem>Customize modules to only assign certain problem types</ListItem>
    <ListItem>Assign specific questions to all students</ListItem>
    <ListItem>
      Standards alignment to all 50 states & DC, 13 Canadian provinces, AP
      Pre-Calc, AP Calc AB, and AP Calc BC
    </ListItem>
    <ListItem>Set maximum problem attempts</ListItem>
    <ListItem>Give individual students an extension</ListItem>
    <ListItem>Automatic Test Corrections</ListItem>
    <ListItem>Adding Coteacher to a Class</ListItem>
    <ListItem>Google Classroom Integration</ListItem>
    <ListItem>Assignment Folders</ListItem>
  </>
);

const INTEGRAL_FEATURES = (
  <>
    {PLUS_FEATURES}
    <ListItem important>Print assignments to PDF</ListItem>
    <ListItem important>Student upload of work</ListItem>
    <ListItem important>
      Canvas/Schoology integration for individual teacher
    </ListItem>
  </>
);

type Props = {
  type: "plus" | "integral";
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

const AllFeaturesAccordion = (props: Props): JSX.Element => {
  return (
    <div className="grow">
      {props.isOpen ? (
        <ul className="fa-ul mb-4 ml-6 flex flex-col gap-2">
          {props.type === "plus" ? PLUS_FEATURES : INTEGRAL_FEATURES}
        </ul>
      ) : null}
      <button
        className="text-sm text-dm-brand-blue-500"
        onClick={() => props.setIsOpen(!props.isOpen)}
      >
        {props.isOpen ? "Hide All Features" : "View All Features"}
      </button>
    </div>
  );
};

export default AllFeaturesAccordion;
