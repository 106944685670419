import { SubStandard } from "DeltaMathAPI/routers/standards.router/api";
import { SkillCodes } from "./SkillCodes";
import SkillDisplay from "./SkillDisplay";

type Props = {
  standard: SubStandard;
  depth: number;
  path: string;
  skillcodes: SkillCodes;
  skillAdd?: (name: string, skillCode: string) => void;
  exampleClick?: (skillCode?: string) => void;
};

const StandardDisplay = (props: Props) => {
  const path = props.path.concat(props.standard.description);

  const printLabel = (label: string | undefined, depth: number) => {
    if (depth === 2) return label ? " (" + label + ")" : "";
    else if ([3, 4, 5, 6].includes(depth)) {
      if (label && label.trim() === label) return label + ":";
      if (label && label.trim() !== label) return "\u2022"; // Bullet Point
      return "";
    }
  };

  // Because tailwind doesnt seem to like string manipulation (aka: `pl-${n}` does not work).
  const getPadding = (depth: number) => {
    if (depth === 2) return "pl-8";
    if (depth === 3) return "pl-16";
    if (depth === 4) return "pl-24";
    else if (depth === 5) return "pl-32";
    else if (depth === 6) return "pl-40";
    else return "";
  };

  const skillsInStandard = (props.standard.module_data || []).map((md) => {
    return {
      __index: md,
      ...props.skillcodes.skills[md],
    };
  });

  return (
    <>
      <div className="bg-white">
        <div className="flow-root px-4">
          {props.depth === 1 && (
            <h4
              id={path}
              className="dm-dark-blue mb-2 mt-0 text-3xl font-normal leading-relaxed hover:bg-dm-lightest-blue hover:text-white"
            >
              {props.standard.description}
            </h4>
          )}
          {props.depth === 2 && (
            <div
              className={getPadding(props.depth)}
              key={props.standard.description}
            >
              <h5
                id={path}
                className="text-1xl dm-dark-blue mb-2 mt-0 font-semibold leading-relaxed hover:bg-dm-lightest-blue hover:text-white"
              >
                {props.standard.description}{" "}
                {printLabel(props.standard.label, props.depth)}
              </h5>
              {skillsInStandard.map((skill, i) => (
                <SkillDisplay
                  skill={skill}
                  k={`${path}-${i}`}
                  skillAdd={props.skillAdd}
                  openExampleModal={props.exampleClick}
                  key={`${path}-${i}`}
                />
              ))}
            </div>
          )}
          {props.depth > 2 && (
            <div className={getPadding(props.depth)}>
              <h5
                id={path}
                className="hover:bg-dm-lightest-blue hover:text-white"
              >
                <span className="text-1xl dm-dark-blue mb-0 mt-0 inline-block font-semibold leading-relaxed">
                  {printLabel(props.standard.label, props.depth)}
                </span>
                <span className="leading-relaxed">
                  {" "}
                  {props.standard.description}
                </span>
              </h5>
              {skillsInStandard.map((skill, i) => (
                <SkillDisplay
                  key={`${path}-${i}`}
                  skill={skill}
                  k={`${path}-${i}`}
                  skillAdd={props.skillAdd}
                  openExampleModal={props.exampleClick}
                />
              ))}
            </div>
          )}
        </div>
      </div>
      {props.standard.data &&
        Object.entries(props.standard.data).map(([k, v]) => (
          <StandardDisplay
            key={k}
            depth={props.depth + 1}
            standard={v}
            path={path.concat(".data.")}
            skillcodes={props.skillcodes}
            skillAdd={props.skillAdd}
            exampleClick={props.exampleClick}
          />
        ))}
    </>
  );
};

export default StandardDisplay;
