import { PropsWithChildren } from "react";
import { GraduationCapIcon } from "../../../shared/icons/GraduationCapIcon";
import Button from "../../../student/components/generic/button";
import { SkeletonText } from "../Skeleton";
import { DashboardCard } from "./DashboardCard";
import { useLearnerAnalytics } from "../../analytics/useLearnerAnalytics";
import { continueLearningButtonEvent } from "../../analytics/events";

export const ContinueLearnerCardContent: React.FC<{
  isLoaded: boolean;
  heading: string;
  subheading: string;
  progressAmount: number;
  ctaText: string;
  ctaHref: string;
  secondaryButton?: boolean;
}> = ({
  isLoaded,
  heading,
  subheading,
  progressAmount,
  ctaText,
  ctaHref,
  secondaryButton,
}) => {
  const { track } = useLearnerAnalytics();
  // Progress < 2 looks weird, so set the minimum to 2
  const progressForProgressBar =
    progressAmount === 0 || progressAmount >= 0.02 ? progressAmount * 100 : 2;

  const TrackedSpan: React.FC<PropsWithChildren<unknown>> = ({ children }) => (
    // This onclick is just for analytics, so it's ok to ignore the warning
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <span onClick={() => track(continueLearningButtonEvent())}>{children}</span>
  );

  return (
    <DashboardCard
      size="large"
      icon={<GraduationCapIcon />}
      title="Continue Learning"
    >
      <h4 className="mb-1 font-serif text-lg font-bold">
        <SkeletonText loaded={isLoaded}>{heading}</SkeletonText>
      </h4>
      <h5 className="mb-2 text-sm">
        <SkeletonText loaded={isLoaded}>{subheading}</SkeletonText>
      </h5>

      <div className="mb-1 h-[10px] w-full rounded-full bg-gradient-to-r from-[#E3E8EF77] to-[#E3E8EF]">
        <div
          className="h-full rounded-full bg-gradient-to-r from-[#2F52E977] to-dm-brand-blue-500"
          style={{ width: `${progressForProgressBar}%` }}
        />
      </div>
      <div className="mb-4 text-xs">
        <SkeletonText loaded={isLoaded}>
          <p>
            <strong>{Math.round(progressAmount * 100)}%</strong> complete
          </p>
        </SkeletonText>
      </div>

      <div className="mb-4">
        <SkeletonText loaded={isLoaded}>
          <TrackedSpan>
            <Button
              href={ctaHref}
              type={secondaryButton ? "outline" : "primary"}
            >
              {ctaText}
            </Button>
          </TrackedSpan>
        </SkeletonText>
      </div>
    </DashboardCard>
  );
};
