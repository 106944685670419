import { ReactNode } from "react";

export const CancelStepHeader: React.FC<{
  title: string;
  subtitle?: string | ReactNode;
  banner?: string;
}> = ({ title, subtitle, banner }) => (
  <header className="flex flex-col items-center gap-2 sm:gap-1">
    {banner && (
      <div className="mb-2 rounded-full bg-[#F5D53F] px-8 py-2 font-bold">
        {banner}
      </div>
    )}
    <h2 className="text-center font-serif text-lg font-bold text-dm-brand-blue-600 sm:text-2xl">
      {title}
    </h2>
    {subtitle && <p className="text-center">{subtitle}</p>}
  </header>
);
