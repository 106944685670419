import { useContext } from "react";
import BookClosedIcon from "../icons/BookClosedIcon"; // TODO: Add correct icon
import { SectionDataStatus } from "../../_constants";
import StudentSectionsContext from "../../_context/StudentSectionsContext";
import Assignments from "../../components/assignments";
import SectionWelcomeState from "./SectionWelcomeState";
import SectionEmptyState from "./SectionEmptyState";
import { getSectionData } from "../../utils";
import { REACT_APP_STUDENT_LINK } from "../../../utils";

export default function Completed(): JSX.Element {
  const { activeSection, dmAssignmentData, showPastDue } = useContext(
    StudentSectionsContext
  );

  if (typeof dmAssignmentData[activeSection] === "undefined") {
    return <SectionWelcomeState />;
  }

  const assignmentList = getSectionData(
    SectionDataStatus.completed,
    activeSection,
    dmAssignmentData
  );
  const assignmentCount = assignmentList?.length;

  const showUpcoming =
    !assignmentCount &&
    getSectionData(SectionDataStatus.upcoming, activeSection, dmAssignmentData)
      ?.length
      ? true
      : false;

  return (
    <>
      {assignmentCount ? (
        <div className="mt-4 px-4 sm:mt-6 sm:px-6">
          {assignmentList?.map((section: any) => (
            <Assignments
              section={section}
              key={`com-section:${encodeURI(section?.ta?._id)}`}
            />
          ))}
        </div>
      ) : (
        <SectionEmptyState
          icon={<BookClosedIcon />}
          header="You have no completed assignments or tests"
          message={`You can find assignments to work on in the Upcoming ${
            showPastDue ? "or Past Due tabs" : "tab"
          } above.`}
          btnText={
            !showUpcoming && showPastDue
              ? "Work On Past Due Assignments"
              : "Check Out Upcoming"
          }
          btnLink={
            !showUpcoming && showPastDue
              ? `${REACT_APP_STUDENT_LINK}/${activeSection}/${SectionDataStatus["past-due"]}`
              : `${REACT_APP_STUDENT_LINK}/${activeSection}/${SectionDataStatus.upcoming}`
          }
        />
      )}
    </>
  );
}
