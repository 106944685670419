import clsx from "clsx";
import { Fragment, ReactNode, useState } from "react";
import { uniqueId } from "lodash";
import { Transition } from "@headlessui/react";

type Props = {
  message: string | ReactNode;
  children: ReactNode;
  /** If you don't have an event handler, use Infotip instead */
  onClick: (e?: any) => void;
  options?: {
    aligned?: "default" | "center";
    inline?: boolean;
    raise?: string;
    tooltipRight?: boolean;
  };
  className?: string;
};

export const TooltipButton = (props: Props) => {
  const [isShowing, setIsShowing] = useState(false);
  const tooltipId = uniqueId("tooltip");

  const showTooltip = () => setIsShowing(true);
  const hideTooltip = () => setIsShowing(false);
  const handleKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (e.key === "Escape") {
      hideTooltip();
    }
  };

  return (
    <div className="inline-block">
      <div
        className={clsx(
          "relative overflow-visible",
          props.options && props.options.inline ? "inline" : "flex flex-col",
          !props.options || props.options.aligned === "center"
            ? "items-center"
            : ""
        )}
        onMouseEnter={showTooltip}
        onMouseLeave={hideTooltip}
      >
        <button
          className={clsx(props.className, "min-h-7 min-w-7")}
          onFocus={showTooltip}
          onBlur={hideTooltip}
          onKeyDown={handleKeyDown}
          onClick={props.onClick}
          aria-describedby={tooltipId}
        >
          {props.children}
        </button>
        <Transition
          as={Fragment}
          show={isShowing}
          enter="transition-opacity duration-100"
          enterFrom="opacity-0"
          enterTo="opacity-100"
        >
          <div
            className={clsx(
              props?.options?.raise ? props.options.raise : "mb-6",
              "absolute bottom-0 z-50 flex flex-col items-center whitespace-nowrap",
              props.options?.tooltipRight ? "left-4 top-0" : ""
            )}
          >
            <span
              role="tooltip"
              id={tooltipId}
              className="relative z-50 whitespace-nowrap rounded-md bg-dm-charcoal-600 p-2 text-xs leading-5 text-white shadow-lg"
            >
              {props.message}
            </span>
            <div className="z-50 -mt-2 h-3 w-3 rotate-45 bg-dm-charcoal-600"></div>
          </div>
        </Transition>
      </div>
    </div>
  );
};
