import { useEffect, useState } from "react";
import { formatNumber } from "../../../utils";
import clsx from "clsx";

export default function SchoolDetails({
  teachers,
  school,
  children,
  districtData,
  schoolData,
  teacherCountsData,
}: {
  teachers: any;
  school: any;
  children: any;
  districtData: any;
  schoolData: any;
  teacherCountsData: any;
}) {
  const [studentsCount, setStudentsCount] = useState(0);
  const [assignmentsCount, setAssignmentsCount] = useState(0);
  const [problemsCount, setProblemsCount] = useState(0);
  const [expires, setExpires] = useState("");
  const [licenseTier, setLicenseTier] = useState("");

  useEffect(() => {
    if (teacherCountsData && teachers) {
      let studentsSubtotal = 0;
      let assignmentsSubtotal = 0;
      let problemsSubtotal = 0;

      for (let i = 0; i < teachers.length; i++) {
        const teacherDataObject = teacherCountsData[teachers[i]["_id"]];
        studentsSubtotal += teacherDataObject.students;
        assignmentsSubtotal += teacherDataObject.assignments;
        problemsSubtotal += teachers[i]["annual_logs"];
      }
      setStudentsCount(studentsSubtotal);
      setAssignmentsCount(assignmentsSubtotal);
      setProblemsCount(problemsSubtotal);
    }
  }, [teacherCountsData, teachers]);

  useEffect(() => {
    const currentUnixTime = Math.floor(Date.now() / 1000);

    //grab school license to check for whether school is covered on dist license
    const currentSchoolData = schoolData?.find(
      (s: any) => s.schoolid === school.schoolid
    );

    // First check the District License
    if (
      districtData?.dmLicense?.expiration &&
      districtData?.dmLicense?.expiration > currentUnixTime &&
      currentSchoolData?.coveredByDistrictLicense
    ) {
      const districtExpiration = new Date(
        districtData?.dmLicense?.expiration * 1000
      ).toLocaleDateString("en-US");
      setExpires(districtExpiration);
      setLicenseTier(
        districtData?.dmLicense?.has_integral ? "Integral" : "Plus"
      );
    }

    // Then check school license
    if (schoolData) {
      let showSchoolLicense = false;

      if (
        currentSchoolData?.dmLicense?.expiration &&
        currentSchoolData?.dmLicense?.expiration > currentUnixTime
      ) {
        // The below cases could have been combined into one if statement, but I wanted to make it clear what each case was doing

        // school has integral license and district does not
        if (
          currentSchoolData?.dmLicense?.has_integral &&
          !districtData?.dmLicense?.has_integral
        ) {
          showSchoolLicense = true;
        }

        // school and district have the same license level, but school expires after
        if (
          !!currentSchoolData?.dmLicense?.has_integral ===
            !!districtData?.dmLicense?.has_integral &&
          currentSchoolData?.dmLicense?.expiration >
            districtData?.dmLicense?.expiration
        ) {
          showSchoolLicense = true;
        }

        //finally, if there is a school license and the district license does not cover the school, show the school license
        if (
          currentSchoolData?.dmLicense?.expiration > currentUnixTime &&
          !currentSchoolData?.coveredByDistrictLicense
        ) {
          showSchoolLicense = true;
        }

        if (showSchoolLicense) {
          const schoolExpiration = new Date(
            currentSchoolData?.dmLicense?.expiration * 1000
          ).toLocaleDateString("en-US");
          setExpires(schoolExpiration);
          setLicenseTier(
            currentSchoolData.dmLicense.has_integral ? "Integral" : "Plus"
          );
        }
      }
    }
  }, [districtData, school.schoolid, schoolData]);

  return (
    <>
      <div className="inline-block">
        <div className={`group relative flex flex-col overflow-visible`}>
          {children}
          {
            <div
              className={clsx(
                //   props?.options?.raise ? props.options.raise : "mb-6",
                "absolute right-8 top-8 z-10 hidden flex-col items-center whitespace-nowrap group-hover:flex"
              )}
            >
              <div className="-mb-2 h-3 w-3 rotate-45 bg-dm-charcoal-600"></div>
              <span className="relative z-10 whitespace-nowrap rounded-md bg-dm-charcoal-600 p-2 text-xs leading-5 text-white shadow-lg">
                {/* {props.message} */}
                <div className="shadow sm:overflow-hidden sm:rounded-md">
                  {school && (
                    <>
                      <dl className="">
                        <div className="flex flex-row">
                          <dt className="text-xs font-medium text-white">
                            Students:
                          </dt>
                          <dd className="ml-1 text-xs text-white">
                            {formatNumber(studentsCount)}
                          </dd>
                        </div>
                        <div className="flex flex-row">
                          <dt className="text-xs font-medium text-white">
                            Assignments:
                          </dt>
                          <dd className="ml-1 text-xs text-white">
                            {formatNumber(assignmentsCount)}
                          </dd>
                        </div>
                        <div className="flex flex-row">
                          <dt className="text-xs font-medium text-white">
                            Problems this Year:
                          </dt>
                          <dd className="ml-1 text-xs text-white">
                            {formatNumber(problemsCount)}
                          </dd>
                        </div>
                        <div className="flex flex-row">
                          <dt className="text-xs font-medium text-white">
                            License Tier:{" "}
                          </dt>
                          <dd className="ml-1 text-xs text-white">
                            {licenseTier.toUpperCase()}
                          </dd>
                        </div>
                        <div className="flex flex-row">
                          <dt className="text-xs font-medium text-white">
                            License Expires:
                          </dt>
                          <dd className="ml-1 text-xs text-white">{expires}</dd>
                        </div>
                      </dl>
                    </>
                  )}
                </div>
              </span>
            </div>
          }
        </div>
      </div>
    </>
  );
}
