import Button from "../../../student/components/generic/button";
import { PricingConfigurator } from "./Payment/PricingConfigurator";
import { useHomepageSignupContext } from "./HomepageSignupContext";

export const Pricing: React.FC = () => {
  const { startTrialText, setShowSignup } = useHomepageSignupContext();
  const subscriptionBenefits = [
    {
      subtitle: "Unlimited Learning",
      description: "Access a vast library of math courses for all levels.",
    },
    {
      subtitle: "All-in-One Platform",
      description:
        "Enjoy a complete suite of learning tools, all in one place.",
    },
    {
      subtitle: "Expertly Crafted Courses",
      description:
        "High-quality content you can trust, designed by experienced teachers.",
    },
    {
      subtitle: "Real-Time Progress Tracking",
      description: "Stay informed with detailed progress reports.",
    },
    {
      subtitle: "Dedicated Support",
      description: "Get assistance from our support team whenever you need",
    },
    {
      subtitle: "Risk-Free Trial & Flexibility",
      description: "Try it free and choose monthly or annual plans.",
    },
    {
      subtitle: "Cancel Anytime",
      description:
        "Enjoy peace of mind knowing you can cancel your subscription at any time.",
    },
  ];

  return (
    <div className="flex w-full flex-col gap-12 md:flex-row md:gap-8">
      <div className="flex flex-col md:flex-1">
        <h5 className="mb-4 font-serif font-bold text-dm-brand-blue-600 md:text-lg">
          Benefits of Your Subscription
        </h5>
        <ul className="flex flex-col gap-6">
          {subscriptionBenefits.map((benefit) => (
            <li className="flex" key={benefit.subtitle}>
              <i
                className="far fa-check mr-3 text-base !text-dm-brand-blue-500"
                aria-hidden="true"
              ></i>
              <p>
                <span className="font-bold">{benefit.subtitle + ": "}</span>
                {benefit.description}
              </p>
            </li>
          ))}
        </ul>
      </div>
      <div className="flex flex-col gap-6 md:flex-1">
        <h5 className="-mb-2 font-serif font-bold text-dm-brand-blue-600 md:text-lg">
          Tailor Your Subscription
        </h5>
        <PricingConfigurator incrementorType="white" accountType={undefined} />
        <Button className="w-full text-sm" onClick={() => setShowSignup(true)}>
          {startTrialText}
        </Button>
      </div>
    </div>
  );
};
