import SidebarWrapper from "../SidebarWrapper";
import { useParams, Link } from "react-router-dom";
import clsx from "clsx";
import { useCourseContext } from "../../../contexts/CourseContext";
import Button from "../../../../student/components/generic/button";
import SubunitSkillsNav from "../subunit/SubunitSkillsNav";
import { LearnerAssignmentTypes } from "../../../types";
import { useLearnerContext } from "../../../contexts/LearnerContext";
import { REACT_APP_LEARNER_LINK } from "../../../../utils";

type Props = {
  type: LearnerAssignmentTypes;
};

export default function Sidebar(props: Props): JSX.Element {
  const { coursePath, unitPath } = useParams();
  const courseContext = useCourseContext();
  const { isSidebarMinimized } = useLearnerContext();

  const unitData = courseContext.getUnitData(unitPath, coursePath);
  const courseData = courseContext.getCourseData(coursePath);

  const unitOrderPosition =
    courseData && unitData && courseData?.unitOrder?.indexOf(unitData.id) + 1;

  return (
    <SidebarWrapper
      showCalculator={true}
      upperSection={
        <>
          <Button
            type="link"
            href={`${REACT_APP_LEARNER_LINK}/course/${coursePath}`}
          >
            <h3
              className={clsx(
                "mb-1 flex justify-between pr-5 text-lg font-bold leading-5 text-dm-charcoal-800 transition-opacity duration-300 ease-out max-lg:pl-3 sm:justify-normal",
                isSidebarMinimized ? "opacity-0" : "opacity-100"
              )}
            >
              {courseData?.name}
            </h3>
          </Button>
          {/* Unit Name */}
          {props.type === "unitTest" ? (
            <div className="flex items-center gap-x-2 border-b border-dm-charcoal-100 py-4 pl-2">
              <Button
                type="link"
                href={`${REACT_APP_LEARNER_LINK}/${coursePath}/${unitPath}`}
                className="flex items-center gap-x-2"
              >
                <span className="fa-stack fa-2x small w-[2em] text-[1em]">
                  <i className="fa-solid fal fa-circle fa-stack-2x text-dm-charcoal-100"></i>
                  <i className="fa-solid fas fa-th fa-stack-1x text-dm-brand-blue-500"></i>
                </span>
                <h3
                  className={clsx(
                    "text-sm text-dm-brand-blue-500 transition-opacity duration-300 ease-out",
                    isSidebarMinimized ? "opacity-0" : "opacity-100"
                  )}
                >
                  <strong>Unit {unitOrderPosition}:</strong> {unitData?.name}
                </h3>
              </Button>
            </div>
          ) : (
            <hr className="mt-4 border-dm-charcoal-100" />
          )}
        </>
      }
    >
      <div className="sidebar-inner-scroll overflow-auto py-6">
        <SubunitSkillsNav
          title={`${props.type === "unitTest" ? "Unit" : "Course"} Test`}
          type={props.type}
        />
      </div>
    </SidebarWrapper>
  );
}
