import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { useParams, useNavigate, Link, useLocation } from "react-router-dom";
import { getStartEndDate } from "../StudentUsageUtils";
import QuickStats from "./QuickStats";
import slugify from "slugify";
import { setIdsArray } from "../StudentUsageUtils";
import { parse, isValid } from "date-fns";
import { Tooltip } from "../../../shared/Tooltip";
import DatePicker from "react-datepicker";
import { Column } from "react-table";
import { DmLoadingSpinner } from "../../../manager/utils/functions";
import { REACT_APP_ADMIN_LINK, formatNumber, useDMQuery } from "../../../utils";
import { Transition } from "@headlessui/react";
import DeltaMathTable, {
  customGradeSort,
  MultiCheckFilter,
} from "../../../shared/DeltaMathTable";
import { DemoMode, demoString } from "../../../utils/demo";
import React from "react";
import { TextFilter, NoFilter } from "../../../shared/table/filters";
import ReportTabs from "./ReportTabs";
import { toLower } from "lodash";
import { DisplayGraphs } from "../DisplayGraphs";
import BackArrowIcon from "../../../shared/icons/BackArrowIcon";
import { QueryClient } from "react-query";
import { CondensedCell } from "../../../shared/table/cells";
import { UsageParamsInterface } from "../StudentUsageUtils";
const ONE_YEAR = 31536000000;
/**
 * custom hook to preserve previous state value, allowing for a comparison between new and old values.
 * @param value state variable whose value will be updated
 * @returns the previous value of an updated state variable (the current ref value, local to this function)
 * https://blog.logrocket.com/accessing-previous-props-state-react-hooks/
 */
const usePrevious = (value: any) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

const StudentUsageContainer = ({
  schoolsWithIntegral,
}: {
  schoolsWithIntegral: any;
}) => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { yesterday, lastWeek, thirtyDays, yearToDate } = getStartEndDate();
  const queryClient = new QueryClient();

  const parseLocalStorage = (storageObj: any, field: string) => {
    if (storageObj && Object.keys(storageObj).length) {
      if (Object.keys(storageObj.usagePreferences)) {
        if (Object.keys(storageObj.usagePreferences.usageReportDataScope)) {
          if (
            Object.keys(storageObj.usagePreferences.usageReportDataScope[field])
          ) {
            return storageObj.usagePreferences.usageReportDataScope[field];
          } else return {};
        } else return {};
      } else return {};
    } else return {};
  };
  const getDefaultDataScope = (obj: any) => {
    /** we need to parse this object on page load, but it requires these check in case anything is not defined. */
    if (obj && Object.keys(obj)) {
      if (obj.usagePreferences) {
        if (obj.usagePreferences.usageReportDataScope) {
          return Object.keys(
            storageObject?.usagePreferences?.usageReportDataScope.Schools
          ).length > 0
            ? "Schools"
            : Object.keys(
                storageObject?.usagePreferences?.usageReportDataScope.Teachers
              ).length > 0
            ? "Teachers"
            : Object.keys(
                storageObject?.usagePreferences?.usageReportDataScope.Sections
              ).length > 0
            ? "Sections "
            : "Schools";
        } else return "Schools";
      } else return "Schools";
    } else return "Schools";
  };

  const storageObject = JSON.parse(
    localStorage.getItem("usagePreferences") || "{}"
  );

  /** State data to required for generating a useage report */
  const [dateRange, setDateRange] = useState<string>("year-to-date");
  const [startDate, setStartDate] = useState<Date | null>(yearToDate);
  const [endDate, setEndDate] = useState<Date | null>(yesterday);
  const [selectedDataScope, setSelectedDataScope] = useState<string>(
    getDefaultDataScope(storageObject) || "Schools"
  );
  const [selectedSchools, setSelectedSchools] = useState<any>(
    parseLocalStorage(storageObject, "Schools")
  );
  const [selectedTeachers, setSelectedTeachers] = useState<any>(
    parseLocalStorage(storageObject, "Teachers")
  );
  const [selectedSections, setSelectedSections] = useState<any>(
    parseLocalStorage(storageObject, "Sections")
  );
  const [requestParams, setRequestParams] = useState<UsageParamsInterface>({
    start: `${(startDate?.getTime() ?? 0) / 1000}`,
    end: `${(endDate?.getTime() ?? 0) / 1000}`,
  });

  //Should be put back in if we go back to using the button
  // const [rengenerateDisabled, setRengenerateDisabled] = useState(true);
  const previousParams = usePrevious(requestParams);

  /** Fetch Teacher and Section data */

  const { data: teacherData } = useDMQuery({
    path: "/admin_new/data/teacher",
    queryOptions: {
      staleTime: 1000 * 60 * 15,
    },
  });

  const { data: sectionData } = useDMQuery({
    path: "/admin_new/data/sections",
    queryOptions: {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 15,
    },
  });

  /** Usage Stat request */
  const {
    data: usageData,
    refetch,
    isLoading,
  } = useDMQuery({
    cacheKey: [`/admin_new/data/usage`, JSON.stringify(requestParams)],
    path: `/admin_new/data/usage`,
    params: requestParams as Record<string, unknown>,
    method: "get",
    queryOptions: {
      enabled: false,
      //not using regenerate button for now
      // onSuccess: () => {
      //   setRengenerateDisabled(true);
      // },
    },
  });

  /** Parse existing preferences on page load */
  useEffect(() => {
    if (
      parseLocalStorage(storageObject, "Schools") &&
      Object.keys(parseLocalStorage(storageObject, "Schools")).length
    ) {
      setSelectedSchools(parseLocalStorage(storageObject, "Schools"));
    }
    if (
      parseLocalStorage(storageObject, "Teachers") &&
      Object.keys(parseLocalStorage(storageObject, "Teachers")).length
    ) {
      setSelectedTeachers(parseLocalStorage(storageObject, "Teachers"));
    }
    if (
      parseLocalStorage(storageObject, "Sections") &&
      Object.keys(parseLocalStorage(storageObject, "Sections")).length
    ) {
      setSelectedSections(parseLocalStorage(storageObject, "Sections"));
    }
    if (
      Object.keys(parseLocalStorage(storageObject, "Schools")).length === 0 &&
      Object.keys(parseLocalStorage(storageObject, "Teachers")).length === 0 &&
      Object.keys(parseLocalStorage(storageObject, "Sections")).length === 0
    ) {
      if (!schoolsWithIntegral || schoolsWithIntegral?.length === 0) return;
      if (selectedSchools.length === 0 && schoolsWithIntegral?.length > 0) {
        const allSchools = Object.create({});
        for (let i = 0; i < schoolsWithIntegral.length; i++) {
          allSchools[i] = true;
        }
        setSelectedSchools(allSchools);
      }
    }
    /** check if we have navigated to /select-scope page -- if so, select a default tab  */
    if (
      location.pathname.indexOf("select-scope") > 0 &&
      params.page === undefined
    ) {
      navigate(
        `${REACT_APP_ADMIN_LINK}/reports/student-usage/select-scope/${toLower(
          selectedDataScope
        )}`,
        {
          replace: true,
        }
      );
    }
  }, []);

  /** Update requestParams when a selection is changed */
  useEffect(() => {
    if (
      selectedDataScope === "Schools" &&
      selectedSchools &&
      schoolsWithIntegral.length > 0
    ) {
      const selectedSchoolIds = setIdsArray(
        selectedSchools,
        schoolsWithIntegral,
        ["schoolid"]
      ).map((school) => school.schoolid);
      setRequestParams({
        ...(startDate?.getTime()
          ? { start: Math.floor(startDate?.getTime() / 1000).toString() }
          : {}),
        ...(endDate?.getTime()
          ? { end: Math.floor(endDate?.getTime() / 1000).toString() }
          : {}),
        ...(selectedSchoolIds.length > 0
          ? { schoolIds: selectedSchoolIds }
          : {}),
        ...(selectedSchoolIds.length > 1 && selectedSchoolIds.length <= 5
          ? { series: selectedSchoolIds }
          : {}),
      });
    } else if (
      selectedDataScope === "Teachers" &&
      selectedTeachers &&
      teacherData
    ) {
      const selectedTeacherCodes = setIdsArray(selectedTeachers, teacherData, [
        "_id",
      ]).map((teacher) => teacher._id);
      setRequestParams({
        ...(startDate?.getTime()
          ? { start: Math.floor(startDate?.getTime() / 1000).toString() }
          : {}),
        ...(endDate?.getTime()
          ? { end: Math.floor(endDate?.getTime() / 1000).toString() }
          : {}),
        ...(selectedTeacherCodes.length > 0
          ? { teacherIds: selectedTeacherCodes }
          : {}),
        ...(selectedTeacherCodes.length > 1 && selectedTeacherCodes.length <= 5
          ? { series: selectedTeacherCodes }
          : {}),
      });
    } else if (
      selectedDataScope === "Sections" &&
      selectedSections &&
      sectionData
    ) {
      const selectedSectionIds = setIdsArray(selectedSections, sectionData, [
        "sectionId",
      ]).map((section) => section.sectionId);
      setRequestParams({
        ...(startDate?.getTime()
          ? { start: Math.floor(startDate?.getTime() / 1000).toString() }
          : {}),
        ...(endDate?.getTime()
          ? { end: Math.floor(endDate?.getTime() / 1000).toString() }
          : {}),
        ...(selectedSectionIds.length > 0
          ? { sectionIds: selectedSectionIds }
          : {}),
        ...(selectedSectionIds.length > 1 && selectedSectionIds.length <= 5
          ? { series: selectedSectionIds }
          : {}),
      });
    }
  }, [
    selectedDataScope,
    selectedSchools,
    selectedSections,
    selectedTeachers,
    schoolsWithIntegral,
    teacherData,
    sectionData,
    startDate,
    endDate,
  ]);

  useEffect(() => {
    let different = false;
    if (previousParams) {
      for (const item in requestParams) {
        if (
          JSON.stringify(
            requestParams[item as keyof UsageParamsInterface] || ""
          ) !== JSON.stringify(previousParams[item])
        ) {
          different = true;
        }
      }
    }
    //Automatically make the request rather than waiting for the user to click the button
    // different ? setRengenerateDisabled(false) : setRengenerateDisabled(true);
    if (
      different &&
      (requestParams?.studentIds ||
        requestParams?.teacherIds ||
        requestParams?.schoolIds ||
        requestParams?.sectionIds ||
        requestParams?.district === "true")
    ) {
      queryClient.invalidateQueries(["/admin_new/data/usage"]);
      refetch();
    }
  }, [requestParams, refetch]);

  /** Update dates via range select */
  // Sets Start and End Date when a date range selection is made
  useEffect(() => {
    if (dateRange === "last-week") {
      setStartDate(lastWeek);
      setEndDate(yesterday);
    } else if (dateRange === "thirty-days") {
      setStartDate(thirtyDays);
      setEndDate(yesterday);
    } else if (dateRange === "year-to-date") {
      setStartDate(yearToDate);
      setEndDate(yesterday);
    }
  }, [dateRange]);

  const scopeTabs = ["Schools", "Teachers", "Sections"];
  const getSelectedCount = (tab: string) => {
    let count = 0;
    if (tab === "Sections") {
      count = Object.keys(selectedSections).length;
    } else if (tab === "Teachers") {
      count = Object.keys(selectedTeachers).length;
    } else if (tab === "Schools") {
      count = Object.keys(selectedSchools).length;
    }
    return count;
  };
  const getSelectedInfo = (tab: string) => {
    let count = undefined;
    if (tab === "Sections") {
      count = Object.keys(selectedSections).length ?? undefined;
      if (count === sectionData.length) {
        count = "All";
      }
    } else if (tab === "Teachers") {
      count = Object.keys(selectedTeachers).length ?? undefined;
      if (count === teacherData.length) {
        count = "All";
      }
    } else if (tab === "Schools") {
      count = Object.keys(selectedSchools).length ?? undefined;
      if (count === schoolsWithIntegral.length) {
        count = "All";
      }
    }
    return count;
    // return count ? (
    //   <span className="ml-3 hidden rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-900 md:inline-block">
    //     {count}
    //   </span>
    // ) : undefined;
  };
  return (
    <>
      {location.pathname.includes(
        "/admin/reports/student-usage/select-scope"
      ) && (
        <>
          <div id="scope-header" className="border border-b-2 bg-white">
            <h2 className="px-6 pt-8 font-serif text-[24px] font-bold">
              Student Usage Reports
            </h2>

            <div className="py-8">
              <QuickStats />
            </div>

            <div className="flex px-6">
              <nav
                className="-mb-px flex justify-between sm:justify-normal sm:space-x-14"
                aria-label="Tabs"
              >
                {scopeTabs.map((tab) => (
                  <Link
                    key={tab}
                    to={`${REACT_APP_ADMIN_LINK}/reports/student-usage/select-scope/${slugify(
                      tab,
                      {
                        lower: true,
                      }
                    )}`}
                    onClick={() => setSelectedDataScope(tab)}
                    replace
                    className={clsx(
                      slugify(tab, { lower: true }) === params.page
                        ? "border-dm-brand-blue-500 font-bold"
                        : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                      "border-b-4 px-1 py-4 text-center text-sm"
                    )}
                    aria-current={
                      slugify(tab, { lower: true }) === params.page
                        ? "page"
                        : undefined
                    }
                  >
                    <span className="px-2">{tab}</span>
                    {/* {getSelectedCount(tab)} */}
                  </Link>
                ))}
              </nav>
            </div>
          </div>
          <div id="scope-body" className="p-6">
            <div className="flex justify-between">
              <div className="inline-flex">
                <Tooltip
                  options={{ aligned: "center", raise: "mb-10" }}
                  message="1 year maximum date range."
                >
                  <DatePicker
                    id="report-range"
                    selected={startDate}
                    onChange={(dates) => {
                      if (dates) {
                        const [start, end] = dates;
                        if (
                          end !== null &&
                          start !== null &&
                          end.getTime() - start.getTime() > ONE_YEAR
                        ) {
                          setDateRange("custom");
                        } else {
                          setStartDate(start);
                          setEndDate(end);
                          setDateRange("custom");
                        }
                      }
                    }}
                    onChangeRaw={(event) => {
                      const userEnteredDate = event.target.value;
                      if (typeof userEnteredDate !== "undefined") {
                        const datesArray = userEnteredDate.split(" - ");
                        if (datesArray.length === 2) {
                          const startDate = parse(
                            datesArray[0],
                            "MM/dd/yyyy",
                            new Date()
                          );
                          const endDate = parse(
                            datesArray[1],
                            "MM/dd/yyyy",
                            new Date()
                          );
                          if (
                            isValid(startDate) &&
                            isValid(endDate) &&
                            endDate.getTime() - startDate.getTime() > ONE_YEAR
                          ) {
                            setEndDate(startDate);
                          } else {
                            if (isValid(startDate)) {
                              setStartDate(startDate);
                              setDateRange("custom");
                            }
                            if (isValid(endDate)) {
                              setEndDate(endDate);
                              setDateRange("custom");
                            }
                          }
                        }
                      }
                    }}
                    startDate={startDate}
                    endDate={endDate}
                    maxDate={yesterday}
                    selectsRange
                    nextMonthButtonLabel=">"
                    previousMonthButtonLabel="<"
                    className="!w-48"
                    popperPlacement="bottom"
                    popperModifiers={[
                      {
                        name: "flip",
                        options: {
                          flipVariations: false,
                          allowedAutoPlacements: ["bottom", "bottom-start"],
                          fallbackPlacements: ["bottom", "bottom-start"],
                        },
                      },
                      {
                        name: "preventOverflow",
                        enabled: false,
                      },
                      {
                        name: "hide",
                        enabled: false,
                      },
                    ]}
                  />
                </Tooltip>
                <div className="ml-4 !w-36">
                  <select
                    onChange={(event) => {
                      setDateRange(event.target.value);
                    }}
                    value={dateRange}
                    className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-dm-light-blue focus:outline-none focus:ring-1 focus:ring-dm-light-blue sm:text-sm"
                  >
                    <option value={"year-to-date"}>School YTD</option>
                    <option value={"last-week"}>Last 7 days</option>
                    <option value={"thirty-days"}>Last 30 days</option>
                    <option value={"custom"}>Custom range</option>
                  </select>
                </div>
              </div>

              <Link
                to={`${REACT_APP_ADMIN_LINK}/reports/student-usage/view-report`}
                replace
                className={clsx(
                  Object.keys(selectedSchools).length ||
                    Object.keys(selectedTeachers).length ||
                    Object.keys(selectedSections).length
                    ? ""
                    : "pointer-events-none opacity-50",
                  "justify-center rounded-md border border-transparent bg-dm-brand-blue-500 px-8 py-2 text-sm font-bold text-white shadow-sm hover:bg-dm-brand-blue-500 focus:outline-none focus:ring-2 focus:ring-dm-brand-blue-500 focus:ring-offset-2"
                )}
              >
                Generate Report
              </Link>
            </div>

            <div id="select-data-scope" className="mt-8">
              <SelectDataScope
                schoolsWithIntegral={schoolsWithIntegral}
                selectedTeachers={selectedTeachers}
                setSelectedTeachers={setSelectedTeachers}
                selectedDataScope={""}
                setSelectedDataScope={undefined}
                selectedSchools={selectedSchools}
                setSelectedSchools={setSelectedSchools}
                selectedSections={selectedSections}
                setSelectedSections={setSelectedSections}
              />
            </div>
          </div>
        </>
      )}
      {location.pathname.includes(
        "/admin/reports/student-usage/view-report"
      ) && (
        <>
          <div
            id="report-header"
            className="space-y-6 border border-b-2 bg-white px-6"
          >
            <Link
              to={`${REACT_APP_ADMIN_LINK}/reports/student-usage/select-scope/${toLower(
                selectedDataScope
              )}`}
              className="align-center inline-flex py-4 font-bold"
            >
              <BackArrowIcon classes="mt-1.5" />
              <span className="pl-2 text-dm-brand-blue-500">Back</span>
            </Link>
            <h2 className="font-serif text-[24px] font-bold">
              {`Report for ${selectedDataScope} x Date`}
            </h2>
            <span className="">{`${getSelectedInfo(
              selectedDataScope
            )} ${selectedDataScope} Selected `}</span>
            <div className="flex justify-between">
              <div className="inline-flex">
                <Tooltip
                  options={{ aligned: "center", raise: "mb-10" }}
                  message="1 year maximum date range."
                >
                  <DatePicker
                    id="report-range"
                    selected={startDate}
                    onChange={(dates) => {
                      if (dates) {
                        const [start, end] = dates;
                        if (
                          end !== null &&
                          start !== null &&
                          end.getTime() - start.getTime() > ONE_YEAR
                        ) {
                          setDateRange("custom");
                        } else {
                          setStartDate(start);
                          setEndDate(end);
                          setDateRange("custom");
                        }
                      }
                    }}
                    onChangeRaw={(event) => {
                      const userEnteredDate = event.target.value;
                      if (typeof userEnteredDate !== "undefined") {
                        const datesArray = userEnteredDate.split(" - ");
                        if (datesArray.length === 2) {
                          const startDate = parse(
                            datesArray[0],
                            "MM/dd/yyyy",
                            new Date()
                          );
                          const endDate = parse(
                            datesArray[1],
                            "MM/dd/yyyy",
                            new Date()
                          );
                          if (
                            isValid(startDate) &&
                            isValid(endDate) &&
                            endDate.getTime() - startDate.getTime() > ONE_YEAR
                          ) {
                            setEndDate(startDate);
                          } else {
                            if (isValid(startDate)) {
                              setStartDate(startDate);
                              setDateRange("custom");
                            }
                            if (isValid(endDate)) {
                              setEndDate(endDate);
                              setDateRange("custom");
                            }
                          }
                        }
                      }
                    }}
                    startDate={startDate}
                    endDate={endDate}
                    maxDate={yesterday}
                    selectsRange
                    nextMonthButtonLabel=">"
                    previousMonthButtonLabel="<"
                    className="!w-48"
                    popperPlacement="bottom"
                    popperModifiers={[
                      {
                        name: "flip",
                        options: {
                          flipVariations: false,
                          allowedAutoPlacements: ["bottom", "bottom-start"],
                          fallbackPlacements: ["bottom", "bottom-start"],
                        },
                      },
                      {
                        name: "preventOverflow",
                        enabled: false,
                      },
                      {
                        name: "hide",
                        enabled: false,
                      },
                    ]}
                  />
                </Tooltip>
                <div className="ml-4 !w-36">
                  <select
                    onChange={(event) => {
                      setDateRange(event.target.value);
                    }}
                    value={dateRange}
                    className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-dm-light-blue focus:outline-none focus:ring-1 focus:ring-dm-light-blue sm:text-sm"
                  >
                    <option value={"year-to-date"}>School YTD</option>
                    <option value={"last-week"}>Last 7 days</option>
                    <option value={"thirty-days"}>Last 30 days</option>
                    <option value={"custom"}>Custom range</option>
                  </select>
                </div>
              </div>

              {/* 
              For now we are going to automatically regenerate the report when the scope changes
              <Button
                disabled={rengenerateDisabled}
                onClick={() => {
                  queryClient.invalidateQueries("usage");
                  refetch();
                }}
                className="mr-6 justify-center rounded-md border border-transparent bg-dm-brand-blue-500 px-8 py-2 text-sm font-bold text-white shadow-sm hover:bg-dm-brand-blue-500 focus:outline-none focus:ring-2 focus:ring-dm-brand-blue-500 focus:ring-offset-2 disabled:opacity-50"
              >
                Regenerate Report
              </Button> */}
            </div>
            <ReportTabs
              compareDisabled={
                getSelectedCount(selectedDataScope) === 1 ||
                getSelectedCount(selectedDataScope) > 5
              }
            />
          </div>
          <div id="report-body" className="p-6">
            {isLoading ? (
              <DmLoadingSpinner message="Loading..." />
            ) : (
              <>
                {usageData && (
                  <DisplayGraphs
                    reportType={params.report_type || "problems-solved"}
                    selectedDataScope={selectedDataScope}
                    requestParams={requestParams}
                    startDate={startDate}
                    endDate={endDate}
                    data={usageData}
                    refetch={refetch}
                    isLoading={isLoading}
                  />
                )}
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default StudentUsageContainer;

const SelectDataScope = ({
  schoolsWithIntegral,
  selectedDataScope,
  selectedSchools,
  setSelectedSchools,
  selectedSections,
  setSelectedSections,
  selectedTeachers,
  setSelectedTeachers,
}: {
  schoolsWithIntegral: any;
  selectedDataScope: string;
  setSelectedDataScope: any;
  selectedSchools?: any;
  setSelectedSchools?: any;
  selectedSections?: any;
  setSelectedSections?: any;
  selectedTeachers?: any;
  setSelectedTeachers?: any;
}) => {
  const params = useParams();
  const [preSelectedSchools, setPreselectedSchools] =
    React.useState<Record<string, any>>(selectedSchools);
  const [preSelectedTeachers, setPreselectedTeachers] =
    React.useState<Record<string, any>>(selectedTeachers);
  const [preSelectedSections, setPreselectedSections] =
    React.useState<Record<string, any>>(selectedSections);
  const [teacherTableData, setTeacherTableData] = React.useState();
  const [sectionsTableData, setSectionsTableData] = React.useState();
  const [schoolTableData, setSchoolTableData] = React.useState<any>();
  const [schoolsCount, setSchoolsCount] = React.useState<number>(0);
  const [teacherColumns, setTeacherColumns] = React.useState<Column[]>();
  const [sectionColumns, setSectionColumns] = React.useState<Column[]>();

  function getAssignmentsCount(teacher_id: number) {
    return teacherCountsData &&
      typeof teacherCountsData[teacher_id]?.assignments != "undefined"
      ? formatNumber(parseInt(teacherCountsData[teacher_id]?.assignments))
      : 0;
  }

  useEffect(() => {
    if (Object.keys(selectedSchools).length > 0) {
      setPreselectedSchools(selectedSchools);
    } else if (schoolTableData) {
      const allSchools = Object.create({});
      for (let i = 0; i < schoolTableData.length; i++) {
        allSchools[i] = true;
      }
      setPreselectedSchools(allSchools);
    }
  }, [schoolTableData]);

  const { data: teacherData, isLoading: teacherDataLoading } = useDMQuery({
    path: "/admin_new/data/teacher",
    queryOptions: {
      staleTime: 1000 * 60 * 15,
    },
  });
  // if there is only one teacher -> pre-select teacher in table
  useEffect(() => {
    if (Object.keys(selectedTeachers).length > 0) {
      setPreselectedTeachers(selectedTeachers);
    }
    if (teacherData && teacherData?.length === 1) {
      setPreselectedTeachers({ 0: true });
    }
  }, [teacherData, selectedTeachers]);

  const { data: teacherCountsData, isLoading: teacherCountsDataLoading } =
    useDMQuery({
      path: "/admin_new/data/teacherCounts",
      queryOptions: {
        staleTime: 1000 * 60 * 15,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
      },
    });

  useEffect(() => {
    const newTeachData: any = [];
    if (Array.isArray(teacherData) && Array.isArray(schoolsWithIntegral)) {
      teacherData.forEach((teacher: any) => {
        const school = schoolsWithIntegral.find(
          (school) =>
            // not all schoolids are in the 0th index of schoolPlus[], so get the first id that is not a district id
            teacher.schoolPlus.find((id: string) => id.length > 7) ===
            school.schoolid
        );
        // only show integral
        if (school) {
          newTeachData.push({
            first: demoString({ value: teacher.first, type: "person_first" }),
            last: demoString({ value: teacher.last, type: "person_first" }),
            school:
              school &&
              demoString({ value: school.schoolName, type: "school_name" }),
            assignments: getAssignmentsCount(teacher._id),
          });
        }
      });
    }
    setTeacherTableData(newTeachData);
  }, [teacherData, schoolsWithIntegral, teacherCountsData]);

  useEffect(() => {
    if (
      schoolsWithIntegral &&
      Array.isArray(schoolsWithIntegral) &&
      teacherCountsData
    ) {
      const theSchoolCounts:
        | ((prevState: never[]) => never[])
        | {
            schoolid: string;
            schoolName: string;
            lowGrade: string;
            highGrade: string;
            theSchoolStudentsCount: number;
          }[] = [];
      schoolsWithIntegral.map(
        (school: {
          schoolid: string;
          schoolName: string;
          lowGrade: string;
          highGrade: string;
          dmLicense: { has_integral: any };
        }) => {
          let theSchoolStudentsCount = 0;
          if (Array.isArray(teacherData) && teacherData.length > 0) {
            teacherData.map(
              (teacher: {
                schoolid: string;
                schoolPlus: string[];
                _id: string;
                admin: string;
                annual_logs: number;
              }) => {
                if (teacher.schoolPlus.includes(school.schoolid)) {
                  const studentCount = getStudentCount(parseInt(teacher._id));
                  theSchoolStudentsCount += studentCount;
                }
              }
            );
          }
          theSchoolCounts.push({
            schoolid: school.schoolid,
            schoolName: demoString({
              value: school.schoolName,
              type: "school_name",
            }),
            lowGrade: school.lowGrade,
            highGrade: school.highGrade,
            theSchoolStudentsCount,
          });
        }
      );
      setSchoolsCount(theSchoolCounts.length);
      setSchoolTableData(theSchoolCounts);
    }
  }, [schoolsWithIntegral, teacherData, teacherCountsData]);

  function getStudentCount(teacher_id: number) {
    return teacherCountsData &&
      teacherCountsData[teacher_id] &&
      typeof teacherCountsData[teacher_id].students != "undefined"
      ? parseInt(teacherCountsData[teacher_id].students)
      : 0;
  }

  const { data: sectionsData, isLoading: sectionsDataLoading } = useDMQuery({
    path: "/admin_new/data/sections",
    queryOptions: {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 15,
    },
  });

  // if there is only one section -> pre-select section in table
  useEffect(() => {
    if (Object.keys(selectedSections).length > 0) {
      setPreselectedSections(selectedSections);
    } else if (sectionsData) {
      if (sectionsData.length === 1) {
        setPreselectedSections({ 0: true });
      } else setPreselectedSections({});
    }
  }, [sectionsData, selectedSections]);

  useEffect(() => {
    const newSectionsData: any = [];
    if (Array.isArray(sectionsData) && Array.isArray(schoolsWithIntegral)) {
      sectionsData.map((section: any) => {
        const school = schoolsWithIntegral.find(
          (school) =>
            // not all schoolids are in the 0th index of schoolPlus[], so get the first id that is not a district id
            section.schools.find((id: string) => id.length > 7) ===
            school.schoolid
        );

        const teacherArray: string[] = [];
        section.teachers.length &&
          section.teachers.forEach((teacher: { _id: number; name: string }) => {
            if (!teacherArray.includes(teacher.name)) {
              teacherArray.push(teacher.name);
            }
          });

        // mostly a check in case there is no "logsPerYear" value, which would throw an error when we access it
        const yearsWeHaveLogs =
          section["logsPerYear"] && Object.keys(section["logsPerYear"]);
        const totalLogs = 0;
        if (school) {
          newSectionsData.push({
            sectionName: demoString({
              value: section.sectionName,
              type: "section_name",
            }),
            teachers:
              teacherArray.length > 0
                ? teacherArray
                    .map((teacher: string) =>
                      demoString({ value: teacher, type: "person_full" })
                    )
                    .join(", ")
                : "-",
            school:
              school &&
              demoString({ value: school.schoolName, type: "school_name" }),
            // aggregate all logs from all years
            logs: Array.isArray(yearsWeHaveLogs)
              ? yearsWeHaveLogs.reduce(
                  (acc: number, year: any) => acc + section.logsPerYear[year],
                  totalLogs
                )
              : 0,
          });
        }
        setSectionsTableData(newSectionsData);
      });
      setSectionsTableData(newSectionsData);
    }
  }, [sectionsData, schoolsWithIntegral]);

  useEffect(() => {
    if (Array.isArray(schoolTableData)) {
      for (let i = 0; i < schoolTableData?.length; i++) {
        schoolTableData[i].schoolName = demoString({
          value: schoolTableData[i].schoolName,
          type: "school_name",
        });
      }
      setSchoolTableData(schoolTableData);
    }
  }, [schoolTableData]);

  /** initialize user preference*/
  const temp = {
    usagePreferences: {
      usageReportDataScope: {
        Schools: {},
        Teachers: {},
        Sections: {},
      },
    },
  };
  let tempPreferences = JSON.parse(
    localStorage.getItem("usagePreferences") || "{}"
  );
  Object.hasOwn(tempPreferences, "fetchPushed") === false
    ? (tempPreferences = temp)
    : null;
  useEffect(() => {
    if (selectedDataScope === "Schools") {
      temp.usagePreferences.usageReportDataScope = {
        Schools: {
          ...selectedSchools,
        },
        Teachers: {},
        Sections: {},
      };
    } else if (selectedDataScope === "Teachers") {
      temp.usagePreferences.usageReportDataScope = {
        Schools: {},
        Teachers: { ...selectedTeachers },
        Sections: {},
      };
    } else if (selectedDataScope === "Sections") {
      temp.usagePreferences.usageReportDataScope = {
        Schools: {},
        Teachers: {},
        Sections: { ...selectedSections },
      };
    }
    tempPreferences = temp;

    /** update localStorage.usagePreferences.usageReportDataScope when a new selection is made */
    localStorage.setItem(
      "usagePreferences",
      JSON.stringify({
        ...tempPreferences,
      })
    );
  }, [selectedSchools, selectedSections, selectedTeachers]);

  const schoolColumns = React.useMemo(
    () => [
      {
        Header: "School Name",
        accessor: "schoolName",
        Filter: TextFilter,
        align: "left",
      },
      {
        Header: "Grade Range",
        accessor: "lowGrade",
        Cell: (props: any) => {
          return `${props.row.original.lowGrade} - ${props.row.original.highGrade}`;
        },
        align: "center",
        sortType: customGradeSort,
      },
      {
        Header: "",
        accessor: "highGrade",
      },
      {
        Header: "# of Students",
        accessor: "theSchoolStudentsCount",
        align: "center",
        sortDescFirst: true,
      },
    ],
    []
  );

  useEffect(() => {
    const teacherColumnsDef: Array<any> = [
      {
        Header: "First Name",
        accessor: "first",
        Filter: TextFilter,
        align: "left",
      },
      {
        Header: "Last Name",
        accessor: "last",
        Filter: TextFilter,
        align: "left",
      },
      {
        Header: "# of Assignments",
        accessor: "assignments",
        Filter: NoFilter,
        align: "left",
        sortDescFirst: true,
      },
    ];

    const sectionColumnsDef: Array<any> = [
      {
        Header: "Section Name",
        accessor: "sectionName",
        Filter: TextFilter,
        align: "left",
      },
      {
        Header: "Teachers",
        accessor: "teachers",
        Filter: TextFilter,
        align: "left",
        Cell: (props: { value: string }) => (
          <CondensedCell value={props.value} />
        ),
      },
      {
        Header: "Problems Solved",
        Cell: (props: any) => {
          return <div>{formatNumber(props.value)}</div>;
        },
        accessor: "logs",
        Filter: NoFilter,
        align: "left",
        sortDescFirst: true,
      },
    ];
    if (schoolsCount > 1) {
      // only show the column if there's more than 1 school
      teacherColumnsDef.splice(2, 0, {
        Header: "School",
        accessor: "school",
        Filter: MultiCheckFilter,
        filter: "includesSome",
        align: "left",
        Cell: (props: any) => {
          return (
            <DemoMode
              value={props.value}
              type="school_name"
              district_size={schoolsCount}
            />
          );
        },
        minWidth: 300,
      });
      sectionColumnsDef.splice(2, 0, {
        Header: "School",
        accessor: "school",
        Filter: MultiCheckFilter,
        filter: "includesSome",
        align: "left",
        minWidth: 300,
      });
    }
    setTeacherColumns(teacherColumnsDef);
    setSectionColumns(sectionColumnsDef);
  }, [schoolsCount]);

  return (
    <div>
      {/* <nav className={"grid grid-cols-3 space-x-4 pb-2"} aria-label="Tabs">
          {tabs.map(
            (tab) =>
              tab.roles &&
              tab.roles.length > 0 &&
              tab?.roles.includes(adminParams.account_type) && (
                <button
                  onClick={() => setSelectedDataScope(tab.name)}
                  key={tab.name}
                  className={clsx(
                    tab.name === selectedDataScope
                      ? "border-dm-light-blue bg-dm-blue text-white"
                      : "border text-gray-500 hover:border-gray-300 hover:text-dm-blue",
                    "col-span-1 border-b-2 px-1 py-4 text-center text-sm font-medium"
                  )}
                >
                  {tab.name}
                </button>
              )
          )}
        </nav> */}

      {(teacherDataLoading ||
        teacherCountsDataLoading ||
        sectionsDataLoading) && <DmLoadingSpinner message={"Loading..."} />}

      {params.page === "schools" && schoolColumns && (
        <>
          {schoolsWithIntegral &&
            teacherData &&
            teacherCountsData &&
            sectionsData &&
            Array.isArray(schoolTableData) &&
            schoolTableData.length > 0 && (
              <div>
                <Transition
                  as="div"
                  show={true}
                  appear={true}
                  enter="transition-opacity duration-300"
                  enterFrom="transform opacity-0"
                  enterTo="transform opacity-100"
                  leave="transition-opacity duration-300"
                  leaveFrom="transform opacity-100"
                  leaveTo="transform opacity-0"
                >
                  <DeltaMathTable
                    columns={schoolColumns}
                    data={schoolTableData}
                    options={{
                      selectable: true,
                      showSelectAll: true,
                      preSelectedRows: preSelectedSchools,
                      initialState: {
                        hiddenColumns: ["highGrade"],
                        sortBy: [
                          {
                            id: "schoolName",
                            desc: false,
                          },
                        ],
                      },
                    }}
                    setSelectedRows={setSelectedSchools}
                  />
                </Transition>
              </div>
            )}
        </>
      )}
      {params.page === "teachers" &&
        teacherColumns &&
        teacherCountsData &&
        selectedTeachers &&
        preSelectedTeachers && (
          <>
            {teacherTableData && (
              <Transition
                as="div"
                show={true}
                appear={true}
                enter="transition-opacity duration-300"
                enterFrom="transform opacity-0"
                enterTo="transform opacity-100"
                leave="transition-opacity duration-300"
                leaveFrom="transform opacity-100"
                leaveTo="transform opacity-0"
              >
                <DeltaMathTable
                  columns={teacherColumns}
                  data={teacherTableData}
                  options={{
                    selectable: true,
                    showSelectAll: true,
                    preSelectedRows: preSelectedTeachers,
                  }}
                  setSelectedRows={setSelectedTeachers}
                />
              </Transition>
            )}
          </>
        )}
      {params.page === "sections" && (
        <>
          {sectionsTableData && sectionColumns && (
            <Transition
              as="div"
              show={true}
              appear={true}
              enter="transition-opacity duration-300"
              enterFrom="transform opacity-0"
              enterTo="transform opacity-100"
              leave="transition-opacity duration-300"
              leaveFrom="transform opacity-100"
              leaveTo="transform opacity-0"
            >
              <DeltaMathTable
                columns={sectionColumns}
                data={sectionsTableData}
                options={{
                  selectable: true,
                  showSelectAll: true,
                  preSelectedRows: preSelectedSections,
                }}
                setSelectedRows={setSelectedSections}
              />
            </Transition>
          )}
        </>
      )}
    </div>
  );
};
