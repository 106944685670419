import { Skill } from "./SkillCodes";

type Props = {
  k: string;
  skill: Skill;
  skillAdd?: (name: string, skillCode: string) => void;
  openExampleModal?: (skillCode?: string) => void;
};

const SkillDisplay = (props: Props) => {
  return (
    <div key={`${props.k}`} className="ml-3 flex items-center gap-4">
      {!props.skill.timed && (
        <i
          className="far fa-plus-circle cursor-pointer text-2xl text-dm-brand-blue-500"
          onClick={() => {
            if (props.skillAdd && props.skill.__index) {
              props.skillAdd(props.skill.name ?? "", props.skill.__index);
            }
          }}
        />
      )}
      <p
        className={`${
          props.skill.timed ? "cursor-not-allowed" : "cursor-pointer"
        } text-base`}
        onClick={() => {
          if (
            !props.skill.timed &&
            props.skill.__index &&
            props.openExampleModal
          ) {
            props.openExampleModal(props.skill.__index);
          }
        }}
      >
        {props.skill.timed && <i className="far fa-clock mr-2" />}
        {props.skill.name}
      </p>
    </div>
  );
};

export default SkillDisplay;
