const PeopleIcon = ({ classes }: { classes?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={classes}
    >
      <path
        d="M16.5 19.4H21V17.6C21 16.1088 19.7912 14.9 18.3 14.9C17.44 14.9 16.6738 15.3021 16.1794 15.9286M16.5 19.4H7.5M16.5 19.4V17.6C16.5 17.0094 16.3862 16.4453 16.1794 15.9286M16.1794 15.9286C15.5159 14.2709 13.8947 13.1 12 13.1C10.1053 13.1 8.48415 14.2709 7.82062 15.9286M7.5 19.4H3V17.6C3 16.1088 4.20883 14.9 5.7 14.9C6.56005 14.9 7.32617 15.3021 7.82062 15.9286M7.5 19.4V17.6C7.5 17.0094 7.61378 16.4453 7.82062 15.9286M14.7 7.7C14.7 9.19117 13.4912 10.4 12 10.4C10.5088 10.4 9.3 9.19117 9.3 7.7C9.3 6.20884 10.5088 5 12 5C13.4912 5 14.7 6.20884 14.7 7.7ZM20.1 10.4C20.1 11.3941 19.2941 12.2 18.3 12.2C17.3059 12.2 16.5 11.3941 16.5 10.4C16.5 9.40589 17.3059 8.6 18.3 8.6C19.2941 8.6 20.1 9.40589 20.1 10.4ZM7.5 10.4C7.5 11.3941 6.69411 12.2 5.7 12.2C4.70589 12.2 3.9 11.3941 3.9 10.4C3.9 9.40589 4.70589 8.6 5.7 8.6C6.69411 8.6 7.5 9.40589 7.5 10.4Z"
        stroke="#9CA3AF"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PeopleIcon;
