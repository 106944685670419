import { useEffect, useRef } from "react";
import { useTourContext } from "../../contexts/TourContext";
import { Course } from "../../types";
import { CourseCard } from "./CourseCard";
import { CoursesHeader } from "./CoursesHeader";
import TourCardWrapper from "../Tour/TourCardWrapper";

export const CoursesLanding: React.FC<{ courses: Course[] }> = ({
  courses,
}) => {
  return (
    <>
      <CoursesHeader />
      <TourCardWrapper
        step={5}
        tourType="learner"
        classNames="relative grid grid-cols-1 content-stretch justify-stretch gap-4 md:grid-cols-2"
        allowClick
        forceTop
      >
        {courses.map((course) => (
          <CourseCard key={course.id} course={course} />
        ))}
      </TourCardWrapper>
    </>
  );
};
