import { getFilePath } from "../../utils";
const { $ } = window;

// TODO: consider using resolveConfig to get access to the custom tailwind colors
/**
 * DeltaMath colors for game scripts
 * */
export const colors = {
  "dm-brand-blue-100": "#DBEAFE",
  "dm-brand-blue-200": "#A9CCFA",
  "dm-brand-blue-500": "#2F52E9",
  "dm-brand-blue-600": "#263FA9",
  "dm-brand-blue-800": "#012758",
  "alt-light-blue": "#97AAFA",
  "alt-dark-blue": "#6B7DC6",
  "error-500": "#D21527",
  "success-500": "#078445",
  white: "#FFFFFF",
};

/**
 * possible starting numbers for collatz - they have between 6 and 20 steps, inclusive
 */
export const collatzStartingNums = [
  3, 6, 7, 9, 10, 11, 12, 13, 14, 15, 17, 18, 19, 20, 21, 22, 23, 24, 26, 28,
  29, 30, 34, 35, 40, 42, 44, 45, 46, 48, 52, 53, 56, 58, 60, 61, 64, 68, 69,
  70, 75, 80, 84, 85, 88, 90, 92, 93, 96,
];

/** Adds a linear gradient to the graph with the given ID
 * @param graph DeltaGraph instance
 * @param id string of id for gradient
 * @param startColor string of color at the top
 * @param stopColor string of the color at the bottom
 */
export function addLinearGradient(graph, id, startColor, stopColor) {
  const defs = graph.svg.append("defs");
  const linearGradient = defs.append("linearGradient").attr({
    id,
    gradientTransform: "rotate(90)",
  });

  linearGradient.append("stop").attr({
    offset: "0",
    "stop-color": startColor,
  });

  linearGradient.append("stop").attr({
    offset: "1",
    "stop-color": stopColor,
  });
}

/** Adds game over text and button
 * @param graph DeltaGraph instance
 * @param buttonHeight height of button in terms of the graph
 * @return an object with the group ref, overlay (rect) ref and button ref
 */
export function addLoseGameText(graph) {
  const titleHtml = `<h2>
    Game Over
  </h2>`;

  const textHtml = `<h2>
    Try Again!
  </h2>`;

  const textContainer = `<div class="font-serif text-white text-xl text-center font-bold flex flex-col gap-2">
    ${titleHtml}
    ${textHtml}
  </div>`;

  const buttonHtml = `<button id="startGame" class="font-sans text-sm font-bold bg-white w-full border rounded border-dm-charcoal-800 h-10">
    Start Game
  </button>`;

  const outerDivHtml = `<div class="flex flex-col gap-6 items-center max-w-xs">
    ${textContainer}
    ${buttonHtml}
  </div>`;

  const htmlRef = graph.html(
    graph.invx((500 - 320) / 2), // width of graph minus width of div
    (graph.bounds.ymin + graph.bounds.ymax) * 0.65,
    outerDivHtml
  );

  const button = $("#startGame");

  return { htmlRef, button };
}

/** Adds game won text
 * @param graph DeltaGraph instance
 */
export function addWinGameText(graph) {
  const { ymin, ymax } = graph.bounds;

  // html for image container
  const imgHtml = `<img
    src= ${getFilePath("/images/learner/signup/deltie-thumbs-up.png")}
    alt= "Deltie smiling and giving a thumbs up"
    class= "w-[125px]"
  ></img>`;

  const pointsPlusOneHtml = `<div class="bg-dm-charcoal-800 rounded px-2 py-0.5 text-white font-sans flex flex-row gap-2 justify-center items-center max-w-20 absolute -top-4 -right-6">
    <i class="far fa-stars text-xl"></i>
    <div>+1 pt</div>
  </div>`;

  const imgContainer = `<div class="relative w-[125px]">
    ${imgHtml}
    ${pointsPlusOneHtml}
  </div>`;

  // html for text container
  const titleHtml = `<h2 class="font-serif text-2xl font-bold">You're a Math Mastermind! 🧠</h2>`;

  const pHtml = `<p class="font-sans text-base text-wrap">
    Return tomorrow for another chance to win! 
  </p>`;

  const textContainer = `<div class="flex flex-col gap-2 content-center text-dm-charcoal-800 text-center">
    ${titleHtml}
    ${pHtml}
  </div>`;

  // html to add
  const container = `<div class="flex flex-col gap-6 items-center max-w-xs">
    ${imgContainer}
    ${textContainer}
  </div>`;

  graph.html(
    graph.invx((500 - 320) / 2), // width of graph minus width of div
    (ymin + ymax) * 0.75,
    container
  );
}
