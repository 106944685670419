import axios from "axios";
import { useMutation } from "react-query";
import { Learner, PascalsTriangleRow, PrizeKey } from "../types";
import { deltamathAPI } from "../../manager/utils";
import { useDeltaToastContext } from "../../shared/contexts/ToasterContext";
import { useLearnerContext } from "../contexts/LearnerContext";
import { useLearnerAnalytics } from "../analytics/useLearnerAnalytics";
import { enterPointsTriangleEvent } from "../analytics/events";

interface PascalsTriangleInput {
  newRow: PascalsTriangleRow;
  nextPrize: PrizeKey | undefined;
}

interface PascalsTriangleResponse {
  learner: Learner;
}

/** Mutation for updating a learner's Pascal's Triangle */
export function usePascalsTriangleMutation() {
  const toastContext = useDeltaToastContext();
  const learnerContext = useLearnerContext();
  const { track } = useLearnerAnalytics();

  const { mutateAsync } = useMutation(
    (body: PascalsTriangleInput) => {
      return axios.post<PascalsTriangleResponse>(
        `${deltamathAPI()}/learner/data/pascals-triangle`,
        JSON.stringify(body),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    },
    {
      onSuccess(data) {
        const { pointsUsed, pascalsRow, prizes } = data.data.learner;
        const pointsEarnedTotal = learnerContext.learner?.pointsEarned ?? -1;
        const pointsRemaining = pointsEarnedTotal - pointsUsed;
        const pointsRedeemed =
          (learnerContext.pointsAvailable ?? 0) - pointsRemaining;
        track(
          enterPointsTriangleEvent({
            pointsEarnedTotal,
            pointsRemaining,
            pointsRedeemed,
          })
        );

        learnerContext.updateLearner({
          pointsUsed,
          pascalsRow,
          prizes,
        });
      },
      onError() {
        toastContext.addToast({
          title: "Error",
          message: "There was an error updating Pascal's Triangle",
          status: "Error",
        });
      },
    }
  );

  return { mutate: mutateAsync };
}
