import Modal from "../../../student/components/generic/Modal";
import { FeedbackForm } from "./FeedbackForm";

export const GiveUsFeedbackModal: React.FC<{
  showModal: boolean;
  onClose: () => void;
}> = ({ showModal, onClose }) => {
  return (
    <Modal
      visible={showModal}
      onClose={onClose}
      closeX
      bodyClassName="!px-4 pb-6 !pt-12 sm:!px-10 sm:pb-8"
      title={
        <div className="flex flex-col items-center justify-center gap-2 font-serif">
          <div className="text-lg font-bold text-dm-brand-blue-600 sm:text-2xl">
            Give Us Feedback
          </div>
          <p className="text-base font-bold text-dm-charcoal-800 sm:text-lg">
            Help us improve our product for you
          </p>
        </div>
      }
      body={
        <>
          <p className="mb-6 text-center font-sans text-base">
            How would you describe your experience with the app so far?
          </p>
          <FeedbackForm onClose={onClose} />
        </>
      }
    />
  );
};
