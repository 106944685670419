import { useQuery } from "react-query";
import { executeQuery } from "../../utils";
import { useCallback } from "react";

type CheckEmailResult = { success: boolean; message?: string };

/** Hook to check for duplicate email or username during signup */
export function useCheckEmail(
  email: string,
  checkType: "email" | "username" = "email"
): {
  checkEmail: () => Promise<CheckEmailResult>;
  isLoading: boolean;
} {
  const { refetch: fetchEmailAvailability, isLoading } = useQuery<{
    available: boolean;
  }>(
    ["usernameCheck", email],
    () =>
      executeQuery({
        path: `/learner/beta/username_check/${email}`,
      }),
    {
      enabled: false,
      refetchOnWindowFocus: false,
    }
  );

  const checkEmail: () => Promise<CheckEmailResult> = useCallback(async () => {
    // Ignore blank emails
    if (email.trim().length === 0) {
      return { success: true };
    }

    if (checkType === "email" && !email.includes("@")) {
      return { success: false, message: "Please enter a valid email address" };
    }

    const response = await fetchEmailAvailability();

    // Ignore if the request is idle
    if (response.status === "idle") {
      return { success: true };
    }

    if (!response?.data) {
      return {
        success: false,
        message:
          checkType === "email"
            ? "There was an error while checking your email"
            : "There was an error while checking your username",
      };
    }

    if (!response.data.available) {
      return {
        success: false,
        message:
          checkType === "email"
            ? "There is already an account with this email address"
            : "There is already an account with this username",
      };
    } else {
      return { success: true };
    }
  }, [checkType, email, fetchEmailAvailability]);

  return { checkEmail, isLoading };
}
