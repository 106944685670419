import { NavLink } from "react-router-dom";
import { REACT_APP_LEARNER_LINK } from "../utils";

export const NotFoundPage: React.FC = () => (
  <main className="flex h-dvh w-full flex-col items-center justify-center gap-2">
    <h1 className="bold font-serif text-3xl text-dm-brand-blue-600">
      That page doesn&apos;t exist!
    </h1>
    <p>
      <NavLink to={`${REACT_APP_LEARNER_LINK}/dashboard`}>
        Go to your dashboard
      </NavLink>
    </p>
  </main>
);
