import { useState, Fragment, useEffect } from "react";
import PortalModal from "../../../shared/PortalModal";
import { useCourseContext } from "../../contexts/CourseContext";
import ProgressRadial from "../ProgressRadial";
import { myCourseDataType, Progress, myCoursesUnitDataType } from "../../types";
import CourseProgressUnitSummaryCard from "../Parent/CourseProgressUnitSummaryCard";
import CourseProgressTestCard from "../Parent/CourseProgressTestCard";
import { useLearnerAnalytics } from "../../analytics/useLearnerAnalytics";
import { parentViewCourseProgressDetailsEvent } from "../../analytics/events";

const UNIT_RADIAL_LEGEND = (
  <div className="mb-8 flex flex-wrap gap-y-1 overflow-hidden transition-all sm:gap-x-1 md:gap-x-3 lg:flex-nowrap xl:gap-x-7">
    <div className="flex basis-1/2 items-center gap-x-1 text-xs leading-none sm:basis-1/3 md:basis-auto">
      <i
        className="far fa-check fa-fw text-xl leading-none !text-dm-brand-blue-500"
        aria-hidden="true"
      ></i>
      <span className="text-dm-gray-800">Unit Complete</span>
    </div>
    <div className="flex basis-1/2 items-center gap-x-1 text-xs leading-none sm:basis-1/3 md:basis-auto">
      <i
        className="fas fa-square fa-fw text-xl leading-none !text-dm-brand-blue-500"
        aria-hidden="true"
      ></i>
      <span className="text-dm-gray-800">Section Complete</span>
    </div>
    <div className="flex basis-1/2 items-center gap-x-1 text-xs leading-none sm:basis-1/3 md:basis-auto">
      <i
        className="fas fa-square fa-fw text-xl leading-none !text-dm-brand-blue-200"
        aria-hidden="true"
      ></i>
      <span className="text-dm-gray-800">Section In Progress</span>
    </div>
    <div className="flex basis-1/2 items-center gap-x-1 text-xs leading-none sm:basis-1/3 md:basis-auto">
      <i
        className="fas fa-square fa-fw text-xl leading-none !text-dm-charcoal-100"
        aria-hidden="true"
      ></i>
      <span className="text-dm-gray-800">Not Started</span>
    </div>
    <div className="flex basis-1/2 items-center gap-x-1 text-xs leading-none sm:basis-1/3 md:basis-auto">
      <i
        className="far fa-list fa-fw text-xl leading-none !text-dm-gray-200"
        aria-hidden="true"
      ></i>
      <span className="text-dm-gray-800">Course Test</span>
    </div>
  </div>
);

type Props = {
  visible: boolean;
  onClose: () => void;
  courseProgress: Progress;
  currentCourseData: myCourseDataType | undefined;
  header: JSX.Element;
};

export const CourseProgressModal: React.FC<Props> = (props: Props) => {
  const courseContext = useCourseContext();
  const [lastClickedId, setLastClickedId] = useState("");
  const { track } = useLearnerAnalytics();

  const courseData = courseContext.getCourseData(props.courseProgress.courseId);

  const closeModal = () => {
    props.onClose();
  };

  const estimatedTime = Math.max(
    5,
    Math.ceil((courseData?.avgQuizTime || 0) / 60 / 5) * 5
  );

  useEffect(() => {
    if (props.visible) {
      track(parentViewCourseProgressDetailsEvent());
    }
  }, [props.visible, track]);

  const isCurrentTestSubmitted =
    props.courseProgress?.courseTest !== undefined &&
    props.courseProgress?.courseTest?.assignment?.submitted !== undefined;
  const isFirstAttemptComplete =
    isCurrentTestSubmitted ||
    !!props.courseProgress?.courseTest?.assignment?.resultHist;

  if (!courseData) {
    return null;
  }

  return (
    <PortalModal
      visible={props.visible}
      onClose={closeModal}
      noLine
      largeModal
      title={
        <div className="text-center font-serif text-xl font-bold sm:text-2xl">
          {props.currentCourseData?.name || "Course"} Progress Details
        </div>
      }
      body={
        <section>
          <div className="mb-4 mt-6 flex items-center gap-x-4 text-sm font-bold">
            {props.header}
          </div>
          <h2 className="font-serif text-lg font-bold">Units</h2>
          <div className="flex gap-x-4 overflow-auto pb-1.5">
            {props.currentCourseData?.units?.map(
              (unit: myCoursesUnitDataType, ix: number) => {
                return (
                  <ProgressRadial
                    key={`unitProgress-${unit.id}-${ix}`}
                    progressObject={unit.subunits}
                    name={unit.name}
                    id={unit.id}
                    progress={unit.progress}
                    index={ix}
                    setLastClickedId={setLastClickedId}
                  />
                );
              }
            )}
          </div>
          {UNIT_RADIAL_LEGEND}
          {courseData?.unitOrder.map((u, i) => {
            const unit = (courseData.units ?? []).find((unit) => unit.id === u);
            if (!unit || unit?.subunitOrder.length <= 0) {
              return null;
            }

            return (
              <Fragment key={`unitcardwrapper-${unit.id}-${i}`}>
                <hr className="mb-6 flex-grow border-t border-dm-charcoal-100" />
                <CourseProgressUnitSummaryCard
                  unit={unit}
                  course={courseData}
                  key={`unitcard-${unit.id}`}
                  cardId={`panel-${unit.id}`}
                  unitNumber={i + 1}
                  lastClickedId={lastClickedId}
                />
              </Fragment>
            );
          })}
          <CourseProgressTestCard
            courseData={courseData}
            progress={props.courseProgress}
            estimatedTime={estimatedTime}
            isFirstAttemptComplete={isFirstAttemptComplete}
          />
        </section>
      }
    />
  );
};
