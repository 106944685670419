import { useEffect } from "react";
import { ConfigPrizeModalProps, LoadingIconKey } from "../../../types";
import { useLearnerContext } from "../../../contexts/LearnerContext";
import clsx from "clsx";
import { LoadingIcon } from "../../LoadingIcon";

const ICON_KEYS: LoadingIconKey[] = ["disco", "idea", "skateboard"];

const ImageChoice: React.FC<{
  choice: LoadingIconKey;
  activeChoice: string | undefined;
  onChange: () => void;
}> = ({ choice, activeChoice, onChange }) => (
  // Label content is in the LoadingIcon component and that confuses eslint
  // eslint-disable-next-line jsx-a11y/label-has-associated-control
  <label
    htmlFor={choice}
    className={clsx(
      "group rounded-lg border-2 p-2 md:px-6 md:py-4",
      activeChoice === choice
        ? "border-dm-brand-blue-600"
        : "cursor-pointer border-dm-charcoal-100 hover:border-dm-charcoal-200"
    )}
  >
    <input
      className="sr-only"
      type="radio"
      id={choice}
      name="image"
      checked={activeChoice === choice}
      onChange={onChange}
    />
    <div className="w-[118px]">
      <div className="hidden group-hover:block">
        <LoadingIcon icon={choice} />
      </div>
      <div className="block group-hover:hidden">
        <LoadingIcon icon={choice} staticImg />
      </div>
    </div>
  </label>
);

export const LoadingIconConfig: React.FC<ConfigPrizeModalProps> = ({
  config,
  setConfig,
  setTitle,
  setConfirmButtonText,
  setConfirmButtonDisabled,
}) => {
  const { learner } = useLearnerContext();

  useEffect(() => {
    setConfig({
      image: undefined,
      ...(learner?.prizesState?.loadingIcon ?? {}),
    });
    setTitle("Customize Loading Animation");
    setConfirmButtonText("Customize Animation");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [learner?.prizesState]);

  useEffect(() => {
    setConfirmButtonDisabled(config.image === undefined);
  }, [config.image, setConfirmButtonDisabled]);

  if (Object.keys(config).length === 0) {
    return null;
  }

  return (
    <form
      className="flex flex-col gap-6 px-1"
      onSubmit={(e) => e.preventDefault()}
    >
      <fieldset className="flex flex-col items-center justify-between gap-4 md:flex-row md:gap-2">
        {ICON_KEYS.map((choice) => (
          <ImageChoice
            key={choice}
            choice={choice}
            activeChoice={config.image}
            onChange={() => setConfig({ ...config, image: choice })}
          />
        ))}
      </fieldset>

      <div>
        <label
          htmlFor="noImage"
          className="inline-flex items-center gap-2 text-sm text-dm-charcoal-600"
        >
          <input
            type="checkbox"
            id="noImage"
            name="noImage"
            checked={config.image === "default"}
            onChange={(e) => {
              setConfig({
                ...config,
                image: config.image === "default" ? undefined : "default",
              });
            }}
          />
          No thank you, no loading animation for me
        </label>
      </div>
    </form>
  );
};
