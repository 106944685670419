import { LearnerAssignmentTypes } from "../../types";
import SubunitSkillsNav from "./subunit/SubunitSkillsNav";
import { getReadableAssignmentType } from "../../utils";

type Props = {
  type: LearnerAssignmentTypes;
};

const MobileQuestionDropdown = (props: Props) => (
  <div className="sticky top-[61px] z-[7] mb-2 border-b border-b-dm-charcoal-100">
    <SubunitSkillsNav
      title={getReadableAssignmentType(props.type)}
      type={props.type}
      isQuickDropdown={true}
    />
  </div>
);

export default MobileQuestionDropdown;
