import { useState } from "react";
import { useQuery } from "react-query";
import { executeQuery } from "../../../../utils";
import { Learner } from "../../../types";
import { ChangePasswordForm } from "../ChangePasswordForm";
import { getLearnerAccountStatus } from "../getLearnerAccountStatus";
import { ProfileField } from "../ProfileField";
import Button from "../../../../student/components/generic/button";

/**
 * CASES:
 * 1. A person changing their own password (who did not use oauth) - show current password, new password, confirm password
 * 2. A person changing their own password (who did use oauth) - show nothing
 * 3. A parent changing the password of their child learner - new password, confirm password
 * 4. A parent adding a password to their child learner - new learner password, confirm new learner password (copy changes)
 */

export const PasswordFieldContainer: React.FC<{
  user: Learner;
  isParentEditingLearner?: boolean;
  updateUserUponAddPassword?: () => void;
}> = ({ user, isParentEditingLearner = false, updateUserUponAddPassword }) => {
  const [showPasswordForm, setShowPasswordForm] = useState(false);

  const accountStatus = getLearnerAccountStatus(user);
  const noPassword = accountStatus === "pending";

  const { data, isLoading: isHasPasswordLoading } = useQuery<{
    hasPassword: boolean;
  }>(
    ["hasPassword", user._id],
    () =>
      executeQuery({
        path: `/learner/shared/password_check`,
      }),
    {
      enabled: !isParentEditingLearner,
      refetchOnWindowFocus: false,
    }
  );

  // A person editing their own account used oAuth (show nothing)
  if (!isParentEditingLearner && (!data?.hasPassword || isHasPasswordLoading))
    return <></>;

  return (
    <>
      {showPasswordForm ? (
        <ProfileField
          label={noPassword ? "Create Password" : "Change Password"}
        >
          <ChangePasswordForm
            hideForm={() => {
              setShowPasswordForm(false);
            }}
            user={user}
            showCurrentPasswordField={!isParentEditingLearner}
            updateUserUponAddPassword={updateUserUponAddPassword}
            noPassword={noPassword}
          />
        </ProfileField>
      ) : noPassword ? (
        <ProfileField label="Password">
          <div>No password set</div>
          <Button
            type="link"
            onClick={() => setShowPasswordForm(true)}
            className="!py-0 text-left"
          >
            Create Password
          </Button>
        </ProfileField>
      ) : (
        <ProfileField label="Password">
          <div>{"*".repeat(16)}</div>
          <Button
            type="link"
            onClick={() => setShowPasswordForm(true)}
            className="!py-0 text-left"
          >
            Change Password
          </Button>
        </ProfileField>
      )}
    </>
  );
};
