import { Dispatch, SetStateAction, createContext } from "react";

type AdminContextType = {
  useAdminStyles: boolean;
  setUseAdminStyles: Dispatch<SetStateAction<boolean>>;
};

const AdminFeatureContext = createContext<AdminContextType>({
  useAdminStyles: false,
  setUseAdminStyles: () => ({}),
});

AdminFeatureContext.displayName = "AdminFeatureContext";

export default AdminFeatureContext;
