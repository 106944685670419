import { useEffect, useState } from "react";
import axios from "axios";
import { useMutation } from "react-query";
import { deltamathAPI } from "../../manager/utils";
import { useDMQuery } from "../../utils";
import { getAdminParams } from "../utils";
import { useDeltaToastContext } from "../../shared/contexts/ToasterContext";
import ChangePassword from "./profile/ChangePassword";
import { DemoMode } from "../../utils/demo";
import { useUserContext } from "../../shared/contexts/UserContext";

export default function Index({
  hasIntegralAtAll,
}: {
  hasIntegralAtAll: boolean;
}) {
  const toastContext = useDeltaToastContext();
  const userContext = useUserContext();
  const token = userContext.getJWT();
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const [theUser, setTheUser] = useState<{
    admin?: string;
    _id?: string;
    googleSSOTimestamp?: any;
    defaultView?: string;
  }>({});
  const [currentUser, setCurrentUser] = useState<{
    first?: string;
    last?: string;
    email?: string;
  }>({});
  const [userCanChangePassword, setUserCanChangePassword] = useState(true);
  const [userAdminID, setUserAdminID] = useState("");
  const [userDefaultView, setUserDefaultView] = useState("");
  const [showChangePassword, setShowChangePassword] = useState(false);

  const adminParams = getAdminParams();

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    if (query.get("passwordRedirect") === "true") {
      setShowChangePassword(true);
    }
  }, []);

  const { data: credentialData, refetch: refetchCredentialData } = useDMQuery({
    path: "/admin_new/data/ltiCredentials",
    queryOptions: {
      enabled: false,
      staleTime: 1000 * 60 * 15,
      refetchOnWindowFocus: false,
    },
  });

  const { data: districtData, refetch: districtDataRefetch } = useDMQuery({
    path: "/admin_new/data/district",
    queryOptions: {
      staleTime: 1000 * 60 * 15,
      refetchOnWindowFocus: false,
      enabled: false, // We only want to make this request if we're a district admin, we check that in a useEffect below
    },
  });

  const { data: adminsData } = useDMQuery({
    path: "/admin_new/data/admins",
    params: adminParams,
    queryOptions: {
      staleTime: 1000 * 60 * 15,
      refetchOnWindowFocus: false,
    },
  });

  const { data: adminTeacherInfoData, refetch: adminTeacherInfoRefetch } =
    useDMQuery({
      path: "/admin_new/data/admin-teacher-info",
      queryOptions: {
        staleTime: 1000 * 60 * 15,
        refetchOnWindowFocus: false,
        enabled: false,
      },
    });

  useEffect(() => {
    const theUser = JSON.parse(localStorage.getItem("user") || "{}");
    setTheUser(theUser);

    if (theUser.googleSSOTimestamp) {
      setUserCanChangePassword(false);
    }
    setUserAdminID(theUser?.admin || "");
    setUserDefaultView(theUser?.defaultView || "teacher");
  }, []);

  useEffect(() => {
    if (adminTeacherInfoData) {
      localStorage.setItem("user", JSON.stringify(adminTeacherInfoData));
      setUserDefaultView(adminTeacherInfoData.defaultView);
      setTheUser(adminTeacherInfoData);
    }
  }, [adminTeacherInfoData]);

  const updateDefaultView = useMutation(
    (body: string) => {
      return axios.post(
        deltamathAPI() + "/admin_new/manage/update_default_view",
        body,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    {
      onSuccess() {
        adminTeacherInfoRefetch();
        toastContext.addToast({
          status: "Success",
          message: "Default View Updated.",
        });
      },
    }
  );

  useEffect(() => {
    if (adminsData && theUser) {
      const id = theUser?.admin ? theUser?.admin : theUser?._id;
      const findCurrentUser = adminsData.filter(
        (user: { _id: string }) => user._id === id
      );
      if (findCurrentUser && findCurrentUser.length > 0) {
        setCurrentUser(findCurrentUser[0]);
        districtDataRefetch();
      }
    }
  }, [adminsData, theUser]);

  useEffect(() => {
    // check if user CAN have integrations before requesting integration info
    if (
      (districtData?.dmLicense?.has_integral ||
        user?.deltamath_integral_active) &&
      (districtData?.dmLicense?.integrations || hasIntegralAtAll)
    ) {
      refetchCredentialData();
    }
  }, [districtData, hasIntegralAtAll]);

  const viewOptions = [
    { id: "admin", title: "Admin" },
    { id: "teacher", title: "Teacher" },
  ];

  return (
    <>
      <div id="header" className="border border-b-2 bg-white">
        <h2 className="px-6 py-8 font-serif text-[24px] font-bold">
          My Account Details
        </h2>
      </div>
      <div id="body" className="bg-dm-background-blue-100 p-6">
        <div className="space-y-4 rounded-md border bg-white p-6">
          <div className="text-[16px] font-bold">User Details</div>
          <dl className="space-y-4 divide-y divide-gray-200">
            <div className="space-y-4">
              <div>
                <dt className="text-[14px] font-bold">Name</dt>
                <dd className="">
                  <DemoMode
                    value={`${currentUser.first} ${currentUser.last}`}
                    type="person_full"
                  />
                </dd>
              </div>
              <div>
                <dt className="text-[14px] font-bold">Email</dt>
                <dd className="">
                  <DemoMode value={currentUser.email} type="person_email" />
                </dd>
              </div>
              {userCanChangePassword && (
                <button
                  type="submit"
                  onClick={() => setShowChangePassword(true)}
                  className="inline-flex justify-center rounded-md border border-dm-charcoal-500 bg-white px-8 py-2 text-sm font-medium text-dm-charcoal-500 shadow-sm hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-50"
                >
                  Change Password
                </button>
              )}
            </div>

            <div className="py-8">
              <label className="text-[16px] text-base font-bold text-gray-900">
                Default View
              </label>
              <fieldset className="mt-4">
                <legend className="sr-only">Notification method</legend>
                <div className="space-y-4">
                  {viewOptions.map((opt) => (
                    <div key={opt.id} className="flex items-center">
                      <input
                        id={opt.id}
                        name="notification-method"
                        checked={userDefaultView === opt.id}
                        onChange={() => {
                          setUserDefaultView(opt.id);
                        }}
                        type="radio"
                        className="h-4 w-4 border-transparent outline outline-dm-charcoal-200 checked:bg-dm-brand-blue-500 checked:outline-dm-brand-blue-500"
                      />
                      <label
                        htmlFor={opt.id}
                        className="ml-3 block text-sm font-medium text-gray-700"
                      >
                        {opt.title}
                      </label>
                    </div>
                  ))}
                  <button
                    type="submit"
                    disabled={userDefaultView === theUser.defaultView}
                    onClick={() =>
                      updateDefaultView.mutate(
                        JSON.stringify({
                          _id: userAdminID,
                          defaultView: userDefaultView,
                        })
                      )
                    }
                    className="inline-flex justify-center rounded-md border border-dm-charcoal-500 bg-white px-8 py-2 text-sm font-medium text-dm-charcoal-500 shadow-sm hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-50"
                  >
                    Save
                  </button>
                </div>
              </fieldset>
            </div>
            {credentialData && credentialData?.lti_credentials && (
              <div id="lti-creds" className="space-y-4 py-8">
                <span className="text-[16px] text-base font-bold text-gray-900">
                  LTI Integration Credentials
                </span>
                <div>
                  <dt className="text-[14px] font-bold">Key</dt>
                  <dd className="">
                    <DemoMode
                      value={credentialData?.lti_credentials?.key}
                      type="guid"
                    />
                  </dd>
                </div>
                <div>
                  <dt className="text-[14px] font-bold">Secret</dt>
                  <dd className="">
                    <DemoMode
                      value={credentialData?.lti_credentials?.secret}
                      type="guid"
                    />
                  </dd>
                </div>
              </div>
            )}
          </dl>
        </div>
      </div>
      {showChangePassword && (
        <ChangePassword setShowChangePassword={setShowChangePassword} />
      )}
    </>
  );
}
