import clsx from "clsx";

export const RoundedLockIcon: React.FC<{
  variant: "static" | "ping";
  size: "sm" | "md";
  showAria?: boolean;
}> = ({ variant, size, showAria = false }) => (
  <div
    className={clsx(
      variant === "ping" &&
        "flex h-[56px] w-[56px] items-center justify-center rounded-full border border-dm-purple-100 shadow-[0_0_10px_rgba(79,1,156,0.74)]"
    )}
  >
    {variant === "ping" && (
      <>
        <div className="absolute flex h-[30px] w-[30px] animate-[ping_1500ms_ease-in-out_infinite] items-center justify-center rounded-full border border-dm-purple-500"></div>
        <div className="absolute flex h-[30px] w-[30px] animate-[ping_1500ms_ease-in-out_infinite_300ms] items-center justify-center rounded-full border border-dm-purple-500"></div>
      </>
    )}
    <div
      className={clsx(
        "flex items-center justify-center rounded-full leading-none text-white",
        size === "sm" ? "h-6 w-6 text-sm" : "h-8 w-8 text-lg",
        variant === "static" ? "bg-dm-brand-blue-800" : "bg-dm-purple-600"
      )}
    >
      <i
        className="far fa-lock-alt mb-[2px]"
        aria-label="Locked"
        role="img"
        aria-hidden={!showAria}
      ></i>
    </div>
  </div>
);
