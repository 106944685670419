import clsx from "clsx";
import { FormEventHandler, HTMLAttributes, useRef, useState } from "react";
import Button from "../../../student/components/generic/button";
import { RadioButton } from "./RadioButton";
import { TextField } from "./TextField";
import { Checkbox } from "./Checkbox";
import { useMutation } from "react-query";
import axios from "axios";
import { deltamathAPI } from "../../../manager/utils";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import { LearnerEntitlement } from "../../types";

interface FormState {
  accountType: Extract<LearnerEntitlement, "parent" | "learner">;
  first: string;
  last: string;
  email: string;
  communicationConsent: boolean;
}

interface BetaWaitlistResponse {
  data:
    | {
        success: true;
      }
    | {
        success: false;
        errors: {
          [K in keyof FormState]?: string;
        };
      };
}

interface BetaWaitlistFormProps
  extends Omit<HTMLAttributes<HTMLFormElement>, "onSubmit"> {
  setExpanded: (expanded: boolean) => void;
}

export const BetaWaitlistForm: React.FC<BetaWaitlistFormProps> = ({
  className,
  setExpanded,
  ...props
}) => {
  const toastContext = useDeltaToastContext();
  const [formState, setFormState] = useState<FormState>({
    accountType: "parent",
    first: "",
    last: "",
    email: "",
    communicationConsent: true,
  });
  const [formErrors, setFormErrors] = useState<{
    [K in keyof FormState]?: string;
  }>({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  // This is use to store the form height to prevent the page jumping
  // when displaying the success message
  const [presubmitFormHeight, setPresubmitFormHeight] = useState(0);
  const formRef = useRef<HTMLFormElement>(null);

  const { mutateAsync: sendFormData, isLoading } = useMutation<
    BetaWaitlistResponse,
    unknown,
    FormState
  >((body) => {
    return axios.post(
      `${deltamathAPI()}/learner/beta/waitlist`,
      JSON.stringify(body),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  });

  const handleSubmit: FormEventHandler = async (e) => {
    e.preventDefault();
    try {
      const { data } = await sendFormData(formState);
      if (data.success) {
        setPresubmitFormHeight(formRef.current?.offsetHeight ?? 0);
        // Delay setting success to make sure we get an accurate form height
        setTimeout(() => {
          setFormErrors({});
          setIsSubmitted(true);
        }, 1);
      } else {
        setFormErrors(data.errors);
      }
    } catch (e) {
      toastContext.addToast({
        title: "Error",
        message: "There was an error submitting the form",
        status: "Error",
      });
    }
  };

  const Title = () => (
    <h3 className="w-full text-center font-serif text-lg font-bold text-dm-brand-blue-600 md:text-2xl">
      Join the Waitlist
    </h3>
  );

  if (isSubmitted) {
    return (
      <div
        className={clsx(className, "flex flex-col text-center")}
        style={{ minHeight: `${presubmitFormHeight}px` }}
      >
        <Title />
        <div className="mt-6 flex flex-col gap-2">
          <h4
            className="font-serif text-lg font-bold text-dm-brand-blue-600"
            aria-live="assertive"
          >
            You&apos;re on the list!
          </h4>
          <p>
            We&apos;ll keep you updated with all the latest news and let you
            know as soon as DeltaMath for Home is available.
          </p>
        </div>
      </div>
    );
  }

  return (
    <form
      onSubmit={handleSubmit}
      className={clsx(className, "flex flex-col gap-6")}
      ref={formRef}
      {...props}
    >
      <Title />

      <div className="flex flex-col gap-4">
        {/* Account type buttons */}
        <div className="sr-only">Are you a parent or student?</div>
        <fieldset className="flex gap-4">
          <RadioButton
            label="Parent"
            checked={formState.accountType === "parent"}
            onClick={() => {
              setFormState({
                ...formState,
                accountType: "parent",
              });
            }}
          />
          <RadioButton
            label="Student"
            checked={formState.accountType === "learner"}
            onClick={() => {
              setFormState({
                ...formState,
                accountType: "learner",
              });
            }}
          />
        </fieldset>

        {/* Text fields */}
        <TextField
          label="First Name"
          value={formState.first}
          error={formErrors.first}
          onChange={(e) =>
            setFormState({ ...formState, first: e.target.value })
          }
        />
        <TextField
          label="Last Name"
          value={formState.last}
          error={formErrors.last}
          onChange={(e) => setFormState({ ...formState, last: e.target.value })}
        />
        <TextField
          label="Email"
          value={formState.email}
          error={formErrors.email}
          onChange={(e) =>
            setFormState({ ...formState, email: e.target.value })
          }
          type="email"
        />
      </div>

      {/* Checkbox for communication */}
      <Checkbox
        checked={formState.communicationConsent}
        error={formErrors.communicationConsent}
        onChange={() =>
          setFormState({
            ...formState,
            communicationConsent: !formState.communicationConsent,
          })
        }
      >
        Get notified of updates & offers
      </Checkbox>

      <Button disabled={isLoading}>Sign Me Up!</Button>
    </form>
  );
};
