import { ReactNode, useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import { differenceInDays, format } from "date-fns/esm";
import {
  LearnerAssignmentTypes,
  ParentActivity,
  ParentActivityPastAttemptProps,
} from "../../types";
import Document from "../../../student/components/icons/Document";
import Accordion from "../Accordion";
import IconProgress from "../IconProgress";
import clsx from "clsx";
import { useLearnerAnalytics } from "../../analytics/useLearnerAnalytics";
import { parentRecentActivityDropdownsEvent } from "../../analytics/events";

//TODO: The rules for the title of each item in the modal may be different from
// the rules of RecentActivityCard, so keeping these GetProgressLabel() functions
// unique for now
const GetProgressLabel = ({
  timeSpent,
  submitted,
  pastAttempts,
  grade,
  maxGrade,
  quizImprovement,
}: {
  timeSpent?: number;
  submitted?: Date;
  pastAttempts?: ParentActivityPastAttemptProps[];
  grade?: number;
  maxGrade?: number;
  quizImprovement?: ReactNode;
}) => {
  const allAttempts: ParentActivityPastAttemptProps[] = [
    ...(pastAttempts || []),
    ...(submitted
      ? [
          {
            grade: grade || 0,
            timeSpent: timeSpent || 0,
            submitted: submitted.toString(),
          },
        ]
      : []),
  ];
  const bestResult: ParentActivityPastAttemptProps | undefined =
    allAttempts.length
      ? allAttempts?.reduce((acc, curr) => {
          return curr.grade > acc.grade ? curr : acc;
        })
      : undefined;
  const timeValue =
    bestResult?.timeSpent !== undefined
      ? Math.max(1, Math.round(bestResult.timeSpent / 60))
      : 0;
  const timeLabel =
    bestResult?.timeSpent !== undefined ? (
      <>
        <span className="mx-2 text-dm-charcoal-100">|</span>
        <strong>{timeValue}</strong>{" "}
        {timeValue === 1 ? "min spent" : "mins spent"}
      </>
    ) : null;
  if (!bestResult) {
    return (
      <>
        : <strong>In Progress</strong>
      </>
    );
  } else if (bestResult) {
    return (
      <>
        {" "}
        Score: {Math.round((maxGrade || grade || 0) * 100)}%{" "}
        {allAttempts.length > 1 ? "(best)" : ""}
        {quizImprovement}
        {timeLabel}
      </>
    );
    // if this test is a retake, just show the best score, whether or not it's in progress
  } else if (allAttempts) {
    return (
      <>
        {" "}
        Score: {Math.round((maxGrade || 0) * 100)}%{" "}
        {allAttempts.length > 1 ? "(best)" : ""}
        {quizImprovement}
        {timeLabel}
      </>
    );
  }
  return (
    <>
      : <strong>Not Started</strong>
    </>
  );
};

const GetAssignmentTitle: React.FC<{
  label: ReactNode;
  pastAttempts?: ParentActivityPastAttemptProps[];
  maxGrade?: number;
  submitted?: Date;
  grade?: number;
  timeSpent?: number;
  assignmentType: LearnerAssignmentTypes;
}> = ({
  label,
  pastAttempts,
  maxGrade,
  submitted,
  grade,
  timeSpent,
  assignmentType,
}) => {
  const { track } = useLearnerAnalytics();
  const [isOpen, setIsOpen] = useState(false);
  const allAttempts: ParentActivityPastAttemptProps[] = [
    ...(pastAttempts || []),
    ...(submitted
      ? [
          {
            grade: grade || 0,
            timeSpent: timeSpent || 0,
            submitted: submitted.toString(),
          },
        ]
      : []),
  ];

  useEffect(() => {
    if (isOpen) {
      track(parentRecentActivityDropdownsEvent({ assignmentType }));
    }
    // Only run when isOpen changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <div className="text-sm">
      <Accordion
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        wrapperClasses="flex-grow"
        buttonClasses="!justify-start !pr-0 !pl-1 !-mx-1 !py-1 !mb-0 hover:!bg-inherit"
        panelClasses="bg-dm-brand-blue-100/40"
        titleButton={
          <span className="flex flex-grow items-center gap-x-2 text-left">
            <i className="far fa-list w-4 text-center text-xs text-dm-gray-200"></i>
            <span>{label}</span>
          </span>
        }
        panel={
          <div className="py-2 pl-6">
            {allAttempts
              ?.sort(
                (a, b) =>
                  new Date(b.submitted).getTime() -
                  new Date(a.submitted).getTime()
              )
              .map((attempt: ParentActivityPastAttemptProps, index) => {
                const timeValue =
                  attempt.timeSpent !== undefined
                    ? Math.max(1, Math.round(attempt.timeSpent / 60))
                    : undefined;
                return (
                  <div
                    className="py-1"
                    key={`${attempt.submitted.toString()}-${index}`}
                  >
                    {format(new Date(attempt.submitted), "MMM do, h:mmaaa")}:{" "}
                    <strong>{Math.round((attempt.grade || 0) * 100)}%</strong>
                    {attempt.grade === maxGrade &&
                      allAttempts.length > 1 &&
                      " (best)"}
                    {timeValue !== undefined && (
                      <>
                        <span className="mx-2 text-dm-charcoal-100">|</span>
                        <strong>{timeValue}</strong>{" "}
                        {timeValue === 1 ? "min spent" : "mins spent"}
                      </>
                    )}
                  </div>
                );
              })}
          </div>
        }
      />
    </div>
  );
};

type Props = {
  activity: ParentActivity;
};

const RecentActivityDetailCard: React.FC<Props> = (props) => {
  const { track } = useLearnerAnalytics();
  const [isOpen, setIsOpen] = useState(false);
  const daysSinceLastActivity = differenceInDays(
    new Date(),
    new Date(props.activity.lastActivity)
  );
  const lastActivityLabel =
    daysSinceLastActivity === 0
      ? "Today"
      : daysSinceLastActivity === 1
      ? "1 day ago"
      : `${daysSinceLastActivity} days ago`;

  const type = props.activity.type;
  const progress = props.activity.progress;
  const practice = props.activity?.practice;

  const assignmentType: LearnerAssignmentTypes =
    type === "CourseTest"
      ? "courseTest"
      : type === "UnitTest"
      ? "unitTest"
      : "postQuiz";

  const isPreQuizSkipped =
    props.activity?.preQuiz && Object.keys(props.activity.preQuiz).length === 0;

  const GetAssignmentLabel = ({
    progress,
  }: {
    progress: number | undefined;
  }) => {
    if (progress === undefined) {
      return (
        <>
          : <strong>Not Started</strong>
        </>
      );
    }
    return (
      <span className="inline-flex items-center rounded-full bg-dm-brand-blue-500 px-1.5 py-1 text-xs font-normal leading-none text-white">
        {Math.round((progress || 0) * 100)}% Complete
      </span>
    );
  };

  const showPracticeSkills = () => {
    if (practice?.progress === undefined) return false;
    const hiddenOptionalSkills = (practice.skills || []).filter(
      (x) => x.optional && x.record === 0
    ).length;

    if (
      practice.skills &&
      practice.skills.length - hiddenOptionalSkills === 0
    ) {
      return false;
    }
    return true;
  };

  const getSkillTooltip = (skillName: string, history: Array<boolean>) => {
    const skillHistory = history?.map((h: boolean) => {
      if (h === true) {
        return `<i
          class="far fa-check fa-fw text-xl leading-none !text-dm-success-500"
          aria-hidden="true"
        ></i>`;
      } else {
        return `<i
          class="far fa-times fa-fw text-xl leading-none !text-dm-error-500"
          aria-hidden="true"
        ></i>`;
      }
    });

    return `${skillName}<br />${skillHistory.join("")}`;
  };

  const postQuizImprovement = () => {
    if (type !== "Subunit" || isPreQuizSkipped) return null;

    const preQuizGrade = props.activity?.preQuiz?.grade || 0;
    const postQuizGrade =
      props.activity?.postQuiz?.maxGrade ||
      props.activity?.postQuiz?.grade ||
      0;

    if (postQuizGrade > preQuizGrade) {
      return (
        <span className="ml-2 inline-flex items-center rounded bg-dm-success-500 px-1.5 py-1 text-sm font-normal leading-none text-white">
          +{Math.round((postQuizGrade - preQuizGrade) * 100)}%
        </span>
      );
    }
    return null;
  };

  useEffect(() => {
    if (isOpen) {
      track(parentRecentActivityDropdownsEvent({ assignmentType: "practice" }));
    }
    // Only run when isOpen changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  ReactTooltip.rebuild();

  return (
    <article className="flex flex-col gap-y-2">
      <header className="flex justify-between gap-2 text-sm text-dm-charcoal-800">
        <h2 className="font-bold">{props.activity.title}</h2>
        <h3 className="shrink-0 italic">{lastActivityLabel}</h3>
      </header>
      <section className="flex flex-col gap-y-1 pl-1">
        {type === "Subunit" && (
          <>
            <div className="flex items-center gap-x-2 py-1">
              <i className="far fa-list w-4 text-center text-xs text-dm-gray-200"></i>
              <span className="text-sm text-dm-charcoal-800">
                Pre-Quiz
                {isPreQuizSkipped ? (
                  <>
                    : <strong>Skipped</strong>
                  </>
                ) : (
                  <GetProgressLabel
                    timeSpent={props.activity?.preQuiz?.timeSpent}
                    submitted={props.activity?.preQuiz?.submitted}
                    grade={props.activity?.preQuiz?.grade}
                  />
                )}
              </span>
            </div>
            {!showPracticeSkills() ? (
              <div className="flex items-center gap-x-2 py-1">
                <Document classes="w-4 text-center" strokeColor="#9CA3AF" />
                <span className="flex items-center gap-x-2 text-sm text-dm-charcoal-800">
                  Practice
                  <GetAssignmentLabel progress={practice?.progress} />
                </span>
              </div>
            ) : (
              <div className="text-sm">
                <Accordion
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  wrapperClasses="flex-grow"
                  buttonClasses="!justify-start !pr-0 !pl-1 !-mx-1 !py-1 !mb-0 hover:!bg-inherit"
                  panelClasses="bg-dm-brand-blue-100/40"
                  onToggle={() => ReactTooltip.rebuild()}
                  titleButton={
                    <span className="flex flex-grow gap-x-2 text-left">
                      <Document
                        classes="w-4 text-center"
                        strokeColor="#9CA3AF"
                      />
                      Practice
                      <GetAssignmentLabel progress={practice?.progress} />
                    </span>
                  }
                  panel={
                    <div className="py-2">
                      {practice?.skills?.map((skill, index: number) => {
                        if (skill.optional && skill.record === 0) return null;
                        return (
                          <div
                            key={`${props.activity.title}-skill-${skill.skillCode}-${index}`}
                            className={clsx(
                              "mb-0.5 flex items-center gap-x-1.5 py-1.5 pl-6 last:mb-0",
                              skill.history.length > 0 && "cursor-help"
                            )}
                            {...(skill.history.length > 0 && {
                              "data-for": "activityTooltip",
                              "data-tip": getSkillTooltip(
                                skill.skillName,
                                skill.history
                              ),
                            })}
                            // tabIndex={0}
                          >
                            <IconProgress
                              score={skill.record}
                              required={skill.required}
                              record={skill.record}
                              showThumbsUp={false}
                            />
                            <div className="text-sm">{skill.skillName}</div>
                            {skill.optional && (
                              <span className="inline-flex items-center gap-x-1 rounded-full bg-dm-success-200 px-2 py-0.5 text-xs font-normal text-dm-success-800">
                                <i
                                  className="far fa-thumbs-up fa-fw text-xs leading-none !text-dm-success-500"
                                  role="img"
                                  aria-hidden={true}
                                ></i>
                                Optional
                              </span>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  }
                />
              </div>
            )}
            {props.activity?.postQuiz?.pastAttempts ? (
              <GetAssignmentTitle
                assignmentType="postQuiz"
                maxGrade={
                  props.activity?.postQuiz?.maxGrade ||
                  props.activity?.postQuiz?.grade
                }
                label={
                  <>
                    Post-Quiz
                    <GetProgressLabel
                      timeSpent={props.activity?.postQuiz?.timeSpent}
                      submitted={props.activity?.postQuiz?.submitted}
                      pastAttempts={props.activity?.postQuiz?.pastAttempts}
                      grade={props.activity?.postQuiz?.grade}
                      maxGrade={props.activity?.postQuiz?.maxGrade}
                      quizImprovement={postQuizImprovement()}
                    />
                  </>
                }
                pastAttempts={props.activity?.postQuiz?.pastAttempts}
                submitted={props.activity?.postQuiz?.submitted}
                grade={props.activity?.postQuiz?.grade}
                timeSpent={props.activity?.postQuiz?.timeSpent}
              />
            ) : (
              <div className="flex items-center gap-x-2 py-1">
                <i className="far fa-list w-4 text-center text-xs text-dm-gray-200"></i>
                <span className="flex items-center gap-x-2 text-sm text-dm-charcoal-800">
                  Post-Quiz
                  {props.activity?.postQuiz?.progress === undefined ? (
                    <>
                      : <strong>Not Started</strong>
                    </>
                  ) : (
                    <GetProgressLabel
                      timeSpent={props.activity?.postQuiz?.timeSpent}
                      submitted={props.activity?.postQuiz?.submitted}
                      pastAttempts={props.activity?.postQuiz?.pastAttempts}
                      grade={props.activity?.postQuiz?.grade}
                      maxGrade={props.activity?.postQuiz?.maxGrade}
                      quizImprovement={postQuizImprovement()}
                    />
                  )}
                </span>
              </div>
            )}
          </>
        )}
        {["UnitTest", "CourseTest"].includes(type) && (
          <GetAssignmentTitle
            assignmentType={assignmentType}
            maxGrade={progress?.maxGrade || progress?.grade}
            label={
              <>
                {type === "UnitTest" ? `Unit Test` : `Course Test`}
                <GetProgressLabel
                  timeSpent={progress?.timeSpent}
                  submitted={progress?.submitted}
                  pastAttempts={progress?.pastAttempts}
                  grade={progress?.grade}
                  maxGrade={progress?.maxGrade}
                />
              </>
            }
            pastAttempts={progress?.pastAttempts}
            submitted={progress?.submitted}
            grade={progress?.grade}
            timeSpent={progress?.timeSpent}
          />
        )}
      </section>
    </article>
  );
};

export default RecentActivityDetailCard;
