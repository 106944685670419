export const PROGRESS_MINIMUM = 0.7;
export const MOBILE_NAV_BAR_HEIGHT = 61;
export const MIN_POINTS_TO_UNLOCK = 31;
export const MIN_ROWS_TO_UNLOCK = 5;
export const WEEKLY_GOAL_POINTS = 50;
export const LOCKED_PRIZE_TOOLTIP = "You have not yet unlocked this prize";
export const ACTIVE_LEARNER_STALE_TIME = 1000 * 60 * 30; // 30 minutes

// Timer localStorage keys
export const TIMER_LOCALSTORAGE_KEY_PREFIX = "dm_t";
export const TIMER_LOCALSTORAGE_KEY_VERSION = 2;
export const TIMER_LOCALSTORAGE_ROOT_KEY = `${TIMER_LOCALSTORAGE_KEY_PREFIX}:v${TIMER_LOCALSTORAGE_KEY_VERSION}`;
export const TIMER_LOCALSTORAGE_APP_KEY = "app:total";
export const TIMER_LOCALSTORAGE_BASE_COURSE_KEY = "app:course";

export const TMP_HOMEPAGE_PATH = "d9ed1a33";
