import { noop } from "lodash";
import { PascalsTriangleRow } from "../../../types";
import { generatePascalsTriangle } from "../../../utils/generatePascalsTriangle";
import { PascalsCell } from "../PascalsCell";
import { LockedRow } from "../LockedRow";

export const OnboardingPascalsTriangle: React.FC<{
  step: number;
  filledInRow: PascalsTriangleRow;
  isPaused: boolean;
}> = ({ step, filledInRow, isPaused }) => {
  const rows = generatePascalsTriangle(Array(5).fill(false));
  const isFillingFirstCell =
    filledInRow[0] === true && filledInRow.filter(Boolean).length === 1;
  const isFillingLastCell = filledInRow.every(Boolean);
  return (
    <div className="flex flex-col gap-[11px]">
      {rows.map((row, rowIndex) => (
        <div key={rowIndex} className="flex justify-center">
          {row.map(([, expectedValue], cellIndex) => {
            let currentValue: number | null;
            if (rowIndex === filledInRow.length - 1) {
              currentValue = filledInRow[cellIndex] ? expectedValue : null;
            } else if (rowIndex < filledInRow.length) {
              currentValue = expectedValue;
            } else {
              currentValue = null;
            }

            let isHighlighted = false;
            if (isPaused && step === 3) {
              // Highlight parents of selected cell when paused
              isHighlighted =
                rowIndex === 3 && (cellIndex === 0 || cellIndex === 1);
            } else if (filledInRow.length >= 4) {
              // Highlight ones
              isHighlighted =
                (isFillingFirstCell && cellIndex === 0) ||
                (isFillingLastCell && cellIndex === row.length - 1);
            }

            return (
              <PascalsCell
                key={cellIndex}
                currentValue={currentValue}
                expectedValue={expectedValue}
                isEditable={false}
                isAutofill={currentValue === null}
                isHighlighted={isHighlighted}
                showPlus={
                  step === 3 && isPaused && rowIndex === 3 && cellIndex === 1
                }
                isSelected={
                  step === 3 && isPaused && rowIndex === 4 && cellIndex === 1
                }
                setActiveCell={noop}
                redeemPoints={noop}
              />
            );
          })}
        </div>
      ))}
      <LockedRow length={rows.length + 1} />
    </div>
  );
};
