import { useNavigate } from "react-router";
import Button from "../generic/button";
import {
  getTimedAssignment,
  unlockAssignment,
  useShowResults,
} from "../../utils/api";
import {
  timeLimitText,
  updateFullAssignmentData,
  getAssignmentDueDateType,
} from "../../utils";
import { capitalize } from "lodash";
import { useContext, useEffect, useState } from "react";
import StudentSectionsContext from "../../_context/StudentSectionsContext";
import clsx from "clsx";
import { NavLink } from "react-router-dom";
import { useQueryClient } from "react-query";
import UploadWork from "../UploadWork";
import { REACT_APP_STUDENT_LINK } from "../../../utils";

type Props = {
  timedAssignment: {
    isTimed: boolean;
    timeLimit: any;
    endTime: number | undefined;
    serverTime: any;
    additionalTime: any;
    timerText: string;
    isUnlimited: boolean;
    isOverUnlimitedTime: boolean | 0 | undefined;
    isOver: any;
  };
  sa: any;
  ta: any;
  teacherId: string;
  sectionId: string;
  refetchAssignments: () => void;
};

const TimedAssignment = (props: Props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { dmAssignmentData, setDmAssignmentData, setLoadingData } = useContext(
    StudentSectionsContext
  );
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const pcKeyName = "pc_" + props.ta._id + "_" + user?._id;
  const [navigateToFirstSkill, setNavigateToFirstSkill] =
    useState<boolean>(false);
  const uploadWorkAvailable = props.ta.notes === 2;

  const [passcode, setPasscode] = useState<string>("");

  const requiresPasscode =
    props.ta.is_test &&
    props.ta.passcode_required === "yes" &&
    (localStorage.getItem(pcKeyName) === null ||
      props.sa.passcodeEntered !== true) &&
    !props.sa.solutions_seen;
  const isTimedPending =
    props.timedAssignment.isTimed &&
    props.timedAssignment.timeLimit &&
    !props.timedAssignment.endTime;
  const isShowResultsAfterDueDate =
    props.ta.otherwiseHidden === true &&
    props.ta.obscureResults === true &&
    props.ta.solutionsAvailable === true;

  const assignmentsUrl = `${REACT_APP_STUDENT_LINK}/${
    props.sectionId
  }/${getAssignmentDueDateType(props?.sa?.status)}`;
  const firstSkill: any = props.ta.order?.[0]
    ? props.ta.skills[props.ta.order[0]]
    : undefined;
  const firstSkillUrl = `${REACT_APP_STUDENT_LINK}/${props.sectionId}/${props.teacherId}/${firstSkill?.uid}`;

  useEffect(() => {
    if (navigateToFirstSkill) {
      navigate(firstSkillUrl, { replace: true });
    }
  }, [navigateToFirstSkill]);

  // If this exists in local storage it was an LTI launch
  const lti = JSON.parse(
    localStorage.getItem("lti_assignment_payload") || "{}"
  ).isLtiResourceLaunch;

  /* **************** */
  /* unlockAssignment */
  /* **************** */

  const unlockWithPasscode = unlockAssignment(
    props.teacherId,
    passcode,
    setLoadingData
  );

  const showResultsMutation = useShowResults(props.ta._id);

  const startTimed = getTimedAssignment(
    props.teacherId,
    props.sectionId,
    setLoadingData,
    dmAssignmentData,
    setDmAssignmentData,
    navigate,
    queryClient,
    props.refetchAssignments
  );
  const { refetch: timedRefetch } = startTimed;

  // unlockAssignment useEffect:
  // Timed tests with a passcode need to run timedRefetch() after unlockAssignment()
  useEffect(() => {
    if (unlockWithPasscode.isSuccess && isTimedPending) {
      timedRefetch();
    } else if (unlockWithPasscode.isSuccess && firstSkill !== undefined) {
      if (!props.sa.passcodeEntered) {
        const assignment = {
          ta: props.ta,
          sa: props.sa,
        };
        updateFullAssignmentData(
          {
            ...assignment,
            sa: {
              ...assignment.sa,
              passcodeEntered: true,
            },
          },
          assignment.ta._id,
          props.sectionId,
          dmAssignmentData,
          setDmAssignmentData
        );
      }
      navigate(firstSkillUrl, { replace: true });
    }
  }, [unlockWithPasscode.isSuccess]);

  const handleShowSolutionsData = (taId: any, skillName?: string) => {
    const body = skillName ? JSON.stringify({ sk: skillName }) : "{}";
    showResultsMutation.mutate(body, {
      onSuccess: (data) => {
        if (data?.data?.assignment) {
          updateFullAssignmentData(
            data.data.assignment,
            taId,
            props.sectionId,
            dmAssignmentData,
            setDmAssignmentData
          );
          navigate(firstSkillUrl, { replace: true });
        }
      },
    });
  };

  if (isShowResultsAfterDueDate) {
    return (
      <>
        <div className="mb-10 flex flex-col gap-6">
          <p>
            Results are now available for this test. Press &quot;Show
            Results&quot; to continue.
          </p>
        </div>
        <div className="flex justify-between border-t py-8">
          <div className="flex gap-4">
            {!lti && (
              <Button
                onClick={() => {
                  navigate(assignmentsUrl);
                }}
                type={props.ta.obscureResults ? "outline" : "primary"}
              >
                Back to Home
              </Button>
            )}
            {uploadWorkAvailable && (
              <UploadWork
                section={{ ta: props.ta, sa: props.sa }}
                buttonType="outline"
              />
            )}
          </div>
          <Button
            onClick={() => {
              handleShowSolutionsData(props.ta._id);
            }}
          >
            Show Results
          </Button>
        </div>
      </>
    );
  } else if (props.timedAssignment.isOver && !props.sa.solutions_seen) {
    return (
      <>
        {props.timedAssignment.isOver && (
          <>
            <div className="mb-10 flex flex-col gap-6">
              <h3 className="text-2xl font-bold">
                You&apos;ve Reached The Time Limit
              </h3>
              <p>You have run out of time to complete this assignment.</p>
            </div>
            <div className="flex justify-between border-t py-8">
              <div className="flex gap-4">
                {!lti && (
                  <Button
                    onClick={() => {
                      navigate(assignmentsUrl);
                    }}
                    type={props.ta.obscureResults ? "outline" : "primary"}
                  >
                    Back to Home
                  </Button>
                )}
                {uploadWorkAvailable && (
                  <UploadWork
                    section={{ ta: props.ta, sa: props.sa }}
                    buttonType="outline"
                  />
                )}
              </div>
              {props.ta.obscureResults &&
                (props.ta.solutionsAvailable ||
                  (props.timedAssignment?.isTimed &&
                    props.ta.showSolutionsOnTimeOver === true &&
                    props.timedAssignment.isOver)) && (
                  <Button
                    onClick={() => {
                      handleShowSolutionsData(props.ta._id);
                    }}
                  >
                    Show Results
                  </Button>
                )}
            </div>
          </>
        )}
      </>
    );
  } else if (requiresPasscode) {
    return (
      <>
        <p>
          {props.timedAssignment.isTimed ? (
            <>
              Once you start this test, you will have{" "}
              <strong>
                {props.timedAssignment.isUnlimited
                  ? "unlimited time"
                  : timeLimitText(props.timedAssignment.timeLimit)}
              </strong>{" "}
              to complete it.
            </>
          ) : (
            "You must enter the correct passcode to begin the test on this device."
          )}
        </p>
        <div className="mt-6 flex flex-wrap items-end gap-x-6 gap-y-3 sm:flex-nowrap">
          <div className="flex grow basis-full flex-col sm:basis-auto">
            <label
              htmlFor="passcode"
              className="text-sm leading-6 text-dm-charcoal-500"
            >
              Passcode
            </label>
            <input
              type="text"
              name="passcode"
              id="passcode"
              className="block grow rounded border border-[#979797] px-2 py-2.5 text-sm text-gray-900 focus:ring-dm-brand-blue-500"
              maxLength={5}
              value={passcode}
              onChange={(e) => setPasscode(e.target.value)}
            />
          </div>
        </div>
        <div className="mt-8 flex flex-col-reverse items-center justify-between gap-6 border-t border-t-[#CCCED0] pt-6 sm:flex-row">
          {!lti && (
            <Button
              onClick={() => {
                navigate(assignmentsUrl);
              }}
              type="outline"
            >
              Back to Home
            </Button>
          )}
          <button
            type="button"
            className={clsx(
              "basis-full rounded border px-8 py-2.5 text-sm text-white focus-visible:outline focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:basis-auto",
              !passcode
                ? "border-transparent bg-dm-brand-blue-500/50"
                : "border-dm-brand-blue-500 bg-dm-brand-blue-500 hover:bg-dm-brand-blue-600"
            )}
            disabled={!passcode}
            onClick={() => unlockWithPasscode.mutate({})}
          >
            Start Test Now
          </button>
        </div>
      </>
    );
  } else if (isTimedPending && startTimed.status !== "success") {
    const testOrAssignment = props.ta.is_test ? "test" : "assignment";
    const timingText = props.timedAssignment.isUnlimited
      ? "unlimited time"
      : timeLimitText(props.timedAssignment.timeLimit);

    return (
      <>
        <p>
          {props.timedAssignment.additionalTime && (
            <>
              You have been given additional time to finish this{" "}
              {testOrAssignment}.{" "}
            </>
          )}
          <>
            Once you {props.timedAssignment.additionalTime ? "resume" : "start"}{" "}
            this {testOrAssignment}, you will have {timingText} to complete it.
          </>
        </p>
        <div className="mt-8 flex flex-wrap items-center justify-between gap-x-6 gap-y-3 border-t border-t-[#CCCED0] pt-8 sm:flex-nowrap">
          {!lti ? (
            <NavLink
              to={assignmentsUrl}
              className="basis-full rounded border border-dm-charcoal-500 bg-white px-5 py-2.5 text-center text-sm text-dm-charcoal-500 hover:bg-dm-charcoal-500 hover:text-white focus-visible:outline focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:basis-auto sm:px-8"
            >
              Back To Home
            </NavLink>
          ) : (
            <div></div>
          )}
          <button
            className={clsx(
              "basis-full rounded border px-5 py-2.5 text-sm text-white focus-visible:outline focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:basis-auto sm:px-8",
              "border-dm-brand-blue-500 bg-dm-brand-blue-500 hover:bg-dm-brand-blue-600"
            )}
            onClick={() => {
              timedRefetch();
            }}
          >
            {props.timedAssignment.additionalTime ? "Resume " : "Start "}
            {capitalize(testOrAssignment)} Now
          </button>
        </div>
      </>
    );
  }

  // We are in the else scenario where the correction has already been initialized and is not being reset
  if (!navigateToFirstSkill) {
    setNavigateToFirstSkill(true);
  }

  return <></>;
};

export default TimedAssignment;
