import clsx from "clsx";
import { MouseEventHandler } from "react";

export const RadioButton: React.FC<{
  label: string;
  checked: boolean;
  onClick: MouseEventHandler;
}> = ({ label, checked, onClick }) => (
  <button
    type="button"
    className={clsx(
      "inline-flex h-10 grow items-center justify-center rounded-full border",
      checked
        ? "border-dm-brand-blue-600 bg-dm-brand-blue-600 text-white"
        : "cursor-pointer border-dm-charcoal-800 bg-white text-dm-charcoal-800 hover:bg-dm-charcoal-100"
    )}
    aria-controls={`accountType-${label}`}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    onKeyDown={(e) => {
      e.preventDefault();
      if (e.key === "Enter") {
        onClick(e as any);
      }
    }}
  >
    <input
      className="sr-only"
      id={`accountType-${label}`}
      type="radio"
      name="accountType"
      defaultChecked={checked}
      aria-label={`I am a ${label}`}
    />
    <span aria-hidden>{label}</span>
  </button>
);
