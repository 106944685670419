import { useEffect } from "react";
import { factorBubbleScript } from "../../../utils/factorBubbleScript";
import { ContentPrizeModalProps } from "../../../types";

export const FactorBubbleGame: React.FC<ContentPrizeModalProps> = ({
  setTitle,
  winFunc,
  loseFunc,
}) => {
  useEffect(() => {
    setTitle("Bubble Blast Game");
    factorBubbleScript(winFunc, loseFunc);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="canv-div-wrapper col-xs-12"
      style={{ margin: "0", padding: "0 0 1rem" }}
    >
      <svg id="factor-bubble-game-canvas" />
    </div>
  );
};
