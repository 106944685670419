import confetti from "canvas-confetti";
import { ConfettiType, ConfettiShape, Learner } from "../types";

const runConfetti = (
  learner: Learner | undefined,
  type?: ConfettiType,
  particle?: ConfettiShape,
  emojiGlyph?: string
) => {
  confetti.reset(); // Clear existing confetti

  const confettiType: ConfettiType =
    type ?? learner?.prizesState?.confetti?.type ?? "default";
  const confettiShape: ConfettiShape =
    particle ?? learner?.prizesState?.confetti?.shape ?? "default";

  const emoji = confetti.shapeFromText({
    text: emojiGlyph ?? learner?.prizesState?.confetti?.emoji ?? "❤️",
    scalar: 1,
  });

  const count = confettiShape === "emoji" ? 300 : 500;

  const defaults = {
    decay: 0.93,
    ticks: 500,
    gravity: 1.2,
    ...(confettiShape === "default" && { scalar: 1.2 }),
    ...(confettiShape === "emoji" && { shapes: [emoji], scalar: 2.2 }),
    colors: ["#2F52E9", "#078445", "#EDAB46", "#FCD95F", "#7001DE", "#D21527"],
  };

  function fire(particleRatio: number, opts: confetti.Options | undefined) {
    confetti({
      ...defaults,
      ...opts,
      particleCount: Math.floor(count * particleRatio),
    });
  }

  const confettiDefault = () => {
    // Left
    fire(0.35, {
      spread: 100,
      startVelocity: 55,
      angle: 110,
      origin: { y: 0.25 },
      gravity: 1.2,
    });
    // Up
    fire(0.2, {
      spread: 100,
      startVelocity: 40,
      origin: { y: 0.3 },
      gravity: 1.3,
    });
    // Right
    fire(0.35, {
      spread: 100,
      startVelocity: 50,
      angle: 70,
      origin: { y: 0.2 },
      gravity: 1.2,
    });
  };

  const confettiShooting = () => {
    const end = Date.now() + 3 * 1000;

    (function frame() {
      // Left
      confetti({
        ...defaults,
        particleCount: confettiShape === "emoji" ? 1 : 6,
        angle: 65,
        spread: 45,
        origin: { x: -0.01 },
        startVelocity: 55,
        ticks: 400,
      });
      // Right
      confetti({
        ...defaults,
        particleCount: confettiShape === "emoji" ? 1 : 6,
        angle: 115,
        spread: 45,
        origin: { x: 1.01 },
        startVelocity: 55,
        ticks: 400,
      });

      if (Date.now() < end) {
        window.requestAnimationFrame(frame);
      }
    })();
  };

  const confettiFireworks = () => {
    const duration = 5 * 1000;
    const animationEnd = Date.now() + duration;
    const fireworksDefaults = {
      ...defaults,
      startVelocity: randomInRange(20, 30),
      spread: 360,
      ...(confettiShape === "emoji"
        ? { ticks: 100, scalar: 2.4 }
        : { ticks: 60, scalar: confettiShape === "default" ? 1.2 : 2 }),
    };

    function randomInRange(min: number, max: number) {
      return Math.random() * (max - min) + min;
    }

    const interval: ReturnType<typeof setInterval> = setInterval(function () {
      const timeLeft = animationEnd - Date.now();

      if (timeLeft <= 0) {
        return clearInterval(interval);
      }

      const particleCount =
        (confettiShape === "emoji" ? 60 : 200) * (timeLeft / duration);

      confetti({
        ...fireworksDefaults,
        particleCount,
        origin: { x: randomInRange(0.1, 0.45), y: Math.random() - 0.2 },
      });
      confetti({
        ...fireworksDefaults,
        particleCount,
        origin: { x: randomInRange(0.55, 0.9), y: Math.random() - 0.2 },
      });
    }, 500);
  };

  /**  UNUSED TYPES, ERASE AFTER FINAL DECISIONS MADE */
  const confettiFloatDownFromTop = () => {
    fire(0.7, {
      spread: 180,
      startVelocity: 40,
      origin: { y: -0.4 },
      // angle: 90,
    });
    fire(0.2, {
      spread: 105,
      startVelocity: 25,
      origin: { y: -0.2 },
      // angle: 90,
    });
  };

  const confettiFromTopCorners = () => {
    // Left
    fire(0.4, {
      origin: { y: 0, x: -0.1 },
      spread: 130,
      startVelocity: 45,
      angle: 20,
      drift: 0.5,
    });
    // Right
    fire(0.4, {
      origin: { y: 0, x: 1.1 },
      spread: 130,
      startVelocity: 45,
      angle: 160,
      drift: -0.5,
    });
    // Center to fill in empty space
    fire(0.2, {
      spread: 200,
      startVelocity: 30,
      origin: { y: -0.1 },
      // angle: 90,
    });
  };

  const confettiCannonBottom = () => {
    // Left
    fire(0.3, {
      spread: 110,
      startVelocity: 55,
      angle: 105,
      origin: { x: 0.4, y: 1.1 },
    });
    // Up
    fire(0.4, {
      spread: 90,
      startVelocity: 42,
      origin: { y: 1.2 },
    });
    // Right
    fire(0.3, {
      spread: 110,
      startVelocity: 55,
      angle: 75,
      origin: { x: 0.6, y: 1.1 },
    });
  };
  /** END UNUSED TYPES */

  switch (confettiType) {
    case "cannon":
      confettiShooting();
      break;
    case "fireworks":
      confettiFireworks();
      break;
    default:
      confettiDefault();
  }
};

export default runConfetti;
