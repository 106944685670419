import clsx from "clsx";
import { useEffect, useState } from "react";
import { formatNumber } from "../../../utils";

export const tailwindColors = ["red", "orange", "amber", "dmyellow", "lime"];

const getOpacity = (
  studentCounts: any,
  allValuesInRow: number[],
  isTotal: boolean
) => {
  const maxSquareRoot = allValuesInRow.reduce(
    (acc: number, val: number) =>
      Math.max(acc ? acc : 0, Math.sqrt(val ? val : 0)),
    0
  );

  let thisCountProportion = Math.sqrt(studentCounts) / maxSquareRoot;
  if (thisCountProportion > 1) {
    thisCountProportion = 1;
  } else if (!isTotal && thisCountProportion >= 1 / 6) {
    thisCountProportion -= 1 / 6; // never make individual rows as opaque as the individual schools/sections/teachers
  }

  if (maxSquareRoot === 0) {
    return "100";
  } else {
    return Math.ceil(6 * thisCountProportion) + "00"; // so 5/6<x<1 goes to "600", 4/6<x<5/6 goes to "500", etc...
  }
};

const getBorder = (value: any, quotient: any, opacity: string) => {
  if (value === "--" || quotient === "--")
    return "rounded-lg border border-b-white";
  let index = Math.floor((value / quotient) * tailwindColors.length);
  if (index > 4) index = index - 1;
  return "border-b-" + tailwindColors[index] + "-" + opacity;
};

export default function DataCard({
  title,
  stats,
  showFluency,
  isTotal,
  isOverall,
  allValuesInRow,
}: {
  title: string;
  stats: { [key: string]: any };
  showFluency: boolean;
  isTotal: boolean;
  isOverall: boolean;
  allValuesInRow: number[];
}) {
  const [border, setBorder] = useState("");

  useEffect(() => {
    const opacity = isOverall
      ? "600"
      : getOpacity(stats["numStudentAssignments"], allValuesInRow, isTotal);
    setBorder(
      clsx(
        showFluency
          ? getBorder(stats["flu"], 20, opacity)
          : getBorder(stats["perc"], 100, opacity),
        "rounded-t-lg border border-b-8 mx-1 mb-2 shadow col-span-1 rounded-lg"
      )
    );
  }, []);

  return (
    <>
      {!border && (
        <>
          <dl className="col-span-1 mx-1 mb-2 rounded-lg rounded-t-lg border border-b-8 border-b-white shadow">
            <div className="py-3">
              <dt className="pl-2 text-left text-lg font-medium">-</dt>
              <dd className="justify-left mt-1">
                <div className="mx-2 text-sm">
                  {" "}
                  <span className="text-lg"> --</span>
                </div>
                <div className="m-2 text-left text-xs">
                  {" "}
                  <span className="text-sm"> --</span>
                </div>
              </dd>
            </div>
          </dl>
        </>
      )}
      {border && (
        <dl className={border}>
          <div key={title} className="py-3">
            <dt className="pl-2 text-left text-lg font-medium">{title}</dt>
            <dd className="justify-left mt-1">
              <>
                <div className="mx-2 text-sm">
                  {showFluency ? (
                    <>
                      {" "}
                      Fluency:{" "}
                      <span className="text-lg">
                        {" "}
                        {stats["flu"] === "--"
                          ? stats["flu"]
                          : `${stats["flu"]}/20`}
                      </span>
                    </>
                  ) : (
                    <>
                      Completion:{" "}
                      <span className="text-lg">
                        {" "}
                        {stats["perc"] === "--"
                          ? stats["perc"]
                          : `${stats["perc"]}%`}
                      </span>
                    </>
                  )}
                </div>
                <div className="m-2 text-left text-xs">
                  # Data Points:{" "}
                  <span className="text-sm">
                    {" "}
                    {isNaN(stats["numStudentAssignments"])
                      ? stats["numStudentAssignments"]
                      : formatNumber(stats["numStudentAssignments"])}
                  </span>
                </div>
              </>
            </dd>
          </div>
        </dl>
      )}
    </>
  );
}
