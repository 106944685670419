import { useState, useEffect } from "react";
import { useParams, NavLink } from "react-router-dom";
import clsx from "clsx";
import { myCoursesUnitDataType } from "../../../types";
import Accordion from "../../Accordion";
import IconAssignmentProgress from "../../IconAssignmentProgress";
import { useCourseContext } from "../../../contexts/CourseContext";
import { UnitNav } from "./UnitNav";
import { useLearnerContext } from "../../../contexts/LearnerContext";
import { REACT_APP_LEARNER_LINK } from "../../../../utils";
import ArchiveCourse from "../ArchiveCourse";

type Props = {
  id: string;
  name: string;
  progress?: number;
  units?: Array<myCoursesUnitDataType>;
};
export default function MyCourses(props: Props): JSX.Element {
  const learnerContext = useLearnerContext();
  const { isSidebarMinimized } = learnerContext;
  const { coursePath } = useParams();
  const [isHover, setIsHover] = useState<boolean>(false);
  const courseContext = useCourseContext();
  const courseData = courseContext.getCourseData(props.id);

  const defaultOpen = coursePath === courseData?.path;
  const [isOpen, setIsOpen] = useState<boolean>(defaultOpen);

  useEffect(() => {
    setIsOpen(defaultOpen);
  }, [defaultOpen]);

  useEffect(() => {
    if (isSidebarMinimized) {
      setIsOpen(false);
    }
  }, [isSidebarMinimized]);

  const isStarted = props.progress !== undefined;
  const chosenIconUrl = isStarted
    ? courseData?.smallIconUrl || courseData?.iconUrl
    : courseData?.greySmallIconUrl ||
      courseData?.smallIconUrl ||
      courseData?.iconUrl;

  // From @Maggie:
  // The parameters we gave the designer, if this is helpful:
  //
  // LARGE: 112px x 112 px square artboard, 70px width icon (height to scale)
  // * SMALL: 32px x 32px square artboard, the icon width within that is 16px (height to scale)
  return (
    <>
      <div className="overflow-y-auto">
        <Accordion
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          onPointerEnter={() => {
            if (!isHover) setIsHover(true);
          }}
          onPointerLeave={() => {
            if (isHover) setIsHover(false);
          }}
          titleButton={
            <>
              <span
                className={clsx(
                  isSidebarMinimized && "flex w-8 justify-center"
                )}
              >
                <IconAssignmentProgress
                  type={"course"}
                  progress={Math.round((props?.progress || 0) * 100)}
                  submitted={props?.progress === 100}
                  isActive={isHover}
                  isStarted={isStarted}
                  iconClasses="w-8 h-8 object-cover"
                  lineWidth={21}
                  iconUrl={chosenIconUrl}
                  name={props.name}
                />
              </span>
              {!isSidebarMinimized && (
                <div
                  className={clsx(
                    "group flex flex-grow items-center justify-between gap-x-1.5 text-left text-sm text-dm-gray-600 group-hover:text-dm-gray-800",
                    defaultOpen && "font-bold"
                  )}
                >
                  <p className="text-sm">{props.name}</p>
                </div>
              )}
            </>
          }
          panel={
            <div className="flex flex-col gap-1">
              <div className="flex flex-row items-center gap-1">
                <NavLink
                  to={`${REACT_APP_LEARNER_LINK}/course/${
                    courseData?.path || props.id
                  }`}
                  className={({ isActive }) =>
                    clsx(
                      isActive ? "!bg-dm-brand-blue-100" : "",
                      "group rounded px-2 py-1 text-sm !leading-none hover:bg-dm-brand-blue-100/50",
                      "flex flex-1 flex-row items-center justify-center gap-x-2"
                    )
                  }
                >
                  {({ isActive }) => (
                    <>
                      <span
                        className={clsx(
                          "shrink-0 basis-[3.3rem] text-xs uppercase group-hover:text-dm-gray-600",
                          isActive ? "text-dm-gray-600" : "text-dm-gray-200"
                        )}
                      >
                        Course:
                      </span>
                      {!isSidebarMinimized && (
                        <span
                          className={clsx(
                            "flex-grow text-sm group-hover:text-dm-gray-800",
                            isActive ? "text-dm-gray-800" : "text-dm-gray-600"
                          )}
                        >
                          Overview
                        </span>
                      )}
                    </>
                  )}
                </NavLink>
                <ArchiveCourse courseId={props.id} />
              </div>
              {!isSidebarMinimized &&
                props?.units?.map(
                  (unit: myCoursesUnitDataType, index: number) => (
                    <UnitNav
                      key={unit.id}
                      unit={unit}
                      defaultOpen={defaultOpen}
                      courseData={courseData}
                      index={index}
                      id={unit.id}
                    />
                  )
                )}
            </div>
          }
        />
      </div>
    </>
  );
}
