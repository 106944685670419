import { addYears, format } from "date-fns";
import {
  FormattedLearnerSubscriptionPlan,
  FormattedPrice,
  LearnerSubscriptionDetails,
} from "../../../types";
import { ProfileField } from "../ProfileField";
import { SkeletonText } from "../../Skeleton";
import axios from "axios";
import { deltamathAPI } from "../../../../manager/utils";
import { withJsonHeader } from "../../../../shared/axiosUtils";
import { useMutation } from "react-query";
import { useDMQuery } from "../../../../utils";
import { formatPrice } from "../../../utils/formatPrice";
import Modal from "../../../../student/components/generic/Modal";
import { useState } from "react";
import { DmLoadingSpinner } from "../../../../manager/utils/functions";
import { PriceCard } from "../../Home/Payment/PriceCard";
import { calculateAnnualPlanDiscount } from "../../Home/Payment/calculateAnnualPlanDiscount";
import { useDeltaToastContext } from "../../../../shared/contexts/ToasterContext";
import { useCancellationContext } from "../Cancellation/CancellationContext";

export const BillingFrequencyField: React.FC<{
  subscription: LearnerSubscriptionDetails | undefined;
}> = ({ subscription }) => {
  const { refetchSubscriptionData } = useCancellationContext();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const toastContext = useDeltaToastContext();

  const {
    refetch: fetchSubscriptionUpgradePreviewData,
    data: upgradePreviewData,
  } = useDMQuery<{
    proratedAmount: FormattedPrice;
    fullAmount: FormattedPrice;
    netAmount: FormattedPrice;
    plans: FormattedLearnerSubscriptionPlan[];
  }>({
    path: "/payments/subscriptions/update/plan/preview",
    queryOptions: { refetchOnWindowFocus: false, enabled: false },
  });

  const { mutateAsync: subscriptionUpgradeMutation } = useMutation(() => {
    return axios.post(
      `${deltamathAPI()}/payments/subscriptions/update/plan`,
      JSON.stringify({}),
      withJsonHeader()
    );
  });

  const proratedAmount = formatPrice(
    upgradePreviewData
      ? {
          ...upgradePreviewData.proratedAmount,
          value: Math.abs(upgradePreviewData.proratedAmount.value),
        }
      : { currency: "USD", value: 0 }
  );
  const netAmount = formatPrice(
    upgradePreviewData
      ? upgradePreviewData.netAmount
      : { currency: "USD", value: 0 }
  );
  const fullAmount = formatPrice(
    upgradePreviewData
      ? upgradePreviewData.fullAmount
      : { currency: "USD", value: 0 }
  );
  const nextAnnualBillingDate = Intl.DateTimeFormat("en-us", {
    dateStyle: "long",
  }).format(addYears(new Date(), 1));

  const monthlyPlan = upgradePreviewData?.plans.find(
    (p) => p.interval === "monthly"
  );
  const annualPlan = upgradePreviewData?.plans.find(
    (p) => p.interval === "annual"
  );

  const confirmUpgrade = async () => {
    setIsLoading(true);
    await subscriptionUpgradeMutation();
    await refetchSubscriptionData();
    setIsLoading(false);
    setShowConfirmation(false);
    toastContext.addToast({
      message: "You've successfully upgraded to an annual subscription!",
      status: "Success",
    });
  };

  return (
    <>
      <ProfileField label="Billing Frequency">
        {subscription ? (
          <>
            <span>
              {subscription.interval === "month" ? "Monthly" : "Yearly"} (billed
              on the {format(new Date(subscription.periodEnd), "do")})
            </span>
            {subscription.interval === "month" &&
              subscription.status === "active" && (
                <button
                  className="text-dm-brand-blue-500 underline-offset-2 hover:underline"
                  onClick={() => {
                    setShowConfirmation(true);
                    fetchSubscriptionUpgradePreviewData();
                  }}
                >
                  Change to yearly
                </button>
              )}
          </>
        ) : (
          <SkeletonText>Loading billing data</SkeletonText>
        )}
      </ProfileField>

      <Modal
        visible={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        closeX
        body={
          <>
            {upgradePreviewData && monthlyPlan && annualPlan ? (
              <div className="flex flex-col gap-10 pt-10 sm:pt-0">
                <header className="flex flex-col gap-2 text-center">
                  <h2 className="font-serif text-2xl font-bold text-dm-brand-blue-600">
                    Billing Frequency
                  </h2>
                  <p>Upgrade to Annual & Save!</p>
                </header>
                <div className="flex flex-col items-center gap-4 sm:flex-row">
                  <div className="w-72 max-w-full flex-none">
                    <PriceCard
                      title="Annual"
                      type="year"
                      plan={annualPlan}
                      discount={calculateAnnualPlanDiscount(
                        monthlyPlan,
                        annualPlan
                      )}
                      displayOnly
                    />
                  </div>
                  <p>
                    Switching to an annual plan costs {netAmount} today, with a
                    credit for your remaining monthly subscription. You&apos;ll
                    then pay {fullAmount} on {nextAnnualBillingDate}.
                  </p>
                </div>
              </div>
            ) : (
              <div className="flex h-48 w-full items-center justify-center">
                <DmLoadingSpinner message="" />
              </div>
            )}
          </>
        }
        confirmButtonText="Update to Annual Plan"
        onConfirm={confirmUpgrade}
        confirmDisabled={isLoading}
        secondaryButtonText="Keep Monthly Billing Frequency"
        secondaryOnClick={() => setShowConfirmation(false)}
      />
    </>
  );
};
