import { SectionsIcon } from "../../../shared/icons/SectionsIcon";
import { useLearnerContext } from "../../contexts/LearnerContext";
import { Progress, SubunitProgress, UnitProgress } from "../../types";
import { DashboardCard } from "./DashboardCard";

function calculateCompletedSections(progresses: Progress[]): number {
  let total = 0;
  progresses.forEach((progress: Progress) => {
    progress.units.forEach((unit: UnitProgress) => {
      unit.subunits.forEach((subunit: SubunitProgress) => {
        if (subunit.progress === 1) total += 1;
      });
    });
  });
  return total;
}

export const SectionsCompletedCard: React.FC = () => {
  const learnerContext = useLearnerContext();
  const sections = calculateCompletedSections(learnerContext.state.progresses);

  return (
    <DashboardCard title="Sections Completed" icon={<SectionsIcon />}>
      <h4 className="mb-2 mt-4 font-serif text-3xl font-bold">
        {sections} sections
      </h4>
      <p className="mb-1 text-xs text-gray-500">
        Total sections completed so far.
      </p>
    </DashboardCard>
  );
};
