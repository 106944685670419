import { useState } from "react";
import { Category, Skill } from "./SkillCodes";
import ExampleModal from "../../../learner/components/Modals/ExampleModal";
import { FocusContextProvider } from "../../../learner/contexts/FocusContext";
import { renderA11yString } from "../../../student/utils/render-a11y-string";
import SkillDisplay from "./SkillDisplay";

type Props = {
  category: Category;
  color: string;
  year: string;
  skills: Skill[];
  filteredSkills?: Skill[];
  onOpen: () => void;
  onClose: () => void;
  opened?: boolean;
  skillAdd?: (name: string, skillCode: string) => void;
};

const CategoryDisplay = (props: Props) => {
  const [selectedSkillCode, setSelectedSkillCode] = useState<
    string | undefined
  >(undefined);

  const [showExample, setShowExample] = useState<boolean>(false);

  const skillClick = (sk?: string) => {
    if (sk) {
      setSelectedSkillCode(sk);
      setShowExample(true);
    }
  };

  if (!window.renderA11yString) {
    // Not ideal but is used in dmGraph so we need it on the window
    window.renderA11yString = renderA11yString;
  }

  return (
    <>
      <div className="w-[calc(100%*(1/3)-17px*2/3)]">
        <div
          style={{ backgroundColor: props.color }}
          className="rounded-md"
          key={props.category.code}
        >
          <div
            className="flex cursor-pointer items-center gap-4 p-3"
            onClick={props.opened ? props.onClose : props.onOpen}
          >
            <span
              className={`flex h-8 w-8 items-center justify-center rounded-full bg-white bg-opacity-20 text-sm text-white`}
            >
              {props.year}
            </span>
            <h4 className="text-base font-bold text-white">
              {props.category.name}
            </h4>
          </div>
        </div>
        <div>
          {props.opened ? (
            <div className="display mt-4 flex flex-col gap-4">
              {props.skills.map((skill, i) => {
                return (
                  <SkillDisplay
                    key={`${props.category.code}${i}`}
                    k={`${props.category.code}${i}`}
                    skill={skill}
                    skillAdd={props.skillAdd}
                    openExampleModal={skillClick}
                  />
                );
              })}
            </div>
          ) : props.filteredSkills && props.filteredSkills.length > 0 ? (
            <div className="display mt-4 flex flex-col gap-4">
              {props.filteredSkills.map((skill, i) => {
                return (
                  <SkillDisplay
                    key={`${props.category.code}${i}`}
                    k={`${props.category.code}${i}`}
                    skill={skill}
                    skillAdd={props.skillAdd}
                    openExampleModal={skillClick}
                  />
                );
              })}
            </div>
          ) : (
            <></>
          )}
        </div>
        <FocusContextProvider>
          <ExampleModal
            skillCode={selectedSkillCode ?? ""}
            visible={showExample}
            onClose={() => setShowExample(false)}
            toggleQuestion
            noAnalytics
          />
        </FocusContextProvider>
      </div>
    </>
  );
};

export default CategoryDisplay;
