import { generatePascalsTriangle } from "./generatePascalsTriangle";
import { PascalsTriangleRow } from "../types";

/**
 * Calculates the points required to complete the provided Pascal's Triangle
 * row and compares with the provided pointsAvailable
 */
export function isPascalsRowCompletable(
  row: PascalsTriangleRow,
  pointsAvailable: number
): boolean {
  const lastRow = generatePascalsTriangle(row).pop();
  if (!lastRow) {
    return false;
  }
  const pointsRequired = lastRow.reduce((acc, [actual, expected]) => {
    if (actual === null) {
      return acc + expected;
    }
    return acc;
  }, 0);

  return pointsRequired === 0 || pointsAvailable >= pointsRequired;
}
