import { Listbox, Transition } from "@headlessui/react";
import clsx from "clsx";
import { Fragment } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GridSquareIcon } from "../../../shared/icons/GridSquareIcon";
import { REACT_APP_LEARNER_LINK } from "../../../utils";
import { Learner } from "../../types";
import Chevron from "../Chevron";

export const InsightsDropdown: React.FC<{
  learners: Learner[];
  currentLearner: Learner;
  updateLearner: (learner: Learner) => void;
}> = ({ learners, currentLearner, updateLearner }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isDashboardActive = pathname === `${REACT_APP_LEARNER_LINK}/parent`;

  const handleChange = (learner: Learner) => {
    updateLearner(learner);
    navigate(`${REACT_APP_LEARNER_LINK}/parent`);
  };

  return (
    <Listbox value={currentLearner} onChange={handleChange}>
      {({ open }) => (
        <div className="relative w-48">
          <Listbox.Button
            className={clsx(
              "relative w-full cursor-default rounded-[4px] border py-2 pl-3 pr-7 text-left text-[14px] leading-[26px] text-dm-gray-800 focus:outline-none",
              isDashboardActive
                ? "border-dm-brand-blue-100 bg-dm-brand-blue-100 font-bold hover:border-dm-brand-blue-200"
                : "border-transparent"
            )}
          >
            <span className="flex items-center gap-2 truncate">
              <span
                className={clsx(
                  isDashboardActive ? "text-dm-gray-800" : "text-dm-gray-200"
                )}
                aria-hidden
              >
                <GridSquareIcon />
              </span>
              {currentLearner.first}&apos;s Insights
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <Chevron open={open} />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white p-1 text-sm shadow-lg ring-1 ring-black/5 focus:outline-none">
              {learners.map((learner) => (
                <Listbox.Option
                  key={learner._id}
                  className={({ active, selected }) =>
                    clsx(
                      "relative cursor-default select-none rounded-md p-2 text-dm-gray-800",
                      active ? "bg-dm-brand-blue-100" : "",
                      selected ? "font-bold" : ""
                    )
                  }
                  value={learner}
                >
                  {learner.first}&apos;s Insights
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
};
