import { useState } from "react";
import { ContentPrize } from "../../types";
import { DashboardCard } from "../Dashboard/DashboardCard";
import { ContentPrizeModal } from "../Modals/Prizes/ContentPrizeModal";
import { FactorBubblesIcon } from "./FactorBubblesIcon";
import { SnakeIcon } from "./SnakeIcon";
import { ContentPrizeButton } from "./Prizes/ContentPrizeButton";
import clsx from "clsx";
import { useLearnerContext } from "../../contexts/LearnerContext";

export const GameCard: React.FC<{
  prize: ContentPrize;
  layout: "narrow" | "wide";
}> = ({ prize, layout }) => {
  const [showContentPrizeModal, setShowContentPrizeModal] =
    useState<boolean>(false);
  const learnerContext = useLearnerContext();

  const data =
    prize.key === "factorBubblesGame"
      ? {
          title: "Bubble Blast Game",
          Icon: FactorBubblesIcon,
          cta: "Play Bubble Blast Game",
        }
      : {
          title: prize.name,
          Icon: SnakeIcon,
          cta: `Play Snake Game`,
        };

  return (
    <DashboardCard title={data.title} icon={<data.Icon />}>
      <div
        className={clsx(
          "mt-8 flex w-full",
          layout === "wide" && "justify-end md:-mt-4"
        )}
      >
        <div
          className={clsx(
            "flex w-full flex-col items-center gap-5 md:items-end",
            layout !== "wide" && "justify-between md:flex-row"
          )}
        >
          <div
            className={clsx(
              "flex-none overflow-hidden rounded-lg",
              layout !== "wide" && "w-[90px]"
            )}
          >
            {prize.image}
          </div>

          <ContentPrizeButton
            className=""
            setShowGame={setShowContentPrizeModal}
            isPrizeUnlocked={true}
            prize={prize}
            title={data.cta}
          />
        </div>
      </div>

      <ContentPrizeModal
        visible={showContentPrizeModal}
        onClose={() => {
          setShowContentPrizeModal(false);
          learnerContext.refetchLearner();
        }}
        prize={prize}
        showContent={true}
      />
    </DashboardCard>
  );
};
