import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DeltaMathTable from "../../shared/DeltaMathTable";
import { useDMQuery, formatNumber, REACT_APP_ADMIN_LINK } from "../../utils";
import { DemoMode } from "../../utils/demo";
import { getAdminParams } from "../utils";

export default function Index() {
  const adminParams = getAdminParams();
  const navigate = useNavigate();
  const [schoolCountsData, setSchoolCountsData] = useState<any>([]);
  const {
    data: adminsData,
    isLoading: adminsDataLoading,
    isSuccess: adminsDataSuccess,
  } = useDMQuery({
    path: "/admin_new/data/admins",
    params: adminParams,
    queryOptions: {
      staleTime: 1000 * 60 * 15,
      refetchOnWindowFocus: false,
    },
  });

  const { data: districtData, refetch: districtDataRefetch } = useDMQuery({
    path: "/admin_new/data/district",
    queryOptions: {
      staleTime: 1000 * 60 * 15,
      refetchOnWindowFocus: false,
      enabled: false, // We only want to make this request if we're a district admin, we check that in a useEffect below
    },
  });

  const {
    data: teacherData,
    isLoading: teacherDataLoading,
    isSuccess: teacherDataSuccess,
  } = useDMQuery({
    path: "/admin_new/data/teacher",
    queryOptions: {
      staleTime: 1000 * 60 * 15,
      refetchOnWindowFocus: false,
    },
  });

  const {
    data: schoolData,
    isLoading: schoolDataLoading,
    isSuccess: schoolDataSuccess,
  } = useDMQuery({
    path: "/admin_new/data/school",
    queryOptions: {
      staleTime: 1000 * 60 * 15,
      refetchOnWindowFocus: false,
    },
  });

  const {
    data: teacherCountsData,
    isLoading: teacherCountsDataLoading,
    isSuccess: teacherCountsDataSuccess,
  } = useDMQuery({
    path: "/admin_new/data/teacherCounts",
    queryOptions: {
      staleTime: 1000 * 60 * 15,
      refetchOnWindowFocus: false,
    },
  });

  useEffect(() => {
    // There is only one school, just redirect there
    if (schoolData && schoolData?.length === 1) {
      navigate(`${REACT_APP_ADMIN_LINK}/schools/${schoolData[0]?.schoolid}`, {
        replace: true,
      });
    }
  }, [schoolData]);

  useEffect(() => {
    if (adminsData) {
      const user = JSON.parse(localStorage.getItem("user") || "{}");
      const id = user.admin ? user.admin : user._id;
      const findCurrentUser = adminsData.filter(
        (user: { _id: string }) => user._id === id
      );
      if (findCurrentUser && findCurrentUser.length > 0) {
        districtDataRefetch();
      }
    }
  }, [adminsData]);

  useEffect(() => {
    if (schoolData && Array.isArray(schoolData) && teacherCountsData) {
      const theSchoolCounts:
        | ((prevState: never[]) => never[])
        | {
            schoolid: string;
            schoolName: string;
            theSchoolAnnualProblemsCount: number;
            theSchoolAssignmentsCount: number;
            theSchoolStudentsCount: number;
            theSchoolTeachersCount: number;
            theSchoolAdminsCount: number;
          }[] = [];
      schoolData.map((school: { schoolid: string; schoolName: string }) => {
        let theSchoolStudentsCount = 0;
        let theSchoolAssignmentsCount = 0;
        let theSchoolAnnualProblemsCount = 0;
        let theSchoolTeachersCount = 0;
        let theSchoolAdminsCount = 0;

        if (Array.isArray(adminsData) && adminsData.length > 0) {
          adminsData.map(
            (admin: {
              schoolIDs: string[];
              account_type: string;
              email: string;
            }) => {
              if (admin?.schoolIDs?.includes(school.schoolid)) {
                if (
                  admin.account_type === "school" ||
                  admin.account_type === "super_school"
                )
                  theSchoolAdminsCount++;
              }
            }
          );
        }

        if (Array.isArray(teacherData) && teacherData.length > 0) {
          teacherData.map(
            (teacher: {
              schoolid: string;
              schoolPlus: string[];
              _id: string;
              admin: string;
              annual_logs: number;
            }) => {
              if (teacher.schoolPlus.includes(school.schoolid)) {
                const studentCount = getStudentCount(parseInt(teacher._id));
                if (!teacher.admin || (teacher.admin && studentCount > 0)) {
                  theSchoolTeachersCount++;
                }
                theSchoolAnnualProblemsCount += teacher.annual_logs;
                theSchoolStudentsCount += studentCount;
                theSchoolAssignmentsCount += getAssignmentsCount(
                  parseInt(teacher._id)
                );
              }
            }
          );
        }

        theSchoolCounts.push({
          schoolid: school.schoolid,
          schoolName: school.schoolName,
          theSchoolAnnualProblemsCount,
          theSchoolAssignmentsCount,
          theSchoolStudentsCount,
          theSchoolTeachersCount,
          theSchoolAdminsCount,
        });
      });

      const sortedResults = theSchoolCounts.sort(function (
        a: { schoolName: string },
        b: { schoolName: string }
      ) {
        const textA = a.schoolName.toUpperCase();
        const textB = b.schoolName.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
      setSchoolCountsData(sortedResults);
    }
  }, [schoolData, teacherCountsData, adminsData, teacherData]);

  function getStudentCount(teacher_id: number) {
    return teacherCountsData &&
      teacherCountsData[teacher_id] &&
      typeof teacherCountsData[teacher_id].students != "undefined"
      ? parseInt(teacherCountsData[teacher_id].students)
      : 0;
  }

  function getAssignmentsCount(teacher_id: number) {
    return teacherCountsData &&
      teacherCountsData[teacher_id] &&
      typeof teacherCountsData[teacher_id].assignments != "undefined"
      ? parseInt(teacherCountsData[teacher_id].assignments)
      : 0;
  }

  const teachersTableColumns = [
    {
      Header: "School",
      accessor: "schoolName",
      align: "left",
      Cell: (props: any) => {
        return (
          <Link
            className="text-dm-brand-blue-500 hover:underline"
            to={`${REACT_APP_ADMIN_LINK}/schools/${props.cell.row.original.schoolid}`}
          >
            <DemoMode
              value={props.value}
              type="school_name"
              district_size={schoolData.length}
            />
          </Link>
        );
      },
    },
    {
      Header: "Annual Problems",
      accessor: "theSchoolAnnualProblemsCount",
      Cell: (props: any) => {
        return <div>{formatNumber(props.value)}</div>;
      },
      align: "left",
      width: "180px",
      sortDescFirst: true,
    },
    {
      Header: "Admins",
      accessor: "theSchoolAdminsCount",
      Cell: (props: any) => {
        return <div>{formatNumber(props.value)}</div>;
      },
      align: "left",
      sortDescFirst: true,
    },
    {
      Header: "Teachers",
      accessor: "theSchoolTeachersCount",
      Cell: (props: any) => {
        return <div>{formatNumber(props.value)}</div>;
      },
      align: "left",
      sortDescFirst: true,
    },
    {
      Header: "Students",
      accessor: "theSchoolStudentsCount",
      Cell: (props: any) => {
        return <div>{formatNumber(props.value)}</div>;
      },
      align: "left",
      sortDescFirst: true,
    },
    {
      Header: "Assignments",
      accessor: "theSchoolAssignmentsCount",
      Cell: (props: any) => {
        return <div>{formatNumber(props.value)}</div>;
      },
      align: "left",
      sortDescFirst: true,
    },
  ];

  return (
    <>
      {districtData?.districtName && (
        <div className="border border-b-2 bg-white">
          <h2 className="px-6 py-8 font-serif text-2xl font-bold">
            <DemoMode value={districtData?.districtName} type="city" />
          </h2>
        </div>
      )}

      {/* <p className="mt-1 text-sm text-gray-500"></p> */}
      <div className="p-6">
        {(schoolDataLoading ||
          teacherCountsDataLoading ||
          adminsDataLoading ||
          teacherDataLoading) && <p>Loading...</p>}

        {teacherData && teacherData.length === 0 && (
          <div className="flow-root">
            <p className="mb-2 px-2 text-lg text-dm-brand-blue-500">
              Select a School to Invite Teachers
            </p>
          </div>
        )}

        {teacherDataSuccess &&
          schoolDataSuccess &&
          adminsDataSuccess &&
          teacherCountsDataSuccess &&
          schoolCountsData &&
          schoolCountsData.length > 0 && (
            <div className="flow-root rounded-md border">
              <DeltaMathTable
                columns={teachersTableColumns}
                data={schoolCountsData}
                options={{
                  initialState: {
                    sortBy: [
                      {
                        id: "schoolName",
                        desc: false,
                      },
                    ],
                  },
                }}
              />
            </div>
          )}
      </div>
    </>
  );
}
