import { FormattedPrice } from "../types";

/**
 * Return a FormattedPrice as a string for display. This is mostly a shorter
 * wrapper for Intl.NumberFormat
 */
export function formatPrice(price: FormattedPrice): string {
  return Intl.NumberFormat("en-us", {
    style: "currency",
    currency: price.currency,
  }).format(price.value);
}
