import { NavLink } from "react-router-dom";
import Button from "../../../../student/components/generic/button";
import { getFilePath, REACT_APP_LEARNER_LINK } from "../../../../utils";
import { ResponsiveImage } from "../../ResponsiveImage";

export const AddLearnersIntro: React.FC<{
  numLearners: number;
  isTrialing: boolean;
  next: () => void;
}> = ({ numLearners, isTrialing, next }) => {
  const activatedText = isTrialing
    ? "Your 7-day free trial is activated!"
    : "You account is activated!";
  const actionText =
    numLearners === 1
      ? "Let's link your learner's account so they can start exploring."
      : "Let's link your learners' accounts so they can start exploring.";
  return (
    <div className="flex flex-1 flex-col px-4 text-center sm:w-[424px]">
      <div className="mb-6 flex justify-center">
        <ResponsiveImage
          srcs={[
            getFilePath("/images/learner/parent/deltie-light-bulb.png"),
            getFilePath("/images/learner/parent/deltie-light-bulb@2x.png"),
          ]}
          alt="Deltie has an idea"
          aria-hidden
        />
      </div>
      <h1 className="mb-2 font-serif text-lg font-bold text-dm-brand-blue-600">
        Welcome To DeltaMath for Home
      </h1>
      <p className="mb-8 sm:mb-10">
        {activatedText} {actionText}
      </p>
      <Button className="mb-4 w-full text-sm" onClick={() => next()}>
        {numLearners === 1 ? "Add learner" : "Add learners"}
        <i className="far fa-arrow-right ml-2" />
      </Button>
      <NavLink
        className="text-dm-brand-blue-500 underline-offset-2 hover:underline"
        to={`${REACT_APP_LEARNER_LINK}/parent/profile`}
      >
        Skip for now
      </NavLink>
    </div>
  );
};
