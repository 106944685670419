import { LearnerAssignment, ResultHistoryType } from "../../../types";
import { concat } from "lodash";
import PastAttempt from "./PastAttempt";

type Props = {
  assignment: LearnerAssignment;
  baseUrl: string;
};

const PastAttemptsNav = (props: Props) => {
  const lastAttempt: ResultHistoryType = {
    grade: Math.round(props.assignment.grade * 100),
    skills: props.assignment.skills,
    submitted: props.assignment.submitted,
    created: props.assignment.createdAt,
    _id: props.assignment._id,
    questionSwap: props.assignment.questionSwap,
  };

  const assignmentSkills: Array<ResultHistoryType> = concat(
    props.assignment.resultHist
      ? props.assignment.resultHist.map((attempt, i) => {
          const grade = Math.round(attempt.grade * 100);
          return {
            ...attempt,
            grade,
          };
        })
      : [],
    lastAttempt
  );

  return (
    <>
      {assignmentSkills.map((attempt: ResultHistoryType, i: number) => {
        return (
          <PastAttempt
            key={`PastAttempt-${attempt._id}`}
            _id={attempt._id}
            type={props.assignment.type}
            grade={Number(attempt.grade)}
            skills={attempt.skills}
            submitted={attempt?.submitted}
            created={attempt.created}
            baseUrl={props.baseUrl}
            lastAttempt={i === assignmentSkills.length - 1}
            questionSwap={attempt.questionSwap}
          />
        );
      })}
    </>
  );
};

export default PastAttemptsNav;
