import React from "react";
import { CheckIcon, XIcon } from "@heroicons/react/solid";
import clsx from "clsx";
import { TLicenseOption } from "../../utils/quoteUtils";
import { formatNumber } from "../../../utils";
import { UseMutateFunction } from "react-query";

// Define TypeScript interfaces for props
interface Feature {
  text: string;
  plus: boolean;
  integral: boolean;
}

interface LicenseQuoteCardProps {
  license: TLicenseOption;
  upgradeStatus: "upgrade" | "current" | "lateral";
  entity: "school" | "district" | "individual" | "";
  recommended?: boolean;
  setShowUpgradeForm: React.Dispatch<React.SetStateAction<boolean>>;

  span: number;
  setSelectedUpgrade: React.Dispatch<
    React.SetStateAction<TLicenseOption | undefined>
  >;
  downloadPDF: UseMutateFunction<
    { responseData: any; responseHeaders: any },
    unknown,
    Record<string, unknown>,
    unknown
  >;
  setQuoteStatus: React.Dispatch<
    React.SetStateAction<
      "" | "loading" | "pdf" | "json" | "error" | "renewingFromTrial"
    >
  >;
  quoteStatus: "" | "loading" | "pdf" | "json" | "error" | "renewingFromTrial";
  integralTrial: boolean;
}

const plusIntegralFeatures: Feature[] = [
  {
    text: "Instructional Videos for Every Problem Type",
    plus: true,
    integral: true,
  },
  { text: "Automated Test Corrections", plus: true, integral: true },
  { text: "Selection of Problem Sub-Types", plus: true, integral: true },
  { text: "Create Your Own Problem", plus: true, integral: true },
  { text: "Google Classroom Integration", plus: true, integral: true },
  {
    text: "Clever, ClassLink, Canvas, or Schoology Integration",
    plus: false,
    integral: true,
  },
  { text: "Usage Reports", plus: false, integral: true },
  { text: "Print Assignments/Assessments to PDF", plus: false, integral: true },
  { text: "Upload Student Work/Notes", plus: false, integral: true },
  {
    text: "Push Assignments to Teachers and Sections (i.e. diagnostics)",
    plus: false,
    integral: true,
  },
];

const LicenseQuoteCard: React.FC<LicenseQuoteCardProps> = ({
  license,
  upgradeStatus,
  recommended,
  setShowUpgradeForm,
  span,
  setSelectedUpgrade,
  downloadPDF,
  setQuoteStatus,
  quoteStatus,
  integralTrial,
  entity,
}) => {
  const { type, tier, price, pathId } = license;

  const SCHOOLYEAR = "24-25";

  const handleCardClick = () => {
    if (integralTrial && upgradeStatus === "current") {
      setQuoteStatus("renewingFromTrial");
    }

    setShowUpgradeForm(true);
    setSelectedUpgrade(license);

    if (
      tier !== "Integral" &&
      quoteStatus !== "loading" &&
      !(integralTrial && upgradeStatus === "current")
    ) {
      const requestBody = {
        type,
        licenseTier: "Plus",
        rosteringSystem: "",
        learningManagementSystem: "",
        pathId,
      };

      setQuoteStatus("loading");
      downloadPDF(requestBody);
    }
  };

  let buttonText = "Download Quote for";
  if (!integralTrial) {
    buttonText +=
      upgradeStatus === "current" && entity !== "individual"
        ? " Current Plan"
        : " Upgraded Plan";
  } else {
    buttonText += tier === "Integral" ? " INTEGRAL" : " PLUS";
  }

  return (
    <div
      className={clsx(
        "relative",
        `m-${span === 1 ? "4" : "8"}`,
        `col-span-${span}`
      )}
    >
      {recommended && (
        <div className="absolute left-1/2 top-0 w-1/3 min-w-[150px] -translate-x-1/2 -translate-y-1/2 transform rounded-full bg-dm-brand-blue-500 px-3 py-1 text-center text-white">
          RECOMMENDED
        </div>
      )}
      <div className="card-container mx-auto my-0 max-w-[1500px]">
        <div
          className={clsx(
            "flex-col rounded-xl border border-gray-300 font-sans shadow-xl lg:flex lg:max-w-full",
            tier === "Integral" ? "bg-dm-brand-blue-100" : "bg-white"
          )}
          style={{ borderColor: "#E3E8EF" }}
        >
          <div className="blue m-2 flex flex-col justify-between rounded-b p-4 leading-normal lg:rounded-b-none lg:rounded-r">
            <div className="mb-8">
              <div className="mb-2 min-h-12 text-gray-900 sm:text-2xl">
                <strong className="mr-4">
                  {upgradeStatus === "current" && entity !== "individual"
                    ? "Current"
                    : upgradeStatus === "upgrade" || entity === "individual"
                    ? "Upgraded"
                    : ""}{" "}
                  License{" "}
                </strong>
                {type}
                {upgradeStatus === "current" && integralTrial && (
                  <div className="text-sm">
                    You currently have INTEGRAL features on a trial basis only.
                  </div>
                )}
              </div>
              <p
                className={clsx(
                  "max-w-xs rounded-lg p-3 text-base text-gray-700",
                  tier === "Plus"
                    ? "bg-dm-brand-blue-200"
                    : "bg-dm-earth-yellow-500"
                )}
              >
                <strong>Delta</strong>Math {tier.toUpperCase()} ({SCHOOLYEAR})
              </p>
            </div>
            <div className="text-bold sm:text-xl">${formatNumber(price)}</div>
          </div>
          <div className="blue m-2 -mt-2 flex flex-col justify-between rounded-b p-4 leading-normal lg:rounded-b-none lg:rounded-r">
            <div className="border-b-2 border-dotted border-gray-400"></div>
            <ul className={`mb-2 list-none ${span === 2 ? "columns-2" : ""}`}>
              {plusIntegralFeatures.map((feature, index) => (
                <li
                  key={index}
                  className={`m-2 flex items-center text-xs sm:text-lg ${
                    tier === "Plus" && !feature.plus
                      ? "text-gray-400 line-through"
                      : ""
                  }`}
                >
                  {tier === "Integral" || (tier === "Plus" && feature.plus) ? (
                    <CheckIcon className="mr-3 h-5 w-5 flex-shrink-0 text-dm-brand-blue-500" />
                  ) : (
                    <XIcon className="mr-3 h-5 w-5 flex-shrink-0 text-gray-400" />
                  )}
                  {feature.text}
                </li>
              ))}
            </ul>

            <div className="mt-4 flex items-center justify-center">
              <button
                className={clsx(
                  `focus:shadow-outline rounded px-4 py-2 font-bold focus:outline-none`,
                  tier === "Integral"
                    ? "bg-dm-brand-blue-500 text-white"
                    : "border border-black bg-white text-black",
                  span === 1 ? "w-full" : "w-full md:w-2/5"
                )}
                onClick={handleCardClick}
              >
                {buttonText}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LicenseQuoteCard;
