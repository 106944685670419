import { processChoices } from "./snakeGameProblemGenerator";

const { rand, $ } = window;

/** Find double, triple or half of a number
 * @return problem object with the following properties: questionPrompt (string with html), question?  (string), choices (array of 4 strings representing answer choices with the correct answer always at index 0), solution function to run
 */
export function doubleTripleHalf() {
  const types = ["double", "triple", "half"];
  const type = types[rand(0, types.length - 1)];

  switch (type) {
    case "double":
      return double();
    case "triple":
      return triple();
    case "half":
      return half();
    default:
      break;
  }
}

/** Find double a number
 * @return problem object with the following properties: questionPrompt (string with html), question? (string), choices (array of 4 strings representing answer choices with the correct answer always at index 0), solution function to run
 */
function double() {
  const types = ["single digit", "multiple of ten", "multiple of 100"];
  const type = types[rand(0, types.length - 1)];

  let value, correctAnswer;
  let offset1 = 1;
  let offset2 = 2;
  let caseSpecificDistractors = [];

  switch (type) {
    case "single digit":
      value = rand(1, 9);
      correctAnswer = value * 2;
      caseSpecificDistractors.push(Math.pow(value, 2));
      break;
    case "multiple of ten":
      value = rand(1, 9) * 10;
      correctAnswer = value * 2;
      if (correctAnswer * 10 < 1000)
        caseSpecificDistractors.push(correctAnswer * 10);
      offset1 *= 10;
      offset2 *= 10;
      break;
    case "multiple of 100":
      value = rand(1, 4) * 100;
      correctAnswer = value * 2;
      offset1 *= 100;
      offset2 *= 100;
      break;
    default:
      break;
  }

  // distractor choices
  let choices = [
    ...caseSpecificDistractors,
    correctAnswer + offset1,
    correctAnswer - offset1,
    value / 2,
  ];
  // add extra distractor choices conditionally
  if (correctAnswer + offset2 < 1000) choices.push(correctAnswer + offset2);
  if (correctAnswer - offset2 >= 0) choices.push(correctAnswer - offset2);

  choices = processChoices(choices, correctAnswer);

  const solution = (id) => {
    $(id).html(`\\(${value}+${value}=2*${value}=${correctAnswer}\\)`);
  };

  return {
    questionPrompt: `What is double \\(${value}\\)?`,
    choices,
    solution,
  };
}

/** Find triple a number
 * @return problem object with the following properties: questionPrompt (string with html), question? (string), choices (array of 4 strings representing answer choices with the correct answer always at index 0), solution function to run
 */
function triple() {
  const types = ["single digit", "multiple of ten"];
  const type = types[rand(0, types.length - 1)];

  let value;
  let offsets = [1, 2, 3];

  switch (type) {
    case "single digit":
      value = rand(1, 9);
      break;
    case "multiple of ten":
      value = rand(1, 9) * 10;
      offsets = offsets.map((offset) => offset * 10);
      break;
    default:
      break;
  }

  const correctAnswer = value * 3;

  // distractor choices
  let choices = [
    correctAnswer + offsets[0],
    correctAnswer - offsets[0],
    correctAnswer + offsets[1],
    correctAnswer + offsets[2],
    value * 2,
  ];
  // add extra distractor choices conditionally
  if (correctAnswer - offsets[1] >= 0) choices.push(correctAnswer - offsets[1]);
  if (correctAnswer - offsets[2] >= 0) choices.push(correctAnswer - offsets[2]);

  choices = processChoices(choices, correctAnswer);

  const solution = (id) => {
    $(id).html(`\\(${value}+${value}+${value}=3*${value}=${correctAnswer}\\)`);
  };

  return {
    questionPrompt: `What is triple \\(${value}\\)?`,
    choices,
    solution,
  };
}

/** Find half a number
 * @return problem object with the following properties: questionPrompt (string with html), question? (string), choices (array of 4 strings representing answer choices with the correct answer always at index 0), solution function to run
 */
function half() {
  const types = ["single digit", "multiple of ten", "multiple of 100"];
  const type = types[rand(0, types.length - 1)];

  let value;
  let offsets = [0.5, 1];

  switch (type) {
    case "single digit":
      value = rand(1, 9);
      break;
    case "multiple of ten":
      value = rand(1, 9) * 10;
      offsets = offsets.map((offset) => offset * 10);
      break;
    case "multiple of 100":
      value = rand(1, 9) * 100;
      offsets = offsets.map((offset) => offset * 100);
      break;
    default:
      break;
  }

  const correctAnswer = value / 2;

  // distractor choices
  let choices = [
    correctAnswer + offsets[0],
    correctAnswer - offsets[0],
    correctAnswer + offsets[1],
  ];
  // add extra distractor choices conditionally
  if (correctAnswer - offsets[1] >= 0) choices.push(correctAnswer - offsets[1]);
  if (value * 2 < 1000) choices.push(value * 2);

  choices = processChoices(choices, correctAnswer);

  const solution = (id) => {
    $(id).html(`\\(\\frac{${value}}{2}=${correctAnswer}\\)`);
  };

  return {
    questionPrompt: `What is half of \\(${value}\\)?`,
    choices,
    solution,
  };
}
