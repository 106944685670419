import { Menu, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { QueryClient } from "react-query";
import { useUserContext } from "./contexts/UserContext";
import { REACT_APP_HOMEPAGE_LINK } from "../utils";

export default function AppSwitcher(props: {
  loggedIn: boolean;
  setLoggedIn: React.Dispatch<React.SetStateAction<boolean | undefined>>;
}) {
  const queryClient = new QueryClient();
  const userContext = useUserContext();
  const { loggedIn, setLoggedIn } = props;
  return (
    <>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute left-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          {loggedIn && (
            <Menu.Item>
              <a
                className="block px-4 py-2 text-sm text-gray-700"
                href="#"
                onClick={() => {
                  queryClient.invalidateQueries();
                  queryClient.removeQueries();
                  userContext.clearJWT();
                  localStorage.removeItem("admin");
                  localStorage.removeItem("user");
                  window.location.href = `${REACT_APP_HOMEPAGE_LINK}`;
                  setLoggedIn(false);
                }}
              >
                Logout
              </a>
            </Menu.Item>
          )}
        </Menu.Items>
      </Transition>
    </>
  );
}
