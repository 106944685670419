/** Get the short ordinal (1st, 2nd, 3rd, etc) for a number */
export function shortOrdinal(n: number): string | undefined {
  if (n <= 0) {
    return undefined;
  }
  switch (n % 10) {
    case 1:
      return `${n}st`;
    case 2:
      return `${n}nd`;
    case 3:
      return `${n}rd`;
    default:
      return `${n}th`;
  }
}
