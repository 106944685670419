import clsx from "clsx";
import { HTMLAttributes } from "react";

interface StepIndicatorProps extends HTMLAttributes<HTMLDivElement> {
  totalSteps: number;
  currentStep: number;
}

/**
 * Generic step indicator, will show one dot for each step with active step highlighted.
 * Note: this is typically used with `useStep` which uses 1-based indexing
 */
export const StepIndicator: React.FC<StepIndicatorProps> = ({
  totalSteps,
  currentStep,
  ...props
}) => (
  <div {...props}>
    <ol className="flex w-full items-center justify-center gap-2">
      {Array(totalSteps)
        .fill(null)
        .map((_, i) => {
          const isActive = i + 1 === currentStep;
          return (
            <li
              key={i}
              className={clsx(
                "rounded-full transition-all",
                isActive
                  ? "h-3 w-3 bg-dm-brand-blue-500"
                  : "h-2 w-2 bg-dm-brand-blue-200"
              )}
              aria-current={isActive}
            />
          );
        })}
    </ol>
  </div>
);
