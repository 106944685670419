import { processChoices } from "./snakeGameProblemGenerator";

const { rand, $ } = window;

/** Evaluate expression using the correct order of operations
 * @return problem object with the following properties: questionPrompt (string with html), question?  (string), choices (array of 4 strings representing answer choices with the correct answer always at index 0), solution function to run
 */
export function orderOfOperations() {
  // TODO: divSub (although I'm not sure there will be enough problems -- tricky to generate numbers that result in positive integers for all distractor answers)
  const types = ["addMult", "addDiv", "multSub"];
  const type = types[rand(0, types.length - 1)];

  let question, choices, solution;

  switch (type) {
    case "addMult":
      ({ question, choices, solution } = addMult());
      break;
    case "addDiv":
      ({ question, choices, solution } = addDiv());
      break;
    case "multSub":
      ({ question, choices, solution } = multSub());
      break;
    // case "divSub":
    //   return divSub();
    //   break;
    default:
      break;
  }

  return {
    questionPrompt: "Evaluate: ",
    question,
    choices,
    solution,
  };
}

/** Evaluate expression using the correct order of operations, using addition and multiplication
 * @return problem object with the following properties: question (string), choices (array of 4 strings representing answer choices with the correct answer always at index 0), solution function to run
 */
function addMult() {
  const types = ["add", "addParen", "mult", "multParen"];
  const type = types[rand(0, types.length - 1)];

  const a = rand(2, 9);
  const b = rand(2, 9);
  const c = rand(2, 9);
  const subtractOffset = rand(0, 1) === 0;

  let correctAnswer, question, solutionStr;
  let choices = [];

  switch (type) {
    case "add":
      correctAnswer = a + b * c;
      question = `${a} + ${b} \\times ${c}`;
      choices.push(
        (a + b) * c,
        a * b + c,
        subtractOffset ? a + b * (c - 1) : a + b * (c + 1)
      );
      solutionStr = question + `=${a} + ${b * c}=` + correctAnswer;
      break;
    case "addParen":
      correctAnswer = (a + b) * c;
      question = `(${a} + ${b}) \\times ${c}`;
      choices.push(
        a + b * c,
        a * b + c,
        subtractOffset ? (a + b) * (c - 1) : (a + b) * (c + 1)
      );
      solutionStr = question + `=${a + b} \\times ${c}=` + correctAnswer;
      break;
    case "mult":
      correctAnswer = a * b + c;
      question = `${a} \\times ${b} + ${c}`;
      choices.push(
        a * (b + c),
        a + b * c,
        subtractOffset ? (a - 1) * b + c : (a + 1) * b + c
      );
      solutionStr = question + `=${a * b} + ${c}=` + correctAnswer;
      break;
    case "multParen":
      correctAnswer = a * (b + c);
      question = `${a} \\times (${b} + ${c})`;
      choices.push(
        a * b + c,
        a + b * c,
        subtractOffset ? (a - 1) * (b + c) : (a + 1) * (b + c)
      );
      solutionStr = question + `=${a} \\times ${b + c}=` + correctAnswer;
      break;
    default:
      break;
  }

  choices = processChoices(choices, correctAnswer);
  if (choices.length !== 4) return addMult();

  const solution = (id) => {
    $(id).html("\\(" + solutionStr + "\\)");
  };

  return {
    question,
    choices,
    solution,
  };
}

/** Evaluate expression using the correct order of operations, using addition and division
 * @return problem object with the following properties: question (string), choices (array of 4 strings representing answer choices with the correct answer always at index 0), solution function to run
 */
function addDiv() {
  const types = ["add", "addParen", "div", "divParen"];
  const type = types[rand(0, types.length - 1)];

  let a, b, c, x, correctAnswer, question, solutionStr;
  let choices = [];

  switch (type) {
    case "add":
      c = rand(2, 5);
      b = c * (c - 1) * rand(1, Math.floor(49 / (c * (c - 1))));
      a = b * rand(1, Math.floor(49 / b));
      correctAnswer = a + b / c;
      question = `${a} + ${b} \\div ${c}`;
      choices.push((a + b) / c, a / b + c, a + b / (c - 1));
      solutionStr = question + `=${a} + ${b / c}=` + correctAnswer;
      break;
    case "addParen":
      c = rand(2, 5);
      b = c * (c - 1) * rand(1, Math.floor(49 / (c * (c - 1))));
      a = b * rand(1, Math.floor(49 / b));
      correctAnswer = (a + b) / c;
      question = `(${a} + ${b}) \\div ${c}`;
      choices.push(a + b / c, a / b + c, (a + b) / (c - 1));
      solutionStr = question + `=${a + b} \\div ${c}=` + correctAnswer;
      break;
    case "div":
      c = rand(1, 5);
      x = rand(1, Math.floor(9 / c));
      b = c * x;
      a = c * x * (x + 1) * rand(1, Math.floor(99 / (c * x * (x + 1))));
      correctAnswer = a / b + c;
      question = `${a} \\div ${b} + ${c}`;
      choices.push(a / (b + c), a + b / c, (2 * a) / b + c);
      solutionStr = question + `=${a / b} + ${c}=` + correctAnswer;
      break;
    case "divParen":
      c = rand(1, 5);
      x = rand(1, Math.floor(9 / c));
      b = c * x;
      a = c * x * (x + 1) * rand(1, Math.floor(99 / (c * x * (x + 1))));
      correctAnswer = a / (b + c);
      question = `${a} \\div (${b} + ${c})`;
      choices.push(a / b + c, a + b / c, 2 * correctAnswer);
      solutionStr = question + `=${a} \\div ${b + c}=` + correctAnswer;
      break;
    default:
      break;
  }

  choices = processChoices(choices, correctAnswer);
  if (choices.length !== 4) return addDiv();

  const solution = (id) => {
    $(id).html("\\(" + solutionStr + "\\)");
  };

  return {
    question,
    choices,
    solution,
  };
}

/** Evaluate expression using the correct order of operations, using addition and division
 * @return problem object with the following properties: question (string), choices (array of 4 strings representing answer choices with the correct answer always at index 0), solution function to run
 */
function multSub() {
  const types = ["sub", "subParen", "mult", "multParen"];
  const type = types[rand(0, types.length - 1)];

  let a, b, c, correctAnswer, question, solutionStr;
  let choices = [];
  const subtractOffset = rand(0, 1) === 0;

  switch (type) {
    case "sub":
      c = rand(2, 5);
      b = rand(2, Math.floor(10 / c));
      a = b * c + 1 + rand(1, 20 - (b * c + 1));
      correctAnswer = a - b * c;
      question = `${a} - ${b} \\times ${c}`;
      choices.push(
        (a - b) * c,
        a * b - c,
        subtractOffset ? a - (b * c - 1) : a - (b * c + 1)
      );
      solutionStr = question + `=${a} - ${b * c}=` + correctAnswer;
      break;
    case "subParen":
      c = rand(2, 5);
      b = rand(2, Math.floor(10 / c));
      a = b * c + rand(1, 14 - b * c);
      correctAnswer = (a - b) * c;
      question = `(${a} - ${b}) \\times ${c}`;
      choices.push(
        a - b * c,
        a * b - c,
        subtractOffset ? (a - b - 1) * c : (a - b + 1) * c
      );
      solutionStr = question + `=${a - b} \\times ${c}=` + correctAnswer;
      break;
    case "mult":
      c = rand(1, 2);
      b = rand(c + 1, 5);
      a = b * c + rand(1, 12 - b * c);
      correctAnswer = a * b - c;
      question = `${a} \\times ${b} - ${c}`;
      choices.push(
        a - b * c,
        a * (b - c),
        subtractOffset ? a * (b - 1) - c : a * (b + 1) - c
      );
      solutionStr = question + `=${a * b} - ${c}=` + correctAnswer;
      break;
    case "multParen":
      c = rand(1, 2);
      b = rand(c + 1, 5);
      a = b * c + rand(1, 12 - b * c);
      correctAnswer = a * (b - c);
      question = `${a} \\times (${b} - ${c})`;
      choices.push(
        a * b - c,
        a - b * c,
        subtractOffset ? (a - 1) * (b - c) : (a + 1) * (b - c)
      );
      solutionStr = question + `=${a} \\times ${b - c}=` + correctAnswer;
      break;
    default:
      break;
  }

  choices = processChoices(choices, correctAnswer);
  if (choices.length !== 4) return multSub();

  const solution = (id) => {
    $(id).html("\\(" + solutionStr + "\\)");
  };

  return {
    question,
    choices,
    solution,
  };
}
