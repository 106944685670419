import { PropsWithChildren, ReactNode } from "react";

export const CourseLandingWrapper: React.FC<
  PropsWithChildren<{
    mobileCourseSearch: ReactNode;
    header: ReactNode;
  }>
> = ({ header, mobileCourseSearch, children }) => {
  return (
    <main className="relative flex w-full flex-col gap-6">
      <div className="block md:hidden">{mobileCourseSearch}</div>
      {header}
      <div className="flex flex-col gap-y-2">{children}</div>
    </main>
  );
};
