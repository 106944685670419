/* eslint-disable no-eval */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, Dispatch, SetStateAction, useRef } from "react";
import { MenuIcon } from "@heroicons/react/solid";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/outline";
import { deltamathLogo } from "../utils";
import { useMutation } from "react-query";
import axios from "axios";
import { useParams } from "react-router-dom";
import { deltamathAPI } from "../manager/utils";
import { getRenderMathSettings } from "../student/utils";
import { clamp, cloneDeep, debounce } from "lodash";
import { Problem as ProblemType } from "../learner/types";
import { useDeltaToastContext } from "./contexts/ToasterContext";
import Problem from "../student/components/standard-problems/Problem";
import Sidebar from "../student/layout/Sidebar";
import Button from "../student/components/generic/button";
import renderMathInElement from "../student/utils/auto-render";
import { renderA11yString } from "../student/utils/render-a11y-string";
import {
  checkForCustomFiles,
  generateProblemScripts,
  processlines,
  resizeKatex,
} from "../student/utils";
import {
  centerVerticallyInParent,
  REACT_APP_HOMEPAGE_LINK,
  REACT_APP_URL_PREFIX,
  isRequestFromLocal,
  isRequestFromReviewapp,
} from "../utils";
import { useUserContext } from "./contexts/UserContext";
import { getCustomPromptA11y } from "./accessibility/a11yFunctions";
import { PAGE_TYPE_ID_MAP } from "./constants";
import clsx from "clsx";
import { useMediaQuery } from "usehooks-ts";
// import "katex/dist/katex.min.css";

type Props = {
  currentProblemIndex: number;
  setCurrentProblemIndex: Dispatch<SetStateAction<number>>;
  sidebarOpen: boolean;
  total?: number;
};

export function SideBarQRMenu({
  currentProblemIndex,
  setCurrentProblemIndex,
  sidebarOpen,
  total,
}: Props): JSX.Element {
  const sidebtnRefs = useRef<Map<number, any> | null>(null);

  const refCallback = (node: any, key: number) => {
    if (!sidebtnRefs.current) sidebtnRefs.current = new Map();
    const map = sidebtnRefs.current;
    if (node) {
      map.set(key, node);
    } else {
      map.delete(key);
    }
  };

  useEffect(() => {
    const questionNav = document.getElementById("question-nav");
    const timeout = setTimeout(() => {
      if (questionNav && sidebtnRefs?.current?.get(currentProblemIndex)) {
        centerVerticallyInParent(
          questionNav,
          sidebtnRefs?.current?.get(currentProblemIndex)
        );
      }
    }, 20);

    return () => clearTimeout(timeout);
  }, [currentProblemIndex, sidebarOpen]);

  return (
    <div className="relative flex h-full flex-col">
      <div className="mb-16 max-w-36 shrink-0">
        <img alt="DeltaMath Home" src={deltamathLogo} />
      </div>
      <div className="flex flex-grow flex-col overflow-hidden">
        <div
          id="question-nav"
          className="flex flex-grow flex-col gap-y-1 overflow-auto"
        >
          {[...Array(total)].map((_, i) => (
            <button
              key={`qrquestion-${i}`}
              ref={(node: any) => refCallback(node, i)}
              className={clsx(
                "group flex items-center gap-x-2 py-2 text-sm leading-none hover:text-dm-gray-800",
                i === currentProblemIndex
                  ? "font-bold text-dm-gray-800"
                  : "text-[#6B7280]"
              )}
              onClick={() => {
                setCurrentProblemIndex(i);
              }}
            >
              <i
                className={clsx(
                  "far fa-question-circle shrink-0 group-hover:text-dm-brand-blue-500",
                  i === currentProblemIndex
                    ? "text-dm-brand-blue-500"
                    : "text-dm-gray-200"
                )}
              ></i>
              Question {i + 1}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}

let customPromptA11y: undefined | JSX.Element = undefined;

export default function QRCodeSolutions(): JSX.Element {
  const toastContext = useDeltaToastContext();
  const isSmallDevice = useMediaQuery("(max-width : 1023px)");
  const { code } = useParams();
  const pageID = PAGE_TYPE_ID_MAP["solution"];
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );
  const [qrProblems, setqrProblems] = useState<ProblemType[] | undefined>(
    undefined
  );

  const [currentProblemIndex, setCurrentProblemIndex] = useState<number>(0);
  const currentProblem = qrProblems?.[currentProblemIndex];

  const mathBlockContent = useRef<null | HTMLDivElement>(null);
  // TODO:  If during testing, a loading spinner is wanted between problem renderings,
  //        uncomment the following line and related code
  // const loadingContent = useRef<null | HTMLDivElement>(null);
  const [katexResizingData, setKatexResizingData] = useState<any>({});
  const [customDataFetched, setCustomDataFetched] = useState<boolean>(false);

  const isCustom =
    currentProblem?.ansType === "custom" ||
    currentProblem?.data?.externalUrlExists ||
    currentProblem?.data?.sharedExternalFile;

  window.renderMathInElement = renderMathInElement;
  window.renderA11yString = renderA11yString;
  const makeDisplayStyle = (window as any).makeDisplayStyle;

  const userContext = useUserContext();

  const changeProblemIndex = (diff: number) => {
    if (qrProblems !== undefined) {
      setCurrentProblemIndex((prev) =>
        clamp(prev + diff, 0, qrProblems.length)
      );
    }
  };

  const { mutate: qrCodeMutate } = useMutation(
    (body: { code: string }) => {
      return axios.post(
        `${deltamathAPI()}/qr_code_to_solutions`,
        JSON.stringify(body),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    },
    {
      onSuccess: (data) => {
        if (data.data.solutionDelay) {
          const delayTime = new Date(data.data.solutionDelay * 1000);
          const dateTimeFormat = new Intl.DateTimeFormat("en-US", {
            timeStyle: "short",
            dateStyle: "full",
          });
          setErrorMessage(
            `The solutions for this document will not be available until ${dateTimeFormat.format(
              delayTime
            )}`
          );
        } else if (data.data.message) {
          setErrorMessage(data.data.message);
        } else if (data.data.problems) {
          // TODO: Code lifted from getProblemForClient & mapTeacherCreatedProblem in node-backend
          //       Maybe move this into qr_code_to_solutions when implementing for teachers

          const pdfProblems = data.data.problems.map((problem: any) => {
            if (
              typeof problem._id === "string" &&
              problem._id.substring(0, 1) === "u"
            ) {
              problem.lines = cloneDeep(problem.qlines);
              problem.inlineSolutionCode = problem.inlineQuestionCode;

              if (
                problem.gradeType === "teacher" ||
                problem.displayLast === undefined
              ) {
                return problem;
              }
              if (
                !problem.displayLast ||
                problem.displayLast.indexOf('"iv":') !== -1
              ) {
                problem.displayLast = "(\\text{no solution provided})";
              }
              if (problem.displayLast.substring(0, 2) === "\\[") {
                problem.displayLast = problem.displayLast.slice(2, -2);
              }
              problem.lines = problem.lines.concat([
                {
                  type: "html",
                  html: '<hr class="border-dashed border-black" />Correct Answer:',
                  format: { classes: "text-base" },
                },
                problem.displayLast.substring(0, 1) === "<"
                  ? { type: "html", html: problem.displayLast }
                  : { type: "line", line: problem.displayLast },
              ]);
            }

            return problem;
          });
          setqrProblems(pdfProblems);
        }

        // TODO: Look over this functionality a little closer before going to production
        if (!data.data.loginData) {
          const user = JSON.parse(localStorage.getItem("user") || "{}");

          if (!user || !user._id) {
            userContext.logoutUser();
            const newPath =
              !isRequestFromReviewapp(window.location.origin) &&
              !isRequestFromLocal(window.location.origin)
                ? window.location.pathname.replace(
                    `${REACT_APP_URL_PREFIX}/`,
                    ""
                  )
                : window.location.pathname;

            window.location.href = `${REACT_APP_HOMEPAGE_LINK}/sign-in?redirectUrl=${newPath}`;
          }
        }
      },
      onError: (error: any) => {
        toastContext.addToast({
          title: "Error",
          message:
            error?.response?.data?.message ||
            error?.message ||
            "An error occurred",
          status: "Error",
        });
      },
    }
  );

  useEffect(() => {
    if (code !== undefined) {
      const body = {
        code: code,
      };
      qrCodeMutate(body);
    }
  }, []);

  useEffect(() => {
    document.title = "DeltaMath Student Application";
    document.body.classList.add("h-full");
    const html = document.getElementsByTagName("html")[0]; // '0' to assign the first (and only `HTML` tag)
    html?.setAttribute("class", "h-full bg-gray-100");
    const root = document.getElementById("root");
    root?.classList.add("h-full");
  }, []);

  // useEffect to invoke displayQuestion after custom data is fetched (for custom problems only)
  useEffect(() => {
    if (isCustom && customDataFetched) displayQuestion();
  }, [customDataFetched]);

  useEffect(() => {
    if (currentProblem === undefined) return;
    setSidebarOpen(false);
    setCustomDataFetched(false);
    mathBlockContent?.current?.classList.add("invisible");
    // loadingContent?.current?.classList.remove("hidden");

    setKatexResizingData({});

    if (isCustom) {
      // fetch custom data and update problem object with problemScripts
      setCustomFileData(currentProblem);
    } else {
      // display the question immediately
      displayQuestion();
    }

    const mathBlock = document.getElementById("mathBlock");

    if (mathBlock) renderMathInElement(mathBlock, getRenderMathSettings());

    setKatexResizingData((prevState: any) => ({
      ...prevState,
      // eslint-disable-next-line no-useless-computed-key
      ["solution"]: resizeKatex("solution", prevState),
    }));
  }, [currentProblem?._id]);

  const setCustomFileData = async (problem: any) => {
    const customData = generateProblemScripts(
      await checkForCustomFiles(problem),
      problem
    );

    setCustomDataFetched(true);

    const tempQRProblems = cloneDeep(qrProblems);
    if (tempQRProblems !== undefined) {
      tempQRProblems[currentProblemIndex] = {
        ...tempQRProblems?.[currentProblemIndex],
        ...customData,
      };
      setqrProblems(tempQRProblems);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      document.querySelector(`#${pageID} .answerData`)?.classList.add("hidden");
      // loadingContent?.current?.classList.add("hidden");
      mathBlockContent?.current?.classList.remove("invisible");
      clearTimeout(timer);
    }, 0);

    return () => clearTimeout(timer);
  }, [katexResizingData]);

  const inlineSolutionCode = () => {
    return `
      window.deltaGraphs = [];
      ${currentProblem?.inlineSolutionCode};`;
  };

  const displayQuestion = () => {
    // These are possibly used by inlineSolutionCode when eval()
    // Guarantees data & page are in scope.
    // !!DO NOT DELETE!!

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const data = currentProblem?.data?.data || {};
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const page = document.getElementById(pageID);

    if (currentProblem?.data?.inlineFirst === undefined) {
      eval(inlineSolutionCode());
      if (currentProblem?.problemScripts && currentProblem?.lines) {
        currentProblem?.problemScripts?.solutionScripts(page);
      }
    } else if (currentProblem?.data?.inlineFirst === false) {
      if (currentProblem?.problemScripts && currentProblem?.lines) {
        currentProblem?.problemScripts?.solutionScripts(page);
      }
      eval(inlineSolutionCode());
    }
    // increase input width as more data is typed in
    if (
      document.querySelector(
        'script[src*="/assets/jquery.auto-grow-input.min.js"]'
      ) &&
      (window as any).$?.fn?.autoGrowInput
    ) {
      eval(`$('.display-problem input:not(.no-auto-grow)').autoGrowInput();`);
    }
    //[MB - 1.26.2024] - allowing for module coder over-ride of accesibility text for the prompt
    if (
      currentProblem?.prompt &&
      typeof currentProblem?.promptA11ySettings?.customString !== "undefined"
    ) {
      const {
        fOfX = true,
        longNumber = true,
        customString = "",
      } = currentProblem.promptA11ySettings;
      customPromptA11y = getCustomPromptA11y(currentProblem.prompt, {
        fOfX,
        longNumber,
        customString,
      });
    } else {
      customPromptA11y = undefined;
    }
  };

  /* On browser resize, call resizeKatex() to readjust the KaTeX width */
  const handleResize = () => {
    setKatexResizingData((prevState: any) => ({
      ...prevState,
      // eslint-disable-next-line no-useless-computed-key
      ["solution"]: resizeKatex("solution", prevState),
    }));
  };

  const debounce_resize = debounce(handleResize, 150);

  useEffect(() => {
    window.addEventListener("resize", debounce_resize);
    return () => {
      window.removeEventListener("resize", debounce_resize);
    };
  });

  /* Logic for prompt display */
  let prompt = null;
  if (currentProblem?.prompt) {
    prompt = currentProblem?.prompt;
    if (typeof currentProblem.prompt === "string") {
      // use katex display style for prompt
      if (!currentProblem.prompt.includes("displaystyle"))
        prompt = makeDisplayStyle(prompt);
      // replace new lines for strings with html breaks for teacher created questions
      if (currentProblem?.skillcode === "userCreatedProblem")
        prompt = prompt.replaceAll("\n", "<br>");
    }
  }

  return (
    <>
      {errorMessage !== undefined ? (
        <div className="m-6">
          <p className="relative overflow-y-auto rounded-lg border border-[#E4E6EA] bg-white px-2 py-4 sm:p-5 lg:p-8">
            {errorMessage}
          </p>
        </div>
      ) : (
        <div className="flex h-full flex-col">
          {qrProblems !== undefined ? (
            <div className="flex flex-grow flex-col bg-dm-background-blue-100 sm:flex-row">
              <Sidebar
                sidebarOpen={sidebarOpen}
                setSidebarOpen={setSidebarOpen}
                widthClasses="lg:w-1/6"
                pageType="qr"
                qrOptions={{
                  currentProblemIndex: currentProblemIndex,
                  setCurrentProblemIndex: setCurrentProblemIndex,
                  total: qrProblems?.length,
                }}
              />
              <div className="flex w-full flex-grow flex-col px-3 py-4 sm:px-6 lg:w-5/6">
                <nav className="z-[39] mb-3 flex gap-x-2 min-[480px]:gap-x-5">
                  <div className="relative flex items-center justify-between gap-x-1 lg:hidden">
                    <button
                      type="button"
                      className="self-stretch text-white focus:outline-none sm:mr-5"
                      onClick={() => setSidebarOpen(true)}
                    >
                      <span className="sr-only">Open sidebar</span>
                      <MenuIcon
                        className="h-5 w-5"
                        aria-hidden="true"
                        color="#9CA3AF"
                      />
                    </button>
                    <div className="shrink-0">
                      <img
                        alt="DeltaMath Home"
                        src={deltamathLogo}
                        className="h-[18px] min-[480px]:h-6"
                      />
                    </div>
                  </div>
                  {qrProblems !== undefined && (
                    <div className="flex flex-grow items-center justify-end gap-x-2">
                      <Button
                        onClick={() => changeProblemIndex(-1)}
                        disabled={currentProblemIndex === 0}
                        type="primary"
                        size={isSmallDevice ? "small" : "standard"}
                        className="flex items-center justify-center gap-x-2 max-sm:w-full max-sm:px-3"
                      >
                        <ArrowLeftIcon className="h-5 w-5" aria-hidden="true" />
                        {isSmallDevice ? "Prev" : "Previous Question"}
                      </Button>
                      <Button
                        onClick={() => changeProblemIndex(1)}
                        disabled={currentProblemIndex >= qrProblems.length - 1}
                        type="primary"
                        size={isSmallDevice ? "small" : "standard"}
                        className="flex items-center justify-center gap-x-2 max-sm:w-full max-sm:px-3"
                      >
                        {isSmallDevice ? "Next" : "Next Question"}
                        <ArrowRightIcon
                          className="h-5 w-5"
                          aria-hidden="true"
                        />
                      </Button>
                    </div>
                  )}
                </nav>
                <main
                  id="mathBlock"
                  className="relative grow overflow-x-hidden rounded-lg border border-[#E4E6EA] bg-white px-2 py-4 sm:p-5 lg:p-8"
                  aria-live="polite"
                >
                  {/* <div
                    ref={loadingContent}
                    className="flex flex-col items-center justify-center"
                  >
                    <div
                      style={{ borderTopColor: "transparent" }}
                      className="h-16 w-16 animate-spin rounded-full border-4 border-solid border-blue-400"
                    ></div>
                    <h2 className="mt-2 text-center text-xl font-bold">
                      Loading...
                    </h2>
                  </div> */}
                  <div ref={mathBlockContent} className="invisible">
                    <h2 className="mb-2 font-sans text-sm text-dm-charcoal-200 lg:hidden">
                      Question {currentProblemIndex + 1}
                    </h2>
                    {prompt ? (
                      <h2
                        id="problemPrompt"
                        role="group"
                        className="text-base font-medium leading-7 focus:outline-none"
                        aria-hidden={customPromptA11y !== undefined}
                        dangerouslySetInnerHTML={{
                          __html: prompt,
                        }}
                      ></h2>
                    ) : null}
                    {customPromptA11y !== undefined && customPromptA11y}
                    {currentProblem ? (
                      <div
                        key={`qrpage:${currentProblem?._id}`}
                        id={pageID}
                        className="display-problem problem-page"
                      >
                        <Problem
                          displayData={processlines(currentProblem?.lines)}
                          problemData={currentProblem}
                          resizingData={katexResizingData["solution"]}
                          locString="solution"
                        />
                      </div>
                    ) : null}
                  </div>
                </main>
              </div>
            </div>
          ) : null}
        </div>
      )}
    </>
  );
}
