/* eslint-disable @typescript-eslint/ban-ts-comment */

import React, { Fragment, useContext, useState } from "react";
import {
  CheckCircleIcon,
  XIcon,
  XCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/outline";
import { Transition } from "@headlessui/react";
import { v4 as uuidv4 } from "uuid";
import Button from "../../student/components/generic/button";

/*

To use, first import:

import { useDeltaToastContext } from "../../shared/contexts/ToasterContext";

Then use the context in your component: 

const toastContext = useDeltaToastContext();

Finally, whenever you need to send a message:

toastContext.addToast({
  status: "Success",
  message: "Your request was successful.",
  dismiss: 5, // If not set
});
*/

export interface ToastInterface {
  id?: string;
  message: string;
  status: "Success" | "Error" | "Warning" | "None";
  dismiss?: "manual" | number | null;
  title?: string;
  largeDismissButton?: boolean;
}

interface ToastApi {
  addToast(toast: ToastInterface): void;
}

type ToasterState = {
  toasts: ToastInterface[];
};

export class ToastHolder implements ToastApi {
  private readonly state: ToasterState;
  private readonly DEFAULT_DISMISS = 5;
  private setState: (state: Partial<ToasterState>) => void;

  addToast = (toast: ToastInterface, replace?: boolean) => {
    const toastWithId = toast;
    toastWithId.id = toast.id ?? uuidv4();
    const currentToasts = replace
      ? this.state.toasts.filter((x) => x.id !== toast.id)
      : this.state.toasts;
    this.setState({ toasts: [...currentToasts, toastWithId] });

    if (toast.dismiss !== "manual" || toast.dismiss === undefined) {
      const dismissSeconds =
        toast.dismiss && Number.isInteger(toast.dismiss) && toast.dismiss > 0
          ? toast.dismiss
          : this.DEFAULT_DISMISS;
      setTimeout(
        () => this.setState({ toasts: this.state.toasts.slice(1) }),
        dismissSeconds * 1000
      );
    }
  };

  constructor(
    state: ToasterState,
    setState: (state: Partial<ToasterState>) => void
  ) {
    this.state = state;
    this.setState = setState;
  }
}

const ToastContext = React.createContext<ToastHolder>(null!);

export default ToastContext;

export function ToasterContextProvider({ children }: { children: any }) {
  const [state, setState] = useState<ToasterState>({ toasts: [] });

  const saveState = (partialState: Partial<ToasterState>) => {
    setState({ ...state, ...partialState });
  };

  return (
    <ToastContext.Provider value={new ToastHolder(state, saveState)}>
      {children}
      <div className="toasts-wrapper">
        <div
          aria-live="assertive"
          className="pointer-events-none fixed inset-0 z-[60] flex items-end px-4 py-6 sm:items-start sm:p-6"
        >
          <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
            {state.toasts &&
              state.toasts.length > 0 &&
              state.toasts.map((toast: ToastInterface, index: number) => (
                <Transition
                  key={index}
                  show={true}
                  as={Fragment}
                  enter="transform ease-out duration-300 transition"
                  enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                  enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                    <div className="p-4">
                      <div
                        className={`flex items-start ${
                          toast.largeDismissButton ? "gap-2" : ""
                        }`}
                      >
                        {!toast.largeDismissButton && (
                          <div className="flex-shrink-0">
                            {toast.status === "Success" ? (
                              <CheckCircleIcon
                                className="h-6 w-6 text-green-400"
                                aria-hidden="true"
                              />
                            ) : toast.status === "Warning" ? (
                              <ExclamationCircleIcon
                                className="h-6 w-6 text-yellow-400"
                                aria-hidden="true"
                              />
                            ) : (
                              <XCircleIcon
                                className="h-6 w-6 text-red-400"
                                aria-hidden="true"
                              />
                            )}
                          </div>
                        )}
                        <div className="ml-3 w-0 flex-1 pt-0.5">
                          {toast.title ? (
                            <h5>{toast.title}</h5>
                          ) : (
                            <p className="text-sm font-medium text-gray-900">
                              {toast.status}
                            </p>
                          )}
                          <p
                            className={`mt-1 text-sm text-gray-500 ${
                              toast.largeDismissButton ? "leading-6" : ""
                            }`}
                          >
                            {toast.message}
                          </p>
                        </div>
                        {toast.largeDismissButton ? (
                          <div className="mb-auto ml-8 mr-8 mt-auto flex h-full items-center justify-center">
                            <Button
                              type="link"
                              onClick={() => {
                                setState({
                                  toasts: [
                                    ...state.toasts.filter(
                                      (t: ToastInterface) => t.id !== toast.id
                                    ),
                                  ],
                                });
                              }}
                              className="cursor-pointer"
                            >
                              Dismiss
                            </Button>
                          </div>
                        ) : (
                          <div className="ml-4 flex flex-shrink-0">
                            <button
                              type="button"
                              className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                              onClick={() => {
                                setState({
                                  toasts: [
                                    ...state.toasts.filter(
                                      (t: ToastInterface) => t.id !== toast.id
                                    ),
                                  ],
                                });
                              }}
                            >
                              <span className="sr-only">Close</span>
                              <XIcon className="h-5 w-5" aria-hidden="true" />
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Transition>
              ))}
          </div>
        </div>
      </div>
    </ToastContext.Provider>
  );
}

export function useDeltaToastContext() {
  return useContext(ToastContext);
}
