import { AnalyticsPlugin } from "analytics";
import { AnalyticsPayload } from "../types";
import { deltamathAPI } from "../../../manager/utils";
import axios from "axios";
import { withJsonHeader } from "../../../shared/axiosUtils";

async function sendInternalEvent(
  userId: string | undefined,
  event: string,
  properties: Record<string, unknown>
) {
  await axios.post(
    userId ? `${deltamathAPI()}/a/l` : `${deltamathAPI()}/a/la`,
    {
      event,
      properties: {
        href: window.location.href,
        host: window.location.host,
        pathname: window.location.pathname,
        hash: window.location.hash,
        search: window.location.search,
        ...properties,
      },
    },
    withJsonHeader()
  );
}

/**
 * This is an Analytics plugin that implements an interface for sending events
 * to an internal endpoint
 * @see https://getanalytics.io/
 * @see https://getanalytics.io/plugins/writing-plugins/
 */
export const DELTAMATH_PLUGIN: AnalyticsPlugin = {
  name: "deltamath",
  initialize: () => {
    // Nothing to do here
  },
  page: ({ payload }: { payload: AnalyticsPayload<any> }) => {
    sendInternalEvent(payload.userId, "pageView", {});
  },
  track: ({ payload }: { payload: AnalyticsPayload<any> }) => {
    sendInternalEvent(payload.userId, payload.event, payload.properties);
  },
  identify: () => {
    // We can identify the user on the backend
  },
  loaded: () => true,
};
