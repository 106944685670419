import { usePrizes } from "./usePrizes";
import { Prize } from "../types";
import { useLearnerContext } from "../contexts/LearnerContext";

/** Find the prize the user most recently unlocked */
export function useNewPrize(): Prize | undefined {
  const { learner } = useLearnerContext();
  const key = learner?.prizes
    ? learner.prizes[learner.prizes.length - 1]
    : undefined;
  const prizes = usePrizes();
  return prizes.find((prize) => prize.key === key);
}
