import { uniqueId } from "lodash";

export const TriangleStarsIcon: React.FC<{ showAria?: boolean }> = ({
  showAria = false,
}) => {
  const id = uniqueId("TriangleStarsIcon");
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      aria-label="triangle with stars"
      aria-hidden={!showAria}
    >
      <g clipPath={`url(#${id})`}>
        <path
          d="M15 10L8.93782 20.5L21.0622 20.5L15 10Z"
          stroke="currentColor"
          strokeWidth="2"
        />
        <path
          d="M8.47467 8.42267L9 10L9.52533 8.42267C9.67261 7.98084 9.92074 7.57937 10.2501 7.25005C10.5794 6.92074 10.9808 6.67261 11.4227 6.52533L13 6L11.4227 5.47467C10.9808 5.32739 10.5794 5.07926 10.2501 4.74995C9.92074 4.42063 9.67261 4.01916 9.52533 3.57733L9 2L8.47467 3.57733C8.32739 4.01916 8.07926 4.42063 7.74995 4.74995C7.42063 5.07926 7.01916 5.32739 6.57733 5.47467L5 6L6.57733 6.52533C7.01916 6.67261 7.42063 6.92074 7.74995 7.25005C8.07926 7.57937 8.32739 7.98084 8.47467 8.42267Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.606 16.817L5 18L5.394 16.817C5.50446 16.4856 5.69055 16.1845 5.93754 15.9375C6.18453 15.6906 6.48563 15.5045 6.817 15.394L8 15L6.817 14.606C6.48563 14.4955 6.18453 14.3094 5.93754 14.0625C5.69055 13.8155 5.50446 13.5144 5.394 13.183L5 12L4.606 13.183C4.49554 13.5144 4.30945 13.8155 4.06246 14.0625C3.81547 14.3094 3.51437 14.4955 3.183 14.606L2 15L3.183 15.394C3.51437 15.5045 3.81547 15.6906 4.06246 15.9375C4.30945 16.1845 4.49554 16.4856 4.606 16.817Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect
            width="24"
            height="24"
            fill="white"
            transform="matrix(-1 0 0 1 24 0)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
