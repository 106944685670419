import { BREVO_PLUGIN } from "./brevo";
import { DELTAMATH_PLUGIN } from "./deltamath";
import { MIXPANEL_PLUGIN } from "./mixpanel";

/** The set of available analytics plugins. IMPORTANT: The key must match the plugin's `name` field */
export const ANALYTICS_PLUGINS = {
  brevo: BREVO_PLUGIN,
  deltamath: DELTAMATH_PLUGIN,
  mixpanel: MIXPANEL_PLUGIN,
};

/**
 * A list of all plugin keys. Pass this to createAnalyticsEvent if you
 * want to send the event to all providers
 */
export const ALL_PLUGINS = Object.keys(ANALYTICS_PLUGINS) as Array<
  keyof typeof ANALYTICS_PLUGINS
>;
