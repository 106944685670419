import Button from "../../../student/components/generic/button";
import { getFilePath } from "../../../utils";
import { ResponsiveImage } from "../ResponsiveImage";
import { SectionTitle } from "./SectionTitle";
import { useHomepageSignupContext } from "./HomepageSignupContext";
import { TiltedBanner } from "./TiltedBanner";

export const FooterCtaNarrow: React.FC = () => {
  const { setShowSignup } = useHomepageSignupContext();
  return (
    <section className="-mt-6 flex w-full justify-center overflow-hidden pt-6">
      <div className="relative flex h-[493px] w-[358px] flex-none justify-center">
        <div className="relative z-10 max-w-[100dvw] px-6 pt-24">
          <SectionTitle
            title="Personalized Learning. Proven Results."
            subtitle="Experience the DeltaMath for Home difference today!"
          />
          <Button
            className="-mt-4 w-full text-sm"
            onClick={() => setShowSignup(true)}
          >
            Get Unlimited Access
          </Button>
          <TiltedBanner
            rotateClockwise={true}
            className="-right-3 top-2 bg-[#7BD78C]"
          />
        </div>
        <ResponsiveImage
          className="absolute left-0 top-0 w-full"
          srcs={[
            getFilePath("/images/learner/home/bg-with-deltie@2x.png"),
            getFilePath("/images/learner/home/bg-with-deltie.png"),
          ]}
          alt="Deltie background"
          aria-hidden="true"
        />
      </div>
    </section>
  );
};
