import { SVGAttributes } from "react";

export const DeltieIcon: React.FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    {...props}
  >
    <path
      d="M16 6L4 26.7911H28L16 6Z"
      fill="#94DB98"
      stroke="#2F52EA"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.2842 22.2818C13.2842 22.2818 15.7146 23.8293 18.2684 22.2344"
      stroke="#2F52EA"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5183 18.7833C12.8801 18.7833 13.1734 18.49 13.1734 18.1282C13.1734 17.7664 12.8801 17.4731 12.5183 17.4731C12.1566 17.4731 11.8633 17.7664 11.8633 18.1282C11.8633 18.49 12.1566 18.7833 12.5183 18.7833Z"
      fill="#2F52EA"
    />
    <path
      d="M19.0262 18.7833C19.3879 18.7833 19.6812 18.49 19.6812 18.1282C19.6812 17.7664 19.3879 17.4731 19.0262 17.4731C18.6644 17.4731 18.3711 17.7664 18.3711 18.1282C18.3711 18.49 18.6644 18.7833 19.0262 18.7833Z"
      fill="#2F52EA"
    />
    <path
      d="M10.9339 21.5172C13.0364 21.5172 14.7409 19.8128 14.7409 17.7103C14.7409 15.6078 13.0364 13.9033 10.9339 13.9033C8.83139 13.9033 7.12695 15.6078 7.12695 17.7103C7.12695 19.8128 8.83139 21.5172 10.9339 21.5172Z"
      stroke="#2F52EA"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.6038 21.5172C22.7064 21.5172 24.4108 19.8128 24.4108 17.7103C24.4108 15.6078 22.7064 13.9033 20.6038 13.9033C18.5013 13.9033 16.7969 15.6078 16.7969 17.7103C16.7969 19.8128 18.5013 21.5172 20.6038 21.5172Z"
      stroke="#2F52EA"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.9434 17.7102C14.9434 17.7102 15.6934 17.0504 16.6 17.7102"
      stroke="#2F52EA"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
