import Button from "../../../student/components/generic/button";
import { useMutation } from "react-query";
import axios from "axios";
import { deltamathAPI } from "../../utils";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import { useState } from "react";

type Props = {
  versionKey: string;
  type: "course" | "unit";
  id: string;
  callback: (id: string, versionKey: string) => void;
};

const NewVersion = (props: Props) => {
  const toastContext = useDeltaToastContext();
  const [disabled, setDisabled] = useState<boolean>(false);

  const newVersion = useMutation(
    (body: string) => {
      return axios.post(
        `${deltamathAPI()}/manager_new/parent-portal/newVersion`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    },
    {
      onSuccess(data) {
        setDisabled(false);
        props.callback(
          data.data._id,
          data.data.versionHistory[data.data.versionHistory.length - 1]
            .versionKey
        );
      },
      onError(e) {
        setDisabled(false);
        toastContext.addToast({
          status: "Error",
          message: "Issue creating new version",
        });
      },
    }
  );

  const createNewVersion = () => {
    const body = {
      id: props.id,
      type: props.type,
      versionKey: props.versionKey,
    };
    setDisabled(true);

    newVersion.mutate(JSON.stringify(body));
  };

  return (
    <Button onClick={createNewVersion} disabled={disabled}>
      New {props.type} version
    </Button>
  );
};

export default NewVersion;
