import Button from "../../../../student/components/generic/button";

type Props = {
  nextProblem: () => void;
};

const AdditionalHelp = (props: Props) => {
  return (
    <>
      <div className="relative mt-4 rounded-lg border border-dm-charcoal-100 bg-white px-2 py-4 text-left sm:p-5 lg:p-8">
        <h3 className="font-serif text-lg font-bold">Want to practice more?</h3>
        <p className="mb-6 mt-2">
          Get extra practice on this skill for even better understanding.
        </p>
        <Button
          size="standard"
          type="outline"
          className="shrink-0 max-md:w-full"
          onClick={props.nextProblem}
        >
          Keep Practicing
        </Button>
      </div>
    </>
  );
};

export default AdditionalHelp;
