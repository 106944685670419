import { ToastInterface } from "../../../shared/contexts/ToasterContext";

export const TitleAndMessage = (
  saId: string,
  isTest: boolean,
  isTimed: boolean,
  isOver: boolean,
  allowResubmit: boolean,
  isLocked: boolean,
  solutionsAvailable: boolean,
  lti: boolean
): ToastInterface => {
  const toastId = `${saId}_completed`;

  const toastTitle = isTest
    ? "You've Completed the Test!"
    : "You've Completed the Assignment!";

  // allow_resubmit: 1
  if (isTimed && !isOver && isLocked) {
    if (isTest && allowResubmit) {
      return {
        id: toastId,
        title: toastTitle,
        message: "You can look over your answers or end time early.",
        status: "Success",
        dismiss: 20,
        largeDismissButton: true,
      };
    } else {
      return {
        id: toastId,
        title: toastTitle,
        message:
          "You can end time early if you would like to move on to a different assignment.",
        status: "Success",
        dismiss: 20,
        largeDismissButton: true,
      };
    }
  } else if (isTest) {
    if (solutionsAvailable) {
      if (lti) {
        if (allowResubmit) {
          return {
            id: toastId,
            title: toastTitle,
            message:
              "You can check over your answers or see your results on this test.",
            status: "Success",
            dismiss: 20,
            largeDismissButton: true,
          };
        } else {
          return {
            id: toastId,
            title: toastTitle,
            message:
              "You can see your results on this test or close this window.",
            status: "Success",
            dismiss: 20,
            largeDismissButton: true,
          };
        }
      } else {
        if (allowResubmit) {
          return {
            id: toastId,
            title: toastTitle,
            message:
              "You can check over your answers, see your results on this test, or work on another assignment.",
            status: "Success",
            dismiss: 20,
            largeDismissButton: true,
          };
        } else {
          return {
            id: toastId,
            title: toastTitle,
            message:
              "You can see your results on this test or work on another assignment.",
            status: "Success",
            dismiss: 20,
            largeDismissButton: true,
          };
        }
      }
    } else {
      if (lti) {
        if (allowResubmit) {
          return {
            id: toastId,
            title: toastTitle,
            message: "You can check over your answers or close this window.",
            status: "Success",
            dismiss: 20,
            largeDismissButton: true,
          };
        } else {
          return {
            id: toastId,
            title: toastTitle,
            message: "You can close this window.",
            status: "Success",
            dismiss: 20,
            largeDismissButton: true,
          };
        }
      } else {
        if (allowResubmit) {
          return {
            id: toastId,
            title: toastTitle,
            message:
              "You can check over your answers or work on another assignment.",
            status: "Success",
            dismiss: 20,
            largeDismissButton: true,
          };
        } else {
          return {
            id: toastId,
            title: toastTitle,
            message: "You can work on another assignment or sign out.",
            status: "Success",
            dismiss: 20,
            largeDismissButton: true,
          };
        }
      }
    }
  } else {
    if (lti) {
      return {
        id: toastId,
        title: toastTitle,
        message: "You can keep practicing or close this window.",
        status: "Success",
        dismiss: 20,
        largeDismissButton: true,
      };
    } else {
      return {
        id: toastId,
        title: toastTitle,
        message: "You can keep practicing or work on another assignment.",
        status: "Success",
        dismiss: 20,
        largeDismissButton: true,
      };
    }
  }
};
