import clsx from "clsx";
import { ReactNode } from "react";
import { NUM_ONBOARDING_STEPS } from "./constants";
import { ResponsiveImage } from "../../ResponsiveImage";
import { getFilePath } from "../../../../utils";

const Caret = () => (
  <i
    className="absolute right-3 top-full border-8 border-transparent border-t-dm-charcoal-800"
    aria-hidden
  />
);

export const SpeechBubble: React.FC<{
  text: ReactNode;
  isVisible: boolean;
  onClick: () => void;
  stepNumber: number;
  isLastStep?: boolean;
}> = ({ text, isVisible, onClick, stepNumber, isLastStep = false }) => {
  const imageFile =
    stepNumber === 1
      ? "deltie-pointing"
      : stepNumber === 2
      ? "deltie-pointing-up"
      : "deltie-pointing2";

  return (
    <div
      className={clsx(
        "flex w-48 flex-col gap-3",
        isVisible
          ? "opacity-100 transition-opacity"
          : "pointer-events-none opacity-0"
      )}
    >
      <div
        className={clsx(
          "relative flex w-full flex-col gap-1 rounded bg-dm-charcoal-800 p-2 text-xs text-white shadow-[0px_0px_4px_#767676]",
          stepNumber === 1 && "onboardingBounce"
        )}
      >
        <p aria-live="polite">{text}</p>
        <div className="flex justify-between text-dm-charcoal-200">
          <span aria-label={`Step ${stepNumber} of ${NUM_ONBOARDING_STEPS}`}>
            {stepNumber}/{NUM_ONBOARDING_STEPS}
          </span>
          <button
            className="group -m-2 flex items-center gap-2 p-2 font-bold text-dm-brand-blue-200 hover:text-dm-brand-blue-100"
            onClick={onClick}
          >
            {isLastStep ? "Got it!" : "Next"}
            <i
              className={clsx(
                isLastStep
                  ? "fas fa-check !text-dm-brand-blue-200 group-hover:!text-dm-brand-blue-100"
                  : "fas fa-arrow-right"
              )}
              aria-hidden
            />
          </button>
        </div>
        <Caret />
      </div>
      <div className="flex justify-end">
        <ResponsiveImage
          className="inline h-auto w-32 object-contain"
          srcs={[
            getFilePath(`/images/learner/points/${imageFile}.png`),
            getFilePath(`/images/learner/points/${imageFile}@2x.png`),
          ]}
          alt=""
          aria-hidden="true"
        />
      </div>
    </div>
  );
};
