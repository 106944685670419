import {
  ChangeEventHandler,
  FormEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { endOfDay, getTime } from "date-fns";
import Modal from "../../../student/components/generic/Modal";
import { initial, uniqueId } from "lodash";
import { useMutation } from "react-query";
import axios from "axios";
import { withJsonHeader } from "../../../shared/axiosUtils";
import { deltamathAPI } from "../../utils";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import { PriceScheme } from "./types";

const PricingGroup: React.FC<{ label: string }> = ({ label, children }) => (
  <div className="flex flex-col gap-4 rounded bg-dm-charcoal-100 p-4">
    <h3 className="font-bold">{label}</h3>
    <div className="flex w-full gap-6">{children}</div>
  </div>
);

const StyledInput: React.FC<{
  label: string;
  value: number | string | undefined;
  disabled?: boolean;
  type?: "number" | "text";
  onChange: ChangeEventHandler<HTMLInputElement>;
}> = ({ label, value, disabled, type, onChange }) => {
  const id = uniqueId("input");
  if (type === undefined) {
    type = "number";
  }
  return (
    <div className="flex grow flex-col gap-1">
      <label htmlFor={id}>{label}</label>
      <input
        className={`w-48 rounded px-2 py-1 ${
          disabled
            ? "cursor-not-allowed bg-gray-200 text-gray-500"
            : "bg-white text-black"
        }`}
        type={type}
        id={id}
        value={value}
        disabled={disabled}
        onChange={onChange}
      />
    </div>
  );
};

export const PriceSchemeModal: React.FC<{
  visible: boolean;
  selectedScheme?: Omit<PriceScheme, "status" | "default">;
  onClose: () => void;
  onSchemeCreated: () => void;
  onSchemeEdited: () => void;
}> = ({
  visible,
  selectedScheme,
  onClose,
  onSchemeCreated,
  onSchemeEdited,
}) => {
  const toastContext = useDeltaToastContext();
  const [isEditing, setIsEditing] = useState(false);
  const datePickerRef = useRef<DatePicker>(null);
  const [formData, setFormData] = useState<
    Omit<PriceScheme, "status" | "default">
  >({
    key: "",
    individual: { Plus: 0, Integral: 0 },
    per_student: { Plus: 0, Integral: 0 },
    minimum: { Plus: 0, Integral: 0 },
    expiration: 0,
    proratedDiscount: undefined,
    type: "full_year",
  });

  useEffect(() => {
    if (selectedScheme) {
      setFormData(selectedScheme);
      setIsEditing(true);
    } else {
      setIsEditing(false);
      setFormData({
        key: "",
        individual: { Plus: 0, Integral: 0 },
        per_student: { Plus: 0, Integral: 0 },
        minimum: { Plus: 0, Integral: 0 },
        expiration: 0,
        proratedDiscount: undefined,
        type: "full_year",
      });
    }
  }, [selectedScheme]);

  const { mutateAsync: createScheme } = useMutation(() => {
    return axios.post(
      `${deltamathAPI()}/manager_new/price-scheme/create`,
      JSON.stringify(formData),
      withJsonHeader()
    );
  });

  const { mutateAsync: editScheme } = useMutation(() => {
    return axios.put(
      `${deltamathAPI()}/manager_new/price-scheme/update`,
      JSON.stringify(formData),
      withJsonHeader()
    );
  });

  const handleDateChange = (date: Date | null) => {
    if (date) {
      const endOfDayTimestamp = Math.floor(getTime(endOfDay(date)) / 1000);
      setFormData({ ...formData, expiration: endOfDayTimestamp });
    }
  };

  const handleSubmit = async (e?: FormEvent) => {
    if (e) {
      e.preventDefault();
    }
    try {
      if (isEditing) {
        await editScheme();
        onSchemeEdited();
      } else {
        await createScheme();
        onSchemeCreated();
      }
    } catch (e: any) {
      if (e.response.status === 400 && e.response.data instanceof Array) {
        e.response.data.forEach((error: any) => {
          toastContext.addToast({
            message: error.message,
            status: "Error",
          });
        });
      } else {
        toastContext.addToast({
          message: e.response.data.message || "Unable to create scheme",
          status: "Error",
        });
      }
    }
  };

  return (
    <Modal
      visible={visible}
      onClose={onClose}
      title={isEditing ? "Update Price Scheme" : "Create New Price Scheme"}
      confirmButtonText={isEditing ? "Update" : "Create"}
      onConfirm={handleSubmit}
      confirmDisabled={false} // Set this to true if you want to disable the confirm button conditionally
      closeX
      body={
        <div>
          <form className="flex flex-col gap-6" onSubmit={handleSubmit}>
            <div className="flex w-full gap-6 px-4">
              <StyledInput
                label="Display Name"
                value={formData.key}
                type="text"
                disabled={isEditing}
                onChange={(e) =>
                  setFormData({ ...formData, key: e.target.value })
                }
              />
              <div className="flex grow flex-col gap-1">
                <label htmlFor="type">Type</label>
                <select
                  id="type"
                  name="type"
                  value={formData.type}
                  disabled={isEditing}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      type: e.target.value as "pro_rated" | "full_year",
                    })
                  }
                  className={`w-48 rounded px-2 py-1 ${
                    isEditing
                      ? "cursor-not-allowed bg-gray-200 text-gray-500"
                      : "bg-white text-black"
                  }`}
                >
                  <option value="full_year">Full Year</option>
                  <option value="pro_rated">Pro Rated</option>
                </select>
              </div>
            </div>
            <div className="flex w-full gap-6 px-4">
              <StyledInput
                label="Prorated Discount"
                value={formData.proratedDiscount}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    proratedDiscount: e.target.value
                      ? +e.target.value
                      : undefined,
                  })
                }
              />
              <div className="flex grow flex-col gap-1">
                <div className="flex items-center">
                  <DatePicker
                    className="w-48"
                    ref={datePickerRef}
                    onChange={handleDateChange}
                    customInput={
                      <div className="flex grow flex-col gap-1">
                        <label>Expiration</label>
                        <input
                          className="w-48 rounded px-2 py-1"
                          style={{
                            padding: "4px 8px",
                            border: "1px solid #6B7280",
                            fontSize: "1rem",
                            lineHeight: "1.5rem",
                          }}
                          value={formData.expiration}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              expiration: +e.target.value,
                            })
                          }
                        />
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
            <PricingGroup label="Individual">
              <StyledInput
                label="Plus"
                value={formData.individual.Plus}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    individual: {
                      ...formData.individual,
                      Plus: +e.target.value,
                    },
                  })
                }
              />
              <StyledInput
                label="Integral"
                value={formData.individual.Integral}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    individual: {
                      ...formData.individual,
                      Integral: +e.target.value,
                    },
                  })
                }
              />
            </PricingGroup>
            <PricingGroup label="Per Student">
              <StyledInput
                label="Plus"
                value={formData.per_student.Plus}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    per_student: {
                      ...formData.per_student,
                      Plus: +e.target.value,
                    },
                  })
                }
              />
              <StyledInput
                label="Integral"
                value={formData.per_student.Integral}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    per_student: {
                      ...formData.per_student,
                      Integral: +e.target.value,
                    },
                  })
                }
              />
            </PricingGroup>
            <PricingGroup label="Minimum">
              <StyledInput
                label="Plus"
                value={formData.minimum.Plus}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    minimum: { ...formData.minimum, Plus: +e.target.value },
                  })
                }
              />
              <StyledInput
                label="Integral"
                value={formData.minimum.Integral}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    minimum: { ...formData.minimum, Integral: +e.target.value },
                  })
                }
              />
            </PricingGroup>
            <button className="hidden" type="submit">
              Submit
            </button>
          </form>
        </div>
      }
    />
  );
};
