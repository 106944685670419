import { Skeleton } from "../../Skeleton";
import { formatPrice } from "../../../utils/formatPrice";
import { PriceCard } from "./PriceCard";
import clsx from "clsx";
import { useHomepageSignupContext } from "../HomepageSignupContext";
import { calculateAnnualPlanDiscount } from "./calculateAnnualPlanDiscount";

export const PricingConfigurator: React.FC<{
  incrementorType: "blue" | "white";
  accountType: "parent" | "learner" | undefined;
}> = ({ incrementorType, accountType }) => {
  const { plans, numLearners, setNumLearners, selectedPlan } =
    useHomepageSignupContext();

  const handleIncrease = () => {
    setNumLearners(numLearners + 1);
  };

  const handleDecrease = () => {
    if (numLearners > 1) setNumLearners(numLearners - 1);
  };

  if (!plans) {
    return (
      <div className="h-96 w-full">
        <Skeleton />
      </div>
    );
  }

  const [monthlyPlan, annualPlan] = plans;
  const discount = calculateAnnualPlanDiscount(monthlyPlan, annualPlan);

  return (
    <>
      {(!accountType || accountType === "parent") && (
        <div className="flex flex-col gap-2">
          <p className="font-bold">How Many Learners?</p>
          <div className="isolate inline-flex rounded-md text-base">
            <button
              type="button"
              className={clsx(
                "relative inline-flex w-[46px] items-center justify-center rounded-l-md border border-dm-charcoal-800 px-3 py-2 text-sm focus:z-10",
                incrementorType === "blue"
                  ? "bg-dm-brand-blue-100 hover:bg-dm-brand-blue-200"
                  : "bg-white hover:bg-dm-charcoal-100"
              )}
              onClick={handleDecrease}
              aria-label="Decrease the number of learners"
            >
              &minus;
            </button>
            <span
              className="relative -ml-px inline-flex w-[60px] items-center justify-center border border-dm-charcoal-800 bg-white px-3 py-2 text-sm focus:z-10"
              aria-live="polite"
            >
              {numLearners}
            </span>
            <button
              type="button"
              className={clsx(
                "relative -ml-px inline-flex w-[46px] items-center justify-center rounded-r-md border border-dm-charcoal-800 px-3 py-2 text-sm focus:z-10",
                incrementorType === "blue"
                  ? "bg-dm-brand-blue-100 hover:bg-dm-brand-blue-200"
                  : "bg-white hover:bg-dm-charcoal-100"
              )}
              onClick={handleIncrease}
              aria-label="Increase the number of learners"
            >
              +
            </button>
          </div>
          <p className="italic text-dm-gray-500">
            Additional Learners:{" "}
            {selectedPlan?.interval === "monthly"
              ? `${formatPrice(monthlyPlan.additionalLearnerPrice)}/mo`
              : `${formatPrice(annualPlan.additionalLearnerPrice)}/yr`}{" "}
            each
          </p>
        </div>
      )}
      <div className="flex flex-col gap-4 lg:flex-row">
        <PriceCard title="Monthly Plan" plan={monthlyPlan} type="month" />
        <PriceCard
          title="Annual Plan"
          plan={annualPlan}
          type="year"
          discount={discount}
        />
      </div>
    </>
  );
};
