import { Dialog, Transition } from "@headlessui/react";
import Button from "./generic/button";
import { useEndEarly } from "../utils/api";
import StudentSectionsContext from "../_context/StudentSectionsContext";
import { useContext } from "react";
import { updateFullAssignmentData } from "../utils";

type Props = {
  visible: boolean;
  onClose: () => void;
  taId: number;
  isTest?: boolean;
  isUnlimited?: boolean;
};

const EndEarlyModal = (props: Props) => {
  const { activeSection, dmAssignmentData, setDmAssignmentData } = useContext(
    StudentSectionsContext
  );

  const endEarlyMutation = useEndEarly(props.taId);

  const handleEndEarly = () => {
    endEarlyMutation.mutate("", {
      onSuccess: (data: any) => {
        if (data?.data?.assignment) {
          updateFullAssignmentData(
            data.data.assignment,
            props.taId,
            activeSection,
            dmAssignmentData,
            setDmAssignmentData
          );
        }
        props.onClose();
      },
    });
  };

  return (
    <Transition show={props.visible}>
      <Dialog onClose={props.onClose} className="relative z-50">
        <Transition.Child
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-60" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="transform overflow-hidden rounded-2xl bg-white px-6 py-4 text-left align-middle shadow-xl transition-all sm:w-[672px] sm:px-12 sm:py-10">
                <div className="mb-10 flex flex-col gap-6">
                  <Dialog.Title
                    as="h3"
                    className="mb-3 text-2xl font-medium leading-6 text-gray-900"
                  >
                    Are you sure you want to end the{" "}
                    {props.isTest ? "test" : "assignment"}{" "}
                    {props.isUnlimited ? "now" : "early"}?
                  </Dialog.Title>
                  <Dialog.Description className="text-sm">
                    Ending the {props.isTest ? "test" : "assignment"}{" "}
                    {props.isUnlimited ? "now" : "early"} will mean you can no
                    longer work on your answers.
                  </Dialog.Description>
                </div>

                <div className="mt-4 flex flex-col justify-between gap-4 border-t pt-8 sm:flex-row">
                  <Button onClick={props.onClose} type="outline" size="small">
                    No, Keep Working
                  </Button>

                  <Button size="small" onClick={handleEndEarly}>
                    Yes, End The {props.isTest ? "Test" : "Assignment"}{" "}
                    {props.isUnlimited ? "Now" : "Early"}
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default EndEarlyModal;
