import { useRef, useEffect, useState } from "react";
import clsx from "clsx";
import ReactTooltip from "react-tooltip";

type Props = {
  totalSegments?: number;
  currentScore?: number;
  currentRecord?: number;
  showTickMarks?: boolean;
  percentCompleted?: number;
  percentCompletedSecondary?: number;
  solvePage?: boolean;
  tooltipEnabled?: boolean;
  isMuted?: boolean;
  // parent portal related:
  height?: string;
  width?: string;
  isMinimized?: boolean;
  gradientBknd?: boolean;
};

export default function ProgressBar({
  totalSegments = 5,
  currentScore,
  currentRecord,
  showTickMarks,
  percentCompleted,
  percentCompletedSecondary,
  solvePage = false,
  tooltipEnabled = true,
  isMuted = false,
  height = "h-3.5",
  width = "w-24",
  isMinimized = false,
  gradientBknd,
}: Props): JSX.Element {
  const progressBar = useRef<HTMLDivElement>(null);
  const [offsetPos, setOffsetPos] = useState<number>(0);

  const renderSegments = () => {
    const segmentsCombined = [];

    for (let i = 0; i < totalSegments; i++) {
      // const showSolveTooltip = (): boolean =>
      //   !!tooltipEnabled &&
      //   !!solvePage &&
      //   !!currentRecord &&
      //   currentScore !== undefined &&
      //   currentRecord > currentScore &&
      //   currentRecord == i + 1;

      // const segmentOptions = () => {
      //   return {
      //     "data-tip": solveTooltipCopy(),
      //     "data-for": "segment",
      //     "data-effect": "solid",
      //     "data-place": "top",
      //     "data-event": "",
      //     "data-event-off": "",
      //     "data-delay-hide": 300,
      //     "data-delay-show": 300,
      //     "data-iscapture": true,
      //     //ref: recordSegment,
      //   };
      // };

      let bgColor = "bg-dm-charcoal-100";
      if (currentScore && Math.ceil(currentScore) >= i + 1) {
        bgColor = isMuted ? "bg-dm-gray-500" : "bg-dm-brand-blue-600";
      } else if (
        currentRecord &&
        currentScore !== undefined &&
        currentRecord > Math.ceil(currentScore) &&
        currentRecord >= i + 1
      ) {
        bgColor = isMuted ? "bg-dm-charcoal-200" : "bg-dm-brand-blue-200";
      }

      segmentsCombined.push(
        <div
          key={`segment-${i}`}
          className={clsx(
            "block flex-grow first:rounded-l-full last:rounded-r-full",
            "pointer-events-none",
            showTickMarks
              ? `${
                  solvePage ? "border-r-4" : "border-r-2"
                } border-white last:border-none`
              : "",
            bgColor
          )}
          // {...(tooltipBar && showTooltip() ? tooltipOptions() : {})}
          // {...(tooltipBar && showSolveTooltip() ? solveTooltipOptions() : {})}
          // {...(showSolveTooltip() ? segmentOptions() : {})}
        ></div>
      );
    }
    return segmentsCombined;
  };

  const renderFullBar = () => {
    return (
      <div className="relative block flex-grow overflow-hidden rounded-full bg-slate-200">
        {!!percentCompletedSecondary && (
          <div
            className="absolute h-full rounded-full bg-[#5AC3F7]"
            style={{ width: `${percentCompletedSecondary}%` }}
          ></div>
        )}
        <div
          className={clsx(
            "absolute h-full rounded-full",
            // gradientBknd ? "bg-gradient-to-r from-dm-brand-blue-600/50 to-dm-brand-blue-600" : "bg-dm-brand-blue-600",
            //TODO: #2F52E9 a new blue (in Figma) to add to our palette?
            gradientBknd
              ? "bg-gradient-to-r from-[#2F52E9]/50 to-[#2F52E9]"
              : "bg-dm-brand-blue-600"
          )}
          style={{ width: `${percentCompleted}%` }}
        ></div>
      </div>
    );
  };

  const tooltipCopy = () => {
    return `
      Current Score: ${currentScore}<br>
      Record Score: ${currentRecord}
      `;
  };

  const tooltipFlatCopy = () => {
    return `Current Score: ${currentScore}, Record Score: ${currentRecord}`;
  };

  const solveTooltipCopy = () => {
    return `High Score: ${currentRecord}`;
  };

  // useEffect(() => {
  //   if (recordSegment.current) ReactTooltip.show(recordSegment.current);
  // }, [recordSegment.current]);

  const showTooltip = (): boolean => {
    return Boolean(
      tooltipEnabled &&
        !solvePage &&
        currentRecord &&
        currentScore !== undefined &&
        currentRecord < totalSegments &&
        currentScore < totalSegments &&
        currentRecord > currentScore
    );
  };

  const tooltipOptions = () => {
    return {
      "data-tip": tooltipCopy(),
      "data-for": "progressbar-solve",
      "data-effect": "solid",
      "data-multiline": true,
      "data-place": "left,top,bottom",
      "data-delay-hide": 300,
      "data-delay-show": 300,
    };
  };

  const showSolveTooltip = (): boolean =>
    !!tooltipEnabled &&
    !!solvePage &&
    !!currentRecord &&
    currentScore !== undefined &&
    currentRecord > Math.ceil(currentScore);

  const solveTooltipOptions = () => {
    return {
      "data-tip": solveTooltipCopy(),
      "data-for": "segment",
      "data-effect": "solid",
      "data-place": "top",
      "data-event": "",
      "data-event-off": "",
      "data-delay-hide": 300,
      "data-delay-show": 300,
      "data-iscapture": true,
      "data-offset": `{'right': ${offsetPos}}`,
    };
  };

  const handleTooltipPosition = () => {
    if (progressBar?.current && currentRecord && showSolveTooltip()) {
      ReactTooltip.hide(progressBar.current); // temporarily hide the tooltip
      // calculate offset
      const totalWidth = progressBar.current.offsetWidth;
      const segmentWidth = totalWidth / totalSegments;
      const offset = -(totalWidth / 2) + (currentRecord - 0.5) * segmentWidth;
      setOffsetPos(offset);
    }
    ReactTooltip.rebuild();
  };

  useEffect(() => {
    window.addEventListener("resize", handleTooltipPosition);

    return () => {
      window.removeEventListener("resize", handleTooltipPosition);
    };
  }, []);

  useEffect(() => {
    handleTooltipPosition();
  }, [currentScore, currentRecord]);

  return (
    <>
      {showTooltip() || showSolveTooltip() ? (
        <>
          <ReactTooltip id="segment" delayHide={300} />
          <p className="sr-only">
            {showTooltip() ? tooltipFlatCopy() : null}
            {showSolveTooltip() ? solveTooltipCopy() : null}
          </p>
        </>
      ) : null}
      <div
        className={clsx(
          "relative flex py-1",
          solvePage
            ? "h-4 w-full"
            : `${height} ${width} transition-all duration-300 ease-out`,
          showSolveTooltip() && "cursor-pointer"
        )}
        aria-hidden="true"
        ref={progressBar}
        {...(showTooltip() ? tooltipOptions() : {})}
        {...(showSolveTooltip() ? solveTooltipOptions() : {})}
      >
        {showTickMarks ? <>{renderSegments()}</> : renderFullBar()}
        {isMinimized && (
          <div className="absolute w-full text-center text-xs font-bold text-dm-charcoal-800 [text-shadow:_0_1px_1px_rgb(226_232_240_/_60%)]">
            {percentCompleted}%
          </div>
        )}
      </div>
    </>
  );
}
