import { UserHolder } from "../../shared/contexts/UserContext";

/**
 * Take login data from the backend and determine what to do with it. This makes
 * the user logged-in from the frontend perspective
 */
export function processLoginResponse(
  loginData: any,
  userContext: UserHolder,
  reloadOnBadLogin = true
): { success: boolean } {
  if (loginData?.user?.timeOffset !== undefined) {
    loginData.user.timeOffset = loginData.user.timeOffset * 1000 - +Date.now();
  }
  let badlogin = true;
  if (loginData.admin) {
    localStorage.setItem("admin", JSON.stringify(loginData.admin));
    badlogin = false;
  }
  if (loginData.user) {
    localStorage.setItem("user", JSON.stringify(loginData.user));
    badlogin = false;
  }
  if (loginData.customer_service_token) {
    badlogin = false;
    localStorage.setItem(
      "customer_service_token",
      loginData.customer_service_token
    );
  }
  if (loginData.jwt) {
    userContext.setJWT(loginData.jwt);
  }
  if (badlogin) {
    localStorage.clear();
    if (reloadOnBadLogin) {
      window.location.reload();
    }
  }

  return { success: !badlogin };
}
