import { HTMLAttributes, useState } from "react";
import { AccountFormData, Learner } from "../../types";
import { EditAccountInformationModal } from "../Modals/EditAccountInformationModal";
import { useLearnerContext } from "../../contexts/LearnerContext";
import { EditAccountIconButton } from "./EditAccountIconButton";
import { isSubscriptionActive } from "../../utils/isSubscriptionActive";

export const LearnerEditAccountButton: React.FC<
  Omit<HTMLAttributes<HTMLButtonElement>, "onClick" | "aria-label"> & {
    user: Learner;
  }
> = ({ user, ...props }) => {
  const [showModal, setShowModal] = useState(false);
  const learnerContext = useLearnerContext();

  const updateUser = (formData: AccountFormData) => {
    learnerContext.updateLearner(formData);
  };

  if (!isSubscriptionActive(learnerContext.learner)) {
    return null;
  }

  return (
    <>
      <EditAccountIconButton onClick={() => setShowModal(true)} {...props} />
      <EditAccountInformationModal
        user={user}
        visible={showModal}
        onClose={() => setShowModal(false)}
        updateUser={updateUser}
      />
    </>
  );
};
