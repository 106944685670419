import React, { useEffect, useState } from "react";
import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { useDMQuery, timeConverter, REACT_APP_MANAGER_LINK } from "../../utils";
import { deltamathAPI } from "../utils";
import {
  SelectColumnFilter,
  NoFilter,
  TextFilterNoPersist,
} from "../../shared/table/filters";
import { DeltaMathTable } from "../../shared/DeltaMathTable";
import { EditableTextarea } from "../../shared/table/editable";
import { TextFilter } from "../../shared/table/filters";
import { useDeltaToastContext } from "../../shared/contexts/ToasterContext";
import clsx from "clsx";
import { Tooltip } from "../../shared/Tooltip";
import { DmLoadingSpinner } from "../utils/functions";
import { useDocumentTitle } from "usehooks-ts";

function Heaven() {
  useDocumentTitle("Heaven");
  const params = useParams();
  const navigate = useNavigate();
  const toastContext = useDeltaToastContext();
  const queryClient = useQueryClient();
  const token = localStorage.getItem("customer_service_token");
  const [dataByYear, setDataByYear] = useState<{ [key: string]: any[] }>({});
  const now = new Date();

  const { data, refetch, isLoading } = useDMQuery({
    path: "/manager_new/invoices/activated",
    customerServiceHeader: true,
    queryOptions: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: 1000 * 60 * 5,
    },
  });

  useEffect(() => {
    /**
     * construct a new data object with license data segregated by school year.
     * {
     *   '22-23': [{...licensedata}, {...licenseData2}],
     *   '23-24':[{...licensedata}, {...licenseData2}]
     * }
     */
    if (data && Array.isArray(data)) {
      const newData = data.reduce(
        (acc: { [key: string]: [value: any[]] }, entry) => {
          let schoolYear = "Unknown";
          if (entry.expiration) {
            const date = new Date(entry.expiration * 1000);
            if (date.getFullYear() === now.getFullYear()) {
              schoolYear = date.getFullYear().toString();
            } else if (date.getFullYear() > now.getFullYear()) {
              schoolYear = (now.getFullYear() + 1).toString();
            }
          } else if (entry.schoolYear) {
            schoolYear = `20${entry.schoolYear.slice(3, 5)}` || "Unknown"; //will need to be updated next century
          }
          if (Object.keys(acc).indexOf(schoolYear) !== -1) {
            return { ...acc, [schoolYear]: acc[schoolYear].concat(entry) };
          } else return { ...acc, [schoolYear]: [entry] };
        },
        { All: data }
      );
      setDataByYear(newData);
    }
  }, [data]);

  const updateQuoteMutation = useMutation(
    ({
      quoteNumber,
      field,
      value,
    }: {
      quoteNumber: string | number | null;
      field: string;
      value: number | null;
    }) => {
      const payload: {
        [key: string]: number | null;
      } = {};
      payload[field] = value;
      return axios.put(
        deltamathAPI() + `/manager_new/invoices/${quoteNumber}`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    {
      onSuccess: (data) => {
        if (data.data.success && data.data.message) {
          queryClient.invalidateQueries("/manager_new/invoices");
          refetch();
          toastContext.addToast({
            status: "Success",
            message: data.data.message,
          });
        }
      },
    }
  );

  const columns = React.useMemo(
    () => [
      {
        Header: "Quote",
        minWidth: 80,
        accessor: "quoteNumber",
        Filter: NoFilter,
        Cell: ({ cell }: { cell: any }) => (
          <div className="w-100 text-center">
            {cell.row.values.quoteNumber ? (
              <Link
                className="text-indigo-500 hover:underline"
                to={`${REACT_APP_MANAGER_LINK}/quote-lookup/${cell.row.values.quoteNumber}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {cell.row.values.quoteNumber}
              </Link>
            ) : (
              <>-</>
            )}
          </div>
        ),
      },
      {
        Header: "Invoice Number",
        accessor: "invoiceNumber",
        Filter: TextFilterNoPersist,
      },
      {
        Header: "NCES ID",
        accessor: "id",
        Filter: TextFilter,
      },
      {
        Header: "PO Number",
        accessor: "poNumber",
        Filter: TextFilterNoPersist,
        width: 90,
      },
      {
        Header: "Status",
        minWidth: 240,
        accessor: "status",
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "Entity Name",
        accessor: "name",
        Filter: TextFilterNoPersist,
      },
      {
        Header: "PO Uploaded",
        accessor: "poUploadedTime",
        Filter: NoFilter,
        width: 90,
        Cell: ({ cell }: { cell: any }) => (
          <div className="w-full text-center">
            {timeConverter(cell.row.values.poUploadedTime)}
          </div>
        ),
      },
      {
        Header: "Admin",
        accessor: "adminFirst",
        width: 120,
        Filter: NoFilter,
        Cell: ({ cell }: { cell: any }) => {
          const fullName =
            cell.row.original.adminFirst + " " + cell.row.original.adminLast;
          return (
            <>
              {fullName.length > 20 ? (
                <Tooltip message={fullName}>
                  <div className="w-full text-center">
                    {fullName.substring(0, 15) + " ..."}
                  </div>
                </Tooltip>
              ) : (
                <div className="w-full text-center">{fullName}</div>
              )}
            </>
          );
        },
      },
      {
        Header: "Admin Email",
        accessor: "adminEmail",
        Filter: TextFilterNoPersist,
      },
      {
        Header: "Contact Name",
        accessor: "contactName",
        Filter: TextFilterNoPersist,
      },
      {
        Header: "Contact Email",
        accessor: "contactEmail",
        Filter: TextFilterNoPersist,
      },
      {
        Header: "License Tier",
        minWidth: 200,
        accessor: "licenseTier",
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "Rostering",
        minWidth: 200,
        accessor: "rosteringSystem",
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "LMS",
        minWidth: 200,
        accessor: "lms",
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "Integration Status",
        accessor: "integrationStatus",
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "License Type",
        accessor: "type",
        Filter: SelectColumnFilter,
        minWidth: 140,
        filter: "equals",
        Cell: ({ cell }: { cell: any }) => {
          const licenseType = cell.row.values.type;
          let displayValue;
          if (licenseType === "school") {
            displayValue = "School";
          } else if (licenseType === "school_custom") {
            displayValue = "School (No NCES ID)";
          } else displayValue = licenseType;
          return (
            <div
              className="w-full"
              key={cell.row.values.id + cell.row.values.type}
            >
              {displayValue}
            </div>
          );
        },
      },
      {
        Header: "Price",
        minWidth: 50,
        accessor: "priceInfo.price",
        Filter: NoFilter,
      },
      {
        Header: "Notes",
        accessor: "notes",
        Filter: NoFilter,
        minWidth: 360,
        Cell: ({ cell }: { cell: any }) => (
          <EditableTextarea
            key={cell.row.values.quoteNumber}
            value={cell.row.values.notes}
            row={cell.row}
            updateMutation={updateQuoteMutation}
          />
        ),
      },
    ],
    []
  );

  const [tabs, setTabs] = useState<NavigationTabInterface[]>();

  useEffect(() => {
    if (params.year) {
      updateTabs(params.year);
    } else {
      navigate(`${REACT_APP_MANAGER_LINK}/heaven/All`, {
        replace: true,
      });
    }
  }, [params, dataByYear]);

  useEffect(() => {
    // construct navigation tabs
    if (dataByYear && Object.keys(dataByYear).length) {
      const newTabs = Object.keys(dataByYear).reduce(
        (acc: any[], option: string) => {
          return acc.concat({
            name: option,
            href: option.replaceAll(" ", "_"),
            current: option === "All" ? true : false,
          });
        },
        []
      );
      setTabs(newTabs);
    }
  }, [dataByYear]);

  const updateTabs = (newActive: string) => {
    const newTabs = tabs?.map((tab: NavigationTabInterface) => {
      if (tab.href === newActive) {
        tab.current = true;
      } else tab.current = false;
      return tab;
    });
    setTabs(newTabs);
  };

  return (
    <>
      {tabs && (
        <div>
          <HeavenNav tabs={tabs} />
        </div>
      )}
      {isLoading && <DmLoadingSpinner message={"Loading..."} />}

      {dataByYear &&
        Object.keys(dataByYear).map((section: any) => (
          <>
            {params && params.year === section.replaceAll(" ", "_") && (
              <DeltaMathTable
                key={section}
                columns={columns}
                data={dataByYear[section]}
                updateMutation={updateQuoteMutation}
                options={{
                  globalFilter: true,
                  selectable: false,
                  initialState: {
                    sortBy: [
                      {
                        id: "quoteNumber",
                        desc: true,
                      },
                    ],
                  },
                  stickyHeader: true,
                }}
              />
            )}
          </>
        ))}
    </>
  );
}

export default Heaven;

interface NavigationTabInterface {
  name: string;
  href: string;
  current: boolean;
}

const HeavenNav = ({ tabs }: { tabs: NavigationTabInterface[] }) => {
  const navigate = useNavigate();

  return (
    <div>
      {/* would we ever need to view this on a small screen? */}
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        {tabs && (
          <select
            id="tabs"
            name="tabs"
            onChange={(e) => {
              e.preventDefault();
              navigate(
                `${REACT_APP_MANAGER_LINK}/heaven/${e.target.value.replaceAll(
                  " ",
                  "_"
                )}`,
                {
                  replace: true,
                }
              );
            }}
            className="focus:border-dm-darkest-blue-500 block w-full rounded-md border-gray-300 focus:ring-dm-darkest-blue"
            defaultValue={
              tabs?.find((tab: { current: boolean }) => tab?.current)?.name
            }
          >
            {tabs.map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        )}
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex" aria-label="Tabs">
            {tabs.map((tab) => (
              <NavLink
                key={tab.name}
                to={`${REACT_APP_MANAGER_LINK}/heaven/${tab.href}`}
                className={clsx(
                  tab.current
                    ? "border-dm-darkest-blue text-dm-darkest-blue"
                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                  "w-1/4 border-b-2 px-1 py-4 text-center text-sm font-medium"
                )}
                aria-current={tab.current ? "page" : undefined}
              >
                {tab.name}
              </NavLink>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};
