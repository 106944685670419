export const Loading: React.FC = () => (
  <div className="relative z-40">
    <div className="fixed inset-0 bg-black bg-opacity-60" />
    <div className="fixed inset-0 flex min-h-full flex-col items-center justify-center gap-2">
      <div
        style={{ borderTopColor: "transparent" }}
        className="h-16 w-16 animate-spin rounded-full border-4 border-solid border-blue-400"
      ></div>
      <h2 className="text-center text-xl font-bold text-white">Loading...</h2>
    </div>
  </div>
);
