import { Disclosure, Transition } from "@headlessui/react";
import { LearnerAssignment, LearnerAssignmentSkillData } from "../../../types";
import Chevron from "../../Chevron";
import { format } from "date-fns/esm";
import { findIndex, concat, max } from "lodash";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useCourseContext } from "../../../contexts/CourseContext";
import { REACT_APP_LEARNER_LINK, getFilePath } from "../../../../utils";
import { ResponsiveImage } from "../../ResponsiveImage";
import { SparkleIcon } from "../../../../shared/icons/SparkleIcon";

type Props = {
  assignment: LearnerAssignment;
  courseId: string;
  unitId: string;
  subunitId: string;
};
const PostQuizSummary = (props: Props) => {
  const courseContext = useCourseContext();
  const courseData = courseContext.getCourseData(props.courseId);
  const unitData = courseContext.getUnitData(props.unitId, undefined);
  const subunitData = courseContext.getSubunitData(
    props.subunitId,
    undefined,
    undefined
  );

  type assignmentType = {
    grade: number;
    skills: LearnerAssignmentSkillData[];
    submitted?: Date;
    created: Date;
    _id: string;
    scoreDifference?: number;
    current: number;
    duration?: number;
    pointsEarned?: number;
    badPostQuizResult: boolean;
  };

  const assignmentSkills: Array<assignmentType> = concat(
    props.assignment.resultHist
      ? props.assignment.resultHist.map((x, i) => {
          const previousResults =
            i > 0 ? (props.assignment.resultHist || []).slice(0, i) : [];
          const bestGrade = max(previousResults.map((rh) => rh.grade));

          const scoreDiff =
            i > 0 && bestGrade !== undefined ? x.grade - bestGrade : undefined;

          const badPostQuizResult =
            x.grade < 0.7 && previousResults.some((pr) => pr.grade >= 0.7);

          return {
            ...x,
            scoreDifference: scoreDiff,
            current: 0,
            badPostQuizResult: badPostQuizResult,
          };
        })
      : [],
    props.assignment?.submitted
      ? {
          grade: props.assignment.grade,
          skills: props.assignment.skills,
          submitted: props.assignment.submitted,
          created: props.assignment.createdAt,
          _id: props.assignment._id,
          scoreDifference: props.assignment.resultHist
            ? props.assignment.grade -
              (max(props.assignment.resultHist?.map((rh) => rh.grade)) || 0)
            : undefined,
          current: 1,
          duration: props.assignment.duration,
          pointsEarned: props.assignment.pointsEarned,
          badPostQuizResult:
            props.assignment.grade < 0.7 && props.assignment.maxGrade >= 0.7,
        }
      : []
  );

  return (
    <>
      {assignmentSkills.map((assignment: assignmentType) => {
        const date =
          assignment?.submitted &&
          format(new Date(assignment.submitted), "MMM do, yyyy, h:mmaaa");
        const score = Math.round((assignment?.grade || 0) * 100);
        const durationMins = Math.round((assignment.duration || 0) / 60);

        const isFirst = assignment.current === 1;

        const scoreDiff =
          assignment.scoreDifference !== undefined
            ? Math.round(assignment.scoreDifference * 100)
            : undefined;

        const correctFraction = () => {
          const numberCorrect = assignment.skills.filter(
            (s: LearnerAssignmentSkillData) => s.score === 1
          ).length;
          const numberTotal = assignment.skills.length;
          return `(${numberCorrect} out of ${numberTotal})`;
        };

        const questionList = assignment.skills.map(
          (skill: LearnerAssignmentSkillData, index: number) => (
            <Link
              to={`${REACT_APP_LEARNER_LINK}/${
                courseData?.path || props.courseId
              }/${unitData?.path || props.unitId}/${
                subunitData?.path || props.subunitId
              }/postquiz/${index}${
                !isFirst && assignment.submitted
                  ? `/${Number(new Date(assignment.submitted))}`
                  : ""
              }`}
              key={`question-${index}-${Number(new Date(assignment.created))}`}
              className="group align-middle leading-normal text-dm-gray-500"
            >
              {skill.score === 1 ? (
                <i
                  className="far fa-check fa-fw mr-1 align-middle text-xl leading-none !text-dm-success-500"
                  aria-hidden="true"
                />
              ) : (
                <i
                  className="far fa-times fa-fw mr-1 align-middle text-xl leading-none !text-dm-error-500"
                  aria-hidden="true"
                />
              )}
              <span className="align-middle group-hover:underline">
                Question {index + 1}
              </span>
            </Link>
          )
        );

        const firstIncorrect = () => {
          const firstWrong = findIndex(
            assignment.skills,
            (skill: LearnerAssignmentSkillData) => skill.score === 0
          );

          return `${REACT_APP_LEARNER_LINK}/${
            courseData?.path || props.courseId
          }/${unitData?.path || props.unitId}/${
            subunitData?.path || props.subunitId
          }/postquiz/${firstWrong}${
            !isFirst && assignment.submitted
              ? `/${Number(new Date(assignment.submitted))}`
              : ""
          }`;
        };

        return (
          <Disclosure
            as="div"
            className={clsx(
              "mb-2 rounded-lg border border-dm-charcoal-100 bg-white",
              "hover:border-dm-charcoal-200 hover:shadow-[2px_2px_10px_0px_rgba(0,0,0,0.08)]"
            )}
            key={`attempt-${assignment._id}`}
            defaultOpen={!!isFirst}
          >
            {({ open }) => (
              <>
                <Disclosure.Button
                  className={clsx(
                    "flex w-full items-center justify-between gap-x-2 px-10 py-8 text-left font-serif text-lg font-bold leading-none"
                    // open && "pb-4"
                  )}
                >
                  <span>{date}</span>
                  <Chevron open={open} />
                </Disclosure.Button>
                <Transition
                  show={open}
                  enter="transition-opacity duration-150"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Disclosure.Panel
                    static
                    className="mb-6 px-10 text-sm text-gray-500"
                  >
                    <>
                      {score >= 70 ? (
                        <>
                          <ResponsiveImage
                            className="mx-auto h-auto w-56"
                            srcs={[
                              getFilePath(
                                "/images/learner/postquiz/summary-page-celebrate.png"
                              ),
                              getFilePath(
                                "/images/learner/postquiz/summary-page-celebrate@2x.png"
                              ),
                            ]}
                            alt=""
                            aria-hidden="true"
                          />
                          <h2 className="mb-2 mt-8 font-serif text-2xl font-bold text-dm-charcoal-500">
                            Section complete - nice work!
                          </h2>
                          <p className="mx-auto mb-3 w-full lg:w-3/5">
                            You&rsquo;ve demonstrated a solid understanding of
                            this material. Looks like you know your stuff!
                          </p>
                        </>
                      ) : (
                        <>
                          <ResponsiveImage
                            className="mx-auto h-auto w-56"
                            srcs={[
                              getFilePath(
                                "/images/learner/postquiz/postquiz-summary-retake.png"
                              ),
                              getFilePath(
                                "/images/learner/postquiz/postquiz-summary-retake@2x.png"
                              ),
                            ]}
                            alt=""
                            aria-hidden="true"
                          />
                          <h2 className="mb-2 mt-8 font-serif text-2xl font-bold text-dm-charcoal-500">
                            {assignment.badPostQuizResult ? (
                              <>Ready to move on or want to try again?</>
                            ) : (
                              <>Let&rsquo;s give that another try!</>
                            )}
                          </h2>
                          <p className="mx-auto mb-3 w-full lg:w-3/5">
                            {assignment.badPostQuizResult ? (
                              <>
                                You scored {score}% on this attempt of the
                                post-quiz. Since you previously scored above
                                70%, you&rsquo;ve already successfully completed
                                this section. You&rsquo;re welcome to retake the
                                post-quiz for extra practice, or move on to your
                                next challenge.
                              </>
                            ) : (
                              <>
                                You got a {score}% on the post-quiz. Don&rsquo;t
                                worry, you can improve your score! Review your
                                answers, learn from any mistakes, and retake the
                                post-quiz to complete this section.
                              </>
                            )}
                          </p>
                          <h3 className="font-bold text-dm-charcoal-500">
                            Score: {score}%{" "}
                            <span className="text-dm-charcoal-100">|</span>{" "}
                            {durationMins +
                              " Minute" +
                              (durationMins === 1 ? "" : "s")}{" "}
                            Spent
                          </h3>
                        </>
                      )}
                      <h3 className="mb-2 mt-10 w-full text-left text-base font-bold text-dm-charcoal-500">
                        Post-Quiz Stats
                      </h3>
                      <div className="mx-auto grid grid-cols-1 gap-x-6 gap-y-2 text-dm-charcoal-500 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                        <div className="rounded-2xl border border-dm-charcoal-100 p-6 text-left">
                          <h4 className="flex items-center justify-between align-middle">
                            <span>
                              <i
                                className="fas fa-percentage pr-2 align-middle text-xl leading-none"
                                aria-hidden="true"
                              ></i>
                              <span className="align-middle">Score</span>
                            </span>
                            {scoreDiff && scoreDiff > 0 ? (
                              <div className="rounded-lg bg-dm-success-500 px-3 py-2 leading-none text-white">
                                {`+${scoreDiff}%`}
                              </div>
                            ) : null}
                          </h4>
                          <h5 className="mt-3 font-serif text-2xl font-bold">
                            {score}% {correctFraction()}
                          </h5>
                        </div>
                        <div className="rounded-2xl border border-dm-charcoal-100 p-6">
                          <h5 className="text-left">
                            <i
                              className="far fa-clock pr-2 align-middle text-xl leading-none"
                              aria-hidden="true"
                            ></i>
                            <span className="align-middle">Time Spent</span>
                          </h5>
                          <p className="mt-3 text-left font-serif text-2xl font-bold">
                            {durationMins +
                              " min" +
                              (durationMins === 1 ? "" : "s")}
                          </p>
                        </div>
                        <div className="rounded-2xl border border-dm-charcoal-100 p-6">
                          <h5 className="text-left">
                            <SparkleIcon className="h-7 w-7 pr-2 align-middle" />
                            <span className="align-middle">Points Earned</span>
                          </h5>
                          <p className="mt-3 text-left font-serif text-2xl font-bold">
                            {assignment.pointsEarned || 0}{" "}
                            {assignment.pointsEarned === 1 ? "pt" : "pts"}
                          </p>
                        </div>
                      </div>
                      <div className="mt-6 rounded-2xl bg-dm-background-blue-100 p-6">
                        <h3 className="mb-6 w-full text-left text-base font-bold text-dm-charcoal-500">
                          Question Breakdown
                        </h3>
                        <div className="grid grid-flow-row grid-cols-2 gap-x-2 gap-y-3 text-left lg:grid-cols-4 xl:grid-cols-6">
                          {questionList}
                        </div>
                      </div>
                      {assignment?.grade < 1 && (
                        <div className="mt-6 flex flex-col items-center justify-between gap-2 rounded-2xl border border-dm-charcoal-100 p-6 sm:flex-row">
                          <h3 className="text-left text-base font-bold text-dm-charcoal-500">
                            Ready for what&rsquo;s next?
                          </h3>
                          <Link
                            to={firstIncorrect()}
                            className={
                              "rounded bg-dm-brand-blue-500 px-8 py-1 text-sm leading-6 text-white hover:bg-dm-brand-blue-600 active:bg-dm-brand-blue-800 disabled:cursor-not-allowed disabled:opacity-50 max-md:w-full"
                            }
                          >
                            Review Incorrect Answers
                          </Link>
                        </div>
                      )}
                    </>
                  </Disclosure.Panel>
                </Transition>
              </>
            )}
          </Disclosure>
        );
      })}
    </>
  );
};

export default PostQuizSummary;
