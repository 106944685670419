import {
  renderA11yString,
  CustomSettingInterface as a11ySettingInterface,
} from "../../student/utils/render-a11y-string";

export const getCustomPromptA11y = (
  string: string,
  settings: a11ySettingInterface
): JSX.Element | undefined => {
  try {
    const customPromptA11y = renderA11yString(
      string,
      undefined,
      true,
      settings
    );
    const promptA11yElement =
      typeof customPromptA11y === "string"
        ? customPromptA11y
        : customPromptA11y.a11yJSX;
    return (
      <div className="sr-only" id="promptAria">
        {promptA11yElement}
      </div>
    );
  } catch (e) {
    console.log("could not set custom a11y for prompt " + e);
    return undefined;
  }
};
