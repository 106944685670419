import { useState } from "react";
import Button from "../../../../student/components/generic/button";
import { ChangePasswordBeta } from "./ChangePasswordBeta";
import { ProfileSection } from "./ProfileSection";
import { Profile } from "./Profile";
import { LearnerPage } from "../../Layouts/LearnerPage";
import { format } from "date-fns";

export const LearnerProfileBeta: React.FC = () => {
  const [showForm, setShowForm] = useState<boolean>(false);
  const jsonUser = localStorage.getItem("user");

  // TODO: error handling for when/if user is null / no access to local storage?
  if (!jsonUser) return null;

  const user = JSON.parse(jsonUser);

  return (
    <LearnerPage title="Profile">
      <Profile>
        <ProfileSection
          header="Full Name"
          text={`${user.first} ${user.last}`}
        />
        <ProfileSection header="User Name" text={user.email} />
        <ProfileSection
          header={showForm ? "Change Password" : "Password"}
          text={"*".repeat(16)}
          showText={!showForm}
        >
          {!showForm ? (
            <Button
              type="link"
              onClick={() => setShowForm(true)}
              className="!py-0 text-left"
            >
              Change Password
            </Button>
          ) : (
            <ChangePasswordBeta hideForm={() => setShowForm(false)} />
          )}
        </ProfileSection>
        <ProfileSection
          header="Start Date"
          text={format(new Date(user.createdAt), "MMMM' 'd', 'yyyy")}
        />
      </Profile>
    </LearnerPage>
  );
};
