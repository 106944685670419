import { useContext, Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/solid";
import { useUserContext } from "../../shared/contexts/UserContext";
import StudentSectionsContext from "../_context/StudentSectionsContext";
import Button from "./generic/button";
import { skillToSolve, getAssignmentDueDateType } from "../utils";
import { REACT_APP_STUDENT_LINK } from "../../utils";

export default function BackToHome({
  type,
  className,
  disabled,
  setSidebarOpen,
}: {
  type: "primary" | "secondary" | "outline" | "link";
  className?: string;
  disabled?: boolean;
  setSidebarOpen?: Dispatch<SetStateAction<boolean>>;
}): JSX.Element {
  const {
    activeSection,
    setOpenAssignment,
    showKeyboard,
    showCalculator,
    setShowKeyboard,
  } = useContext(StudentSectionsContext);
  const userContext = useUserContext();
  const isLocked = userContext.getIsLocked();
  const lti = JSON.parse(
    localStorage.getItem("lti_assignment_payload") || "{}"
  ).isLtiResourceLaunch;
  const navigate = useNavigate();
  const solveSkill: any = skillToSolve();

  const backButtonUrl = `${REACT_APP_STUDENT_LINK}/${activeSection}/${getAssignmentDueDateType(
    solveSkill?.sa?.status
  )}`;

  const handleClick = () => {
    if (typeof setSidebarOpen === "function") {
      setSidebarOpen(false);
    }

    setOpenAssignment(solveSkill?.ta?._id);

    if (type !== "link") {
      navigate(backButtonUrl);
    }
    if (showKeyboard && !showCalculator) {
      setShowKeyboard(false);
    }
  };

  const children: JSX.Element =
    type === "link" ? (
      <div className="inline-flex items-center gap-x-1.5">
        <ArrowLeftIcon
          className="h-5 w-5 text-dm-gray-200"
          aria-hidden="true"
        />
        <div>Back to Home</div>
      </div>
    ) : (
      <>Back to Home</>
    );

  return (
    <>
      {!isLocked && !lti && (
        <Button
          type={type}
          disabled={disabled}
          className={className}
          {...(type === "link"
            ? { href: backButtonUrl, onClick: handleClick }
            : { onClick: handleClick })}
        >
          {children}
        </Button>
      )}
    </>
  );
}
