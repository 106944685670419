import Button from "../../../../student/components/generic/button";
import {
  getFilePath,
  REACT_APP_LEARNER_LINK,
  unreachableCase,
} from "../../../../utils";
import { useParentContext } from "../../../contexts/ParentContext";
import { ResponsiveImage } from "../../ResponsiveImage";
import { ResendInviteButton } from "./ResendInviteButton";

type Case = "username" | "usernames" | "email" | "emails" | "mixed";

export const AddLearnersOutro: React.FC = () => {
  const { learners } = useParentContext();

  let addCase: Case;
  if (learners.length === 1) {
    if (learners[0].inviteCode) addCase = "email";
    else addCase = "username";
  } else {
    if (learners.every((l) => l.inviteCode !== undefined)) addCase = "emails";
    else if (learners.some((l) => l.inviteCode !== undefined))
      addCase = "mixed";
    else addCase = "usernames";
  }

  let title: string, body: string;
  switch (addCase) {
    case "email":
      title = "Learner Invite Sent!";
      body = `We've sent an invitation to ${learners[0].email}. They'll need to accept it to access their DeltaMath for Home account.`;
      break;
    case "username":
      title = "Learner Account Created!";
      body =
        "You've added a learner profile to your account. They can login using the username and password you selected.";
      break;
    case "emails":
      title = "Invitations Sent!";
      body =
        "We've sent invitations to the email addresses you provided. Each learner will need to accept their invitation to access their DeltaMath for Home account.";
      break;
    case "mixed":
      title = "You're Almost There!";
      body =
        "Invites are sent, and usernames are created. Once your learners accept and log in, you can track their progress in one place.";
      break;
    case "usernames":
      title = "Learner Accounts Created!";
      body =
        "You've added multiple learner profiles to your account. They can login using the usernames and passwords you selected.";
      break;
    default:
      unreachableCase(addCase);
  }

  return (
    <div className="flex w-full flex-col items-center gap-10 px-4">
      <div className="flex w-[424px] max-w-full flex-col text-center">
        <div className="flex justify-center">
          <ResponsiveImage
            className="mb-6 flex-none"
            srcs={[
              getFilePath("/images/learner/parent/deltie-stars.png"),
              getFilePath("/images/learner/parent/deltie-stars@2x.png"),
            ]}
            alt="Deltie seeing stars"
            aria-hidden
          />
        </div>
        <h1 className="mb-2 font-serif text-2xl font-bold text-dm-brand-blue-600">
          {title}
        </h1>
        <p>{body}</p>
      </div>
      <div className="flex w-[524px] max-w-full flex-col gap-6 sm:flex-row">
        {learners.every((l) => l.inviteCode !== undefined) && (
          <ResendInviteButton learnerId={learners[0]._id} />
        )}
        <Button
          className="flex h-10 w-full items-center justify-center gap-2 px-0 text-sm"
          href={`${REACT_APP_LEARNER_LINK}/parent`}
        >
          Explore Parent Dashboard <i className="far fa-arrow-right" />
        </Button>
      </div>
    </div>
  );
};
