import clsx from "clsx";
import { uniqueId } from "lodash";
import { ChangeEventHandler, useState } from "react";

type Props = {
  label: string;
  value: string;
  error: string | undefined;
  onChange: ChangeEventHandler<HTMLInputElement>;
};

const PasswordBox = (props: Props) => {
  const [hidden, setHidden] = useState<boolean>(true);

  const id = uniqueId(props.label.toLowerCase().replace(" ", "-"));
  return (
    <div className="flex flex-col gap-1">
      <label
        className={clsx(
          "text-sm font-bold leading-6",
          props.error ? "text-dm-error-500" : "text-dm-charcoal-600"
        )}
        htmlFor={id}
      >
        {props.label}
      </label>
      <div className="relative">
        <input
          className={clsx(
            "w-full rounded-md",
            props.error ? "border-dm-error-500" : "border-dm-gray-200"
          )}
          id={id}
          type={hidden ? "password" : "text"}
          value={props.value}
          onChange={props.onChange}
          required
        />
        <button
          type="button"
          className={`far fa-eye${
            !hidden ? "-slash" : ""
          } absolute right-2 top-1/2 -translate-y-1/2 transform`}
          onClick={() => setHidden(!hidden)}
        />
      </div>
      {props.error && (
        <p className="text-xs text-dm-error-500" role="alert">
          {props.error}
        </p>
      )}
    </div>
  );
};

export default PasswordBox;
