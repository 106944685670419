import { useState } from "react";
import LoginForm from "./LoginForm";
import ForgotPassword from "./ForgotPassword";
import ResetSent from "./ResetSent";
import ResetPassword from "./ResetPassword";

type Props = {
  setLoggedIn?: (loggedIn: boolean) => void;
  setPageState?: (
    pageState: "login" | "forgot" | "reset" | "resetSent"
  ) => void;
  dontHaveAnAccount?: boolean;
};

const LoginFormSwitcher = (props: Props) => {
  const params = new URLSearchParams(window.location.search);
  const reset = params.get("reset");

  const [pageState, setPageState] = useState<
    "login" | "forgot" | "reset" | "resetSent"
  >(reset ? "reset" : "login");

  // this is weird, it's basically to handle allow the the parent to know what state the page is in
  // this is used by the Login.tsx file to manage the images that are getting displayed
  // However handling the true state here will allow us to seemlessly throw this form into a modal
  const changeState = (
    newState: "login" | "forgot" | "reset" | "resetSent"
  ) => {
    if (props.setPageState) {
      props.setPageState(newState);
    }
    setPageState(newState);
  };

  if (pageState === "forgot") {
    return <ForgotPassword updatePageState={changeState} />;
  }
  if (pageState === "resetSent") {
    return <ResetSent />;
  }
  if (pageState === "reset" && reset) {
    return <ResetPassword token={reset} backToLogin={changeState} />;
  }

  // Default to login or pageState === "login"
  return (
    <LoginForm
      setLoggedIn={props.setLoggedIn}
      updatePageState={changeState}
      dontHaveAnAccount={props.dontHaveAnAccount}
    />
  );
};

export default LoginFormSwitcher;
