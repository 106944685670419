import { LearnerAssignment } from "../../types";
import { useLearnerContext } from "../../contexts/LearnerContext";
import StatusTags from "./StatusTags";
import { useProblemSolvingContext } from "../../contexts/ProblemSolvingContext";

type Props = {
  skillCode: string;
  skippedProblem?: boolean;
  optional?: boolean;
  questionTitle?: string;
  solutionShowing?: boolean;
  showExampleButton?: boolean;
  postQuizOrTest?: boolean;
  skillName?: string;
  baseUrl?: string;
  skillPracticeUrl?: string;
  skillIndex: number;
  assignment: LearnerAssignment;
  showVideo?: boolean;
};

const StatusTagsWrapper = (props: Props) => {
  const { logData } = useProblemSolvingContext();
  const learnerContext = useLearnerContext();

  const learnerProgress = learnerContext.getProgress(props.assignment.courseId);
  const currentUnitProgress = learnerProgress?.units.find(
    (unit) => unit.unitId === props.assignment.unitId
  );
  const skill = props.assignment.skills[props.skillIndex];
  // if the assignment is not a unit or course test use the subunit id from the assignment
  // otherwise the subunit id is found on the skill so we know where to link
  const currentSubunitProgress = currentUnitProgress?.subunits.find(
    (subunit) =>
      subunit.subunitId ===
      (props.assignment.subunitId
        ? props.assignment.subunitId
        : skill.subunitId)
  );
  const unlockedQuestionSwap = learnerContext.isPrizeUnlocked("questionSwap");
  const unlockedTestHint = learnerContext.isPrizeUnlocked("testHint");

  return (
    <StatusTags
      skillCode={props.skillCode}
      logData={logData}
      skippedProblem={props.skippedProblem}
      optional={props.optional}
      questionTitle={props.questionTitle}
      solutionShowing={props.solutionShowing}
      showExampleButton={props.showExampleButton}
      postQuizOrTest={props.postQuizOrTest}
      skillName={props.skillName}
      baseUrl={props.baseUrl}
      skillPracticeUrl={props.skillPracticeUrl}
      skillIndex={props.skillIndex}
      practiceInitialized={currentSubunitProgress?.practice ? true : false}
      courseId={props.assignment.courseId}
      assignmentId={props.assignment._id}
      assignmentType={props.assignment.type}
      questionSwap={{
        unlocked: unlockedQuestionSwap,
        type: props.assignment.type,
      }}
      testHint={{
        unlocked: unlockedTestHint,
        used: !!props.assignment?.testHint,
        type: props.assignment.type,
      }}
      showVideo={props.showVideo}
    />
  );
};

export default StatusTagsWrapper;
