import { useState, useLayoutEffect } from "react";
import { useParams } from "react-router-dom";
import { format } from "date-fns/esm";
import {
  LearnerAssignmentSkillData,
  LearnerAssignmentTypes,
} from "../../../types";
import Accordion from "../../Accordion";
import { PastAttemptItem } from "./PastAttemptItem";

type Props = {
  type: LearnerAssignmentTypes;
  grade: number;
  skills: LearnerAssignmentSkillData[];
  submitted?: Date | undefined;
  created: Date;
  _id: string;
  baseUrl: string;
  lastAttempt: boolean;
  questionSwap?: string;
};

const PastAttempt = (props: Props) => {
  const { indexOfSkill, submittedTime } = useParams();
  const date = format(
    props?.submitted ? new Date(props.submitted) : new Date(),
    `MMM do${props?.submitted ? ", h:mmaaa" : ""}`
  );
  const attemptTime = !!props?.submitted && Number(new Date(props.submitted));

  const defaultOpen =
    (["postQuiz", "unitTest", "courseTest"].includes(props.type) &&
      !!attemptTime &&
      submittedTime !== undefined &&
      attemptTime === Number(submittedTime)) ||
    (props.lastAttempt && submittedTime === undefined);

  const [isOpen, setIsOpen] = useState<boolean>(defaultOpen);

  useLayoutEffect(() => {
    setIsOpen(defaultOpen);
  }, [defaultOpen, indexOfSkill]);

  return (
    <>
      <div className="overflow-y-auto">
        <Accordion
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          titleButton={
            <>
              <i className="far fa-folder fa-fw text-xl leading-none text-dm-gray-200"></i>
              <div className="group flex flex-grow items-center gap-x-1.5 text-left text-sm font-bold">
                <span className="text-dm-gray-800">{date}</span>
                <span className="text-dm-charcoal-100 group-hover:text-dm-charcoal-200">
                  |
                </span>
                <span className="text-dm-gray-800">
                  {props?.submitted
                    ? `${Math.round(props.grade)}%`
                    : "In Progress"}
                </span>
              </div>
            </>
          }
          panel={props.skills?.map(
            (skill: LearnerAssignmentSkillData, index: number) => (
              <PastAttemptItem
                key={skill._id}
                skill={skill}
                index={index}
                indexOfSkill={indexOfSkill}
                defaultOpen={defaultOpen}
                submittedTime={submittedTime}
                attemptTime={attemptTime}
                wasSwapped={
                  !!props.questionSwap && props.questionSwap === skill._id
                }
                {...props}
              />
            )
          )}
        />
      </div>
    </>
  );
};

export default PastAttempt;
